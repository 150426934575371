/**
 * @ngdoc overview
 * @name report.pdf.service:ReportPdfService
 */
'use strict';
define(['app'], function (app) {
    app.service('ReportPdfService', ReportPdfService);
    ReportPdfService.$inject = ['$http', '__env'];

    function ReportPdfService($http, __env) {

        return {
            post: post,
            get: get,
            getCategories: getCategories,
        };

        function post(params) {
            if (params.config.default) {
                return $http.post(__env.apiGateway + '/api/v1/report-pdf/',
                    {
                        config: params.config,
                        type: params.type,
                        data: params.data || {},
                    })
                    .then(function (res) {
                        return res.data;
                    })
                    .catch(function (err) {
                        throw err.data;
                    });
            } else {
                return $http.post(__env.apiGateway + '/api/v1/report-pdf/white-label',
                    {
                        config: params.config,
                        type: params.type,
                        data: params.data || {},
                    })
                    .then(function (res) {
                        return res.data;
                    }).catch(function (err) {
                        throw err.data;
                    });
            }
        }

        function get(params) {
            return $http.get(__env.apiGateway + '/api/v1/report-pdf/' + params.id)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    throw err.data;
                });
        }

        function getCategories(params) {
            if (!params) params = {};
            if (params.score === undefined) params.score = true;
            if (params.suggests === undefined) params.suggests = true;
            if (params.preview === undefined) params.preview = true;
            if (params.problems === undefined) params.problems = true;
            if (!params.type) params.type = 'seo_checker';

            var globalConfig = {
                score: params.score,
                preview: params.preview,
                suggests: params.suggests,
                problems: params.problems
            };

            switch (params.type) {
                case 'lead_gen':
                    return {
                        base: {
                            score: true,
                            exams: {
                                serp: globalConfig,
                                title: globalConfig,
                                h1: globalConfig,
                                h2: globalConfig,
                                https: globalConfig,
                                description: globalConfig,
                                urlSeoFriendly: globalConfig,
                                metaRobots: globalConfig,
                                robots: globalConfig,
                                sitemap: globalConfig,
                            }
                        },
                        content: {
                            score: true,
                            exams: {
                                uniqueKeywords: globalConfig,
                                titleCoherence: globalConfig,
                                h1Coherence: globalConfig,
                                ratioTextCode: globalConfig,
                                pageSize: globalConfig,
                                altInTagImage: globalConfig,
                                titleInTagA: globalConfig,
                                microdata: globalConfig,
                            }
                        },
                        social: {
                            score: true,
                            exams: {
                                openGraph: globalConfig,
                                twitterCard: globalConfig,
                                socialPlugin: globalConfig,
                                fbPopularity: globalConfig,
                            }
                        },
                        performanceWeb: {
                            score: params.score,
                            problems: params.problems,
                            exams: {
                                'largest-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'interactive': {score: true, preview: true, suggests: true, problems: true},
                                'first-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'total-blocking-time': {score: true, preview: true, suggests: true, problems: true},
                                'cumulative-layout-shift': {score: true, preview: true, suggests: true, problems: true},
                                'speed-index': {score: true, preview: true, suggests: true, problems: true},
                            },
                        },
                        performanceMobile: {
                            score: params.score,
                            problems: params.problems,
                            exams: {
                                'largest-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'interactive': {score: true, preview: true, suggests: true, problems: true},
                                'first-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'total-blocking-time': {score: true, preview: true, suggests: true, problems: true},
                                'cumulative-layout-shift': {score: true, preview: true, suggests: true, problems: true},
                                'speed-index': {score: true, preview: true, suggests: true, problems: true},
                            },
                        }
                    };
                case 'seo_checker':
                    return {
                        base: {
                            score: true,
                            exams: {
                                'snippet': globalConfig,
                                'title': globalConfig,
                                'metaTagDescription': globalConfig,
                                'favicon': globalConfig,
                                'urlSeoFriendly': globalConfig,
                                'microdata': globalConfig,
                                'deprecatedTags': globalConfig,
                                'hasSitemap': globalConfig,
                                'hasRobots': globalConfig,
                                'https': globalConfig,
                                'iFrame': globalConfig,
                                'titleCoherence': globalConfig,
                                'viewport': globalConfig,
                                'metaViewport': globalConfig,
                                'doctype': globalConfig,
                                'usesPassiveEventListeners': globalConfig,
                                'isCrawlable': globalConfig,
                                'crawlableAnchors': globalConfig,
                                'canonical': globalConfig,
                                'isHttps': globalConfig,
                                'jsLibraries': globalConfig,
                                'externalAnchorsRelNoopener': globalConfig,
                                'htmlLangExist': globalConfig,
                                'htmlLangValid': globalConfig,
                                'hrefLang': globalConfig,
                            }
                        },
                        content: {
                            score: true,
                            exams: {
                                'lowWordCount': globalConfig,
                                'ratioTextHtml': globalConfig,
                                'h1': globalConfig,
                                'h2': globalConfig,
                                'h1Coherence': globalConfig,
                                'titleInTagA': globalConfig,
                                'hasOptimizedUrl': globalConfig,
                                'linkText': globalConfig,
                                'imageAlt': globalConfig,
                                'plugins': globalConfig,
                                'headingOrder': globalConfig,
                            },
                        },
                        social: {
                            score: true,
                            exams: {
                                'openGraph': globalConfig,
                                'twitterCard': globalConfig,
                                'socialPlugin': globalConfig,
                                'fbPopularity': globalConfig,
                            }
                        },
                        performanceWeb: {
                            score: params.score,
                            problems: params.problems,
                            exams: {
                                'largest-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'interactive': {score: true, preview: true, suggests: true, problems: true},
                                'first-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'total-blocking-time': {score: true, preview: true, suggests: true, problems: true},
                                'cumulative-layout-shift': {score: true, preview: true, suggests: true, problems: true},
                                'speed-index': {score: true, preview: true, suggests: true, problems: true},
                            },
                        },
                        performanceMobile: {
                            score: params.score,
                            problems: params.problems,
                            exams: {
                                'largest-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'interactive': {score: true, preview: true, suggests: true, problems: true},
                                'first-contentful-paint': {score: true, preview: true, suggests: true, problems: true},
                                'total-blocking-time': {score: true, preview: true, suggests: true, problems: true},
                                'cumulative-layout-shift': {score: true, preview: true, suggests: true, problems: true},
                                'speed-index': {score: true, preview: true, suggests: true, problems: true},
                            },
                        }
                    };
                case 'seo_spider':
                    return {
                        base: {
                            score: true,
                            exams: {
                                optimizedGoogleSnippet: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                metaDescriptionMissing: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                titleMissing: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                titleOversize: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                metaDescriptionOversize: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                metaDescriptionBelowsize: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                hreflangMissing: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                noXDefault: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                canonical: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                }
                            }
                        },
                        structure: {
                            score: true,
                            exams: {
                                compressionEnabled: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                robotsTxt: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                sitemap: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                textCodeRatio: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                httpsMigration: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                imgMissAlt: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                imgOversizeAlt: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                pagesNotFound: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                }
                            }
                        },
                        social: {
                            score: true,
                            exams: {
                                opengraphIncomplete: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteTitle: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteDescription: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteImage: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteSiteName: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteType: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                opengraphIncompleteUrl: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncomplete: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncompleteTitle: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncompleteDescription: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncompleteImage: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncompleteCard: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                twittercardIncompleteSite: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                            }
                        },
                        content: {
                            score: true,
                            exams: {
                                pagesTextLevelLow: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h1Oversize: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h1Missing: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h1Multiple: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h2Oversize: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h2Missing: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                }
                            }
                        },
                        duplicated: {
                            score: true,
                            exams: {
                                pagesDuplicated: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                titleDuplicated: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                metaDescriptionDuplicated: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h1Duplicated: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                },
                                h2Duplicated: {
                                    score: params.score,
                                    preview: params.preview,
                                    suggests: params.suggests,
                                    problems: params.problems
                                }
                            }
                        }
                    };
            }
        }
    }
});

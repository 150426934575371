'use strict';
define([], function() {
    function ContentController($scope, $state) {
        if ($scope.loading) {
            $state.go('app.seochecker.results.overview');
            return;
        }
        if ($scope.contentCategories) {
            $scope.categories = $scope.contentCategories;
      
            $scope.categoriesWithExams = {};

            for (var property in $scope.categories) {
                $scope.categoriesWithExams[property] = {
                    categoryId: property,
                    exams: []
                };
            }
                        
            for (var exam in $scope.content.exams) { 
                for (var property in $scope.categories) {
                    if ($scope.categories.hasOwnProperty(property)) {
                        if ($scope.categories[property].indexOf(exam) != -1) {
                            $scope.categoriesWithExams[property].exams.push($scope.content.exams[exam]);
                        }
                    }
                }
            }
        }
    }
    ContentController.$inject=['$scope','$state'];
    return ContentController;
});


'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/detail/detail.controller',
    'modules/seospider/exam/analysis/detail/summary/summary.module',
    'modules/seospider/exam/analysis/detail/links/links.module',
    'modules/seospider/exam/analysis/detail/body/body.module',
    'modules/seospider/exam/analysis/detail/lang/lang.module',
    'modules/seospider/exam/analysis/detail/keywords/keywords.module',
    'modules/seospider/exam/analysis/detail/images/images.module',
    'modules/seospider/exam/analysis/detail/browser/browser.module'
],function(angular,PageDetailCtrl){
    var app=angular.module('app.seospider.exam.analysis.detail', [
        'app.seospider.exam.analysis.detail.browser',
        'app.seospider.exam.analysis.detail.keywords',
        'app.seospider.exam.analysis.detail.body',
        'app.seospider.exam.analysis.detail.lang',
        'app.seospider.exam.analysis.detail.images',
        'app.seospider.exam.analysis.detail.summary',
        'app.seospider.exam.analysis.detail.links'
    ]);
    app.controller('PageDetailCtrl',PageDetailCtrl);
    return app;
});
'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/opengraph/opengraph.routes',
    'modules/seospider/exam/analysis/opengraph/opengraph.controller',
    'modules/seospider/exam/analysis/opengraph/all/all.controller',
    'modules/seospider/exam/analysis/opengraph/optimized/optimized.controller',
    'modules/seospider/exam/analysis/opengraph/not-optimized/not-optimized.controller',
],function(
    angular,
    Routes,
    OpengraphCtrl,
    OpengraphAllCtrl,
    OpengraphOptimizedCtrl,
    OpengraphNotOptimizedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.opengraph', []);
    app.config(Routes);
    app.controller('OpengraphCtrl',OpengraphCtrl);
    app.controller('OpengraphAllCtrl',OpengraphAllCtrl);
    app.controller('OpengraphOptimizedCtrl',OpengraphOptimizedCtrl);
    app.controller('OpengraphNotOptimizedCtrl',OpengraphNotOptimizedCtrl);
    return app;
});
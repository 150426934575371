'use strict';
define([], function () {
    function controller(
        $scope,
        $q,
        $state,
        $translate,
        $mdToast,
        $mdDialog,
        KeywordTrackerService,
        CountriesService,
        UserService,
    ) {
        var vm = $scope;
        vm.frequencyLimit = 0;
        vm.keywordsLimit = 0;

        vm.showCustomToast = showCustomToast;
        vm.showDropAlert = showDropAlert;

        vm.locationController = {
            removeLocation: function (index, ev) {
                var location = vm.locations[index];
                showDropAlert(vm.trackerId, location, index, ev);
            },
            setDevice: function (idDevice) {
                if (!idDevice) {
                    return;
                }
                var listDevice = [
                    {id: 1, name: 'desktop'}, {id: 2, name: 'mobile'}
                ];
                var deviceName = '';
                angular.forEach(listDevice, function (obj) {
                    if (obj.id === idDevice) {
                        deviceName = obj.name;
                    }
                })
                return deviceName;
            },
            setSearchEngine: function (idSearchEngine) {
                if (!idSearchEngine) {
                    return;
                }
                var listSearchEngine = [
                    {id: 1, name: 'Google'}, {id: 2, name: 'Yahoo'}, {id: 3, name: 'Yandex'}, {id: 4, name: 'Bing'}
                ];
                var searchEngineName = '';
                angular.forEach(listSearchEngine, function (obj) {
                    if (obj.id === idSearchEngine) {
                        searchEngineName = obj.name;
                    }
                })
                return searchEngineName;
            },
            getCountryNameByCountryCode: function (countryCode) {
                return CountriesService.getByCode(countryCode).name;
            }
        };

        function showDropAlert(trackerId, location, index, ev) {
            $mdDialog.show({
                controller: 'ProjectDeleteLocationCtrl',
                templateUrl: "app/modules/project/dialogs/delete-location/delete-location.html",
                targetEvent: ev,
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    projectId: trackerId,
                    location: location,
                }
            })
                .then(function () {
                    vm.locations.splice(index, 1);
                    vm.keywordsCounter = vm.locations.map(function (location) {
                        return location.count;
                    })
                        .reduce(function (counter, currentValue) {
                            return counter + currentValue;
                        }, 0);
                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        }

        vm.emptyConfiguration = '<i class="icon-blackhole custom-message-template-icon"></i>' +
            '<div class="md-subhead m-b p-l p-r">' +
            $translate.instant('project.wizard.no.configuration') +
            '</div>';

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function getUserLimits() {
            var dfd = $q.defer();

            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'rank-tracker-frequency' && service.qtyAllowed) {
                                vm.frequencyLimit = service.qtyAllowed;
                            }

                            if (service.alias === 'rank-tracker-keywords' && service.qtyAllowed) {
                                vm.keywordsLimit = service.qtyAllowed;
                            }
                        });
                    }

                    dfd.resolve();
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        }

        function getTracker() {
            return KeywordTrackerService
                .getLocations(vm.trackerId)
                .then(function (res) {
                    vm.locations = res.data.locations;
                    vm.keywordsCounter = vm.locations.map(function (location) {
                        return location.count;
                    })
                        .reduce(function (counter, currentValue) {
                            return counter + currentValue;
                        }, 0);
                })
                .catch(function (err) {
                    return err;
                });
        }

        this.$onInit = function () {
            vm.trackerId = this.tracker.id;
            getUserLimits()
                .then(getTracker)
                .catch(function (err) {
                    console.log(err);
                })
        }

    }

    controller.$inject = [
        '$scope',
        '$q',
        '$state',
        '$translate',
        '$mdToast',
        '$mdDialog',
        'KeywordTrackerService',
        'CountriesService',
        'UserService',
    ];

    return {
        templateUrl: 'app/modules/project/settings/tracker/tracker.html',
        bindings: {
            tracker: '<'
        },
        controller: controller
    }
});

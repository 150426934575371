'use strict';
define([], function () {
    function SEOSpiderAnalysisController(
        $scope,
        $state,
        SeospiderService,
        $rootScope,
        $stateParams,
        LanguageService,
        ReportPdfService,
        $timeout,
        $window,
        sessions,
        projects,
        TrackEventService
    ) {
        function init() {
            getSessionDetails();
        }

        var vm = $scope;
        var userData = JSON.parse($window.localStorage.getItem('ngStorage-currentUser'));
        vm.report = null;/*analysis && analysis.report ? analysis.report : analysis || {};*/
        vm.sessions = sessions;
        vm.projects = projects;
        vm.listenResize = 'phantom-tool-navbar';
        vm.$state = $state;
        vm.inCrawling = $stateParams.inCrawling && $stateParams.inCrawling === 'true';
        vm.inStopCrawl = false;
        vm.crawlFinished = true;
        vm.SEOProjectId = getSEOProjectId(projects);
        vm.sessionId = $stateParams.sessionId;
        vm.projectId = $stateParams.projectId;
        vm.menuConfig = {
            singleOpening: false,
            linkFirstLevelMenuToTheFirstSubmenu: false,
            saveStateOnRefresh: false
        };

        vm.menuItems = [
            {
                name: 'SEO Report',
                icon: 'speed',
                isOpened: false,
                mobileTabOrder: 1,
                link: {
                    type: 'internal',
                    urls: ['app.seospider.exam.analysis.report.overview',
                        'app.seospider.exam.analysis.report.base',
                        'app.seospider.exam.analysis.report.structure',
                        'app.seospider.exam.analysis.report.content',
                        'app.seospider.exam.analysis.report.duplicated',
                        'app.seospider.exam.analysis.report.social'
                    ],
                },
            },
            {
                name: 'seospider.pages',
                icon: 'web',
                isOpened: false,
                mobileTabOrder: 1,
                submenu: [
                    {
                        name: 'seospider.title.all',
                        link: {
                            type: 'internal',
                            urls: ['app.seospider.exam.analysis.page.all'],
                        },
                    },
                    {
                        name: 'seospider.title.duplicate',
                        link: {
                            type: 'internal',
                            urls: ['app.seospider.exam.analysis.page.duplicated'],
                        },
                    },
                ]
            },
            {
                name: 'seospider.title',
                icon: 'title',
                isOpened: false,
                mobileTabOrder: 1,
                submenu: [
                    {
                        name: 'Title',
                        isOpened: false,
                        mobileTabOrder: 2,
                        submenu: [
                            {
                                name: 'seospider.title.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.title.all'],
                                },
                            },
                            {
                                name: 'seospider.title.over',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.title.over'],
                                },
                            },
                            {
                                name: 'seospider.title.missing',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.title.missing'],
                                },
                            },
                            {
                                name: 'seospider.title.duplicate',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.title.duplicated'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'H1',
                        isOpened: false,
                        mobileTabOrder: 2,
                        submenu: [
                            {
                                name: 'seospider.h1.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h1.all'],
                                },
                            },
                            {
                                name: 'seospider.h1.missing',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h1.missing'],
                                },
                            },
                            {
                                name: 'seospider.h1.multiple',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h1.multiple'],
                                },
                            },
                            {
                                name: 'seospider.h1.duplicate',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h1.duplicated'],
                                },
                            },
                            {
                                name: 'seospider.h1.over',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h1.over'],
                                },
                            }
                        ]
                    },
                    {
                        name: 'H2',
                        isOpened: false,
                        mobileTabOrder: 3,
                        submenu: [
                            {
                                name: 'seospider.h2.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h2.all'],
                                },
                            },
                            {
                                name: 'seospider.h2.missing',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h2.missing'],
                                },
                            },
                            {
                                name: 'seospider.h2.duplicate',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h2.duplicated'],
                                },
                            },
                            {
                                name: 'seospider.h2.over',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.h2.over'],
                                },
                            }
                        ]
                    },
                ]
            },
            {
                name: 'seospider.content',
                icon: 'text_format',
                isOpened: false,
                mobileTabOrder: 8,
                submenu: [
                    {
                        name: 'seospider.description',
                        isOpened: false,
                        mobileTabOrder: 7,
                        submenu: [
                            {
                                name: 'seospider.description.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.description.all'],
                                },
                            },
                            {
                                name: 'seospider.description.below',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.description.below'],
                                },
                            },
                            {
                                name: 'seospider.description.over',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.description.over'],
                                },
                            },
                            {
                                name: 'seospider.description.missing',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.description.missing'],
                                },
                            },
                            {
                                name: 'seospider.description.duplicate',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.description.duplicated'],
                                },
                            }
                        ]
                    },
                    {
                        name: 'seospider.text',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.content.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.all'],
                                },
                            },
                            {
                                name: 'seospider.content.low',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.low'],
                                },
                            },
                            {
                                name: 'seospider.content.med',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.med'],
                                },
                            },
                            {
                                name: 'seospider.content.high',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.high'],
                                },
                            },
                            {
                                name: 'seospider.content.lowRatio',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.lowRatio'],
                                },
                            },
                            {
                                name: 'seospider.content.highRatio',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.content.highRatio'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'seospider.images',
                        isOpened: false,
                        mobileTabOrder: 7,
                        submenu: [
                            {
                                name: 'seospider.images.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.images.all'],
                                },
                            },
                            {
                                name: 'seospider.images.missing',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.images.missing'],
                                },
                            },
                            {
                                name: 'seospider.images.missingAlt',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.images.missingAlt'],
                                },
                            },
                            {
                                name: 'seospider.images.altOver',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.images.altOver'],
                                },
                            },
                        ]
                    }
                ]
            },
            {
                name: 'seospider.preview',
                icon: 'preview',
                isOpened: false,
                mobileTabOrder: 13,
                submenu: [
                    {
                        name: 'Snippets',
                        isOpened: false,
                        mobileTabOrder: 14,
                        submenu: [
                            {
                                name: 'seospider.description.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.snippets.all'],
                                },
                            },
                            {
                                name: 'seospider.snippets.optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.snippets.optimized'],
                                },
                            },
                            {
                                name: 'seospider.snippets.not-optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.snippets.not-optimized'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'Opengraph',
                        isOpened: false,
                        mobileTabOrder: 15,
                        submenu: [
                            {
                                name: 'seospider.description.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.opengraph.all'],
                                },
                            },
                            {
                                name: 'seospider.snippets.optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.opengraph.optimized'],
                                },
                            },
                            {
                                name: 'seospider.snippets.not-optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.opengraph.not-optimized'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'Twittercard',
                        isOpened: false,
                        mobileTabOrder: 16,
                        submenu: [
                            {
                                name: 'seospider.description.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.twittercard.all'],
                                },
                            },
                            {
                                name: 'seospider.twittercard.optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.twittercard.optimized'],
                                },
                            },
                            {
                                name: 'seospider.twittercard.not-optimized',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.twittercard.not-optimized'],
                                },
                            },

                        ]
                    },
                ]
            },
            {
                name: 'seospider.structure',
                icon: 'code',
                isOpened: false,
                mobileTabOrder: 13,
                submenu: [
                    {
                        name: 'Https',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.https.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.https.all'],
                                },
                            },
                            {
                                name: 'seospider.https.insecure',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.https.insecure'],
                                },
                            },
                            {
                                name: 'seospider.https.secure',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.https.secure'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'Canonical',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.canonical.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.canonical.all'],
                                },
                            },
                            {
                                name: 'seospider.canonical.defined',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.canonical.defined'],
                                },
                            },
                            {
                                name: 'seospider.canonical.withoutCanonical',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.canonical.missing'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'Status',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.status.found',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.httpstatus.200'],
                                },
                            },
                            {
                                name: 'seospider.status.redirect',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.httpstatus.301'],
                                },
                            },
                            {
                                name: 'seospider.status.pageNotFound',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.httpstatus.404'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'seospider.lang',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.lang.allPages',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.languages.all'],
                                },
                            },
                            {
                                name: 'seospider.lang.withoutHreflang',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.languages.missing'],
                                },
                            },
                            {
                                name: 'seospider.lang.withoutXDefault',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.languages.noXDefault'],
                                },
                            },
                        ]
                    },
                    {
                        name: 'Robots',
                        isOpened: false,
                        submenu: [
                            {
                                name: 'seospider.robots.all',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.robots.all'],
                                },
                            },
                            {
                                name: 'seospider.robots.NoIndex',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.robots.noindex'],
                                },
                            },
                            {
                                name: 'seospider.robots.Noodp',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.robots.noodp'],
                                },
                            },
                            {
                                name: 'seospider.robots.Noydir',
                                link: {
                                    type: 'internal',
                                    urls: ['app.seospider.exam.analysis.robots.noydir'],
                                },
                            },
                        ]
                    },

                    {
                        name: 'seospider.taxonomies',
                        isOpened: false,
                        link: {
                            type: 'internal',
                            urls: ['app.seospider.exam.analysis.taxonomies'],
                        },
                    },
                ]
            },
        ];


        function getSEOProjectId(projects) {
            const project = currentSEOProject(projects);

            if (project && project.projectId) {
                return project.projectId;
            }
        }

        function currentSEOProject(projects) {
            if (!projects) {
                return -1;
            }
            try {
                for (var i = 0; i < projects.length; i++) {
                    if (projects[i].crawlerProject.id === Number($stateParams.projectId)) {
                        return projects[i].crawlerProject;
                    }
                }
            } catch (e) {
                $window.console.log(e);
                return -1;
            }
            return -1;
        }

        vm.currentSEOProject = currentSEOProject;

        vm.reCrawl = function () {
            try {
                if (vm.inCrawling) {
                    return;
                }
                $rootScope.$broadcast("startCrawling", {
                    sessionId: undefined,
                    projectId: $stateParams.projectId,
                    status: SeospiderService.getStates().RUNNING,
                    createdAt: new Date().toISOString()
                });
                TrackEventService.event('Suite Usages', 'SEO Project - Start Crawl', 'seo-spider-project-dashboard');
                TrackEventService.gaV2Event('crawl_start', {
                    from: 'seo-spider-project-dashboard'
                });
                vm.inCrawling = true;
                vm.crawlFinished = false;
                SeospiderService.update({id: $stateParams.projectId})
                    .then(function (res) {
                        $state.go('app.seospider.exam.analysis.report.overview', {
                            sessionId: res.data.crawlerSessionId,
                            projectId: $stateParams.projectId
                        });
                    })
                    .catch(function (err) {
                        vm.inCrawling = false;
                        vm.crawlFinished = true;
                    });


            } catch (err) {
                console.log(err);
            }
        };

        vm.stopCrawl = function () {
            if (!vm.projectId || !vm.sessionId) {
                return;
            }
            SeospiderService.stopCrawl(vm.projectId, vm.sessionId)
                .then(function (res) {
                    if (res && res.status && res.status == 200) {
                        $rootScope.$broadcast("stopCrawling", {
                            sessionId: vm.sessionId
                        });

                        vm.inCrawling = false;
                        vm.crawlFinished = false;
                        vm.inStopCrawl = true;
                    }
                })
                .catch(function (err) {
                    console.error(err);
                    vm.inStopCrawl = false;
                });
        };

        vm.$on("inCrawling", function (evt) {
            vm.inCrawling = true;
            vm.crawlFinished = false;
        });

        vm.$on("crawlFinished", function (evt, action) {
            vm.crawlFinished = true;
            vm.inCrawling = false;
            vm.inStopCrawl = false;

            getReport(vm.sessionId);
        });

        vm.isScreenMd = function () {
            return $window.innerWidth < 1360;
        };

        vm.selectSession = function (session) {
            vm.sessionId = session.id;
            $state.go($state.current.name, {
                sessionId: session.id,
                projectId: vm.projectId
            }, {
                reload: false,
            });
        };

        function configReport(sessionId, url) {
            if (!url) {
                return;
            }
            var locale = LanguageService.getLanguageCodeSaveOnStorage();
            vm.reportConfiguration = {
                data: vm.report,
                config: {
                    version: "1.0",
                    params: {
                        url: url,
                        locale: locale,
                        projectId: sessionId,
                        email: {
                            to: userData.email
                        },
                        categories: ReportPdfService.getCategories({
                            type: 'seo_spider',
                            score: true,
                            preview: true,
                            suggests: true,
                            problems: true
                        })
                    },
                    default: true
                },
                type: "seo_spider"
            };
        }

        function getReport(sessionId) {
            var locale = LanguageService.getLanguageCodeSaveOnStorage();
            if (vm.reportConfiguration && vm.reportConfiguration.config && vm.reportConfiguration.config.params) {
                try {

                    vm.reportConfiguration.config.params.locale = locale;
                } catch (err) {
                    $window.console.log(err);
                }
            }

            SeospiderService
                .report({
                    jobId: sessionId,
                    lang: locale,
                })
                .then(function (analysis) {
                    var newReport = analysis && analysis.report ? analysis.report : analysis || {};
                    vm.report = newReport;
                    vm.reportConfiguration.data = newReport;
                })
                .catch(function (err) {
                    $window.console.log(err);
                });
        }

        $rootScope.$on('languageChanged', function () {
            getReport(vm.sessionId);
        });

        vm.selectProject = function (project) {
            if (vm.projectId == project.crawlerProject.id) {
                return;
            }
            var params = {
                sessionId: null,
                projectId: null,
            };
            if (project && project.crawlerProject && project.crawlerProject.id) {
                params.projectId = project.crawlerProject.id;
            }
            if (project && project.crawlerSession && project.crawlerSession.id) {
                params.sessionId = project.crawlerSession.id;
            }
            if (params.sessionId && params.projectId) {
                $state.go('app.seospider.exam.analysis.report.overview', params);
                return;
            }
            SeospiderService
                .getSessions(params.projectId)
                .then(function (res) {
                    params.sessionId = res.data.sessions[res.data.sessions.length - 1].id;
                    $state.go('app.seospider.exam.analysis.report.overview', params);
                })
                .catch(function (err) {
                    console.log(err);
                    return [];
                });
        };

        var getSessionDetails = function () {
            SeospiderService.analysisStats(vm.sessionId)
                .then(function (detail) {
                    if (!detail) {
                        $state.go('app.seospider.list');
                        return;
                    }

                    vm.analysis = detail;

                    var formattedUrl = detail.url.replace('https://', '')
                        .replace('http://', '')
                        .replace('www.', '')
                        .replace(/\/$/, "");

                    configReport(vm.sessionId, formattedUrl);
                })
                .catch(function () {
                    $state.go('app.seospider.list');
                });
        };


        init();
    }

    SEOSpiderAnalysisController.$inject = [
        '$scope',
        '$state',
        'SeospiderService',
        '$rootScope',
        '$stateParams',
        'LanguageService',
        'ReportPdfService',
        '$timeout',
        '$window',
        'sessions',
        'projects',
        'TrackEventService'
    ];
    return SEOSpiderAnalysisController;
});

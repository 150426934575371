'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm=$scope;
        vm.showToolbarDetail=false;
        vm.tabs={
            categoryTitle:'seospider.images',
            categoryState:'app.seospider.exam.analysis.images',
            fields:[
                {label:'seospider.images.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.images.missing',icon:'icon icon-close',uiSref:'.missing'},
                {label:'seospider.images.missingAlt',icon:'icon icon-close',uiSref:'.missingAlt'},
                {label:'seospider.images.altOver',icon:'icon icon-speedometer',uiSref:'.altOver'}
            ]
        };
        vm.evalOccurences=evalOccurences;
        vm.evalLength=evalLength;
        vm.getRealSrc=getRealSrc;
        function getRealSrc(base,relative){
            try{
                return new URL(relative, base).href;
            }catch(err){
                return relative;
            }
        }
        function evalOccurences(score) {
            score=parseInt(score)||0;
            if (score == 1)
                return 'green-text';
            if (score == 0)
                return 'red-text';
            else  return 'text-darken-1 orange-text';
        }
        function evalLength(len) {
            len=parseInt(len)||0;
            if (len >70)
                return 'red-text';
            if (len >15&&len<=70)
                return 'green-text';
            if (len >=5&&len<=15)
                return 'text-darken-1 orange-text';
            return 'red-text';
        }
    }
    Controller.$inject=['$scope'];
    return Controller;
});

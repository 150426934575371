define([], function () {
    controller.$inject = ['$scope', '$rootScope', 'AuthService', 'UserService', '$state', 'WorkspaceService', '$localStorage', '$mdDialog'];

    function controller($scope, $rootScope, AuthService, UserService, $state, WorkspaceService, $localStorage, $mdDialog) {
        function loadMemberLimit() {
            if (!AuthService.isAuthenticated()) {
                return;
            }
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'workspace-users-limit') {
                                $scope.maxMembers = (service.qtyAllowed === 1) ? service.qtyAllowed : service.qtyAllowed + 1;
                            }
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }

        $scope.workspaces = [];

        $scope.$watch('$ctrl.currentUser.workspace.id', function (newVal) {
            $scope.activeWorkspaceId = newVal;
            $scope.currentUser = $localStorage.currentUser;
            getAllWorkspaces();
            getInvitations();
            loadMemberLimit();
        });

        $rootScope.$on('$stateChangeStart', function () {
            if (AuthService.isAuthenticated()) {
                getInvitations();
            }
        });

        function getInvitations() {
            return WorkspaceService
                .getInvitations()
                .then(function (res) {
                    if (res) {
                        $scope.invitations = res;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function getAllWorkspaces() {
            WorkspaceService.getAllWorkspaces()
                .then(function (res) {
                    if (res && res.workspaces && res.workspaces.length) {
                        $scope.workspaces = res.workspaces;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        $scope.switchWorkspace = function (ev, workspace) {
            $mdDialog.show({
                templateUrl: "app/modules/template/dialogs/switch-workspace/switch-workspace.html",
                controller: "SwitchWorkspaceCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    workspace: workspace,
                }
            });
        };

        $scope.$on('invitation-accept', function () {
            getAllWorkspaces();
        });

        $scope.logout = function () {
            AuthService.logout();
            setTimeout(function () {
                $state.go('app.welcome', {}, {
                    reload: true
                });
            }, 1000);
            if (zE) {
                zE('messenger', 'logoutUser');
            }
        };
    }

    return {
        templateUrl: 'app/modules/template/components/st-menu-multi-account/st-menu-multi-account.component.html',
        controller: controller,
        bindings: {
            currentUser: '<',
        }
    };
});

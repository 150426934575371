'use strict';
import app from 'app';

app.component('alertLabel', {
    templateUrl: 'app/components/alert-label/alert-label.html',
    controller: controller,
    bindings: {
        title: '<',
        subtitle: '<',
        description: '<',
        status: '<',
    }
});

controller.$inject = ['$scope'];

function controller($scope) {
    var vm = $scope;
    var ctrl = this;

    vm.showDescription = false;
    vm.closeAlert = false;

    ctrl.$onInit = function () {
        vm.status = ctrl.status;
        vm.title = ctrl.title;
        vm.subtitle = ctrl.subtitle;
        vm.description = ctrl.description;
    };

    ctrl.$onChanges = function () {
        vm.status = ctrl.status;
        vm.title = ctrl.title;
        vm.subtitle = ctrl.subtitle;
        vm.description = ctrl.description;
    };
}


'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'Description',
            categoryState:'app.seospider.exam.analysis.description',
            fields:[
                {label:'seospider.description.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.description.below',icon:'icon icon-speedometer',uiSref:'.below'},
                {label:'seospider.description.over',icon:'icon icon-speedometer',uiSref:'.over'},
                {label:'seospider.description.missing',icon:'icon icon-close',uiSref:'.missing'},
                {label:'seospider.description.duplicate',icon:'icon icon-docs',uiSref:'.duplicated'},
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

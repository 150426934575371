'use strict';
define([
    'angular',
    'modules/leadgeneration/form/form-generator/custom-file/custom-file.controller',
    'modules/leadgeneration/form/form-generator/report/report.controller',
    'modules/leadgeneration/form/form-generator/free/free.controller',
], function (angular, LeadGeneratorFormCustomFileCtrl, LeadGeneratorFormReportCtrl, LeadGeneratorFormFreeFormCtrl) {
    var app = angular.module('app.leadgeneration.form.form-generator', []);
    app.controller('LeadGeneratorFormCustomFileCtrl', LeadGeneratorFormCustomFileCtrl);
    app.controller('LeadGeneratorFormReportCtrl', LeadGeneratorFormReportCtrl);
    app.controller('LeadGeneratorFormFreeFormCtrl', LeadGeneratorFormFreeFormCtrl);
    return app;
});

'use strict';
define([], function () {

    var controller = function ($scope, $mdMedia, PlanDetailsService, $window, $state) {
        var $ctrl = this;
        var vm = $scope;
        vm.isPromoTime = PlanDetailsService.isPromoTime();
        vm.currentPromo = PlanDetailsService.getCurrentPromo();
        vm.goToPromoLink = goToPromoLink;
        vm.$mdMedia = $mdMedia;

        this.$onInit = function () {
            checkLastClosingTime();
        };

        function goToPromoLink() {
            if (vm.currentPromo.bannerCTALink) {
                $window.open(vm.currentPromo.bannerCTALink + '&utm_medium=home_banner', '_blank');
                return;
            } else {
                $state.go('upgrade.choose-plan');
            }
        }

        function checkLastClosingTime() {
            vm.bannerIsVisible = true;

            var timeClosedBanner = localStorage.getItem('close-promo-banner');
            var parsedTimeClosedBanner = JSON.parse(timeClosedBanner);
            if (parsedTimeClosedBanner) {

                var now = new Date().getTime();
                var oneDayLater = parsedTimeClosedBanner + (1 * 24 * 60 * 60 * 1000);
                if (now < oneDayLater) {
                    vm.bannerIsVisible = false;
                } else if (now > oneDayLater) {
                    localStorage.removeItem('close-promo-banner');
                    vm.bannerIsVisible = true;
                }
            } else {
                vm.bannerIsVisible = true;
            }
        }

        vm.closeBanner = function () {
            vm.bannerIsVisible = false;
            var start = new Date().getTime();
            var convertString = JSON.stringify(start);
            localStorage.setItem('close-promo-banner', convertString);
        };

        vm.$watch(function () {
            return localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        }, function (currentLanguage, previousLanguage) {
            if (!vm.currentPromo || !vm.currentPromo.languageVisibility) {
                return;
            }

            vm.isItalianLanguage = currentLanguage === 'it-IT';
            if (vm.currentPromo.languageVisibility == 'all') {
                vm.bannerIsVisibleForThisLanguage = true;
            } else if (vm.currentPromo.languageVisibility == 'it' && vm.isItalianLanguage) {
                vm.bannerIsVisibleForThisLanguage = true;
            } else {
                vm.bannerIsVisibleForThisLanguage = false;
            }

        });
    };
    controller.$inject = ['$scope', '$mdMedia', 'PlanDetailsService', '$window', '$state'];
    return {
        templateUrl: 'app/components/st-promo-banner/st-promo-banner.html',
        bindings: {},
        controller: controller
    };
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/languages/all/all.controller',
],function(
    angular,
    LanguagesAllCtrl
){
    var app=angular.module('app.seospider.exam.analysis.languages.all', []);
    app.controller("LanguagesAllCtrl",LanguagesAllCtrl);
    return app;
});
controller.$inject = ['$scope', 'FavIconService'];

function controller($scope, FavIconService) {
}

export default {
    templateUrl: 'app/modules/writerassistant/components/assistant-auditor-content-structure/assistant-auditor-content-structure.html',
    controller: controller,
    bindings: {
        articleDataSet: "<",
        numImages: "<",
        numHeadings: "<",
        numWords: "<",
        numParagraphs: "<",
        size: "@",
    }
};

'use strict';
define([], function () {
    controller.$inject = ['__env', '$scope', '$state', '$mdMedia', '$mdDialog', 'AuthService', 'TrackEventService', 'UserService', '$location', 'WorkspaceService', '$stateParams',
        '$rootScope', 'PlanDetailsService'
    ];

    function controller(__env, $scope, $state, $mdMedia, $mdDialog, AuthService, TrackEventService, UserService, $location, WorkspaceService, $stateParams, $rootScope,
        PlanDetailsService) {
        var vm = this;

        vm.$onInit = function () {
            $scope.dataIsLoaded = false;
            checkCanDoTrial();
        };

        $scope.isPromoTime = AuthService.isAuthenticated() && PlanDetailsService.isPromoTime() && PlanDetailsService.getCurrentPromo().promoType === 'all-platform-promo';

        if ($scope.isPromoTime) {
            //$scope.classModifier = PlanDetailsService.getCurrentPromo().currentPromoModifierClass;
        }
        $scope.trialPeriodDays = __env.trialPeriodDays;
        $scope.$mdMedia = $mdMedia;

        $scope.template = vm.template || null;

        $scope.redirectTo = $state.current.name;
        $scope.$stateParams = $stateParams;
        $scope.getFragment = getFragment;
        $scope.setFragment = setFragment;
        $scope.isWorkspaceOwner = isWorkspaceOwner();
        $scope.fragment = 'alert-signup';
        $scope.userIsOffline;
        $scope.code = 'NO_ENOUGH_CREDITS';

        function setFragment(fragToAdd) {
            $location.hash(null);
            $scope.fragment = fragToAdd;
            $location.hash(fragToAdd);
        }

        function getFragment() {
            return location.hash;
        }

        function isWorkspaceOwner() {
            return WorkspaceService.isOwner();
        }

        $scope.hide = function () {
            $rootScope.alertLimitIsOpen = false;
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $rootScope.alertLimitIsOpen = false;
            $mdDialog.cancel();
        };

        $scope.answer = function (answer) {
            $rootScope.alertLimitIsOpen = false;
            $mdDialog.hide(answer);
        };

        function checkCanDoTrial() {
            if (!AuthService.isAuthenticated()) {
                $scope.userIsOffline = true;
                $scope.dataIsLoaded = true;
                setFragment('alert-signup');
                return;
            }

            UserService.limits()
                .then(function (res) {
                    if (!res || !res.data || !res.data.workspace ||
                        !res.data.workspace.name || !res.data.workspace.ownerEmail) {
                        return;
                    }

                    $scope.workspaceData = {
                        ownerEmail: res.data.workspace.ownerEmail,
                        workspaceName: res.data.workspace.name
                    };
                    $scope.userHasActiveSubscription = res.data.account === 'sub';
                    $scope.canDoTrial = res.data.canDoTrial;
                    $scope.dataIsLoaded = true;

                    if ($scope.canDoTrial) {
                        setFragment('alert-trial');
                    } else {
                        setFragment('alert-upgrade');
                    }
                })
                .catch(function (err) {
                    console.log(err);

                    $scope.canDoTrial = false;
                    $scope.dataIsLoaded = true;
                    setFragment('alert-upgrade');
                });
        }

        $scope.$watch('$ctrl.template', function (template) {
            $scope.template = 'app/components/st-alert-limit/templates/' + template + '.html';
        });

        $scope.$watchCollection('$ctrl.error', function (err) {
            $scope.error = err.error;
            $scope.errorAlias = err.error.request.alias;

            if (err.error.code) {
                $scope.code = err.error.code;
            }

            if ($scope.userIsOffline || err.error.request.offline) {
                TrackEventService.event('Funnel Events', 'Alert Limit Exceed - Signup', $scope.errorAlias);
                TrackEventService.gaV2Event('alert_limit_exceed', {
                    type: "Signup",
                    service: $scope.errorAlias,
                });

                $scope.error.request.alias = 'user-offline';
            }
        });

        function isSpecialAlert() {
            return $scope.errorAlias === 'workspace-unavailable' ||
                $scope.errorAlias === 'permissions' ||
                $scope.errorAlias === 'seo-project-limit' ||
                $scope.errorAlias === 'max-simultaneous-login';
        }

        function isLogged() {
            return !$scope.userIsOffline;
        }

        $scope.$watch('dataIsLoaded', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!isLogged() || isSpecialAlert()) {
                return;
            }

            if (!$scope.isWorkspaceOwner) {
                TrackEventService.event('Funnel Events', 'Alert Limit Exceed - NoOwner', $scope.errorAlias);
                TrackEventService.gaV2Event('alert_limit_exceed', {
                    type: "NoOwner",
                    service: $scope.errorAlias
                });
                return;
            }

            $scope.limitAlias = 'analysis';
            $scope.trackEventAlias = $scope.limitAlias;
            if (
                $scope.error &&
                $scope.error.request &&
                $scope.error.request.alias
            ) {
                $scope.trackEventAlias = $scope.error.request.alias;

                if (
                    $scope.error.request.alias !== 'seo-checker-audit' &&
                    $scope.error.request.alias !== 'keyword-explorer-searches' &&
                    $scope.error.request.alias !== 'copymetrics-audit' &&
                    $scope.error.request.alias !== 'serp-report-audit'
                ) {
                    $scope.limitAlias = $scope.error.request.alias;
                }
            }

            if ($scope.error && $scope.error.code) {
                $scope.code = $scope.error.code;
            }

            if (!$scope.userHasActiveSubscription && $scope.canDoTrial) {
                $scope.error.request.alias = 'user-can-do-trial';

                TrackEventService.event('Funnel Events', 'Alert Limit Exceed - Trial', $scope.errorAlias);
                TrackEventService.gaV2Event('alert_limit_exceed', {
                    type: "Trial",
                    service: $scope.trackEventAlias
                });
            } else {
                $scope.error.request.alias = 'user-cant-do-trial';

                TrackEventService.event('Funnel Events', 'Alert Limit Exceed - Upgrade', $scope.errorAlias);
                TrackEventService.gaV2Event('alert_limit_exceed', {
                    type: "Upgrade",
                    service: $scope.trackEventAlias
                });
            }
        });
    }

    return {
        templateUrl: 'app/components/st-alert-limit/st-alert-limit.html',
        bindings: {
            template: '=',
            error: '='
        },
        controller: controller
    };
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/description/missing/missing.controller',
],function(
    angular,
    MetaDescMissingCtrl
){
    var app=angular.module('app.seospider.exam.analysis.description.missing', []);
    app.controller("MetaDescMissingCtrl",MetaDescMissingCtrl);
    return app;
});
'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm=$scope;
        vm.showToolbarDetail=false;
        vm.tabs={
            categoryTitle:'Https',
            categoryState:'app.seospider.exam.analysis.https',
            fields:[
                {label:'seospider.https.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.https.insecure',icon:'icon icon-speedometer',uiSref:'.insecure'},
                {label:'seospider.https.secure',icon:'icon icon-speedometer',uiSref:'.secure'}
            ]
        };
        vm.evalOccurences=evalOccurences;
        vm.evalLength=evalLength;
        function evalOccurences(score) {
            score=parseInt(score)||0;
            if (score == 1)
                return 'green-text';
            if (score == 0)
                return 'red-text';
            else  return 'text-darken-1 orange-text';
        }
        function evalLength(len) {
            len=parseInt(len)||0;
            if (len >70)
                return 'red-text';
            if (len >15&&len<=70)
                return 'green-text';
            if (len >=5&&len<=15)
                return 'text-darken-1 orange-text';
            return 'red-text';
        }
    }
    Controller.$inject=['$scope'];
    return Controller;
});

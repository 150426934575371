'use strict';
define([], function () {
    function controller($scope, $state) {
        var ctrl = this;
        var serpFeatures = {
            'app': {
                icon: 'icon-sf-app',
                text: 'app'
            },
            'featured_snippet': {
                icon: 'icon-sf-featured-snippet',
                text: 'feature snippet'
            },
            'images': {
                icon: 'icon-sf-images',
                text: 'images'
            },
            'knowledge_graph': {
                icon: 'icon-sf-knowledge-graph',
                text: 'knowledge graph',
            },
            'local_pack': {
                icon: 'icon-sf-local-pack',
                text: 'local pack'
            },
            'people_also_ask': {
                icon: 'icon-sf-people-also-ask',
                text: 'people also ask'
            },
            'shopping': {
                icon: 'icon-sf-shopping',
                text: 'shopping'
            },
            'twitter': {
                icon: 'icon-sf-twitter',
                text: 'twitter'
            },
            'video': {
                icon: 'icon-sf-video',
                text: 'video'
            },
            'events': {
                icon: 'icon-sf-events',
                text: 'events'
            },
            'paid': {
                icon: 'icon-sf-ad-top',
                text: 'paid'
            },
            'info': {
                icon: 'icon-info-circle-filled',
                text: 'info'
            }
        };

        $scope.getIconSf = function (sf) {
            if (!serpFeatures[sf]) {
                return serpFeatures['info'].icon;
            }
            return serpFeatures[sf].icon;
        };

        $scope.$watch('$ctrl.singleRow', function (singleRow) {
            if (singleRow && singleRow.serpFeatures && singleRow.serpFeatures.length) {
                $scope.templateSerpFeatures = getTextSf(singleRow.serpFeatures);
            }
        })

        function getTextSf(serpFeaturesActive) {
            var tooltipString = serpFeaturesActive.length + ' SERP Features sono attive in questa serp: ';
            var listSerpFeaturesFormatted = [];
            if (serpFeaturesActive.length) {
                angular.forEach(serpFeaturesActive, function (sf) {
                    if (!serpFeatures[sf]) {
                        listSerpFeaturesFormatted.push(
                            '<span class="badge-ctr md-caption st-grey-3 m-xs p-l-xs p-r-xs">' +
                            serpFeatures['info'].text +
                            '</span>'
                        );
                    } else {
                        listSerpFeaturesFormatted.push(
                            '<span class="badge-ctr md-caption st-grey-3 m-xs p-l-xs p-r-xs">' +
                            serpFeatures[sf].text +
                            '</span>'
                        );
                    }
                });
            }
            return tooltipString + listSerpFeaturesFormatted.join('');
        };
    }

    controller.$inject = ['$scope', '$state'];

    return {
        templateUrl: 'app/modules/keywordtracker/components/serp-features-tracker/serp-features-tracker.html',
        bindings: {
            singleRow: '<',
        },
        controller: controller,
    };
});

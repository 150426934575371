'use strict';
define(['app'], function (app) {
    app.component('stSearchSnippet', {
        templateUrl: 'app/components/st-search-snippet/st-search-snippet.html',
        bindings: {
            title: '=',
            breadcrumb: '=',
            description: '=',
            isLoading: '=',
            url: '=',
            noBackground: '<'
        },
        controller: ['$scope', function ($scope) {

            $scope.cleanUrl = cleanUrl;

            function cleanUrl(url) {
                if (!url) {
                    return '';
                }
                url = url.replace('https://', '');
                url = url.replace('http://', '');
                url = url.replace('www.', '');
                url = url.replace(/^www\./i, '');
                url = url.replace(/\/$/i, '');
                return url.trim();
            }
        }]
    });
});

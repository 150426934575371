'use strict';
define(['app','xlsx'], function (app,XLSX) {
    app.component('stExportSelect', {
        templateUrl: 'app/components/st-export-select/st-export-select.html',
        controller: controller,
        bindings: {
            options: "="
        }
    });

    controller.$inject = ['$scope', 'ExportService', 'TrackEventService'];

    function controller($scope, ExportService, TrackEventService) {
        var ctrl = this;

        $scope.exportFormat = null;

        $scope.exportFormats = [{
                id: 'csv',
                label: 'Export CSV'
            },

            {
                id: 'xlsx',
                label: 'Export XLSX'
            }
        ];

        $scope.$watch('$ctrl.options', function (options) {
            if (!options) {
                return;
            }
            ctrl.options.stoTool = options.stoTool;
            ctrl.options.class = options.class;
            ctrl.options.icon = options.icon;
            ctrl.options.text = options.text;
            ctrl.options.data = options.data;
            ctrl.options.limit = options.limit;
            ctrl.options.fileName = options.fileName;
        });

        $scope.click = function(ev) {



            var exportSelect = document.getElementById("export-select");
            if (!$scope.$$phase) {
                ev.stopPropagation();
            ev.preventDefault();
                exportSelect.click();
            }

        }

        ctrl.export = function (rows, limit, fileName) {
            if (!$scope.exportFormat) {
                return;
            }


            if ($scope.exportFormat == 'csv') {
                ctrl.exportCsv(rows, limit, fileName)
            } else if ($scope.exportFormat == 'xlsx') {

                ctrl.exportXlsx(rows, limit, fileName)
            }

            $scope.exportFormat = null;

        }


        ctrl.exportCsv = function (rows, limit, fileName) {

            ExportService.checkExportAvailability()
                .then(function () {
                    var csvContent = "";
                    var counter = 0;

                    if (!limit) {
                        limit = rows.length - 1;
                    }

                    rows.forEach(function (rowArray) {
                        if (counter < limit) {
                            var row = rowArray.join(",");
                            csvContent += row + "\r\n";
                            counter++;
                        }
                    });

                    var blob = new Blob(["\ufeff", csvContent]);
                    var encodedUri = URL.createObjectURL(blob);

                    //var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", fileName + ".csv");

                    document.body.appendChild(link);
                    link.click();

                    switch (ctrl.options.stoTool) {
                        case "keyword-explorer":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword Explorer');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword Explorer",
                                    type: "csv"
                                });
                            break;
                        case "serp-report":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'SERP Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "SERP Checker",
                                    type: "csv"
                                });
                            break;
                        case "keyword-list-manager":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword List Manager');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword List Manager",
                                    type: "csv"
                                });
                            break;
                        case "rank-tracker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Rank Tracker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Rank Tracker",
                                    type: "csv"
                                });
                            break;
                        case "backlink-checker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Backlink Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Backlink Checker",
                                    type: "csv"
                                });
                            break;
                        case "lead-generation-tool":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Lead Generation Tool');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Lead Generstion Tool",
                                    type: "csv"
                                });
                            break;
                        default :
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX');
                            TrackEventService.gaV2Event(
                                'export',{
                                    type: "csv"
                                });
                            break;
                    }

                    $scope.exportFormat = null;
                })
                .catch(function (err) {
                    console.log(err);
                });
        };
        ctrl.exportXlsx = function (rows, limit, fileName) {
            ExportService.checkExportAvailability()
                .then(function () {
                    if (!limit) {
                        limit = rows.length;
                    }

                    var data = [];
                    rows.forEach(function (rowArray, index) {
                        if (index < limit) {
                            data.push(rowArray);
                        }
                    });

                    /* Creazione del workbook */
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(data);
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                    /* Scrittura del file xlsx */
                    XLSX.writeFile(wb, fileName + ".xlsx");

                    switch (ctrl.options.stoTool) {
                        case "keyword-explorer":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword Explorer');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword Explorer",
                                    type: "xlsx"
                                });
                            break;
                        case "serp-report":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'SERP Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "SERP Checker",
                                    type: "xlsx"
                                });
                            break;
                        case "keyword-list-manager":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword List Manager');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword List Manager",
                                    type: "xlsx"
                                });
                            break;
                        case "rank-tracker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Rank Tracker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Rank Tracker",
                                    type: "xlsx"
                                });
                            break;
                        case "backlink-checker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Backlink Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Backlink Checker",
                                    type: "xlsx"
                                });
                            break;
                        case "lead-generation-tool":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Lead Generation Tool');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Lead Generation Tool",
                                    type: "xlsx"
                                });
                            break;

                        default:
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX');
                            TrackEventService.gaV2Event(
                                'export',{
                                    type: "xlsx"
                                });
                            break;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        };
    }
});

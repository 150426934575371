define([
    'angular',
    'modules/core/core.module',
    'modules/access/access.module',
    'modules/upgrade/upgrade.module',
    'modules/template/template.module',
    'modules/dashboard/dashboard.module',
    'modules/seochecker/seochecker.module',
    'modules/seospider/seospider.module',
    'modules/pitchground/pitchground.module',
    //'modules/deals/deals.module',
    //'modules/seoeditor/seoeditor.module',
    'src/app/modules/writerassistant/writerassistant.module',
    'modules/keywordexplorer/keywordexplorer.module',
    'modules/keywordlistmanager/keywordlistmanager.module',
    'modules/keywordtracker/keywordtracker.module',
    'modules/project/project.module',
    'modules/leadgeneration/leadgeneration.module',
    //'modules/customer-offer/customer-offer.module',
    'modules/copymetrics/copymetrics.module',
    'modules/settings/settings.module',
    'modules/serpchecker/serpchecker.module',
    'modules/searchconsole/searchconsole.module',
    'modules/backlink-checker/backlink-checker.module',
    'modules/uisto/uisto.module',
], function (angular) {
    const __env = window.__env;

    function logWelcomeInConsole(__env) {
        console.log('' +
            '  ________  ___________  ______    \n' +
            ' /"       )("     _   ")/    " \\   \n' +
            '(:   \\___/  )__/  \\\\__/// ____  \\  \n' +
            ' \\___  \\       \\\\_ /  /  /    ) :) \n' +
            '  __/  \\\\      |.  | (: (____/ //  \n' +
            ' /" \\   :)     \\:  |  \\        /   \n' +
            '(_______/       \\__|   \\"_____/    \n');
        console.log('Welcome to SEO Tester Online ' + __env.stage + ' console!');
    }

    logWelcomeInConsole(__env);

    var app = angular.module('app', [
        'app.core',
        'app.uisto',
        'app.template',
        'app.dashboard',
        //'deals',
        //'app.seoeditor',
        'app.writerassistant',
        'app.serpchecker',
        'app.searchconsole',
        'app.seochecker',
        'app.seospider',
        'app.keywordexplorer',
        'app.keywordlistmanager',
        'app.keywordtracker',
        'app.project',
        'app.leadgeneration',
        //'customer-offer',
        'app.copymetrics',
        'app.settings',
        'app.backlinkchecker',
        'pitchground',
        'access',
        'upgrade',
    ]);

    app.constant('__env', __env);

    app.factory('Cache', ['$cacheFactory', function ($cacheFactory) {
        return $cacheFactory('Cache', {
            capacity: 100
        });
    }]);

    app.run(['$state', '$rootScope', 'AuthService', 'UserService', 'TrackEventService', '$timeout', 'LanguageService', 'UsetifulService', '__env',
        function ($state, $rootScope, AuthService, UserService, TrackEventService, $timeout, LanguageService, UsetifulService, __env) {

            var isUsetifullInitialized = false;

            function isLocalhost() {
                const hostname = window.location.hostname;
                return hostname === 'localhost' || hostname === '127.0.0.1';
            }

            function initUserOnUsetifull(data) {
                if (isUsetifullInitialized) {
                    return;
                }

                isUsetifullInitialized = true;
                if (isLocalhost() || __env.stage === 'local' || __env.stage === 'development') {
                    return;
                }

                var userId = null;
                var user = AuthService.getSessionUser();
                if (user && user.id) {
                    userId = user.id;
                }

                /**
                 * USETIFUL RELOAD
                 * EVERY TIMES CHANGE SCOPE
                 * ADDED FORCE RELOAD TO SIGN-IN IN PATH:
                 * frontend\src\app\components\access-components\st-signin
                 *
                 * @type {string}
                 */
                var language = LanguageService.getLanguageCodeSaveOnStorage();
                var account = 'offline';
                var canDoTrial = true;
                var isTrial = false;

                if (data) {
                    if (data.account) {
                        account = data.account;
                    }
                    if (data.canDoTrial) {
                        canDoTrial = data.canDoTrial;
                    }
                    if (data.isTrial) {
                        isTrial = data.isTrial;
                    }
                }
                UsetifulService.start(userId, language, account, canDoTrial, isTrial);
            }

            function pushUserOnTrackingSystem() {
                var user = AuthService.getSessionUser();
                if (!user) {
                    return;
                }

                AuthService
                    .me()
                    .then(function (user) {
                        if (!user) {
                            return;
                        }

                        AuthService.setSessionUser(user);
                        TrackEventService.push({
                            'userId': user.uuid
                        });
                        TrackEventService.push({
                            'email': user.email
                        });

                        if (user.jobTitle) {
                            TrackEventService.push({
                                'jobTitle': user.jobTitle
                            });
                        }

                        if (user.businessType) {
                            TrackEventService.push({
                                'businessType': user.businessType
                            });
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }

            var host = window.location.hostname || 'suite.seotesteronline.com';
            var pathname = window.location.pathname || '';
            var vm = $rootScope;

            if (host.indexOf('seotesteronline') >= 0 && window.location.protocol === 'http:') {
                window.location = 'https:' + host + pathname;
            }

            $rootScope.session = {};
            $rootScope.isLoggedIn = {};

            $rootScope.$on('$stateChangeStart',
                function (event, next, toParams, fromState) {
                    vm.isAuth = AuthService.isAuthenticated();

                    if (next.name == "upgrade.choose-plan") {
                        $timeout(function () {
                            vm.bodyClassModifier = 'choosePlanPage';
                        }, 500);
                    } else {
                        vm.bodyClassModifier = null;
                    }

                    if (AuthService.isAuthenticated()) {
                        pushUserOnTrackingSystem();
                        UserService.limits(true)
                            .then(function (res) {
                                if (res && res.data && res.data.workspace && res.data.workspace.id) {
                                    TrackEventService.push({
                                        'workspace': res.data.workspace.id
                                    });
                                }

                                initUserOnUsetifull(res.data);
                                var subscriptions = res.data.subscriptions || [];
                                var subscriptionsActive = [];
                                var subscriptionsTrial = [];
                                vm.subscriptionActive = false;
                                vm.subscriptionTrial = false;
                                subscriptions.forEach(function (subscription) {
                                    if (subscription.status === 'active') {
                                        subscriptionsActive.push(subscription);
                                    }
                                    if (subscription.status === 'trialing') {
                                        subscriptionsTrial.push(subscription);
                                    }
                                });

                                if (subscriptionsActive.length) {
                                    vm.subscriptionActive = true;

                                    if (res && res.data && res.data.mostImportantSub && res.data.mostImportantSub.alias) {
                                        TrackEventService.push({
                                            'accountType': res.data.mostImportantSub.alias
                                        });
                                        TrackEventService.push({
                                            'accountState': res.data.mostImportantSub.alias
                                        });
                                    }
                                    subscriptionsTrial = [];
                                } else if (subscriptionsTrial.length) {
                                    vm.subscriptionTrial = true;

                                    if (subscriptionsTrial &&
                                        subscriptionsTrial.length > 0 &&
                                        subscriptionsTrial[subscriptionsTrial.length - 1] &&
                                        subscriptionsTrial[subscriptionsTrial.length - 1].plans &&
                                        subscriptionsTrial[subscriptionsTrial.length - 1].plans[0] &&
                                        subscriptionsTrial[subscriptionsTrial.length - 1].plans[0].alias
                                    ) {
                                        TrackEventService.push({
                                            'accountType': subscriptionsTrial[subscriptionsTrial.length - 1].plans[0].alias + '-trial'
                                        });
                                        TrackEventService.push({
                                            'accountState': subscriptionsTrial[subscriptionsTrial.length - 1].plans[0].alias + '-trial'
                                        });
                                    }
                                } else {
                                    TrackEventService.push({
                                        'accountType': 'free'
                                    });
                                    TrackEventService.push({
                                        'accountState': 'free' + (res.data.canDoTrial ? '' : '-already-tried')
                                    });
                                    vm.isTrialing = res.data.canDoTrial;
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    } else {
                        initUserOnUsetifull();
                        vm.isTrialing = true;
                        TrackEventService.push({
                            'accountType': 'not-logged-in'
                        });
                        TrackEventService.push({
                            'accountState': 'not-logged-in'
                        });
                    }

                    if (AuthService.isAuthenticated()) {
                        UserService.getSuggestedPlans(true);
                    }

                    $rootScope.previousState = fromState.name;
                    $rootScope.previousParams = $rootScope.toParams;

                    $rootScope.toParams = toParams;
                    $rootScope.currentState = next.name;

                    if (next.redirectTo) {
                        $state.go(next.redirectTo, toParams);
                    }

                    $rootScope.$broadcast('onRoutingChange');

                    if (next.access && next.access.restricted && !AuthService.isAuthenticated()) {
                        event.preventDefault();
                        $state.go('access.signin');
                    }
                });
        }
    ]);

    //
    app.controller('AppController', ['$rootScope', 'CheckoutService', 'AuthService', '$state', '$scope', '$translate',
        '$cookies', '$location', '$timeout', 'SocketFactory', 'stAlertLimitService', 'BetaProgramService', 'LanguageService', '__env',
        function ($rootScope, CheckoutService, AuthService, $state, $scope, $translate, $cookies,
                  $location, $timeout, SocketFactory, stAlertLimitService, BetaProgramService, LanguageService, __env) {

            /**
             * Start Socket Connection
             */
            function initSocket() {
                console.log('Init Socket');
                var user = AuthService.getSessionUser();

                if (user && user.id) {
                    if (user.fromAdmin || __env.stage === 'local' || __env.stage === 'development') {
                        return;
                    }

                    SocketFactory.on('connect', function () {
                        console.log(' socket connected');
                        SocketFactory.emit('multi-session-login', {
                            userId: user.id,
                            session: AuthService.getSessionToken(),
                        });
                    });

                    var multiSessionEvent = false;
                    SocketFactory.on('multi-session-too-many-login', function () {
                        if (multiSessionEvent || user.fromAdmin || __env.stage === 'local' || __env.stage === 'development') {
                            return;
                        }

                        multiSessionEvent = true;
                        stAlertLimitService.open('auth-login', {
                            error: {
                                request: {
                                    alias: 'max-simultaneous-login',
                                }
                            },
                            canClose: false
                        })
                            .then(function () {
                                AuthService.logout();
                                $state.go('access.signin');
                            })

                        $timeout(function () {
                            AuthService.logout();
                        }, 500);

                        $timeout(function () {
                            multiSessionEvent = false;
                        }, 2000);
                    });
                }

                SocketFactory.on('search-result', function (data) {
                    $rootScope.$broadcast("socketSearchResult", data);
                });

                SocketFactory.on('gsc-keywords', function (data) {
                    $rootScope.$broadcast("socketGscKeywords", data);
                });
            }

            $rootScope.allLanguages = $translate.getAvailableLanguageKeys();

            $rootScope.setLang = function (langKey) {
                $scope.lang = langKey;
                if (!langKey) {
                    return;
                }

                LanguageService.setCodeAndCountryOnStorage(langKey);

                $translate.use(langKey)
                    .then(function () {
                        $rootScope.$broadcast('languageChanged', langKey);
                    });
            };

            var storeferral = $location.search().storeferral;
            if (storeferral) {
                $cookies.put('storeferral', storeferral, {
                    domain: 'seotesteronline.com',
                    expires: new Date(new Date().setDate(new Date().getDate() + 60))
                });
            }

            if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('127.0.0.1')) {
                initSocket();
            }

            $scope.$state = $state;
            $scope.source = $state.current.name.replace('app.', '')
                .replace(/\..*/gi, '')
                .toLowerCase();

            $scope.$watchCollection('$state', function () {
                if ($state.current.name) {
                    $scope.source = $state.current.name.replace('app.', '')
                        .replace(/\..*/gi, '')
                        .toLowerCase();
                } else {
                    $scope.source = 'not-specified';
                }
            });

            $translate('currentLangCode')
                .then(function (lang) {
                    $scope.lang = lang;
                });
        }
    ]);

    app.config(['FacebookProvider', function (FacebookProvider) {
        // Set your appId through the setAppId method or
        // use the shortcut in the initialize method directly.
        FacebookProvider.setLocale('it_IT');
        FacebookProvider.init('457790181098375');
    }]);

    // Config Module Translate & Partial Loader
    app.config(['$translateProvider', '$translatePartialLoaderProvider', '__env',
        function ($translateProvider, $translatePartialLoaderProvider, __env) {
            try {
                $translateProvider.useLoader('$translatePartialLoader', {
                    urlTemplate: './app/locales/{lang}/{part}.json'
                });

                // Add all File Json we need
                var locales = __env.locales.split(",");
                for (var i = 0; i < locales.length; i++) {
                    $translatePartialLoaderProvider.addPart(locales[i]);
                }

                // Register all languages we hase set on env
                var languages = __env.languages.split(",");
                $translateProvider.registerAvailableLanguageKeys(languages);

                // Check if a value exists in the fruits array
                if (languages.indexOf(navigator.language) !== -1) {
                    // Language Browser Exists!
                    $translateProvider.preferredLanguage(navigator.language);
                } else {
                    // Language Browser Not Exists!
                    $translateProvider.preferredLanguage(__env.preferredLanguage);
                }

                // FallBack: in case don't exist language set it for Strange Casuistry
                $translateProvider.fallbackLanguage(__env.preferredLanguage);

                // Secure strategy
                $translateProvider.useSanitizeValueStrategy('escapeParameters');
                $translateProvider.forceAsyncReload(true);

                // Save language code in LocalStorage
                // and if it enable into Cookie
                $translateProvider.useLocalStorage();
            } catch (e) {
                console.log("Error during locale initializiation");
                console.log(e);
            }
        }
    ]);

    app.config(['$compileProvider', function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    }]);

    return app;
});

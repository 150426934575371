define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seochecker", {
                abstract: true,
                controller: 'SEOCheckerCtrl',
                templateUrl: './app/modules/seochecker/seochecker.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state('app.seochecker.test-translations', {
                url: '/seo-checker/test-translations',
                controller: 'SCTranslationsCtrl',
                templateUrl: './app/modules/seochecker/translations/translations.html',
                resolve: {
                    translations: ['SeocheckerService', function (SeocheckerService) {
                        return SeocheckerService.testTranslations()
                            .then(function (res) {
                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                }
            })
            .state("app.seochecker.landing", {
                url: "/seo-checker",
                controller: 'SCLandingCtrl',
                templateUrl: './app/modules/seochecker/landing/landing.html'
            })
            .state("app.seochecker.results", {
                url: "/seo-checker/:url?open-dialog",
                controller: 'SEOCheckerResultsCtrl',
                templateUrl: './app/modules/seochecker/results/results.html',
                //access:{restricted:true},
                params: {
                    'open-dialog': { squash: true },
                },
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                    isValidUrl: ['$stateParams', '$location', function ($stateParams, $location) {
                        try {
                            var searchText = atob(decodeURIComponent($stateParams.url));
                            var correct_url = new RegExp(/^((http:\/\/|https:\/\/)?(www\.|^\.)?((([\-|\wæÆØøÅå|\d]+)\.)+([a-zA-ZæÆØøÅå]{1,10}))(\/?(([a-zA-ZZæÆØøÅå0-9_@]+\.([a-zA-ZZæÆØøÅå0-9]{3,6}))?(\??[-A-Za-zæÆØøÅå0-9+:&@#\/%=~'_\.?|]+))){0,1})$/gim);
                            var isValidSearch = (
                                searchText != undefined &&
                                searchText.length != 0 &&
                                searchText.match(correct_url)
                            );
                            if (!isValidSearch) {
                                $location.url('/seo-checker/');
                            }
                        } catch (err) {
                            $location.url('/seo-checker/');
                        }
                    }]
                }
            })
            .state("app.seochecker.results.structure", {
                url: "/base",
                controller: 'SEOCheckerStructureController',
                templateUrl: './app/modules/seochecker/results/structure/structure.html'
            })
            .state("app.seochecker.results.content", {
                url: "/content",
                controller: 'SEOCheckerContentController',
                templateUrl: './app/modules/seochecker/results/content/content.html'
            })
            .state("app.seochecker.results.overview", {
                url: "/",
                controller: 'SEOCheckerOverviewController',
                templateUrl: './app/modules/seochecker/results/overview/overview.html'
            })
            .state("app.seochecker.results.social", {
                url: "/social",
                controller: 'SEOCheckerSpeedCtrl',
                templateUrl: './app/modules/seochecker/results/social/social.html',
            })
            .state("app.seochecker.results.speed", {
                url: "/speed",
                controller: 'SEOCheckerSpeedCtrl',
                templateUrl: './app/modules/seochecker/results/speed/speed.html',
                params: {
                    showTab: {
                        value: null,
                        squash: true
                    }
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define([], function () {
    function Controller(
        $scope,
        $filter,
        Countries,
        $stateParams,
        $state,
        keywordList,
        KeywordListService,
        $mdToast,
        $translate,
        $mdConstant,
        TrackEventService
    ) {
        $scope.countriesLimit = 36;
        $scope.countriesSelected = [];
        $scope.countriesCount = $scope.countriesSelected.length || 1;
        $scope.countries = loadCountries();

        $scope.keywordsLimit = 1000;
        $scope.loading = {
            post: false
        };

        $scope.keywords = [];
        $scope.keys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA];
        $scope.keywordsAlreadyOnList = countQueries(keywordList.queries);
        $scope.keywordsCount = $scope.keywordsAlreadyOnList + ($scope.keywords.length * $scope.countriesCount);
        $scope.keywordsLeftOver = $scope.keywordsLimit - $scope.keywordsCount;
        $scope.keywordList = keywordList;

        $scope.onImport = function (importedKeywords) {
            if (!importedKeywords.length) {
                showCustomToast('File CSV is empty', 'icon-x st-red');
                return;
            }

            for (var i = 0; i < importedKeywords.length; i++) {
                addKeywordWithoutFormat(importedKeywords[i]);
            }

            updateKeywords();
        };

        $scope.formatKeywords = function (keywords) {
            var splittedKeywords = keywords.split(',');
            var lastKeywords = keywords;

            if (splittedKeywords.length <= 1) {
                return formatKeyword(keywords);
            }

            angular.forEach(splittedKeywords, function (splittedKeyword) {
                lastKeywords = splittedKeyword;
                addKeyword(splittedKeyword);
            });

            updateKeywords();

            return null;
        };

        $scope.$watchCollection('keywords', function () {
            updateKeywords();
        });

        var countKeyword = function () {
            $scope.keywordsCount = $scope.keywordsAlreadyOnList + ($scope.keywords.length * $scope.countriesCount);
            $scope.keywordsLeftOver = $scope.keywordsLimit - $scope.keywordsCount;

            return ($scope.keywords.length * $scope.countriesCount) + $scope.keywordsAlreadyOnList;
        };

        var formatKeyword = function (keyword) {
            keyword = keyword.replace(/[!"#\$%&'\(\)\*\+,\/:;<=>\?@\[\]\^_`{\|}~£∞·»”’¢‰›⁄¿≈⁄›¢ØŒÆ‚¯˘˚¸˝˛ˇ≥‡°¶§]/gi, '');
            keyword = keyword.trim();

            var keywordWithoutDots = keyword.replace(/\./gi, '');
            return keyword && keyword !== '' && keywordWithoutDots !== '' ? keyword : null;
        };

        var addKeywordWithoutFormat = function (keyword) {
            if (countKeyword() > $scope.keywordsLimit) {
                return;
            }

            if (keyword && keyword !== '' && $scope.keywords.indexOf(keyword) < 0) {
                $scope.keywords.push(keyword);
            }
        };

        var addKeyword = function (keyword) {
            keyword = formatKeyword(keyword);

            if (countKeyword() > $scope.keywordsLimit) {
                return;
            }

            if (keyword && keyword !== '' && $scope.keywords.indexOf(keyword) < 0) {
                $scope.keywords.push(keyword);
            }
        };

        var updateKeywords = function () {
            // Workaround to fix an issue on md-chip. DON'T REMOVE
            setTimeout(function () {
                angular.element('textarea.md-caption').click();
            }, 200);

            if (countKeyword() <= $scope.keywordsLimit) {
                return;
            }

            $scope.keywords = $scope.keywords.splice(0, $scope.keywordsLimit);
        };

        $scope.$watchCollection('countriesSelected', function () {
            $scope.countriesCount = $scope.countriesSelected.length || 1;
            $scope.keywordsCount = $scope.keywordsAlreadyOnList + ($scope.keywords.length * $scope.countriesCount);
            $scope.keywordsLeftOver = $scope.keywordsLimit - $scope.keywordsCount;
        });

        var stepKeys = {
            'choose-keywords': 1,
            'choose-countries': 2
        };

        $scope.step = stepKeys[$stateParams.step] ? stepKeys[$stateParams.step] : 1;
        if ($scope.step > 1 && $scope.keywords.length === 0) {
            $state.go('app.keywordlistmanager.wizard', {
                keywordListId: $stateParams.keywordListId,
                step: 'choose-keywords'
            });
            return;
        }

        $scope.selectedItem = null;
        $scope.searchText = null;
        $scope.csvImportFile = [];

        $scope.setStep = function (step) {
            if (step === 0) {
                $state.go('app.keywordlistmanager.keywordlist', {
                    keywordListId: $scope.keywordList.keywordListId
                }, );
                return;
            }

            if (step === 2 && $scope.keywords.length === 0) {
                showCustomToast('keyword.list.wizard.error.keywords', 'icon-x st-red');
                return;
            }

            if (step === 3) {
                if ($scope.countriesSelected.length === 0) {
                    showCustomToast('keyword.list.wizard.error.country', 'icon-x st-red');
                    return;
                }

                if (countKeyword() > $scope.keywordsLimit) {
                    showCustomToast('keyword.list.wizard.error.toomanykeywords', 'icon-x st-red');
                    return;
                }

                $scope.loading.post = true;
                var countryCodes = getCodesFromSelectedCountries($scope.countriesSelected);
                KeywordListService.addKeywords($stateParams.keywordListId, $scope.keywords, countryCodes)
                    .then(function () {
                        TrackEventService.event(
                            'Suite Usages',
                            'Keyword List Manager - Keyword Added');
                        TrackEventService.gaV2Event(
                            'keyword_add', {
                                tool: "Keyword List Manager"
                            });
                        $state.go(
                            'app.keywordlistmanager.keywordlist', {
                                keywordListId: $stateParams.keywordListId
                            },
                        );
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
                return;
            }

            $scope.step = step;
            updateUrlParams(step);
        };

        $scope.querySearch = function (criteria) {
            return criteria ? $scope.countries.filter(createFilterFor(criteria)) : [];
        };

        function updateUrlParams(step) {
            var params = {
                keywordListId: $stateParams.keywordListId,
                step: getKeyByValue(stepKeys, step)
            };
            $state.transitionTo('app.keywordlistmanager.wizard', params, {
                notify: false
            });
            $stateParams = params;
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function getKeyByValue(object, value) {
            return Object.keys(object).find(function (key) {
                return object[key] === value;
            });
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();
            return function filterFn(country) {
                return (country.name.toLowerCase().indexOf(lowercaseQuery) !== -1);
            };
        }

        function loadCountries() {
            var countries = Countries.all();
            return countries.map(function (c) {
                return {
                    name: c.name,
                    code: c.code,
                };
            });
        }

        function countQueries(queries) {
            if (queries && queries.rows && typeof queries.rows === 'object') {
                return queries.rows.length;
            }
            return 0;
        }

        function getCodesFromSelectedCountries(selectedCountries) {
            var codes = [];
            selectedCountries.forEach(function (selectedCountry) {
                if (!selectedCountry || !selectedCountry.code) {
                    return;
                }
                codes.push(selectedCountry.code);
            });

            return codes;
        }
    }

    Controller.$inject = [
        '$scope',
        '$filter',
        'CountriesService',
        '$stateParams',
        '$state',
        'keywordList',
        'KeywordListService',
        '$mdToast',
        '$translate',
        '$mdConstant',
        'TrackEventService'
    ];
    return Controller;
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog, WorkspaceService, $localStorage, $mdToast, $translate, TrackEventService) {
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;
        $scope.invitationSent = invitationSent;
        var permissions = $localStorage.currentUser.workspace.permissions;
        $scope.roles = [{
                id: 2,
                label: 'Admin',
                permissionAvailable: 'workspace_user_admin',
                permission: $translate.instant('settings.dialogs.role.admin.subtitle'),
                title: $translate.instant('settings.dialogs.role.admin.title'),
                listTitle: $translate.instant('settings.dialogs.role.admin.list.title'),
                description: $translate.instant('settings.dialogs.role.admin.description')
            },
            {
                id: 3,
                label: 'Manager',
                permissionAvailable: 'workspace_user_manager',
                permission: $translate.instant('settings.dialogs.role.manager.subtitle'),
                title: $translate.instant('settings.dialogs.role.manager.title'),
                listTitle: $translate.instant('settings.dialogs.role.manager.list.title'),
                description: $translate.instant('settings.dialogs.role.manager.description')
            },
            {
                id: 4,
                label: 'Bookkeeper',
                permissionAvailable: 'workspace_user_bookkeeper',
                permission: $translate.instant('settings.dialogs.role.bookkeeper.subtitle'),
                title: $translate.instant('settings.dialogs.role.bookkeeper.title'),
                listTitle: $translate.instant('settings.dialogs.role.bookkeeper.list.title'),
                description: $translate.instant('settings.dialogs.role.bookkeeper.description')
            },
            {
                id: 5,
                label: 'Marketer',
                permissionAvailable: 'workspace_user_marketer',
                permission: $translate.instant('settings.dialogs.role.marketer.subtitle'),
                title: $translate.instant('settings.dialogs.role.marketer.title'),
                listTitle: $translate.instant('settings.dialogs.role.marketer.list.title'),
                description: $translate.instant('settings.dialogs.role.marketer.description')
            },
        ]
        $scope.user = {
            role: getRole(),
            email: ''
        };
        $scope.selectedRole = 5;

        function answerDialog() {

            WorkspaceService.sendInvitations({
                workspaceId: $localStorage.currentUser.workspace.id,
                email: $scope.user.email,
                roleId: $scope.user.role.id,
            }).then(function (res) {
                if (res && res.data && res.data.message === 'success') {
                    TrackEventService.event('Workspace', 'Invite Member');
                    TrackEventService.gaV2Event('invite_member');
                    invitationSent();
                    $mdDialog.hide();
                }
            }).catch(function (err) {
                if (err.data.error.code === 'USER_ALREADY_INVITED') {
                    showCustomToast(
                        $translate.instant('settings.dialogs.user.already.invited'),
                        'icon-x st-err',
                        ''
                    )
                }
                if (err.status === 426) {
                    showCustomToast(
                        $translate.instant('settings.dialogs.out.of.credits'),
                        'icon-x st-err',
                        '<a class="md-body-1 md-primary txt-decoration-und m-l-xs" ' +
                        '   ui-sref="upgrade.choose-plan">'
                        + $translate.instant('settings.dialogs.make.upgrade')
                        + '</a>'
                    )
                }
            })
        }

        function showCustomToast(string, classIcon, templateCallToAction) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + string + '&nbsp;</div>' +
                    '' + templateCallToAction + '' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function getRole(roleName) {

            if (!roleName) {
                return setDefaultRoleForPermission();
            }

            var roleId = '';
            angular.forEach($scope.roles, function (role) {
                if (role.label.toLowerCase() == roleName) {
                    roleId = role;
                }
            });
            return roleId;
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
        $scope.setRole = function (role) {
            $scope.user.role = role;
        }

        function setDefaultRoleForPermission() {
            return $scope.roles[$scope.roles.length-1];
            /*
            var role = {};
            if (!permissions && $localStorage.currentUser.workspace.roleName === 'owner') {
                role = $scope.roles[0];
            }
            if (permissions && permissions.length) {

                if (permissions.indexOf('workspace_user_marketer') > -1) {
                    role = $scope.roles[3];
                }
                if (permissions.indexOf('workspace_user_bookkeeper') > -1) {
                    role = $scope.roles[2];
                }
                if (permissions.indexOf('workspace_user_manager') > -1) {
                    role = $scope.roles[1];
                }
                if (permissions.indexOf('workspace_user_admin') > -1) {
                    role = $scope.roles[0];
                }
            }
            return role;
            */
        }

        function invitationSent(ev) {
            $mdDialog.show({
                templateUrl: "app/modules/settings/dialogs/invitation-sent/invitation-sent.html",
                controller: "InvitationSentCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                locals: {
                    user: $scope.user
                },
                clickOutsideToClose: true,
            }).then(function () {
            });
        }

    }
    Controller.$inject = ['$scope', '$mdDialog', 'WorkspaceService', '$localStorage', '$mdToast', '$translate', 'TrackEventService'];
    return Controller;
});

'use strict';
define(['angular'],function(ng){
    var defaultLimit=100;
    return {
        templateUrl: 'app/components/arrow-pagination/arrow-pagination.html',
        bindings: {
            offset: '=',
            limit: '=',
            numElements: '=',
            nextEvent: '@nextEvent',
            backEvent: '@backEvent',
            onPaging: '&',
            reduceLayout: '&',
            onPagingScroll: '@onPagingScroll',
        },
        controller:['$scope','$window','$q',function($scope,$window,$q){
            var vm=this;
            if(vm.offset==undefined)
                vm.offset=0;
            else vm.offset=parseInt(vm.offset);
            if(vm.limit==undefined)
                vm.limit=defaultLimit;
            else vm.limit=parseInt(vm.limit);
            if(vm.numElements==undefined)
                vm.numElements=Infinity;
            else vm.numElements=parseInt(vm.numElements);
            vm.currentPage=1;
            vm.amountPages=1;
            vm.isNotLoading=true;
            vm.next={disable: false};
            vm.back={disable: true};
            vm.strong=function(txt){
                return '<b>'+txt+'</b>';
            };
            vm.backPage=backPage;
            vm.nextPage=nextPage;
            vm.skipPage=skipPage;
            vm.performPaging=performPaging;
            function checkBackDisable(){
                if(vm.offset<0)vm.offset=0;
                if(vm.offset<vm.limit)
                    vm.back.disable=true;
                else vm.back.disable=false;
            }
            function checkNextDisable(){
                var tmpSum=vm.offset+vm.limit;
                if(tmpSum>=vm.numElements){
                    vm.next.disable=true;
                }else vm.next.disable=false;
            }
            function checkForDisabledBtn(){
                checkBackDisable();
                checkNextDisable();
            }
            function scrollTop(){
                if(vm.onPagingScroll)
                    ng.element($window).scrollTop(vm.onPagingScroll);
            }
            function skipPage(){
                vm.offset=(vm.currentPage-1)*vm.limit;
                vm.performPaging().then(function(){
                    vm.isNotLoading=true;
                    updatePagingNumbers();
                    checkForDisabledBtn();
                    scrollTop();
                });
            }
            function backPage(){
                if(!vm.back.disable&&vm.isNotLoading){
                    vm.isNotLoading=false;
                    if(vm.offset-vm.limit<=0)
                        vm.offset=0;
                    else vm.offset-=vm.limit;
                    checkForDisabledBtn();
                    vm.performPaging().then(function(){
                        vm.isNotLoading=true;
                        updatePagingNumbers();
                        checkForDisabledBtn();
                        scrollTop();
                    });
                }
            }
            function nextPage(){
                if(!vm.next.disable&&vm.isNotLoading) {
                    vm.isNotLoading=false;
                    vm.offset += vm.limit;
                    checkForDisabledBtn();
                    vm.performPaging().then(function(){
                        vm.isNotLoading=true;
                        updatePagingNumbers();
                        checkForDisabledBtn();
                        scrollTop();
                    });
                }
            }
            function updatePagingNumbers(){
                if(vm.numElements==0){
                    vm.amountPages=0;
                    vm.numElements=0;
                }
                else{
                    vm.currentPage=(vm.offset/vm.limit)+1;
                    vm.amountPages=Math.ceil(vm.numElements/vm.limit);
                }
            }
            function performPaging(){
                var dfd = $q.defer();
                $q.when([
                    vm.onPaging({
                        limit:vm.limit,
                        offset:vm.offset
                    })
                ]).then(function(){
                    dfd.resolve();
                });
                return dfd.promise;
            }
            $scope.$watch('$ctrl.onPagingScroll',function(onPagingScroll){
                vm.onPagingScroll=parseInt(onPagingScroll)||null;
            });
            $scope.$watch('$ctrl.onPaging',function(onPaging){
                vm.onPaging=onPaging||function(p){};
            });
            $scope.$watch('$ctrl.limit',function(limit){
                vm.limit=parseInt(limit);
                updatePagingNumbers();
            });
            $scope.$watch('$ctrl.offset',function(offset){
                vm.offset=parseInt(offset);
                updatePagingNumbers();
            });
            $scope.$watch('$ctrl.numElements',function(numElements){
                vm.numElements=parseInt(numElements);
                checkForDisabledBtn();
                updatePagingNumbers();
            });
        }]
    };
});

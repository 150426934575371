define(['angular','app'],function(angular,app) {
    function padTime(t) {
        return t < 10 ? "0"+t : t;
    }
    app.filter('secondsToTime', [function() {
        return function(_seconds) {
            if (typeof _seconds !== "number" || _seconds < 0)
                return "00:00:00";

            var hours = Math.floor(_seconds / 3600),
                minutes = Math.floor((_seconds % 3600) / 60),
                seconds = Math.floor(_seconds % 60);

            return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
        };
    }]);
    app.filter('secondsToMinutesTime', [function() {
        return function(_seconds) {
            if (typeof _seconds !== "number" || _seconds < 0)
                return "00:00:00";

            var hours = Math.floor(_seconds / 3600),
                minutes = Math.floor((_seconds % 3600) / 60),
                seconds = Math.floor(_seconds % 60);
            minutes+=hours*60;
            return minutes + ":" + padTime(seconds);
        };
    }]);
});

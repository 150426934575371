'use strict';
define([], function () {
    controller.$inject = ['$scope'];

    function controller($scope) {
        var ctrl = this;

        $scope.$watch('$ctrl.options', function (options) {
            if (options) {
                ctrl.options = options;
                $scope.input = options.template;
            }
        });

        $scope.$watch('$ctrl.selectedCountry', function (country) {
            ctrl.selectedCountry = country;
            if (country) {
                $scope.$emit("selectedCountry", JSON.stringify(country));
            }
        });
    }

    return {
        templateUrl: 'app/components/st-input/st-input.html',
        bindings: {
            options: "=",
            selectedCountry: "=",
            insideTabs: "<"
        },
        controller: controller
    };
});

'use strict';
define([], function () {
    function Controller($scope, $timeout,$mdDialog, workspace, $state, $rootScope, WorkspaceService, $localStorage) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.workspace = workspace;

        $timeout(function () {
            $mdDialog.hide();
            $state.reload();
            WorkspaceService.setWorkspace(workspace);
            $rootScope.$broadcast("switchWorkSpace", $localStorage.currentUser);
        }, 3000);

        function answerDialog(answer) {
            $scope.loading.post = true;


            $mdDialog.hide();
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject = ['$scope','$timeout', '$mdDialog', 'workspace', '$state', '$rootScope', 'WorkspaceService', '$localStorage'];
    return Controller;
});

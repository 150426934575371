'use strict';
define(['app'], function (app) {
    app.component('stUserInfo', {
        templateUrl: 'app/components/st-user-info/st-user-info.html',
        controller: controller,
        bindings: {
            title: '<',
            customClass: '=',
            onSuccess: '<',
            onError: '<',
        }
    });

    controller.$inject = [
        '$scope',
        '$q',
        'AuthService',
        'LanguageService',
        'ItalyService',
        'CountriesService',
        'UserService',
        '$mdMedia'
    ];

    function controller(
        $scope,
        $q,
        AuthService,
        LanguageService,
        ItalyService,
        CountriesService,
        UserService,
        $mdMedia
    ) {
        var DEFAULT_COUNTRY = LanguageService.getCountryCodeSaveOnStorage();

        var vm = $scope;
        var $this = this;
        vm.$mdMedia = $mdMedia;

        vm.patterns = {
            destinationCode: /[a-zA-Z0-9]{7}/,
            vat: /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i,
            email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        };
        vm.italy = ItalyService.all();
        vm.countries = CountriesService.allWorld();
        vm.loading = false;
        vm.isItalian = false;
        vm.isCompany = false;
        vm.user = null;

        var formatUser = function (user) {
            if (!user) {
                throw new Error('User not found');
            }

            return {
                billingName: user.billingName,
                billingFirstname: (user.billingFirstname || user.firstname),
                billingLastname: (user.billingLastname || user.lastname),
                billingBusinessName: user.billingBusinessName,
                billingVat: user.billingVat,
                billingCf: user.billingCf,
                billingIsEU: user.billingIsEU === 1,
                billingPhone: user.billingPhone,
                billingAddressCity: user.billingAddressCity,
                billingAddressLine1: user.billingAddressLine1,
                billingAddressLine2: user.billingAddressLine2,
                billingAddressState: user.billingAddressState,
                billingAddressZip: user.billingAddressZip,
                billingAddressCountry: (user.billingAddressCountry) || DEFAULT_COUNTRY,
                billingPec: user.billingPec,
                billingDestinationCode: user.billingDestinationCode,
            }
        }

        var checkIfIsCompany = function (user) {
            return !!(user && (user.billingBusinessName || user.billingVat));
        };

        var getUser = function () {
            var dfd = $q.defer();
            UserService.accountInfo()
                .then(function (res) {
                    dfd.resolve(res.data);
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        };

        vm.init = function () {
            if (!AuthService.isAuthenticated()) {
                if ($this.onError && $this.onError instanceof Function) {
                    $this.onError(new Error('User not found'));
                }
                return;
            }

            getUser()
                .then(function (user) {
                    vm.user = formatUser(user);
                    vm.isCompany = checkIfIsCompany(user);
                    vm.checkIfIsEu();
                    vm.checkIfIsItalian();
                })
                .catch(function (err) {
                    if ($this.onError && $this.onError instanceof Function) {
                        $this.onError(err);
                    }

                    console.log(err);
                })
        };

        vm.checkIfIsEu = function () {
            vm.user.billingIsEU = CountriesService.isEU(vm.user.billingAddressCountry);
        };

        vm.checkIfIsItalian = function () {
            vm.isItalian = vm.user.billingAddressCountry.toLowerCase() === 'it';
        };

        vm.resetCompanyBillingInfo = function () {
            vm.user.billingBusinessName = null;
            vm.user.billingVat = null;
            vm.user.billingPec = null;
            vm.user.billingDestinationCode = null;
        };

        vm.save = function () {
            if (!vm.billingForm.$valid) {
                return;
            }

            vm.loading = true;
            UserService.updateProfile(vm.user)
                .then(function (res) {
                    if ($this.onSuccess && $this.onSuccess instanceof Function) {
                        $this.onSuccess(res);
                    }

                    vm.loading = false;
                })
                .catch(function (err) {
                    if ($this.onError && $this.onError instanceof Function) {
                        $this.onError(err);
                    }

                    vm.loading = false;
                })
        }

        this.$onInit = function () {
            /* set default values of component */
            $this.onSuccess = $this.onSuccess || function () {
            };
            $this.onError = $this.onError || function () {
            };
        };
    }
});

'use strict';
define([
    'angular',
    'modules/upgrade/upgrade.routes',
    'modules/upgrade/components/paypal-button/paypal-button.component',
    'modules/upgrade/components/compare-plans/compare-plans.component',
    'modules/upgrade/components/what-people-says-carousel/what-people-says-carousel.component',
    'modules/upgrade/upgrade.controller',
    'modules/upgrade/choose-plan/choose-plan.controller',
    'modules/upgrade/checkout/checkout.controller',
    'modules/upgrade/thank-you/thank-you.controller',
    'modules/upgrade/payment-failed/payment-failed.controller',
    'modules/upgrade/unavailable/unavailable.controller',
], function (
    angular,
    routes,
    paypalButton,
    comparePlans,
    whatPeopleSaysCarousel,
    UpgradeCtrl,
    UpgradeChoosePlanCtrl,
    UpgradeCheckoutCtrl,
    UpgradeThankYouCtrl,
    UpgradePaymentFailedCtrl,
    UpgradeUnavailableCtrl,
) {
    var app = angular.module('upgrade', ['ngMessages']);
    app.component('paypalButton', paypalButton);
    app.component('comparePlans', comparePlans);
    app.component('whatPeopleSaysCarousel', whatPeopleSaysCarousel);

    app.controller('UpgradeCtrl', UpgradeCtrl);
    app.controller('UpgradeChoosePlanCtrl', UpgradeChoosePlanCtrl);
    app.controller('UpgradeCheckoutCtrl', UpgradeCheckoutCtrl);
    app.controller('UpgradeThankYouCtrl', UpgradeThankYouCtrl);
    app.controller('UpgradePaymentFailedCtrl', UpgradePaymentFailedCtrl);
    app.controller('UpgradeUnavailableCtrl', UpgradeUnavailableCtrl);

    app.config(routes);
    return app;
});

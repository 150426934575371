'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/description/all/all.controller',
],function(
    angular,
    MDAllCtrl
){
    var app=angular.module('app.seospider.exam.analysis.description.all', []);
    app.controller("MetaDescAllCtrl",MDAllCtrl);
    return app;
});
'use strict';
define(['app'], function (app) {
    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(['$rootScope', '$injector', '$q', function ($rootScope, $injector, $q) {
            return {
                responseError: function (error) {
                    var isWorkspaceInvalid = error.status === 417;

                    if (isWorkspaceInvalid) {
                        var $http = $injector.get('$http');
                        var $state = $injector.get('$state');
                        var AuthService = $injector.get('AuthService');
                        var WorkspaceService = $injector.get('WorkspaceService');
                        var stAlertLimitService = $injector.get('stAlertLimitService');

                        if (WorkspaceService.isOwner()) {
                            AuthService.logout();
                            $state.go('app.welcome');
                            return $q.reject(error);
                        }

                        $http.defaults.headers.common.Workspace = null;
                        WorkspaceService
                            .getDefaultWorkspace()
                            .then(function (workspace) {
                                WorkspaceService.setWorkspace(workspace);
                                $rootScope.$broadcast("workspace-set");
                                $state.go('app.welcome', {}, {
                                        notify: true,
                                        reload: true
                                    })
                                    .then(function () {
                                        stAlertLimitService
                                            .open('auth-login', {
                                                error: {
                                                    request: {
                                                        alias: 'workspace-unavailable'
                                                    }
                                                }
                                            });
                                    })
                            })
                            .catch(function (err) {
                                console.log(err);
                                $state.go('app.welcome', {}, {
                                    notify: true,
                                    reload: true
                                });
                            })
                    }

                    return $q.reject(error);
                }
            };
        }]);
    }]);
});

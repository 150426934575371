'use strict';
define([], function () {
    function Controller($scope) {
        var vm = $scope;

    }

    Controller.$inject = ['$scope'];
    return Controller;
});

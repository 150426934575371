/**
 * @ngdoc overview
 * @name utilitiese.service
 *
 * @description
 * Aggregate all Utilities used inside the app into one only service file
 */
'use strict';
define(['app'], function (app) {
	
    app.service('UtilitiesService', UtilitiesService);
    UtilitiesService.$inject = ['$http', '$rootScope', '__env', 'LanguageService'];
    /**
     * Utilities Service
     * @namespace UtilitiesService
     */
    function UtilitiesService($http, $rootScope, $translate, __env, LanguageService) {
    	
        return {
            replace_html_entities: replace_html_entities,
            getRandomString: getRandomString,
            getEmailRegex: getEmailRegex,
            getBusinessTypesOptions: getBusinessTypesOptions,
            getJobsTitleOptions: getJobsTitleOptions,
            getTeamMembersOptions: getTeamMembersOptions,
            getFaqs: getFaqs,
            scrollTo: scrollTo
        };



        /**
         * @param
         * @return array
         */
         function getBusinessTypesOptions() {
           
            return [
                "Digital Agency", 
                "Freelance",
                "E-commerce",
                "Newspaper",
                "Blog",
                "SMB",
                "Enterprise",
                "Education",
                "Non-profit",
                "Other"
            ];
        }

        /**
         * @param
         * @return array
         */
         function getJobsTitleOptions() {
           
            return [
                "CEO/Founder",
                "Head of Marketing",
                "Project Manager",
                "SEO/SEM Specialist",
                "Digital Strategist",
                "Copywriter",
                "Software Developer",
                "Designer",
                "Affiliate Marketer",
                "Data Analyst",
                "Bookkeeper",
                "Journalist",
                "Teacher",
                "Student",
                "Other"
            ];
        }


        /**
         * @param
         * @return array
         */
         function getTeamMembersOptions() {
           
            return [
                "Just me",
                "2-5",
                "6-10",
                "11-20",
                "21-50",
                "51-100",
                "101-500",
                "501-1000",
                "1000+"
            ];
        }
       
        /**
         * @param 
         * @return string
         */
        function replace_html_entities( str ) {
            return str.replace(/&quot;/g, '\'\'').replace(/&apos;/g, '\'');
        }

        /**
         * @param
         * @return string
         */
        function getRandomString() {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 20; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        /**
         * @param
         * @return object
         */
         function getFaqs() {
            //var currentLanguage = LanguageService.getCodeAndCountrySaveOnStorage();
            
             // these values are translation keys you can find on locales json file
            var faqs = [{
                    title: 'upgrade.faq.title.one',
                    response: 'upgrade.faq.response.one',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.two',
                    response: 'upgrade.faq.response.two',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.three',
                    response: 'upgrade.faq.response.three',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.four',
                    response: 'upgrade.faq.response.four',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.five',
                    response: 'upgrade.faq.response.five',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.six',
                    response: 'upgrade.faq.response.six',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.seven',
                    response: 'upgrade.faq.response.seven',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.nine',
                    response: 'upgrade.faq.response.nine',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.eleven',
                    response: 'upgrade.faq.response.eleven',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.twelve',
                    response: 'upgrade.faq.response.twelve',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.seventeen',
                    response: 'upgrade.faq.response.seventeen',
                    languages: ['en', 'it']
                },
                {
                    title: 'upgrade.faq.title.eighteen',
                    response: 'upgrade.faq.response.eighteen',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.twenty-one',
                    response: 'upgrade.faq.response.twenty-one',
                    languages: ['en', 'it', 'es']
                },
                {
                    title: 'upgrade.faq.title.twenty-two',
                    response: 'upgrade.faq.response.twenty-two',
                    languages: ['en', 'it', 'es']
                },
            ];

            /*
            faqs = faqs.filter(function(faq) {
                return faq.languages.indexOf(currentLanguage) > -1;
            });
            */
           
            return faqs;
        }

        /**
         * @param
         * @return RegExp
         */
         function getEmailRegex() {
            return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        }

        function scrollTo(eID) {
            var startY = currentYPosition();
            var stopY = elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
      
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }

            var speed = Math.round(distance / 100);
            if (speed >= 20) speed = 20;

            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;   
            var timer = 0;
        
            if (stopY > startY) {
                for ( var i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for ( var i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
            
            function currentYPosition() {
                // Firefox, Chrome, Opera, Safari
                if (self.pageYOffset) {
                    return self.pageYOffset;
                } 
                
                // Internet Explorer 6 - standards mode
                if (document.documentElement && document.documentElement.scrollTop) {
                    return document.documentElement.scrollTop;
                }

                // Internet Explorer 6, 7 and 8
                if (document.body.scrollTop) {
                    return document.body.scrollTop;
                }
                
                return 0;
            }
            
            function elmYPosition(eID) {
                var elm = document.getElementById(eID);
                var y = elm.offsetTop;
                var node = elm;

                while (node.offsetParent && node.offsetParent != document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                } 
                return y;
            }
    
        };

    }
});

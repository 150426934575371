define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.h1", {
                controller: 'H1Ctrl', abstract: true, templateUrl: './app/modules/seospider/exam/analysis/h1/h1.html', access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.h1.all", {
                url: "/h1", templateUrl: './app/modules/seospider/exam/analysis/h1/all/all.html', access: {restricted: true}, controller: 'H1AllCtrl', resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH1({
                            jobId: $stateParams.sessionId
                        })
                            .then(function (result) {
                                dfd.resolve(result);
                            }, function (err) {
                                dfd.resolve(err.data);
                            });
                        return dfd.promise;
                    }], jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.h1.over", {
                url: "/h1/over-70-char", templateUrl: './app/modules/seospider/exam/analysis/h1/all/all.html', access: {restricted: true}, controller: 'H1OverCtrl', resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH1({
                            jobId: $stateParams.sessionId, size: 'overDesktop'
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;

                    }], jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.h1.missing", {
                url: "/h1/missing", templateUrl: './app/modules/seospider/exam/analysis/h1/all/all.html', access: {restricted: true}, controller: 'H1MissingCtrl', resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH1({
                            jobId: $stateParams.sessionId, missing: true
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }], jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.h1.multiple", {
                url: "/h1/multiple",
                templateUrl: './app/modules/seospider/exam/analysis/h1/multiple/multiple.html',
                access: {restricted: true},
                controller: 'H1MultipleCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH1({
                            jobId: $stateParams.sessionId, multiple: true
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }], jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.h1.duplicated", {
                url: "/h1/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/h1/duplicated/duplicated.html',
                access: {restricted: true},
                controller: 'H1DuplicatedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$q', function ($stateParams, SeospiderPages, $q) {
                        return SeospiderPages.findAllH1Duplicated({
                            jobId: $stateParams.sessionId
                        });
                    }], jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

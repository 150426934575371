'use strict';
define(['angular'], function (ng) {
    function TwittercardNotOptimizedCtrl($scope, $stateParams, $q, results, jobId, Pages, $rootScope, $translate) {
        var vm = $scope;

        vm.notOptimizedTwittercard = [];
        vm.notOptimizedTwittercard = results.data.filter(function (page) {
            return (!page.metaOgTitle || !page.metaTwitterTitle || !page.metaTwitterDescription || !page.metaTwitterImage || !page.metaTwitterCard || !page.metaTwitterSite);
        });

        vm.pages = vm.notOptimizedTwittercard.slice(0, 100);
        vm.jobId = jobId;
        vm.tabs = {
            categoryTitle: 'Twittercard',
            categoryState: 'app.seospider.exam.analysis.twittercard',
            fields: [
                {label: 'seospider.twittercard.all', icon: 'icon icon-list', uiSref: '.all'},
                {label: 'seospider.twittercard.optimized', icon: 'icon icon-list', uiSref: '.optimized'},
                {label: 'seospider.twittercard.not-optimized', icon: 'icon icon-list', uiSref: '.not-optimized'},
            ]
        };
        vm.pagination = {
            numElements: vm.notOptimizedTwittercard.length,
            offset: 0,
            limit: 100,
            callback: pagingCallback
        };
        vm.currentSearch = {
            placeholder: $translate.instant('seospider.twittercard.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.twittercard.search'),
                callback: searchCallback
            };
        });
        vm.getColorByTitleLength = getColorByTitleLength;
        vm.getColorByDescriptionLength = getColorByDescriptionLength;
        vm.getPercentageTitle = getPercentageTitle;
        vm.getPercentageDescription = getPercentageDescription;
        vm.secureUrl = secureUrl;

        function secureUrl(url) {
            if (!url) return url;
            var root = $scope.analysis.url.replace(/\/$/g, '');
            if (isRelative(url)) {
                url = url.replace(/^\//g, '');
                return root + "/" + url;
            }
            return url;
        }

        function isRelative(url) {
            return !new RegExp(/^http[s]{0,1}\:\/\//i).test(url) && !new RegExp(/^\/\//i).test(url);
        }

        function getPercentageTitle(len) {
            if (len > 70)
                return 100;
            return (len * 100) / 70;
        }

        function getPercentageDescription(len) {
            if (len > 200)
                return 100;
            return (len * 100) / 200;
        }

        function getColorByTitleLength(titleLength) {
            if (titleLength <= 70 && titleLength >= 30)
                return 'green-text';
            if (titleLength >= 25 && titleLength <= 29)
                return 'text-darken-1 orange-text';
            else return 'red-text';

        }

        function getColorByDescriptionLength(descriptionLen) {
            if (descriptionLen > 95 && descriptionLen <= 200)
                return 'green-text';
            if (descriptionLen > 60 && descriptionLen <= 95)
                return 'text-darken-1 orange-text';
            else return 'red-text';
        }

        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAll({
                jobId: $stateParams.sessionId,
                q: query,
                fields: [
                    'id',
                    'url',
                    'metaTwitterTitle',
                    'metaTwitterDescription',
                    'metaTwitterImage',
                    'metaTwitterSite',
                    'metaTwitterCard'
                ],
                limit: 30,
                offset: 0
            }).then(function (res) {
                    vm.pagination.offset = 0;
                    vm.pages = res.data.filter(function (page) {
                        return (!page.metaOgTitle || !page.metaTwitterTitle || !page.metaTwitterDescription || !page.metaTwitterImage || !page.metaTwitterCard || !page.metaTwitterSite);
                    });
                    vm.pagination.numElements = vm.pages.length;
                },
                function (err) {
                    vm.pages = err.data;
                });
        }

        function pagingCallback(limit, offset) {
            vm.pages = vm.notOptimizedTwittercard.subarray(offset, limit);
        }
    }

    TwittercardNotOptimizedCtrl.$inject = ['$scope', '$stateParams', '$q', 'results', 'jobId', 'SeospiderPagesService', '$rootScope', '$translate'];
    return TwittercardNotOptimizedCtrl;
});

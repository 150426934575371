'use strict';
define([], function () {
    function Controller(
        $scope,
        $state,
        KeywordTrackerService,
        projects,
        $mdMedia,
        UserService
    ) {
        if (!projects || projects.length <= 0) {
            $state.go(
                'app.project.wizard',
                {
                    action: 'GET',
                    step: 'create-project',
                    tool: 'rank-tracker',
                    from: 'rank-tracker',
                    firstproject: true
                }
            );
            return;
        }

        $scope.$mdMedia = $mdMedia;
        $scope.projects = projects;
        $scope.isLoading = true;
        $scope.keywordCountTracker = 0;

        $scope.projectsAllowed = 0;
        $scope.formattedProjectsAllowed = 0;

        function init() {
            KeywordTrackerService.getAllTrackerProjects()
                .then(function (res) {
                    if (res && res.data && res.data.trackerProjects && res.data.trackerProjects.rows && res.data.trackerProjects.totalRows !== 0) {
                        $scope.projects = res.data.trackerProjects.rows;
                        $scope.isLoading = false;
                        $scope.keywordCountTracker = countAllKeywords($scope.projects);
                        return;
                    }

                    $state.go('app.project.wizard', {
                        action: 'GET',
                        step: 1,
                        tool: 'rank-tracker',
                        from: 'rank-tracker'
                    });
                })
                .catch(function (err) {
                    $scope.isLoading = false;
                    console.log(err);
                });
        }

        UserService.limits()
            .then(function (res) {
                if (res && res.data && res.data.limits && res.data.limits.length) {
                    angular.forEach(res.data.limits, function (service) {
                        if (service.alias === 'rank-tracker-keywords') {
                            $scope.keywordTrackerLimits = service.qtyAllowed;
                        }
                        if (service.alias === 'seo-project-limit') {
                            $scope.projectsAllowed = service.qtyAllowed;
                        }
                    });
                }

                $scope.formattedProjectsAllowed = $scope.projectsAllowed;
                if ($scope.projectsAllowed >= 9999) {
                    $scope.formattedProjectsAllowed = '∞'
                }
            })
            .catch(function (err) {
                console.log(err);
                return null;
            });


        $scope.createProject = createProject;
        $scope.countAllKeywords = countAllKeywords;
        $scope.projectsCreated = projects.length;

        function createProject() {
            $state.go(
                'app.project.wizard',
                {
                    action: 'GET',
                    step: 'create-project',
                    tool: 'rank-tracker',
                    from: 'rank-tracker'
                }
            );
        }

        $scope.$on("deleteProject", function (evt) {
            KeywordTrackerService.getAllTrackerProjects()
                .then(function (res) {
                    if (res && res.data && res.data.trackerProjects && res.data.trackerProjects.totalRows === 0) {
                        $state.go('app.project.wizard', {
                            action: 'GET',
                            step: 1,
                            firstproject: true,
                            tool: 'rank-tracker',
                            from: 'rank-tracker'
                        });
                    }
                    $scope.projectsCreated -= 1;
                    $scope.keywordCountTracker = countAllKeywords(res.data.trackerProjects.rows);
                })
                .catch(function (err) {
                    $scope.isLoading = false;
                    console.log(err);
                });
        });

        function countAllKeywords(projects) {
            return projects.map(function (project) {
                return project.keywords;
            }).reduce(function (counter, el) {
                return counter + el.length;
            }, 0);
        }

        //setTimeout(init, 5000);
        init();
    }

    Controller.$inject = [
        '$scope',
        '$state',
        'KeywordTrackerService',
        'projects',
        '$mdMedia',
        'UserService'
    ];
    return Controller;
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/dashboard/components/shortcut-box/shortcut-box.html',
        bindings: {
            data: '<',
            marginRight: '<',
        },
        controller: ['$scope', '$translate', '$state', function ($scope, $translate, $state) {
            $scope.goToRoute = function(route) {
                $state.go(route);
            }
        }]
    };
});

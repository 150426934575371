'use strict';
define([
    'angular',
    'modules/seochecker/seochecker.routes',
    'modules/seochecker/seochecker.controller',
    'modules/seochecker/components/components.module',
    'modules/seochecker/results/results.module',
    'modules/seochecker/landing/landing.module',
    'modules/seochecker/translations/translations.module'
], function (angular, routes, SEOCheckerCtrl) {
    var app = angular.module('app.seochecker', [
        'app.seochecker.ui',
        'app.seochecker.results',
        'app.seochecker.landing',
        'app.seochecker.translations',
    ]);
    app.config(routes);
    app.controller('SEOCheckerCtrl', SEOCheckerCtrl);
    return app;
});

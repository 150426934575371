'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/h2/h2table.html',
        bindings: {
            pages: '=',
            type: '<',
        },
        controller: ['$scope', '$rootScope', '$translate', function ($scope, $rootScope, $translate) {
            var vm = $scope;
            $scope.$watch('$ctrl.pages', function (pages) {
                vm.pages = pages;
            });

            if (!vm.pages) {
                vm.pages = [];
            }

            vm.openDetail = openDetail;
            vm.evalOccurences = evalOccurences;
            vm.evalLength = evalLength;

            function evalOccurences(score) {
                switch (parseInt(score) || 0) {
                    case 1:
                        return 'green-text';
                    case 0:
                        return 'red-text';
                    default:
                        return 'text-darken-1 orange-text';
                }
            }

            function evalLength(len) {
                len = parseInt(len) || 0;
                if (len > 70)
                    return 'red-text';
                if (len > 15 && len <= 70)
                    return 'green-text';
                if (len >= 5 && len <= 15)
                    return 'text-darken-1 orange-text';
                return 'red-text';
            }

            function openDetail(page) {
                var url = new URL(page.url);
                var pathname = url.pathname.length ? url.pathname : '/';
                $rootScope.$emit('openToolbarDetail', {
                    list: page.list,
                    templateType: 'multiple',
                    element: 'h2',
                    label: $translate.instant('seospider.h2.multiple'),
                    placeholder: $translate.instant('seospider.h2.search.in.page', {'pathname': pathname})
                });

                $rootScope.$on('languageChanged', function () {
                    $rootScope.$emit('openToolbarDetail', {
                            list: page.list,
                            element: 'h2',
                            label: $translate.instant('seospider.h2.multiple'),
                            placeholder: $translate.instant('seospider.h2.search.in.page', {'pathname': pathname})
                        }
                    );
                });
            }
        }]
    };
});

'use strict';
define(['app', 'angular'], function (app, angular) {
    var DEFAULT_TABLE_VALUES = {
        GAP: 4,
        PAGE_SIZE: 25,
        STARTING_PAGE: 0,
        TOGGLE_SELECT_ALL_STATUS: null,
        SORT_BY: null,
        CUSTOM_TEMPLATE: null,
        CUSTOM_TEMPLATE_WHEN_ROWS_ENDED: null,
        CUSTOM_TEMPLATE_WHEN_EMPTY_DATA: null,
        SHOW_FILTER_SEARCH: false
    };
    app.component('stEmptyState', {
        templateUrl: 'app/components/st-table-fluid/st-empty-state.html',
        bindings: {
            type: '<'
        }
    });
    app.component('stTableFluid', {
        templateUrl: 'app/components/st-table-fluid/st-table-fluid.html',
        controller: controller,
        transclude: true,
        bindings: {
            fields: '<',
            fixedRows: '<',
            defaultSortBy: '<',
            rows: '<',
            areLoadingFixedRows: '<',
            isLoading: '<',
            paginationSize: '<',
            paginationGap: '<',
            customFooter: '<',
            controller: '=',
            customTemplate: '<',
            customTemplateWhenRowsEnded: '<',
            customTemplateWhenEmptyData: '<',
            messageWhenEmptyDataIcon: '<',
            messageWhenEmptyDataTitle: '<',
            messageWhenEmptyDataSubtitle: '<',
            messageWhenEmptyDataCta: '<',
            searchable: '<',
            checkboxCustomClass: '<',
            focusable: '<',
            hasHoverEffect: '<',
            activeToggleAll: '<',
            activeFooter: '<',
            onSelectionChange: '=',
            onFocus: '=',
            customRowHeight: '<',
            addLockedRows: '<',
            totalLockedResults: '<'
        }
    });
    controller.$inject = ['__env', '$scope', '$sce', '$filter', '$timeout'];


    function controller(__env, $scope, $sce, $filter, $timeout) {
        var vm = $scope;
        var $this = this;

        function calcFixedTopSize($index){
            var element = document.querySelector('st-table-fluid thead tr:first-child');
            vm.theadHeight = element.clientHeight;
            return (vm.customRowHeight||55)*$index+element.clientHeight;
        }
        vm.calcFixedTopSize=calcFixedTopSize;

        var ICON_NO_DATA=['icon-no-data-kw','icon-no-data-serp','icon-bg-sad-clouds'];
        vm.customRowHeight = '';
        $this.$onInit = function () {
            vm.customRowHeight = $this.customRowHeight;
        };
        vm.activeFooter = $this.activeFooter || false;
        vm.areLoadingFixedRows = $this.areLoadingFixedRows || false;
        vm.defaultSampleHeader = [{
            emptyState: 'square',
            customClass: 'col-small',
        }, {
            emptyState: 'two-lines',
            customClass: 'm-w-md',
        }, '3', '4', '5', '6', '7'];
        vm.rows = [];
        vm.defaultSortBy = null;
        vm.fields = [];

        /* table template management & messages */
        vm.customTemplate = DEFAULT_TABLE_VALUES.CUSTOM_TEMPLATE;
        vm.customTemplateWhenRowsEnded = DEFAULT_TABLE_VALUES.CUSTOM_TEMPLATE_WHEN_ROWS_ENDED;
        vm.customTemplateWhenEmptyData = DEFAULT_TABLE_VALUES.CUSTOM_TEMPLATE_WHEN_EMPTY_DATA;

        /* table command */
        vm.toggleSelectAllStatus = DEFAULT_TABLE_VALUES.TOGGLE_SELECT_ALL_STATUS;
        vm.sortBy = DEFAULT_TABLE_VALUES.SORT_BY;

        /* table pagination */
        vm.gap = DEFAULT_TABLE_VALUES.GAP;
        vm.pageSize = DEFAULT_TABLE_VALUES.PAGE_SIZE;
        vm.currentPage = DEFAULT_TABLE_VALUES.STARTING_PAGE;
        /* table search & data-filter */
        vm.showFilterSearch = DEFAULT_TABLE_VALUES.SHOW_FILTER_SEARCH;

        vm.filterSearch = {
            query: undefined,
            searchBy: null
        };

        vm.command = {
            areCheckedSelected: false
        };

        vm.isActiveACustomTemplate = isActiveACustomTemplate;

        function isActiveACustomTemplate() {
            return !!vm.customTemplate;
        }

        vm.getRandomIconClass = function getRandomIconClass() {
            return ICON_NO_DATA[Math.floor(Math.random() * ICON_NO_DATA.length)];
        };

        vm.toggleSearchFilters = toggleSearchFilters;
        vm.hideSearchFilters = hideFilterSearch;
        vm.showSearchFilters = showSearchFilters;
        vm.paginationRangeGenerator = paginationRangeGenerator;
        vm.selectFocusOnThisRow = selectFocusOnThisRow;
        vm.toggleSelectAll = toggleSelectAll;
        vm.onRowChecked = onRowChecked; //this event capture only single row checkbox change event
        vm.setSortBy = setSortBy;
        vm.setRowsPerPage = setRowsPerPage;
        vm.setCurrentPage = setCurrentPage;
        vm.getNumberAsArray = getNumberAsArray;
        vm.getLockedRows = getLockedRows;
        vm.numberOfPages = numberOfPages;
        vm.isTheLastPage = isTheLastPage;
        vm.isString = isString;
        vm.range = range;

        vm.isHeaderSortable = isHeaderSortable;

        function isHeaderSortable(row) {
            if (!row) return false;
            return row.sortBy && !vm.isLoading && !vm.customTemplate;
        }

        function getLockedRows(lockedRows) {
            if (lockedRows <= 0) {
                return;
            }
            return new Array(lockedRows);
        }

        function toggleSearchFilters() {
            vm.showFilterSearch = !vm.showFilterSearch;
        }

        function hideFilterSearch() {
            vm.showFilterSearch = false;
            vm.filterSearch.query = undefined;
        }

        function showSearchFilters() {
            vm.showFilterSearch = true;
        }

        function range(size, start, end) {
            start = Math.min(start, numberOfPages(size) - vm.gap);
            if (start < 0) start = 0;
            end = Math.min(end, numberOfPages(size));
            var ret = [];
            if (size < end) {
                end = size;
                start = size - vm.gap;
            }
            for (var i = start; i < end; i++) {
                ret.push(i);
            }
            return ret;
        }

        /* utilities */
        function isString(value) {
            return typeof value === 'string';
        }

        function isTheLastPage(filteredCountRows) {
            var totalRows = vm.rows.length;
            if (filteredCountRows) {
                totalRows = filteredCountRows;
            }
            return totalRows - (vm.currentPage * vm.pageSize) <= vm.pageSize;
        }

        function getNumberAsArray(num) {
            return new Array(num);
        }

        function paginationRangeGenerator(size, start, end) {
            var ret = [];
            if (size < end) {
                end = size;
                start = size - $scope.gap;
            }
            for (var i = start; i < end; i++) {
                ret.push(i);
            }

            return ret;
        }

        /* pagination */
        function setCurrentPage(currentPage, filteredCountRows) {
            if (currentPage < 0) {
                currentPage = 0;
            }

            var totalRows = vm.rows.length;
            if (filteredCountRows) {
                totalRows = filteredCountRows;
            }

            if (currentPage > Math.ceil(totalRows / vm.pageSize) - 1) {
                vm.currentPage = Math.min(currentPage, Math.ceil(totalRows / vm.pageSize) - 1);
                return;
            }

            vm.currentPage = currentPage;
            setRowsPerPage();
        }

        function setRowsPerPage() {
            var stringOperatorForNull = '!';
            var results = vm.rows;
            if (vm.filterSearch.query) {
                results = $filter('filter')(vm.rows, vm.filterSearch.query);
            }
            results = $filter('orderBy')(results, [
                '' + stringOperatorForNull.concat('', vm.sortBy) + '', vm.sortBy
            ], false);
            results = $filter('paginationStartFrom')(results, vm.currentPage * vm.pageSize);
            results = $filter('limitTo')(results, vm.pageSize);
            $scope.rowsPerPage = results;
        }

        function numberOfPages(resultsCount) {
            if (resultsCount) {
                return Math.ceil(resultsCount / vm.pageSize);
            }

            return Math.ceil(vm.rows.length / vm.pageSize);
        }

        function unselectAll(alsoToggleAllCheckBox) {
            for (var i = 0; i < vm.rows.length; i++) {
                delete vm.rows[i].selected;
            }
            if (alsoToggleAllCheckBox) {
                vm.command.areCheckedSelected = false;
            }
        }

        /* selection */
        function toggleSelectAll() {
            var isChecked = vm.command.areCheckedSelected;
            var i;
            unselectAll();
            if (!isChecked)
                return triggerEventSelectedChanges();
            try {
                if (vm.filterSearch.query) {
                    $filter('filter')(vm.rows, vm.filterSearch.query);
                }
                for (i = 0; i < vm.results.length && i < (vm.currentPage + 1) * vm.pageSize; i++) {
                    vm.results[i].selected = true;
                }
                triggerEventSelectedChanges();
            } catch (err) {
                for (i = 0; i < vm.rows.length; i++) {
                    vm.rows[i].selected = true;
                }
                triggerEventSelectedChanges();
            }
        }

        function triggerEventSelectedChanges() {
            var currentSelectedRows = [];
            var clonedRows = deepCopy(vm.rows);
            for (var i = 0; i < clonedRows.length; i++) {
                if (clonedRows[i].selected) {
                    delete clonedRows[i].selected;
                    delete clonedRows[i].hover;
                    delete clonedRows[i].isHover;
                    delete clonedRows[i].focus;
                    currentSelectedRows.push(clonedRows[i]);
                }
            }

            if ($this.onSelectionChange) {
                $this.onSelectionChange(currentSelectedRows);
            }
        }

        function onRowChecked() {
            triggerEventSelectedChanges();
        }

        function selectFocusOnThisRow(row) {
            if (vm.focusable) {
                var i;
                for (i = 0; i < vm.rows.length; i++) {
                    delete vm.rows[i].focus;
                }
                for (i = 0; i < vm.fixedRows.length; i++) {
                    delete vm.fixedRows[i].focus;
                }
                row.focus = true;
                if ($this.onFocus)
                    $this.onFocus(row);
            }
        }

        /* sort & order by */

        function setSortBy(header) {
            vm.currentPage = 0;
            var newSort = header.sortBy;
            if (vm.sortBy == null) {
                vm.sortBy = "+" + newSort;
                setRowsPerPage();
                return;
            }

            if (vm.sortBy.replace(/[\-+]/gi, '') === header.sortBy) {
                if (vm.sortBy.indexOf('+') !== -1) {
                    vm.sortBy = "-" + header.sortBy;
                    setRowsPerPage();
                    return;
                }
                if (vm.sortBy.indexOf('-') !== -1) {
                    vm.sortBy = "+" + header.sortBy;
                    setRowsPerPage();
                }
            } else {
                vm.sortBy = "+" + header.sortBy;
                setRowsPerPage();
            }
        }

        /* utils functions */

        function deepCopy(arr) {
            var out = [];
            for (var i = 0, len = arr.length; i < len; i++) {
                var item = arr[i];
                var obj = {};
                for (var k in item) {
                    obj[k] = item[k];
                }
                out.push(obj);
            }
            return out;
        }


        vm.$watch('$ctrl.fixedRows', function (rows) {
            if (!rows)
                rows = [];
            if (rows) {
                for (var i = 0; i < rows.length; i++) {
                    rows[i].isFixed = true;
                }
                vm.fixedRows = rows;
            }
        });

        vm.$watch('$ctrl.rows', function (rows) {
            if (!rows)
                rows = [];
            vm.rows = rows;
            vm.currentPage = 0;
            setRowsPerPage();
        });

        vm.$watch('$ctrl.fields', function (fields) {
            if (!fields)
                fields = [];
            vm.fields = fields;
            if (!vm.sortBy) {
                vm.sortBy = vm.defaultSortBy ? vm.defaultSortBy : DEFAULT_TABLE_VALUES.SORT_BY;
            }
        });

        /* todo - refactor all of this $watch functions and use simply the $this scope */

        vm.$watch('$ctrl.activeToggleAll', function (activeToggleAll) {
            vm.activeToggleAll = activeToggleAll;
        });
        vm.$watch('$ctrl.customTemplate', function (customTemplate) {
            vm.customTemplate = $sce.trustAsHtml(customTemplate);
            vm.sortBy = DEFAULT_TABLE_VALUES.SORT_BY;
        });
        vm.$watch('$ctrl.customTemplateWhenEmptyData', function (customTemplateWhenEmptyData) {
            if (customTemplateWhenEmptyData && isString(customTemplateWhenEmptyData)) {
                vm.customTemplateWhenEmptyData = $sce.trustAsHtml(customTemplateWhenEmptyData);
                return;
            }
            if (customTemplateWhenEmptyData && !isString(customTemplateWhenEmptyData)) {
                vm.customTemplateWhenEmptyData = true;
            }
        });
        vm.$watch('$ctrl.customTemplateWhenRowsEnded', function (customTemplateWhenRowsEnded) {
            if (customTemplateWhenRowsEnded && isString(customTemplateWhenRowsEnded)) {
                vm.customTemplateWhenRowsEnded = customTemplateWhenRowsEnded;
                return;
            }
            if (customTemplateWhenRowsEnded && !isString(customTemplateWhenRowsEnded)) {
                vm.customTemplateWhenRowsEnded = true;
            }
        });

        vm.$watch('$ctrl.customFooter', function (customFooter) {
            vm.customFooter = $sce.trustAsHtml(customFooter);
        });
        vm.$watch('$ctrl.loading', function (loading) {
            vm.loading = loading;
            vm.sortBy = DEFAULT_TABLE_VALUES.SORT_BY;
        });
        vm.$watch('$ctrl.focusable', function (focusable) {
            vm.focusable = focusable;
        });
        vm.$watch('$ctrl.searchable', function (searchable) {
            vm.searchable = searchable;
        });
        vm.$watch('$ctrl.checkboxCustomClass', function (checkboxCustomClass) {
            if (checkboxCustomClass) {
                vm.checkboxClass = checkboxCustomClass;
            } else {
                vm.checkboxClass = 'checkbox-default--xs m-l-xs';
            }
        });
        vm.$watch('$ctrl.paginationGap', function (paginationGap) {
            vm.gap = paginationGap;
        });

        vm.$watch('$ctrl.defaultSortBy', function (defaultSortBy) {
            if (defaultSortBy && defaultSortBy.length) {
                vm.defaultSortBy = defaultSortBy;
                vm.sortBy = defaultSortBy;
                setRowsPerPage();
            }
        });

        vm.$watch('$ctrl.paginationSize', function (paginationSize) {
            vm.pageSize = paginationSize;
        });
        vm.$watch('$ctrl.controller', function (controller) {
            if (controller)
                angular.extend(vm, controller);
        });
        vm.$watch('filterSearch.query', function () {
            vm.currentPage = 0;
            unselectAll(true);
            triggerEventSelectedChanges();
        });
    }

    var templateCheckbox = '<div layout="row" layout-align="center center" class="text-center">' +
        '<md-checkbox ng-click="$event.stopPropagation()" ' +
        ' ng-change="onRowChecked()" ng-model="row.selected" ' +
        ' class="p-0 m-0" ' +
        ' aria-label="select all"></md-checkbox></div>';

    app.directive('transcopeTable', ['$compile', function ($compile) {
            return {
                link: function ($scope, $element, $attrs, controller, $transclude) {
                    if (!$transclude) {
                        throw minErr('ngTransclude')('orphan',
                            'Illegal use of ngTransclude directive in the template! ' +
                            'No parent directive that requires a transclusion found. ' +
                            'Element: {0}',
                            startingTag($element));
                    }
                    var innerScope = $scope.$new();
                    $transclude(innerScope, function (clone) {
                        var newArray = [];
                        try {
                            var isSelectable = !!($scope.$parent && $scope.$parent.activeToggleAll);
                            if (isSelectable) {
                                // if table is selectable I'll add a TD with a checkbox
                                var e = document.createElement('td');
                                e.classList.add("hide-xs");
                                e.style.width = "55px";
                                e.style.maxWidth = "55px";
                                e.innerHTML = templateCheckbox;
                                newArray.push($compile(e)(innerScope));
                            }
                        } catch (e) {}
                        for (var i = 0; i < clone.length; i++) {
                            newArray.push(clone[i]);
                        }
                        $element.empty();
                        $element.append(newArray);
                        $element.on('$destroy', function () {
                            innerScope.$destroy();
                        });
                    });
                }
            };
        }]
    );

    app.filter('paginationStartFrom', function () {
        return function (input, start) {
            if (input)
                return input.slice(start);
        };
    });

});

define(['app'], function (app) {
    const MIN_NUM_OF_HEADINGS_IN_CONTENT = 6;
    app.service('WriterAssistantService', WriterAssistantService);
    app.filter('toArray', function () {
        return function (obj, addKey) {
            if (!angular.isObject(obj)) return obj;
            var result = [];
            angular.forEach(obj, function (value, key) {
                if (addKey) {
                    value.key = key;
                }
                result.push(value);
            });
            return result;
        };
    });


    WriterAssistantService.$inject = ['API', '$sce', '$q', '__env'];

    function WriterAssistantService(API, $sce, $q, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.writeAssistantAPI;

        function generateNewArticleStrategy(params) {
            return new Promise(function (resolve, reject) {
                const articleDataSet = getDefaultArticleDataSetModel();
                if (!params || !params.mainKeyword) {
                    return articleDataSet;
                }

                if (params.mode) {
                    articleDataSet.mode = params.mode;
                }

                if (params.country) {
                    articleDataSet.country = params.country;
                }

                if (params.language) {
                    articleDataSet.language = params.language;
                }

                if (params.device) {
                    articleDataSet.device = params.device;
                }

                articleDataSet.mainKeyword = params.mainKeyword;
                API.post(apiPath + '/articles/', params, true)
                    .then(function (res) {
                        resolve(res);
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            });
        }

        function generateNewStrategy(articleId, articleDataSet) {
            return new Promise(function (resolve, reject) {
                API.post(apiPath + '/articles/' + articleId + '/strategies', articleDataSet, true)
                    .then(function (res) {
                        resolve(res);
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            });
        }

        async function generateHeadingsWithAI(articleId, mainKeyword, language = 'english', minNumHeadings = MIN_NUM_OF_HEADINGS_IN_CONTENT) {
            return new Promise((resolve, reject) => {
                API.post(apiPath + '/articles/' + articleId + '/ai/headings', {
                    mainKeyword,
                    minNumHeadings,
                    language,
                }, true)
                    .then(function (res) {
                        if (!res || !res || !res.headings) {
                            reject(new Error('invalid response'));
                            return;
                        }

                        resolve(res.headings);
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            });
        }

        async function rewriteTextWithAI(articleId, action, selectedHTML) {
            return new Promise((resolve, reject) => {
                const prompt = `${action} this content:${selectedHTML}.`;

                const context = [
                    'Context: You are generating content for a TipTap Editor',
                    'You must respect these rules for the new generated content:' +
                    '\n - Rule 1:  Please return only the content, without wrapping it in body or other HTML tags because I will use it to replace an exist part of my content.' +
                    `\n - Rule 2:  ${action} the content and return valid HTML for an editor without extra newlines or empty spaces`,
                ];

                API.post(apiPath + '/articles/' + articleId + '/ai/text', {
                    prompt,
                    context
                }, true)
                    .then(function (res) {
                        console.log('Text generated', res);

                        if (!res || !res.text) {
                            reject(new Error('invalid response'));
                            return;
                        }

                        resolve(res.text);
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            });
        }

        function generateHeadingsUsingCommonTitles(mainKeyword, marketAnalysis) {
            const titleStructure = {
                value: mainKeyword,
                type: "h1",
                children: []
            };
            try {
                const h2Counts = {};
                // Conta la frequenza degli H2 in tutti i risultati
                marketAnalysis.rows.forEach(row => {
                    if (row.extraParams && row.extraParams.h2) {
                        row.extraParams.h2.forEach(h2 => {
                            if (h2Counts[h2]) {
                                h2Counts[h2]++;
                            } else {
                                h2Counts[h2] = 1;
                            }
                        });
                    }
                });
                // Seleziona solo gli H2 che sono comuni a 2 o più risultati
                Object.keys(h2Counts).forEach(h2 => {
                    if (h2Counts[h2] >= 2) {
                        titleStructure.children.push({
                            value: h2,
                            type: "h2",
                            children: []
                        });
                    }
                });
                return [titleStructure];
            } catch (e) {
                return [titleStructure];
            }
        }

        function getReferencesContentStrategy(articleDataSet) {
            if (!articleDataSet ||
                !articleDataSet.marketAnalysis ||
                !articleDataSet.marketAnalysis.rows ||
                articleDataSet.marketAnalysis.rows.length <= 0) {
                return getDefaultArticleDataSetModel().contentStrategy;
            }
            const {rows} = articleDataSet.marketAnalysis;
            // Filter rows that have useThisStrategy set to true
            const filteredRows = rows.filter(row =>
                row.hasOwnProperty('useThisStrategy') &&
                row.useThisStrategy === true
            );
            if (filteredRows.length <= 0) {
                //if the user choose to set no references, use a default content strategy
                return getDefaultArticleDataSetModel().contentStrategy;
            }
            return {
                words: calculateWordCount(filteredRows),
                paragraph: calculateParagraphMetrics(filteredRows),
                headings: calculateHeadingsMetrics(filteredRows),
                images: calculateImagesMetrics(filteredRows),
            };
        }

        function calculateWordCount(rows) {
            const values = [];
            let sum = 0;
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].hasOwnProperty('useThisStrategy') && rows[i].useThisStrategy) {
                    values.push(Number(rows[i].extraParams.wordCount));
                    sum += Number(rows[i].extraParams.wordCount);
                }
            }

            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);

            return {
                value: parseInt(avg.toFixed(0)),
                marketAvg: parseInt(avg.toFixed(0)),
                marketMin: parseInt(min),
                marketMax: parseInt(max),
            };
        }

        function calculateParagraphMetrics(rows) {
            const values = rows.map(row => {
                if (row && row.extraParams && row.extraParams.paragraphs && row.extraParams.paragraphs.length > 0) {
                    return row.extraParams.paragraphs.length;
                }

                return 0;
            });

            const sum = values.reduce((acc, val) => acc + val, 0);
            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);

            return {
                value: parseInt(avg.toFixed(0)),
                marketAvg: parseInt(avg.toFixed(0)),
                marketMin: parseInt(min),
                marketMax: parseInt(max),
            };
        }

        function calculateImagesMetrics(rows) {
            let sum = 0;
            const values = rows.map(row => {
                if (!row || !row.extraParams) {
                    return 0;
                }

                let totalImage = row.extraParams.articleImages ? row.extraParams.articleImages.length : 0;
                sum += totalImage;
                return totalImage;
            });

            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);

            return {
                value: parseInt(avg.toFixed(0)),
                marketAvg: parseInt(avg.toFixed(0)),
                marketMin: parseInt(min),
                marketMax: parseInt(max),
            };
        }

        function calculateHeadingsMetrics(rows) {
            const values = rows.map(row => {
                if (!row || !row.extraParams) {
                    return 0;
                }

                const h1Count = row.extraParams.h1 ? row.extraParams.h1.length : 0;
                const h2Count = row.extraParams.h2 ? row.extraParams.h2.length : 0;
                const h3Count = row.extraParams.h3 ? row.extraParams.h3.length : 0;
                return h1Count + h2Count + h3Count;
            });

            const sum = values.reduce((acc, val) => acc + val, 0);
            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);

            return {
                value: parseInt(avg.toFixed(0)),
                marketAvg: parseInt(avg.toFixed(0)),
                marketMin: parseInt(min),
                marketMax: parseInt(max),
            };
        }

        function generateContentWithAI(articleId, article) {
            return new Promise((resolve, reject) => {
                API.post(apiPath + '/articles/' + articleId + '/ai/content', article, true)
                    .then(function (res) {
                        if (!res || !res || !res.content) {
                            reject(new Error('invalid response'));
                            return;
                        }

                        console.log('Context generated', res);
                        resolve(res.content);
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            });
        }

        function getDefaultArticleDataSetModel() {
            return {
                mode: 'WRITE_WITH_AI',
                mainKeyword: null,
                country: 'US',
                language: 'en',
                device: 'mobile',
                content: null,
                headings: null,
                marketAnalysis: null,
                contentStrategy: {
                    words: {
                        value: 1000,
                        marketAvg: 1000,
                        marketMin: 1000,
                        marketMax: 1500,
                    },
                    paragraph: {
                        value: 20,
                        marketAvg: 20,
                        marketMin: 10,
                        marketMax: 25,
                    },
                    headings: {
                        value: MIN_NUM_OF_HEADINGS_IN_CONTENT,
                        marketAvg: MIN_NUM_OF_HEADINGS_IN_CONTENT,
                        marketMin: 5,
                        marketMax: 7,
                    },
                    images: {
                        value: 2,
                        marketAvg: 2,
                        marketMin: 1,
                        marketMax: 4,
                    },
                },
                toneOfVoice: "professional",//secondPerson
                pointOfView: "secondPerson",//instructive
                contentType: "blogPost",//blogPost
            };
        }

        function getArticles() {
            return API.get(apiPath + '/articles', {}, true);
        }

        function getArticle(id) {
            return API.get(apiPath + '/articles/' + id, {}, true);
        }

        function updateArticle(id, article) {
            return API.put(apiPath + '/articles/' + id, article, true);
        }

        function deleteArticle(id) {
            return API.delete(apiPath + '/articles/' + id, {}, true);
        }


        return {
            generateNewArticleStrategy: generateNewArticleStrategy,
            generateNewStrategy: generateNewStrategy,
            generateHeadingsWithAI: generateHeadingsWithAI,
            generateContentWithAI: generateContentWithAI,
            rewriteTextWithAI: rewriteTextWithAI,
            generateHeadingsUsingCommonTitles: generateHeadingsUsingCommonTitles,
            getReferencesContentStrategy: getReferencesContentStrategy,
            getDefaultArticleDataSetModel: getDefaultArticleDataSetModel,
            getArticles: getArticles,
            getArticle: getArticle,
            updateArticle: updateArticle,
            deleteArticle: deleteArticle,
        };
    }
});

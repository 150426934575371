'use strict';
define([
    'angular',
    'modules/template/dialogs/switch-workspace/switch-workspace.controller'
], function (angular, SwitchWorkspaceCtrl) {
    var app = angular.module('app.template.dialogs', []);
    app.controller('SwitchWorkspaceCtrl', SwitchWorkspaceCtrl);

    return app;
});

'use strict';
define([], function () {
    function Controller($scope, $q, $mdDialog, $state, LanguageService, $window) {
        var vm = $scope;
        vm.query = {
            url: null
        };

        vm.newAnalysis = copymetrix;

        function copymetrix() {
            $state.go('app.copymetrics.analyzer', vm.query, {notify: true, reload: false});
        }

    }

    Controller.$inject = ['$scope', '$q', '$mdDialog', '$state', 'LanguageService', '$window'];
    return Controller;
});

'use strict';
define(['jquery'], function ($) {
    function PartnersAreaController($scope, $state, $translate, partnerInfo, PartnerInfoService, $mdToast, $timeout, $location, $mdDialog, userInfo, LanguageService) {
        var vm = $scope;

        vm.referentsOld = partnerInfo.referents ? JSON.parse(partnerInfo.referents) : [];
        vm.geographicFocusErrorMessage = '';
        vm.locations = (partnerInfo && partnerInfo.geographicFocus && partnerInfo.geographicFocus !== {}) ? JSON.parse(partnerInfo.geographicFocus) : [];

        vm.isPartner = userInfo.isPartner;
        vm.locator = $location;


        if (!vm.isPartner) {
            showNotPartnerAlert();
        }

        if (userIsNotEnabledToSeePartnerArea()) {
            $state.go('app.settings.overview');
        }

        function userIsNotEnabledToSeePartnerArea() {
            return LanguageService.getLocale() !== 'it_IT';
        }

        function showNotPartnerAlert() {
            $mdDialog.show({
                controller: 'NotPartnerCtrl',
                templateUrl: "app/modules/settings/dialogs/not-partner/not-partner.html",
                clickOutsideToClose: true,
            }).then(function () {
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        vm.employeesNumberOptions = ['Nessuno', '1-4', '5-9', '10-24', '25-49', '50-249', '250+'];

        vm.offeredServicesOptions = [
            {
                id: 1,
                text: 'Search Engine Advertising'
            },
            {
                id: 2,
                text: 'Pay Per Click Advertising'
            },
            {
                id: 3,
                text: 'Video Advertising'
            },
            {
                id: 4,
                text: 'Content Marketing'
            },
            {
                id: 5,
                text: 'Content Strategy'
            },
            {
                id: 6,
                text: 'Copywriting'
            },
            {
                id: 7,
                text: 'Market analysis'
            },
            {
                id: 8,
                text: 'Competitor Analysis'
            },
            {
                id: 9,
                text: 'SEO Strategy'
            },
            {
                id: 10,
                text: 'Inbound Marketing'
            },
            {
                id: 11,
                text: 'Public Relations'
            },
            {
                id: 12,
                text: 'Comunicati Stampa'
            },
            {
                id: 13,
                text: 'Relazioni Media'
            },
            {
                id: 14,
                text: 'SEO'
            },
            {
                id: 15,
                text: 'Local SEO'
            },
            {
                id: 16,
                text: 'SEO internazionale'
            },
            {
                id: 17,
                text: 'SEO A/B Testing'
            },
            {
                id: 18,
                text: 'Backlink Management'
            },
            {
                id: 19,
                text: 'Link Building'
            },
            {
                id: 20,
                text: 'Keyword Research'
            },
            {
                id: 21,
                text: 'Mobile SEO'
            },
            {
                id: 22,
                text: 'SEO Tecnica'
            },
            {
                id: 23,
                text: 'Relazioni Media'
            },
            {
                id: 24,
                text: 'Amazon SEO'
            },
            {
                id: 25,
                text: 'YouTube SEO'
            },
            {
                id: 26,
                text: 'App Store Optimization (ASO)'
            },
            {
                id: 27,
                text: 'Web Development'
            },
            {
                id: 28,
                text: 'Web Design'
            },
            {
                id: 29,
                text: 'User Experience (UX)'
            },
            {
                id: 30,
                text: 'Conversion Rate Optimization (CRO)'
            },
            {
                id: 31,
                text: 'Manutenzione Website'
            },
            {
                id: 32,
                text: 'Sviluppo Landing Page'
            },

        ];

        vm.industryOptions = [
            {
                id: 1,
                text: 'Abbigliamento e accessori'
            },
            {
                id: 2,
                text: 'Architettura e design'
            },
            {
                id: 3,
                text: 'Arte e intrattenimento'
            },
            {
                id: 4,
                text: 'Automotive'
            },
            {
                id: 5,
                text: 'Banca e finanza'
            },
            {
                id: 6,
                text: 'Bellezza e benessere'
            },
            {
                id: 7,
                text: 'Libri'
            },
            {
                id: 8,
                text: 'Media ed editoria'
            },
            {
                id: 9,
                text: 'Servizi commerciali e professionali'
            },
            {
                id: 10,
                text: 'Computer ed elettronica'
            },
            {
                id: 11,
                text: 'Edilizia e ristrutturazione'
            },
            {
                id: 12,
                text: 'Cliniche e forniture dentali'
            },
            {
                id: 13,
                text: 'E-commerce'
            },
            {
                id: 14,
                text: 'Istruzione e Formazione'
            },
            {
                id: 15,
                text: 'Cibo e bevande'
            },
            {
                id: 16,
                text: 'Gioco d\'azzardo'
            },
            {
                id: 17,
                text: 'Governo'
            },
            {
                id: 18,
                text: 'Servizi sanitari e medici'
            },
            {
                id: 19,
                text: 'Casa e giardino'
            },
            {
                id: 20,
                text: 'Prodotti per la casa'
            },
            {
                id: 21,
                text: 'Tecnologia dell\'informazione'
            },
            {
                id: 22,
                text: 'Assicurazioni'
            },
            {
                id: 23,
                text: 'Servizi legali'
            },
            {
                id: 24,
                text: 'Logistica e stoccaggio'
            },
            {
                id: 25,
                text: 'Produzione'
            },
            {
                id: 26,
                text: 'Non profit'
            },
            {
                id: 27,
                text: 'Petrolio e gas'
            },
            {
                id: 28,
                text: 'Immobiliare'
            },
            {
                id: 29,
                text: 'Reclutamento e carriera'
            },
            {
                id: 30,
                text: 'Consulenza'
            },
            {
                id: 31,
                text: 'Ristoranti'
            },
            {
                id: 32,
                text: 'Caffè'
            },
            {
                id: 33,
                text: 'Bar'
            },
            {
                id: 34,
                text: 'Sistemi e servizi di sicurezza'
            },
            {
                id: 35,
                text: 'Sport e fitness'
            },
            {
                id: 36,
                text: 'Telecomunicazioni e comunicazioni mobili'
            },
            {
                id: 37,
                text: 'Trasporti'
            },
            {
                id: 38,
                text: 'Noleggio veicoli'
            },
            {
                id: 39,
                text: 'Viaggi'
            },
            {
                id: 40,
                text: 'Turismo e ospitalità'
            }
        ];

        vm.languagesOptions = [
            {
                id: 1,
                text: 'Inglese'
            },
            {
                id: 2,
                text: 'Spagnolo'
            },
            {
                id: 3,
                text: 'Tedesco'
            },
            {
                id: 4,
                text: 'Francese'
            },
            {
                id: 5,
                text: 'Italiano'
            },
            {
                id: 6,
                text: 'Portoghese'
            },
            {
                id: 7,
                text: 'Cinese'
            },
            {
                id: 8,
                text: 'Giapponese'
            },
            {
                id: 9,
                text: 'Russo'
            },
            {
                id: 10,
                text: 'Indiano'
            }
        ];

        vm.cmsOptions = [
            {
                id: 1,
                text: 'Wordpress'
            },
            {
                id: 2,
                text: 'Joomla'
            },
            {
                id: 3,
                text: 'Drupal'
            },
            {
                id: 4,
                text: 'Prestashop'
            },
            {
                id: 5,
                text: 'Woocommerce'
            },
            {
                id: 6,
                text: 'Magento'
            },
            {
                id: 7,
                text: 'Shopify'
            },
            {
                id: 8,
                text: 'Wix'
            },
            {
                id: 9,
                text: 'Flazio'
            },
            {
                id: 10,
                text: 'Magento 2'
            },
            {
                id: 11,
                text: '1&1 MyWebsite'
            },
            {
                id: 12,
                text: '1&1 E-Commerce'
            },
            {
                id: 13,
                text: 'GoDaddy'
            },
            {
                id: 14,
                text: 'Hostpoint Webshop'
            },
            {
                id: 15,
                text: 'IONOS MyWebsite Creator / MyWebsite'
            },
            {
                id: 16,
                text: 'IONOS MyWebsite Now'
            },
            {
                id: 17,
                text: 'IONOS MyWebsite Shop'
            },
            {
                id: 18,
                text: 'Jimdo'
            },
            {
                id: 19,
                text: 'Jimdo Dolphin'
            },
            {
                id: 20,
                text: 'Register.it - Simply Site'
            },
            {
                id: 21,
                text: 'Squarespace'
            },
            {
                id: 22,
                text: 'SuperSite'
            },
            {
                id: 23,
                text: 'Weebly'
            },
            {
                id: 24,
                text: 'BigCommerce'
            }
        ];

        vm.budgetRangeOptions = [
            {
                id: '0-1000',
                text: '€0 - 1.000'
            },
            {
                id: '1000-2500',
                text: '€1.000 - 2.500'
            },
            {
                id: '2500-5000',
                text: '€2.500 - 5.000'
            },
            {
                id: '5000+',
                text: '€5.000+'
            }
        ];

        vm.foundedYearOptions = function () {
            var yearOptions = [];
            var startYear = 1990;
            var currentYear = new Date().getFullYear();
            for (var i = startYear; i <= currentYear; i++) {
                yearOptions.push(parseInt(i));
            }
            return yearOptions;
        }

        vm.pricingModelOptions = [
            {
                id: 'hourly_rate',
                text: 'Tariffa oraria'
            },
            {
                id: 'fixed_rate',
                text: 'Tariffa Fissa'
            },
            {
                id: 'project_based',
                text: 'Basato su progetto'
            },
            {
                id: 'commission',
                text: 'Commissione'
            },
            {
                id: 'value_based',
                text: 'Basato su valore/prestazioni'
            }
        ];

        vm.clientsSizeOptions = [
            {
                id: 'micro',
                text: 'Micro (da 1 a 9 dipendenti)'
            },
            {
                id: 'small',
                text: 'Piccola (da 10 a 49 dipendenti)'
            },
            {
                id: 'medium',
                text: 'Media (da 50 a 249 dipendenti)'
            },
            {
                id: 'big',
                text: 'Grande (250 dipendenti o più)'
            },
        ];

        if (partnerInfo) {
            vm.addressSavedOnDb = partnerInfo.address;

            vm.partner = {
                name: partnerInfo.name,
                description: partnerInfo.description,
                employeesNumber: partnerInfo.employeesNumber,
                foundedYear: partnerInfo.foundedYear,
                logoImgUrl: partnerInfo.logoImgUrl,
                tagline: partnerInfo.tagline,
                offeredServices: partnerInfo.offeredServices ? partnerInfo.offeredServices.split(',') : [],
                industries: partnerInfo.industries ? partnerInfo.industries.split(',') : [],
                geographicFocus: partnerInfo.geographicFocus ? JSON.parse(partnerInfo.geographicFocus) : [],
                languages: partnerInfo.languages ? partnerInfo.languages.split(',') : [],
                cms: partnerInfo.cms ? partnerInfo.cms.split(',') : [],
                budgetRange: partnerInfo.budgetRange ? partnerInfo.budgetRange.split(',') : [],
                pricingModel: partnerInfo.pricingModel ? partnerInfo.pricingModel.split(',') : [],
                clientsSize: partnerInfo.clientsSize,
                website: partnerInfo.website,
                address: partnerInfo.address,
                zipCode: partnerInfo.zipCode,
                email: partnerInfo.email,
                phoneNumber: partnerInfo.phoneNumber,
                referents: partnerInfo.referents ? JSON.parse(partnerInfo.referents) : [],
            }
            vm.partner.agencyType = userInfo.agencyType;
            vm.file = {};
            vm.file.resourceUrl = partnerInfo.logoImgUrl;
            vm.referentsAdded = vm.partner.referents.length;
        }

        if (vm.partner.agencyType === 'marketer') {
            vm.maxPartnerProgramReferent = 1;
        } else if (vm.partner.agencyType === 'silver_agency') {
            vm.maxPartnerProgramReferent = 2;
        } else if (vm.partner.agencyType === 'gold_agency') {
            vm.maxPartnerProgramReferent = 3;
        }

        vm.getReferentIndexes = function () {
            var referentIndexes = [];

            var maxIndex = vm.maxPartnerProgramReferent - 1;
            for (var i = 0; i <= maxIndex; i++) {
                referentIndexes.push(i);
            }
            return referentIndexes;
        }

        vm.searchTermServices = '';
        vm.clearSearchTermServices = function () {
            vm.searchTermServices = '';
        };
        vm.searchTermIndustries = '';
        vm.clearSearchTermIndustries = function () {
            vm.searchTermIndustries = '';
        };
        vm.searchTermLanguages = '';
        vm.clearSearchTermLanguages = function () {
            vm.searchTermLanguages = '';
        };
        vm.searchTermCms = '';
        vm.clearSearchTermCms = function () {
            vm.searchTermCms = '';
        };

        vm.updatePartnerInfo = function (file) {
            var newPartnerInfo = {};
            newPartnerInfo.logoImgUrl = file.resourceUrl;
            newPartnerInfo.name = vm.partner.name;
            newPartnerInfo.description = vm.partner.description;
            newPartnerInfo.tagline = vm.partner.tagline;
            newPartnerInfo.employeesNumber = vm.partner.employeesNumber;
            newPartnerInfo.foundedYear = vm.partner.foundedYear;
            newPartnerInfo.website = vm.partner.website;
            newPartnerInfo.email = vm.partner.email;
            newPartnerInfo.phoneNumber = vm.partner.phoneNumber;
            newPartnerInfo.clientsSize = vm.partner.clientsSize;
            newPartnerInfo.zipCode = vm.partner.zipCode;
            newPartnerInfo.address = vm.partner.address.name || vm.partner.address;
            newPartnerInfo.offeredServices = vm.partner.offeredServices.join(',');
            newPartnerInfo.industries = vm.partner.industries.join(',');
            newPartnerInfo.languages = vm.partner.languages.join(',');
            newPartnerInfo.cms = vm.partner.cms.join(',');
            newPartnerInfo.geographicFocus = JSON.stringify(vm.locations);
            newPartnerInfo.pricingModel = vm.partner.pricingModel.join(',');
            newPartnerInfo.budgetRange = vm.partner.budgetRange.join(',');

            newPartnerInfo.referents = vm.partner.referents.filter(function (referent) {
                return referent.name !== "" && referent.email !== ""
            });
            vm.referentsAdded = newPartnerInfo.referents.length;
            vm.addressSavedOnDb = newPartnerInfo.address;

            newPartnerInfo.referentsToRemove = []
            if (vm.referentsOld) {
                angular.forEach(vm.referentsOld, function (referentToRemove) {
                    var isToRemove = true;
                    angular.forEach(newPartnerInfo.referents, function (referent) {
                        if (referent && referentToRemove && referent.email && referentToRemove.email && referent.email === referentToRemove.email) {
                            isToRemove = false;
                        }
                    });

                    if (isToRemove) {
                        newPartnerInfo.referentsToRemove.push(referentToRemove);
                    }
                });
            }

            newPartnerInfo.referentToRemove = JSON.stringify(newPartnerInfo.referentToRemove);
            newPartnerInfo.referents = JSON.stringify(newPartnerInfo.referents);

            if (!newPartnerInfo.address) {
                partnerInfoForm.$invalid = true;
                vm.showCustomToast('Indirizzo non valido. Riprova!', 'icon-x st-red');
                return;
            }

            PartnerInfoService.updatePartnerInfo(newPartnerInfo)
                .then(function (res) {
                    vm.showCustomToast($translate.instant('settings.updated.info'), 'icon-checkmark st-green');
                })
                .catch(function (err) {
                    vm.showCustomToast($translate.instant('settings.updated.error'), 'icon-x st-red');
                });

        }

        vm.addLocation = function (location) {
            var locationAlreadyAdded = false;

            for (var i = 0; i < vm.locations.length; i++) {
                if (vm.locations[i].name == location.name) {
                    locationAlreadyAdded = true;
                    vm.geographicFocusErrorMessage = 'Area già in lista, riprova';
                    document.querySelector("#add-location-btn").disabled = true;
                    document.querySelector("#place-autocomplete input").focus();
                    $timeout(function () {
                        vm.geographicFocusErrorMessage = '';
                    }, 3000)
                    document.querySelector("#place-autocomplete input").value = '';
                    break;
                }
            }

            if (location && location.name && !locationAlreadyAdded) {
                vm.locations.push(location);
                document.querySelector("#place-autocomplete input").value = '';
                document.querySelector("#add-location-btn").disabled = true;
                vm.geographicFocusErrorMessage = '';
            }
        }

        vm.changeAddress = function (address) {
            vm.addressSavedOnDb = null;
        }

        vm.deleteLocation = function (index) {
            vm.locations.splice(index, 1);
        }

        $(document.querySelector('#page')).bind('scroll', function () {
            var scrollPosition = Math.round(document.querySelector('#page').scrollTop);

            var informationSectionOffsetTop = document.querySelector('#information-section').offsetTop;
            var servicesSectionOffsetTop = document.querySelector('#services-section').offsetTop;
            var contactSectionOffsetTop = document.querySelector('#contact-section').offsetTop;
            var referentSectionOffsetTop = document.querySelector('#page').scrollHeight - scrollPosition;
            var offset = 20;

            if (scrollPosition >= informationSectionOffsetTop - offset && scrollPosition <= informationSectionOffsetTop + 20) {
                $(document.querySelector('#information-item')).click();
            } else if (scrollPosition >= servicesSectionOffsetTop - offset && scrollPosition <= servicesSectionOffsetTop + 20) {
                $(document.querySelector('#services-item')).click();
            } else if (scrollPosition >= contactSectionOffsetTop - offset && scrollPosition <= contactSectionOffsetTop + 20) {
                $(document.querySelector('#contact-item')).click();
            } else if (referentSectionOffsetTop >= 689 - offset && referentSectionOffsetTop <= 689 + offset) {
                $(document.querySelector('#referent-item')).click();
            }

        });

        vm.showCustomToast = function (string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        vm.scrollToElement = function (eID) {
            $location.hash(eID);
        };
    }

    PartnersAreaController.$inject = ['$scope', '$state', '$translate', 'partnerInfo', 'PartnerInfoService', '$mdToast', '$timeout', '$location', '$mdDialog', 'userInfo', 'LanguageService'];
    return PartnersAreaController;
});

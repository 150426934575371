/**
 * @ngdoc overview
 * @name workspace.service:WorkspaceService
 */
'use strict';
define(['app'], function (app) {
    app.service('WorkspaceService', WorkspaceService);
    WorkspaceService.$inject = ['$http', 'API', '__env', '$localStorage'];

    function WorkspaceService($http, API, __env, $localStorage) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.workspaceAPI;

        this.getDefaultWorkspace = function () {
            return API.get(apiPath + '/default')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getInvitations = function () {
            return API.get(apiPath + '/invitation')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.deleteUserFromWorkspace = function (params) {
            return API.delete(apiPath + '/' + params.workspaceId + '/user/' + params.userId)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.deleteUserInvitationFromWorkspace = function (email) {
            return API.delete(apiPath + '/invitation?email=' + encodeURIComponent(email))
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.declineInvitations = function (workspaceId) {
            return API.put(apiPath + '/invitation/decline', {
                workspaceId: workspaceId
            })
        };

        this.acceptInvitations = function (workspaceId) {
            return API.put(apiPath + '/invitation/accept', {
                workspaceId: workspaceId
            })
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.leaveWorkSpace = function (workspaceId) {
            return API.delete(apiPath + '/leave/' + workspaceId)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.changeRoleForUser = function (params, workspaceId) {
            return API.put(apiPath + '/' + workspaceId + '/role', {
                userId: params.userId,
                roleId: params.roleId
            })
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        }

        this.updateRoleInvitation = function (params) {
            return API.put(apiPath + '/invitation', {
                email: params.email,
                roleId: params.roleId
            })
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        }

        this.sendInvitations = function (params) {
            return API.post(apiPath + '/invitation', {
                workspaceId: params.workspaceId,
                email: params.email,
                roleId: params.roleId,
            })
        };

        this.createInvoice = function (invoice) {
            return API.post(apiPath + '/invoices/', {invoice: invoice})
                .then(function (res) {
                    if (!res || !res.data || !res.data) {
                        return new Error('Invoice url not found');
                    }

                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getDetailWorkspace = function (params) {
            return API.get(apiPath + '/' + params.id)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getDetailWorkspaceFromHash = function (params) {
            return API.get(apiPath + '/invitation/details?hash=' + params.hash)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getInvoicesWorkspace = function () {
            return API.get(apiPath + '/invoices')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getNextInvoices = function () {
            return API.get(apiPath + '/invoices/next')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getInvoiceById = function (invoiceId) {
            return API.get(apiPath + '/invoices')
                .then(function (res) {
                    return res.data.invoices.filter(function (invoice) {
                        return invoice.id == invoiceId;
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.getWorkspaceAddonPlans = function () {
            return API.get(apiPath + '/addon-plans', true, true);
        };

        this.getAllWorkspaces = function () {
            return API.get(apiPath + '/')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        this.updateDefaultWorkspace = function (newData) {
            return API.put(apiPath + '/default', newData, true);
        };

        this.setWorkspace = function (workspace) {
            if (!workspace.id) {
                throw new Error('invalid workspace');
            }

            $http.defaults.headers.common.Workspace = workspace.id;
            var currentUser = $localStorage.currentUser;
            currentUser.workspace = workspace;
            $localStorage.currentUser = currentUser;
        };

        this.getWorkspace = function () {
            var currentUser = $localStorage.currentUser;
            if (!currentUser || !currentUser.workspace) {
                throw new Error('invalid workspace');
            }

            return currentUser.workspace;
        }

        this.isOwner = function () {
            var currentUser = $localStorage.currentUser;
            if (!currentUser || !currentUser.workspace) {
                return false;
            }

            return $localStorage.currentUser.id === $localStorage.currentUser.workspace.ownerId;
        }
    }
});

'use strict';
define([], function() {
    function SpeedController($scope, $state, $mdMedia, $stateParams) {
        var vm = $scope;
        vm.$mdMedia = $mdMedia;
        vm.isCurrentTab = $state.$current == 'app.seochecker.results.speed';
      
        if (vm.loading) {
            $state.go('app.seochecker.results.overview');
            return;
        }

        vm.performanceAuditsAreNotPresent = Object.entries(vm.performance.mobile).length == 0;

        if (!$stateParams.showTab) {
            vm.showTab = 'mobile';
        } else {
            vm.showTab = $stateParams.showTab;
        }
    
    }
    SpeedController.$inject=['$scope', '$state', '$mdMedia', '$stateParams'];
    return SpeedController;
});

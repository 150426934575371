'use strict';
define([], function () {
    function DigitalGiftBoxController($scope, $translate, subscriptionsInfo, FavIconService, userInfo) {
        var vm = $scope;
        vm.userIsEnabledForPerks = userIsEnabledForPerks();
        vm.userTrialingPlans = userTrialingPlans();
        vm.trialDaysRemaining = countTrialDaysRemaining(vm.userTrialingPlans);

        vm.perksController = {
            userIsEnabledForPerks: function () {
                return vm.userIsEnabledForPerks;
            },
            getFavIcon: function (url) {
                return FavIconService.get(url);
            }
        }

        vm.perks = [
            {
                status: '50% discount on all annual plans and upgrades SEO Tester Online',
                product: 'SEO Tester Online',
                productUrl: 'https://www.seotesteronline.com/',
                description: 'Optimize your SEO, create content that ranks on the first page and monitor the ranking of your website with SEO Tester Online tools.',
                deadline: '10/01/2020',
                claimText: 'Active Now',
                claimAction: 'https://go.seotesteronline.com/en/digitalgiftbox'
            },
            {
                status: '70% discount on all shared hostings + A domain 1 year for free',
                product: 'Siteground',
                productUrl: 'https://www.siteground.com/',
                description: 'Managed hosting with lots of integrated tools to create and manage your sites and those of your customers.',
                deadline: '31/03/2021',
                claimText: 'Activate the promo',
                claimAction: 'https://www.siteground.com/seotesteronline'
            },
            {
                status: '30% discount on the annual plans to the One Minute Site site builder',
                product: 'One minute Site',
                productUrl: 'https://www.oneminutesite.it/',
                description: 'The only online platform that associates an innovative builder with a path that leads you to the publication of the site.',
                deadline: '31/01/2021',
                claimText: 'Redeem Coupon',
                coupon: 'GIFTBOX1MS20',
                claimAction: 'https://www.oneminutesite.it/'
            },
            {
                status: '3 months free of Userbot Professional to create your AI chatbot',
                product: 'Userbot',
                productUrl: 'https://userbot.ai/',
                description: 'Userbot Essential is the chatbot builder that integrates a sophisticated Artificial Intelligence engine based on NLP.',
                deadline: '31/03/2021',
                claimText: 'Redeem Coupon',
                coupon: 'XMAS50',
                claimAction: 'https://userbot.ai/'
            },
            {
                status: '3 months free of Userbot Agency to create your AI chatbot',
                product: 'Userbot',
                productUrl: 'https://userbot.ai/',
                description: 'The version of Userbot dedicated to agencies, where you can create and configure advanced chatbots with Artificial Intelligence for your customers in a few minutes.',
                deadline: '31/03/2021',
                claimText: 'Request the promo',
                claimAction: 'https://userbot.typeform.com/to/LYqK8s5V'
            },
            {
                status: '30 minutes of free consultation with a Creationdose expert for your influencer marketing strategy',
                product: 'Creationdose',
                productUrl: 'https://creationdose.com/',
                description: 'Understand with a Creationdose expert on how to boost your influencer marketing strategy and push your business viral.',
                deadline: '31/03/2021',
                claimText: 'Fill out the form',
                coupon: 'Dose2021',
                claimAction: 'https://vidoser.com/business'
            },
            {
                status: '30% on all Influencer Marketing, Media, Research and Social Amplification services',
                product: 'Creationdose',
                productUrl: 'https://creationdose.com/',
                description: 'Activate the community and boost your influencer marketing with the Creationdose community of Influencers, Talents and Creators.',
                deadline: '31/03/2021',
                claimText: 'Fill out the form',
                coupon: 'Dose2021',
                claimAction: 'https://vidoser.com/business'
            },
            {
                status: '3 months free of Virtual Office Coderblock',
                product: 'Coderblock Workspace',
                productUrl: 'https://coderblock.com/workspace#pricing',
                description: 'Revolutionize your remote work with Coderblock Workspace: an innovative shared workspace to manage your digital collaborations.',
                deadline: '31/03/2021',
                claimText: 'Request the promo',
                claimAction: 'mailto:info@coderblock.com?subject=' + $translate.instant('coderblock.email.subject') +
                    '&body=' + $translate.instant('coderblock.email.body')
            },
            {
                status: '30% discount on your Virtual Events Coderblock',
                product: 'Coderblock Summit',
                productUrl: 'https://summit.coderblock.com',
                description: 'Discover a new way to reach your target: turn your event into virtual. An immersive experience never seen before for your users.',
                deadline: '31/03/2021',
                claimText: 'Request the promo',
                claimAction: 'mailto:info@coderblock.com?subject=' + $translate.instant('coderblock.email.subject') +
                    '&body=' + $translate.instant('coderblock.email.body')
            },
            {
                status: '50% discount on all hosting plans for the first year',
                product: 'VHosting',
                productUrl: 'https://www.vhosting.net',
                description: 'Low cost, wordpress, professional and semi-dedicated hosting services with high performance at low cost.',
                deadline: '31/03/2021',
                claimText: 'Redeem Coupon',
                coupon: 'seo50',
                claimAction: 'https://www.vhosting.net'
            },
        ];

        function userIsEnabledForPerks() {
            if (userInfo && userInfo.email && userInfo.email === 'luca@pselion.net') {
                return true;
            }

            // users are enabled only if they have an active annual plan not pitchground
            return subscriptionsInfo.filter(function (subscription) {
                return subscription.status === 'active' && // TODO: check for expiration date too
                    subscription.plans[0].interval === 'year' &&
                    !subscription.plans[0].alias.startsWith('plan-pg') &&
                    !subscription.plans[0].alias.startsWith('pitchground');
            }).length > 0
        }

        function userTrialingPlans() {
            return subscriptionsInfo.filter(function (subscription) {
                return subscription.status === 'trialing'; // TODO: check for expiration date too
            });
        }

        function countTrialDaysRemaining(trialingPlans) {
            if (trialingPlans.length <= 0) {
                return;
            }

            var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            var firstDate = Date.now();
            var secondDate = new Date(trialingPlans[0].trialEnd);

            var trialDaysRemaining = Math.round(Math.abs((firstDate - secondDate) / oneDay));
            return trialDaysRemaining;
        }

        // remove some data from html template if the user is not enabled to see it
        if (!vm.userIsEnabledForPerks) {
            for (var i = 0; i < vm.perks.length; i += 1) {
                vm.perks[i].claimAction = '';
                vm.perks[i].coupon = '';
            }
        }

    }

    DigitalGiftBoxController.$inject = ['$scope', '$translate', 'subscriptionsInfo', 'FavIconService', 'userInfo'];
    return DigitalGiftBoxController;
});

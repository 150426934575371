'use strict';
define(['app'], function (app) {
    app.service('ItalyService', ItalyService);
    ItalyService.$inject = [];

    function ItalyService() {
        var self = this;
        self.italy = {
            capoluoghi: ["Chieti", "L'Aquila", "Pescara", "Teramo", "Matera", "Potenza", "Catanzaro", "Cosenza",
                "Crotone", "Reggio Calabria", "Vibo Valentia", "Avellino", "Benevento", "Caserta", "Napoli", "Salerno",
                "Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini",
                "Gorizia", "Pordenone", "Trieste", "Udine", "Frosinone", "Latina", "Rieti", "Roma", "Viterbo",
                "Genova", "Imperia", "La Spezia", "Savona",
                "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Milano", "Monza e Brianza", "Pavia", "Sondrio", "Varese",
                "Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino", "Campobasso", "Isernia",
                "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Torino", "Verbano Cusio Ossola", "Vercelli",
                "Bari", "Barletta-Andria-Trani", "Brindisi", "Lecce", "Foggia", "Taranto",
                "Cagliari", "Carbonia-Iglesias", "Medio Campidano", "Nuoro", "Ogliastra", "Olbia-Tempio", "Oristano", "Sassari",
                "Agrigento", "Caltanissetta", "Catania", "Enna", "Messina", "Palermo", "Ragusa", "Siracusa", "Trapani",
                "Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa e Carrara", "Pisa", "Pistoia", "Prato", "Siena",
                "Bolzano", "Trento", "Perugia", "Terni", "Aosta", "Belluno", "Padova", "Rovigo", "Treviso", "Venezia", "Verona", "Vicenza"],
            province: ["CH", "AQ", "PE", "TE", "MT", "PZ", "CZ", "CS", "KR", "RC", "VV", "AV", "BN", "CE", "NA", "SA",
                "BO", "FE", "FC", "MO", "PR", "PC", "RA", "RE", "RN", "GO", "PN", "TS", "UD", "FR", "LT", "RI", "RM", "VT", "GE", "IM", "SP", "SV",
                "BG", "BS", "CO", "CR", "LC", "LO", "MN", "MI", "MB", "PV", "SO", "VA", "AN", "AP", "FM", "MC", "PU", "CB", "IS",
                "AL", "AT", "BI", "CN", "NO", "TO", "VB", "VC", "BA", "BT", "BR", "LE", "FG", "TA",
                "CA", "CI", "VS", "NU", "OG", "OT", "OR", "SS", "AG", "CL", "CT", "EN", "ME", "PA", "RG", "SR", "TP",
                "AR", "FI", "GR", "LI", "LU", "MS", "PI", "PT", "PO", "SI", "BZ", "TN", "PG", "TR", "AO", "BL", "PD", "RO", "TV", "VE", "VR", "VI"]
        };

        return {
            all: all,
        };

        function all() {
            var italy = self.italy.capoluoghi.map(function (item, index) {
                return {
                    provincia: item,
                    provinciaCode: self.italy.province[index]
                };
            });
            return italy;
        }
    }
});

'use strict';
define([],function(){
    controller.$inject = ['$scope'];
    function controller($scope) {
        var ctrl = this;
        ctrl.checked=false;
        $scope.$watch('$ctrl.checked', function (checked) {
            ctrl.checked=checked;
            $scope.checked=checked;
        });

        this.$onInit = function() {
            $scope.cardMini = ctrl.cardMini;
            $scope.checkmarkFilledGreen = ctrl.checkmarkFilledGreen;
        }
    }
    return {
        templateUrl: 'app/components/st-card-combo/st-card-combo.html',
        transclude:true,
        bindings: {
            checked: '<',
            cardMini: '<',
            checkmarkFilledGreen: '<'
        },
        controller: controller
    }
});
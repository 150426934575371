'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$state,leadsLocked) {
        var vm = $scope;
        vm.isActive=isActive;
        vm.setPricingTypeId=setPricingTypeId;
        vm.checkout = checkout;
        vm.lockedLeads=0;
        vm.pricing={
            pricingTypedId:2,
        };
        leadsLocked.forEach(function(element) {
            element.data.forEach(function(lead){
                if(lead.locked==1){
                    vm.lockedLeads=vm.lockedLeads+1;
                }
            })
        });
        function setPricingTypeId(id){
            vm.pricing.pricingTypedId=id;
        }
        function isActive(id){
            return vm.pricing.pricingTypedId==id;
        }
        function checkout(){
            if(vm.pricing.pricingTypedId == 1){
                $state.go('upgrade.checkout',{alias: 'addon-lead-gen-tool-leads-unlock-plus-25-v1'});
            }else if(vm.pricing.pricingTypedId == 2){
                $state.go('upgrade.checkout',{alias: 'addon-lead-gen-tool-leads-unlock-plus-10-v1'});
            }else{
                $state.go('upgrade.checkout',{alias: 'addon-lead-gen-tool-leads-unlock-plus-5-v1'});
            }
        }
    }
    Controller.$inject=['$scope','$state','leadsLocked'];
    return Controller;
});

 'use strict';
 define(['app'], function (app) {
     app.service('DealsService', DealsService);
     DealsService.$inject = ['__env', 'API'];
 
     /**
      * Deals Service
      * @namespace DealsService
      */
     function DealsService(__env, API) {
         var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
         var apiPath = apiBaseUrl + __env.dealsAPI;
 
         return {
             redeem: redeem,
         };
 
         function redeem(data) {
            return API.post(apiPath + '/redeem', data, true);
         }
     }
 });
 
'use strict';
define([], function() {
    function ResetController($scope, $translate, Auth, $timeout, $stateParams) {
        var vm = $scope;
        var thisTimeout;
        var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
        vm.data = {
            reset: {pass: null, rePass: null, lang: currentLang},
        };
        vm.loading = false;
        vm.passwordWasChanged = false;

        vm.getPasswordStrenght = getPasswordStrenght;
        vm.reset = reset;
        vm.strengthPasswordIndicator = "weak";

        function reset(form) {
            if (vm.loading) {
                return;
            }

            vm.loading = true;
            $timeout.cancel(thisTimeout);

            if (
                !vm.data
                || !vm.data.reset
                || !vm.data.reset.pass
                || !vm.data.reset.pass.trim()
            ) {
                vm.loading = false;
                return;
            }

            if (vm.data.reset.pass !== vm.data.reset.rePass) {
                vm.loading = false;
                form.rePass.$setValidity('unmatchedPasswords', false);
    
                $timeout(function () {
                    form.$setUntouched();
                    form.$setPristine();
                    form.rePass.$setValidity('unmatchedPasswords', true);
                }, 6000);
                return;
            }

            Auth.reset({
                token: $stateParams.token,
                newPass: vm.data.reset.pass,
                lang: vm.data.reset.lang
            })
                .then(function () {
                    $timeout(function () {
                        //reset complete
                        vm.loading = false;
                        vm.passwordWasChanged = true;
                    }, 3500);
                })
                .catch(function (err) {
                    console.log(err);
                    vm.loading = false;
                });
        }

        function getPasswordStrenght(pass) {
            var score = 0;
            if (!pass) {
                return score;
            }

            // award every unique letter until 5 repetitions
            var letters = new Object();
            for (var i=0; i<pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                score += 5.0 / letters[pass[i]];
            }

            // bonus points for mixing it up
            var variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass),
            }

            var variationCount = 0;
            for (var check in variations) {
                variationCount += (variations[check] == true) ? 1 : 0;
            }
            score += (variationCount - 1) * 10;

            if (score > 80) {
                vm.strengthPasswordIndicator = "strong";
                vm.strengthPasswordColor = "#3AB526";
            } else if (score > 60) {
                vm.strengthPasswordIndicator = "good";
                vm.strengthPasswordColor = "#FFC700";
            } else if (score >= 0) {
                vm.strengthPasswordIndicator = "weak";
                vm.strengthPasswordColor = "#FE001A";
            }

            return parseInt(score);
        }
        
    }
    ResetController.$inject=['$scope', '$translate', 'AuthService', '$timeout', '$stateParams'];
    return ResetController;
});

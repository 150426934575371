'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/http-status/301/301.controller',
],function(
    angular,
    SPHttpStatus301Ctrl
){
    var app=angular.module('app.seospider.exam.analysis.httpstatus.301', []);
    app.controller("SPHttpStatus301Ctrl",SPHttpStatus301Ctrl);
    return app;
});
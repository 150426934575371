'use strict';
define([], function () {
    function CustomizationsController($scope, workspaceInfo, WorkspaceService, $mdToast, $translate, $rootScope, $localStorage, $element, $timeout) {
        var vm = $scope;

        if (workspaceInfo) {
            vm.workspace = {
                name: workspaceInfo.name,
                logoImgUrl: workspaceInfo.logoImgUrl,
            }
            vm.file = {};
            vm.file.resourceUrl = workspaceInfo.logoImgUrl;
        }

        vm.updateWorkspaceInfo = function (file) {
            var newWorkspaceInfo = {};
            newWorkspaceInfo.logoImgUrl = file.resourceUrl;
            newWorkspaceInfo.name = vm.workspace.name;

            WorkspaceService.updateDefaultWorkspace(newWorkspaceInfo)
                .then(function (res) {
                    workspaceInfo.name = newWorkspaceInfo.name;
                    workspaceInfo.logoImgUrl = newWorkspaceInfo.logoImgUrl;

                    vm.showCustomToast($translate.instant('settings.updated.info'), 'icon-checkmark st-green');
                    WorkspaceService.setWorkspace(workspaceInfo);
                    $rootScope.$broadcast("switchWorkSpace", $localStorage.currentUser);

                })
                .catch(function (err) {
                    console.log(err)
                    vm.showCustomToast($translate.instant('settings.updated.error'), 'icon-x st-red');
                });
        }

        vm.showCustomToast = function (string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        vm.openMedia = function() {
            $timeout(function(){
                $element[0].querySelector('#media-button button').click();
            });
        }
    }

    CustomizationsController.$inject = ['$scope', 'workspaceInfo', 'WorkspaceService', '$mdToast', '$translate', '$rootScope', '$localStorage', '$element', '$timeout'];
    return CustomizationsController;
});

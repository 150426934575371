'use strict';
define([], function() {
    function Controller($scope, $mdDialog, LanguageService, $window) {
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;
        $scope.goToForm = goToForm;
        $scope.goToWebsite = goToWebsite;
        
        function answerDialog() {
        
        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }

        function goToForm() {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();
            if (language === 'it-IT') {
                $window.open("https://seotesteronline.typeform.com/to/m0IDteNV", '_blank');
            } else if (language === 'es-ES') {
                $window.open("https://seotesteronline.typeform.com/to/PU9OHpGg", '_blank');
            } else {
                $window.open("https://seotesteronline.typeform.com/to/F1R9Vz68", '_blank');
            }
        }

        function goToWebsite() {
            $window.open("https://partner.seotesteronline.com", '_blank');
        }
        
    }
    Controller.$inject=['$scope', '$mdDialog', 'LanguageService', '$window'];
    return Controller;
});
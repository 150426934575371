'use strict';
define([
    'angular',
    'modules/seochecker/results/results.controller',
    'modules/seochecker/results/structure/structure.module',
    'modules/seochecker/results/speed/speed.module',
    'modules/seochecker/results/social/social.module',
    'modules/seochecker/results/overview/overview.module',
    'modules/seochecker/results/content/content.module',
],function(angular, SEOCheckerResultsCtrl){
    var app=angular.module('app.seochecker.results', [
        'app.seochecker.results.structure',
        'app.seochecker.results.speed',
        'app.seochecker.results.social',
        'app.seochecker.results.overview',
        'app.seochecker.results.content',
    ]);
    app.controller('SEOCheckerResultsCtrl',SEOCheckerResultsCtrl);
    return app;
});
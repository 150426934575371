'use strict';
define([], function () {


    return {
        templateUrl: 'app/modules/keywordexplorer/components/keyword-difficulty/keyword-difficulty.html',
        bindings: {
            metric: '<',
            backlinks: '<',
            loading: '<',
            locked: '<',
            theme: '<',
            tool: '@'
        },
        controller: ['$scope', function ($scope) {
            var vm=$scope;

            var colors=[
                '#049961',
                '#4ca74d',
                '#91b73a',
                '#c5be22',
                '#eebd00',
                '#ffad02',
                '#fe8b04',
                '#fc6214',
                '#ff0f00',
                '#cc0605',
            ];
            var DIFFICULTY_SCORE_BEHAVIOR=[
                {
                    label: "Easy",
                    from: 0,
                    until: 10,
                    color: colors[0],
                },
                {
                    label: "Easy",
                    from: 10,
                    until: 20,
                    color: colors[1],
                },
                {
                    label: "Easy",
                    from: 20,
                    until: 30,
                    color: colors[2],
                },
                {
                    label: "Medium",
                    from: 30,
                    until: 40,
                    color: colors[3],
                },
                {
                    label: "Medium",
                    from: 40,
                    until: 50,
                    color: colors[4],
                },
                {
                    label: "Hard",
                    from: 50,
                    until: 60,
                    color: colors[5],
                },
                {
                    label: "Hard",
                    from: 60,
                    until: 70,
                    color: colors[6],
                },
                {
                    label: "Hard",
                    from: 70,
                    until: 80,
                    color: colors[7],
                },
                {
                    label: 'Very Hard',
                    from: 80,
                    until: 90,
                    color: colors[8],
                },
                {
                    label: 'Very Hard',
                    from:  90,
                    until: 100,
                    color: colors[9],
                }
            ];




            var getDatasetByDifficultyScore = function (score) {
                var analyzedRange;
                var remainingToCheck=score;
                var chartData=[];
                var chartColors=[];
                var i;
                for(i=0;i<DIFFICULTY_SCORE_BEHAVIOR.length;i++){
                    analyzedRange=DIFFICULTY_SCORE_BEHAVIOR[i];
                    var totalRangeSize=analyzedRange.until-analyzedRange.from;
                    var isLessThanRangeSize=remainingToCheck-totalRangeSize>0;
                    if(isLessThanRangeSize){
                        /* fill the pie chart with color sized
                         with the current amount of range size
                          in exceeded */
                        remainingToCheck-=totalRangeSize;
                        chartData.push(totalRangeSize);
                        chartColors.push(analyzedRange.color);

                    }
                    else{
                        /* fill the pie chart with the remaining part
                        of current amount and fill the rest with the
                         no data color */
                        chartData.push(remainingToCheck);
                        chartColors.push(analyzedRange.color);
                        chartData.push(100-score);
                        chartColors.push("#C2D1D9");

                        break;
                    }
                }
                var finalLabel='';
                var isTheCorrectRange;
                for(i=0;i<DIFFICULTY_SCORE_BEHAVIOR.length;i++){
                    isTheCorrectRange=score>analyzedRange.from&&score<=analyzedRange.until;
                    if(isTheCorrectRange){
                        finalLabel=analyzedRange.label;
                    }
                }
                $scope.metricLabel=finalLabel;
                $scope.chartData=chartData;
                $scope.chartColors=chartColors;
            };
            $scope.chartOpts = {
                showTooltips: false,
                /*
                    circumference: 1.5 * Math.PI,
                    rotation: -1.25 * Math.PI,
                    circumference: 1.45*Math.PI,
                    rotation: 0.78*Math.PI,
                */
                circumference: 1.35*Math.PI,
                rotation: 0.83*Math.PI,
                cutoutPercentage: 70,
                legend: {
                    display: false
                },

                elements: {
                    arc: {
                        borderWidth: 1
                    }
                },
                tooltips: {
                    enabled: false
                },segmentShowStroke: false,
                hover: false
            };
            $scope.$watch('$ctrl.metric', function (metric) {
                getDatasetByDifficultyScore(metric);
            });
            vm.checkDifficultyFormat=function(score){
                if(!score || score==null || score==undefined)
                    return 5;
                if(score<=5)
                    return 5;
                if(score>=100)
                    return 99;
                return score;
            };

            vm.getColorByScore=function(score){
                if (!score) {
                    return '#ddd';
                }
                if (score>=70) {
                    return '#DB3236';
                } else if (score>=30) {
                    return '#EE671A';
                } else if (score>=10) {
                    return '#FFBE00';
                } else {
                    return '#6CBF11';
                }

            };

            vm.getLabelByScore=function(score){
                if (!score) {
                    return '-';
                }
                if (score>=70) {
                    return 'keyword.exp.very.high';
                } else if (score>=30) {
                    return 'keyword.exp.high';
                } else if (score>=10) {
                    return 'keyword.exp.medium';
                } else {
                    return 'keyword.exp.low';
                }
            };
        }]
    };
});

'use strict';
define([
    'angular',
    'modules/keywordlistmanager/keywordlistmanager.routes',
    'modules/keywordlistmanager/components/input-list-name/input-list-name.component',
    'modules/keywordlistmanager/landing/landing.module',
    'modules/keywordlistmanager/list/list.module',
    'modules/keywordlistmanager/keywordlist/keywordlist.module',
    'modules/keywordlistmanager/wizard/wizard.module',
    'modules/keywordlistmanager/shared/shared.module',
    'modules/keywordlistmanager/dialogs/dialogs.module',
], function (angular, routes, inputListName) {
    var app = angular.module('app.keywordlistmanager', [
        'app.keywordlistmanager.landing',
        'app.keywordlistmanager.list',
        'app.keywordlistmanager.keywordlist',
        'app.keywordlistmanager.wizard',
        'app.keywordlistmanager.shared',
        'app.keywordlistmanager.dialogs'
    ]);

    app.component('inputListName', inputListName);

    app.config(routes);

    return app;
});

'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$rootScope,$stateParams,$q,results,jobId, Pages, $translate) {
        var vm = $scope;
        vm.pages=results.data;
        vm.query='';
        vm.jobId=jobId;
        vm.openDetail=openDetail;
        vm.pagination={
            numElements:results.totalRows,
            offset:0,
            callback: pagingCallback
        };
        vm.currentSearch={
            placeholder: $translate.instant('seospider.title.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged',function () {
            vm.currentSearch={
                placeholder: $translate.instant('seospider.title.search'),
                callback: searchCallback
            };
        });
        function openDetail(info){
            $rootScope.$emit('openToolbarDetail',{
                list:info.duplicated,
                element: 'title',
                label: $translate.instant('seospider.duplicated.in.page'),
                placeholder: $translate.instant('seospider.title.filter',{'title':info.title})
            });
            $rootScope.$on('languageChanged',function () {
                $rootScope.$emit('openToolbarDetail', {
                        list:info.duplicated,
                        element: 'title',
                        label: $translate.instant('seospider.duplicated.in.page'),
                        placeholder: $translate.instant('seospider.title.filter',{'title':info.title})
                    }
                );
            });
        }
        function searchCallback(query){
            vm.query=query;
            vm.pagination.offset=0;
            Pages.findAllTitleDuplicated({
                jobId:$stateParams.sessionId,
                q:query,
                offset:0
            }).
            then(function(res){
                    vm.pagination.offset=0;
                    vm.pagination.numElements=res.totalRows;
                    vm.pages=res.data;
                },
                function(err){
                    vm.pages=err.data;
                });
        }
        function pagingCallback(limit,offset){
            var dfd = $q.defer();
            Pages.findAllTitleDuplicated({
                jobId:$stateParams.sessionId,
                q:vm.query,
                limit:limit,
                offset:offset
            }).
            then(function(res){
                vm.pages=res.data;
            },function(err){
                vm.pages=err.data;
                dfd.resolve();
            });
            return dfd.promise;
        }
    }
    Controller.$inject=['$scope','$rootScope','$stateParams','$q','results','jobId','SeospiderPagesService', '$translate'];
    return Controller;
});

'use strict';
define([], function() {
    function GetStartedNowController($scope, userInfo) {

        var vm = $scope;
        vm.canDoTrial = userInfo.canDoTrial;
       
    }
    GetStartedNowController.$inject=['$scope', 'userInfo'];
    return GetStartedNowController;
});

'use strict';
define(['app', 'socket.io-client'], function (app, io) {
    app.factory('SocketFactory', SocketFactory);

    SocketFactory.$inject = ['__env', '$rootScope'];

    function SocketFactory(__env, $rootScope) {
        var socket = getSocket(__env.socketUrl);

        return {
            on: on,
            emit: emit,
        };

        function getSocket(socketUrl) {
            if (!socketUrl) {
                return;
            }

            return io.connect(socketUrl);
        }

        function on(eventName, callback) {
            if (!socket) {
                return;
            }

            socket.on(eventName, function () {
                var args = arguments;
                $rootScope.$apply(function () {
                    callback.apply(socket, args);
                });
            });
        }

        function emit(eventName, data, callback) {
            if (!socket) {
                return;
            }

            socket.emit(eventName, data, function () {
                var args = arguments;

                $rootScope.$apply(function () {
                    if (callback) {
                        callback.apply(socket, args);
                    }
                });
            });
        }
    }
});

define(['jquery'], function ($) {
    controller.$inject = ['$scope', '$rootScope', '$document', '$element'];

    function controller($scope, $rootScope, $document, $element) {
        var vm = $scope;
        var element = $($element);



        vm.isPopupVisible = false;

        vm.toggleDropdown = function () {
            vm.isPopupVisible = !vm.isPopupVisible;
        };

        $document.bind('click', function (event) {
            var isClickedElementChildOfPopup = element
                .find(event.target)
                .length > 0;

            if (isClickedElementChildOfPopup)
                return;
            vm.isPopupVisible = false;
            vm.$apply();
        });
    }
    return {
        templateUrl: 'app/components/st-dropdown/st-dropdown.html',
        transclude: {
            title: 'stDropdownTitle',
            content: 'stDropdownContent',
            footer: 'stDropdownFooter',
        },
        bindings: {
            disableArrowDown: "<",
            stBgImage:'=',
            stBgPosition:'='
        },
        replace: true,
        controller: controller
    };
});

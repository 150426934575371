define([], function () {
    controller.$inject = ['$scope', '$rootScope', '$translate', '$state', '$mdSidenav', 'AuthService', 'UserService', '$location'];

    function controller($scope, $rootScope, $translate, $state, $mdSidenav, AuthService, UserService, $location) {

        var vm=$scope;
        loadMemberLimit();
        function loadMemberLimit() {
            if (!AuthService.isAuthenticated()) {
                return;
            }
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'workspace-users-limit') {
                                vm.maxMembers = (service.qtyAllowed === 1) ? service.qtyAllowed : service.qtyAllowed + 1;
                            }
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }
    }

    return {
        templateUrl: 'app/modules/template/components/st-default-workspace-navbar/st-default-workspace-navbar.html',
        controller: controller,
        bindings: {currentUser:'='}
    };
});

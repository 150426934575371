'use strict';
define([], function () {
    function Controller(
        $scope,
        settings,
        $stateParams,
        $state,
        $mdToast,
        $translate,
        ProjectsService,
        SeospiderService,
        $mdDialog,
        $debounce,
        $rootScope,
        $window
    ) {
        if ($state.transition) return;

        if ($rootScope.previousState !== 'app.project.settings') {
            $scope.backState = $rootScope.previousState;
            $scope.backParams = $rootScope.previousParams;

            $window.localStorage.setItem('backState', $scope.backState);
            $window.localStorage.setItem('backParams', JSON.stringify($scope.backParams));
        }

        if (!$scope.backState) {
            $scope.backState = $window.localStorage.getItem('backState');
            $scope.backParams = $window.localStorage.getItem('backParams');
            if (typeof $scope.backParams === 'string') {
                $scope.backParams = JSON.parse($scope.backParams);
            }
        }

        var tabKeys = {
            'scope': 0,
            'audit': 1,
            'tracker': 2,
            'competitors': 3
        };
        $scope.showDropAlert = showDropAlert;
        $scope.setActiveTab = setActiveTab;
        $scope.saveSettings = saveSettings;
        $scope.goToPreviousPage = goToPreviousPage;
        $scope.settings = settings;

        $scope.activeTab = tabKeys[$stateParams.activeTab] ? tabKeys[$stateParams.activeTab] : 0;
        $scope.loading = {
            post: false
        };
        $scope.projectName = $scope.settings.project.name;
        if ($scope.settings.spider && $scope.settings.spider.respectRobots) {
            $scope.settings.spider.respectRobots = settings.spider.respectRobots === 1;
        }

        $scope.settings.project.url = settings.project.linkUrl;
        $scope.settings.project.competitors = settings.project.competitors ? settings.project.competitors.map(
            function (competitor) {
                return {
                    id: competitor.id,
                    url: competitor.linkUrl,
                    name: competitor.name,
                    domainType: competitor.domainType
                };
            }) : [];

        function showDropAlert(project, ev) {
            project.projectId = project.id;
            $mdDialog.show({
                controller: 'ProjectDeleteProjectCtrl',
                templateUrl: "app/modules/project/dialogs/delete-project/delete-project.html",
                targetEvent: ev,
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project: project
                }
            }).then(function (answer) {
                if ($scope.backState == 'app.keywordtracker.overview' || $scope.backState == 'app.project.settings') {
                    $state.go('app.keywordtracker.landing');
                    return;
                }

                if ($scope.backState == 'app.seospider.exam.analysis.report.overview' || $scope.backState == 'app.project.settings') {
                    $state.go('app.seospider.list');
                    $state.go('app.seospider.exam.analysis.report.overview');
                    return;
                }

                goToPreviousPage();
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        function goToPreviousPage() {
            if ($scope.backState) {
                $state.go($scope.backState, $scope.backParams);
                return;
            }

            $window.history.back();
        }

        function saveSettings() {
            $debounce(saveSettingsAction, 700);
        }

        function saveSettingsAction() {
            if ($scope.activeTab === 0) {
                var newScopeSettings = {
                    protocol: $scope.settings.project.protocol,
                    linkUrl: $scope.settings.project.url,
                    domainType: $scope.settings.project.domainType,
                    name: $scope.settings.project.name
                };

                if ($scope.projectForm.$invalid) {
                    showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                } else {
                    ProjectsService.saveSettings($scope.settings.project.id, newScopeSettings)
                        .then(function (res) {
                            showCustomToast('project.wizard.project.saved', 'icon-checkmark st-green');
                            $scope.loading.post = false;
                            $scope.projectName = $scope.settings.project.name;
                        })
                        .catch(function (err) {
                            showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                            $scope.loading.post = false;
                            $window.console.log(err);
                        });
                }

            } else if ($scope.activeTab === 1) {
                var newSpiderSettings = {
                    maxDepth: $scope.settings.spider.maxDepth,
                    respectRobots: $scope.settings.spider.respectRobots,
                    speed: $scope.settings.spider.speed,
                    userAgent: $scope.settings.spider.userAgent,
                    resourcesLimit: $scope.settings.spider.resourcesLimit,
                    scheduleFrequency: $scope.settings.spider.scheduleFrequency,
                    scheduleStartDay: $scope.settings.spider.scheduleStartDay,
                    scheduleStartHour: $scope.settings.spider.scheduleStartHour,
                    scheduleTimezoneOffset: $scope.settings.spider.scheduleTimezoneOffset,
                    runScheduled: $scope.settings.spider.runScheduled ? 1 : 0,
                };

                SeospiderService.saveSettings($scope.settings.spider.id, newSpiderSettings)
                    .then(function (res) {
                        showCustomToast('project.wizard.project.saved', 'icon-checkmark st-green');
                        $scope.loading.post = false;
                        $scope.projectName = $scope.settings.project.name;
                    }).catch(function (err) {
                    showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                    $scope.loading.post = false;
                    $window.console.log(err);
                });
            }
        }

        function setActiveTab(tabNumber) {
            $scope.activeTab = tabNumber;
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }
    }

    Controller.$inject = [
        '$scope',
        'settings',
        '$stateParams',
        '$state',
        '$mdToast',
        '$translate',
        'ProjectsService',
        'SeospiderService',
        '$mdDialog',
        '$debounce',
        '$rootScope',
        '$window'
    ];
    return Controller;
});

'use strict';
define([], function () {
    var LOADING_LABELS = [
        'seochecker.loader.alert.im.turning.on.the.ballasts',
        'seochecker.loader.alert.im.analyzing.the.seo.of.the.page',
        'seochecker.loader.alert.im.analyzing.structured.data',
        'seochecker.loader.alert.im.drawing.a.new.orbital.plane',
        'seochecker.loader.alert.im.analyzing.the.html.tags',
        'seochecker.loader.alert.im.analyzing.the.ux.and.performances',
        'seochecker.loader.alert.im.checking.for.collisions.with.asteroids',
        'seochecker.loader.alert.im.analyzing.your.content'
    ];

    return {
        templateUrl: 'app/modules/seospider/exam/analysis/components/website-preview/website-preview.html',
        bindings: {
            loading: '<',
            isCrawling: '<',
            customClass: '<',
            url: '<',
        },
        controller: ['$scope', '$interval', 'PagespeedService', '$q', function ($scope, $interval, PagespeedService, $q) {
            var vm = $scope;
            var labels = Object.assign([], LOADING_LABELS);
            var randomIndex = Math.floor(Math.random() * labels.length);
            labels.splice(randomIndex, 1);
            vm.loadingLabelsToShow = labels[randomIndex];

            function showMessage() {
                if (!vm.isCrawling) {
                    vm.loadingLabelsToShow = "seospider.generating.the.website.preview";
                } else {
                    if (vm.loading) {
                        $interval(function () {
                            randomIndex = Math.floor(Math.random() * labels.length);
                            if (labels.length !== 1) {
                                vm.loadingLabelsToShow = labels[randomIndex];
                                labels.splice(randomIndex, 1);
                            } else if (labels.length === 1) {
                                labels = Object.assign([], LOADING_LABELS);
                            }
                        }, 2500);
                    }
                }
            }

            vm.screenshotInProgress = false;
            this.$onChanges = function () {
                vm.pageNotFound = false;
                vm.loading = this.loading;
                vm.isCrawling = this.isCrawling;

                if (!this.url || vm.screenshotInProgress) {
                    vm.pageNotFound = true;
                    return;
                }

                vm.screenshotInProgress = true;
                $q.all([
                    PagespeedService.getScreenshot(this.url, 'desktop', 30),
                    PagespeedService.getScreenshot(this.url, 'mobile', 30),
                ])
                    .then(function (result) {
                        if (result[0] && result[0].src) {
                            vm.screenshotDesktop = result[0].src;
                        }

                        if (result[1] && result[1].src) {
                            vm.screenshotMobile = result[1].src;
                        }

                        showMessage();
                        vm.screenshotInProgress = false;
                    })
                    .catch(function (err) {
                        console.error(err);
                        showMessage();
                        vm.screenshotInProgress = false;
                    });
            };
        }]
    };
});

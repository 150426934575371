'use strict';
define([], function () {

    return {
        templateUrl: 'app/modules/seospider/components/select-session/select-session.html',
        bindings: {
            inCrawling: '<',
            projectId: '<',
            sessions: '<',
            sessionId: '<',
            projectSpiderUrl: '<',
            selectSession: '=',
        },
        controller: ['$scope', 'SeospiderService', '$state', '$stateParams', function ($scope, SeospiderService, $state, $stateParams) {
            var vm = $scope;
            var ctrl = this;
            vm.sessions = [];
            vm.selectSession = {};

            vm.$on("startCrawling", function (evt, newSession) {
                vm.sessionId=undefined;
                vm.sessions.push(newSession);
            });
            vm.$on("inCrawling", function (evt, session) {
                setSessionStatus(session.sessionId, SeospiderService.getStates().RUNNING);
            });
            vm.$on("crawlFinished", function (evt, session) {
                setSessionStatus(session.sessionId, SeospiderService.getStates().FINISHED);
            });
            vm.$on("stopCrawling", function (evt, session) {
                setSessionStatus(session.sessionId, SeospiderService.getStates().STOPPING);
            });

            function setSessionStatus(sessionId, status = 'finished') {
                for (var i = 0; i < vm.sessions.length; i++) {
                    if (vm.sessions[i].id == sessionId) {
                        vm.sessions[i].status = status;
                    }
                }
            }

            ctrl.$onInit = function () {
                vm.sessions = ctrl.sessions || [];
                vm.projectId = ctrl.projectId || null;
                vm.sessionId = ctrl.sessionId || null;
                if (!vm.sessions || !vm.sessions.length) {
                    SeospiderService
                        .getSessions(ctrl.projectId)
                        .then(function (res) {
                            vm.sessions = res.data.sessions;
                        })
                        .catch(function (err) {
                            vm.sessions = [];
                        });
                }
            };

            function nth(d) {
                if (d > 3 && d < 21) return 'th';
                switch (d % 10) {
                    case 1:
                        return "st";
                    case 2:
                        return "nd";
                    case 3:
                        return "rd";
                    default:
                        return "th";
                }
            }


            vm.formatDate = function (date) {
                var localLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                var options = {
                    month: 'long',
                    day: 'numeric'
                };
                if (localLang === 'en-US') {
                    return new Date(date).toLocaleDateString(localLang, options) + nth(new Date(date).getDate());
                }
                return new Date(date).toLocaleDateString(localLang, options);
            }

            vm.selectSession = function (session) {
                ctrl.selectSession(session);
                if ($state.current.name != 'app.seospider.exam.analysis.report.overview') {
                    $state.go('app.seospider.exam.analysis.report.overview', {
                        projectId: session.crawlerProjectId,
                        sessionId: session.id,
                    });
                }
            };

        }]
    };
});

'use strict';
define([], function () {
    function SinglePageImagesCtrl($scope) {
        $scope.projectUrl = null;
        $scope.currentNavItem = 'table';

        var init = function () {
            if (!$scope.projectUrl) {
                return;
            }
        }

        $scope.isInternalLink = isInternalLink;
        $scope.secureUrl = secureUrl;
        $scope.setTab = function (tab) {
            $scope.currentNavItem = tab;
        };

        function secureUrl(url) {
            var root = $scope.detail.url.replace(/\/$/g, '');
            if (isRelative(url)) {
                if (!url) url = '';
                url = url.replace(/^\//g, '');
                return root + "/" + url;
            }
            return url;
        }

        function isRelative(url) {
            return !new RegExp(/^http[s]{0,1}\:\/\//i).test(url) && !new RegExp(/^\/\//i).test(url);
        }

        function isInternalLink(domainUrl, urlToCheck) {
            if (!$scope.projectUrl) {
                return;
            }

            var isValidUrl = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
            if (isRelative(urlToCheck)) {
                return true;
            } else {
                if (isValidUrl.test(urlToCheck)) {
                    var thisHost = new URL(urlToCheck).host.replace(/^www./gi, '');
                    if (thisHost.toLowerCase() === $scope.projectUrl) {
                        return true;
                    } else return false;
                } else return true;
            }
        }

        var getUrl = function (project) {
            if (!project || !project.url || !project.protocol) {
                return;
            }

            return project.url.replace(/\/$/, "");
        }

        init();
    }

    SinglePageImagesCtrl.$inject = ['$scope'];
    return SinglePageImagesCtrl;
});

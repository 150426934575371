'use strict';
define([], function () {

    function controller($scope, $state, $translate, PaypalService) {
        var ctrl = this;
        var vm = $scope;

        // ALL OUR PLAN
        vm.listPlan = function () {
            var listPlan = PaypalService.listPlan();
            listPlan.then(function (response) {
                vm.listAllPlan = response.data.data.data.body.plans;
            });

        };

        // DETAILS PLAN SUBSCRIBER
        vm.detailsPlan = function () {
            var detailsPlan = PaypalService.detailsPlan();
            detailsPlan.then(function (response) {});
        };
    }

    controller.$inject = ['$scope', '$state', '$translate', 'PaypalService'];
    return {
        templateUrl: 'app/modules/settings/components/paypal/paypal-subscriptions-info.html',
        controller: controller
    };

});

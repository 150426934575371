'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/access-components/st-signin/st-signin.html',
        bindings: {
            service: '=',
            onAuth: '=',
            redirectTo: '<',
            workspaceId: '<',
            triggerSrc: '<',
            dialogMode: '<',
            params: '<',
            hash: '<',
        },
        controller: ['$translate', '$window', '$scope', '$state', 'AuthService', '$timeout', 'Facebook',
            'TrackEventService', 'PendingActionsService', '$location', '$rootScope', 'UtilitiesService',
            'WorkspaceService', '$mdMedia', 'LanguageService', 'UserService', 'UsetifulService', '__env',
            function ($translate, $window, $scope, $state, Auth, $timeout, Facebook,
                      TrackEventService, PendingActionsService, $location, $rootScope, UtilitiesService,
                      WorkspaceService, $mdMedia, LanguageService, UserService, UsetifulService, __env) {
                $window.___gcfg = {
                    isSignedOut: true
                };
                var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
                var ctrl = this;

                $scope.emailPattern = UtilitiesService.getEmailRegex();
                $scope.onAuth = null;
                $scope.$mdMedia = $mdMedia;

                $scope.$watch('$ctrl.service', setService);
                $scope.$watch('$ctrl.onAuth', setOnAuth);

                ctrl.$onInit = function () {
                };

                var hash = $location.search().hash;
                if (hash) {
                    var params = {
                        hash: hash
                    };

                    WorkspaceService.getDetailWorkspaceFromHash(params)
                        .then(function (res) {
                            $scope.workspaceLogoUrl = res.logoImgUrl;
                            $scope.workspaceName = res.name;
                            params.id = res.id;
                            $scope.workspaceId = res.id;
                        })
                        .catch(function (err) {
                            console.log('err', err);
                        });
                }

                if (($rootScope.currentState === 'access.signin' ||
                        $rootScope.currentState === 'access.signup') &&
                    !angular.equals($location.$$search, {})) {
                    PendingActionsService.register($location.$$search);
                }

                $scope.loading = false;

                $scope.data = {
                    signin: {
                        email: null,
                        pass: null,
                        lang: currentLang
                    },
                };

                $scope.goSignup = goSignup;
                $scope.goToForgotPassword = goToForgotPassword;
                $scope.signin = signin;
                $scope.loginFacebook = loginFacebook;
                $scope.$on('event:google-plus-signin-success', loginGoogleEvent);

                var thisTimeout;

                $scope.$onAuth = function () {
                    usetifulBootStrap();
                    if ($scope.onAuth) {
                        $scope.onAuth();
                    } else {
                        var stateOptions = {};
                        var stateObject = {};

                        if (ctrl.dialogMode) {
                            $scope.$parent.cancel();
                            stateOptions = {
                                reload: true
                            };
                        }

                        if (ctrl.params) {
                            stateObject = ctrl.params;
                        }

                        if ($window.localStorage.getItem('coupon') === 'SITEGROUNDDISCOUNT') {
                            $state.go('upgrade.checkout', {
                                alias: 'plan-siteground-v1'
                            });
                        } else if (ctrl.redirectTo) {
                            $state.go(ctrl.redirectTo, stateObject, stateOptions);
                        } else {
                            $state.go('app.welcome', stateObject, stateOptions);
                        }
                    }

                    PendingActionsService.execute($scope, function somethingChanged() {
                        // Handle property changes if you need
                    });
                };

                /**
                 * BOOTSTRAP USETIFUL
                 */
                function usetifulBootStrap() {
                    if (__env.stage === 'local') {
                        return;
                    }

                    var user = Auth.getSessionUser();
                    if (!user || !user.id) {
                        return;
                    }

                    var language = LanguageService.getLanguageCodeSaveOnStorage();
                    UserService.limits()
                        .then(function (res) {
                            if (!res || !res.data || !res.data.isActive) {
                                console.log('usetiful: no data res');
                                return null;
                            }

                            var account = res.data.account || 'offline';
                            var canDoTrial = res.data.canDoTrial || true;
                            var isTrial = res.data.isTrial || false;

                            UsetifulService.start(user.id, language, account, canDoTrial, isTrial);
                        }).catch(function (err) {
                        console.log(err);
                        return null;
                    });
                }

                /**
                 * SIGN IN WITH STO
                 * @param form
                 */
                function signin(form) {
                    if (form.$valid && !$scope.loading) {
                        $scope.loading = true;
                        $timeout.cancel(thisTimeout);
                        Auth.signin($scope.data.signin)
                            .then(function (res) {
                                if (res.verified == 0) {
                                    if (ctrl.dialogMode) {
                                        $scope.$parent.cancel();
                                    }
                                    $state.go('access.email-verification', {
                                        token: btoa($scope.data.signin.email)
                                    });
                                    return;
                                }
                                if (!res.businessType || !res.teamMembers || !res.jobTitle) {
                                    if (ctrl.dialogMode) {
                                        $scope.$parent.cancel();
                                    }
                                    $state.go('access.tailor-made');
                                    return;
                                }

                                $scope.$onAuth();
                            })
                            .catch(function (err) {
                                console.log(err);
                                $scope.loading = false;
                                //TODO: verificare questa gestione degli errori
                                if (err && err.message && err.message == "incorrect password") {
                                    form.pass.$setValidity('wrongPassword', false);
                                }

                                form.pass.$setValidity('unregisteredAccount', false);

                                thisTimeout = $timeout(function () {
                                    form.$setUntouched();
                                    form.$setPristine();
                                    form.pass.$setValidity('wrongPassword', true);
                                    form.pass.$setValidity('unregisteredAccount', true);
                                }, 6000);

                            });
                    }
                }

                /**
                 * SIGN IN WITH FB
                 */
                function loginFacebook() {
                    if (!Facebook.isReady()) {
                        return;
                    }
                    Facebook.login(function (response) {
                        if (response.status !== 'connected') {
                            return;
                        }
                        Auth.facebookSignin({
                            lang: currentLang,
                            access_token: response.authResponse.accessToken
                        })
                            .then(function (res) {
                                if (res.data.info.firstAccess) {
                                    if ($scope.service) {
                                        TrackEventService.event('Funnel Events', 'Signup Completed', $scope.service);
                                        TrackEventService.gaV2Event('sign_up', {
                                            'sign_up_method': 'Facebook',
                                            'method': 'Facebook',
                                            'service': $scope.service
                                        });
                                    } else {
                                        TrackEventService.event('Funnel Events', 'Signup Completed');
                                        TrackEventService.gaV2Event('sign_up', {
                                            'sign_up_method': 'Facebook',
                                            'method': 'Facebook',
                                        });
                                    }
                                    if (res && res.data && res.data.user && (!res.data.user.businessType || !res.data.user.teamMembers || !res.data.user.jobTitle)) {
                                        if (ctrl.dialogMode) {
                                            $scope.$parent.cancel();
                                        }
                                        $state.go('access.tailor-made');
                                        return;
                                    }
                                    $scope.$onAuth();
                                } else {
                                    if (res && res.data && res.data.user && (!res.data.user.businessType || !res.data.user.teamMembers || !res.data.user.jobTitle)) {
                                        if (ctrl.dialogMode) {
                                            $scope.$parent.cancel();
                                        }
                                        $state.go('access.tailor-made');
                                        return;
                                    }
                                    $scope.$onAuth();
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }, {
                        scope: 'email,public_profile'
                    });
                }

                /**
                 *
                 * @param evt
                 * @param token
                 */
                function loginGoogleEvent(evt, token) {
                    loginGoogle(token);
                }

                /**
                 * SIGN IN WITH GOOGLE
                 * @param token
                 */
                function loginGoogle(token) {
                    Auth.googleSignin({
                        lang: currentLang,
                        access_token: token
                    })
                        .then(function (res) {
                            if (res.data.info.firstAccess) {
                                if ($scope.service) {
                                    TrackEventService.event('Funnel Events', 'Signup Completed', $scope.service);
                                    TrackEventService.gaV2Event('sign_up', {
                                        'sign_up_method': 'Google',
                                        'method': 'Google',
                                        'service': $scope.service
                                    });
                                } else {
                                    TrackEventService.event('Funnel Events', 'Signup Completed');
                                    TrackEventService.gaV2Event('sign_up', {
                                        'sign_up_method': 'Google',
                                        'method': 'Google',
                                    });
                                }

                                if (res && res.data && res.data.user && (!res.data.user.businessType || !res.data.user.teamMembers || !res.data.user.jobTitle)) {
                                    if (ctrl.dialogMode) {
                                        $scope.$parent.cancel();
                                    }
                                    $state.go('access.tailor-made');
                                    return;
                                }

                                $timeout(function () {
                                    $scope.$onAuth();
                                }, 2400);
                            } else {
                                if (res && res.data && res.data.user && (!res.data.user.businessType || !res.data.user.teamMembers || !res.data.user.jobTitle)) {
                                    if (ctrl.dialogMode) {
                                        $scope.$parent.cancel();
                                    }
                                    $state.go('access.tailor-made');
                                    return;
                                }
                                $scope.$onAuth();
                            }

                        })
                        .catch(function (err) {
                            console.log(err);
                        })
                }

                function setService(value) {
                    $scope.service = value;
                }

                function setOnAuth(value) {
                    $scope.onAuth = value;
                }

                function goSignup() {
                    if (ctrl.dialogMode) {
                        $scope.$parent.setFragment('alert-signup');
                    } else {
                        $state.go('access.signup');
                    }
                }

                function goToForgotPassword() {
                    if (ctrl.dialogMode) {
                        $scope.$parent.cancel();
                    }
                    $state.go('access.forgot');
                }
            }
        ]
    };
});

'use strict';
define(['app'], function (app) {
    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(['$injector', '$q', '$window', '$location', '$timeout', function ($injector, $q, $window, $location, $timeout) {
            return {
                request: function (config) {
                    config.headers = config.headers || {};
                    if (localStorage.getItem('accessToken')) {
                        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
                    }
                    return config;
                },
                responseError: function (response) {
                    var unauthorizedLocation = function (location) {
                        return location !== '/seo-spider/crawling' &&
                            location !== '/seo-spider/completed' &&
                            location !== '/seo-spider' &&
                            location !== '/signin' &&
                            location !== '/signup' &&
                            location !== '/lead-generation'
                    }

                    var isAuthenticationProcess = function (response) {
                        return response && response.data && response.data.message
                            && (response.data.message === 'incorrect password' || response.data.message === 'incorrect email')
                    }

                    if (response.status === 401 || response.status === 403) {
                        $injector.get('AuthService').logout();
                        var pathname = $window.location.pathname;

                        if (unauthorizedLocation(pathname) && !isAuthenticationProcess(response)) {
                            return $timeout(function () {
                                $location.url('/signin');
                            }, 20);
                        }
                    }

                    return $q.reject(response);
                }
            };
        }]);
    }]);
});

/**
 * @ngdoc overview
 * @name keywordexplorer.service:KeywordExplorerService
 */
'use strict';
define(['app'], function (app) {
    app.service('KeywordExplorerService', KeywordExplorerService);
    KeywordExplorerService.$inject = ['API', '__env', 'sessionHistoryCache', '$q'];

    function KeywordExplorerService(API, __env, sessionHistoryCache, $q) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.keywordExplorerAPI;

        this.searchKeywords = function (query) {
            var dfd = $q.defer();
            try {
                var apiRequestUrl = apiPath + '/request-by-search';
                var cacheId = setJSONParamsInURL('request-by-search', query);
                var existCached = sessionHistoryCache.get(cacheId);

                /* if cache exist, use the cached results in browser history */
                if (existCached) {
                    existCached.isCached = true;
                    dfd.resolve(existCached);
                } else {
                    API.post(apiRequestUrl, query, true, true)
                        .then(function (res) {
                            sessionHistoryCache.put(cacheId, res);
                            dfd.resolve(res);
                        })
                        .catch(function (err) {
                            dfd.reject(err);
                        })
                }
            } catch (err) {
                console.log(err);
                dfd.reject(err);
            }

            return dfd.promise;
        };


        this.searchKeywordsByDomain = function (query) {
            var dfd = $q.defer();
            var apiRequestUrl = apiPath + '/keywords-domain';

            try {
                API.post(apiRequestUrl, query, true, true)
                    .then(function (res) {
                        dfd.resolve(res);
                        })
                    .catch(function (err) {
                        dfd.reject(err);
                    });

            } catch (err) {
                console.log(err);
                dfd.reject(err);
            }

            return dfd.promise;
        };

        this.searchByKeywords = function (keywords, filters) {
            return API.post(apiPath + '/request-by-keywords', {
                keywords: keywords,
                filters: filters,
            }, true, true);
        };

        function setJSONParamsInURL(apiRequestUrl, apiParams) {
            return apiRequestUrl + '/' + JSON.stringify(apiParams);
        }
    }
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/serpchecker/components/sc-link-profile/sc-link-profile.html',
        bindings: {
            loading: '<',
            avgRefDomains: '<',
            backlinksNoFollow:'=',
            backlinksDoFollow:'=',
            backlinksTotal:'='
        },
        controller: ['$scope', function ($scope) {
            var vm=$scope;
            var $this=this;

            vm.relDistribution=[100,0];

            vm.graphRelLabel=['dofollow','nofollow'];

            vm.chartOptions={
                responsive: false,
                aspectRatio:1,
                maintainAspectRatio: true,
                cutoutPercentage:75,
                legend: {
                    display: false,
                },
                tooltips:{
                    enabled:false
                },
                elements: {
                    arc: {
                        width:10,
                        borderWidth: 0
                    }
                }
            };

            $scope.$watch('$ctrl.backlinksDoFollow', function(backlinksDoFollow){
                if(backlinksDoFollow==null){
                    vm.backlinksDoFollow=null;
                    vm.backlinksNoFollow=null;
                    vm.relDistribution=[100,0];
                    return;
                }
                vm.backlinksDoFollow=backlinksDoFollow;
                var total=backlinksDoFollow+vm.backlinksNoFollow;
                vm.doFollowRate=getWholePercent($this.backlinksDoFollow,total);
                vm.doFollowRate=100-vm.doFollowRate;
                vm.relDistribution=[vm.noFollowRate,vm.doFollowRate];

            });
            $scope.$watch('$ctrl.backlinksNoFollow', function(backlinksNoFollow){
                if(backlinksNoFollow==null){
                    vm.backlinksDoFollow=null;
                    vm.backlinksNoFollow=null;
                    vm.relDistribution=[100,0];
                    return;
                }
                vm.backlinksNoFollow=backlinksNoFollow;
                var total=vm.backlinksDoFollow+backlinksNoFollow;
                vm.noFollowRate=getWholePercent($this.backlinksNoFollow,total);
                vm.doFollowRate=100-vm.noFollowRate;
                vm.relDistribution=[vm.noFollowRate,vm.doFollowRate];
            });
            $scope.$watch('$ctrl.backlinksTotal', function(backlinksTotal){
                vm.backlinksTotal=backlinksTotal;
            });


            function getWholePercent(percentFor,percentOf) {
                return Math.floor(percentFor/percentOf*100);
            }
        }]
    };
});

import globalLanguages from "../../../../../locales/global-languages.json";

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/brand-guidelines/brand-guidelines.html',
    bindings: {
        language: "=",//secondPerson
        toneOfVoice: "=",//professional
        pointOfView: "=",//secondPerson
        contentType: "=",//blogPost
        disabled: "<",//blogPost
    },
    controller: ['$scope', function ($scope) {
        $scope.langs = globalLanguages;
        $scope.getChosenValue = function (list, valueToFind) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].value === valueToFind) {
                    return list[i];
                }
            }
            return null;
        };
        $scope.pointOfViewOptions = [
            {
                value: "firstPerson",
                label: "First Person",
                description: "Uses 'I' or 'we' to provide a personal perspective. Suitable for making the content feel direct and personal."
            },
            {
                value: "secondPerson",
                label: "Second Person",
                description: "Uses 'you' to directly address the reader or user. This point of view is engaging and makes the interaction feel more conversational."
            },
            {
                value: "thirdPersonLimited",
                label: "Third Person Limited",
                description: "Uses 'he', 'she', or 'they' with a focus on one character's thoughts and experiences. Provides a more focused narrative while maintaining some distance."
            },
            {
                value: "thirdPersonOmniscient",
                label: "Third Person Omniscient",
                description: "Uses 'he', 'she', or 'they' with an all-knowing perspective, providing insight into the thoughts and actions of multiple characters or perspectives."
            },
            {
                value: "objective",
                label: "Objective",
                description: "A neutral point of view that presents facts and observable details without personal bias or internal thoughts. Suitable for reporting or informative content."
            },
            {
                value: "subjective",
                label: "Subjective",
                description: "Provides a personal or biased perspective, often including opinions and personal feelings. Suitable for opinion pieces or personal blogs."
            }
        ];
        $scope.toneOfVoiceOptions = [
            {
                value: "friendly",
                label: "Friendly",
                description: "Warm, conversational, and casual. Makes users feel comfortable and welcome."
            },
            {
                value: "professional",
                label: "Professional",
                description: "Polite, respectful, and serious. Suitable for business or formal contexts."
            },
            {
                value: "playful",
                label: "Playful",
                description: "Light-hearted, witty, and fun. Engages users with humor and a casual tone."
            },
            {
                value: "empathetic",
                label: "Empathetic",
                description: "Understanding, compassionate, and caring. Ideal for providing emotional support or sensitive content."
            },
            {
                value: "instructive",
                label: "Instructive",
                description: "Clear, informative, and direct. Focuses on teaching or guiding users with precise instructions."
            },
            {
                value: "authoritative",
                label: "Authoritative",
                description: "Strong, assertive, and knowledgeable. Great for giving advice or expert opinions."
            },
            {
                value: "inspirational",
                label: "Inspirational",
                description: "Encouraging, uplifting, and positive. Aims to inspire and motivate users toward goals."
            },
            {
                value: "casual",
                label: "Casual",
                description: "Easygoing, laid-back, and informal. Suitable for casual interactions and everyday conversations."
            },
            {
                value: "optimistic",
                label: "Optimistic",
                description: "Positive, upbeat, and enthusiastic. Promotes a happy and positive atmosphere."
            },
            {
                value: "concise",
                label: "Concise",
                description: "Brief, to the point, and no-nonsense. Suitable for quick and efficient communication."
            }
        ];
        $scope.contentTypeOptions = [
            {
                value: "blogPost",
                label: "Blog Post",
                description: "Informative or opinion-based content intended for blogs."
            },
            {
                value: "productDescription",
                label: "Product Description",
                description: "Concise descriptions of products for e-commerce sites."
            },/*
            {
                value: "socialMediaPost",
                label: "Social Media Post",
                description: "Short and engaging content for platforms like Twitter, Facebook, etc."
            },*/
            {
                value: "newsletter",
                label: "Newsletter",
                description: "Content designed for email distribution, focusing on updates, promotions, or news."
            }
        ];

    }]
};

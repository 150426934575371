'use strict';
define([], function() {
    function ChangePasswordController($scope, $timeout, $mdToast, UserService) {
        var vm = $scope;
        var thisTimeout;
       
        vm.data = {
            oldPass: null, 
            newPass: null, 
            rePass: null
        };

        vm.loading = false;
        vm.passwordWasChanged = false;

        vm.changePassword = changePassword;
        
        function changePassword(form) {
            if (vm.loading) {
                return;
            }

            vm.loading = true;
            $timeout.cancel(thisTimeout);

            if (
                !vm.data
                || !vm.data.oldPass
                || !vm.data.oldPass.trim()
            ) {
                vm.loading = false;
                return;
            }

            if (vm.data.newPass !== vm.data.rePass) {
                vm.loading = false;
                form.newPass.$setValidity('unmatchedPasswords', false);
                form.rePass.$setValidity('unmatchedPasswords', false);
                
    
                $timeout(function () {
                    form.$setUntouched();
                    form.$setPristine();
                    form.newPass.$setValidity('unmatchedPasswords', true);
                    form.rePass.$setValidity('unmatchedPasswords', true);
                }, 6000);
                return;
            }

            UserService.changePassword({oldPass: vm.data.oldPass, newPass: vm.data.newPass})
                    .then(function() {
                        $scope.loading = false;
                        $mdToast.show({
                            template:
                            '<md-toast class="md-toast" >' +
                            '<span layout="row" layout-align="start center"><i class="material-icons st-green m-r">done</i><span translate>{{"settings.pass.change.success"|translate}}</span></span>' +
                            '</md-toast>',
                            hideDelay: 5000,
                            position: 'top right'
                        });
                    })
                    .catch(function(err){
                        $scope.loading = false;
                        console.log(err)
                        if (err && err.data && err.data.error && err.data.error.code && err.data.error.code == 'INVALID_OLD_PASSWORD') {
                         
                            $mdToast.show({
                                template:
                                '<md-toast class="md-toast" >' +
                                '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i><span translate>{{ "The old password is incorrect." | translate}}</span></span>' +
                                '</md-toast>',
                                hideDelay: 5000,
                                position: 'top right'
                            });

                            return;
                        }

                        $mdToast.show({
                            template:
                            '<md-toast class="md-toast" >' +
                            '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i><span translate>{{"settings.problem.verified.retry"|translate}}</span></span>' +
                            '</md-toast>',
                            hideDelay: 5000,
                            position: 'top right'
                        });
                    });
        }
        
    }
    ChangePasswordController.$inject=['$scope', '$timeout', '$mdToast', 'UserService'];
    return ChangePasswordController;
});
'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$mdDialog,$state,$stateParams,LeadGenerationService,TrackEventService) {
        var vm = $scope;
        vm.loading={
            post: false
        };

        vm.duplicatedNameFormError = false;
        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;
        vm.isActive = isActive;
        vm.setFormTypeId = setFormTypeId;

        vm.changeNameForm=function () {
            vm.duplicatedNameFormError = false;
        };

        vm.form = {
            formTypeId: 1,
            name: null
        };

        function isActive(id) {
            return vm.form.formTypeId == id;
        }

        function setFormTypeId(id) {
            vm.form.formTypeId = id;
        }

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            $mdDialog.cancel();
        }
        function answer() {
            vm.loading.post=true;
            if (!vm.form.name||vm.form.name.length<1) {
                vm.loading.post=false;
                return;
            }

            var formData = {
                name:vm.form.name.trim(),
                formTypeId: vm.form.formTypeId
            }

            LeadGenerationService.createForm(formData)
                .then(function(res){
                    TrackEventService.event(
                        'Suite Usages',
                        'Lead Gen - Campaign Created');
                    TrackEventService.gaV2Event(
                        'campaign_create',{
                            tool: "Lead Generation Tool"
                        });

                    if (res.id) {
                        switch(vm.form.formTypeId) {
                            case 1:
                                $state.go('app.leadgeneration.form.form-generator.free-checker.form', {"formId": res.id});
                                break;
                            case 2:
                                $state.go('app.leadgeneration.form.form-generator.whitelabel-report.form', {"formId": res.id});
                                break;
                            case 3:
                                $state.go('app.leadgeneration.form.form-generator.custom-file.form', {"formId": res.id});
                                break
                            default:
                                $state.go('app.leadgeneration.landing');
                        }
                    }


                    //$mdDialog.hide();
                    //vm.loading.post = false;
                })
                .catch(function(err){
                    vm.loading.post=false;
                    if (err && err.errors && err.errors.length>0 && err.errors[0].code=='ER_DUP_ENTRY') {
                        vm.duplicatedNameError = true;
                    }
                });
        }
    }
    Controller.$inject=['$scope','$mdDialog','$state','$stateParams','LeadGenerationService','TrackEventService'];
    return Controller;
});

'use strict';
define(['angular'], function (angular) {
    function Controller($scope, $stateParams, $state, $mdDialog, $timeout, SERPChecker, LanguageService, $window, UserService) {
        var vm = $scope;
        vm.searchEngineAvailable = SERPChecker.config.searchEnginesAvailable;
        vm.devicesAvailable = SERPChecker.config.devicesAvailable;
        vm.queryKeyword = $stateParams.keyword || null;
        vm.expandSection = false;
        vm.query = {
            mode: getCurrentMode() || 'backlink',
            keyword: $stateParams.keyword || null,
            deviceId: $stateParams.deviceId || SERPChecker.config.defaultDeviceId,
            searchEngineId: $stateParams.searchEngineId || SERPChecker.config.defaultSearchEngineId,
            countryCode: $stateParams.countryCode || LanguageService.getCountryCodeSaveOnStorage() || 'US',
            benchmark: getBenchmarkFromStateParams() || []
        };

        vm.newAnalysis = newAnalysis;
        vm.editCompetitors = editCompetitors;
        vm.cleanUrl = cleanUrl;
        updateLimit();

        vm.currentActiveTab = 0;
        switch (getCurrentMode()) {
            default:
            case 'backlink':
                vm.currentActiveTab = 0;
                break;
            case 'social':
                vm.currentActiveTab = 1;
                break;
            case 'copywriting':
                vm.currentActiveTab = 2;
                break;
        }

        vm.linkTabs = [{
            label: 'serpchecker.backlink.data',
            labelXs: 'Backlink',
            isActive: getCurrentMode() === 'backlink',
        }, {
            label: 'serpchecker.social.engagement',
            labelXs: 'Social',
            isActive: getCurrentMode() === 'social',
        }, {
            label: 'serpchecker.copywriting.strategies',
            labelXs: 'Copywriting',
            isActive: getCurrentMode() === 'copywriting',
        }];

        vm.setActiveTab = function (tabIndex) {
            vm.currentActiveTab = tabIndex;
            switch (tabIndex) {
                default:
                case 0:
                    vm.query.mode = 'backlink';
                    break;
                case 1:
                    vm.query.mode = 'social';
                    break;
                case 2:
                    vm.query.mode = 'copywriting';
                    break;
            }
            //newAnalysis();
        };

        function updateLimit() {
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits) {
                        vm.analysisLimit = res.data.limits.find(function (service) {
                            return service.alias === 'analysis';
                        });
                    }

                })
                .catch(function (err) {
                    console.error(err);
                    return null;
                });
        }


        function getBenchmarkFromStateParams() {
            var stateParamsBenchmark = [];
            if ($stateParams.benchmark && isString($stateParams.benchmark))
                stateParamsBenchmark = [$stateParams.benchmark];
            if ($stateParams.benchmark && !isString($stateParams.benchmark) && $stateParams.benchmark.length)
                stateParamsBenchmark = $stateParams.benchmark;
            return stateParamsBenchmark;
        }

        function editCompetitors(ev) {
            $mdDialog.show({
                templateUrl: "app/modules/serpchecker/dialogs/edit-competitors/edit-competitors.html",
                controller: "SerpCheckerEditCompetitorsCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                preserveScope: true,  // do not forget this if use parent scope
                locals: {benchmark: vm.query.benchmark}
            }).then(function (benchmark) {
                vm.query.benchmark = benchmark;
                vm.$broadcast("SERPCheckerAuditCompetitors", vm.query);
                updateStateUsingParams(false);
            });
        }

        function removeCompetitor(evt, index) {
            vm.query.benchmark.splice(index, 1);
            updateStateUsingParams(false);
        }

        function newAnalysis() {
            if (vm.queryKeyword && !!vm.queryKeyword.length) {
                vm.query.keyword = vm.queryKeyword;
            }
            vm.$broadcast("SERPCheckerAudit", vm.query);
            updateStateUsingParams();
            updateLimit();
        }

        function updateStateUsingParams(notify = true) {
            switch (vm.query.mode) {
                case 'social':
                    $state.go('app.serpchecker.tool.benchmark.social', vm.query, {
                        notify: notify,
                        reload: 'app.serpchecker.tool.benchmark'
                    });
                    break;
                case 'seo':
                    $state.go('app.serpchecker.tool.benchmark.seo', vm.query, {
                        notify: notify,
                        reload: 'app.serpchecker.tool.benchmark'
                    });
                    break;
                case 'copywriting':
                    $state.go('app.serpchecker.tool.benchmark.copywriting', vm.query, {
                        notify: notify,
                        reload: 'app.serpchecker.tool.benchmark'
                    });
                    break;
                default:
                    $state.go('app.serpchecker.tool.benchmark.backlink', vm.query, {
                        notify: notify,
                        reload: 'app.serpchecker.tool.benchmark'
                    });
                    break;
            }
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function getCurrentMode() {
            try {
                return $state.current.name.replace(/.*\./, '');
            } catch (e) {
                return 'backlink';
            }
        }

        /* utilities */
        function isString(value) {
            return typeof value === 'string';
        }

        vm.$watch('query.mode', function (newMode, oldMode) {
            var isModeChanged = newMode !== oldMode;
            if (isModeChanged) {
                newAnalysis();
            }
        });
        vm.$watch('query.searchEngineId', function (searchEngineId) {
            if (searchEngineId === 2 || searchEngineId === 3) {
                vm.query.deviceId = 1;
            }
        });
        vm.$on('SERPCheckerOnCompetitorsChanged', removeCompetitor);
    }

    Controller.$inject = ['$scope', '$stateParams', '$state', '$mdDialog', '$timeout', 'SerpCheckerService', 'LanguageService', '$window', 'UserService'];
    return Controller;
});

'use strict';
define(['app'], function (app) {
    app.factory('sessionHistoryCache', ['$cacheFactory', '__env',
        function ($cacheFactory, __env) {
            /* in production use a real session cache only ,
            all request done will expired at the end of session
            or during a browser refresh */
            var useTemporarySessionCache = (__env.stage !== 'development-cached') && $cacheFactory;
            var sessionHistoryCache = $cacheFactory('sessionHistoryCache');
            if (useTemporarySessionCache) {
                return sessionHistoryCache;
            }
            return {
                get: get,
                put: put,
                removeAll: resetLocalStorage
            };

            function get(key) {
                var persistentHistoryCache = getSessionCache();
                var cachedItem = persistentHistoryCache[key] || sessionHistoryCache.get(key);
                if (!cachedItem) {
                    return false;
                }
                return cachedItem;
            }

            function getSessionCache() {
                var persistentHistoryCache = localStorage.getItem('persistentHistoryCache');
                if (!persistentHistoryCache) {
                    resetLocalStorage();
                    return {};
                }
                return JSON.parse(persistentHistoryCache);
            }

            function put(key, value) {
                var persistentHistoryCache = getSessionCache();
                persistentHistoryCache[key] = value;
                try {
                    localStorage.setItem('persistentHistoryCache', JSON.stringify(persistentHistoryCache));
                } catch (err) {
                    sessionHistoryCache.put(key, value);
                    console.log(err);
                }
            }

            function resetLocalStorage() {
                localStorage.setItem('persistentHistoryCache', JSON.stringify({}));
            }
        }
    ]);
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog, user, WorkspaceService, $localStorage, $translate) {
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;
        $scope.loading = {
            post: false
        };

        $scope.roles = [{
                id: 2,
                label: 'Admin',
                permissionAvailable: 'workspace_user_admin',
                permission: $translate.instant('settings.dialogs.role.admin.subtitle'),
                title: $translate.instant('settings.dialogs.role.admin.title'),
                listTitle: $translate.instant('settings.dialogs.role.admin.list.title'),
                description: $translate.instant('settings.dialogs.role.admin.description')
            },
            {
                id: 3,
                label: 'Manager',
                permissionAvailable: 'workspace_user_manager',
                permission: $translate.instant('settings.dialogs.role.manager.subtitle'),
                title: $translate.instant('settings.dialogs.role.manager.title'),
                listTitle: $translate.instant('settings.dialogs.role.manager.list.title'),
                description: $translate.instant('settings.dialogs.role.manager.description')
            },
            {
                id: 4,
                label: 'Bookkeeper',
                permissionAvailable: 'workspace_user_bookkeeper',
                permission: $translate.instant('settings.dialogs.role.bookkeeper.subtitle'),
                title: $translate.instant('settings.dialogs.role.bookkeeper.title'),
                listTitle: $translate.instant('settings.dialogs.role.bookkeeper.list.title'),
                description: $translate.instant('settings.dialogs.role.bookkeeper.description')
            },
            {
                id: 5,
                label: 'Marketer',
                permissionAvailable: 'workspace_user_marketer',
                permission: $translate.instant('settings.dialogs.role.marketer.subtitle'),
                title: $translate.instant('settings.dialogs.role.marketer.title'),
                listTitle: $translate.instant('settings.dialogs.role.marketer.list.title'),
                description: $translate.instant('settings.dialogs.role.marketer.description')
            },
        ]
        user.role = getRole(user.roleName);
        $scope.user = user;

        function answerDialog() {
            $scope.loading.post = true;
            if (user.confirmedAt) {
                changeRoleForUser();
            } else {
                updateRoleInvitation();
            }
        }

        function cancelDialog() {
            $mdDialog.hide();
        }

        function changeRoleForUser() {
            WorkspaceService.changeRoleForUser({
                userId: $scope.user.userId,
                roleId: $scope.user.role.id,
            }, $localStorage.currentUser.workspace.id).then(function (res) {
                $scope.loading.post = false;
                if (res && res.message === 'success') {
                    cancelDialog()
                }
            }).catch(function (err) {
                $scope.loading.post = false;
            })
        }

        function updateRoleInvitation() {
            WorkspaceService.updateRoleInvitation({
                roleId: $scope.user.role.id,
                email: $scope.user.email,
            }).then(function (res) {
                $scope.loading.post = false;
                if (res && res.message === 'success') {
                    cancelDialog()
                }
            }).catch(function (err) {
                $scope.loading.post = false;
            })
        }

        function getRole(roleName) {
            if (!roleName) {
                return 0;
            }
            var roleId = '';
            angular.forEach($scope.roles, function (role) {
                if (role.label.toLowerCase() == roleName) {
                    roleId = role;
                }
            });
            return roleId;
        }

        $scope.setRole = function (role) {
            user.role = role;
        }

    }
    Controller.$inject = ['$scope', '$mdDialog', 'user', 'WorkspaceService', '$localStorage', '$translate'];
    return Controller;
});

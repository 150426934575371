'use strict';
define([], function () {
    function controller(
        $scope,
        $filter,
        $mdMedia,
        $translate,
        $mdToast,
        $stateParams,
        TrackEventService,
        $rootScope,
        UserService,
        $q,
        TimezoneService,
        CRMService,
    ) {
        var ctrl = this;
        var resources = [10, 25, 50, 100, 150, 200, 250, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 50000, 100000];

        $scope.$mdMedia=$mdMedia;
        $scope.resources = [];
        $scope.showCustomToast = showCustomToast;

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast max-w">' +
                    '   <div class="p-t p-b"><i class="' + classIcon + ' m-r-sm"></i>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: angular.element('.toast-show')
            });
        }

        function getUserMaxResources() {
            var dfd = $q.defer();
            UserService.limits()
                .then(function (res) {
                    if (!res || !res.data || !res.data.limits || !res.data.limits.length) {
                        dfd.resolve(15);
                        return;
                    }

                    var limit = 15
                    angular.forEach(res.data.limits, function (service) {
                        if (service.alias === 'seo-spider-limit' && service.qtyAllowed) {
                            limit = service.qtyAllowed;
                        }
                    });

                    dfd.resolve(limit);
                })
                .catch(function (err) {
                    dfd.resolve(15);
                    return null;
                });

            return dfd.promise;
        }

        this.$onInit = function () {
            $scope.schedule = this.schedule;
            $scope.times = TimezoneService.getAllTimeOptions();
            $scope.timezones = TimezoneService.getAllTimezoneOptions();
            $scope.wizardMode = this.wizardMode;
            $scope.stepSchedule = 'scheduleExam';

            if ($scope.schedule && $scope.schedule.runScheduled) {
                $scope.schedule.runScheduled = true;
            }

            $scope.maxResources = 15;
            getUserMaxResources()
                .then(function (maxResources) {
                    $scope.maxResources = maxResources || 15;
                    angular.forEach(resources, function (resource) {
                        if (resource <= $scope.maxResources) {
                            $scope.resources.push(resource)
                        }
                    });

                    if (!$scope.schedule) {
                        return;
                    }

                    if (!$scope.schedule.resourcesLimit) {
                        $scope.schedule.resourcesLimit = 100;
                        return;
                    }

                    if ($scope.schedule.resourcesLimit > maxResources) {
                        $scope.schedule.resourcesLimit = maxResources;
                    }
                });

            // TRACKING
            var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';
            var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
            if ($scope.wizardMode) {
                CRMService.addEvents(['ProjectToConfigEvent'], [
                    'ProjectToCreateEvent',
                ]);

                TrackEventService.event(
                    'Suite Usages',
                    'SEO Project - Step - Schedule Audit', eventLabel);
                TrackEventService.gaV2Event(
                    'project_wizard', {
                        step: 'Schedule Audit',
                        from: $rootScope.stoFrom,
                        type: stoTool
                    });
            }
            //
        };

    }

    controller.$inject = [
        '$scope',
        '$filter',
        '$mdMedia',
        '$translate',
        '$mdToast',
        '$stateParams',
        'TrackEventService',
        '$rootScope',
        'UserService',
        '$q',
        'TimezoneService',
        'CRMService'
    ];

    return {
        templateUrl: 'app/modules/project/wizard/steps/schedule-audits/schedule-audits.html',
        bindings: {
            schedule: '<',
            wizardMode: '<',
        },
        controller: controller
    };
});

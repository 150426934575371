'use strict';
define(['app'], function (app) {
    app.component('stCarousel', {
        templateUrl: 'app/components/st-carousel/st-carousel.html',
        controller: controller,
        transclude: true,
        bindings: {
            testimonials: '<'
        }
    });
    controller.$inject = ['__env', '$scope', '$timeout'];

    function controller(__env, $scope, $timeout) {
        var slideIndex = 1;
        var ctrl = this;
        ctrl.testimonials = [];
        $scope.plusSlides = plusSlides;
        $scope.currentSlide = currentSlide;

        $scope.$watch('$ctrl.testimonials', function (testimonials) {
            if (testimonials && testimonials.length) {
                ctrl.testimonials = testimonials;
                $timeout(function () {
                    showSlides(slideIndex);
                }, 1300);
            }
        });

        function plusSlides(n) {
            showSlides(slideIndex += n);
        }

        function currentSlide(n) {
            showSlides(slideIndex = n);
        }

        function showSlides(n) {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            var dots = document.getElementsByClassName("dot");
            if (!slides.length || !dots.length) {
                return;
            }
            if (n > slides.length) {
                slideIndex = 1;
            }
            if (n < 1) {
                slideIndex = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" dot-active", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " dot-active";
        }
    }
});

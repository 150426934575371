/**
 * @ngdoc overview
 * @name copymetrix.service:CopymetrixService
 */
'use strict';
define(['app'], function (app) {
    app.service('CopyMetricsService', Service);
    Service.$inject = ['API', '__env', '$timeout', 'sessionHistoryCache', '$q', '$http', 'GoogleSuggestionsService'];

    /**
     * @return {boolean}
     */
    function Service(API, __env, $timeout, sessionHistoryCache, $q, $http, GoogleSuggestions) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.copyMetrixAPI;

        return {
            get: get,
            post: post,
            getKeywordsByUrl: getKeywordsByUrl,
            urls: analyzeCopywritingFromURLs
        };

        function get(params) {
            return analyzeCopywritingFromURLs({urls: [params.url]})
                .then(function (res) {
                    var resultItem = res.data.rows[0];
                    resultItem.headings = unifyHeadings(resultItem);
                    //checkRelevance(resultItem);
                    return resultItem;
                });
        }

        /** BETA **/

        function getKeywordsByUrl(url) {
            var i;
            var j;
            var z;
            var keywords = [];
            var relevantKeywords = [];
            return get({url: url})
                .then(function (copymetricsData) {
                    relevantKeywords = copymetricsData.extraParams.relevantKeywords || [];
                    for (j = 0; j < relevantKeywords.length; j++) {
                        if (relevantKeywords[j].relevancy > 70) {
                            keywords.push(relevantKeywords[j].word);
                        }
                    }
                    return generateKeywordsFromCopymetricsData(copymetricsData);
                })
                .then(function (googleSuggestions) {
                    for (i = 0; i < googleSuggestions.length; i++) {
                        for (z = 0; z < googleSuggestions[i].length; z++) {
                            keywords.push(googleSuggestions[i][z]);
                        }
                    }
                    return getDistinctKeywords(keywords);
                });
        }

        function getDistinctKeywords(keywords) {
            var unique = [];
            var distinct = [];
            for (var i = 0; i < keywords.length; i++) {
                if (!unique[keywords[i]]) {
                    unique[keywords[i]] = 1;
                    distinct.push(keywords[i]);
                }
            }
            return distinct;
        }


        function generateKeywordsFromCopymetricsData(resultsFromCopyMetricsAPI) {
            try {
                var obj;
                var CopyMetricsData = resultsFromCopyMetricsAPI.extraParams || {};
                var relevantKeywords = CopyMetricsData.relevantKeywords || [];
                var promises = [];
                var i;
                var imInLoading = false;
                var oneWordAnalyzed = false;
                /** fetch suggestions from most relevant keywords */
                for (i = 0; i < relevantKeywords.length; i++) {
                    obj = relevantKeywords[i];
                    if (obj.relevancy > 85 && (!oneWordAnalyzed || obj.numWords > 1)) {
                        if (obj.numWords === 1)
                            oneWordAnalyzed = true;
                        imInLoading = true;
                        promises.push(fetchFromGoogleSuggestions(obj.word));
                    }
                }
                if (!imInLoading) {
                    var fallbackFounded = 0;
                    /** if not found nothing perform a fallback and fetch suggestions from first 3th secondary keywords */
                    for (i = 0; i < relevantKeywords.length && fallbackFounded < 3; i++) {
                        obj = relevantKeywords[i];
                        if (obj.relevancy >= 70 && (!oneWordAnalyzed || obj.numWords > 1)) {
                            if (obj.numWords === 1)
                                oneWordAnalyzed = true;
                            fallbackFounded++;
                            imInLoading = true;
                            promises.push(fetchFromGoogleSuggestions(obj.word));
                        }
                    }
                }
                if (!imInLoading && relevantKeywords[0]) {
                    /** if not found nothing perform a fallback and fetch suggestions from relevant keywords */
                    imInLoading = true;
                    promises.push(fetchFromGoogleSuggestions(relevantKeywords[0].word));

                }
                if (CopyMetricsData.h1 && CopyMetricsData.h1.length) {
                    /** Fetch suggestions from most important H1  */
                    imInLoading = true;
                    promises.push(fetchFromGoogleSuggestions(CopyMetricsData.h1[0]));
                }
                if (!imInLoading) {
                    if (CopyMetricsData.h2 && CopyMetricsData.h2.length) {
                        /** Fetch suggestions from all H2  */
                        for (var j = 0; j < CopyMetricsData.h2.length && j < 3; j++) {
                            imInLoading = true;
                            promises.push(fetchFromGoogleSuggestions(CopyMetricsData.h2[j]));
                        }
                    }
                }
                return $q.all(promises);
            } catch (err) {
                console.log(err);
                return $q.resolve([]);
            }
        }

        function fetchFromGoogleSuggestions(keyword) {
            return GoogleSuggestions.autocomplete(keyword + ' ');
        }

        function analyzeCopywritingFromURLs(params, apiRequestConfig = {}) {
            if (!params.urls || !params.urls.length) {
                return $q.resolve({
                    data: {rows: []},
                    message: 'success'
                });
            }
            var useCachedSERP = false;
            if (params.forceStatisticsUsingCachedSERP) {
                useCachedSERP = cloneObject(params.forceStatisticsUsingCachedSERP);
                delete params.forceStatisticsUsingCachedSERP;
            }
            var apiRequestUrl = apiPath + '/';
            var cacheId = setJSONParamsInURL('urls-copywriting-audit-with-text', params);
            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached && existCached.rows && existCached.rows.length) {
                return $timeout(function () {
                    if (useCachedSERP)
                        existCached = updateDataWithCachedSERP(existCached, useCachedSERP);
                    return $q.resolve(existCached);
                }, 1000);
            }
            return $http.post(apiRequestUrl, params)
                .then(function (res) {
                    var isDataNotVoid = res.data && res.data.rows && res.data.rows.length;
                    if (isDataNotVoid)
                        sessionHistoryCache.put(cacheId, res.data);
                    if (useCachedSERP)
                        return updateDataWithCachedSERP(res.data, useCachedSERP);
                    return res.data;
                });
        }

        function post(params) {
            return API.post(apiPath, params, true);
        }

        function setJSONParamsInURL(apiRequestUrl, apiParams) {
            return apiRequestUrl + '/' + JSON.stringify(apiParams);
        }

        function cloneObject(objToClone) {
            return JSON.parse(JSON.stringify(objToClone));
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function unifyHeadings(resultItem) {
            var headings = [];
            var i;
            var h1s = resultItem.extraParams.h1 || [];
            var h2s = resultItem.extraParams.h2 || [];
            for (i = 0; i < h1s.length; i++) {
                headings.push({value: h1s[i], type: 'h1'});
            }
            for (i = 0; i < h2s.length; i++) {
                headings.push({value: h2s[i], type: 'h2'});
            }
            return headings;
        }


        function updateDataWithCachedSERP(benchmark, cachedSerp) {
            var benchmarkResults = benchmark.data.rows;
            for (var i = 0; i < benchmarkResults.length; i++) {
                for (var j = 0; j < cachedSerp.length; j++) {
                    var isInSERP = cachedSerp[j].resultType === 'organic' &&
                        cleanUrl(cachedSerp[j].link.url) === cleanUrl(benchmarkResults[i].link.url);
                    if (isInSERP) {
                        benchmarkResults[i] = cloneObject(cachedSerp[j]);
                        break;
                    }
                }
            }
            return benchmark;
        }
    }
});

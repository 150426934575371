'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/images/images.routes',
    'modules/seospider/exam/analysis/images/images.controller',
    'modules/seospider/exam/analysis/images/all/all.controller',
    'modules/seospider/exam/analysis/images/missing/missing.controller',
    'modules/seospider/exam/analysis/images/missingalt/missingalt.controller',
    'modules/seospider/exam/analysis/images/altover/altover.controller',
    'modules/seospider/exam/analysis/images/table.component'
],function(
    angular,
    Routes,
    imagesCtrl,
    AllCtrl,
    ImagesMissingCtrl,
    ImagesMissingAltCtrl,
    ImagesAltOverCtrl,
    imagesTable
){
    var app=angular.module('app.seospider.exam.analysis.images',[]);
    app.config(Routes);
    app.controller("ImagesCtrl",imagesCtrl);
    app.controller("ImagesAllCtrl",AllCtrl);
    app.controller("ImagesMissingAltCtrl",ImagesMissingAltCtrl);
    app.controller("ImagesMissingCtrl",ImagesMissingCtrl);
    app.controller("ImagesAltOverCtrl",ImagesAltOverCtrl);
    app.component("imagesTable",imagesTable);
    return app;
});
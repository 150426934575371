'use strict';
define(['angular'], function(angular) {

    var PATTERNS={
        cf: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
        destinationCode: /[a-zA-Z0-9]{7}/,
        vat: /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i,
        email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    };
    function BillingInfoController($scope, $mdToast, $log, $rootScope, $state, UserService, CountriesService, AuthService, billingInfo, numberOfSubscriptions, ItalyService) {
        var vm=$scope;
        vm.patterns=PATTERNS;
        vm.loading = {update:false};
        vm.numberOfSubscriptions = numberOfSubscriptions;
        vm.countries = CountriesService;
        vm.requiredVat = false;
        vm.requiredBusinessName = false;
        vm.italy = ItalyService.all();
        vm.checkError = checkError;
        vm.checkForFE = checkForFE;
        vm.checkForVat = checkForVat;
        vm.user = {
            billingFirstname: billingInfo.billingFirstname,
            billingLastname: billingInfo.billingLastname,
            billingAddressLine1: billingInfo.billingAddressLine1,
            billingAddressZip: billingInfo.billingAddressZip,
            billingAddressCity: billingInfo.billingAddressCity,
            billingAddressCountry: billingInfo.billingAddressCountry,
            billingAddressState: billingInfo.billingAddressState,
            billingPhonePrefix: billingInfo.billingPhonePrefix,
            billingPhone: billingInfo.billingPhone,
            billingBusinessName: billingInfo.billingBusinessName,
            billingVat: billingInfo.billingVat,
            billingCf: billingInfo.billingCf,
            billingPec: billingInfo.billingPec,
            billingDestinationCode: billingInfo.billingDestinationCode
        };

        vm.$watchCollection('user', function (user) {
            if(!(vm.user.billingAddressCountry && vm.user.billingAddressCountry.toLowerCase()=='it')){
                vm.user.billingAddressState = null;
                vm.user.billingCf = null;
            }
            if(!(vm.user.billingBusinessName && vm.countries.isEU(vm.user.billingAddressCountry) && vm.user.billingBusinessName.length))
                vm.user.billingVat = null;
            if(!(vm.user.billingAddressCountry=='IT' && vm.user.billingBusinessName && vm.user.billingBusinessName.trim().length)){
                vm.user.billingPec = null;
            }
        });

        vm.$watch('user.billingAddressCountry', function (addressCountry) {
            vm.isItalian = addressCountry && addressCountry.toLowerCase() == 'it';
            vm.isEuropean = addressCountry && vm.countries.isEU(addressCountry);
        });

        vm.$watch('user.billingBusinessName', function (businessName) {
            vm.isCompany = businessName !== null && businessName !== '';
        });

        vm.update = function(){
            if (vm.user.billingCf) {
                vm.user.billingCf = vm.user.billingCf.toUpperCase();
            }

            vm.loading.update = true;
            vm.updated = vm.error = vm.errorBilling = false;
            if (numberOfSubscriptions>0 && angular.element('.ng-invalid').length) {
                $scope.errorBilling = true;
                return;
            }
            $scope.user.billingName = (vm.user.billingFirstname + ' ' + vm.user.billingLastname).trim();

            UserService.updateProfile(vm.user)
                .then(function(){
                    vm.loading.update=false;
                    $mdToast.show({
                        template:
                            '<md-toast class="md-toast" >' +
                            '<span layout="row" layout-align="start center"><i class="material-icons st-green m-r">done</i>{{"settings.updated.info"|translate}}</span>' +
                            '</md-toast>',
                        hideDelay: 5000,
                        position: 'top right'
                    });
                    vm.updated=true;
                })
                .catch(function (err) {
                    $mdToast.show({
                        template:
                            '<md-toast class="md-toast" >' +
                            '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i><span translate>{{"settings.problem.verified.retry"|tranlate}}</span></span>' +
                            '</md-toast>',
                        hideDelay: 5000,
                        position: 'top right'
                    });
                    vm.loading.update=false;
                    vm.error=true;
                });
        };

        function checkError(field) {
            if(numberOfSubscriptions==0)
                return false;
            else
                return vm.user[field]==undefined||!vm.user[field].length;
        }

        function checkForFE() {
            if(numberOfSubscriptions==0){
                return false;
            }else{
                try{
                    return (vm.user.billingPec == undefined || !vm.user.billingPec.trim().length) &&
                        (vm.user.billingDestinationCode ==undefined || !vm.user.billingDestinationCode.trim().length);
                }catch(e){
                    return false;
                }
            }
        }

        function checkForVat() {
            if (numberOfSubscriptions==0) {
                return false;
            }else{
                try{
                    return (vm.user.billingBusinessName || vm.user.billingBusinessName.trim().length) &&
                        (!vm.user.billingVat||!vm.user.billingVat.trim().length);
                }catch(e){
                    return false;
                }
            }
        }
    }
    BillingInfoController.$inject=['$scope', '$mdToast', '$log', '$rootScope', '$state', 'UserService', 'CountriesService', 'AuthService', 'billingInfo', 'numberOfSubscriptions', 'ItalyService'];
    return BillingInfoController;
});

'use strict';
define([], function() {
    function ForgotController($scope, Auth, $timeout, $translate, UtilitiesService) {
        var vm = $scope;
        var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
        vm.emailPattern = UtilitiesService.getEmailRegex();
        vm.forgot = forgot;
        vm.goForgot = goForgot;
        vm.domainSwitch = '';
        vm.resetLinkWasSent = false;
        vm.loading = false;
        vm.data = {
            forgot: {email: null, lang: currentLang},
        };

        function forgot(form) {
            if (!vm.loading) {
                vm.loading = true;
                vm.domainSwitch = getEmailDomain(vm.data.forgot.email);
                Auth.forgot({
                    email: vm.data.forgot.email,
                    lang: vm.data.forgot.lang
                }).then(function (res) {

                    $timeout(function () {
                        vm.loading = false;
                        vm.resetLinkWasSent = true;
                    }, 3500);
                }).catch(function (err) {
                    console.log(err);
                    if (err && err.status && err.status == 404) {
                        vm.loading = false;
                        form.email.$setValidity('emailNotFound', false);
                
                        $timeout(function () {
                            form.$setUntouched();
                            form.$setPristine();
                            form.email.$setValidity('emailNotFound', true);
                        }, 6000);
                        return;
                    }
                    
                    vm.loading = false;
                    $timeout(function () {
                        //
                    }, 6000);
                });
            }
        }

        function getEmailDomain(email) {
            var splitEmail = email.toLowerCase().split("@");
            var splitDomain = splitEmail[1].split(".");
            return splitDomain[0];
        }

        function goForgot() {
            vm.resetLinkWasSent = false;
        }
       
    }
    ForgotController.$inject=['$scope', 'AuthService', '$timeout', '$translate', 'UtilitiesService'];
    return ForgotController;
});

WriterAssistantAuditorService.$inject = ['GoogleSuggestionsService'];

function WriterAssistantAuditorService(GoogleSuggestionsService) {
    const MIN_NUM_OF_HEADINGS_IN_CONTENT = 6;
    const ALL_CONTENT_ANALYSIS_ISSUE = {
        "brief": {
            "category": "Brief",
            "tasks": {
                "checkWordCount": {
                    "task": "Ensure the article's word count is above the recommended range of the brief",
                    "args": {},
                    "done": false,
                    "collapsed": false,
                },
                "checkParagraphCount": {
                    "task": "Ensure the article's paragraph count is above the recommended range of the brief",
                    "args": {},
                    "done": false,
                    "collapsed": false,
                },
                "checkHeadingsCount": {
                    "task": "Ensure the article's headings count is above the recommended range of the brief",
                    "args": {},
                    "done": false,
                    "collapsed": false,
                },
                "checkImageCount": {
                    "task": "Ensure the article's image count is above the recommended range of the brief",
                    "args": {},
                    "done": false,
                    "collapsed": false,
                },
                "verifyHeadingsStructure": {
                    "task": "Ensure all headings specified in the brief are present in the content",
                    "done": false,
                    "collapsed": false,
                }
            }
        },
        "contentStructure": {
            "category": "Content",
            "tasks": {
                "addH1WithKeyword": {
                    "task": "Add an H1 tag at the beginning of your article with the primary keyword",
                    "done": false,
                    "weight": 10,
                    "collapsed": false,
                },
                "h1Under60Chars": {
                    "task": "Ensure the article H1 is under 60 characters",
                    "done": false,
                    "collapsed": false,
                },
                "useH2ForSections": {
                    "task": "Use H2 tags in your content",
                    "done": false,
                    "collapsed": false,
                },
                "shortParagraphs": {
                    "task": "Break up long paragraphs into shorter ones of 4-5 sentences",
                    "done": false,
                    "collapsed": false,
                },
                "addLists": {
                    "task": "Include at least one bullet point or numbered list in the article",
                    "done": false,
                    "collapsed": false,
                },
                "addImageWithAlt": {
                    "task": "Insert at least one relevant image with a descriptive alt tag",
                    "done": false,
                    "collapsed": false,
                },
                "clearAndLogicalFlow": {
                    "task": "Ensure the article is easy to read with a clear and logical flow",
                    "done": false,
                    "collapsed": false,
                },
                "addExternalLink": {
                    "task": "Include at least one external link to a credible source that supports your content",
                    "done": false,
                    "collapsed": false,
                }
            }
        },
        "keywordUsage": {
            "category": "Keywords",
            "tasks": {
                "keywordInFirst100Words": {
                    "task": "Include the primary keyword in the first 100 words of the article",
                    "done": false,
                    "weight": 10,
                    "collapsed": false,
                },
                "avoidKeywordStuffing": {
                    "task": "Use your content keywords naturally throughout the article without overstuffing",
                    "done": false,
                    "weight": 5,
                    "collapsed": false,
                },
                "includeRelevantTopic": {
                    "task": "Include relevant topic in your content",
                    "done": false,
                    "weight": 10,
                    "collapsed": false,
                },
                "includeSecondaryKeywords": {
                    "task": "Include most relevant secondary keywords in sections and headings",
                    "done": false,
                    "weight": 5,
                    "collapsed": false,
                }
            }
        }
    };

    function removeShortWordsAndApostrophes(str) {
        return str
            .replace(/\b\w{1,3}\b/g, '')            // Rimuove le parole con meno di 4 caratteri
            .replace(/\b\w+'\w*\b/g, '')            // Rimuove le parole apostrofate
            .replace(/['’]/g, '')                   // Rimuove apostrofi singoli
            .replace(/\s{2,}/g, ' ')                // Elimina spazi multipli
            .trim();                                // Rimuove spazi iniziali e finali
    }

    function recurseHeadings(headings, flatHeadings = []) {
        headings.forEach(heading => {
            flatHeadings.push(heading.value.toLowerCase().trim()); // Add the heading value
            if (heading.children && heading.children.length > 0) {
                recurseHeadings(heading.children, flatHeadings); // Recursively flatten the children
            }
        });
    }

    function flattenBriefHeadings(briefHeadings) {
        if (!briefHeadings) {
            return [];
        }
        let flatHeadings = [];
        recurseHeadings(briefHeadings, flatHeadings);
        return flatHeadings;
    }

    function flattenContentHeadings(contentHeadings) {
        return contentHeadings.map(heading => {
            const headingText = heading.content &&
            heading.content.length > 0 &&
            heading.content[0] ?
                heading.content[0].text.toLowerCase().trim()
                : '';
            return headingText;
        });
    }

    function verifyHeadings(briefHeadings, contentHeadings) {
        const issues = [];

        // Flatten both briefHeadings and contentHeadings structures for easier comparison
        const flatBriefHeadings = flattenBriefHeadings(briefHeadings);
        const flatContentHeadings = flattenContentHeadings(contentHeadings);

        // Now we need to check if each briefHeading exists in contentHeadings
        flatBriefHeadings.forEach(briefHeading => {
            if (!flatContentHeadings.includes(briefHeading)) {
                issues.push({
                    description: `Brief heading "${briefHeading}" is missing from the content.`,
                    start: null,
                    end: null
                });
            }
        });

        return issues;
    }

    function isKeywordStuffing(keywordCount, totalWordCount, threshold = null) {
        if (!threshold) {
            if (totalWordCount <= 500) {
                threshold = 0.05; // 5% for short content
            } else if (totalWordCount <= 1000) {
                threshold = 0.04; // 4% for content up to 1000 words
            } else if (totalWordCount <= 3000) {
                threshold = 0.03; // 3% for content up to 3000 words
            } else if (totalWordCount <= 5000) {
                threshold = 0.02; // 2% for content up to 5000 words
            } else if (totalWordCount <= 10000) {
                threshold = 0.015; // 1.5% for content up to 10,000 words
            } else {
                threshold = 0.01; // 1% for content over 10,000 words
            }
        }
        // Calculate keyword density
        const keywordDensity = keywordCount / totalWordCount;

        // Determine if keyword stuffing is present based on the threshold
        return keywordDensity > threshold;
    }

    // Example of LIX Calculation for Swedish/Danish
    function calculateLIX(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const longWordsCount = text.split(/\s+/).filter(word => word.length > 6).length || 0;

        return (wordCount / sentenceCount) + ((longWordsCount * 100) / wordCount);
    }

    // Gulpease Index for Italian
    function calculateGulpeaseIndex(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const characterCount = text.replace(/\s+/g, '').length || 1;

        // Gulpease Index Formula
        return 89 - (10 * (characterCount / wordCount)) + (300 * (sentenceCount / wordCount));
    }

    // Flesch-Kincaid Readability for English
    function calculateFleschKincaid(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1; // Default to 1 to avoid division by zero
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const syllableCount = text.split(/\s+/).reduce((acc, word) => acc + countSyllables(word), 0) || 1;

        return 206.835 - (1.015 * (wordCount / sentenceCount)) - (84.6 * (syllableCount / wordCount));
    }

    // Fernandez-Huerta Readability for Spanish
    function calculateFernandezHuerta(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const syllableCount = text.split(/\s+/).reduce((acc, word) => acc + countSyllables(word), 0) || 1;

        return 206.84 - (0.60 * (wordCount / sentenceCount)) - (1.02 * (syllableCount / wordCount));
    }

    // Wiener Sachtextformel for German
    function calculateWienerSachtextformel(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const polysyllableCount = text.split(/\s+/).filter(word => countSyllables(word) > 3).length || 0;

        return 0.1935 * (wordCount / sentenceCount) + 0.1672 * (polysyllableCount / wordCount * 100) + 0.1297 * (wordCount / sentenceCount) - 0.0327 * 100;
    }

    // Gunning Fog Index for French, Italian, etc.
    function calculateGunningFogIndex(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const complexWordCount = text.split(/\s+/).filter(word => countSyllables(word) > 2).length || 0;

        return 0.4 * ((wordCount / sentenceCount) + 100 * (complexWordCount / wordCount));
    }

    // Automated Readability Index (ARI) for fallback
    function calculateARI(text) {
        const sentenceCount = text.split(/[.!?]/).filter(Boolean).length || 1;
        const wordCount = text.split(/\s+/).filter(Boolean).length || 1;
        const characterCount = text.replace(/\s+/g, '').length || 1;

        let ariScore = 4.71 * (characterCount / wordCount) + 0.5 * (wordCount / sentenceCount) - 21.43;

        if (ariScore < 0) ariScore = 0;

        return ariScore;
    }


    // Funzione di supporto per contare le sillabe in una parola
    function countSyllables(word) {
        word = word.toLowerCase();
        if (word.length <= 3) return 1;
        word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
        word = word.replace(/^y/, '');
        const syllableMatch = word.match(/[aeiouy]{1,2}/g);
        return syllableMatch ? syllableMatch.length : 1;
    }

    // Funzione per cercare nodi nel JSON del Tiptap
    function findNodesByType(doc, type) {
        let nodes = [];
        if (doc && doc.content) {
            doc.content.forEach(node => {
                if (node.type === type) {
                    nodes.push(node);
                }
                if (node.content) {
                    nodes = nodes.concat(findNodesByType(node, type));
                }
            });
        }
        return nodes;
    }

    function audit(articleDataSet) {

        const todoList = ALL_CONTENT_ANALYSIS_ISSUE;
        const articleContentStats = getCurrentContentStats(articleDataSet.content);

        let editorContentHTML = '';
        if (articleDataSet && articleDataSet.content && articleDataSet.content.html) {
            editorContentHTML = articleDataSet.content.html;
        }

        let editorContentText = '';
        if (articleDataSet && articleDataSet.content && articleDataSet.content.text) {
            editorContentText = articleDataSet.content.text;
        }

        // Reset di tutte le task a false e svuotamento delle issue list

        updateKeywordsOccurrences(articleDataSet, editorContentHTML);

        for (let key in todoList) {
            for (let taskKey in todoList[key].tasks) {
                todoList[key].tasks[taskKey].done = false;
                todoList[key].tasks[taskKey].issues = []; // Inizializza un array vuoto per le issue
            }
        }

        // Estrarre la parola chiave principale
        const mainKeyword = articleDataSet.mainKeyword.toLowerCase();

// Estrarre il titolo (H1)
        const h1Nodes = findNodesByType(articleDataSet.content.json, 'heading')
            .filter(node => node.attrs && node.attrs.level === 1);
        const title = h1Nodes &&
        h1Nodes.length > 0 &&
        h1Nodes[0].content &&
        h1Nodes[0].content.length > 0 &&
        h1Nodes[0].content[0].text ?
            h1Nodes[0].content[0].text.toLowerCase() :
            '';
        const h1 = title;

// Estrarre tag H2
        const h2Nodes = findNodesByType(articleDataSet.content.json, 'heading')
            .filter(node => node.attrs && node.attrs.level === 2);

// Estrarre tag H2
        const h3Nodes = findNodesByType(articleDataSet.content.json, 'heading')
            .filter(node => node.attrs && node.attrs.level === 3);

// Estrarre tag H2
        const h4Nodes = findNodesByType(articleDataSet.content.json, 'heading')
            .filter(node => node.attrs && node.attrs.level === 4);
// Estrarre tag H2
        const h5Nodes = findNodesByType(articleDataSet.content.json, 'heading')
            .filter(node => node.attrs && node.attrs.level === 4);

        const headingsInContent = [...h1Nodes, ...h2Nodes, ...h3Nodes, ...h4Nodes, ...h5Nodes];
// Estrarre i link
        const externalLinks = findNodesByType(articleDataSet.content.json, 'link');


        // Contare le parole nel testo dell'articolo
        const wordCount = articleContentStats.numWords;
        // Contare le parole nel testo dell'articolo
        const numImages = articleContentStats.numImages;
        // Contare le parole nel testo dell'articolo
        const numParagraphs = articleContentStats.numParagraphs;
        // Contare le parole nel testo dell'articolo
        const numHeadings = articleContentStats.numHeadings;

        // Estrarre le immagini e verificarne i tag alt

        const secondaryKeywords = articleDataSet.secondaryKeywords || [];

        // Verifica la struttura dei titoli (H1, H2, H3) rispetto al brief
        const headingIssues = verifyHeadings(articleDataSet.headings, headingsInContent);
        if (headingIssues.length > 0) {
            todoList.brief.tasks.verifyHeadingsStructure.done = false;
            todoList.brief.tasks.verifyHeadingsStructure.issues.push({
                label: "Your content is missing this heading specified in brief",
                elements: headingIssues
            });
        } else {
            todoList.brief.tasks.verifyHeadingsStructure.done = true;
        }

        // Brief: Verifica se il numero di parole, paragrafi, titoli e immagini rientra negli intervalli raccomandati
        const {words, paragraph, headings, images: briefImages} = articleDataSet.contentStrategy;

        todoList.brief.tasks.checkWordCount.done = wordCount >= words.value;

        todoList.brief.tasks.checkWordCount.task = "Write at least {{wordsToAdd}} words for this content";
        todoList.brief.tasks.checkWordCount.args = {
            wordsToAdd: words.value
        };


        todoList.brief.tasks.checkParagraphCount.done = numParagraphs >= paragraph.value;
        todoList.brief.tasks.checkParagraphCount.task = "Add at least {{paragraphsToAdd}} paragraphs in this content";
        todoList.brief.tasks.checkParagraphCount.args = {
            paragraphsToAdd: paragraph.value
        };

        todoList.brief.tasks.checkHeadingsCount.done = numHeadings >= headings.value;
        todoList.brief.tasks.checkHeadingsCount.task = "Add at least {{headingsToAdd}} headings in this content";
        todoList.brief.tasks.checkHeadingsCount.args = {
            headingsToAdd: headings.value
        };


        todoList.brief.tasks.checkImageCount.done = numImages >= briefImages.value;
        todoList.brief.tasks.checkImageCount.task = "Add at least {{imagesToAdd}} images in this content";
        todoList.brief.tasks.checkImageCount.args = {
            imagesToAdd: briefImages.value
        };


        todoList.contentStructure.tasks.h1Under60Chars.done = title.length > 0 && title.length <= 60;
        if (!todoList.contentStructure.tasks.h1Under60Chars.done) {
            const issue = {
                label: "H1 length exceeds limit",
                elements: []
            };
            if (title.length > 0) {
                issue.elements.push({
                    description: `The H1 length is {{h1Length}}, which exceeds the 60 characters limit.`,
                    args: {
                        h1Length: title.length
                    },
                    start: null,
                    end: null
                });
                todoList.contentStructure.tasks.h1Under60Chars.issues.push(issue);
            }
        }


        todoList.contentStructure.tasks.addH1WithKeyword.done = h1.includes(mainKeyword) || removeShortWordsAndApostrophes(h1).includes(mainKeyword);
        todoList.contentStructure.tasks.addH1WithKeyword.task = `Add an H1 heading that includes the main keyword: "<strong>{{mainKeyword}}</strong>"`;
        todoList.contentStructure.tasks.addH1WithKeyword.args = {
            mainKeyword
        };


        todoList.contentStructure.tasks.useH2ForSections.done = h2Nodes.length > 0;


        todoList.keywordUsage.tasks.includeRelevantTopic.issues = [];
        const includeRelevantTopicIssue = {
            label: "Some relevant topics are not present in content",
            elements: []
        };
        Object.values(secondaryKeywords).forEach((keyword) => {
            if (keyword.relevancy < 20) {
                return;
            }
            if (keyword.occurrencesInCurrentText <= 0) {
                includeRelevantTopicIssue.elements.push({
                    description: `You should explore the topic <strong>"{{keyword}}"</strong> in your content`,
                    args: {
                        keyword: keyword.text
                    },
                    start: null,
                    end: null,
                });
            }
        });

        todoList.keywordUsage.tasks.includeRelevantTopic.done = includeRelevantTopicIssue.elements.length <= 0;
        if (!todoList.keywordUsage.tasks.includeRelevantTopic.done) {
            todoList.keywordUsage.tasks.includeRelevantTopic.issues.push(includeRelevantTopicIssue);
        }


        todoList.keywordUsage.tasks.includeSecondaryKeywords.issues = [];

        const secondaryKeywordsIssue = {
            label: "Some secondary keywords are not present in content",
            elements: []
        };
        Object.values(secondaryKeywords).forEach((keyword) => {
            if (keyword.relevancy < 10) {
                return;
            }
            if (keyword.occurrencesInCurrentText < keyword.min) {
                const keywordsToAdd = keyword.min - keyword.occurrencesInCurrentText;
                secondaryKeywordsIssue.elements.push({
                    description: `You should add the keyword <strong>"{{keyword}}"</strong> in your content at least <strong>{{keywordsToAdd}}</strong> times to align your content to the market`,
                    args: {keyword: keyword.text, keywordsToAdd},
                    start: null,
                    end: null,
                });
            }
        });

        todoList.keywordUsage.tasks.includeSecondaryKeywords.done = secondaryKeywordsIssue.elements.length <= 0;
        if (!todoList.keywordUsage.tasks.includeSecondaryKeywords.done) {
            todoList.keywordUsage.tasks.includeSecondaryKeywords.issues.push(secondaryKeywordsIssue);
        }
        const paragraphsNodes = findNodesByType(articleDataSet.content.json, 'paragraph');

        todoList.contentStructure.tasks.shortParagraphs.done = true; // Assume all paragraphs are short by default
        const paragraphIssue = {
            label: "Paragraph exceeds 100 words",
            elements: []
        };

// Iteriamo su ogni nodo di paragrafo trovato
        paragraphsNodes.forEach((p) => {
            // Estraiamo il testo dal contenuto del nodo paragrafo
            const paragraphText = p.content && p.content.map(content => content.text).join(' ') || '';
            const wordCount = paragraphText.split(/\s+/).filter(Boolean).length; // Conta le parole

            if (wordCount > 100) {
                // Aggiunge il problema del paragrafo troppo lungo alla lista degli errori
                paragraphIssue.elements.push({
                    description: `"${paragraphText.slice(0, 80)}..." (${wordCount} words)`,
                    start: null,
                    end: null
                });
            }
        });

        if (paragraphIssue.elements.length > 0) {
            todoList.contentStructure.tasks.shortParagraphs.done = false; // Mark as false if any paragraph is too long
            todoList.contentStructure.tasks.shortParagraphs.issues.push(paragraphIssue);
        }
// Trova i nodi 'bulletList' e 'orderedList' nel documento JSON di Tiptap
        const unorderedLists = findNodesByType(articleDataSet.content.json, 'bulletList');
        const orderedLists = findNodesByType(articleDataSet.content.json, 'orderedList');

// Verifica se ci sono liste nel documento
        todoList.contentStructure.tasks.addLists.done = unorderedLists.length > 0 || orderedLists.length > 0;

        // Keyword Usage
        todoList.keywordUsage.tasks.keywordInFirst100Words.done = editorContentText.slice(0, 100).toLowerCase().includes(mainKeyword.toLowerCase()) || removeShortWordsAndApostrophes(editorContentText).slice(0, 100).toLowerCase().includes(mainKeyword.toLowerCase());

        todoList.keywordUsage.tasks.keywordInFirst100Words.task = "Add the main keyword <strong>{{mainKeyword}}</strong> in the first 100 words of this content";
        todoList.keywordUsage.tasks.keywordInFirst100Words.args = {
            mainKeyword: articleDataSet.mainKeyword,
        };

        todoList.keywordUsage.tasks.avoidKeywordStuffing.issues = [];
        const avoidKeywordStuffingIssue = {
            label: "Keyword not naturally used",
            elements: []
        };
        Object.values(secondaryKeywords).forEach((keyword) => {
            if (isKeywordStuffing(keyword.occurrencesInCurrentText, wordCount)) {
                avoidKeywordStuffingIssue.elements.push({
                    description: `Possible keyword stuffing for the keyword: <strong>"{{keyword}}"</strong> `,
                    args: {
                        keyword: keyword.text
                    },
                    start: null,
                    end: null,
                });
            }
        });

        todoList.keywordUsage.tasks.avoidKeywordStuffing.done = avoidKeywordStuffingIssue.elements.length <= 0;
        if (!todoList.keywordUsage.tasks.avoidKeywordStuffing.done) {
            todoList.keywordUsage.tasks.avoidKeywordStuffing.issues.push(avoidKeywordStuffingIssue);
        }

        const languageCode = articleDataSet.language;

        let readabilityScore;
        let readabilityThreshold;
        let isReadabilityAcceptable;
        let algorithmUsed;

        switch (languageCode) {
            case 'en': // English
                readabilityScore = calculateFleschKincaid(editorContentText);
                readabilityThreshold = 50; // Flesch-Kincaid for moderately complex text
                isReadabilityAcceptable = readabilityScore >= readabilityThreshold;
                algorithmUsed = "Flesch-Kincaid";
                break;
            case 'de': // German
                readabilityScore = calculateWienerSachtextformel(editorContentText);
                readabilityThreshold = 8; // Wiener Sachtextformel for moderately complex text
                isReadabilityAcceptable = readabilityScore <= readabilityThreshold;
                algorithmUsed = "Wiener Sachtextformel";
                break;
            case 'sv': // Swedish
            case 'da': // Danish
                readabilityScore = calculateLIX(editorContentText);
                readabilityThreshold = 30; // LIX score for moderately complex text
                isReadabilityAcceptable = readabilityScore <= readabilityThreshold;
                algorithmUsed = "LIX";
                break;
            case 'es': // Spanish
                readabilityScore = calculateFernandezHuerta(editorContentText);
                readabilityThreshold = 50; // Fernandez-Huerta for moderately complex text
                isReadabilityAcceptable = readabilityScore >= readabilityThreshold;
                algorithmUsed = "Fernandez-Huerta";
                break;
            case 'fr': // French
                readabilityScore = calculateGunningFogIndex(editorContentText);
                readabilityThreshold = 12; // Gunning Fog Index for moderately complex text
                isReadabilityAcceptable = readabilityScore <= readabilityThreshold;
                algorithmUsed = "Gunning Fog Index";
                break;
            case 'it': // Italian
                readabilityScore = calculateGulpeaseIndex(editorContentText);
                readabilityThreshold = 39; // Gulpease Index for moderately complex text
                isReadabilityAcceptable = readabilityScore >= readabilityThreshold;
                algorithmUsed = "Gulpease Index";
                break;
            // Add other cases for languages like SMOG, LIX, etc.
            default:
                readabilityScore = calculateARI(editorContentText);
                readabilityThreshold = 12; // ARI for moderately complex text
                isReadabilityAcceptable = readabilityScore <= readabilityThreshold;
                algorithmUsed = "Automated Readability Index (ARI)";
        }


        todoList.contentStructure.tasks.clearAndLogicalFlow.done = isReadabilityAcceptable;

        if (!isReadabilityAcceptable) {
            todoList.contentStructure.tasks.clearAndLogicalFlow.issues.push({
                label: "Article appears empty or lacks clear content",
                elements: [
                    {
                        description: '{{algorithmUsed}}: <strong>{{readabilityScore}}</strong>',
                        args: {
                            algorithmUsed,
                            readabilityScore: Math.floor(readabilityScore)
                        },
                        start: null,
                        end: null,
                    }
                ]
            });
        }

        // Trova tutti i nodi di tipo 'image' nel documento JSON di Tiptap
        const imageNodes = findNodesByType(articleDataSet.content.json, 'image');


        const images = [];
        const imagesWithAlt = [];

        // Itera su tutti i nodi immagine trovati
        imageNodes.forEach((node) => {
            images.push({node});
            // Se l'immagine ha un attributo alt valido, aggiungila a imagesWithAlt
            if (node.attrs.alt && node.attrs.alt.trim().length > 0) {
                imagesWithAlt.push({node});
            }
        });


        // Verifica della presenza di immagini con il tag alt
        todoList.contentStructure.tasks.addImageWithAlt.done = imagesWithAlt.length >= images.length;
        const imageAltIssue = {
            label: "Image missing alt attribute",
            elements: []
        };

        if (images.length > 0 && imagesWithAlt.length < images.length) {
            images.forEach(({node, pos}) => {
                if (!node.attrs.alt || node.attrs.alt.trim().length === 0) {
                    imageAltIssue.elements.push({
                        description: node.attrs.src,
                        start: null,
                        end: null
                    });
                }
            });
        }


        if (imageAltIssue.elements.length > 0) {
            todoList.contentStructure.tasks.addImageWithAlt.issues.push(imageAltIssue);
        }


        todoList.contentStructure.tasks.addExternalLink.done = externalLinks.length > 0;


        // Calculate the score based on completed tasks
        let totalTasks = 0;
        let completedTasks = 0;

        Object.values(todoList).forEach(category => {
            Object.values(category.tasks).forEach(task => {
                totalTasks += task.weight ? task.weight : 1;
                if (task.done) {
                    completedTasks += task.weight ? task.weight : 1;
                }
            });
        });


        let totalKeywords = 0;
        let totalKeywordsOptimized = 0;
        Object.values(secondaryKeywords).forEach((keyword) => {
            if (keyword.relevancy < 8) {
                return;
            }
            totalKeywords += keyword.relevancy * 2;
            if (keyword.occurrencesInCurrentText >= 1) {
                totalKeywordsOptimized += keyword.relevancy;
            }
            if (keyword.occurrencesInCurrentText >= keyword.min) {
                totalKeywordsOptimized += keyword.relevancy;
            }
        });

        let keywordScore = 0;
        if (totalKeywords > 0) {
            keywordScore = Math.round((totalKeywordsOptimized / totalKeywords) * 100);
        }
        // Calculate the score as a percentage
        const auditScore = Math.round((completedTasks / totalTasks) * 100);
        const score = Math.round((keywordScore + auditScore) / 2);

        return {
            score,
            audits: todoList,
            stats: articleContentStats,
            prompt: toPrompt(articleDataSet, todoList)
        };
    }

    const generateHeadingStructure = (headings, level = 0) => headings.map(heading => {
        const indent = ' '.repeat(level * 2); // Indenta i titoli in base al loro livello
        const titleLine = `${indent}- ${heading.value} (${heading.type})`;
        const childrenLines = heading.children && heading.children.length > 0 ? generateHeadingStructure(heading.children, level + 1) : '';
        return [titleLine, childrenLines].filter(Boolean)
            .join('\n');
    }).join('\n');

    function toPrompt(articleDataSet, todoList, useCompletedTasksOnly = false) {
        const minimumWordsInParagraphs = parseInt(articleDataSet.contentStrategy.words.value / articleDataSet.contentStrategy.paragraph.value) * 4;
        let prompt = `

        You are an esteemed expert copywriter, renowned for crafting deeply insightful and detailed blog articles. You face the challenge of thoroughly exploring a specific topic, penetrating its essence to gift readers with profound understanding and diverse viewpoints. This task demands an exhaustive dissection of the topic, covering implications, methodologies, historical contexts, future prospects, and potential controversies, all integrated into a cohesive narrative.

        ### Your Mission
        Your mission is to write an extensive article in "${articleDataSet.language}" (it's the lang code), directly starting the narrative.
         This article should interlace short and long sentences to maintain reader interest, infused with rare terminology and sophisticated language to enhance originality and depth.
         The presentation must reflect the highest professional standards, with a lucid structure, flawless grammar, and a logically progressing cascade of ideas.

        ### Direct Approach to Content Creation
        This approach, void of self-referential commentary or writing process exposition, aims to showcase your expertise and engage those seeking thorough insights.

         ### Task Details

        Craft a content that aims to fully utilize a 16000-token capacity, delving directly into an in-depth examination of the given topic and adhering to guidelines to deliver a comprehensive and engaging piece. ` +

            `\n• The content must contain a minimum of ${articleDataSet.contentStrategy.words.value * 2} words.` +
            `\n• Each section of every title (h1,h2,h3 etc..) in my content must contain a minimum of ${minimumWordsInParagraphs} words.` +
            `\n• The content must be write in "${articleDataSet.language}" language (it's the lang code).` +
            `\n• The content should be optimized for the primary keyword: "${articleDataSet.mainKeyword}".` +
            `\n• The content must be written in "${articleDataSet.language}".` +
            `\n• The tone of voice of the content must be "${articleDataSet.toneOfVoice}".` +
            `\n• The content must be write from the "${articleDataSet.pointOfView}" perspective.` +
            `\n• This generated text will be a "${articleDataSet.contentType}" content.` +
            `\n• If it's necessary include also external links to reputable sources that complement the content. Make sure these sources are properly cited and relevant to the topic.` +
            `\n• The final response should be in HTML format. The tags HTML allowed in the content are:
                - **Text formatting:** <strong>, <em>, <u>, <del>, <ins>
                - **Quotations and code:** <blockquote>, <pre>, <code>
                - **Lists:** <ol>, <ul>, <li>
                - **Images and figures:** <img>, <figure>
                - **Tables:** <table>, <tr>, <td>, <th>, <thead>, <tbody>, <tfoot>
                - **Miscellaneous:** <abbr>, <p>, <a>` +
            `\n• Highlight important terms in bold within the HTML tags` +
            `\n• The content must following the structure for headings: \n\t${generateHeadingStructure(articleDataSet.headings)}`;

        // Helper function to remove HTML tags from a string
        function stripHTML(html) {
            return html.replace(/<\/?[^>]+(>|$)/g, ""); // Regular expression to remove HTML tags
        }

        // Helper function to replace args in a string
        function replaceArgs(text, args) {
            if (args) {
                Object.keys(args).forEach(arg => {
                    if (text && typeof text === 'string') {
                        text = text.replace(`{{${arg}}}`, args[arg]);
                    }
                });
            }
            return text;
        }

        // Helper function to format task issues
        function formatIssues(issues) {

            return issues.map(issue => {

                // Replace args in the label using issue.args
                let label = replaceArgs(stripHTML(issue.label), issue.args);
                let text = "\n\t• " + label;
                text = text.replace('should', 'must');
                if (issue.elements && issue.elements.length > 0) {
                    text += ': \n' + issue.elements.map(element => {
                        // Remove HTML tags from the description and replace args in element.args
                        let description = stripHTML(element.description);

                        description = replaceArgs(description, element.args);
                        description = description.replace('You should explore the topic', 'You must add the keyword');
                        description = description.replace('should', 'must');
                        description = description.replace('to align your content to the market', '');

                        return `\t\t- ${description}`; // Add indent for better clarity
                    }).join('\n');
                }
                return text;
            }).join('\n');
        }

        let taskCounter = 1;
        // Iterate over each category
        Object.keys(todoList).forEach(categoryKey => {
            const category = todoList[categoryKey];

            // Iterate over each task in the category
            Object.keys(category.tasks).forEach(taskKey => {
                const task = category.tasks[taskKey];
                const taskTitle = replaceArgs(task.task, task.args || {});
                if (taskTitle.match('images')) {
                    return;
                }


                // Include tasks based on the `useCompletedTasksOnly` flag
                if (!useCompletedTasksOnly || (useCompletedTasksOnly && task.done)) {
                    prompt += `\n• ${taskTitle}`; // Add task number

                    // Add issues if there are any
                    if (task.issues && task.issues.length > 0) {
                        prompt += `, here more specifications about what to do:`;
                        prompt += formatIssues(task.issues);
                    }

                    taskCounter++; // Increment task counter
                }
            });

            prompt += `\n`; // Add space between categories
        });

        // console.log(prompt);
        return prompt;
    }


    function getCurrentContentStats(editorContent = {text: null, html: null}) {
        const content = editorContent;
        const articleContentStats = {
            numWords: 0,
            numImages: 0,
            numHeadings: 0,
            numParagraphs: 0,
        };
        try {
            if (!content || !content.text || !content.html) {
                return articleContentStats;
            }
            // Calcolo delle parole
            if (content.text) {
                // Rimuovi spazi extra e dividi il testo in parole
                let words = content.text.trim().split(/\s+/);
                articleContentStats.numWords = words.length;
            }

            // Calcolo dei paragrafi
            if (content.html) {
                // Conta i tag <p> nel contenuto HTML
                articleContentStats.numParagraphs = (content.html.match(/<p[^>]*>/g) || []).length;

                // Calcolo delle immagini
                // Conta i tag <img> nel contenuto HTML
                articleContentStats.numImages = (content.html.match(/<img[^>]*>/g) || []).length;

                // Calcolo delle intestazioni
                // Conta i tag <h1>, <h2>, <h3>, <h4>, <h5>, <h6> nel contenuto HTML
                articleContentStats.numHeadings = (content.html.match(/<h[1-6][^>]*>/g) || []).length;
            }

        } catch (e) {
            console.log(e);
        }
        return articleContentStats;
    }

    function updateKeywordsOccurrences(articleDataSet, articleHtml) {

        if (!articleDataSet || !articleDataSet.secondaryKeywords) {
            return;
        }

        // Ciclare su tutte le keyword e settare showMio=false
        for (let keyword in articleDataSet.secondaryKeywords) {
            const occurrencesInCurrentText = countKeywordUsage(articleDataSet.content.text, keyword);
            // Aggiungi/aggiorna il campo occurrencesInCurrentText
            articleDataSet.secondaryKeywords[keyword].occurrencesInCurrentText = occurrencesInCurrentText;
        }

    }

    // Funzione per ottenere la posizione start e end di un nodo
    function getNodePosition(node, editor) {
        if (!node || !editor || !editor.view || !node.textContent) {
            return {start: null, end: null};
        }
        const pos = editor.view.posAtDOM(node, 0);
        const nodeSize = node.textContent.length;
        return {start: pos, end: pos + nodeSize};
    }

    function getNodeCharSize(node) {
        let size = 0;
        for (let i = 0; i < node.content.length; i++) {
            if (node.content[i].text) {
                size += node.content[i].text.length;
            }
        }
        return size;
    }

    function getDefaultArticleDataSetModel() {
        return {
            mode: 'WRITE_WITH_AI',
            mainKeyword: null,
            country: 'US',
            language: 'en',
            device: 'mobile',
            content: null,
            headings: null,
            marketAnalysis: null,
            contentStrategy: {
                words: {
                    value: 1000,
                    marketAvg: 1000,
                    marketMin: 1000,
                    marketMax: 1500,
                },
                paragraph: {
                    value: 20,
                    marketAvg: 20,
                    marketMin: 10,
                    marketMax: 25,
                },
                headings: {
                    value: MIN_NUM_OF_HEADINGS_IN_CONTENT,
                    marketAvg: MIN_NUM_OF_HEADINGS_IN_CONTENT,
                    marketMin: 5,
                    marketMax: 7,
                },
                images: {
                    value: 2,
                    marketAvg: 2,
                    marketMin: 1,
                    marketMax: 4,
                },
            },
            toneOfVoice: "professional",//secondPerson
            pointOfView: "secondPerson",//instructive
            contentType: "blogPost",//blogPost
        };
    }

    function generateHeadingsUsingCommonTitles(mainKeyword, marketAnalysis) {
        const titleStructure = {
            value: mainKeyword,
            type: "h1",
            children: []
        };
        try {
            const h2Counts = {};
            // Conta la frequenza degli H2 in tutti i risultati
            marketAnalysis.rows.forEach(row => {
                if (row.extraParams && row.extraParams.h2) {
                    row.extraParams.h2.forEach(h2 => {
                        if (h2Counts[h2]) {
                            h2Counts[h2]++;
                        } else {
                            h2Counts[h2] = 1;
                        }
                    });
                }
            });
            // Seleziona solo gli H2 che sono comuni a 2 o più risultati
            Object.keys(h2Counts).forEach(h2 => {
                if (h2Counts[h2] >= 2) {
                    titleStructure.children.push({
                        value: h2,
                        type: "h2",
                        children: []
                    });
                }
            });
            return [titleStructure];
        } catch (e) {
            return [titleStructure];
        }
    }

    function getReferencesContentStrategy(articleDataSet) {

        if (!articleDataSet ||
            !articleDataSet.marketAnalysis ||
            !articleDataSet.marketAnalysis.rows ||
            articleDataSet.marketAnalysis.rows.length <= 0) {
            return getDefaultArticleDataSetModel().contentStrategy;
        }

        const {rows} = articleDataSet.marketAnalysis;

        const filteredRows = rows.filter(row =>
            row.hasOwnProperty('useThisStrategy') &&
            row.useThisStrategy === true
        );

        if (filteredRows.length <= 0) {
            //if the user choose to set no references, use a default content strategy
            return getDefaultArticleDataSetModel().contentStrategy;
        }

        const contentStrategy = {
            words: calculateWordCount(filteredRows),
            paragraph: calculateParagraphMetrics(filteredRows),
            headings: calculateHeadingsMetrics(filteredRows),
            images: calculateImagesMetrics(filteredRows),
        };

        const isContentStrategyIncorrect = isNaN(contentStrategy.words.value) ||
            isNaN(contentStrategy.headings.value) ||
            isNaN(contentStrategy.paragraph.value) ||
            isNaN(contentStrategy.images.value);

        if (isContentStrategyIncorrect) {
            //if the user choose to set no references, use a default content strategy
            return getDefaultArticleDataSetModel().contentStrategy;
        }

        if (!contentStrategy.words.marketMin) {
            contentStrategy.words.marketMin = contentStrategy.words.marketAvg;
        }
        if (!contentStrategy.headings.marketMin) {
            contentStrategy.headings.marketMin = contentStrategy.headings.marketAvg;
        }
        if (!contentStrategy.paragraph.marketMin) {
            contentStrategy.paragraph.marketMin = contentStrategy.paragraph.marketAvg;
        }
        if (!contentStrategy.images.marketMin) {
            contentStrategy.images.marketMin = contentStrategy.images.marketAvg;
        }
        return contentStrategy;
    }

    function calculateWordCount(rows) {
        const values = [];
        let sum = 0;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].hasOwnProperty('useThisStrategy') &&
                rows[i].hasOwnProperty('extraParams') &&
                rows[i].extraParams &&
                rows[i].useThisStrategy) {
                values.push(Number(rows[i].extraParams.wordCount));
                sum += Number(rows[i].extraParams.wordCount);
            }
        }

        const avg = sum / values.length;
        const min = Math.min(...values);
        const max = Math.max(...values);

        return {
            value: parseInt(avg.toFixed(0)),
            marketAvg: parseInt(avg.toFixed(0)),
            marketMin: parseInt(min),
            marketMax: parseInt(max),
        };
    }

    function calculateParagraphMetrics(rows) {
        const values = [];

        rows.map(row => {
            if (row && row.extraParams && row.extraParams.paragraphs && row.extraParams.paragraphs.length > 0) {
                values.push(row.extraParams.paragraphs.length);
            }
        });

        const sum = values.reduce((acc, val) => acc + val, 0);
        const avg = sum / values.length;
        const min = Math.min(...values);
        const max = Math.max(...values);

        return {
            value: parseInt(avg.toFixed(0)),
            marketAvg: parseInt(avg.toFixed(0)),
            marketMin: parseInt(min),
            marketMax: parseInt(max),
        };
    }

    function calculateImagesMetrics(rows) {
        let sum = 0;
        const values = rows.map(row => {
            if (!row || !row.extraParams) {
                return 0;
            }

            let totalImage = row.extraParams.articleImages ? row.extraParams.articleImages.length : 0;
            sum += totalImage;
            return totalImage;
        });

        const avg = sum / values.length;
        const min = Math.min(...values);
        const max = Math.max(...values);

        return {
            value: parseInt(avg.toFixed(0)),
            marketAvg: parseInt(avg.toFixed(0)),
            marketMin: parseInt(min),
            marketMax: parseInt(max),
        };
    }

    function calculateHeadingsMetrics(rows) {
        const values = rows.map(row => {
            if (!row || !row.extraParams) {
                return 0;
            }

            const h1Count = row.extraParams.h1 ? row.extraParams.h1.length : 0;
            const h2Count = row.extraParams.h2 ? row.extraParams.h2.length : 0;
            const h3Count = row.extraParams.h3 ? row.extraParams.h3.length : 0;
            return h1Count + h2Count + h3Count;
        });

        const sum = values.reduce((acc, val) => acc + val, 0);
        const avg = sum / values.length;
        const min = Math.min(...values);
        const max = Math.max(...values);

        return {
            value: parseInt(avg.toFixed(0)),
            marketAvg: parseInt(avg.toFixed(0)),
            marketMin: parseInt(min),
            marketMax: parseInt(max),
        };
    }


    async function generatedSeedKeywords(articleDataSet) {
        if (!articleDataSet.mainKeyword) {
            return {};
        }
        const keywordStats = {};
        keywordStats[articleDataSet.mainKeyword] = {
            text: articleDataSet.mainKeyword,
            occurrencesInCurrentText: 1,
            min: 1,
            max: 1,
            relevancy: 100,
            total: 0,
            usedInHeadings: true,

            occurrences: 0,
            currentExampleIndex: 0,
            showExample: false,
            examples: [], // Inizializza l'array di esempi
            sources: [] // Inizializza l'array di sorgenti
        };
        const related = await GoogleSuggestionsService.related({
            lang: articleDataSet.language,
            country: articleDataSet.country,
            query: articleDataSet.mainKeyword,
        });
        const questions = await GoogleSuggestionsService.questions({
            lang: articleDataSet.language,
            country: articleDataSet.country,
            query: articleDataSet.mainKeyword,
        });
        const keywordsToAdd = [...related, ...questions];
        for (let i = 0; i < keywordsToAdd.length; i++) {
            keywordStats[keywordsToAdd[i]] = {
                text: keywordsToAdd[i],
                occurrencesInCurrentText: 1,
                min: 1,
                max: 1,
                relevancy: 10,
                total: 0,
                usedInHeadings: false,
                occurrences: 0,
                currentExampleIndex: 0,
                showExample: false,
                examples: [],
                sources: []
            };

            let relatedTopic = keywordsToAdd[i].replace(articleDataSet.mainKeyword, '').trim();
            if (relatedTopic.length > 2) {
                keywordStats[relatedTopic] = {
                    text: relatedTopic,
                    occurrencesInCurrentText: 1,
                    min: 1,
                    max: 1,
                    relevancy: 10,
                    total: 0,
                    usedInHeadings: false,
                    occurrences: 0,
                    currentExampleIndex: 0,
                    showExample: false,
                    examples: [],
                    sources: []
                };
            }

        }
        return keywordStats;
    }

    async function getRelevantKeywords(articleDataSet) {
        let rows = [];
        const keywordStats = {};
        if (articleDataSet &&
            articleDataSet.marketAnalysis &&
            articleDataSet.marketAnalysis.rows) {
            rows = articleDataSet.marketAnalysis.rows;
        }

        if (!rows.length) {
            return await generatedSeedKeywords(articleDataSet);
        }

        let countReferences = 0;

        // Define common stop words from multiple languages
        const stopWords = [
            // Italian
            'di', 'e', 'il', 'la', 'i', 'gli', 'le', 'un', 'una', 'in', 'su', 'per', 'con', 'da', 'che', 'al', 'ai',
            // English
            'the', 'and', 'a', 'an', 'of', 'to', 'in', 'on', 'for', 'with', 'at', 'by', 'from', 'that', 'it', 'as',
            // French
            'le', 'la', 'les', 'et', 'un', 'une', 'des', 'de', 'du', 'dans', 'sur', 'pour', 'avec', 'par', 'que',
            // Spanish
            'el', 'la', 'los', 'las', 'y', 'un', 'una', 'de', 'del', 'en', 'con', 'por', 'para', 'que',
            // German
            'der', 'die', 'das', 'und', 'ein', 'eine', 'eines', 'dem', 'den', 'zu', 'im', 'auf', 'für', 'mit', 'von', 'als',
            // Portuguese
            'o', 'a', 'os', 'as', 'e', 'um', 'uma', 'de', 'do', 'da', 'no', 'na', 'por', 'para', 'que',
            // Dutch
            'de', 'het', 'een', 'en', 'van', 'op', 'bij', 'met', 'voor', 'dat',
            // More languages can be added as needed
        ];

        let contentHasAtLeastAStrategy = false;
        rows.forEach(row => {
            if (row.useThisStrategy) {
                contentHasAtLeastAStrategy = true;
            }
        });
        let followedStrategy = 0;
        if (!contentHasAtLeastAStrategy) {
            rows.forEach(row => {
                if (followedStrategy <= 3) {
                    followedStrategy++;
                    row.useThisStrategy = true;
                }
            });
        }

        rows.forEach(row => {
            if (!row.useThisStrategy) {
                return;
            }
            countReferences++;
            const relevantKeywords = row.extraParams.relevantKeywords || [];
            let textualContent = row.extraParams.textualContent || '';
            const sourceUrl = row.link.url || null;
            let host = null;

            // Extract the host from the URL
            if (sourceUrl) {
                try {
                    const urlObj = new URL(sourceUrl);
                    host = urlObj.hostname;
                } catch (e) {
                    console.error(`Invalid URL: ${sourceUrl}`);
                    return;
                }
            }

            textualContent = textualContent.replace(/\s+/g, ' ').trim();
            relevantKeywords.forEach(keywordInfo => {
                let word = keywordInfo.word;
                const count = keywordInfo.count;
                const relevancy = keywordInfo.relevancy || 0;
                const usedInHeadings = keywordInfo.isInH1 || keywordInfo.isInH2;

                // Clean up the keyword
                word = word.replace(/[^a-zèòàùéì&'0-9\s]/gi, ' ').trim();
                word = word.replace(/\s+/g, ' ').trim();

                // Split the keyword into individual components (words)
                const keywordParts = word.split(/\s+/);

                // Check if any component of the keyword is found in the host (excluding stop words and words smaller than 4 characters)
                const isBrandedKeyword = keywordParts.some(part => {
                    return host && !stopWords.includes(part.toLowerCase()) && part.length >= 4 && host.toLowerCase().includes(part.toLowerCase());
                });

                // Skip if it's a branded keyword, except for the main keyword
                if (isBrandedKeyword && word !== articleDataSet.mainKeyword) {
                    return;
                }

                if (!keywordStats.hasOwnProperty(word)) {
                    keywordStats[word] = {
                        text: word,
                        occurrencesInCurrentText: 0,
                        min: count,
                        max: count,
                        relevancy: relevancy,
                        total: count,
                        usedInHeadings,
                        occurrences: 1,
                        currentExampleIndex: 0,
                        showExample: false,
                        examples: [], // Initialize examples array
                        sources: [] // Initialize sources array
                    };
                } else {
                    keywordStats[word].min = Math.min(keywordStats[word].min, count);
                    keywordStats[word].max = Math.max(keywordStats[word].max, count);
                    keywordStats[word].total += count;
                    if (!keywordStats[word].usedInHeadings) {
                        keywordStats[word].usedInHeadings = usedInHeadings;
                    }
                    keywordStats[word].occurrences += 1;
                    keywordStats[word].relevancy += relevancy;
                }
                keywordStats[word].examples.push(...getExamplesFromText(word, textualContent, sourceUrl));
            });
            analyzeMainKeyword(articleDataSet.mainKeyword, row, keywordStats);
        });

        for (const word in keywordStats) {
            if (keywordStats.hasOwnProperty(word)) {
                const stats = keywordStats[word];
                stats.avg = stats.total / stats.occurrences;
                stats.relevancy = stats.relevancy / countReferences;
                delete stats.total;
                delete stats.occurrences;
                if (word !== articleDataSet.mainKeyword && keywordStats[word].max <= 2) {
                    delete keywordStats[word];
                }
            }
        }

        return keywordStats;
    }


    function analyzeMainKeyword(mainKeyword, serpItemReference, keywordStats) {
        let textualContent = serpItemReference.extraParams.textualContent || '';
        const source = {
            url: serpItemReference.link.url || null,
            title: serpItemReference.extraParams.title || ''
        };
        const occurrencesInCurrentText = countKeywordUsage(textualContent, mainKeyword);
        if (!keywordStats.hasOwnProperty(mainKeyword)) {
            keywordStats[mainKeyword] = {
                text: mainKeyword,
                occurrencesInCurrentText: 1,
                min: 1,
                max: 1,
                relevancy: 100,
                total: 0,
                usedInHeadings: true,
                occurrences: 0,
                currentExampleIndex: 0,
                showExample: false,
                examples: [], // Inizializza l'array di esempi
                sources: [] // Inizializza l'array di sorgenti
            };
        }

        keywordStats[mainKeyword].min = Math.min(keywordStats[mainKeyword].min, occurrencesInCurrentText);
        keywordStats[mainKeyword].max = Math.max(keywordStats[mainKeyword].max, occurrencesInCurrentText);
        keywordStats[mainKeyword].total += occurrencesInCurrentText;
        keywordStats[mainKeyword].occurrences += 1;
        keywordStats[mainKeyword].relevancy += 100;
        keywordStats[mainKeyword].examples.push(...getExamplesFromText(mainKeyword, textualContent, source));
    }

    function countKeywordUsage(text, keyword) {

        if (keyword.length > 3 && keyword.match(/\s/mi)) {
            // Create a case-insensitive regular expression to find the keyword
            const keywordRegex = new RegExp(`\\b${removeShortWordsAndApostrophes(keyword)}\\b`, 'gim');

            // Use the match method to find all occurrences of the keyword
            const matches = removeShortWordsAndApostrophes(text).match(keywordRegex);

            // Return the count of matches, or 0 if no matches were found
            return matches ? matches.length : 0;
        }

        if (keyword.length > 3 && !keyword.match(/\s/mi)) {
            // If the keyword is a single word and has more than 3 characters,
            // Create a regex that allows the last character to vary (e.g., "ricetta" and "ricette")
            const keywordRegex = new RegExp(`\\b${removeShortWordsAndApostrophes(keyword).slice(0, -1)}?`, 'gim');

            // Use the match method to find all occurrences of the keyword
            const matches = removeShortWordsAndApostrophes(text).match(keywordRegex);

            // Return the count of matches, or 0 if no matches were found
            return matches ? matches.length : 0;
        }


        // Create a case-insensitive regular expression to find the exact keyword
        const keywordRegex = new RegExp(`\\b${keyword}`, 'gim');

        // Use the match method to find all occurrences of the keyword
        const matches = text.match(keywordRegex);

        // Return the count of matches, or 0 if no matches were found
        return matches ? matches.length : 0;
    }

    function getExamplesFromText(word, textualContent, source = null) {
        const examples = [];
        // Costruisce la regex per trovare la keyword nel testo e catturare l'intero periodo
        const regex = new RegExp(`[^.!?\\n]*?\\b${word}\\b[^.!?\\n]*[.!?]`, 'gmi');
        let match;

        while ((match = regex.exec(textualContent)) !== null) {
            let example = match[0].trim();

            // Assicurarsi che l'esempio contenga la parola chiave completa
            const startIndex = match.index;
            const endIndex = regex.lastIndex;

            const before = textualContent.slice(Math.max(0, startIndex - 100), startIndex);
            const after = textualContent.slice(endIndex, Math.min(textualContent.length, endIndex + 150));

            example = `${before} ${example} ${after}`.trim();

            if (example.length > 250) {
                // Se l'esempio supera i 250 caratteri, troncalo senza troncare la parola chiave
                const keywordIndex = example.toLowerCase().indexOf(word.toLowerCase());
                const beforeKeyword = example.slice(0, keywordIndex).trim();
                const afterKeyword = example.slice(keywordIndex + word.length).trim();
                const beforeText = beforeKeyword.length > 125 ? `...${beforeKeyword.slice(-125)}` : beforeKeyword;
                const afterText = afterKeyword.length > 125 ? `${afterKeyword.slice(0, 125)}...` : afterKeyword;

                example = `${beforeText} <strong>${word}</strong> ${afterText}`.trim();
            } else {
                // Evidenzia la parola chiave rispettando la distinzione tra maiuscole e minuscole
                example = example.replace(new RegExp(`\\b${word}\\b`, 'gi'), match => `<strong>${match}</strong>`);
            }

            examples.push({
                text: example.replace(/\\n/gim, ' ').replace(/\\s/gim, ' ').replace(/\\t/gim, ' '),
                source: source
            });
        }
        return examples;
    }

    /* Randomize array in-place using Durstenfeld shuffle algorithm */
    function shuffleArray(array) {
        for (var i = array.length - 1; i >= 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    return {
        getRelevantKeywords,
        generateHeadingsUsingCommonTitles,
        getReferencesContentStrategy,
        audit,
        getCurrentContentStats,
        updateKeywordsOccurrences,
        toPrompt

    };

}

export default WriterAssistantAuditorService;

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/components/st-toolbar-detail/st-toolbar-detail.html',
        bindings: {},
        controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
            var vm = this;
            vm.list = [];
            vm.key = null;
            vm.label = '';
            $scope.filterQuery = undefined;
            vm.tag = '';
            if (vm.showToolbar == undefined) {
                vm.showToolbar = false;
            }

            $scope.$watch('$ctrl.showToolbar', function (showToolbar) {
                vm.showToolbar = showToolbar;
            });

            $rootScope.$on('$stateChangeStart', function () {
                vm.showToolbar = false;
            });

            $rootScope.$on('openToolbarDetail', function (event, data) {
                vm.filterQuery = '';
                vm.showToolbar = true;
                vm.list = data.list;
                vm.templateType = data.templateType || 'none';
                vm.key = data.key;
                vm.label = data.label || '';
                vm.element = data.element || '';
                vm.placeholder = data.placeholder || 'Search..';
            });
        }]
    };
});

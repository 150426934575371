/**
 * Created by Vittorio Urzì on 09/08/2017.
 */
'use strict';
define(['app'], function (app) {
    var STIMED_CTR_SERP={
        topAds: {
            1: 7.11,
            2: 3.01,
            3: 2.19,
            4: 1.61,
        },
        sideAds:{
            1: 2.15,
            2: 1.61,
            3: 1.04,
            4: 0.89,
            5: 0.75,
            6: 0.55
        },
        organic:{
            1: 28.5,
            2: 15.7,
            3: 11.0,
            4: 8.0,
            5: 7.2,
            6: 5.1,
            7: 4.0,
            8: 3.2,
            9: 2.8,
            10: 2.5,
            11: 3.2,
            12: 0.20,
            13: 0.19,
            14: 0.19,
            15: 0.18,
            16: 0.18,
            17: 0.16,
            18: 0.16,
        }
    };

    app.service('SerpCTRService', SerpCTRService);
    SerpCTRService.$inject = ['$http', '$sce'];

    function SerpCTRService() {

        return {
            getCTRByPosition: getCTRByPosition
        };

        function getCTRByPosition(pos,type='organic'){
            if(type==='paid')
                type='topAds';
            if(!STIMED_CTR_SERP[type])
                return 0;
            return STIMED_CTR_SERP[type][pos]||0;
        }
    }
});

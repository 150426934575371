'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/status-code-bar/status-code-bar.html',
        bindings: {
            inAnimation: '<',
            codesByDepth: '<'
        },
        controller: ['$scope', '$mdMedia', function ($scope, $mdMedia) {
            var vm = $scope;
            vm.$mdMedia = $mdMedia;
            vm.inAnimation = true;
            vm.dataSuccess = [];
            vm.dataRedirect = [];
            vm.dataError = [];
            vm.colors = ['#0BC7B5', '#FFE3D8', '#FF0066'];
            vm.labels = [1, 2, 3, 4, 5, 6];

            vm.isLoaded = false;

            vm.data = [
                vm.dataSuccess,
                vm.dataRedirect,
                vm.dataError
            ];

            vm.datasetOverride = [{
                label: "Success (2xx)",
                borderWidth: 0,
                backgroundColor: '#0BC7B5',
            },
                {
                    label: "Redirect (3xx)",
                    borderWidth: 0,
                    backgroundColor: '#FFE3D8',
                },
                {
                    label: "Client Error (4xx)",
                    borderWidth: 0,
                    backgroundColor: '#FF0066',
                }
            ];

            vm.chartOpts = {
                responsive: true,
                animation: {},
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    animation: false,
                    align: 'left',
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    boxWidth: 10,
                },
                title: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        display: false,
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent',
                        },
                        barPercentage: 1.0,
                        categoryPercentage: 0.8,
                        ticks: {
                            suggestedMax: 6,
                        }
                    }],
                    yAxes: [{
                        position: 'right',
                        stacked: true,
                        display: true,
                        gridLines: {
                            color: 'rgba(118, 110, 148, 0.12)',
                            zeroLineColor: 'rgba(118, 110, 148, 0.12)',
                        },
                        ticks: {
                            suggestedMax: 100,
                        }
                    }]
                }
            }

            vm.$watchCollection('$ctrl.codesByDepth', function (codesByDepth) {
                if (codesByDepth && codesByDepth.length) {
                    setData(codesByDepth)
                }
                if (codesByDepth && !codesByDepth.length) {
                    vm.dataSuccess = [0];
                    vm.dataRedirect = [0];
                    vm.dataError = [0];
                    vm.data = [
                        vm.dataSuccess,
                        vm.dataRedirect,
                        vm.dataError
                    ];
                }
            });

            vm.$watch('$ctrl.inAnimation', function (inAnimation) {
                if (!inAnimation) {
                    vm.chartOpts.animation.duration = 0;
                }
            })

            function getCountFromJson(codeCounts, status) {
                var codeCountForDepth = 0;
                codeCounts.forEach(function (code) {
                    if (code.status === status) {
                        codeCountForDepth = code.count;
                    }
                })
                return codeCountForDepth;
            }

            function setData(codesByDepth) {
                vm.labels = [];
                angular.forEach(codesByDepth, function (codeByDepth, key) {
                    vm.labels.push(key);

                    var codeCounts = JSON.parse(codeByDepth.json);

                    var countSuccess = getCountFromJson(codeCounts, 200);
                    var countRedirect = getCountFromJson(codeCounts, 301);
                    var countError = getCountFromJson(codeCounts, 404);

                    vm.dataSuccess.splice(codeByDepth.depth - 1, 1, countSuccess);
                    vm.dataRedirect.splice(codeByDepth.depth - 1, 1, countRedirect);
                    vm.dataError.splice(codeByDepth.depth - 1, 1, countError);
                });

                vm.data = [
                    vm.dataSuccess,
                    vm.dataRedirect,
                    vm.dataError
                ];
            }
        }]
    };
});

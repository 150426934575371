'use strict';

var SEO_ONPAGE_SCORE_BEHAVIOR = [
    {
        label: "Easy",
        from: 0,
        until: 25,
        color: '#DB3236',
    },
    {
        label: "Medium",
        from: 25,
        until: 50,
        color: '#EE671A'
    },
    {
        label: "Hard",
        from: 50,
        until: 75,
        color: '#FFBE00'
    },
    {
        label: 'Very Hard',
        from: 75,
        until: 100,
        color: '#6CBF11'
    }
];
define([], function () {
    function Controller($scope, $rootScope, SEOEditorService, AuthService,$timeout) {
        var vm = $scope;

        vm.densities = [];
        vm.mainKeyword = [];
        vm.secondariesKeywords = [];

        vm.tabMode='seo-checklist';

        if (AuthService.isAuthenticated()) {
            auditListener();
        }


        this.$postLink = function() {
            $timeout(()=>{
                var sidebar = document.getElementById('st-editor-main-sidebar');
                var startShowingStickyHeader = document.getElementById('start-showing-sticky-header');
                var stickyHeader = document.getElementById('sticky-header-sidebar-editor');

                sidebar.addEventListener('scroll', function() {
                    if (startShowingStickyHeader.getBoundingClientRect().y < 0) {
                        stickyHeader.classList.add("sticky-header");
                        stickyHeader.classList.remove("hidden");
                    }
                    if (startShowingStickyHeader.getBoundingClientRect().y >= 0) {
                        stickyHeader.classList.remove("sticky-header");
                        stickyHeader.classList.add("hidden");
                    }
                },{
                    passive:true
                });
            },300);


        };

        function auditListener() {
            var mutexDensities = true;
            $scope.$watch("audit", function (audit) {
                if (audit && audit.density && mutexDensities) {
                    mutexDensities = false;
                    $timeout( ()  => {
                        mutexDensities = true;
                        vm.mainKeyword = [];
                        vm.secondariesKeywords = [];
                        vm.densities = [];
                        vm.mainKeyword = vm.mainKeyword.concat(audit.content.primaryKw.value);
                        vm.secondariesKeywords = vm.secondariesKeywords.concat(audit.content.secondariesKw.value);
                        vm.densities = vm.densities.concat(audit.density.oneWord.density);
                        vm.densities = vm.densities.concat(audit.density.twoWords.density);
                        vm.densities = vm.densities.concat(audit.density.threeWords.density);
                        vm.densities = vm.densities.concat(audit.density.fourWords.density);
                    }, 250);
                }
            }, true);

        }

    }



    Controller.$inject = ['$scope', '$rootScope', 'SEOEditorService', 'AuthService', '$timeout'];
    return Controller;
});

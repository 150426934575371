'use strict';
define([], function () {
    function Controller($rootScope, $scope, LanguageService) {
        $scope.switchLang = switchLang;
        $scope.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();
    
        function switchLang(language) {
            $scope.selectedLang = language;
            $rootScope.setLang(language);
        }
    }

    Controller.$inject = ['$rootScope', '$scope', 'LanguageService'];
    return Controller;
});

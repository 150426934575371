'use strict';
define([], function () {
    function SinglePageSummaryCtrl($scope, $filter) {
        $scope.isInternalLink = isInternalLink;
        $scope.isRelative = isRelative;
        $scope.getColorByWordCount = getColorByWordCount;
        $scope.getColorByTitleLength = getColorByTitleLength;
        $scope.getColorByDescriptionLength = getColorByDescriptionLength;
        $scope.getPercentageTitle = getPercentageTitle;
        $scope.getPercentageDescription = getPercentageDescription;
        $scope.getPercentageWCount = getPercentageWCount;
        if ($scope.detail.metaOgImage) {
            if (isRelative($scope.detail.metaOgImage))
                $scope.detail.metaOgImage = $scope.analysis.url + "/" + $scope.detail.metaOgImage;
        }

        if ($scope.detail.metaTwitterImage) {
            if (isRelative($scope.detail.metaTwitterImage))
                $scope.detail.metaTwitterImage = $scope.analysis.url + "/" + $scope.detail.metaTwitterImage;
        }

        $scope.formatDate = function (date, options) {
            if (!date) {
                return;
            }
            var localLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
            return new Date(date).toLocaleDateString(localLang, options);
        };

        $scope.cleanUrl = function (url) {
            if (!url) {
                return;
            }

            var urlObj = new URL(url);
            var formattedUrl = (urlObj.hostname + urlObj.pathname).replace(/\/$/, "");

            if (formattedUrl.length > 50) {
                return '...' + formattedUrl.substring(formattedUrl.length - 50, 100);
            }

            return formattedUrl;
        };

        function isInternalLink(domainUrl, urlToCheck) {
            var host = new URL(domainUrl).host.toLowerCase().replace(/^www./gi, '');
            var isRelative = new RegExp("/^[^\/]+\/[^\/].*$|^\/[^\/].*$/gi");
            var isValidUrl = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
            if (isRelative.test(urlToCheck)) {
                return true;
            } else {
                if (isValidUrl.test(urlToCheck)) {
                    var thisHost = new URL(urlToCheck).host.replace(/^www./gi, '');
                    if (thisHost.toLowerCase() == host) {
                        return true;
                    } else return false;
                } else return true;
            }
        }

        function isRelative(url) {
            return !new RegExp(/^http[s]{0,1}\:\/\//i).test(url) && !new RegExp(/^\/\//i).test(url);
        }

        function getColorByWordCount(wordCount) {
            if (wordCount >= 600)
                return 'green-text';
            else if (wordCount >= 400)
                return 'text-darken-1 orange-text';
            else
                return 'red-text';

        }

        function getPercentageTitle(len) {
            if (len > 70)
                return 100;
            return (len * 100) / 70;
        }

        function getPercentageDescription(len) {
            if (len > 160)
                return 100;
            return (len * 100) / 160;
        }

        function getPercentageWCount(len) {
            if (len > 600)
                return 100;
            return (len * 100) / 600;
        }

        function getColorByTitleLength(titleLength) {
            if (titleLength <= 70 && titleLength >= 45)
                return 'st-green';
            if (titleLength >= 35 && titleLength <= 44)
                return 'st-orange';
            else return 'st-red';

        }

        function getColorByDescriptionLength(titleLength) {
            if (titleLength <= 160 && titleLength >= 70)
                return 'st-green';
            if (titleLength >= 71 && titleLength <= 159)
                return 'st-orange';
            else return 'st-red';
        }
    }

    SinglePageSummaryCtrl.$inject = ['$scope', '$filter'];
    return SinglePageSummaryCtrl;
});

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/headings/edit-headings/edit-headings.html',
    bindings: {
        headings: '=',
        editable: '=',
    },
    controller: function() {

        var ctrl = this;

        // Function to move the heading up in the list
        ctrl.moveUp = function(array, index) {
            if (index > 0) {
                var temp = array[index];
                array[index] = array[index - 1];
                array[index - 1] = temp;
            }
        };

        // Function to move the heading down in the list
        ctrl.moveDown = function(array, index) {
            if (index < array.length - 1) {
                var temp = array[index];
                array[index] = array[index + 1];
                array[index + 1] = temp;
            }
        };
        ctrl.addHeading = function(parentHeading) {
            var newHeadingType = ctrl.getNextHeadingType(parentHeading.type);

            parentHeading.children = parentHeading.children || [];
            parentHeading.children.push({
                value: parentHeading.newChildValue,
                type: newHeadingType,
                children: []
            });

            parentHeading.newChildValue = '';  // Resetta il campo input specifico per questo heading
        };

        ctrl.checkAddChild = function(event, parentHeading) {
            if (event.key === 'Enter' && parentHeading.newChildValue.trim() !== '') {
                ctrl.addHeading(parentHeading);
            }
        };

        ctrl.removeHeading = function(parentArray, index) {
            parentArray.splice(index, 1);
        };

        ctrl.getNextHeadingType = function(currentType) {
            var currentLevel = parseInt(currentType.replace('h', ''), 10);
            var nextLevel = Math.min(currentLevel + 1, 6);  // h6 è il livello massimo
            return 'h' + nextLevel;
        };
        ctrl.getNextHeadingLevel = function(currentType) {
            var currentLevel = parseInt(currentType.replace('h', ''), 10);
            var nextLevel = Math.min(currentLevel + 1, 6);  // h6 è il livello massimo
            return nextLevel;
        };

    }


};

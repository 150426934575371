'use strict';
define(['app'], function (app) {
    app.component('stCopyClipboard', {
        templateUrl: 'app/components/st-copy-clipboard/st-copy-clipboard.html',
        controller: controller,
        bindings: {
            options: "="
        }
    });

    controller.$inject = ['$scope','$mdToast'];

    function controller($scope, $mdToast){
        var ctrl = this;

        $scope.$watch('$ctrl.options', function (options) {
            ctrl.options = options;
        });

        function showToast() {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="icon-checkmark st-green m-r-sm"></i></div>' +
                    '   <div>{{\'Copied\' | translate}}</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        ctrl.copyToClipboard= function(data,limit,mode){

            var content = data;
            var counter=0;
            switch(mode){
                case 'rows':
                    content="";
                    if(!limit){
                        limit=data.length;
                    }
                    data.forEach(function(rowArray) {
                        if(counter<limit){
                            var row = rowArray.join(", ");
                            content += row + "\r\n";
                            counter++;
                        }
                    });
                    break;
                default :
                    break;
            }

            var el = document.createElement('textarea');  // Create a <textarea> element
            el.value = content;                                 // Set its value to the string that you want copied
            el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
            el.style.position = 'absolute';
            el.style.left = '-9999px';                      // Move outside the screen to make it invisible
            document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
            var selected =
                document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                    ? document.getSelection().getRangeAt(0)     // Store selection if found
                    : false;                                    // Mark as false to know no selection existed before
            el.select();                                    // Select the <textarea> content
            document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
            document.body.removeChild(el);                  // Remove the <textarea> element
            if (selected) {                                 // If a selection existed before copying
                document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
                document.getSelection().addRange(selected);   // Restore the original selection
            }
            if(ctrl.options.showToast) {
                showToast();
            }
        };

    }

});

'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/access-components/st-tailor-made/st-tailor-made.html',
        bindings: {
            redirectTo: '<'
        },
        controller: ['$window', '$scope', '$state', '$stateParams', '$filter', 'UserService', 'TrackEventService', 'CountriesService', 'LanguageService', '$mdMedia', 'UtilitiesService',
            function ($window, $scope, $state, $stateParams, $filter, UserService, TrackEventService, CountriesService, LanguageService, $mdMedia, UtilitiesService) {
                var vm = $scope;
                var currentLang = LanguageService.getCodeAndCountrySaveOnStorage();
                var defaultDialCode = CountriesService.getDialCodeByCountryCode(currentLang.split('-')[1]);
                vm.$mdMedia = $mdMedia;

                UserService.accountInfo()
                    .then(function (res) {
                        vm.data = {
                            signupInfo: {
                                businessType: res.data.businessType || null,
                                jobTitle: res.data.jobTitle || null,
                                acceptedReceiveOnboardingEmailAt: $filter('date')(res.data.acceptedReceiveOnboardingEmailAt, 'yyyy-MM-dd HH:mm:ss') || null,
                                domain: res.data.domain || null,
                                billingPhone: res.data.billingPhone || null,
                                billingPhonePrefix: res.data.billingPhonePrefix || defaultDialCode,
                                teamMembers: res.data.teamMembers || null,
                                lastLogin: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss')
                            },
                        };

                        if (vm.data.signupInfo.acceptedReceiveOnboardingEmailAt) {
                            vm.newsletterCheckbox = true;
                        }
                        return res.data;
                    })
                    .catch(function () {
                        return {};
                    });

                var ctrl = this;

                vm.signupInfo = signupInfo;
                vm.loading = false;

                vm.businessTypesOptions = UtilitiesService.getBusinessTypesOptions();
                vm.jobsTitleOptions = UtilitiesService.getJobsTitleOptions();
                vm.teamMembersOptions = UtilitiesService.getTeamMembersOptions();

                vm.updateNewsletterCheckbox = function (value) {
                    if (value) {
                        vm.data.signupInfo.acceptedReceiveOnboardingEmailAt = $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss');
                    } else {
                        vm.data.signupInfo.acceptedReceiveOnboardingEmailAt = null;
                    }
                };

                vm.$watch('data.signupInfo.billingPhonePrefix', function (newVal) {
                    if (newVal && newVal.dial_code) {
                        vm.data.signupInfo.billingPhonePrefix = newVal.dial_code;
                    }
                });

                function signupInfo(form) {
                    vm.loading = true;
                    var suggestedPlans = [];

                    /**
                     * Aggiungendo i Log bisogna distinguere le chiamate da dove partono
                     * e siccome sono tutte con lo stesso EndPoint diventa impossibile checkare tutto
                     * quindi creo typeCall per identificare da dove parte.
                     *
                     * @type {string}
                     */
                    // vm.data.signupInfo.typeCall = 'update-by-tailor-mode-data';
                    UserService.updateProfile(vm.data.signupInfo)
                        .then(function (res) {
                            if (res && res.data && res.data.suggestedPlans) {
                                suggestedPlans = res.data.suggestedPlans;
                            }

                            return UserService.limits(true);
                        })
                        .then(function (limitsResult) {
                            TrackEventService.event('Funnel Events', 'Profiling Completed');
                            TrackEventService.gaV2Event('profile_complete');

                            if (vm.newsletterCheckbox) {
                                $window.localStorage.setItem('onboardingResponse', 'yes');
                            } else {
                                $window.localStorage.setItem('onboardingResponse', 'no');
                            }

                            var isFirstAccess = true;
                            if (limitsResult && limitsResult.data && limitsResult.data.account && limitsResult.data.account === 'sub') {
                                isFirstAccess = false;
                            }

                            if ($stateParams.workspaceId) {
                                $state.go('app.settings.invitations');
                            } else if (isFirstAccess) {
                                $state.go('access.get-started-now', {
                                    suggestedPlans: suggestedPlans,
                                    redirectTo: ctrl.redirectTo,
                                    userCanDoTrial: limitsResult.data.canDoTrial,
                                });
                            } else if (ctrl.redirectTo) {
                                $state.go(ctrl.redirectTo);
                            } else {
                                $state.go('app.project.wizard');
                            }
                        })
                        .catch(function (err) {
                            console.log('err', err);
                            vm.loading = false;
                        })
                }
            }]
    };
});

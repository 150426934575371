'use strict';
define([], function () {
    function controller($scope, $sce) {
        var ctrl = this;

        $scope.keywordsLimit = 15;
        $scope.keywordsAlreadyAddedCounter = 0;
        $scope.keywordsCount = 0;
        $scope.locationsCount = 1;

        ctrl.$onInit = function () {
            $scope.keywordsLimit = ctrl.keywordsLimit;
            $scope.keywordsAlreadyAddedCounter = ctrl.keywordsAlreadyAddedCounter;
            $scope.keywordsCount = ctrl.keywordsCount;
            $scope.locationsCount = ctrl.locationsCount;
        }

        $scope.$watch('$ctrl.keywordsCount', function (keywordsCount) {
            $scope.keywordsCount = keywordsCount || 0;
        });

        $scope.$watch('$ctrl.locationsCount', function (locationsCount) {
            $scope.locationsCount = locationsCount || 1;
        });

        $scope.$watch('$ctrl.keywordsAlreadyAddedCounter', function (keywordsAlreadyAddedCounter) {
            $scope.keywordsAlreadyAddedCounter = keywordsAlreadyAddedCounter || 0;
        });

        $scope.getCount = function () {
            return ($scope.keywordsCount * $scope.locationsCount) + $scope.keywordsAlreadyAddedCounter;
        }

        $scope.isLimitexceeded = function () {
            return $scope.getCount() > $scope.keywordsLimit;
        }
    }

    controller.$inject = ['$scope', '$sce'];

    return {
        templateUrl: 'app/modules/project/components/chart-keywords/chart-keywords.html',
        bindings: {
            keywordsLimit: '<',
            keywordsAlreadyAddedCounter: '<',
            keywordsCount: '<',
            locationsCount: '<',
        },
        controller: controller,
    }
});

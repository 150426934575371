'use strict';
define([
    'angular',
    'modules/leadgeneration/leadgeneration.routes',
    'modules/leadgeneration/components/components.module',
    'modules/leadgeneration/landing/landing.module',
    'modules/leadgeneration/form/form.module',
    'modules/leadgeneration/pricing/pricing.module'
],function(angular,routes){
    var app=angular.module('app.leadgeneration', [
        'app.leadgeneration.ui',
        'app.leadgeneration.landing',
        'app.leadgeneration.form',
        'app.leadgeneration.pricing'
    ]);
    app.config(routes);
    return app;
});

/**
 * @ngdoc overview
 * @name media.service:MediaService
 */
'use strict';
define(['app'], function (app) {
    app.service('MediaService', MediaService);
    MediaService.$inject = ['API', '$http', '__env'];

    function MediaService(API, $http, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.mediaAPI;

        return {
            findOne: findOne,
            findAll: findAll,
            upload: upload,
            remove: remove,
            inEmbed: inEmbed
        };

        function findOne(params) {
            return $http.get(apiPath + '/' + params.id);
        }

        function findAll(params) {
            if (params && params.type) {
                return API.get(apiPath + '?type=' + params.type);
            }

            return API.get(apiPath);
        }

        function upload(data) {
            return API.post(apiPath, data);
        }

        function remove(params) {
            return API.delete(apiPath + '/' + params.id);
        }

        function inEmbed(params) {
            return $http.get(apiPath + '/in-embed/' + params.id);
        }
    }
});

'use strict';
define([], function () {
    function Controller($window, $scope, $mdDialog, userLimit, title, additionalSubtitle, WorkspaceService, $mdToast, $state, $translate) {
        $scope.cancelDialog = cancelDialog;
        $scope.goToCheckout = goToCheckout;
        $scope.filterPlanByIntervalAndUserQty = filterPlanByIntervalAndUserQty;

        $scope.plans = [];
        $scope.interval = 'year';
        $scope.userQty = 1;
        $scope.userLimit = userLimit;
        $scope.title = title;
        $scope.additionalSubtitle = additionalSubtitle;

        WorkspaceService.getWorkspaceAddonPlans()
            .then(function (res) {
                if (!res || !res.addonPlans || !res.addonPlans.data) {
                    return;
                }

                $scope.plans = res.addonPlans.data;
                $scope.filterPlanByIntervalAndUserQty($scope.interval, $scope.userQty);
            })
            .catch(function (err) {
                showCustomToast('settings.something.went.wrong', 'icon-x st-err', '');
                $mdDialog.hide();
                $window.console.log(err);
            });


        function filterPlanByIntervalAndUserQty(interval, userQty) {
            $scope.currentPlan = $scope.plans.filter(function (plan) {
                return plan.interval == interval && plan.alias.indexOf('additional-user-' + userQty + '-') > -1;
            })[0];

            if ($scope.currentPlan) {
                $scope.currentAmount = $scope.currentPlan.amount / 100;
            }

        }

        function goToCheckout() {
            if ($scope.currentPlan) {
                $state.go('upgrade.checkout', {alias: $scope.currentPlan.alias});
            } else {
                showCustomToast('settings.something.went.wrong', 'icon-x st-err', '');
            }

            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }

        function showCustomToast(string, classIcon, templateCallToAction) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '&nbsp;</div>' +
                    '' + templateCallToAction + '' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            })
                .catch(function (err) {
                    $window.console.log('err', err);
                });
        }


    }

    Controller.$inject = ['$window', '$scope', '$mdDialog', 'userLimit', 'title', 'additionalSubtitle', 'WorkspaceService', '$mdToast', '$state', '$translate'];
    return Controller;
});

define(['app'], function (app) {
    app.filter('counterValue', function () {
        return function (value) {
            value = parseInt(value);
            if (!isNaN(value) && value >= 0 && value < 10) {
                return "0" + value;
            }

            return value;
        };
    });
});

/**
 * @ngdoc overview
 * @name wordpress.service:WordpressService
 */
'use strict';
define(['app'], function (app) {
    var entities = {
        'amp': '&',
        '#038': '&',
        'apos': '\'',
        '#x27': '\'',
        '#x2F': '/',
        '#39': '\'',
        '#47': '/',
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'quot': '"',
        '#8217': '\'',
        '#8211': '-'
    };

    app.service('WordpressService', WordpressService);

    WordpressService.$inject = ['$http', '$q', 'LanguageService', '__env'];

    function WordpressService($http, $q, LanguageService, __env) {
        return {
            blogPosts: blogPosts,
            getLatestPosts: getLatestPosts,
            blogCategory: blogCategory
        };

        function blogPosts(lang) {
            if(!lang){
                lang = LanguageService.getLanguageCodeSaveOnStorage();
                /* At the moment we have only english and italian articles on the blog,
                so we show english articles for the other languages */

                if (lang !== 'it') {
                    lang = 'en';
                }

            }
            if (!fetch) {
                return $q.resolve([]);
            }

            var deferred = $q.defer();
            fetch('https://www.seotesteronline.com/wp-json/wp/v2/posts?_embed&&per_page=10&status=publish&lang=' + lang)
                .then(async function (response) {
                    return await response.json();
                })
                .then(function (res) {
                    if (!res) {
                        deferred.resolve([]);
                    }

                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve([]);
                });

            return deferred.promise;
        }

        function blogCategory(id) {
            if (!fetch) {
                return $q.resolve([]);
            }

            var deferred = $q.defer();
            fetch('https://www.seotesteronline.com/wp-json/wp/v2/categories/' + id)
                .then(function (response) {
                    return response.json();
                })
                .then(function (res) {
                    if (!res) {
                        deferred.resolve([]);
                    }

                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve([]);
                });

            return deferred.promise;
        }

        var posts;

        function getLatestPosts() {
            if (__env.stage !== 'production' && __env.stage !== 'development') {
                return $q.resolve([]);
            }

            var languageCode = LanguageService.getLanguageCodeSaveOnStorage();

            /* At the moment we have only english and italian articles on the blog,
            so we show english articles for the other languages */

            if (languageCode != 'it') {
                languageCode = 'en';
            }

            return blogPosts(languageCode)
                .then(function (res) {
                    posts = res;
                    return getCategoryPost(res);
                })
                .then(function (categories) {
                    var i = 0;
                    posts.forEach(function (post) {
                        post.categories[0] = categories[i].name;
                        post.categories[0] = categories[i].name;
                        post.date_gmt = '' + new Date(post.date_gmt).getUTCDate() + '/' + (new Date(post.date_gmt).getUTCMonth() + 1) + '/' + new Date(post.date_gmt).getFullYear() + '';
                        post.title.rendered = decodeHTMLEntities(post.title.rendered);
                        i++;
                    });

                    return posts;

                });
        }


        function getCategoryPost(posts) {
            // TODO Replace this promise with $q
            return new Promise(function (resolve) {
                var categories = [];

                posts.forEach(function (post) {
                    categories.push(blogCategory(post.categories[0]));
                });

                Promise.all(categories).then(function (values) {
                    resolve(values);
                }).catch(function (err) {
                    console.log(err);
                });
            });
        }

        function decodeHTMLEntities(text) {
            return text.replace(/&([^;]+);/gm, function (match, entity) {
                return entities[entity] || match;
            });
        }
    }
});

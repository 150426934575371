'use strict';
define(['angular'], function (angular) {
    return {
        templateUrl: 'app/components/st-media-upload/st-media-upload.html',
        bindings: {
            selected: '=',
            disable: '<',
            type: '<',
            color: '<',
            onChange: '<',
        },
        controller: ['$scope', '$mdDialog',
            function ($scope, $mdDialog) {
                var ctrl = this;

                $scope.$watchCollection('$ctrl.selected', function (selected) {
                    ctrl.selected = selected;

                    if (selected && typeof ctrl.onChange === 'function') {
                        ctrl.onChange(selected);
                    }
                });

                $scope.$watch('$ctrl.disable', function (disable) {
                    ctrl.dis = disable;
                });

                $scope.$watch('$ctrl.type', function (type) {
                    ctrl.type = type;
                });

                $scope.$watch('$ctrl.color', function (color) {
                    ctrl.color = color;
                });

                ctrl.mutex = false;

                $scope.showDialog = function (selected, type, color, ev) {
                    ctrl.mutex = true;
                    $mdDialog.show({
                        controller: dialogController,
                        templateUrl: 'app/components/st-media-upload/st-media-upload-dialog.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        preserveScope: true,
                        scope: this,
                        bindToController: true,
                        multiple: true,
                        clickOutsideToClose: false,
                        locals: {
                            selected: selected,
                            type: type,
                            color: color
                        },
                        resolve: {
                            mediaList: ['MediaService', function (MediaService) {
                                return MediaService.findAll({
                                    type: type,
                                })
                                    .then(function (result) {
                                        return result.data;
                                    })
                                    .catch(function (err) {
                                        return [];
                                    });

                            }]
                        }
                    }).then(function (media) {
                        ctrl.selected = media;
                        ctrl.mutex = false;
                    }, function (media) {
                        ctrl.mutex = false;
                        if (media == null) {
                            ctrl.selected = media;
                        }
                    });
                };

                function dialogController($scope, $rootScope, $mdDialog, selected, mediaList, color, type, MediaService) {
                    $scope.cancel = function () {
                        $mdDialog.cancel($scope.selected);
                    };

                    $scope.answer = function () {
                        $mdDialog.hide($scope.selected);
                    };

                    var mediaUploaded;
                    $scope.mediaList = mediaList.data;
                    $scope.loading = false;
                    $scope.inEmbed = false;
                    $scope.color = color;
                    $scope.type = type;
                    $scope.selectedTab = 0;
                    $scope.index = 0;
                    $scope.selected = selected;
                    $scope.setButtonDeleteMedia = function (media) {
                        $scope.buttonDeleteMedia = media;
                    };
                    $scope.setSelectedMedia = function (media) {
                        $scope.selected = media;
                    };
                    $scope.deleteMediaDialog = function (media, ev) {
                        $mdDialog.show({
                            controller: deleteMediaDialogController,
                            targetEvent: ev,
                            bindToController: true,
                            preserveScope: true,
                            scope: $scope,
                            clickOutsideToClose: false,
                            hasBackdrop: false,
                            multiple: true,
                            templateUrl: 'app/components/st-media-upload/st-media-upload-delete-dialog.html',
                            locals: {
                                media: media,
                            },
                            resolve: {
                                inEmbed: ['MediaService', function (MediaService) {
                                    return MediaService.inEmbed({id: media.id})
                                        .then(function (result) {
                                            return result.data.code === 'IN_LEAD_GEN_EMBED';
                                        })
                                        .catch(function (err) {
                                            console.log(err);
                                        });
                                }]
                            }
                        });
                    };

                    function deleteMediaDialogController($scope, $mdDialog, MediaService, media, inEmbed) {
                        $scope.inEmbed = inEmbed;
                        $scope.deleteMedia = function () {
                            $scope.loading = true;
                            MediaService.remove({id: media.id})
                                .then(function () {
                                    MediaService.findAll({type: $scope.type})
                                        .then(function (list) {
                                            $scope.mediaList = list.data.data;
                                            $scope.loading = false;
                                            if (media.id == $scope.selected.id) {
                                                $scope.selected = null;
                                            }
                                            $mdDialog.cancel();
                                        })
                                        .catch(function (err) {
                                            return [];
                                        });
                                })
                                .catch(function (err) {
                                    console.log(err);
                                });
                        };

                    }

                    deleteMediaDialogController.$inject = ['$scope', '$mdDialog', 'MediaService', 'media', 'inEmbed'];

                    $scope.upload = function (files, file, newFiles, duplicateFiles, invalidFiles, event) {
                        if (invalidFiles.length) {
                            $scope.invalidImage = invalidFiles[0].$error;
                            $scope.file = false;
                            mediaUploaded = false;
                        } else if (event.type == 'change' || event.type == 'drop') {
                            $scope.loading = true;
                            $scope.invalidImage = false;
                            $scope.file = file;
                            var reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = function () {
                                mediaUploaded = reader.result;

                                var partOfFile = mediaUploaded.split(';');
                                var content = partOfFile[1].replace('base64,', '');
                                var contentType = partOfFile[0].replace('data:', '');

                                mediaUploaded = mediaUploaded.substring(mediaUploaded.indexOf(',') + 1);
                                MediaService.upload({
                                    type: $scope.type,
                                    content: content,
                                    contentType: contentType,
                                })
                                    .then(function (result) {
                                        MediaService.findAll({type: $scope.type})
                                            .then(function (list) {
                                                $scope.selected = result.data.data;
                                                $scope.color = color;
                                                $scope.mediaList = list.data.data;
                                                $scope.selectedTab = 0;
                                                $scope.loading = false;
                                                $scope.file = false;
                                                mediaUploaded = false;
                                            })
                                            .catch(function (err) {
                                            });
                                    })
                                    .catch(function (err) {
                                        if (err.data.code === 'MAX_NUMBER_OF_IMAGES') {
                                            $scope.invalidImage = 'maxNumberLimit';
                                            $scope.loading = false;
                                            $scope.file = false;
                                        }
                                    });
                            };
                        }
                    };

                }

                dialogController.$inject = ['$scope', '$rootScope', '$mdDialog', 'selected', 'mediaList', 'color', 'type', 'MediaService'];
            }]
    };
});

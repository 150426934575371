'use strict';
define([
    'angular',
    'modules/leadgeneration/form/form.controller',
    'modules/leadgeneration/form/settings/settings.module',
    'modules/leadgeneration/form/overview/overview.module',
    'modules/leadgeneration/form/form-generator/form-generator.module',
],function(angular, LeadGenerationFormCtrl){
    var app=angular.module('app.leadgeneration.form', [
        'app.leadgeneration.form.settings',
        'app.leadgeneration.form.overview',
        'app.leadgeneration.form.form-generator',
    ]);
    app.controller('LeadGenerationFormCtrl',LeadGenerationFormCtrl);
    return app;
})

'use strict';
define([], function () {
    function Controller($scope, KeywordListService, AuthService) {
        $scope.isLogged = AuthService.isAuthenticated();
    }

    Controller.$inject = [
        '$scope',
        'KeywordListService',
        'AuthService',
        '$sce',
        '$templateRequest',
        '$state',
        '$mdToast',
        '$translate'
    ];
    return Controller;
});

'use strict';
define([], function () {

    var limitOkRange = 70;
    var limitWarningRange = 50;
    

    return {
        templateUrl: 'app/modules/seochecker/components/category-analysis/category-analysis.html',
        bindings: {
            loading: '<',
            score: '<',
            base: '<',
            content: '<',
            performance: '<',
            social: '<',
            badExams: '='
        },
        controller: ['$scope', '$translate', function ($scope, $translate) {
            var vm = $scope
            vm.chartLabels = [];
            vm.chartData = [[]];
            vm.chartColors;
            vm.totalExamOk = 0;
            vm.totalExamWarning = 0;
            vm.totalExamError = 0;
           
            this.$onChanges = function (data) { 
                vm.loading = this.loading;
                vm.chartColors = [getColorGraphByScore(data.score.currentValue)];
                vm.chartData[0][0] = data.base.currentValue ? data.base.currentValue.score || 0 : 0;
                vm.chartData[0][1] = data.content.currentValue ? data.content.currentValue.score || 0 : 0;
                vm.chartData[0][2] = data.performance.currentValue ? data.performance.currentValue.desktop.score || 0 : 0;
                vm.chartData[0][3] = data.performance.currentValue ? data.performance.currentValue.mobile.score || 0 : 0;
                vm.chartData[0][4] = data.social.currentValue ? data.social.currentValue.score || 0 : 0;

                if (data.base.currentValue && data.base.currentValue.exams) {
                    for (var key  in data.base.currentValue.exams) {
                        if (data.base.currentValue.exams[key].score >= limitOkRange) {
                            vm.totalExamOk += 1;
                        } else if (data.base.currentValue.exams[key].score >= limitWarningRange) {
                            vm.totalExamWarning += 1;
                        } else {
                            vm.totalExamError += 1;
                        }
                    }
                }

                if (data.content.currentValue && data.content.currentValue.exams) {
                    for (var key in data.content.currentValue.exams) {
                        if (data.content.currentValue.exams[key].score >= limitOkRange) {
                            vm.totalExamOk += 1;
                        } else if (data.content.currentValue.exams[key].score >= limitWarningRange) {
                            vm.totalExamWarning += 1;
                        } else {
                            vm.totalExamError += 1;
                        }
                    }
                }

                if (data.social.currentValue && data.social.currentValue.exams) {
                    for (var key in data.social.currentValue.exams) {
                        if (data.social.currentValue.exams[key].score >= limitOkRange) {
                            vm.totalExamOk += 1;
                        } else if (data.social.currentValue.exams[key].score >= limitWarningRange) {
                            vm.totalExamWarning += 1;
                        } else {
                            vm.totalExamError += 1;
                        }
                    }
                }

                if (data.performance.currentValue && data.performance.currentValue.desktop && data.performance.currentValue.desktop.exams) {
                    for (var key in data.performance.currentValue.desktop.exams) {
                        if (data.performance.currentValue.desktop.exams[key].score >= limitOkRange) {
                            vm.totalExamOk += 1;
                        } else if (data.performance.currentValue.desktop.exams[key].score >= limitWarningRange) {
                            vm.totalExamWarning += 1;
                        } else {
                            vm.totalExamError += 1;
                        }
                    }
                }

                if (data.performance.currentValue && data.performance.currentValue.mobile && data.performance.currentValue.mobile.exams) {
                    for (var key in data.performance.currentValue.mobile.exams) {
                        if (data.performance.currentValue.mobile.exams[key].score >= limitOkRange) {
                            vm.totalExamOk += 1;
                        } else if (data.performance.currentValue.mobile.exams[key].score >= limitWarningRange) {
                            vm.totalExamWarning += 1;
                        } else {
                            vm.totalExamError += 1;
                        }
                    }
                }

                this.badExams = vm.totalExamError;
            };
         
            vm.chartOptions = {

                maintainAspectRatio: true,
                scale: {
                    ticks: {
                        display: false,
                        min: 0,// minimum value
                        max: 100,// maximum value
                        fixedStepSize: 20
                    },
                   
                    pointLabels: {
                        fontSize: 8,
                        fontColor: '#596679' 
                    },
                },
                elements: {
                    line: {
                        borderWidth: 2,
                        stepped: true
                    }, 
                    point: {
                        radius: 0
                    }
                },
                defaultFontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                legend: {
                    display: false
                }
            };
    
            var results = ['Structure', 'Content', 'SpeedDesktop', 'SpeedMobile', 'Social'];
    
            for (var i = 0; i < results.length; i++) {
                $translate("seochecker.overview.parts.chartLabel" + results[i])
                    .then(function (translated) {
                        vm.chartLabels.push(translated);
                    })
                    .catch(function (idLangNotFound) {
                    });
            }
            
            function getColorGraphByScore(score) {
                score = parseFloat(score);
                var greenColorHex = "#28b670";
                var orangeColorHex = "#fb8c00";
                var redColorHex = "#f44336";
    
                return score >= 75 ? greenColorHex :
                    score >= 50 ? orangeColorHex : redColorHex;
            }
    
        }]
    };
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.page", {
                controller: 'PageCtrl',
                abstract: true,
                templateUrl: './app/modules/seospider/exam/analysis/page/page.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.page.all", {
                url: "/page",
                templateUrl: './app/modules/seospider/exam/analysis/page/all/all.html',
                access: {restricted: true},
                controller: 'PageAllCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'canonical', 'title'],
                            status: 200
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.page.duplicated", {
                url: "/page/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/page/duplicated/duplicated.html',
                access: {restricted: true},
                controller: 'PageDuplicatedCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, Pages) {
                        var dfd = $q.defer();
                        Pages.findAllPageDuplicated({
                            jobId: $stateParams.sessionId
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

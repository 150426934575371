'use strict';
define(['angular'], function (angular) {

    var controller = function ($scope, $sce, $compile) {
        var ctrl = this;
        ctrl.takeValue = false;
        ctrl.takeFormat = false;

        var replaceArguments = function (row, args) {
            var formattedHtml = row;

            angular.forEach(args, function (arg) {
                if (arg && arg.key && arg.value && formattedHtml) {
                    // Exception for links
                    // TODO format a link here
                    if (arg.key === 'LINK') {
                        formattedHtml = formattedHtml.replace('{{BEGIN_LINK}}', '');
                        formattedHtml = formattedHtml.replace('{{END_LINK}}', '');
                    }

                    formattedHtml = formattedHtml.replace('{{' + arg.key + '}}', arg.value);
                }
            });

            return formattedHtml;
        };

        $scope.$watch('$ctrl.data', function () {
            var row = ctrl.data.format;
            var args = ctrl.data.args;
            var formattedHtml = replaceArguments(row, args);

            ctrl.formattedHtml = $sce.trustAsHtml(formattedHtml);
            ctrl.takeFormat = true;
            $compile()(ctrl);
        });


    };
    controller.$inject = ['$scope', '$sce', '$compile'];
    return {
        templateUrl: 'app/components/api-textual-rendering-pagespeed/api-textual-rendering-pagespeed.html',
        bindings: {data: '<'},
        controller: controller
    };
});

'use strict';
define([], function () {
    function Controller(
        $state
    ) {
        $state.go('app.keywordexplorer.search')
    }

    Controller.$inject = [
        '$state'
    ];
    return Controller;
});

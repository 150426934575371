/**
 * @ngdoc overview
 * @name user.service:UserService
 */
'use strict';
define(['app', 'angular'], function (app, ng) {
    app.service('UserService', UserService);

    UserService.$inject = ['API', '__env', '$q', 'sessionHistoryCache', 'AuthService'];

    /**
     *
     * @param API
     * @param __env
     * @param $q
     * @param sessionHistoryCache
     * @param AuthService
     * @returns {{accountInfo: (function(): *),
     *  getPaymentMethods: (function(): *),
     *  getSuggestedPlans: getSuggestedPlans,
     *  getUserTypePlans: getUserTypePlans,
     *  getCards: (function(): *),
     *  addCard: (function(*=): *),
     *  updateProfile: (function(*): *),
     *  deleteAccount: (function(): *),
     *  limits: limits,
     *  changePassword: (function(*=): *)}}
     * @constructor
     */
    function UserService(API, __env, $q, sessionHistoryCache, AuthService) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.userAccountAPI;

        return {
            accountInfo: accountInfo,
            changePassword: changePassword,
            updateProfile: updateProfile,
            deleteAccount: deleteAccount,
            getPaymentMethods: getPaymentMethods,
            getSuggestedPlans: getSuggestedPlans,
            getUserTypePlans: getUserTypePlans,
            getCards: getCards,
            addCard: addCard,
            limits: limits,
        };

        /**
         *
         * @returns {*}
         */
        function accountInfo() {
            return API.get(apiPath);
        }

        /**
         *
         * @param data
         * @returns {*}
         */
        function changePassword(data) {
            return API.put(apiPath + '/changePassword', data);
        }

        /**
         *
         * @param data
         * @returns {*}
         */
        function updateProfile(data) {
            sessionHistoryCache.removeAll();
            const dataCopy = ng.copy(data);

            if (dataCopy.email != undefined) {
                delete dataCopy.email;
            }

            return API.put(apiPath, {params: dataCopy});
        }

        /**
         *
         * @returns {*}
         */
        function deleteAccount() {
            sessionHistoryCache.removeAll();
            return API.delete(apiPath);
        }

        /**
         *
         * @returns {*}
         */
        function getPaymentMethods() {
            return API.get(apiPath + '/payment-methods', {}, true);
        }

        /**
         *
         * @returns {*}
         */
        function getCards() {
            return API.get(apiPath + '/payment-methods/cards');
        }

        /**
         *
         * @param data
         * @returns {*}
         */
        function addCard(data) {
            return API.post(apiPath + '/payment-methods/cards', data, true);
        }

        /**
         *
         * @param useCache
         * @returns {*}
         */
        function getSuggestedPlans(useCache) {
            var deferred = $q.defer();
            var cacheId = 'get-suggested-plans';

            var existCached = sessionHistoryCache.get(cacheId);
            /*
            * if cache exist,
            * use the cached results
            * in browser history
            */
            if (existCached && useCache) {
                return $q.resolve(existCached);
            }

            API.get(apiPath + '/suggested-plans')
                .then(function(res){
                    sessionHistoryCache.put(cacheId, res);
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        /**
         *
         * @param useCache
         * @returns {*}
         */
        function getUserTypePlans(useCache) {
            var deferred = $q.defer();
            var cacheId = 'get-user-type-plans';

            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached && useCache) {
                return $q.resolve(existCached);
            }

            API.get(apiPath + '/user-type-plans')
                .then(function(res){
                    sessionHistoryCache.put(cacheId, res);
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        /* Limit Management */
        function limits(useCache) {
            var deferred = $q.defer();
            var cacheId = 'get-user-limits';

            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached && useCache) {
                return $q.resolve(existCached);
            }

            API.get(apiPath + '/limits')
                .then(function (res) {
                    localStorage.setItem('userInfo', JSON.stringify(res.data));
                    sessionHistoryCache.put(cacheId, res);
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }
    }
});

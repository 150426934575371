define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.description", {
                controller: 'MetaDescCtrl',
                abstract: true,
                templateUrl: './app/modules/seospider/exam/analysis/description/description.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.description.all", {
                url: "/meta-description",
                templateUrl: './app/modules/seospider/exam/analysis/description/all/all.html',
                access: {restricted: true},
                controller: 'MetaDescAllCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllMetaDescription({
                            jobId: $stateParams.sessionId
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.description.missing", {
                url: "/meta-description/missing",
                templateUrl: './app/modules/seospider/exam/analysis/description/all/all.html',
                access: {restricted: true},
                controller: 'MetaDescMissingCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllMetaDescription({
                            jobId: $stateParams.sessionId,
                            missing: true
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.description.over", {
                url: "/meta-description/over",
                templateUrl: './app/modules/seospider/exam/analysis/description/all/all.html',
                access: {restricted: true},
                controller: 'MetaDescOverCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllMetaDescription({
                            jobId: $stateParams.sessionId,
                            size: 'over'
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.description.below", {
                url: "/meta-description/below",
                templateUrl: './app/modules/seospider/exam/analysis/description/all/all.html',
                access: {restricted: true},
                controller: 'MetaDescBelowCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllMetaDescription({
                            jobId: $stateParams.sessionId,
                            size: 'below'
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.description.duplicated", {
                url: "/meta-description/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/description/duplicated/duplicated.html',
                access: {restricted: true},
                controller: 'MetaDescDuplicatedCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        return SeospiderPages.findAllMetaDescriptionDuplicated({
                            jobId: $stateParams.sessionId,
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define([], function () {
    function controller(
        $window,
        $scope,
        UserService,
        KeywordListService,
        $stateParams,
        TrackEventService,
        $rootScope,
        $mdToast,
        $translate,
    ) {
        var ctrl = this;
        var vm = $scope;
        vm.keywordLimit = 100;
        vm.keywordToAdd = '';
        vm.placeholderTextarea = 'keyword 1 \n' + 'keyword 2 \n' + 'keyword 3';
        vm.replaceCommasWithNewLine = replaceCommasWithNewLine;
        vm.clearAllKeywords = clearAllKeywords;
        vm.splitKeywordsToAdd = splitKeywordsToAdd;
        vm.addKeywordsToTrack = addKeywordsToTrack;
        vm.getAllKeywordList = getAllKeywordList;
        vm.addKeywordListOnInput = addKeywordListOnInput;
        vm.paginationGap = 4;
        vm.paginationSize = 100;
        vm.tableTrackedKeywordsCtrl = {
            remove: function (item) {
                var index = ctrl.keywords.indexOf(item);
                if (index !== -1) {
                    ctrl.keywords.splice(index, 1);
                }

                if (ctrl.keywords.length <= 100) {
                    vm.paginationGap = null;
                    vm.paginationSize = null;
                }

            }
        };

        ctrl.$onInit = function () {
            vm.keywords = ctrl.keywords;
            vm.wizardMode = ctrl.wizardMode;

            // Limit keywords to import from Search Console
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'rank-tracker-keywords') {
                                vm.keywordLimit = service.qtyRemaining && Number(service.qtyRemaining) > 0 ? Number(service.qtyRemaining) : 10;
                            }
                        });

                        if (vm.keywordLimit > 100) {
                            vm.keywordLimit = Number(vm.keywordLimit / 2);
                        }
                    }
                })
                .catch(function (err) {
                    $window.console.log(err);
                    return null;
                });

            // TRACKING
            var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';
            var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
            if (vm.wizardMode) {
                TrackEventService.event(
                    'Suite Usages',
                    'SEO Project - Step - Add Keywords', eventLabel);
                TrackEventService.gaV2Event(
                    'project_wizard', {
                        step: 'Add Keywords',
                        from: $rootScope.stoFrom,
                        type: stoTool
                    });
            }
            //
            getAllKeywordList();
        };

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast max-w">' +
                    '   <div class="p-t p-b"><i class="' + classIcon + ' m-r-sm"></i>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: angular.element('.toast-show')
            });
        }

        vm.keywordListOptions = {
            template: 1,
            getAllKeywordList: vm.getAllKeywordList,
            addKeywordListOnInput: vm.addKeywordListOnInput,
            showCustomToast: showCustomToast,
        };

        vm.onImport = function (importedKeywords) {
            if (!importedKeywords.length) {
                showCustomToast('project.wizard.csv.file.is.empty', 'icon-x st-red');
                return;
            }

            angular.forEach(importedKeywords, function (keyword) {
                ctrl.keywords.push(keyword);
            });

            // Workaround to fix an issue on md-chip. DON'T REMOVE
            $window.setTimeout(function () {
                angular.element('textarea.md-caption').click();
            }, 200);
        };

        function replaceCommasWithNewLine() {
            vm.keywordToAdd = replaceCommaLine(vm.keywordToAdd);
        }

        function addKeywordsToTrack() {
            var kws = splitKeywordsToAdd();
            vm.keywordToAdd = '';

            for (var i = 0; i < kws.length; i++) {
                addKeyword(kws[i]);
            }
        }

        function splitKeywordsToAdd() {
            var keywordToAdd = [];
            var tmpKeyword;
            var rawKeywordList = vm.keywordToAdd.trim().split('\n');
            for (var i = 0; i < rawKeywordList.length; i++) {
                tmpKeyword = formatKeyword(rawKeywordList[i]);
                if (tmpKeyword && tmpKeyword.length && tmpKeyword.length > 1) {
                    keywordToAdd.push(tmpKeyword);
                }
            }
            return keywordToAdd;
        }

        function clearAllKeywords() {
            while (ctrl.keywords.length > 0) {
                ctrl.keywords.pop();
            }
        }

        function replaceCommaLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split(',').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("\n");
        }

        function getAllKeywordList() {
            return KeywordListService.getAllKeywordList()
                .then(function (res) {
                    if (res && res.data && res.data.rows) {
                        vm.keywordLists = res.data.rows;
                    }

                    return res;
                });
        }

        function addKeywordListOnInput(keywordList) {
            KeywordListService.getOneKeywordList(keywordList.keywordListId)
                .then(function (res) {
                    angular.forEach(res.data.queries.rows, function (row) {
                        addKeyword(row.keyword);
                    });
                })
                .catch(function (err) {
                    $window.console.log(err);
                });
        }

        var formatKeyword = function (keyword) {
            keyword = keyword.replace(/[!"#\$%\(\)\*\+,\/:;<=>\?@\[\]\^_`{\|}~£∞·»”’¢‰›⁄¿≈⁄›¢ØŒÆ‚¯˘˚¸˝˛ˇ≥‡°¶§]/gi, '');
            keyword = keyword.trim();
            keyword = keyword.trim().replace(/\s{1,/g, ' ').trim();
            var keywordWithoutDots = keyword.replace(/\./gi, '');
            return keyword && keyword !== '' && keywordWithoutDots !== '' ? keyword : null;
        };

        var addKeyword = function (keyword) {
            keyword = formatKeyword(keyword);
            if (keyword && keyword !== '' && keyword.length > 1 && ctrl.keywords.indexOf(keyword) < 0) {
                ctrl.keywords.push(keyword);
            }

            if (ctrl.keywords.length > 100) {
                vm.paginationGap = 4;
                vm.paginationSize = 100;
            }
        };
    }

    controller.$inject = [
        '$window',
        '$scope',
        'UserService',
        'KeywordListService',
        '$stateParams',
        'TrackEventService',
        '$rootScope',
        '$mdToast',
        '$translate',
    ];

    return {
        templateUrl: 'app/modules/project/wizard/steps/add-keywords/add-keywords.html',
        bindings: {
            keywords: '=',
            wizardMode: '<',
            loadingSuggestedKeywords: '<'
        },
        controller: controller,
    };
});

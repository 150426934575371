/**
 * @ngdoc overview
 * @name keywords.service:KeywordsService
 */
'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('KeywordsService', KeywordsService);
    KeywordsService.$inject = ['$http', '$q', 'Cache', '__env'];

    function KeywordsService($http, $q, Cache, __env) {
        return {
            lookup: lookup,
        };

        function encodeKeywords(keywordList) {
            for (var i = 0; i < keywordList.length; i++)
                keywordList[i] = encodeURIComponent(keywordList[i]);
            return keywordList;
        }

        function lookup(keywordList, countryCode) {
            return $q(function (resolve, reject) {
                if (!keywordList || keywordList.length <= 0) {
                    reject(new Error('invalid input params'));
                    return;
                }

                keywordList = encodeKeywords(angular.copy(keywordList));
                keywordList = keywordList.join('|');
                var request = '?q=' + keywordList;

                if (!countryCode) {
                    countryCode = 'US';
                }
                request += '&country=' + countryCode;

                $http.get(__env.apiGateway + '/api/v1/keywords/lookup' + request, {cache: Cache})
                    .then(function (res) {
                        if (res.data) {
                            if (res.data.data) {
                                resolve(res.data.data);
                            }

                            resolve(res.data);
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                        resolve({
                            rows: [],
                            totalRows: 0
                        });
                    });
            });
        }
    }

});

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/copy-competitors-strategies/copy-competitors-strategies.html',
    bindings: {
        loading: '=',
        marketAnalysis: "=",
        currentStep: "=",
        wizardHandler:"=",
        mainKeyword: "=",
        device: "=",
        country: "=",
        language: "=",
        size: "@",
    },
    controller: ['$scope', '$timeout', 'FavIconService', function ($scope, $timeout, FavIconService) {
        var vm = this;

        $scope.maxItemRowSelectableAllowed = 5;

        $scope.totalSelectedStrategies = function () {
            if (vm.marketAnalysis && vm.marketAnalysis.totalFollowedStrategies) {
                return vm.marketAnalysis.totalFollowedStrategies;
            }
            return 0;
        };

        function updateSelected() {
            if (!vm.marketAnalysis ||
                !vm.marketAnalysis ||
                !vm.marketAnalysis.rows ||
                vm.marketAnalysis.rows.length <= 0) {
                return 0;
            }

            let total = 0;
            try {
                for (let i = 0; i < vm.marketAnalysis.rows.length; i++) {
                    if (vm.marketAnalysis.rows[i].hasOwnProperty('useThisStrategy') &&
                        vm.marketAnalysis.rows[i].useThisStrategy === true) {
                        total++;
                    }
                }
            } catch (e) {
            }

            vm.marketAnalysis.totalFollowedStrategies = total;
            return "" + total;
        }

        $scope.updateSelected = updateSelected;
        $scope.FavIconService = FavIconService;

        // Access `mainKeyword` in the controller
        vm.$onInit = async function () {
            updateSelected();
        };
    }]
};

'use strict';
define([
    'angular',
    'modules/seospider/exam/exam.controller',
    'modules/seospider/exam/analysis/analysis.module'
],function(angular,SEOSpiderExamCtrl){
    var app=angular.module('app.seospider.exam', [
        'app.seospider.exam.analysis'
    ]);
    app.controller('SEOSpiderExamCtrl',SEOSpiderExamCtrl);
    return app;
});
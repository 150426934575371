'use strict';
define([
    'angular',
    'modules/access/access.controller',
    'modules/access/access.routes',
    'modules/access/forgot/forgot.module',
    'modules/access/reset/reset.module',
    'modules/access/get-started-now/get-started-now.module',
    'modules/access/signin-zendesk/signin-zendesk.module',
    'modules/access/signin-feedbear/signin-feedbear.module',
], function(
        angular,
        AccessController,
        routes
) {
        var accessModule = angular.module('access', [
                'access.forgot',
                'access.reset',
                'access.get-started-now',
                'access.signin-zendesk',
                'access.signin-feedbear'
        ]);
        accessModule.controller('AccessController', AccessController);
        accessModule.config(routes);
        return accessModule;
});

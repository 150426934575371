'use strict';
define([], function () {
    function controller($scope, $mdMedia, ReviewsService, $timeout) {
        var vm = $scope;
        var $ctrl = this;
        vm.$mdMedia = $mdMedia;
        vm.allReviews = ReviewsService.getAll();
        vm.reviews = vm.allReviews;
        vm.categories = ['Agency', 'PMI', 'Corporate', 'Freelance', 'E-Commerce']

        vm.getFirstReviewForCategory = getFirstReviewForCategory;
        vm.currentReviewIndex = 0;
        var carousel = document.querySelector('.carousel');
        var slider = document.querySelector('.slider');
        var direction;

        function getFirstReviewForCategory(category, currentReviewIndex) {
            var firstReviewForCategory = 0;
            for (i = 0; i < vm.reviews.length; i++) {
                
                if (vm.reviews[i].category === category) {
                    firstReviewForCategory = i;
                    break;
                }
            }
            
            

            if (firstReviewForCategory == (currentReviewIndex +1)) {
                vm.right();
            } else if (firstReviewForCategory == (currentReviewIndex -1)) {
                vm.left();
            } else {
                vm.currentReviewIndex = firstReviewForCategory;
            }

            slider.style.transition = 'none';
            slider.style.transform = 'translate(0)';
            slider.style.opacity = '1';
            
            slider.style.transition = 'all 0.5s';
            
                
        }

        vm.left = function() {
            if (direction === -1) {
                direction = 1;
                slider.appendChild(slider.firstElementChild);
            }
            carousel.style.justifyContent = 'flex-end';    
            slider.style.transform = 'translate(20%)';  
            slider.style.opacity = '0.5';
            if (vm.currentReviewIndex <= 0) {
                vm.currentReviewIndex = vm.reviews.length-1;
            } else {
                vm.currentReviewIndex -= 1;
            }
        }

        vm.onSwipeLeft = function() {
            vm.right();
        }

        vm.onSwipeRight = function() {
            vm.left();
        }

        vm.right = function() {
            slider.style.opacity = '0.5';
            direction = -1;
            carousel.style.justifyContent = 'flex-start';
            slider.style.transform = 'translate(-20%)';  
           
            if (vm.currentReviewIndex >= vm.reviews.length-1) {
                vm.currentReviewIndex = 0;
            } else {
                vm.currentReviewIndex += 1;
            }
        }

        slider.addEventListener('transitionend', function() {
            // get the last element and append it to the front
            
            if (direction === 1) {
                slider.prepend(slider.lastElementChild);
            } else {
                slider.appendChild(slider.firstElementChild);
            }
            
            slider.style.transition = 'none';
            slider.style.transform = 'translate(0)';
            slider.style.opacity = '1';
            setTimeout(function() {
                slider.style.transition = 'all 0.5s';
            });
                
            }, false);

    }

    controller.$inject = ['$scope', '$mdMedia', 'ReviewsService', '$timeout'];
    return {
        templateUrl: 'app/modules/upgrade/components/what-people-says-carousel/what-people-says-carousel.html',
        controller: controller,
        bindings: {
           
        },
    };
});


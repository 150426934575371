'use strict';
define([], function () {
    var SERP_FEATURES = [
        'app',
        'featured_snippet',
        'images',
        'knowledge_graph',
        'local_pack',
        'people_also_ask',
        'shopping',
        'twitter',
        'video',
        'events',
        'paid'
    ];

    return {
        templateUrl: 'app/modules/keywordexplorer/components/serp-features/serp-features.html',
        bindings: {
            searchResults: '<',
            serpFeaturesList: '<',
            theme: '<',
            loading: '<',
            locked: '<',
            tool: '@'
        },
        controller: ['$scope', '$mdMedia', '$translate', function ($scope, $mdMedia, $translate) {
            var ctrl = this;
            ctrl.blockFeatures = true;
            $scope.serpFeatures = null;
            $scope.$mdMedia = $mdMedia;

            $scope.getSerpFeatureNameByID=function(myStringId){
                if(myStringId==='paid')
                    return 'ads top';
                return myStringId.replace(/_/g,' ');
            };

            $scope.getTextBySerpFeature = function(serpFeature) {
                return $translate.instant("keyword.exp.serp.feature." + serpFeature );
            }

            $scope.$watch('$ctrl.serpFeaturesList', function (serpFeaturesList) {
                if (serpFeaturesList) {
                    var serpFeaturesDef = [];

                    for (var i = 0; i < serpFeaturesList.length; i++) {
                        if (SERP_FEATURES.indexOf(serpFeaturesList[i]) >= 0) {
                            serpFeaturesDef.push(serpFeaturesList[i]);
                        }
                    }

                    ctrl.blockFeatures = serpFeaturesDef.length > 3;
                    $scope.serpFeatures = serpFeaturesDef;

                } else {
                    $scope.serpFeatures = null;
                    ctrl.blockFeatures = false;
                }
            });

            ctrl.viewAllFeatures = function () {
                ctrl.blockFeatures = false;
            };
        }]
    };
});

define(['angular','app'],function(angular,app) {
    app.filter('objectToArray', function() {
        return function(input) {
          var out = []; 
          for(i in input){
            out.push(input[i]);
          }
          return out;
        }
      })
});
'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/copymetrics/components/copymetrics-text-analysis/copymetrics-text-analysis.html',
        bindings: {
            url: '=',
            isLoading: '=',
            analysis: '=',
            sections: '=',
            messageWhenEmptyDataTitle: '<',
            messageWhenEmptyDataSubtitle: '<',
            messageWhenEmptyDataIcon: '<',
            messageWhenEmptyDataCta: '<'
        },
        controller: ['$scope', 'GoogleSuggestionsService', 'FavIconService', '$sce', '$compile', 'CopyMetricsService', 'TrackEventService', 'stLimitMessage', 'UserService', '$q',
            function ($scope, GoogleSuggestions, FavIcon, $sce, $compile, CopyMetrics, TrackEventService, stLimitMessage, UserService, $q) {
                var vm = $scope;
                var $this = this;

                var defaultSections = {
                    header: true,
                    snippetOverview: true,
                    relevantKeywords: true,
                    keywordsSuggestions: false,
                    textOverview: true,
                    headings: true,
                    images: true,
                    links: true,
                };
                vm.$sce = $sce;
                vm.$compile = $compile;
                vm.keywordsSuggestionsLoading = true;
                vm.getDomain = getDomain;
                vm.getPathname = getPathname;
                $this.$onInit = constructor;

                function constructor() {
                    vm.sections = Object.assign(defaultSections, $this.sections || {});
                    if ($this.isLoading) {
                        vm.isLoading = $this.isLoading;
                        vm.keywordsSuggestionsLoading = $this.isLoading;
                    }
                    if ($this.analysis) {
                        vm.analysis = $this.analysis;
                        newAnalysis();
                        return;
                    }
                    if ($this.url) {
                        fetchAnalysisFromAPI();
                    }
                }

                function newAnalysis(forceANewAnalysis = false) {
                    vm.suggestions = [];
                    if (vm.analysis && !vm.analysis.headings)
                        vm.analysis.headings = unifyHeadings(vm.analysis);
                    if (vm.analysis && forceANewAnalysis)
                        generateKeywordsSuggestions(vm.analysis.extraParams);
                }

                function unifyHeadings(SEOData) {
                    if (!SEOData.extraParams) {
                        return [];
                    }
                    var headings = [];
                    var i;
                    var h1s = SEOData.extraParams.h1 || [];
                    var h2s = SEOData.extraParams.h2 || [];
                    for (i = 0; i < h1s.length; i++) {
                        headings.push({value: h1s[i], type: 'h1'});
                    }
                    for (i = 0; i < h2s.length; i++) {
                        headings.push({value: h2s[i], type: 'h2'});
                    }
                    return headings;
                }

                function fetchAnalysisFromAPI() {
                    setLoading();
                    CopyMetrics.get({
                        url: $this.url,
                    })
                        .then(function (results) {
                            TrackEventService.event(
                                'Suite Usages',
                                'Copy Metrics - Audit', 'URL');
                            TrackEventService.gaV2Event(
                                'copy_audit', {
                                    type: "URL",
                                    tool: "Copy Metrics"
                                });
                            lastRequestDone = false;
                            vm.isLoading = false;
                            vm.analysis = results;
                        })
                        .catch(function (err) {
                            lastRequestDone = false;
                            if (err.status === 429 || err.status === 426) {
                                getUserInfo().then(function (userInfo) {
                                    vm.isLoading = false;
                                    $this.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                                    $this.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                                    $this.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                                    $this.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                                });
                            } else {
                                vm.isLoading = false;
                            }
                        });
                }

                var lastRequestDone = null;

                function getUserInfo() {
                    var dfd = $q.defer();
                    UserService.limits(true).then(function (res) {
                        return dfd.resolve(res.data);
                    }).catch(function () {
                        return dfd.resolve(null);
                    });
                    return dfd.promise;
                }

                function setLoading() {
                    vm.isLoading = true;
                    vm.suggestions = null;
                    vm.keywordsSuggestionsLoading = true;
                    lastRequestDone = $this.url;
                    vm.messageWhenEmptyDataTitle = null;
                    vm.messageWhenEmptyDataSubtitle = null;
                    vm.messageWhenEmptyDataIcon = null;
                    vm.messageWhenEmptyDataCta = null;
                    vm.suggestions = [];
                }

                vm.getFavIconURL = FavIcon.get;

                vm.cleanUrl = cleanUrl;
                vm.analyzeUrlBySeochecker = analyzeUrlBySeochecker;

                function analyzeUrlBySeochecker(url) {
                    return encodeURIComponent(btoa(url));
                }

                function cloneObject(obj) {
                    return JSON.parse(JSON.stringify(obj));
                }

                function getPathname(url) {
                    try {
                        return new URL(url).pathname;
                    } catch (err) {
                        return cleanUrl(url);
                    }

                }

                function getDomain(url) {
                    if (!url) {
                        return '';
                    }
                    var result;
                    var match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1];
                        match = result.match(/^[^\.]+\.(.+\..+)$/);
                        if (match) {
                            result = match[1];
                        }
                    }
                    return result;
                }

                function cleanUrl(url) {
                    if (!url) {
                        return '';
                    }
                    url = url.replace('https://', '');
                    url = url.replace('http://', '');
                    url = url.replace('www.', '');
                    url = url.replace(/^www\./i, '');
                    url = url.replace(/\/$/i, '');
                    return url.trim();
                }

                /** BETA **/
                function generateKeywordsSuggestions(SEOData) {
                    if (!SEOData) {
                        return;
                    }
                    var obj;
                    var relevantKeywords = SEOData.relevantKeywords || [];
                    var articleText = SEOData.textualContent || '';
                    vm.suggestions = [];
                    if (!relevantKeywords || !relevantKeywords.length) {
                        vm.keywordsSuggestionsLoading = false;
                        return;
                    }
                    var unique = [];
                    var i;
                    var imInLoading = false;
                    var oneWordAnalyzed = false;
                    /** fetch suggestions from most relevant keywords */
                    for (i = 0; i < relevantKeywords.length; i++) {
                        obj = relevantKeywords[i];
                        if (obj.relevancy > 85 && (!oneWordAnalyzed || obj.numWords > 1)) {
                            if (obj.numWords === 1)
                                oneWordAnalyzed = true;
                            imInLoading = true;
                            fetchFromGoogleSuggestions(vm, obj, articleText, unique);
                        }
                    }
                    if (!imInLoading) {
                        var fallbackFounded = 0;
                        /** if not found nothing perform a fallback and fetch suggestions from first 3th secondary keywords */
                        for (i = 0; i < relevantKeywords.length && fallbackFounded < 3; i++) {
                            obj = relevantKeywords[i];
                            if (obj.relevancy >= 70 && (!oneWordAnalyzed || obj.numWords > 1)) {
                                if (obj.numWords === 1)
                                    oneWordAnalyzed = true;
                                fallbackFounded++;
                                imInLoading = true;
                                fetchFromGoogleSuggestions(vm, obj, articleText, unique);
                            }
                        }
                    }
                    if (!imInLoading) {
                        /** if not found nothing perform a fallback and fetch suggestions from relevant keywords */
                        imInLoading = true;
                        fetchFromGoogleSuggestions(vm, relevantKeywords[0], articleText, unique);

                    }
                    if (SEOData.h1 && SEOData.h1.length) {
                        /** Fetch suggestions from most important H1  */
                        imInLoading = true;
                        fetchFromGoogleSuggestions(vm, {
                            word: SEOData.h1[0],
                            relevancy: 90,
                        }, articleText, unique);
                    }


                    if (SEOData.h2 && SEOData.h2.length) {
                        /** Fetch suggestions from all H2  */
                        for (var j = 0; j < SEOData.h2.length && j < 3; j++) {
                            imInLoading = true;
                            fetchFromGoogleSuggestions(vm, {
                                word: SEOData.h2[j].split(' ').length > 2 ? SEOData.h2[j] : relevantKeywords[0].word + ' ' + SEOData.h2[j],
                                relevancy: 70,
                            }, articleText, unique);
                        }
                    }

                    if (!imInLoading) {
                        vm.keywordsSuggestionsLoading = false;
                    }
                }

                function fetchFromGoogleSuggestions(scope, toFetch, textualContent, unique) {
                    toFetch = cloneObject(toFetch);
                    scope.keywordsSuggestionsLoading = true;
                    GoogleSuggestions.autocomplete(toFetch.word + ' ').then(function (googleSuggestions) {
                        scope.keywordsSuggestionsLoading = false;
                        uniqueSuggestions(vm.suggestions,
                            formatSuggestionsToAdd(googleSuggestions, textualContent, toFetch),
                            unique);
                    }).catch(function (err) {
                        scope.keywordsSuggestionsLoading = false;
                    });
                }

                function formatSuggestionsToAdd(suggestions, textualContent = '', parent = {word: null, relevancy: null}) {
                    var results = [];
                    textualContent = textualContent.replace(/\s{1,}/g, ' ').toLowerCase();
                    for (var i = 0; i < suggestions.length; i++) {
                        results.push({
                            word: suggestions[i],
                            usedInText: textualContent.indexOf(suggestions[i].toLowerCase()) >= 0 ? 'in_text' : 'not_in_text',
                            parentWord: parent.word,
                            parentRelevancy: parent.relevancy
                        });
                    }
                    return results;
                }

                function uniqueSuggestions(suggestions, suggestionsToAdd, unique) {
                    for (var i = 0; i < suggestionsToAdd.length; i++) {
                        if (!unique[suggestionsToAdd[i].word]) {
                            unique[suggestionsToAdd[i].word] = 1;
                            suggestions.push(suggestionsToAdd[i]);
                        }
                    }
                    suggestions.sort(function (a, b) {
                        return b.parentRelevancy - a.parentRelevancy;
                    });
                }

                vm.$watch('$ctrl.sections', function (sections) {
                    if (sections) {
                        vm.sections = sections;
                        if (vm.sections.keywordsSuggestions)
                            newAnalysis();
                    }
                });
                vm.$watch('$ctrl.isLoading', function (isLoading) {
                    vm.isLoading = isLoading;
                    if (isLoading)
                        vm.keywordsSuggestionsLoading = true;
                    vm.suggestions = null;
                });
                vm.$watch('$ctrl.analysis', function (analysis) {
                    vm.suggestions = null;
                    vm.keywordsSuggestionsLoading = true;
                    vm.analysis = analysis;
                    newAnalysis(true);
                });
            }]
    };
});

/**
 * @ngdoc overview
 * @name seoeditor.service:SEOEditorService
 */
'use strict';
define(['app'], function (app) {
    app.service('SEOEditorService', SEOEditorService);
    SEOEditorService.$inject = ['API','$http', '$sce', '$q', '__env'];

    function SEOEditorService(API, $http, $sce, $q, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.seoEditorAPI;

        return {
            isInSubscription: isInSubscription,
            findKeywords: findKeywords,
            saveArticle: saveArticle,
            getArticles: getArticles,
            getArticle: getArticle,
            updateArticle: updateArticle,
            deleteArticle: deleteArticle
        };

        function isInSubscription() {
            return API.get(apiPath);
        }

        function findKeywords(params) {
            return $q(function (resolve) {
                if (!Array.isArray(params.q))
                    params.q = [encodeURIComponent(angular.copy(params.q))];
                else {
                    params.q = encodeKeywords(angular.copy(params.q));
                    params.q = params.q && params.q.length ? params.q.join('|') : [];
                }

                var request = '?q=' + params.q;
                if (params.country != undefined)
                    request += '&country=' + params.country;
                if (!params.q.length)
                    return false;
                $http.get(__env.apiGateway + '/api/v1/seo-editor/keywords' + request).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    resolve({list: []});
                });
            });
        }

        function encodeKeywords(keywordList) {
            for (var i = 0; i < keywordList.length; i++)
                keywordList[i] = encodeURIComponent(keywordList[i]);
            return keywordList;
        }

        function saveArticle(article) {
            return API.post(apiPath + '/save-article', article, true);
        }

        function getArticles() {
            return API.get(apiPath + '/articles', {}, true);
        }

        function getArticle(id) {
            return API.get(apiPath + '/article/' + id, {}, true);
        }

        function updateArticle(id, article) {
            return API.put(apiPath + '/update-article/' + id, article, true);
        }

        function deleteArticle(id) {
            return API.put(apiPath + '/delete-article/' + id, {}, true);
        }

    }
});

import angular from 'angular';
import 'angular-load';
import 'angular-scroll';
import 'angular-ui-router';
import 'angular-animate';
import 'angular-sanitize';
import 'ngstorage';
import 'angular-cookies';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-loader-partial';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-chart.js';
import 'angular-facebook';
import 'angular-counter';
import 'sparkline';
import 'ng-file-upload';
import 'angular-drag-and-drop-lists';

export default angular.module('app.core', [
			'angularLoad',
			'duScroll',
			'ngAnimate',
			'ngStorage',
			'ngSanitize',
			'ngCookies',
			'ui.router',
			'pascalprecht.translate',
			'chart.js',
			'sparkline',
			'facebook',
			'ngFileUpload',
			'dndLists',
            'counter',

		]);

'use strict';
define([
    'angular',
    'modules/leadgeneration/components/leadgeneration-preview/leadgeneration-preview.component',
    'modules/leadgeneration/components/leadgeneration-add-box/leadgeneration-add-box.component',
    'modules/leadgeneration/components/lead-gen-form-editing/lead-gen-form-editing.component',
    'modules/leadgeneration/components/dialogs/dialogs.module'

], function (angular, leadGenerationPreview, leadGenerationAddBox, leadGenFormEditing) {
    var app = angular.module('app.leadgeneration.ui', ['app.leadgeneration.ui.dialogs']);
    app.component('leadGenerationPreview', leadGenerationPreview);
    app.component('leadGenerationAddBox', leadGenerationAddBox);
    app.component('leadGenFormEditing',leadGenFormEditing);
    return app;
});

/**
 * @ngdoc overview
 * @name log.service:LogService
 */
'use strict';
define(['app'], function (app) {
    app.service('LogService', LogService);
    LogService.$inject = ['API', '__env'];

    /**
     * @param {API} API
     * @param {Object} __env
     * @constructor
     */
    function LogService(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.logAPI;
        /**
         * Return all Logs for User or WorkSpace
         * @param {'queries'|'activities'} type
         * @returns {Promise}
         */
        this.getAllLogs = function (type) {
            return API.get(apiPath + '/all/' + type)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        /**
         * Return only External Api Logs
         * @returns {Promise}
         */
         this.getApiLogs = function () {
            return API.get(apiPath + '/external-api')
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };
    }
});

'use strict';
define([], function () {
    function controller($scope, $filter) {
        var ctrl = this;
        $scope.otherChart = [];
        $scope.firstChart = {};
        var axes = {
            display: {
                x: true,
                y: true
            }
        };
        var controlChartDataSetsLength;
        ctrl.average = true;
        ctrl.isLoading = true;

        ctrl.competitorsSelected = [];
        ctrl.options = {
            opacityCompetitors: false
        };
        setDefaultChart();

        $scope.chartOpts = {
            fill: false,
            title: {
                display: true,
            },
            onHover: function () {
                if (!controlChartDataSetsLength || controlChartDataSetsLength.length <= 0) {
                    setDisplayTooltip('none');
                } else {
                    setDisplayTooltip('block');
                }
            },
            onClick: function () {
                setDisplayTooltip('none');
            },
            tooltips: {
                enabled: false,
                mode: 'index',
                intersect: false,
                custom: customTooltip,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || '';
                        return '<span style="font-weight:bold">' + tooltipItem.yLabel + '</span> - ' + label;
                    }
                }
            },
            elements: {
                responsive: true,
                point: {
                    radius: 2,
                    HitRadius: 1,
                    hoverRadius: 2,
                    borderWidth: 1,
                    borderColor: '#C2D1D9',
                    backgroundColor: '#C2D1D9',
                    hoverBackgroundColor: '#C2D1D9',
                },
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    display: axes.display.x,
                    time: {
                        unit: 'day',
                        displayFormats: {
                            quarter: 'MMM YYYY',
                        },
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 20
                    }
                }],
                yAxes: [{
                    display: axes.display.y,
                    type: 'linear',
                    position: 'right',
                    ticks: {
                        maxTicksLimit: 2.66666666,
                        reverse: true,
                        min: 1,
                        fontSize: 13,
                        fontColor: '#6e7c8a',
                        fontFamily: " 'Helvetica Neue', Helvetica, Arial, sans-serif",
                        padding: 15,
                        precision: 1,
                    },
                    gridLines: {
                        color: '#EFF2F5',
                        drawBorder: false,
                        zeroLineColor: '#EFF2F5',
                    },
                }],
            },
        };

        $scope.$watch('$ctrl.options', function (options) {
            if (options) {
                $scope.chartOpts.scales.xAxes[0].display = options.display.x;
                $scope.chartOpts.scales.yAxes[0].display = options.display.y;
            }
        });
        $scope.$watch('$ctrl.average', function (average) {
            ctrl.average = average;
        });

        $scope.$watchCollection('$ctrl.competitorsSelected', function (competitorsSelected) {
            ctrl.competitorsSelected = competitorsSelected;
            if (ctrl.project) {
                setDataSets();
            }
        });

        $scope.$watch('$ctrl.keywordSelectedPerTrend', function (keywordSelectedPerTrend) {
            if (keywordSelectedPerTrend && !isEmpty(keywordSelectedPerTrend)) {
                ctrl.keywordSelectedPerTrend = keywordSelectedPerTrend;
            }
            if (ctrl.project) {
                setDataSets();
            }
        });

        $scope.$on("updateKeywords", function (evt, rows) {
            if (ctrl.project) {
                ctrl.project.keywords = rows;
                setDataSets();
            }
        });

        $scope.$watch('$ctrl.project', function (project) {
            if (project) {
                ctrl.project = project;
                setDataSets();
            }
        });

        function setDataSets() {
            var arrayOfarrays;

            if (ctrl.keywordSelectedPerTrend && !isEmpty(ctrl.keywordSelectedPerTrend)) {
                arrayOfarrays = [
                    formattedFirstChartForOneKeyword(ctrl.keywordSelectedPerTrend),
                    formattedCompetitorsChartForOneKeyword(ctrl.keywordSelectedPerTrend)
                ];
            } else {
                arrayOfarrays = [
                    formattedFirstChart(ctrl.project),
                    formattedCompetitorsChart(ctrl.project)
                ];
            }
            var dataSets = arrayOfarrays.flat();

            $scope.chartDataSets = [];
            $scope.chartSeries = [];
            $scope.chartDataSetsOverride = [];
            angular.forEach(dataSets, function (trends) {
                if (!trends) return;
                $scope.chartSeries.push(trends.label);
                $scope.chartDataSets.push(trends.data);
                $scope.chartDataSetsOverride.push(trends);
            });
            controlChartDataSetsLength = $scope.chartDataSets.flat();
            controlChartDataSetsLength = controlChartDataSetsLength.filter(function (e) {
                return e;
            });
            if (!controlChartDataSetsLength.length) {
                setDefaultChart();
            }
        }

        function setDefaultChart() {
            $scope.chartLabels = [new Date().setDate(new Date().getDate() - 30), new Date()];
            $scope.chartSeries = ['Empty-value'];
            $scope.chartColors = ['#EFF2F5'];
            $scope.chartDataSets = [
                [{
                        t: new Date().setDate(new Date().getDate() - 30),
                        y: 18,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 28),
                        y: 9,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 25),
                        y: 15,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 20),
                        y: 8,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 18),
                        y: 13,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 15),
                        y: 7,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 10),
                        y: 5,
                    },
                    {
                        t: new Date().setDate(new Date().getDate() - 5),
                        y: 15,
                    },
                    {
                        t: new Date(),
                        y: 2,
                    }
                ],
            ];
            setDisplayTooltip('none!important');

            $scope.chartDataSetsOverride = [{
                yAxisID: '',
                fill: false,
            }];
        }

        function formattedFirstChartForOneKeyword(row) {
            return {
                label: '<span style="font-weight:bold">(You)</span>',
                backgroundColor: '#2296f3',
                borderColor: '#2296F3',
                pointBackgroundColor: '#2296F3',
                pointBorderColor: '#2296F3',
                pointHoverBackgroundColor: '#2296F3',
                pointHoverBorderColor: '#2296F3',
                fill: false,
                data: setTrendsPerKeyword(row.searches),
            };
        }

        function formattedCompetitorsChartForOneKeyword(row) {
            if (!row.competitorSearches) {
                return;
            }
            var competitors = [];
            var color;
            $scope.competitors = ctrl.project.competitors;
            ctrl.project.competitors.forEach(function (competitor) {

                color = setColorOpacityForCompetitor(competitor.color);
                competitors.push({
                    label: '<span style="color:#dad9d9">' + cleanUrl(competitor.linkUrl) + '</span>',
                    backgroundColor: competitor.color,
                    pointBackgroundColor: competitor.color,
                    pointBorderColor: competitor.color,
                    pointHoverBackgroundColor: competitor.color,
                    pointHoverBorderColor: competitor.color,
                    borderColor: color,
                    fill: false,
                    hidden: !competitorIsVisible(competitor.linkUrl),
                    data: setTrendsPerKeyword(row.competitorSearches[competitor.id]),
                });
            });
            return competitors;
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function setTrendsPerKeyword(searches) {
            var trends = [];
            if (!searches || !searches.length) {
                return [];
            }
            searches.forEach(function (search) {
                trends.push({
                    t: search.createdAt,
                    y: search.position
                });
            });

            if (trends.length && trends[trends.length - 1].t && trends[0].t) {
                if (trends[trends.length - 1].t === trends[0].t) {
                    var dates = trends[0].t;
                    $scope.chartLabels = [
                        $filter('date')(new Date(dates).setDate(new Date(dates).getDate() - 2), 'yyyy-MM-dd'),
                        $filter('date')(new Date(dates).setDate(new Date(dates).getDate() + 2), 'yyyy-MM-dd')
                    ];
                } else {
                    $scope.chartLabels = [trends[trends.length - 1].t, trends[0].t];
                }
            }
            return trends;
        }

        function formattedCompetitorsChart(project) {
            var competitors = [];
            $scope.competitors = project.competitors;
            project.competitors.forEach(function (competitor) {
                var dullColor = setColorOpacityForCompetitor(competitor.color);
                competitors.push({
                    label: '<span style="color:#dad9d9">' + cleanUrl(competitor.linkUrl) + '</span>',
                    backgroundColor: competitor.color,
                    borderColor: dullColor,
                    pointBackgroundColor: competitor.color,
                    pointBorderColor: dullColor,
                    pointHoverBackgroundColor: competitor.color,
                    pointHoverBorderColor: dullColor,
                    fill: false,
                    hidden: !competitorIsVisible(competitor.linkUrl),
                    data: setTrends(project.keywords, competitor.id),
                });
            });
            return competitors;
        }

        function competitorIsVisible(name) {
            if (!ctrl.competitorsSelected) {
                return true;
            }
            return ctrl.competitorsSelected.some(function (competitor) {
                return competitor === name;
            });
        }

        function setColorOpacityForCompetitor(color) {
            if (!color) {
                return '#ffff';
            }
            if (ctrl.options && ctrl.options.opacityCompetitors) {
                var rgb = hexToRgb(color);
                return 'rgb(' + rgb.red + ',' + rgb.green + ',' + rgb.blue + ',' + ctrl.options.opacityCompetitors + ')';
            }
            return color;
        }

        function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                red: parseInt(result[1], 16),
                green: parseInt(result[2], 16),
                blue: parseInt(result[3], 16)
            } : null;
        }

        function setDisplayTooltip(value) {
            if (document.getElementById('chartjs-tooltip')) {
                document.getElementById('chartjs-tooltip').style.display = value;
            }
        }

        function formattedFirstChart(project) {
            return {
                label: '<span style="font-weight:bold">(You)</span>',
                backgroundColor: '#2296f3',
                borderColor: '#2296F3',
                pointBackgroundColor: '#2296F3',
                pointBorderColor: '#2296F3',
                pointHoverBackgroundColor: '#2296F3',
                pointHoverBorderColor: '#2296F3',
                fill: false,
                data: setTrends(project.keywords),
            };
        }

        function setTrends(keywords, idCompetitor) {
            var dates;
            var object;
            var searches;
            var trends = [];
            var average = function (positions) {
                return positions.reduce(function (a, b) {
                    return a + b;
                }) / positions.length;
            };
            if (idCompetitor) {
                object = getSearchesCompetitor(keywords, idCompetitor);
                searches = object.searches;
                dates = object.dates;
            } else {
                object = getSearches(keywords);
                searches = object.searches;
                dates = object.dates;
            }

            var uniqueDates = dates.filter(function (item, pos) {
                return dates.indexOf(item) === pos;
            });

            uniqueDates = uniqueDates.sort(function (a, b) {
                return new Date(a) - new Date(b);
            });

            uniqueDates.forEach(function (date) {
                var positions = searches.filter(function (search) {
                    if (search && search.createdAt) return search.createdAt === date;
                }).map(function (search) {
                    return search.position;
                });
                trends.push({
                    t: date,
                    y: average(positions).toFixed(2)
                });
            });

            if (uniqueDates[uniqueDates.length - 1] === uniqueDates[0]) {
                if (uniqueDates[0]) {
                    var dates = uniqueDates[0].t ? uniqueDates[0].t : uniqueDates[0];
                    $scope.chartLabels = [
                        new Date(dates).setDate(new Date(dates).getDate() - 2),
                        new Date(dates).setDate(new Date(dates).getDate() + 2),
                    ];
                }
            } else {
                $scope.chartLabels = [uniqueDates[uniqueDates.length - 1], uniqueDates[0]];
            }

            return trends;
        }

        function getSearchesCompetitor(keywords, idCompetitor) {
            var dates = [];
            var searches = keywords.map(function (keyword) {
                if (keyword.competitorSearches && keyword.competitorSearches[idCompetitor] && keyword.competitorSearches[idCompetitor].length) {
                    keyword.competitorSearches[idCompetitor].forEach(function (search) {
                        dates.push(search.createdAt);
                    });
                    return keyword.competitorSearches[idCompetitor];
                }
            });

            return {
                dates: dates,
                searches: searches.flat()
            };
        }

        function getSearches(keywords) {
            var dates = [];
            var searches = keywords.map(function (keyword) {
                if (keyword.searches && keyword.searches.length) {
                    keyword.searches.forEach(function (search) {
                        if (search.createdAt) {
                            dates.push(search.createdAt);
                        }
                    });
                    return keyword.searches;
                }
            });

            //Clean Array to undefined value
            var cleanSearches = searches.filter(function (e) {
                return e;
            });

            return {
                dates: dates,
                searches: cleanSearches.flat()
            };
        }

        function customTooltip(tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.style.zIndex = "9999999999999";
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            function getDayNumberSuffix(day) {
                if (day >= 11 && day <= 13) {
                    return "th";
                }
                switch (day % 10) {
                    case 1:
                        return "st";
                    case 2:
                        return "nd";
                    case 3:
                        return "rd";
                    default:
                        return "th";
                }
            }

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            // Set Text
            if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);
                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                    var day = new Date(title).getDate();
                    var suffixDay = getDayNumberSuffix(day);
                    var month = new Date(title).toLocaleString('default', {
                        month: 'short'
                    });
                    innerHtml += '<tr><th style="font-size:14px;">' + day + suffixDay + ' ' + capitalizeFirstLetter(month) + '</th></tr>';
                });
                innerHtml += '</thead><tbody style="margin-top:10px">';

                bodyLines.forEach(function (body, i) {
                    var colors = tooltipModel.labelColors[i];
                    var style = 'margin-right:5px;opacity:1;background-color:' + colors.backgroundColor;
                    var div1 = '<div class="st-badge-circle" style=' + style + '></div>';
                    var div2 = '<div style="color:#ffff;">' + body + '</div>';
                    innerHtml += '<tr style="margin-top:5px;display:flex">' +
                        '<td style="display:flex;justify-content:center;align-items:center;font-size:11px">' + div1 + div2 + '</td></tr>';
                });
                innerHtml += '</tbody>';
                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.width = '180px';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.background = 'rgba(0, 0, 0, .7)';
        }

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }

    }

    controller.$inject = ['$scope', '$filter'];
    return {
        templateUrl: 'app/modules/keywordtracker/components/trend-competitors/trend-competitors.html',
        bindings: {
            average: '<',
            project: '<',
            competitorsSelected: '<',
            options: '<',
            keywordSelectedPerTrend: '<'
        },
        controller: controller,
    };
});

'use strict';
define([
    'angular',
    'modules/seospider/list/list.controller',
], function (angular, SeoSpiderListCtrl) {
    var app = angular.module('app.seospider.list', []);

    app.controller('SeoSpiderListCtrl', SeoSpiderListCtrl);

    return app;
});

'use strict';
define([
    'angular',
    'modules/pitchground/pitchground.routes',
    'modules/pitchground/pitchground.controller'
],function(angular,routes,PitchgroundController){
    var app=angular.module('pitchground', []);
    app.config(routes);
    app.controller('PitchgroundController',PitchgroundController);
    return app;
});
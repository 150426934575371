/**
 * @ngdoc overview
 * @name TrackEvent.service:API
 */
'use strict';
define(['app'], function (app) {
    app.service('TrackEventService', TrackEventService);
    TrackEventService.$inject = [
        '$window',
    ];

    /**
     * TrackEvent Service
     * @namespace TrackEventService
     */
    function TrackEventService($window) {
        window.dataLayer = window.dataLayer || [];

        /**
         * @param {Object} parameters
         */
        function push(parameters) {
            if (!parameters || parameters === {}) {
                return;
            }

            window.dataLayer.push(parameters);
        }

        /**
         * @param {string} eventName
         * @param {string} category
         * @param {string} action
         * @param {string|null} label
         * @param {string|null} value
         * @returns {string}
         */
        function customEvent(eventName, category, action, label = null, value = null) {
            window.dataLayer.push({
                'event' : eventName,
                'attributes' : {
                    'category' : category,
                    'action' : action,
                    'label' : label,
                    'value' : value,
                }
            });
        }

        /**
         * @param {string} category
         * @param {string} action
         * @param {string|null} label
         * @param {string|null} value
         * @returns {string}
         */
        function event(category, action, label = null, value = null) {
            customEvent('stoTrackEvent', category, action, label, value);
        }

        /**
         * @param {string} name
         * @param {object|null} properties
         */
        function gaV2Event(name, properties = null) {
            window.dataLayer.push({
                'event' : name,
                'eventProperties' : properties
            });
        }

        return {
            push: push,
            event: event,
            customEvent: customEvent,
            gaV2Event: gaV2Event

        };
    }
});

controller.$inject = ['$scope', '$element', '$filter', 'WriterAssistantService', 'FavIconService', 'WriterAssistantAuditorService'];

function controller($scope, $element, $filter, WriterAssistantService, FavIconService, WriterAssistantAuditorService) {
    var vm = this;

    $scope.FavIconService = FavIconService;
    $scope.selectedBriefMode = 0;
    $scope.todolist = {};
    $scope.articleContentStats = {
        numWords: 0,
        numImages: 0,
        numHeadings: 0,
        numParagraphs: 0,
    };
    $scope.score = 0;
    $scope.selectedTabMode = 0; // 0 keywords, 1 assistant

    // Access `mainKeyword` in the controller
    vm.$onInit = async () => {
        vm.articleDataSet.secondaryKeywords = await WriterAssistantAuditorService.getRelevantKeywords(vm.articleDataSet);
        vm.articleDataSet.contentStrategy = WriterAssistantAuditorService.getReferencesContentStrategy(vm.articleDataSet);
        const {score, audits, stats} = WriterAssistantAuditorService.audit(
            vm.articleDataSet,
            vm.editor
        );
        vm.articleDataSet.score = score;
        $scope.score = score;
        $scope.todolist = audits;
        $scope.articleContentStats = stats;

        // Find the seo-onpage-score element using $element (from Angular)
        const seoOnpageScore = $element[0].querySelector('seo-onpage-score');
        const stickyScore = $element[0].querySelector('#sticky-score');

        // Ensure both elements exist
        if (seoOnpageScore && stickyScore) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    // Toggle the visibility of the sticky score based on intersection
                    if (!entry.isIntersecting) {
                        stickyScore.style.display = 'block';
                    } else {
                        stickyScore.style.display = 'none';
                    }
                },
                {root: null, threshold: 0}
            );

            // Start observing the seoOnpageScore element
            observer.observe(seoOnpageScore);
        }
    };


    $scope.$watchCollection('$ctrl.articleDataSet.content', function () {
        try {
            const {
                score,
                audits,
                stats
            } = WriterAssistantAuditorService.audit(
                vm.articleDataSet,
                vm.editor
            );
            vm.articleDataSet.score = score;
            $scope.score = score;
            $scope.todolist = audits;
            $scope.articleContentStats = stats;
        } catch (e) {
            console.log(e);
        }
    });

    $scope.countReferences = function () {
        if (!vm.articleDataSet || !vm.articleDataSet.marketAnalysis || !vm.articleDataSet.marketAnalysis.rows) {
            return 0;
        }


        // Funzione che conta gli elementi filtrati
        let itemsArray;
        // Se $scope.items è un oggetto, lo converto in un array di valori
        if (!Array.isArray($scope.items)) {
            itemsArray = Object.values(vm.articleDataSet.marketAnalysis.rows);
        } else {
            itemsArray = vm.articleDataSet.marketAnalysis.rows;
        }
        return $filter('filter')(itemsArray, {useThisStrategy: true}).length;
    };

    // Funzione che conta gli elementi filtrati
    $scope.getFilteredItemCount = function (filter) {
        if (!vm.articleDataSet || !vm.articleDataSet.secondaryKeywords) {
            return 0;
        }

        var itemsArray;
        // Se $scope.items è un oggetto, lo converto in un array di valori
        if (!Array.isArray($scope.items)) {
            itemsArray = Object.values(vm.articleDataSet.secondaryKeywords);
        } else {
            itemsArray = vm.articleDataSet.secondaryKeywords;
        }
        return $filter('filter')(itemsArray, filter).length;
    };

    $scope.focusEditorOnError = function (issue) {
        vm.editor.chain().focus().setTextSelection({from: issue.start, to: issue.end}).scrollIntoView().run();
    };
}

export default {
    templateUrl: 'app/modules/writerassistant/components/assistant-auditor/assistant-auditor.html',
    controller: controller,
    bindings: {
        articleDataSet: "<",
        editor: "<",
        loading: "<"
    }
};

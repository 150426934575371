/**
 * @ngdoc overview
 * @name export.service:ExportService
 *
 * @description
 * export module to generate csv, excel and clipboard
 */
'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('ExportService', ExportService);
    ExportService.$inject = ['$window', '$http', '__env'];

    function ExportService($window, $http, __env) {
        this.checkExportAvailability = function () {
            return $http.get(__env.apiGateway + '/api/v1/export-csv-xlsx');
        };

        this.convertObjectToCsvArray = function (queries) {
            var result = [];

            if (!isExtractableObject(queries)) {
                return result;
            }

            result.push(extractTitles(queries));

            // Extract all column values
            for (var i = 0; i <= queries.length; i += 1) {
                var query = queries[i];
                var values = [];

                angular.forEach(query, function (value) {
                    try {
                        if (!value && typeof value !== 'boolean' && typeof value !== 'number') {
                            values.push('N/A');
                            return;
                        }

                        if (typeof value !== 'object') {
                            if (value === '0.1' || value === 0.1) {
                                values.push('0');
                            } else {
                                values.push(value.toString().replace(',', ''));
                            }
                            return;
                        }

                        angular.forEach(value, function (subvalue) {
                            values.push(subvalue.toString().replace(',', ''));
                        });
                    } catch (err) {
                        $window.console.error(err);
                    }
                });

                result.push(values);
            }

            return result;
        };

        function isExtractableObject(queries) {
            return Array.isArray(queries) && queries.length > 0;
        }

        function extractTitles(queries) {
            var columns = [];
            angular.forEach(queries[0], function (value, index) {
                if (typeof value !== 'object' || value === null) {
                    columns.push(index);
                    return;
                }

                angular.forEach(value, function (value, index) {
                    columns.push(index);
                });
            });

            return columns;
        }
    }
});

define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.images",{
                controller:'ImagesCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/images/images.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.images.all",{
                url:"/images",
                templateUrl: './app/modules/seospider/exam/analysis/images/all/all.html',
                access: {restricted: true},
                controller:'ImagesAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllImages({
                            jobId:$stateParams.sessionId
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.images.altOver",{
                url:"/images/alt-over-100-chars",
                templateUrl: './app/modules/seospider/exam/analysis/images/all/all.html',
                access: {restricted: true},
                controller:'ImagesAltOverCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllImages({
                            jobId:$stateParams.sessionId,
                            altSize:'overDesktop'
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;

                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.images.missing",{
                url:"/images/missing",
                templateUrl: './app/modules/seospider/exam/analysis/images/missing/missing.html',
                access: {restricted: true},
                controller:'ImagesMissingCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllImages({
                            jobId:$stateParams.sessionId,
                            missing:true
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.images.missingAlt",{
                url:"/images/missing-alt",
                templateUrl: './app/modules/seospider/exam/analysis/images/all/all.html',
                access: {restricted: true},
                controller:'ImagesMissingAltCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllImages({
                            jobId:$stateParams.sessionId,
                            missingAlt:true
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});

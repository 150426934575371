'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/robots/components/table.component'
],function(
    angular,
    robotsTable
){
    var app=angular.module('app.seospider.exam.analysis.robots.components',
        []
    );
    app.component("robotsTable",robotsTable);
    return app;
});
'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/url-split/url-split.html',
        bindings: { url: '=',status: '=',type:'@' },
        controller:['$scope',function($scope){
            var ctrl = this;
            $scope.$watch('$ctrl.url', function (url) {
                try{
                    ctrl.parsedURL = new URL(url);
                }catch(err){
                    ctrl.parsedURL = {
                        pathname:url,
                        hostname:null,
                    };
                }
            });
        }]
    };
});

'use strict';
define([
    'angular',
    'modules/template/template.routes',
    'modules/template/template.controller',
    'modules/template/components/st-sidebar-mini/st-sidebar-mini.component',
    'modules/template/components/st-menu-multi-account/st-menu-multi-account.component',
    'modules/template/components/st-default-workspace-navbar/st-default-workspace-navbar.component',
    'modules/template/dialogs/dialogs.module'
], function(angular,routes,TemplateCtrl,stSidebarMini,stMenuMultiAccount,stDefaultWorkspaceNavbar){
    var app = angular.module('app.template',[
        'app.template.dialogs'
    ]);
    app.config(routes);
    app.controller("TemplateCtrl",TemplateCtrl);
    app.config(['$mdThemingProvider',function($mdThemingProvider) {
        $mdThemingProvider.definePalette('stoblue', {
            '50': 'fafbfc',
            '100': 'f4f8fc',
            '200': 'c5d9e8',
            '300': 'a8c6df',
            '400': '8dabc4',//efefef
            '500': 'eeeeee',
            '600': '2c405a',
            '700': '012152',
            '800': 'fafbfc',//#14266b
            '900': '14266b',  // actual navbar
            'A100': 'ffffff',
            //203171 // prev navbar
            'A200': '555555',
            'A400': '29345a',
            'A700': '0D1C4E',
            'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                                // on this palette should be dark or light
            'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
                '200', '300', '400', 'A100'],
            'contrastLightColors': undefined    // could also specify this if default was 'dark'
        });

        $mdThemingProvider.definePalette('white', {
            '50': 'f0f0f0',
            '100': 'ffffff',
            '200': 'ffffff',
            '300': 'ffffff',
            '400': 'ffffff',
            '500': 'ffffff',
            '600': 'ffffff',
            '700': 'ffffff',
            '800': 'ffffff',
            '900': 'ffffff',
            'A100': 'ffffff',
            'A200': 'ffffff',
            'A400': 'ffffff',
            'A700': 'ffffff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100',
                '200', '300', '400', 'A100'],
            'contrastLightColors': undefined
        });
        $mdThemingProvider.theme('default')
            .primaryPalette('blue')
            .backgroundPalette('stoblue',{
                'default': '100'
            });
    }]);
    app.component("stDefaultWorkspaceNavbar",stDefaultWorkspaceNavbar);
    app.component("stSidebarMini",stSidebarMini);
    app.component("stMenuMultiAccount",stMenuMultiAccount);
    return app;
});

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/content-structure/content-structure.html',
    bindings: {
        loading: '=',
        contentStrategy: "=",
        marketAnalysis: "=",
        currentStep: "=",
        mainKeyword: "=",
        device: "=",
        country: "=",
        language: "=",
    },
    controller: ['$scope', '$timeout', 'FavIconService', 'WriterAssistantAuditorService',
        function ($scope, $timeout, FavIconService, WriterAssistantAuditorService) {
            const vm = this;

            $scope.linked = true;
            vm.initialValues = {
                words: null,
                headings: null,
                images: null,
                paragraph: null,
            };

            $scope.reset = function () {
                vm.contentStrategy.words.value = vm.initialValues.words;
                vm.contentStrategy.images.value = vm.initialValues.images;
                vm.contentStrategy.headings.value = vm.initialValues.headings;
                vm.contentStrategy.paragraph.value = vm.initialValues.paragraph;
            };

            $scope.toggleLinked = function () {
                $scope.linked = !$scope.linked;
            };

            adjustStrategyToMarketAnalysis();

            function adjustStrategyToMarketAnalysis() {
                if (!vm.marketAnalysis) {
                    return;
                }
                vm.contentStrategy = WriterAssistantAuditorService.getReferencesContentStrategy(vm);
            }

            // Access `mainKeyword` in the controller
            vm.$onInit = async function () {
                adjustStrategyToMarketAnalysis();
                // Calculate initial ratios
                vm.initialValues = {
                    words: Number(vm.contentStrategy.words.value),
                    headings: Number(vm.contentStrategy.headings.value),
                    images: Number(vm.contentStrategy.images.value),
                    paragraph: Number(vm.contentStrategy.paragraph.value)
                };
                // Calculate initial ratios
                const initialRatios = {
                    headings: Number(vm.contentStrategy.words.value) / Number(vm.contentStrategy.headings.value),
                    images: Number(vm.contentStrategy.words.value) / Number(vm.contentStrategy.images.value),
                    paragraphs: Number(vm.contentStrategy.words.value) / Number(vm.contentStrategy.paragraph.value)
                };


                $scope.$watch(() => vm.contentStrategy.words.value, (newVal, oldVal) => {
                    const differential = newVal - oldVal;
                    if ($scope.linked && differential !== 0) {
                        // Recalculate headings, images, and paragraphs based on the fixed ratios
                        vm.contentStrategy.headings.value = Math.max(1, Math.round(newVal / initialRatios.headings));
                        vm.contentStrategy.images.value = Math.max(0, Math.round(newVal / initialRatios.images));
                        vm.contentStrategy.paragraph.value = Math.max(1, Math.round(newVal / initialRatios.paragraphs));

                        // Ensure all values are updated in the scope
                        $scope.$apply();
                    }
                });

            };

        }]
};

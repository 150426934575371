'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/title/duplicated/duplicated.controller',
],function(
    angular,
    TitleDuplicatedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.title.duplicated', []);
    app.controller("TitleDuplicatedCtrl",TitleDuplicatedCtrl);
    return app;
});
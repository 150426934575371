'use strict';
define(['angular'], function (angular) {
    function Controller($scope, $translate, keywordLimit, listsProperties, SearchConsoleService, $filter, $window, $mdToast) {
        var mutex = 0;
        var vm = $scope;
        vm.loading = false;

        vm.listsProperties = listsProperties && listsProperties.length > 0 ? listsProperties : [];
        vm.website = vm.listsProperties > 0 ? vm.listsProperties[0] : '';

        vm.projectName = localStorage.getItem('projectName');
        vm.searchConsoleToken = localStorage.getItem('googleSearchConsoleToken');

        vm.rowLimit = keywordLimit;

        vm.range = {
            startDate: new Date(),
            endDate: new Date()
        };

        vm.range.startDate.setDate(1);
        vm.range.startDate.setMonth(vm.range.startDate.getMonth() - 1);

        vm.applyRangeOfDate = function (range) {
            vm.range = {
                startDate: new Date(range.startDate),
                endDate: new Date(range.endDate)
            };
        };

        vm.closeWindow = function () {
            $window.close();
        };

        vm.formatUrl = function (url) {
            return url.replace('sc-domain:', '');
        };

        vm.getKeywords = function () {
            if (mutex === 1) {
                return;
            }

            mutex = 1;
            vm.loading = true;

            if (!vm.searchConsoleToken) {
                mutex = 0;
                vm.loading = false;
                return;
            }

            SearchConsoleService.getQueries(vm.searchConsoleToken, {
                website: vm.website,
                rowLimit: vm.rowLimit,
                range: {
                    startDate: $filter('date')(vm.range.startDate, 'yyyy-MM-dd'),
                    endDate: $filter('date')(vm.range.endDate, 'yyyy-MM-dd'),
                }
            })
                .then(function (res) {
                    mutex = 0;
                    vm.loading = false;

                    if (res.data.message === 'Not have permission') {
                        showToast();
                        setTimeout(function () {
                            $window.close();
                        }, 1500);
                    } else {
                        $window.close();
                    }
                })
                .catch(function (err) {
                    mutex = 0;
                    vm.loading = false;
                    console.log(err);

                    showToast('error.notfound.keyword');
                    setTimeout(function () {
                        $window.close();
                    }, 1500);
                });
        };

        function showToast(errorMessage) {
            var defaultError = 'error.permissions.website';
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="icon-x st-err m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(errorMessage || defaultError) + ' ' + vm.website.siteUrl + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }
    }

    Controller.$inject = ['$scope', '$translate', 'keywordLimit', 'listsProperties', 'SearchConsoleService', '$filter', '$window', '$mdToast'];
    return Controller;
});

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/market/market.html',
    bindings: {
        wizardHandler: '=',
        loading: '=',
        articleDataSet: "="
    },
    controller: ['$scope', '$location','$state', '$timeout', 'WriterAssistantService', 'stAlertLimitService', 'AuthService', function ($scope, $location, $state, $timeout, WriterAssistantService, stAlertLimitService, AuthService) {
        var vm = this;

        // Access `mainKeyword` in the controller
        vm.$onInit = async function () {
            try {
                if (!AuthService.isAuthenticated()) {
                    stAlertLimitService.open('auth-login', {
                        error: {
                            request: {
                                alias: 'seo-checker-unlock-suggestions',
                                offline: false,
                            }
                        },
                        canClose: true,
                    })
                        .then(function () {
                            if (!AuthService.isAuthenticated()) {
                                $state.go('app.writerassistant.landing');
                                return;
                            }

                            $state.go('app.writerassistant.wizard', vm.articleDataSet, {notify: true, reload: true});
                        })
                        .catch(function (err) {
                            $state.go('app.writerassistant.landing');
                        });

                    return;
                }

                if (!vm.articleDataSet || !vm.articleDataSet.marketAnalysis) {
                    let savedContent

                    if (vm.articleDataSet && vm.articleDataSet.id) {
                        savedContent = await WriterAssistantService.generateNewStrategy(vm.articleDataSet.id, vm.articleDataSet);
                    } else {
                        savedContent = await WriterAssistantService.generateNewArticleStrategy({
                            mainKeyword: vm.articleDataSet.mainKeyword,
                            country: vm.articleDataSet.country,
                            language: vm.articleDataSet.language,
                            device: vm.articleDataSet.device,
                        });



                    }

                    if (!savedContent) {
                        throw new Error('internal server error');
                    }

                    vm.articleDataSet.marketAnalysis = savedContent.marketAnalysis;
                    vm.articleDataSet.aiContextGeneratedAt = savedContent.aiContextGeneratedAt;
                    vm.articleDataSet.aiHeadingGeneratedAt = savedContent.aiHeadingGeneratedAt;
                    vm.articleDataSet.id = savedContent.id;
                    vm.articleDataSet.workspaceId = savedContent.workspaceId;
                    vm.articleDataSet.createdAt = savedContent.createdAt;

                    // Use $state.go to update the URL with the article ID as a query parameter
                    $state.go('.', { id: savedContent.id }, { notify: false, reload: false });

                }

                vm.wizardHandler.currentStep++;
                $scope.$apply();
            } catch (err) {
                console.log(err);

                $state.go('app.writerassistant.landing');
            }
        };
    }]
};

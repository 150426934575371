'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'Title',
            categoryState:'app.seospider.exam.analysis.title',
            fields:[
                {label:'seospider.title.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.title.over',icon:'icon icon-speedometer',uiSref:'.over'},
                {label:'seospider.title.missing',icon:'icon icon-close',uiSref:'.missing'},
                {label:'seospider.title.duplicate',icon:'icon icon-docs',uiSref:'.duplicated'},
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

'use strict';
define(['app'], function (app) {
    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(['$rootScope', '$injector', '$q', function ($rootScope, $injector, $q) {
            return {
                responseError: function (error) {
                    var isWorkspaceInvalid = error.status === 418;

                    if (isWorkspaceInvalid) {
                        $injector
                            .get('$state')
                            .go('app.welcome', {}, {notify: true, reload: true})
                            .then(function () {
                                $injector
                                    .get('stAlertLimitService')
                                    .open('auth-login', {
                                        error: {
                                            request: {
                                                alias: 'permissions'
                                            }
                                        }
                                    });

                            });
                    }

                    return $q.reject(error);
                }
            };
        }]);
    }]);
});

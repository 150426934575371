'use strict';
define([], function () {
    function SinglePageLinksController($scope) {
        $scope.projectUrl = null;

        $scope.chartOpts = {
            fill: false,
            responsive: false,
            aspectRatio: 0.6,
            animation: {},
            maintainAspectRatio: false,
            circumference: 2 * Math.PI,
            rotation: -0.5 * Math.PI,
            cutoutPercentage: 65,
            legend: {
                display: false,
                align: 'left',
                position: 'bottom',
                labels: {
                    usePointStyle: true
                },
            },
            title: {
                display: false,
                text: 'Status Code',
                position: 'top',
                fontStyle: 'bold',
                fontSize: '14',
                fontColor: '#130D41'
            },
            elements: {
                arc: {
                    borderWidth: 1,
                },
            },
        };

        var init = function () {
            $scope.categorizeRel = categorizeRel($scope.detail.aList);
            $scope.graphRelLabel = ['follow', 'nofollow', 'other'];
            $scope.graphRelData = [$scope.categorizeRel.follow, $scope.categorizeRel.nofollow, $scope.categorizeRel.other];

            $scope.categorizeInternal = categorizeInternal($scope.projectUrl, $scope.detail.aList);
            $scope.graphInternalLabel = ['Internal', 'External'];
            $scope.graphInternalData = [$scope.categorizeInternal.internal, $scope.categorizeInternal.external];

            var ctx = document.getElementById("chartRel").getContext("2d");
            ctx.canvas.width = 140;
            ctx.canvas.height = 40;

            var ctx = document.getElementById("chartInternal").getContext("2d");
            ctx.canvas.width = 140;
            ctx.canvas.height = 40;

            $scope.isInternalLink = isInternalLink;
            $scope.isRelative = isRelative;
            $scope.secureUrl = secureUrl;
        }


        function categorizeRel(links) {
            var founded = {
                follow: 0,
                nofollow: 0,
                other: 0
            };
            if (!links) return founded;
            for (var i = 0; i < links.length; i++) {
                var rel = links[i].rel.toLowerCase();
                if (rel.indexOf("nofollow") != -1) {
                    founded.nofollow++;
                } else {
                    if (rel.indexOf("follow") != -1) {
                        founded.follow++;
                    } else founded.other++;
                }
            }
            return founded;
        }

        function secureUrl(url) {
            if (!$scope.projectUrl) {
                return;
            }

            var root = $scope.projectUrl.replace(/\/$/g, '');
            if (isRelative(url)) {
                url = url.replace(/^\//g, '');
                return root + "/" + url;
            }
            return url;
        }

        function isRelative(url) {
            return !new RegExp(/^http[s]{0,1}\:\/\//i).test(url) && !new RegExp(/^\/\//i).test(url);
        }

        function isInternalLink(urlToCheck) {
            if (!$scope.projectUrl) {
                return;
            }

            var isValidUrl = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
            if (isRelative(urlToCheck)) {
                return true;
            } else {
                if (isValidUrl.test(urlToCheck)) {
                    var thisHost = new URL(urlToCheck).host.replace(/^www./gi, '');
                    if (thisHost.toLowerCase() === $scope.projectUrl) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        }

        function categorizeInternal(domain, links) {
            var founded = {
                internal: 0,
                external: 0
            };
            if (!links) return founded;
            for (var i = 0; i < links.length; i++) {
                if (isInternalLink(domain, links[i].href)) {
                    founded.internal++;
                } else {
                    founded.external++;
                }
            }
            return founded;
        }

        init();
    }


    SinglePageLinksController.$inject = ['$scope'];
    return SinglePageLinksController;
});

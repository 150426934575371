'use strict';

define([], function () {
    function Controller($scope, form) {
        var vm = $scope;
        vm.workingSessionModel={
            form: null
        };
        
        vm.form = form;
    }

    Controller.$inject = ['$scope', 'form'];
    return Controller;
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog, $state, $translate, $mdToast, KeywordListService, TrackEventService) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };

        function answerDialog(nameKeywordList) {
            $scope.loading.post = true;
            if (!nameKeywordList || nameKeywordList.length < 1) {
                $scope.loading.post = false;
                return;
            }
            KeywordListService.createAKeywordList({name: nameKeywordList, toolSourceId: 1})
                .then(function (query) {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - List Created');
                    TrackEventService.gaV2Event(
                        'list_create',{
                            tool: "Keyword List Manager"
                        });
                    $scope.loading.post = false;
                    $mdDialog.hide();
                    $state.go("app.keywordlistmanager.wizard", {
                        keywordListId: query.data.keywordListId,
                        step: 'choose-keywords'
                    });
                })
                .catch(function (err) {
                    $scope.loading.post = false;
                    err = err.data.error ? err.data.error :
                        $mdToast.show({
                            template:
                                '<md-toast layout="row" flex class="md-toast">' +
                                '   <div><i class="icon-x st-err m-r-sm"></i></div>' +
                                '   <div>' + $translate.instant(err.data.error.message) + '&nbsp;</div>' +
                                '</md-toast>',
                            hideDelay: 5000,
                            position: 'top right'
                        });
                });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }

    }

    Controller.$inject = ['$scope', '$mdDialog', '$state', '$translate', '$mdToast', 'KeywordListService', 'TrackEventService'];
    return Controller;
});

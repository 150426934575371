'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('stAlertLimitService', stAlertLimitService);
    stAlertLimitService.$inject = ['$q', '$mdDialog', '$location', '$rootScope'];

    function stAlertLimitService($q, $mdDialog, $location, $rootScope) {
        return {
            open: open
        };

        function open(url, error) {
            if ($rootScope.alertLimitIsOpen) {
                return $q.resolve();
            }

            $rootScope.alertLimitIsOpen = true;

            var dfd = $q.defer();
            $mdDialog.show({
                controller: ['$scope', '$location', 'url', 'error', function ($scope, $location, url, err) {
                    $scope.url = url;
                    $scope.error = err;
                }],
                locals: {url: url, error: error},
                templateUrl: 'app/components/st-alert-limit/st-alert-limit.service.html',
                parent: angular.element(document.body),
                clicskOutsideToClose: (error && error.canClose) || false,
                escapeToClose: (error && error.canClose) || false,
            })
                .then(function () {
                    $location.hash(null);

                    dfd.resolve();
                })
                .catch(function () {
                    $location.hash(null);
                    dfd.resolve();
                })

            return dfd.promise;
        }
    }
});

'use strict';
define([], function () {
    function Controller($scope, $q, $stateParams, results, Pages, $rootScope, $translate) {
        var vm = $scope;
        vm.query = '';
        vm.pages = results.data;
        vm.tabs = $scope.$parent.tabs;
        vm.pagination = {
            numElements: results.totalRows,
            offset: 0,
            callback: pagingCallback
        };
        vm.currentSearch = {
            placeholder: $translate.instant('seospider.search.page'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.search.page'),
                callback: searchCallback
            };
        });

        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAll({
                jobId: $stateParams.sessionId,
                fields: ['id', 'url', 'canonical', 'title'],
                status: 200,
                q: query,
                offset: 0
            }).then(function (res) {
                    vm.pagination.offset = 0;
                    vm.pagination.numElements = res.totalRows;
                    vm.pages = res.data;
                },
                function (err) {
                    vm.pages = err.data;
                });
        }

        function pagingCallback(limit, offset) {
            var dfd = $q.defer();
            Pages.findAll({
                jobId: $stateParams.sessionId,
                fields: ['id', 'url', 'canonical', 'title'],
                status: 200,
                q: vm.query,
                limit: limit,
                offset: offset
            }).then(function (res) {
                vm.pages = res.data;
            }, function (err) {
                vm.pages = err.data;
                dfd.resolve();
            });
            return dfd.promise;
        }
    }

    Controller.$inject = ['$scope', '$q', '$stateParams', 'results', 'SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

/**
 * @ngdoc overview
 * @name log.service:LogService
 */
'use strict';
define(['app'], function (app) {
    app.service('CRMService', CRMService);
    CRMService.$inject = ['API', '__env'];

    /**
     * @param {API} API
     * @param {Object} __env
     * @constructor
     */
    function CRMService(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.crmAPI;

        /**
         * Return all Logs for User or WorkSpace
         * @param {string} eventName
         * @param {string[] | null} eventsToReset
         * @returns {Promise}
         */
        this.addEvents = function (eventNames, eventsToReset) {
            return API.post(apiPath + '/event', {
                names: eventNames,
                toReset: eventsToReset,
            }, true)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };

        /**
         * Reset events to stop
         * @param {string[]} eventNames
         * @returns {Promise}
         */
        this.resetEvents = function (eventNames) {
            return API.patch(apiPath + '/event/reset', {
                names: eventNames,
            }, true)
                .then(function (res) {
                    return res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    return err;
                });
        };
    }
});

'use strict';
define([], function () {
    function Controller(
        $scope,
        $mdDialog,
        tokenKeywordList,
        keywordListName,
        KeywordListService,
        $state,
        __env,
        $window) {
        var apiBaseUrl = __env.frontEndUrl;
        var apiPath = apiBaseUrl +'/keyword-list-manager/shared/';
        
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.urlShared = apiPath + tokenKeywordList;
        $scope.keywordListName = keywordListName;

        $scope.shareFacebook = function() {
            $window.open("https://www.facebook.com/sharer/sharer.php?u=" + $scope.urlShared, "pop", "width=600, height=400, scrollbars=no");
        };

        $scope.shareTwitter = function() {
            $window.open("http://twitter.com/intent/tweet?" + $scope.urlShared, "pop", "width=600, height=400, scrollbars=no");
        };

        $scope.shareLinkedin = function() {
            $window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + $scope.urlShared, "pop", "width=600, height=400, scrollbars=no");
        };

        $scope.copyToClipboardOptions = {
            class: 'st-button__rounded--md--violet',
            text: 'keyword.exp.copy.clipboard',
            icon:'icon-copy',
            showToast: true,
            data: $scope.urlShared,
            mode: 'string',
        };

        function answerDialog(nameKeywordList) {
            $scope.loading.post = true;
            KeywordListService.updateKeywordList(nameKeywordList, keywordListId).then(function (res) {
                $state.reload();
                $scope.loading.post = false;
                $mdDialog.hide();
            }).catch(function (err) {
                $scope.loading.post = false; 
                $scope.duplicatedNameKeywordListError = true;
            });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }

    Controller.$inject = [
        '$scope',
        '$mdDialog',
        'tokenKeywordList',
        'keywordListName',
        'KeywordListService',
        '$state',
        '__env',
        '$window'
    ];
    return Controller;
});

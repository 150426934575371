'use strict';
define(['app'], function (app) {
    app.component('stTimer', {
        templateUrl: 'app/components/st-timer/st-timer.html',
        controller: controller,
        transclude: true,
        bindings: {
            start: '<',
            end: '<'
        }
    });
    controller.$inject = ['__env', '$scope', '$interval'];

    function controller(__env, $scope, $interval) {
        var vm = $scope;
        var ctrl = this;
        var timerCrawling;
        vm.timer = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }

        function msToTime(duration) {
            vm.timer.seconds = Math.floor((duration / 1000) % 60);
            vm.timer.minutes = Math.floor((duration / (1000 * 60)) % 60);
            vm.timer.hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
            vm.timer.days = Math.floor((duration / (1000 * 60 * 60)));
        }

        function timerSpiderCrawling() {
            vm.timer.seconds += 1;
            if (vm.timer.seconds === 60) {
                vm.timer.seconds = 0;
                vm.timer.minutes += 1;
            }
            if (vm.timer.minutes === 60) {
                vm.timer.seconds = 0;
                vm.timer.minutes = 0;
                vm.timer.hours += 1;
            }
            if (vm.timer.hours === 24) {
                vm.timer.seconds = 0;
                vm.timer.minutes = 0;
                vm.timer.hours = 0;
                vm.timer.days += 1;
            }
        }

        function resetTimer() {
            vm.timer = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        vm.$watch('$ctrl.start', function (start) {
            if (start) {
                $interval.cancel(timerCrawling);
                clearInterval(timerCrawling);
                resetTimer();
                msToTime(new Date().getTime() - new Date(start).getTime());
                timerCrawling = $interval(timerSpiderCrawling, 1000);
            }
        });

        vm.$watch('$ctrl.end', function (end) {
            if (end) {
                $interval.cancel(timerCrawling);
                clearInterval(timerCrawling);
                msToTime(new Date(end).getTime() - new Date(ctrl.start).getTime());
            }
        });
    }

});

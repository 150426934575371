define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.httpstatus",{
                controller:'SPHttpStatusCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/http-status/http-status.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.httpstatus.200",{
                url:"/http-status",
                templateUrl: './app/modules/seospider/exam/analysis/http-status/200/200.html',
                access: {restricted: true},
                controller:'SPHttpStatus200Ctrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            fields:['id','url','title','status','depth'],
                            status: 200
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.httpstatus.301",{
                url:"/http-status/redirect",
                templateUrl: './app/modules/seospider/exam/analysis/http-status/301/301.html',
                access: {restricted: true},
                controller:'SPHttpStatus301Ctrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllRedirect({
                            jobId:$stateParams.sessionId,
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.httpstatus.404",{
                url:"/http-status/errors",
                templateUrl: './app/modules/seospider/exam/analysis/http-status/404/404.html',
                access: {restricted: true},
                controller:'SPHttpStatus404Ctrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllError({
                            jobId:$stateParams.sessionId,
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});

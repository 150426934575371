define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.project", {
                abstract: true,
                url: "/project/",
                templateUrl: './app/modules/project/project.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.project.settings", {
                url: ":projectId/settings/:activeTab?:fromTool",
                controller: 'SettingsCtrl',
                templateUrl: './app/modules/project/settings/settings.html',
                access: {restricted: true},
                resolve: {
                    settings: ['$stateParams', 'ProjectsService', '$state',
                        function ($stateParams, ProjectsService, $state) {
                            return ProjectsService.getSettings($stateParams.projectId)
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.settings) {
                                        $state.go('app.project.wizard', {tool: 'rank-tracker', step: 'create-project'});
                                        return;
                                    }

                                    return res.data.settings;
                                })
                                .catch(function (err) {
                                    $state.go('app.project.wizard', {tool: 'rank-tracker', step: 'create-project'});

                                    console.log(err);
                                    return err.data;
                                });
                        }],
                },
            })
            .state("app.project.wizard", {
                url: "wizard/:tool/:step?:firstproject&:from&:url",
                controller: 'ProjectWizardCtrl',
                templateUrl: './app/modules/project/wizard/wizard.html',
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define([], function () {
    function Controller($scope, $state, $stateParams, LeadGenerationService, $mdToast, $translate, $element, settings) {
        var vm = $scope;
        vm.saveSettings = saveSettings;
        vm.settings = settings;
        vm.settings.autoLeadUnlock = !!vm.settings.autoLeadUnlock;
        if (vm.settings.whitelist == null) {
            vm.settings.whitelist = ""; 
        }
    
        function saveSettings(form) {
            if (form && form.name && !form.name.$valid) {
                showCustomToast('leadgeneration.form.name.not.valid', 'icon-x st-red');
                return;
            }

            var settingsData = {
                formId: $stateParams.formId,
                whitelist: vm.settings.whitelist,
                name: vm.settings.name,
                autoLeadUnlock: vm.settings.autoLeadUnlock
            }

            LeadGenerationService
                    .saveSettings(settingsData)
                    .then(function (res) {
                        showCustomToast('leadgeneration.settings.saved', 'icon-checkmark st-green');
                        $state.reload();
                    }, function (err) {
                        showCustomToast('settings.something.went.wrong', 'icon-x st-red');
                    });
        }

        function showCustomToast(string, classIcon, data) {
            var translation;
            if (data && data.email) {
                translation = $translate.instant(string, {email:data.email});
            } else {
                translation = $translate.instant(string);
            }
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + translation + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: $element[0].querySelector('.toast-show')
            });
        }
    }

    Controller.$inject = ['$scope', '$state', '$stateParams', 'LeadGenerationService', '$mdToast', '$translate', '$element', 'settings'];
    return Controller;
});

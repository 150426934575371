export default ['$scope', '$timeout', '$stateParams', '$state', '$sce', 'WriterAssistantService', 'article', function ($scope, $timeout, $stateParams, $state, $sce, WriterAssistantService, article) {
    var vm = $scope;
    vm.articleDataSet = {};
    vm.$stateParams = $stateParams;
    vm.steps = [{
        id: "CHOOSE_PRIMARY_KEYWORD",
        stepNumber: 1,
        stepLabel: 'Select Primary Keywords',
        preview: (article && article.country ? `<img class="flag-circle b m-l-x" style="width: 14px;height: 14px;"
                             src="/assets/img/modules/flags/${article.country.toLowerCase()}.png">
                             <span>${article.mainKeyword}</span>` : ''),
        setPreview: () => {
            return `<img class="flag-circle b m-l-x" style="width: 14px;height: 14px;"
                             src="/assets/img/modules/flags/${vm.articleDataSet.country.toLowerCase()}.png">
                             <span>${vm.articleDataSet.mainKeyword.toLowerCase()}</span>`;
        }, done: true, validation: () => vm.articleDataSet.mainKeyword && vm.articleDataSet.mainKeyword.length >= 3
    }, {
        id: "MARKET_ANALYSIS", stepNumber: 2, stepLabel: 'Analyze the market with AI', preview: null, done: false, disableStepsBarControl: true, validation: () => true
    }, {
        id: "COPY_STRATEGY",
        stepNumber: 3,
        stepLabel: 'Choose your reference',
        preview: null,
        done: false,
        stepBack: 1,
        hideBackButton: true,
        validation: () => vm.articleDataSet && vm.articleDataSet.marketAnalysis && vm.articleDataSet.marketAnalysis.totalFollowedStrategies && vm.articleDataSet.marketAnalysis.totalFollowedStrategies >= 1
    }, {
        id: "CONTENT_STRATEGY",
        stepNumber: 4,
        stepLabel: 'Define your content structure',
        preview: null,
        done: false,
        validation: () => true
    }, {
        id: "BRANDING",
        stepNumber: 5,
        stepLabel: 'Customize writing style',
        preview: null,
        done: false,
        validation: () => true
    }, {
        id: "HEADINGS_STRUCTURE",
        stepNumber: 6,
        stepLabel: 'Choose headings',
        preview: null,
        done: false,
        validation: () => vm.articleDataSet.headings && vm.articleDataSet.headings.length > 0 && vm.articleDataSet.headings[0].value && vm.articleDataSet.headings[0].value.length > 0
    }, {
        id: "GENERATE_ARTICLE",
        stepNumber: 7,
        stepLabel: 'Generate with AI',
        preview: null,
        done: false,
        disableStepsBarControl: true,
        validation: () => true,
        hideIf: function () {
            return vm.articleDataSet.mode !== 'WRITE_WITH_AI';
        }
    },];

    function getStep(article) {
        if (!article) {
            return 1;
        }

        if (!article.marketAnalysis) {
            return 2;
        }

        if (!article.marketAnalysis.totalFollowedStrategies && !article.contentStrategy) {
            return 3;
        }

        if (!article.contentStrategy) {
            return 4;
        }

        if (!article.aiHeadingGeneratedAt) {
            return 5;
        }

        if (!article.aiContextGeneratedAt) {
            return 6;
        }

        return 3;
    }

    let currentStep = 1;

    try {

        vm.articleDataSet = WriterAssistantService.getDefaultArticleDataSetModel($stateParams);

        if (article) {
            vm.articleDataSet = article;
            currentStep = getStep(article);
        }

        if ($stateParams.country) {
            vm.articleDataSet.country = $stateParams.country;
        }
        if ($stateParams.language) {
            vm.articleDataSet.language = $stateParams.language;
        }
        if ($stateParams.mainKeyword) {
            vm.articleDataSet.mainKeyword = $stateParams.mainKeyword;
        }
        if ($stateParams.device) {
            vm.articleDataSet.device = $stateParams.device;
        }

        if ($stateParams.mode) {
            vm.articleDataSet.mode = $stateParams.mode;
        }

    } catch (err) {
        console.error(err);
    }

    vm.wizardHandler = {
        currentStep,
        getCurrentStep,
        stepNext,
        stepBack,
        loading: true,
        completeWizard: async () => {
            if (vm.articleDataSet.mode !== 'WRITE_WITH_AI') {
                await WriterAssistantService.updateArticle(vm.articleDataSet.id, vm.articleDataSet);
            }

            $state.go('app.writerassistant.editor', {
                id: vm.articleDataSet.id
            });
        }
    };

    $timeout(() => {
        vm.wizardHandler.loading = false;
    }, 300);

    vm.isCurrentStep = function (step) {
        return step.stepNumber === vm.wizardHandler.currentStep;
    };


    function getCurrentStep() {
        return vm.steps[vm.wizardHandler.currentStep - 1];
    }

    vm.getCurrentStep = getCurrentStep;


    function stepBack() {
        if (vm.getCurrentStep().stepBack) {
            vm.wizardHandler.currentStep = vm.getCurrentStep().stepBack;
            return;
        }
        if (vm.wizardHandler.currentStep >= 2) {
            vm.wizardHandler.currentStep--;
        }
    }


    async function stepNext() {
        try {
            if (vm.wizardHandler.currentStep < getTotalNumberOfStepsInWizard()) {
                if (vm.getCurrentStep().setPreview) {
                    vm.getCurrentStep().preview = $sce.trustAsHtml(vm.getCurrentStep().setPreview());
                }
                vm.wizardHandler.currentStep++;
                vm.$apply();
            } else {
                await vm.wizardHandler.completeWizard();
            }
        } catch (err) {
        }
    }

    vm.stepBack = stepBack;
    vm.stepNext = stepNext;

    $scope.getTotalNumberOfStepsInWizard = getTotalNumberOfStepsInWizard;

    function getTotalNumberOfStepsInWizard() {
        let count = 0;
        for (let i = 0; i < vm.steps.length; i++) {
            if (!vm.steps[i].hasOwnProperty('hideIf') || !vm.steps[i].hideIf()) {
                count++;
            }
        }
        return count;
    }
}];

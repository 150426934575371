'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/access-components/st-reached-limit/st-reached-limit.html',
        bindings: {
            title: '@',
            subtitle: '@',
            description: '@',
            errorType: '@',
            isWorkspaceOwner: '<',
            data: '<'
        },
        controller: ['$scope', '$state', '$stateParams', '$mdMedia', '$window', 'LanguageService',
            function ($scope, $state, $stateParams, $mdMedia, $window, LanguageService) {
                $scope.cancel = $scope.$parent.cancel;
                $scope.goToChoosePlan = goToChoosePlan;
                $scope.goToContactUs = goToContactUs;
                $scope.$mdMedia = $mdMedia;

                function goToChoosePlan() {
                    $scope.$parent.cancel();
                    $state.go('upgrade.choose-plan');
                }

                function goToContactUs() {
                    $scope.$parent.cancel();
                    var language = LanguageService.getCodeAndCountrySaveOnStorage();
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/contatti", '_blank');
                    } else if (language === 'es-ES') {
                        $window.open(" https://es.seotesteronline.com/contacts", '_blank');
                    } else {
                        $window.open("https://www.seotesteronline.com/contacts", '_blank');
                    }
                }
            }]
    };
});

'use strict';
define([
    'angular',
    'modules/keywordtracker/landing/landing.controller'
], function (angular, KeywordTrackerLandingCtrl) {
    var app = angular.module('app.keywordtracker.landing', []);

    app.controller('KeywordTrackerLandingCtrl', KeywordTrackerLandingCtrl);

    return app;
});

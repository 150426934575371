'use strict';
define([], function () {
    controller.$inject = ['$scope'];

    function controller($scope) {
        const vm=this;
        const colors = [
            '#049961',
            '#4ca74d',
            '#91b73a',
            '#c5be22',
            '#eebd00',
            '#ffad02',
            '#fe8b04',
            '#fc6214',
            '#ff0f00',
            '#cc0605',
        ].reverse();

        $scope.chartData = [0, 100]; // Initial score data
        $scope.chartLabels = ['Score', 'Remaining'];

        // Doughnut chart options
        $scope.chartOptions = {
            /*
                   circumference: 1.5 * Math.PI,
                   rotation: -1.25 * Math.PI,
                   circumference: 1.45*Math.PI,
                   rotation: 0.78*Math.PI,
               */
            circumference: 1.35*Math.PI,
            rotation: 0.83*Math.PI,
            cutoutPercentage: 60,
            /*
            legend: {
                display: true
            },
            */

            elements: {
                arc: {
                    borderWidth: 1
                }
            },
            tooltips: {
                enabled: false
            },

            maintainAspectRatio: false,
            legend: { display: false },   // Disable legend
        };

        // Dynamically set the color based on score
        $scope.getChartColors = function(score) {
            const index = Math.min(Math.floor(score / 10), colors.length - 1);
            return [colors[index], '#e0e0e0']; // Set dynamic color and grey for remaining
        };

        // Function to update the score in the doughnut chart
        $scope.updateChart = function(score) {
            $scope.chartData = [score, 100 - score]; // Update chart data with score
            $scope.chartColors = $scope.getChartColors(score); // Update chart colors
        };


        // Access `mainKeyword` in the controller
        vm.$onInit = () => {

            // Dynamically watch the score and update the chart
            $scope.$watch('$ctrl.score', function (newScore) {
                if (newScore !== undefined) {
                    $scope.updateChart(newScore);
                }
            });

        };


    }
    return {
        templateUrl: 'app/components/st-chart-score/st-chart-score.html',
        bindings: {
            score: '<',
        },
        controller: controller
    };
});

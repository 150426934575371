'use strict';
define([], function() {
    function SinglePageBodyController($scope) {
        $scope.evalLength=evalLength;
        function evalLength(len) {
            len=parseInt(len)||0;
            if (len >70)
                return 'red-text';
            if (len >15&&len<=70)
                return 'green-text';
            if (len >=5&&len<=15)
                return 'text-darken-1 orange-text';
            return 'red-text';
        }
    }
    SinglePageBodyController.$inject=['$scope'];
    return SinglePageBodyController;
});

define([
    'angular',
    'modules/dashboard/components/shortcut-box/shortcut-box.component',
    'modules/dashboard/components/latest-feature/latest-feature.component'
],function(angular, shortcutBox,latestFeature){
    var app=angular.module('app.dashboard.ui-components', []);
    app.component('shortcutBox', shortcutBox);
    app.component('latestFeature', latestFeature);
    return app;
});

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/headings/headings.html',
    bindings: {
        loading: '=',
        articleDataSet: '=',
        contentStrategy: "=",
        marketAnalysis: "=",
        headings: "=",
        currentStep: "=",
        mainKeyword: "=",
        device: "=",
        country: "=",
        language: "=",
        articleId: "<"
    },
    controller: ['$scope', '$timeout', 'WriterAssistantService', function ($scope, $timeout, WriterAssistantService) {
        const vm = this;
        $scope.loading = true;
        $scope.editable = true;

        async function generateHeadingsWithRetry(maxRetries = 2) {
            let retries = 0;

            while (retries < maxRetries) {
                try {
                    const result = await WriterAssistantService.generateHeadingsWithAI(
                        vm.articleId,
                        vm.mainKeyword,
                        vm.language,
                        vm.contentStrategy.headings.value
                    );

                    if (result && result.length > 0) {
                        return result;
                    } else {
                        console.log('No headings generated, retrying...');
                    }
                } catch (error) {
                    console.error('Error during heading generation:', error);
                }

                retries++;
                console.log(`Attempt ${retries} of ${maxRetries}`);
            }

            console.error('Unable to generate headings after maximum number of attempts');
            return WriterAssistantService.generateHeadingsUsingCommonTitles(
                vm.mainKeyword,
                vm.marketAnalysis
            );
        }

        vm.$onInit = async function () {
            if (!vm.articleDataSet.headings || vm.articleDataSet.headings.length <= 0) {
                vm.articleDataSet.headings = [];
            }

            if (vm.articleDataSet.headings.length <= 0) {
                vm.articleDataSet.headings = await generateHeadingsWithRetry(2);
            }

            $scope.loading = false;
            $scope.$apply();
        };
    }]
};

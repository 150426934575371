'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/spider-card/spider-card.html',
        bindings: {
            project: '<',
            type: '<',
            isLoading: '<'
        },
        controller: ['$scope', 'FavIconService', '$state', '$mdDialog', 'SeospiderService', '$timeout', 'TrackEventService', '$mdToast', '$translate',
            function ($scope, FavIconService, $state, $mdDialog, SeospiderService, $timeout, TrackEventService, $mdToast, $translate) {
                var vm = $scope;
                vm.$watch('$ctrl.project', function (project) {
                    vm.project = project;
                    if (project && project.crawlerSession
                        && (project.crawlerSession.status === 'running' || project.crawlerSession.status === 'deleting')) {
                        nextLoad(2500);
                    }
                });

                vm.getFavIcon = FavIconService.get;
                vm.startCrawl = startCrawl;
                vm.goToProject = goToProject;

                function startCrawl(projectId, ev) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    var params = {};
                    params.id = projectId;
                    SeospiderService.update(params)
                        .then(function (res) {
                            TrackEventService.event('Suite Usages', 'SEO Project - Start Crawl');
                            TrackEventService.gaV2Event('crawl_start');
                            if (vm.project.crawlerSession) {
                                vm.project.crawlerSession.status = res.status;
                            }
                            getData();

                        })
                        .catch(function (err) {
                            vm.timer = {
                                hours: 0,
                                minutes: 0,
                                seconds: 0
                            }

                            if (err.message === 'Invalid url') {
                                $mdToast.show({
                                    template:
                                        '<md-toast layout="row" flex class="md-toast">' +
                                        '   <div><i class="icon-x st-red m-r-sm"></i></div>' +
                                        '   <div>' + $translate.instant('error.invalid.website') + '</div>' +
                                        '</md-toast>',
                                    hideDelay: 5000,
                                    position: 'top right'
                                });
                            }

                            console.log(err);
                        });
                }

                function goToProject(projectId, sessionId) {

                    $state.go('app.seospider.exam.analysis.report.overview', {
                        projectId: projectId,
                        sessionId: sessionId,
                    });
                }

                var cancelNextLoad = function () {
                    $timeout.cancel(loadPromise);
                };

                var nextLoad = function (delay) {
                    //Always make sure the last timeout is cleared before starting a new one
                    cancelNextLoad();
                    loadPromise = $timeout(getData, delay);
                };

                var loadPromise;
                var getData = function () {
                    SeospiderService.findJobs({})
                        .then(function (projects) {
                            if (!projects || projects.length <= 0) {
                                return;
                            }

                            vm.project = projects.find(function (item) {
                                return item.id === vm.project.id;
                            });

                            if (vm.project
                                && vm.project.crawlerSession
                                && vm.project.crawlerSession.status
                                && (
                                    vm.project.crawlerSession.status === 'running'
                                    || vm.project.crawlerSession.status === 'deleting'
                                )) {
                                nextLoad(2500);
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                            cancelNextLoad();
                        });
                };
            }]
    };
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.goToWorkspace = goToWorkspace;
        $scope.loading = {
            post: false
        };


        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }

        function goToWorkspace() {
            hideDialog();
        }
    }
    Controller.$inject = ['$scope', '$mdDialog'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/canonical/components/table.component'
],function(
    angular,
    canonicalTable
){
    var app=angular.module('app.seospider.exam.analysis.canonical.components',
        []
    );
    app.component("canonicalTable",canonicalTable);
    return app;
});
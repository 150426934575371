'use strict';
define(['app'], function (app) {
    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(['$injector', '$q', function ($injector, $q) {
            return {
                responseError: function (error) {
                    var isPlatformLimitDialog = error.status === 429
                        && error.data
                        && error.data.error
                        && error.data.error.request !== undefined;

                    if (isPlatformLimitDialog) {
                        // injected manually to get around circular dependency problem.
                        var $state = $injector.get('$state');

                        if (!error || !error.data || !error.data.error || !error.data.error.request || !error.data.error.request.alias) {
                            return;
                        }

                        if (error.data.error.request.alias === 'ai-credits') {
                            var addOnService = $injector.get('addOnService');
                            addOnService.open(error.data.error.request.alias);
                            return;
                        }

                        var stAlertLimitService = $injector.get('stAlertLimitService');
                        error.data.canClose = false;

                        // Override to improve tracking
                        if (error.data.error.request.alias === 'analysis' && $state.current && $state.current.name) {
                            if ($state.current.name.indexOf('app.seochecker') >= 0) {
                                error.data.error.request.alias = 'seo-checker-audit';
                            }

                            if ($state.current.name.indexOf('app.keywordexplorer') >= 0) {
                                error.data.error.request.alias = 'keyword-explorer-searches';
                            }

                            if ($state.current.name.indexOf('app.copymetrics') >= 0) {
                                error.data.error.request.alias = 'copymetrics-audit';
                            }

                            if ($state.current.name.indexOf('app.serpchecker') >= 0) {
                                error.data.error.request.alias = 'serp-report-audit';
                            }
                        }

                        stAlertLimitService.open('auth-login', error.data);
                    }

                    return $q.reject(error);
                }
            };
        }]);
    }]);
});

'use strict';
define([
    'angular',
    'modules/leadgeneration/form/overview/overview.controller',
], function (
    angular,
    LeadGenerationFormOverviewCtrl) {
    var app = angular.module('app.leadgeneration.form.overview', []);
    app.controller('LeadGenerationFormOverviewCtrl', LeadGenerationFormOverviewCtrl);
    return app;
});

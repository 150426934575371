'use strict';
define([], function () {
    function Controller($scope, $mdDialog, user, WorkspaceService,workspaceId) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.user = user;

        function answerDialog(answer) {
            $scope.loading.post = true;
            if(user.confirmedAt){
                deleteUserFromWorkspace();
            }else{
                deleteUserInvitation();
            }
        }

        function deleteUserInvitation(){
            WorkspaceService.deleteUserInvitationFromWorkspace(user.email).then(function (res) {
                hideDialog();
            }).catch(function (err) {
                $scope.loading.post = false;
                console.log(err)
            })
        }

        function deleteUserFromWorkspace(){
            WorkspaceService.deleteUserFromWorkspace({workspaceId:workspaceId,userId:user.userId}).then(function (res) {
                hideDialog();
            }).catch(function (err) {
                $scope.loading.post = false;
                console.log(err)
            })
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject = ['$scope', '$mdDialog', 'user', 'WorkspaceService','workspaceId'];
    return Controller;
});

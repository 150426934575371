'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/canonical/all/all.controller',
],function(
    angular,
    SPCanonicalAllCtrl
){
    var app=angular.module('app.seospider.exam.analysis.canonical.all', []);
    app.controller("SPCanonicalAllCtrl",SPCanonicalAllCtrl);
    return app;
});
'use strict';
define([], function () {
    function controller(__env, $scope) {
        $scope.trialPeriodDays = __env.trialPeriodDays;
    }

    controller.$inject = ['__env', '$scope'];
    return {
        templateUrl: 'app/modules/settings/components/subscriptionsNotPresents/alert-subscriptions-not-presents.html',
        controller: controller,
    };
});

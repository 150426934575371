'use strict';
define(['app'], function (app) {
    app.component('stIcon', {
        templateUrl: 'app/components/st-icon/st-icon.html',
        controller: controller,
        bindings: {
            info: "<",
            size: "@",
            addEditProfile: "<"
        }
    });
    controller.$inject = ['$scope'];

    function controller($scope) {
        var ctrl = this;
        var colors = ['#FFC700', '#3AB526', '#0BC7B5', '#0099FF', '#9600FF', '#CC00AE', '#FF0066', '#FE4A53'];
        var letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

        $scope.$watch('$ctrl.info',function(info){
            ctrl.info = info;
            $scope.backgroundIcon = setBg(info);
        })
        $scope.$watch('$ctrl.info.string',function(info){
            $scope.backgroundIcon = setBg(ctrl.info);
        })
        function setBg (info) {
            if (!info) return { "background": "#FFC700" };
            if (info.logoImgUrl) return {
                "background-image": "url(" + info.logoImgUrl + ")",
                "background-repeat": "no-repeat",
                "background-size": "cover",
                "background-position": "center",
                "border-radius":"0%"
            };

            if (info.string) {
                var firstLetter = info.string.charAt(0).toLowerCase();
                if (letters.indexOf(firstLetter) >= 0 && letters.indexOf(firstLetter) <= 3) {
                    return {
                        "background": "rgb(255 199 0 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 3 && letters.indexOf(firstLetter) <= 6) {
                    return {
                        "background": "rgb(58 181 38 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 6 && letters.indexOf(firstLetter) <= 9) {
                    return {
                        "background": "rgb(11 199 181 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 9 && letters.indexOf(firstLetter) <= 12) {
                    return {
                        "background": "rgb(0 153 255 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 12 && letters.indexOf(firstLetter) <= 15) {
                    return {
                        "background": "rgb(150 0 255 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 15 && letters.indexOf(firstLetter) <= 18) {
                    return {
                        "background": "rgb(255 127 80 / 79%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 18 && letters.indexOf(firstLetter) <= 21) {
                    return {
                        "background": "rgb(204 0 174 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 21 && letters.indexOf(firstLetter) <= 24) {
                    return {
                        "background": "rgb(255 0 102 / 50%)",
                    }
                }
                if (letters.indexOf(firstLetter) > 24 && letters.indexOf(firstLetter) <= 27) {
                    return {
                        "background": "rgb(58 181 38 / 50%)",
                    }
                }
            }

        }
    }
});

/**
 * Created by Vittorio Urzì on 09/08/2017.
 */
'use strict';
define(['app'], function (app) {
    app.service('GrammarService', GrammarService);
    GrammarService.$inject = ['$http', '$sce'];

    function GrammarService($http, $sce) {
        var apiEndPoint = 'https://languagetool.org/api/v2/check';
        return {
            check: check
        };

        function check(params) {
            return $http({
                url: $sce.trustAsResourceUrl(apiEndPoint),
                method: "POST",
                data: $.param(params),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
                .then(function (response) {
                        return response.data;
                    },
                    function (response) { // optional
                        // failed
                        console.log('Errore on suggestion', response);
                    });
        }
    }
});

'use strict';
define(['angular'],function(angular){

    var controller=function($scope,$timeout) {
        var $ctrl = this;
        var vm=$scope;

        vm.countdown = {
            init: false,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };


        $ctrl.internalOnChange = function(changedValue) {

        };

        function timer(countDownDate){
            $timeout(function () {
                // Get today's date and time
                var now = new Date().getTime();
                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                vm.countdown = {
                    init: true,
                    days: days,
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds,
                };
                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance > 0) {
                    timer(countDownDate);
                }
            }, 1000);
        }

        $ctrl.$onInit = function() {
            if($ctrl.countdownTo)
                timer($ctrl.countdownTo);
        };

        $ctrl.$postLink = function(){


        };


    };
    controller.$inject = ['$scope','$timeout'];
    return {
        templateUrl: 'app/components/st-countdown/st-countdown.html?hash=xxre988',
        bindings: { countdownStart: '<' , countdownTo: '=' },
        controller: controller
    };
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/access-components/st-plan-detail/st-plan-detail.html',
        bindings: {
            userIsOffline: '<',
            canDoTrial: '<',
            plan: '<',
            dialogMode: '<'
        },
        controller: ['$scope', '$state',
            function ($scope, $state) {
                $scope.data = {
                    group: 'freeplan'
                };
                $scope.goToCheckout = goToCheckout;

                function goToCheckout(alias) {
                    var coupon = '';

                        $scope.cancel();


                    if (alias) {
                        $state.go('upgrade.checkout', {
                            alias: alias,
                            coupon: coupon
                        });
                    }
                }
            }],


    };
});

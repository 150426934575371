'use strict';
define(['app', 'jquery'], function (app, $) {
    app.service('ArticleAuditorService', ArticleAuditorService);
    ArticleAuditorService.$inject = ['TextStatistics', '$q', '$translate', 'KeywordDensityService'];

    function ArticleAuditorService(TextStatistics, $q, $translate, KeywordDensity) {
        var self = this;
        self.stopWords = ['a', 'an', 'are', 'adesso', 'ai', 'al', 'all', 'alla', 'allo', 'allora', 'altre', 'altri', 'altro',
            'anche', 'ancora', 'avere', 'aveva', 'avevano', 'avrai', 'ben', 'buono', 'che', 'chi', 'cinque', 'comprare',
            'con', 'cosa', 'come', 'cui', 'da', 'dei', 'del', 'dell', 'devi', 'dalla', 'della', 'dello', 'dentro', 'deve', 'devo', 'di', 'doppio', 'due',
            'e', 'ecco', 'fare', 'for', 'fine', 'fino', 'fra', 'gente', 'giu', 'ha', 'hai', 'hanno', 'ho', 'il',
            'indietro', 'invece', 'io', 'is', 'la', 'le', 'lei', 'lo', 'loro', 'lui', 'lungo', 'ma', 'me', 'mi',
            'meglio', 'molta', 'molti', 'molto', 'nei', 'nell', 'nella', 'nelle', 'nello', 'no', 'noi', 'nome', 'non', 'nostro', 'nove', 'nuovi',
            'nuovo', 'o', 'ogni', 'oltre', 'ora', 'otto', 'peggio', 'per', 'pero', 'piu', 'puoi', 'poco', 'primo',
            'promesso', 'qua', 'quarto', 'quasi', 'quattro', 'quale', 'quello', 'questo', 'qui', 'quindi', 'quinto',
            'rispetto', 'sara', 'secondo', 'sei', 'sembra', 'sembrava', 'senza', 'sette', 'sia', 'siamo', 'siete',
            'solo', 'sono', 'sopra', 'sotto', 'stati', 'stato', 'stesso', 'sto', 'su', 'sul', 'sull', 'sulla', 'sulle', 'tanto',
            'te', 'tempo', 'terzo', 'there', 'that', 'this', 'the', 'tra', 'tre', 'triplo', 'tuo', 'tutte', 'tutti', 'tutto', 'ultimo', 'un', 'una', 'uno', 'va',
            'vai', 'voi', 'volte', 'vostro', 'vostri', 'var', 'www', 'will', 'cos'];
        optimize();
        return {
            audit: audit
        };

        function optimize() {
            var tmp = {};
            for (var i = 0; i < self.stopWords.length; i++) {
                tmp[self.stopWords[i]] = '';
            }
            self.stopWords = tmp;
        }

        function cleanText(text) {
            return text.toLowerCase()
                .replace(/\s[a-z]{1,2}\s/g, " ")
                .replace(/[^a-zA-Zéèòàùì]/g, " ")
                .replace("\s{2,}", " ").trim();
        }

        function audit(params) {
            params.text = params.text.replace(/&amp;/ig, "&");
            return new $q(function (resolve, reject) {
                if (!params) return resolve({});
                var textualContent = params.title + " " + params.text;
                var res = {};

                var stats = TextStatistics.textStat(params.text);
                KeywordDensity.setText(textualContent);
                res.density = {
                    oneWord: KeywordDensity.oneWord(),
                    twoWords: KeywordDensity.twoWords(),
                    threeWords: KeywordDensity.threeWords(),
                    fourWords: KeywordDensity.fourWords()
                };
                var allKeywords = [];
                allKeywords.push(params.keywords.main);
                allKeywords = allKeywords.concat(params.keywords.secondaries);
                legibilityScore(stats, res);
                titleScore(params.title, params.keywords, res);
                contentScore(params.html, params.keywords, res);
                headingsScore(params.html, params.keywords, res);
                miscellaneousScore(params.html, params.text, allKeywords, res);
                res.average = calculateTotal(res, true);
                resolve(res);
            });
        }


        function headingsScore(t, kws, res) {
            if (t == undefined) t = '';
            t = t.toLowerCase();

            // title<h1[^>]*>[^<]*?<\/h1[^>]*>
            var h1 = t.match(/<h1[^>]*>[^]*?<\/h1>/gm) || [];
            var h1Words = checkWordCount(h1, 2);
            var h1Kws = 0;

            var validH1 = true;
            for (var i = 0; i < h1.length; i++) {
                var current = h1[i].replace(/<[^>]*>/g, "");
                validH1 = current.length ? validH1 : false;
            }
            h1 = validH1 ? h1 : [];
            var h1Count = h1.length;

            var h2 = t.match(/<h2[^>]*>[^]*?<\/h2>/gm) || [];
            var h2Count = h2.length;

            // Others
            var h36 = t.match(/(<h3>)|(<h4>)|(<h5>)|(<h6>)/g) || [];
            var h36Count = h36.length;
            var h26 = h2.concat(h36);
            var h26Kws = 0;

            // KWS in headings
            kws.secondaries.forEach(function (tag) {
                if (h26.length)
                    h26.forEach(function (h) {
                        h26Kws += h.indexOf(tag) != -1 ? 1 : 0;
                    });
            });
            if (h1.length == 1)
                h1Kws = matchMainKeyword(h1[0], kws.main);
            else h1Kws = 0;
            var headings = {
                value: 0,
                h1: {value: h1Count, score: 0},
                h1Kws: {value: h1Kws, score: 0},
                h1Words: {value: h1Words, score: 0},
                h2: {value: h2Count, score: 0},
                total:h1Count+h2Count,
                helpers: []
            };

            res.headings = headings;
            // H1
            buildSuggest(res, 'headings', 'h1', distance(headings.h1.value, [1, 1]) * 1, 'Add 1 H1 tag to your page');
            buildSuggest(res, 'headings', 'h1Kws', distance(headings.h1Kws.value, [2, 100]) * 1, 'Add your keyword more often within a H1 / Header 1 tag.');
            buildSuggest(res, 'headings', 'h1Words', distance(headings.h1Words.value, [2, 100]) * 1, 'Not all H1 tags have the minimum number of words in it. Use at least 2 words in each H1 / Header 1 tag.');

            // h2-6
            buildSuggest(res, 'headings', 'h2', distance(headings.h2.value, [2, 100]) * 1, 'Add at least 2 H2 tag');
            /*buildSuggest(res,'headings', 'h36', distance(headings.h36.value, [4, 100]) * 1, 'Consider adding one or more H3-H6 tag(s) to your page.');
            buildSuggest(res,'headings', 'h26Kws', distance(headings.h26Kws.value, [1, 100]) * 1, ' Consider adding the main keyword or related keyword in your h2-h6 tags.');
    */
            res.headings.value = calculateTotal(headings);
            res.headings = headings;

            ////////

            function checkWordCount(array, min) {
                var output = true;
                array.forEach(function (t) {
                    if (escapeHtml(t).split(' ').length < min)
                        output = false;
                });
                return (output && array.length) ? 2 : 0;
            }
        }

        function titleScore(t, kws, res) {
            if (t == undefined) t = '';
            t = t.toLowerCase();
            var title = {
                value: 0,
                coherence: {score: 0},
                chars: {value: t.length, score: 0},
                kws: {value: 0, score: 0},
                setted: {value: 0, score: 0},
                helpers: []
            };
            var titleKeywords = cleanText(t).split(/\s{1,}/g) || [];
            var point = 0;
            var total = 0;
            var j;
            for (var i = 0; i < titleKeywords.length; i++) {
                if (self.stopWords[titleKeywords[i]] == undefined && titleKeywords[i].length > 1) {
                    total++;
                    j = 0;
                    while (j < res.density.oneWord.density.length) {
                        if (res.density.oneWord.density[j].word == titleKeywords[i]
                            && res.density.oneWord.density[j].count > 1) {
                            point++;
                            j = res.density.oneWord.density.length + 1;
                        }
                        j++;
                    }
                }
            }
            var scoreTitleCoherence;
            if (total > 0) {
                scoreTitleCoherence = ((point / total) * 100);
            } else scoreTitleCoherence = 0;
            // Kw presence
            res.title = title;
            if (t.length > 2) {
                buildSuggest(res, 'title', 'coherence', scoreTitleCoherence);
                buildSuggest(res, 'title', 'kws', matchMainKeyword(t, kws.main));
                buildSuggest(res, 'title', 'chars', title.chars.value > 70 ? 0 : 100);
                buildSuggest(res, 'title', 'setted', title.chars.value <= 2 ? 0 : 100);
            } else {
                buildSuggest(res, 'title', 'coherence', 0);
                buildSuggest(res, 'title', 'kws', 0);
                buildSuggest(res, 'title', 'chars', 0);
                buildSuggest(res, 'title', 'setted', 0);
            }
            title.value = calculateTotal(title);
            res.title = title;
            return title;
        }

        function contentScore(t, kw, res) {
            var kws = [];
            kws.push(kw.main);
            kws = kws.concat(kw.secondaries);
            t = t.toLowerCase();
            var links = t.match(/<a.*?>.*?<\/a>/g) || [];
            var images = t.match(/<img.*?>/g) || [];
            // Kws
            var kwCount = 0;
            //var kwNotFirst = [];
            var primaryKeyword = [];
            var secondariesKeywords = [];
            primaryKeyword = primaryKeyword.concat(keywordInText('word', res.density.oneWord.density, kw.main));
            primaryKeyword = primaryKeyword.concat(keywordInText('word', res.density.twoWords.density, kw.main));
            primaryKeyword = primaryKeyword.concat(keywordInText('word', res.density.threeWords.density, kw.main));
            primaryKeyword = primaryKeyword.concat(keywordInText('word', res.density.fourWords.density, kw.main));
            if (primaryKeyword.length == 0) {
                primaryKeyword.push({word: kw.main, count: 0, percentage: 0});
            }
            for (var i = 0; i < kw.secondaries.length; i++) {
                var secondariesKeywordsOne = keywordInText('word', res.density.oneWord.density, kw.secondaries[i]);
                var secondariesKeywordsTwo = keywordInText('word', res.density.twoWords.density, kw.secondaries[i]);
                var secondariesKeywordsThree = keywordInText('word', res.density.threeWords.density, kw.secondaries[i]);
                var secondariesKeywordsFour = keywordInText('word', res.density.fourWords.density, kw.secondaries[i]);
                if (secondariesKeywordsOne.length == 0 && secondariesKeywordsTwo.length == 0 &&
                    secondariesKeywordsThree.length == 0 && secondariesKeywordsFour.length == 0) {
                    secondariesKeywords.push({word: kw.secondaries[i], count: 0, percentage: 0});
                } else {
                    secondariesKeywords = secondariesKeywords.concat(secondariesKeywordsOne);
                    secondariesKeywords = secondariesKeywords.concat(secondariesKeywordsTwo);
                    secondariesKeywords = secondariesKeywords.concat(secondariesKeywordsThree);
                    secondariesKeywords = secondariesKeywords.concat(secondariesKeywordsFour);
                }
            }
            var content = {
                value: 0,
                words: {value: res.legibility.numberWords, score: 0},
                links: {value: links.length, score: 0},
                images: {value: images.length, score: 0},
                // densityH: {value: kwdHigh.length, score: 0},
                // densityL: {value: kwdLow.length, score: 0},
                primaryKw: {value: primaryKeyword, score: 0},
                secondariesKw: {value: secondariesKeywords, score: 0},
                keywordStuffing: {value: kwCount, score: 0},
                //kwNotFirst: {value: kwNotFirst.length, score: 0},
                helpers: []
            };
            res.content = content;
            buildSuggest(res, 'content', 'words', distance(content.words.value, [400, 5000]) * 1);
            buildSuggest(res, 'content', 'links', distance(links.length, [1, 200]) * 1);
            buildSuggest(res, 'content', 'images', distance(images.length, [1, 10]) * 1);
            primaryKeywordControl(res, kws);
            secondariesKeywordControl(res, kw.secondaries);
            keywordStuffingControl(res);

            //buildSuggest(res,'content', 'kwNotFirst', distance(kwNotFirst.length, [0, 0]) * 1);
            res.content.value = calculateTotal(content);
        }

        function legibilityScore(stats, res) {
            var fleschScore = 0;
            var gulpeaseScore = 0;
            var gunningFogScore = 0;
            var readability = {
                value: 0,
                helpers: [],
                flesch: {value: 0, score: 0},
                gulpease: {value: 0, score: 0},
                gunningFog: {value: 0, score: 0},
                numberWords: 0,
                readingTime: 0
            };
            if (stats.char > 2) {
                readability.flesch.value = stats.flesch;
                readability.gulpease.value = stats.gulpease;
                readability.gunningFog.value = stats.gunningFog;
                readability.numberWords = stats.word;
                readability.readingTime = stats.readingTime;
                fleschScore = 100;
                gulpeaseScore = 100;
                if (stats.flesch < 100)
                    fleschScore = distance(stats.flesch, [60, 100]) * 1;
                if (stats.gulpease < 100)
                    gulpeaseScore = distance(stats.gulpease, [60, 100]) * 1;
                gunningFogScore = distance(stats.gunningFog, [1, 12]) * 1;
                fleschScore = fleschScore > 100 ? 100 : fleschScore;
                gulpeaseScore = gulpeaseScore > 100 ? 100 : gulpeaseScore;
                gunningFogScore = gunningFogScore > 100 ? 100 : gunningFogScore;
            }
            res.legibility = readability;
            buildLegibility(res, 'legibility', 'flesch', fleschScore);
            //buildSuggest(res,'legibility', 'gulpease', gulpeaseScore);
            //buildSuggest(res,'legibility', 'gunningFog', gunningFogScore);
            buildInfo(res, 'legibility', 'numberWords', stats.word);
            buildInfo(res, 'legibility', 'readingTime', stats.readingTime);
            res.legibility.value = parseInt((fleschScore + gulpeaseScore + gunningFogScore) / 3);
        }

        function escapeHtml(html) {
            try {
                return html.replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{1,}/gm, ' ').trim();
            } catch (err) {
                return '';
            }
        }

        function miscellaneousScore(html, text, kws, res) {
            var imgs = html.match(/<img[^>]*>/g) || [];
            var strongTags = html.match(/<strong[^>]*>.*<\/strong>/g) || [];
            var emTags = html.match(/<em[^>]*>.*<\/em>/g) || [];
            var strongKwCount = 0;
            var emKwCount = 0;
            var imgsNotAlt = 0;
            imgs.forEach(function (tag) {
                if (tag.indexOf('alt') == -1) {
                    imgsNotAlt++;
                } else if (tag.indexOf('alt=""') != -1) {
                    imgsNotAlt++;
                }
            });
            kws.forEach(function (tag) {
                strongTags.forEach(function (b) {
                    b = b.toLowerCase();
                    strongKwCount += b.indexOf(tag) != -1 ? 1 : 0;
                });
                emTags.forEach(function (i) {
                    i = i.toLowerCase();
                    emKwCount += i.indexOf(tag) != -1 ? 1 : 0;
                });
            });
            res.misc = {
                value: 0,
                helpers: [],
                imgNotAlt: {value: imgsNotAlt, score: 0},
                strongTags: {value: strongTags.length, score: 0},
                italicTags: {value: emTags.length, score: 0},
                strongKwCount: {value: strongKwCount, score: 0},
                italicKwCount: {value: emKwCount, score: 0}
            };
            try {
                if (text && typeof text == 'string' && text.trim().length > 5) {
                    if (imgs.length)
                        buildSuggest(res, 'misc', 'imgNotAlt', distance(imgsNotAlt, [0, 0]) * 1);
                    else buildSuggest(res, 'misc', 'imgNotAlt', 0);
                    buildSuggest(res, 'misc', 'strongTags', distance(strongTags.length, [1, 100]) * 1);
                    buildSuggest(res, 'misc', 'italicTags', distance(emTags.length, [1, 100]) * 1);
                    buildSuggest(res, 'misc', 'strongKwCount', distance(strongKwCount, [1, 100]) * 1);
                    buildSuggest(res, 'misc', 'italicKwCount', distance(emKwCount, [1, 100]) * 1);
                } else throw ('not_string');
            } catch (err) {
                buildSuggest(res, 'misc', 'imgNotAlt', 0);
                buildSuggest(res, 'misc', 'strongTags', 0);
                buildSuggest(res, 'misc', 'italicTags', 0);
                buildSuggest(res, 'misc', 'strongKwCount', 0);
                buildSuggest(res, 'misc', 'italicKwCount', 0);
            }
            res.misc.value = calculateTotal(res.misc);
        }

        function buildSuggest(res, key, value, score) {
            var text = $translate.instant('seoscore.' + key + '.' + value);
            res[key][value].score = score;
            addHelper(res, key, score, text);
        }

        function buildLegibility(res, key, value, score) {
            var text = $translate.instant('seoscore.' + key + '.' + value);
            res[key][value].score = score;
            var scoreText = score.toFixed(0) + '' + $translate.instant('seoscore.' + key + '.' + 'totalFlesch');
            addInfo(res, key, text, scoreText);
        }

        function buildInfo(res, key, value, score) {
            var text = $translate.instant('seoscore.' + key + '.' + value);
            res[key][value] = score;
            addInfo(res, key, text, score);
        }

        function distance(val, con) {
            if (between(val, con))
                return 100;
            var medium = (con[1] - con[0]) / 2 + con[0];
            var res = Math.abs(medium - val) / (medium + val) * 100;
            return (res.toFixed(0) && ((100 - res.toFixed(0)) > 0)) ? (100 - res.toFixed(0)) : 0;
        }

        function between(val, con) {
            return (val >= con[0] && val <= con[1]);
        }

        function calculateTotal(obj, general) {
            var x = general ? 'value' : 'score';
            var tot = 0;
            var i = 0;
            for (var prop in obj) {
                if (typeof obj[prop][x] != 'undefined') {
                    tot += obj[prop][x] * 1;
                    i++;
                }
            }
            return (tot / i).toFixed(0);
        }

        function matchMainKeyword(title, mainKeyword) {
            if (mainKeyword == undefined || title == undefined) return 0;
            if (title.toLowerCase().indexOf(mainKeyword.toLowerCase()) != -1)
                return 100;
            return 0;
        }

        function primaryKeywordControl(res, kws) {
            var vote = 0;
            if (search('word', res.density.oneWord.density, kws[0]) || search('word', res.density.twoWords.density, kws[0]) ||
                search('word', res.density.threeWords.density, kws[0]) || search('word', res.density.fourWords.density, kws[0])) {
                vote = 100;
            }
            buildSuggest(res, 'content', 'primaryKw', vote * 1);
        }

        function secondariesKeywordControl(res, kws) {
            var vote = 0;
            var sum = 0;
            if (kws.length != 0) {
                for (var i = 0; i < kws.length; i++) {
                    if (search('word', res.density.oneWord.density, kws[i]) || search('word', res.density.twoWords.density, kws[i]) ||
                        search('word', res.density.threeWords.density, kws[i]) || search('word', res.density.fourWords.density, kws[i])) {
                        sum = sum + 1;
                    }
                }
                if (sum == (kws.length))
                    vote = 100;
            }
            buildSuggest(res, 'content', 'secondariesKw', vote * 1);
        }

        function keywordStuffingControl(res) {
            console.log(res.density);
            var oneWordInStuffing = searchKwStuffing(res, res.density.oneWord.density);
            var twoWordsInStuffing = searchKwStuffing(res, res.density.twoWords.density);
            var threeWordsInStuffing = searchKwStuffing(res, res.density.threeWords.density);
            var fourWordsInStuffing = searchKwStuffing(res, res.density.fourWords.density);
            if (oneWordInStuffing == 0 && twoWordsInStuffing == 0 && threeWordsInStuffing == 0 && fourWordsInStuffing == 0)
                res.content.keywordStuffing.score = 0;
            else res.content.keywordStuffing.score = 100;
        }

        function search(nameKey, myArray, value) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i][nameKey].toLowerCase() == value.toLowerCase()) {
                    return true;
                }
            }
            return false;
        }

        function searchKwStuffing(res, myArray) {
            var error = 0;
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].percentage >= 6.00) {
                    error++;
                    var text = $translate.instant('seoscore.content.keywordStuffing', {'option': myArray[i].word});
                    addHelper(res, 'content', 0, text);
                }
            }
            return error;
        }

        function addHelper(res, key, score, text) {
            var icon = score < 80 ? 'close' : 'done';
            var classe = score < 80 ? 'error' : 'success';
            var helper = text;
            if (res[key].helpers.indexOf(helper) == -1)
                res[key].helpers.push({
                    label:text,
                    score:score,
                });
        }

        function addLegibility(res, key, text, score) {

            var helper = '<span class="layout-row layout-align-start-center">' +
                '<i class="material-icons md-body-1 st-grey-3 md-icon m-r">info_outline</i>' +
                '<div class="layout-column md-caption">' +
                '<span class="f-w-500 m-0 st-blue">' + text + '</span>' +
                '<span class="st-grey-3">' + score + '</span>' +
                '</div>' +
                '</span>';

            if (res[key].helpers.indexOf(helper) === -1) {
                res[key].helpers.push(helper);
            }
        }

        function addInfo(res, key, text, score) {

            var helper = '<span class="layout-row layout-align-start-center">' +
                '<i class="material-icons md-body-1 st-grey-3 md-icon m-r">info_outline</i>' +
                '<div class="layout-column md-caption">' +
                '<span class="f-w-500 m-0 st-blue">' + text + '</span>' +
                '<span class="st-grey-3">' + score + '</span>' +
                '</div>' +
                '</span>';

            if (res[key].helpers.indexOf(helper) === -1) {
                res[key].helpers.push(helper);
            }
        }

        function keywordInText(nameKey, myArray, value) {
            var result = [];
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i][nameKey].trim().toLowerCase() == value.trim().toLowerCase()) {
                    result.push(myArray[i]);
                }
            }
            return result;
        }
    }
});

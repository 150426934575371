'use strict';
define(['jquery'], function ($) {
    function Controller(
        __env,
        $q,
        $scope,
        $window,
        $localStorage,
        KeywordExplorerService,
        SERPChecker,
        KeywordListService,
        AuthService,
        UserService,
        ExportService,
        $sce,
        $templateRequest,
        $state,
        $stateParams,
        $mdToast,
        $translate,
        userInfo,
        ErrorMessageService,
        LanguageService,
        GoogleSuggestionsService,
        $rootScope,
        TrackEventService,
        stToggleExpand,
        stLimitMessage
    ) {
        var CUSTOM_TEMPLATE_FOR_EMPTY_RESULT = '<div class="max-w-lg p-xl">' +
            '<i class="material-icons st-grey-0 f-s-60-force" style="font-size:60px">search</i>' +
            '<p class="m-t-md md-title font-bold st-grey-3">' +
            $translate.instant('keyword.exp.start.your.research') +
            '</p>' +
            '<p class="md-subhead st-grey-2">' +
            $translate.instant('keyword.exp.type.a.keyword') +
            '</p>' +
            '</div>';
        var keywordListSelected = [];
        var DEFAULT_QUERIES;
        var domainSearched;
        var vm = $scope;
        vm.customTemplate = CUSTOM_TEMPLATE_FOR_EMPTY_RESULT;
        vm.searchCanStart = false;
        vm.typeOfResearch = 'keyword';
        vm.userHasNoActiveSubscription = userInfo ? (userInfo.account !== 'sub') : false;

        vm.fields = vm.typeOfResearch == 'keyword' ? [{
                customClass: 'm-w-md text-left',
                emptyState: 'square-line',
                sortBy: 'keyword',
                label: 'keyword.list.component.keyword',
                tooltip: 'keyword.exp.table.tooltip.keyword'
            },
            {
                label: 'keyword.list.component.volume',
                sortBy: 'avgVolume',
                tooltip: 'keyword.exp.table.tooltip.volume'
            },
            {
                label: 'keyword.list.component.trend',
                customClass: 'hide-xs',
                tooltip: 'keyword.exp.table.tooltip.trend'
            },
            {
                sortBy: 'avgCpc',
                label: 'cpc',
                customClass: 'hide-xs',
                tooltip: 'keyword.exp.table.tooltip.cpc'
            },
            {
                sortBy: 'avgCompetition',
                label: 'keyword.list.component.comp',
                customClass: 'hide-xs hide-md',
                tooltip: 'keyword.exp.table.tooltip.comp'
            },
            {
                sortBy: 'avgDifficulty',
                label: 'keyword.list.component.kd',
                customClass: 'column-small',
                emptyState: 'circle',
                tooltip: 'keyword.exp.table.tooltip.difficulty'
            },
        ] : [{
                customClass: 'm-w-md text-left',
                emptyState: 'square-line',
                sortBy: 'keyword',
                label: 'keyword.list.component.keyword',
                tooltip: 'keyword.exp.table.tooltip.keyword'
            },
            {
                emptyState: 'square-line',
                sortBy: 'position',
                label: 'Pos',
                tooltip: 'keyword.exp.table.serp.tooltip.pos'
            },
            {
                label: 'keyword.list.component.volume',
                sortBy: 'avgVolume',
                tooltip: 'keyword.exp.table.tooltip.volume'
            },
            {
                sortBy: 'avgCpc',
                label: 'cpc',
                customClass: 'hide-xs',
                tooltip: 'keyword.exp.table.tooltip.cpc'
            },
            {
                sortBy: 'avgCompetition',
                label: 'keyword.list.component.comp',
                customClass: 'hide-xs hide-md',
                tooltip: 'keyword.exp.table.tooltip.comp'
            },
            {
                sortBy: 'avgDifficulty',
                label: 'keyword.list.component.kd',
                emptyState: 'circle',
                customClass: 'column-small',
                tooltip: 'keyword.exp.table.tooltip.difficulty'
            }
        ];
        vm.lastSerpSearch;
        vm.isAuthenticated = AuthService.isAuthenticated();
        vm.serpEnabled = $localStorage.serpEnabled && $stateParams.keyword || false;
        vm.expandSection = stToggleExpand.isExpanded('keyword-explorer-toggle') || false;

        /* todo refactor all using a single object search params like above: */
        vm.searchParams = {
            typeOfMode: $stateParams.mode || 'phrases_match'
        };
        vm.searchKeywordsBusy = false;
        vm.isLogged = AuthService.isAuthenticated();

        vm.typeOfMode = $stateParams.mode || 'phrases_match';
        vm.showFilter = false;
        vm.serpLoading = true;
        vm.serpLocked = false;
        vm.keywordLocked = false;
        vm.searchResults = null;
        vm.serpFeaturesList = null;
        vm.keywordDifficulty = null;
        vm.backlinks = null;
        vm.trendSearchesData = null;
        vm.keywordList = false;
        vm.rowsSelected = [];
        vm.userInfo = userInfo;
        vm.keywordSearched = '';
        vm.isMobile = $(window).width() < 960 ? true : false;
        vm.selectedCountry = $stateParams.countryCode || null;
        vm.currentActiveTab = $stateParams.typeOfResearch == 'domain' ? 1 : 0 ;



        /** Api **/
        vm.getSerp = getSerp;
        vm.searchKeywords = searchKeywords;
        vm.searchKeywordsByDomain = searchKeywordsByDomain;
        vm.addQueriesToKeywordlist = addQueriesToKeywordlist;
        vm.searchKeywordsByKeywordList = searchKeywordsByKeywordList;
        vm.getAllKeywordList = getAllKeywordList;
        vm.createAKeywordList = createAKeywordList;
        vm.getKeywordListByQuery = getKeywordListByQuery;
        vm.deleteQueriesToKeywordList = deleteQueriesToKeywordList;
        vm.analyzeUrlBySeochecker = analyzeUrlBySeochecker;
        vm.checkboxKeywordsSelected = checkboxKeywordsSelected;

        /** Utilities **/
        vm.setKeyword = setKeyword;
        vm.setFields = setFields;
        vm.addKeywordListOnInput = addKeywordListOnInput;
        vm.setTypeOfResearch = setTypeOfResearch;
        vm.showCustomToast = showCustomToast;
        vm.removeKeywordList = removeKeywordList;
        vm.canUseTheFilters = canUseTheFilters;
        vm.isString = isString;
        vm.formatHistory = formatHistory;
        vm.setTypeOfMode = setTypeOfMode;


        vm.modeTypes = {
            list: {
                "phrases_match": $translate.instant('keyword.exp.input.type.phrases'),
                "related": $translate.instant('keyword.exp.input.type.related'),
                "have_same_terms": $translate.instant('keyword.exp.input.type.terms'),
            }
        };

        vm.keywordListPage = {
            template: 3,
            createAKeywordList: vm.createAKeywordList,
            addQueriesToKeywordlist: vm.addQueriesToKeywordlist,
            getAllKeywordList: vm.getAllKeywordList,
            getKeywordListByQuery: vm.getKeywordListByQuery,
            buttonShow: true,
            buttonDisabled: !vm.rowsSelected.length,
            queries: [],
            queriesSelected: [],
            class: 'st-button__rounded--md--blue--outline',
            position: '0 -150',
            showCustomToast: showCustomToast,
        };

        vm.keywordController = {
            getSerp: getSerp,
            isExpanded: stToggleExpand.isExpanded,
            userOffline: !AuthService.isAuthenticated(),
            formatHistory: formatHistory,
            selectedCountry: vm.selectedCountry,
            optionsForKeywordList: {
                template: 2,
                addKeywordListOnInput: vm.addKeywordListOnInput,
                createAKeywordList: vm.createAKeywordList,
                addQueriesToKeywordlist: vm.addQueriesToKeywordlist,
                deleteQueriesToKeywordList: vm.deleteQueriesToKeywordList,
                getAllKeywordList: vm.getAllKeywordList,
                getKeywordListByQuery: vm.getKeywordListByQuery,
                showCustomToast: showCustomToast,
            }
        };

        vm.linkTabs = [{
            label: 'keyword.exp.search.keyword',
            labelXs: 'keyword.exp.search.keyword',
            isActive: true,
        }, {
            label: 'keyword.exp.search.domain',
            labelXs: 'keyword.exp.search.domain',
            isActive: false
        }];

        vm.setActiveTab = function (tabIndex) {
            vm.keyword = {};
            vm.currentActiveTab = tabIndex;
            switch (tabIndex) {
                default:
                case 0: {
                    vm.typeOfResearch = 'keyword';

                    if (vm.lastKeywordResults) {
                        vm.keyword.rows = vm.lastKeywordResults;
                    } else {
                        vm.keyword.rows = null;
                    }

                    if (vm.lastKeywordUrlParams) {
                        $stateParams = vm.lastKeywordUrlParams;
                        $stateParams.typeOfResearch = vm.typeOfResearch;
                        $stateParams.domain = null;

                        if ( vm.inputKeywordExplorer && vm.inputKeywordExplorer.query) {
                            $stateParams.keyword = vm.inputKeywordExplorer.query;
                        }
                        $state.transitionTo('app.keywordexplorer.search', vm.lastKeywordUrlParams, {notify: false});
                    }

                    break;
                }
                case 1: {
                    vm.typeOfResearch = 'domain';

                    if (vm.lastDomainResults) {
                        vm.keyword.rows = vm.lastDomainResults;
                    } else {
                        vm.keyword.rows = null;
                    }

                    if (vm.lastDomainUrlParams) {
                        $stateParams.typeOfResearch = vm.typeOfResearch;
                        $stateParams.keyword = null;

                        if ( vm.inputDomain && vm.inputDomain.query) {
                            $stateParams.domain  = vm.inputDomain.query;
                        }
                        vm.$stateParams = vm.lastDomainUrlParams;
                        $state.transitionTo('app.keywordexplorer.search', vm.lastDomainUrlParams, {notify: false});
                    }

                    break;
                }
            }

            if (vm.keyword && vm.keywordResearchedForSerp && vm.keyword.rows && vm.keyword.rows.length > 0 && vm.keyword.rows[0].keyword != vm.keywordResearchedForSerp) {
                vm.keyword.rows.map(function(row){
                    if (row.keyword == vm.keywordResearchedForSerp ) {
                        row.focus = true;
                        return;
                    } else {
                        row.focus = false;
                    }
                });
            }
        };

        vm.loadMoreKeywords = function () {
            vm.limit = vm.otherKeywordsToUnlock;
            searchKeywordsByDomain({
                domain: domainSearched,
                typeOfResearch: 'domain',
                limit: vm.limit,
                offset: vm.keyword.rows.length
            });
        };

        setKeyword($stateParams);
        updateLimits();

        vm.serpController = {
            analyzeUrlBySeochecker: analyzeUrlBySeochecker,
            setKeyword: setKeyword,
            setFields: setFields,
            setUrl: function (value) {
                var url = value.url.replace('https://', '');
                url = url.replace('http://', '');
                return url;
            },
            setSnippetIcon: function (row, value) {
                if (row.resultType === 'paid') {
                    return '<i class="md-subhead icon-sf-ad"></i>';
                }

                if (row.resultType !== 'paid' && row.resultType !== 'organic') {
                    return '<i class="md-subhead icon-sf-featured-snippet"></i>';
                }

                return value;
            }
        };

        vm.$watch('typeOfResearch', function () {
            createInputComponents();
            adjustFieldsUsingTypeOfResearch();
        });


        vm.$watch('domainRowsLimit', function (newValue, oldValue) {
              buildOptionForLoadMoreKeywords();
        });

        vm.$on('selectedCountry', function (evt, country) {
            vm.selectedCountry = JSON.parse(country);
        });

        function buildOptionForLoadMoreKeywords () {
            vm.keywordsToUnlockOptions = []
            if (!vm.resultPerReportLimit) {
                vm.keywordsToUnlockOptions.push({
                    limit: 100,
                    label: 'keyword.exp.unlock.other.keywords'
                });
                return;
            }

            vm.maxKeywordToAdd = vm.resultPerReportLimit.qtyAllowed / 10 <= 250 ? vm.resultPerReportLimit.qtyAllowed / 10 : 250;
            //localStorage.setItem('KE-lastLoadMoreOption', JSON.stringify(vm.maxKeywordToAdd));

            var optionLimitToAdd = vm.maxKeywordToAdd;

            if (vm.keyword && vm.keyword.rows) {
                vm.rowsRemainingForSearch = vm.totalRowsForDomain-vm.keyword.rows.length;
            }

            if (optionLimitToAdd<vm.rowsRemainingForSearch) {
                vm.keywordsToUnlockOptions.push({
                    limit: optionLimitToAdd,
                    label: 'keyword.exp.unlock.other.keywords'
                });
            } else {
                if (vm.rowsRemainingForSearch > 0) {
                    vm.keywordsToUnlockOptions.push({
                        limit: vm.rowsRemainingForSearch,
                        label: 'keyword.exp.unlock.other.keywords'
                    });
                }
            }

            while (optionLimitToAdd <= vm.domainRowsLimit.qtyRemaining && optionLimitToAdd<vm.rowsRemainingForSearch) {
                optionLimitToAdd*=2;

                /*
                var optionAlreadyAddedd = vm.keywordsToUnlockOptions.filter(function(option){
                    option.limit == optionLimitToAdd
                });

                if (optionAlreadyAddedd.length>0) {
                    return;
                }
                */


                //if (optionAlreadyAddedd.length == 0) {
                    if (optionLimitToAdd < vm.domainRowsLimit.qtyRemaining)
                    vm.keywordsToUnlockOptions.push({
                        limit: optionLimitToAdd,
                        label: 'keyword.exp.unlock.other.keywords'
                    })
                //}

            }

            var lastOptionAlreadyAddedd = vm.keywordsToUnlockOptions.filter(function(option){
                return option.limit == vm.domainRowsLimit.qtyRemaining
            });


            if (vm.domainRowsLimit.qtyRemaining>0 && lastOptionAlreadyAddedd.length == 0 &&  vm.domainRowsLimit.qtyRemaining<vm.rowsRemainingForSearch) {
                vm.keywordsToUnlockOptions.push({
                    limit: vm.domainRowsLimit.qtyRemaining,
                    label: 'keyword.exp.unlock.other.keywords'
                })
            }

            if (vm.keywordsToUnlockOptions.length>0 && vm.keywordsToUnlockOptions[vm.keywordsToUnlockOptions.length-1].limit > vm.rowsRemainingForSearch) {
                vm.keywordsToUnlockOptions[vm.keywordsToUnlockOptions.length-1].limit = vm.rowsRemainingForSearch;
            }

            if (vm.keywordsToUnlockOptions.length == 0) {
                vm.keywordsToUnlockOptions.push({
                    limit: vm.maxKeywordToAdd,
                    label: 'keyword.exp.unlock.other.keywords'
                });
            }

        }


        function setTypeOfMode(type) {
            vm.searchParams.typeOfMode = type;
        }

        function checkboxKeywordsSelected(rowsSelected) {
            vm.rowsSelected = rowsSelected;
            vm.keywordListPage.buttonDisabled = !vm.rowsSelected.length;
            vm.keywordListPage.queriesSelected = rowsSelected;
            setQueriesForComponents(rowsSelected);
        }

        function setQueriesForComponents(queries, initialQueries) {
            if (initialQueries) {
                DEFAULT_QUERIES = initialQueries;
            }
            if (!queries.length) {
                queries = DEFAULT_QUERIES ? DEFAULT_QUERIES : [];
            }
            var objectForComponents = {
                queriesName: [],
                queriesKeywordUuids: [],
                queriesAllFields: []
            };

            queries.forEach(function (query) {
                objectForComponents.queriesName.push(query.keyword);
                objectForComponents.queriesKeywordUuids.push(query.keywordUuid);
                objectForComponents.queriesAllFields.push({
                    keyword: query.keyword,
                    position: query.position,
                    volume: query.avgVolume,
                    competition: query.avgCompetition,
                    cpc: query.avgCpc,
                    difficulty: query.avgDifficulty,
                    countryCode: query.countryCode,
                });
            });
            vm.keywordUuids = objectForComponents.queriesKeywordUuids;
            createComponentExportAndCopyToClipboard(objectForComponents);
        }

        function adjustFieldsUsingTypeOfResearch() {

            if (vm.typeOfResearch !== 'keyword') {
                setFields([{
                        customClass: 'm-w-md text-left',
                        emptyState: 'square-line',
                        sortBy: 'keyword',
                        label: 'keyword.list.component.keyword',
                        tooltip: 'keyword.exp.table.tooltip.keyword'
                    },
                    {
                        emptyState: 'square-line',
                        sortBy: 'position',
                        label: 'Pos',
                        tooltip: 'keyword.exp.table.serp.tooltip.pos'
                    },
                    {
                        label: 'keyword.list.component.volume',
                        sortBy: 'avgVolume',
                        tooltip: 'keyword.exp.table.tooltip.volume'
                    },
                    {
                        sortBy: 'avgCpc',
                        label: 'cpc',
                        customClass: 'hide-xs',
                        tooltip: 'keyword.exp.table.tooltip.cpc'
                    },
                    {
                        sortBy: 'avgCompetition',
                        label: 'keyword.list.component.comp',
                        customClass: 'hide-xs hide-md',
                        tooltip: 'keyword.exp.table.tooltip.comp'
                    },
                    {
                        sortBy: 'avgDifficulty',
                        label: 'keyword.list.component.kd',
                        emptyState: 'circle',
                        customClass: 'column-small',
                        tooltip: 'keyword.exp.table.tooltip.difficulty'
                    }
                ]);
                return;
            }
            setFields([{
                    customClass: 'm-w-md text-left',
                    emptyState: 'square-line',
                    sortBy: 'keyword',
                    label: 'keyword.list.component.keyword',
                    tooltip: 'keyword.exp.table.tooltip.keyword'
                },
                {
                    label: 'keyword.list.component.volume',
                    sortBy: 'avgVolume',
                    tooltip: 'keyword.exp.table.tooltip.volume'
                },
                {
                    label: 'keyword.list.component.trend',
                    customClass: 'hide-xs',
                    tooltip: 'keyword.exp.table.tooltip.trend'
                },
                {
                    sortBy: 'avgCpc',
                    label: 'cpc',
                    customClass: 'hide-xs',
                    tooltip: 'keyword.exp.table.tooltip.cpc'
                },
                {
                    sortBy: 'avgCompetition',
                    label: 'keyword.list.component.comp',
                    customClass: 'hide-xs hide-md',
                    tooltip: 'keyword.exp.table.tooltip.comp'
                },
                {
                    sortBy: 'avgDifficulty',
                    label: 'keyword.list.component.kd',
                    customClass: 'column-small',
                    emptyState: 'circle',
                    tooltip: 'keyword.exp.table.tooltip.difficulty'
                },
            ]);
        }

        function setFields(res) {
            vm.fields = res;
        }

        createFiltersComponent({
                filters: {},
            },
            $stateParams);

        vm.$on("languageChanged", function () {
            createInputComponents();
        });

        function updateLimits() {
            UserService.limits()
                .then(function (res) {
                    userInfo = res.data;

                    vm.analysisLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'analysis';
                    });

                    vm.domainRowsLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'keyword-explorer-domain-rows';
                    });

                    vm.resultPerReportLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'keyword-explorer-data';
                    });


                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }

        /** Embed for feedback **/
        (function () {
            var qs, js, q, s, d = document,
                gi = d.getElementById,
                ce = d.createElement,
                gt = d.getElementsByTagName,
                id = "typef_form_share",
                b = "https://embed.typeform.com/";
            if (!gi.call(d, id)) {
                js = ce.call(d, "script");
                js.id = id;
                js.src = b + "embed.js";
                q = gt.call(d, "script")[0];
                q.parentNode.insertBefore(js, q);
            }
        })();

        /** API **/
        function getSerpData(row) {
            return new Promise(function (resolve, reject) {
                if (vm.keywordLocked) {
                    reject({
                        error: {
                            code: 'NO_ENOUGH_CREDITS'
                        }
                    });
                }

                if (!row) {
                    reject({
                        error: {
                            code: 'SUCCESS'
                        }
                    });
                }

                vm.keywordResearchedForSerp = row.keyword;
                var query = {
                    keyword: row.keyword,
                    filters: {
                        countryCode: vm.selectedCountry === 'GBL' ? 'US' : vm.selectedCountry,
                    }
                };

                resolve(SERPChecker.analyzeQueryBacklink(query));
            });
        }

        function isAborted(err) {
            return err && err.xhrStatus && err.xhrStatus === 'abort';
        }

        vm.enableSerpSearch = function () {
            $('#column-serp-disabled-noauth').addClass('page-grid--hidden-w-xxxxl');
            $('#column-serp-disabled').addClass('page-grid--hidden-w-xxxxl');

            // $timeout(function () {
            $localStorage.serpEnabled = vm.serpEnabled = true;
            vm.restartSerpSearch();

            $('#column-serp').removeClass('page-grid--hidden-flex').click();
            // }, 200);
        }

        vm.disableSerpSearch = function () {
            $('#column-serp').addClass('page-grid--hidden-flex');

            // $timeout(function () {
            $localStorage.serpEnabled = vm.serpEnabled = false;

            $('#column-serp-disabled').removeClass('page-grid--hidden-w-xxxxl').click();
            $('#column-serp-disabled-noauth').removeClass('page-grid--hidden-w-xxxxl').click();
            // }, 200);
        }

        vm.restartSerpSearch = function () {
            if (vm.isMobile || !vm.serpEnabled) {
                return;
            }

            getSerp(vm.lastSerpSearch);
        }

        function getSerp(row,index) {
            vm.previousIndexClicked = vm.lastIndexClicked || 0;
            vm.lastIndexClicked = index;
            if (vm.isMobile || !vm.serpEnabled) {
                vm.lastSerpSearch = row;
                return;
            }

            vm.serpTableLoading = true;
            createComponentsSerp(null, true);
            transformResponseForSerp(null, null, null);
            return getSerpData(row)
                .then(function (res) {
                    updateLimits();
                    createComponentsSerp(res.data.details, false);
                    TrackEventService.event(
                        'Suite Usages',
                        'SERP Checker - Audit', 'Backlink - Keyword Explorer - Integration');
                    TrackEventService.gaV2Event(
                        'serp_audit', {
                            type: "Backlink",
                            tool: "Keyword Explorer"
                        });
                    var serpRows = transformResponseForSerp(res.data.rows, res.data.details.totalRows, res.message);
                    vm.serp = {
                        rows: serpRows.data.rows
                    };
                    vm.serpTableLoading = false;
                })
                .catch(function (err) {
                    if (isAborted(err)) {
                        throw err;
                    }
                    vm.serpLoading = false;
                    try {
                        err.error = err.data.error;
                    } catch (e) {
                        err.error = {
                            code: 'SUCCESS'
                        };
                    }
                    if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                        vm.serpLocked = true;
                    }
                    vm.serpTableLoading = false;
                    vm.serp = {
                        rows: []
                    };
                    vm.messageDataSerp = ErrorMessageService.setMessageForTheTable(
                        0,
                        err.error.code,
                        userInfo,
                        'SERP'
                    );
                    exportSerpComponent([]);
                    updateLimits();
                });
        }

        function transformSerp(rows) {
            var serp = [];

            if (!rows || rows.length <= 0) {
                return serp;
            }

            for (var i = 0; i < rows.length; i += 1) {
                var row = rows[i];

                var query = {};

                query.position = row.position;
                query.breadcrumb = row.breadcrumb;

                query.resultType = 'organic';
                if (row.resultType) {
                    query.resultType = row.resultType;
                }

                query.website = {
                    title: row.title,
                    url: '',
                };

                if (row.link) {
                    query.website.url = row.link.url || null;
                    query.pa = row.link.authority || null;
                    query.da = row.link.domainAuthority || null;
                }

                if (row.extraParams) {
                    query.links = row.extraParams.backlinks || null;
                    query.fb = row.extraParams.share || null;
                    query.ev = row.extraParams.estimatedVisits || null;
                }

                serp.push(query);
            }

            return serp;
        }

        function transformResponseForSerp(rows, totalRows = 0) {
            var formattedRows = transformSerp(rows);
            exportSerpComponent(formattedRows);
            return {
                data: {
                    rows: formattedRows,
                    totalRows: totalRows,
                    message: {
                        code: 'success'
                    }
                },
            };
        }

        function createAKeywordList(name) {
            return KeywordListService.createAKeywordList({
                    name: name,
                    toolSourceId: 1
                })
                .then(function () {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - List Created', 'Keyword Explorer - Integration');
                    TrackEventService.gaV2Event(
                        'list_create', {
                            tool: "Keyword Explorer"
                        });
                    return getAllKeywordList();
                });
        }

        function getAllKeywordList() {
            if (!AuthService.isAuthenticated()) return [];
            return KeywordListService.getAllKeywordList()
                .then(function (res) {
                    return res;
                })
                .catch(function (err) {
                    console.log(err);
                })
        }

        function addQueriesToKeywordlist(queriesSelected, keywordListId, allQueries) {
            return KeywordListService.addQueries(keywordListId, queriesSelected)
                .then(function () {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - Keyword Added', 'Keyword Explorer - Integration');
                    TrackEventService.gaV2Event(
                        'keyword_add', {
                            tool: "Keyword Explorer"
                        });
                    return getKeywordListByQuery(allQueries)
                        .then(function () {
                            return getAllKeywordList();
                        })
                        .catch(function (err) {
                            console.log(err);
                            return err;
                        });
                });
        }

        function deleteQueriesToKeywordList(keywordUuids, keywordListId, allQueries) {
            return KeywordListService.deleteKeywords(keywordListId, keywordUuids).then(function () {
                TrackEventService.event(
                    'Suite Usages',
                    'Keyword List Manager - Keyword Deleted', 'Keyword Explorer - Integration');
                TrackEventService.gaV2Event(
                    'keyword_remove', {
                        tool: "Keyword Explorer"
                    });
                return getKeywordListByQuery(allQueries)
                    .then(function () {
                        return getAllKeywordList();
                    })
                    .catch(function (err) {
                        return err;
                    });
            });
        }

        function getSuggestions(keyword, countryCode) {
            var dfd = $q.defer();
            var languageCode = GoogleSuggestionsService.getLanguage(countryCode);
            GoogleSuggestionsService.autocomplete(keyword, languageCode)
                .then(function (suggestions) {
                    dfd.resolve(suggestions);
                })
                .catch(function (err) {
                    console.log(err);
                    dfd.resolve([]);
                });
            return dfd.promise;
        }

        var lastSearch = null;

        function searchKeywords(query, startSerpWithoutWaiting) {
            if (!query) {
                query = {
                    keyword: vm.keywordSearched,
                    typeOfResearch: 'keyword'
                };
            }

            if (lastSearch && lastSearch === query.keyword && vm.searchKeywordsBusy) {
                console.log('Too many requests');
                return $q.reject(new Error('too many requests'));
            }

            query = formattedObjectForKeywordResearch(query);
            lastSearch = query.keyword;
            vm.searchKeywordsBusy = true;
            vm.showFilter = false;

            /** Set loading for the components **/
            vm.keywordLocked = false;
            vm.keywordTableLoading = true;
            setItemLastSearches(query.keyword, 'lastKeywordSearches');

            if (startSerpWithoutWaiting) {
                createComponentsSerp(null, true);
                vm.serpTableLoading = true;
                getSerp({
                    keyword: query.keyword
                });
            }

            return getSuggestions(query.keyword, query.filters.countryCode)
                .then(function (seeds) {
                    query.seeds = seeds;
                    return KeywordExplorerService.searchKeywords(query);
                })
                .then(function (res) {
                    getKeywordListByQuery(res.data.rows);
                    vm.keyword = {
                        rows: res.data.rows
                    };

                    vm.lastKeywordResults = vm.keyword.rows

                    vm.totalRows = res.data.totalRows;

                    if (startSerpWithoutWaiting) {
                        setRowSelected(res.data.rows, 'keyword', query.keyword);
                    }
                    vm.keywordTableLoading = false;

                    var params = updateUrlParams(query, 'keyword');
                    vm.lastKeywordUrlParams = params;
                    createFiltersComponent(query, params);
                    updateLimits();
                    searchCountQueries(query);
                    setQueriesForComponents(res.data.rows, res.data.rows);
                    vm.searchKeywordsBusy = false;
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword Explorer - Search', 'Standard');
                    TrackEventService.gaV2Event(
                        'keyword_search', {
                            type: "Standard",
                            tool: "Keyword Explorer"
                        });

                    return res;
                })
                .catch(function (err) {
                    console.error(err);

                    vm.searchKeywordsBusy = false;
                    try {
                        err.error = err.data.error;
                    } catch (e) {
                        err.error = {
                            code: 'SUCCESS'
                        };
                    }
                    if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                        vm.keywordLocked = true;
                    }
                    vm.keyword = {
                        rows: []
                    };
                    vm.keywordTableLoading = false;
                    if (err.status === 429 || err.status === 426) {
                        vm.messageDataKeyword = {};
                        vm.messageDataKeyword.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                        vm.messageDataKeyword.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                        vm.messageDataKeyword.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                        vm.messageDataKeyword.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                    }
                });
        }

        function setRowSelected(rows, condition, value) {
            if (rows.length) {
                if (condition) {
                    for (var i = 0; i < vm.keyword.rows.length; i++) {
                        if (vm.keyword.rows[i].keyword === value) {
                            vm.keyword.rows[i].focus = true;
                        }
                    }
                    return;
                }
                vm.keyword.rows[0].focus = true;
            }
        }

        function formatHistory(history) {
            if (!history) {
                return '<div>-</div>';
            }
            var trends = [];
            var formattedTrends = history;
            if (typeof formattedTrends === 'string') {
                formattedTrends = JSON.parse(formattedTrends);
            }

            for (const datetime in formattedTrends) {
                trends.push(formattedTrends[datetime]);
            }

            return '<st-sparkline data="[' + trends + ']"></st-sparkline>';
        }

        function searchKeywordsByDomain(query) {
            vm.keywordLocked = false;
            vm.showFilter = false;
            vm.keywordTableLoading = true;

            query = formattedObjectForSearchedByDomain(query);
            setItemLastSearches(query.domain, 'lastDomainSearches');
            if (!query.offset) {
                vm.serpTableLoading = true;
                createComponentsSerp(null, true);
            }

            if (!query.limit) {
                //query.limit = JSON.parse(localStorage.getItem('KE-lastLoadMoreOption'))
            }

            return KeywordExplorerService.searchKeywordsByDomain(query).then(function (res) {

                    var params = updateUrlParams(query, 'domain');
                    vm.lastDomainUrlParams = params;
                    vm.totalRowsForDomain = res.data.totalRows;

                    getKeywordListByQuery(res.data.rows);
                    if (query.offset>0) {
                        vm.keyword.rows = vm.resultRowsCopy.concat(res.data.rows);
                        if (Number.isInteger(vm.lastIndexClicked) && vm.lastIndexClicked >= 0) {
                            vm.keyword.rows[vm.previousIndexClicked].focus = false;
                            vm.keyword.rows[vm.lastIndexClicked].focus = true;
                        }
                    } else {
                        vm.keyword = {
                            rows: res.data.rows
                        };
                        getSerp(res.data.rows[0]);
                        setRowSelected(res.data.rows);
                    }

                    if (vm.keyword.rows.length == vm.totalRowsForDomain) {
                        showCustomToast(
                            '',
                            'icon-checkmark st-green',
                            $translate.instant('keyword.exp.you.have.reached.the.number.of.keywords.availabl.for.this.search')
                        );
                    }

                    if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                        vm.customFooterForKeywordsTable = '<div flex class="m-b-n-sm">' + vm.keyword.rows.length + ' {{\'of\'|translate}} ' +  res.data.totalRows + ' {{\'results available\'|translate}}</div>';
                    }

                    vm.lastDomainResults = vm.keyword.rows;

                    vm.resultRowsCopy = angular.copy(vm.keyword.rows);

                    vm.keywordTableLoading = false;
                    createFiltersComponent(query, params);
                    updateLimits();
                    vm.showFilter = false;
                    setQueriesForComponents(vm.keyword.rows, vm.keyword.rows);


                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword Explorer - Search', 'Domain');
                    TrackEventService.gaV2Event(
                        'keyword_search', {
                            type: "Domain",
                            tool: "Keyword Explorer"
                        });
                    return res;
                })
                .catch(function (err) {
                    console.log('err', err)
                    vm.serpLoading = false;
                    vm.showFilter = false;
                    // err.error = err.error ? err.error : !err.error ? err.data.error : {code: 'SUCCESS'};
                    try {
                        err.error = err.data.error;
                    } catch (e) {
                        err.error = {
                            code: 'SUCCESS'
                        };
                    }
                    if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                        vm.keywordLocked = true;
                    }
                    getSerp([]);

                    vm.keywordTableLoading = false;
                    if (err.status === 429 || err.status === 426) {
                        vm.messageDataKeyword = {};
                        vm.messageDataKeyword.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                        vm.messageDataKeyword.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                        vm.messageDataKeyword.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                        vm.messageDataKeyword.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                    }
                });
        }

        function searchKeywordsByKeywordList(query) {
            vm.keywordLocked = false;
            vm.showFilter = false;
            vm.serpTableLoading = true;
            vm.keywordTableLoading = true;
            createComponentsSerp(null, true);
            addKeywordListOnInput({
                keywordListId: query.keywordListId,
                keywordListName: query.keywordListName
            });
            query = formattedObjectForKeywordListResearch(query);
            return KeywordListService.getOneKeywordList(query.keywordListId)
                .then(function (res) {
                    var keywords = [];
                    var queries = res.data.queries.rows;
                    queries.forEach(function (query) {
                        keywords.push(query.keyword);
                    });
                    return KeywordExplorerService.searchByKeywords(keywords, query.filters)
                        .then(function (res) {
                            var params = updateUrlParams(query, 'keywordList');
                            vm.lastKeywordUrlParams = params;
                            getKeywordListByQuery(res.data.rows);
                            vm.keyword = {
                                rows: res.data.rows
                            };

                            vm.lastKeywordResults = vm.keyword.rows;

                            setRowSelected(res.data.rows);
                            vm.keywordTableLoading = false;

                            vm.showFilter = false;
                            createFiltersComponent(query, params);
                            updateLimits();
                            setQueriesForComponents(res.data.rows, res.data.rows);
                            getSerp(res.data.rows[0]);

                            TrackEventService.event(
                                'Suite Usages',
                                'Keyword Explorer - Search', 'List');
                            TrackEventService.gaV2Event(
                                'keyword_search', {
                                    type: "List",
                                    tool: "Keyword Explorer"
                                });
                            return res;
                        })
                        .catch(function (err) {
                            vm.showFilter = false;
                            try {
                                err.error = err.data.error;
                            } catch (e) {
                                err.error = {
                                    code: 'SUCCESS'
                                };
                            }
                            if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                                vm.keywordLocked = true;
                            }
                            vm.keyword = {
                                rows: []
                            };
                            getSerp([]);

                            vm.keywordTableLoading = false;
                        });
                });
        }

        function getKeywordListByQuery(rows) {
            if (!vm.isLogged || !rows) {
                return;
            }
            var keywordUuids = [];
            rows.forEach(function (row) {
                keywordUuids.push(row.keywordUuid);
            });
            return KeywordListService.getKeywordListByQuery({
                keywordUuids: keywordUuids
            }).then(function (res) {
                $rootScope.$broadcast('getKeywordListByQuery', res);
                return res;
            });
        }

        /** Utilities **/
        function setKeyword(query) {
            if (!query.keyword && !query.keywordListName && !query.domain) {
                vm.customTemplate = CUSTOM_TEMPLATE_FOR_EMPTY_RESULT;
                return;
            }
            vm.searchCanStart = true;
            vm.customTemplate = null;
            if (query.typeOfResearch === 'domain') {

                if (userInfo.account === 'offline' || userInfo.account === 'online') {
                    setLimitToast();
                    return;
                }
                domainSearched = query.domain;
                searchKeywordsByDomain(query);
            }
            if (query.typeOfResearch === 'keyword') {
                vm.keywordSearched = query.keyword;
                searchKeywords(query, true);
            }
            if (query.typeOfResearch === 'keywordList') {

                searchKeywordsByKeywordList(query);
            }
            var typeOfResearch = query.typeOfResearch || $stateParams.typeOfResearch;
            setTypeOfResearch(typeOfResearch);
            adjustFieldsUsingTypeOfResearch();
        }

        function setLimitToast() {
            var copyCallToAction;
            if (!userInfo.canDoTrial && userInfo.account === 'offline' ||
                userInfo.canDoTrial && userInfo.account === 'online') {
                copyCallToAction = '<strong>{{\'cta.get.trial\'|translate: {trialPeriodDays: ' + __env.trialPeriodDays + '} }}</strong>';
            } else {
                copyCallToAction = '<strong>{{\'cta.get.sub\'|translate | lowercase}}</strong>';
            }
            showCustomToast(
                'cta.unlock.pro.feature',
                'icon-x st-err',
                '<a class="md-body-1 md-primary txt-decoration-und m-l-xs" ' +
                '   ui-sref="upgrade.choose-plan"> ' +
                '' + copyCallToAction + '' +
                '</a>'
            );
        }

        function showCustomToast(string, classIcon, templateCallToAction) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '&nbsp;</div>' +
                    '' + (templateCallToAction || '') + '' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function updateUrlParams(query, typeOfResearch) {
            var params = {
                typeOfResearch: typeOfResearch,
                mode: query.mode,
                countryCode: setCountry(query.filters.countryCode),
            };
            if (typeOfResearch === 'domain') {
                params.domain = query.domain;
            }
            if (typeOfResearch === 'keywordList') {
                params.keywordListName = query.keywordListName;
                params.keywordListId = query.keywordListId;
            }
            if (typeOfResearch === 'keyword') {
                params.keyword = query.keyword;
            }
            if (query.filters.excludedWords) {
                params.excludedWords = query.filters.excludedWords.join();
            }
            if (query.filters.includedWords) {
                params.includedWords = query.filters.includedWords.join();
            }

            if (query.filters && query.filters.volume && query.filters.volume.min) {
                params.minVolume = query.filters.volume.min;
            }

            if (query.filters && query.filters.volume && query.filters.volume.max) {
                params.maxVolume = query.filters.volume.max;
            }

            if (query.filters && query.filters.cpc && query.filters.cpc.min) {
                params.minCpc = query.filters.cpc.min;
            }

            if (query.filters && query.filters.cpc && query.filters.cpc.max) {
                params.maxCpc = query.filters.cpc.max;
            }

            if (query.filters && query.filters.difficulty && query.filters.difficulty.min) {
                params.minDifficulty = query.filters.difficulty.min;
            }

            if (query.filters && query.filters.difficulty && query.filters.difficulty.max) {
                params.maxDifficulty = query.filters.difficulty.max;
            }

            if (query.filters && query.filters.competition && query.filters.competition.min) {
                params.minCompetition = query.filters.competition.min;
            }

            if (query.filters && query.filters.competition && query.filters.competition.max) {
                params.maxCompetition = query.filters.competition.max;
            }

            $state.transitionTo('app.keywordexplorer.search', params, {
                notify: false
            });
            $stateParams = params;
            return params;
        }

        function formattedInArray(string) {
            if (string) {
                return string.split(',');
            }
            return null;
        }

        function setMinMaxFilter(filters, filterType, minFilter, maxFilter) {
            if ((!minFilter || minFilter === 'null') && (!maxFilter || maxFilter === 'null')) {
                return filters;
            }

            if (!filters[filterType]) {
                filters[filterType] = {};
            }

            if (minFilter && minFilter !== 'null') {
                filters[filterType].min = minFilter;
            } else {
                filters[filterType].min = null;
            }

            if (maxFilter && maxFilter !== 'null') {
                filters[filterType].max = maxFilter;
            } else {
                filters[filterType].max = null;
            }

            return filters;
        }

        function setCountry(country) {
            var languageCodeSaveOnStorage = LanguageService.getCountryCodeSaveOnStorage();

            if (!country) {
                country = languageCodeSaveOnStorage;
            }

            if (country === 'GBL') {
                country = null;
            }

            return country;
        }

        function removeKeywordList() {
            vm.keywordList = false;
        }

        function validateFilters(query) {
            var filters = query.filters;
            var object = {};
            if (!query.applyFilters && !query.filters || !query.applyFilters && query.filters) {
                filters = $stateParams;
            }

            object.countryCode = setCountry(vm.selectedCountry);

            if (filters.excludedWords && filters.excludedWords !== 'null') {
                object.excludedWords = formattedInArray(filters.excludedWords);
            }

            if (filters.includedWords && filters.includedWords !== 'null') {
                object.includedWords = formattedInArray(filters.includedWords);
            }

            object = setMinMaxFilter(object, 'volume', filters.minVolume, filters.maxVolume);
            object = setMinMaxFilter(object, 'cpc', filters.minCpc, filters.maxCpc);
            object = setMinMaxFilter(object, 'difficulty', filters.minDifficulty, filters.maxDifficulty);
            object = setMinMaxFilter(object, 'competition', filters.minCompetition, filters.maxCompetition);

            return object;
        }

        function setItemLastSearches(item, type) {
            if (!item) {
                return;
            }
            item = item.toLowerCase();
            var lastSearches = JSON.parse(localStorage.getItem(type)) || [];
            var index = lastSearches.indexOf(item);

            if (index > -1) {
                lastSearches.splice(index, 1);
            }

            if (lastSearches.length >= 3) {
                lastSearches.pop();
            }
            lastSearches.unshift(item);
            localStorage.setItem(type, JSON.stringify(lastSearches));
            return lastSearches;
        }

        function setTypeOfResearch(type = 'keyword') {
            if (type === 'keywordList') {
                type = 'keyword';
            }
            vm.typeOfResearch = type;
            createInputComponents();
        }

        function addKeywordListOnInput(keywordList) {
            vm.keywordList = true;
            if (keywordListSelected.length) {
                keywordListSelected.splice(0, 1);
            }
            keywordListSelected.push({
                keywordListName: keywordList.keywordListName,
                keywordListId: keywordList.keywordListId
            });
            createInputComponents();
        }

        function analyzeUrlBySeochecker(website) {
            var url = $state.href('app.seochecker.results.overview', {
                url: encodeURIComponent(btoa(website))
            });
            $window.open(url, '_blank');
        }

        function formattedObjectForKeywordResearch(query) {
            return {
                keyword: vm.keywordSearched,
                mode: vm.searchParams.typeOfMode,
                filters: validateFilters(query)
            };
        }

        function formattedObjectForKeywordListResearch(query) {
            return {
                keywordListId: keywordListSelected[0].keywordListId,
                keywordListName: keywordListSelected[0].keywordListName,
                filters: validateFilters(query)
            };
        }

        function formattedObjectForSearchedByDomain(query) {
            return {
                domain: domainSearched,
                filters: validateFilters(query),
                limit: query.limit || vm.maxKeywordToAdd,
                offset: query.offset
            };
        }

        function createFiltersComponent(query, params) {
            var filtersApplyLength = Object.keys(query.filters).length - 1;
            if (vm.showFilter) {
                vm.showFilter = false;
            }
            if (filtersApplyLength > 0) {
                vm.filtersApplyLength = '(' + filtersApplyLength + ')';
            }
            if (filtersApplyLength === 0) {
                vm.filtersApplyLength = '';
            }
            vm.filtersComponent = {
                callback: vm.canUseTheFilters,
                filters: params,
            };
        }

        function canUseTheFilters(options) {
            if (userInfo.account === 'offline' || userInfo.account === 'online') {
                setLimitToast();
                vm.showFilter = false;
                return;
            }

            //var usedFilters = clean(options.filters);
            createFiltersComponent({
                    filters: {},
                },
                $stateParams);

            if (options.filters.typeOfResearch === 'domain') {
                searchKeywordsByDomain({
                    domain: domainSearched,
                    filters: options.filters,
                    applyFilters: true,
                    typeOfResearch: 'domain'
                });
            }
            if (options.filters.typeOfResearch === 'keywordList') {
                searchKeywordsByKeywordList({
                    keywordListId: keywordListSelected[0].keywordListId,
                    keywordListName: keywordListSelected[0].keywordListName,
                    filters: options.filters,
                    applyFilters: true,
                    typeOfResearch: 'keywordList'
                });
            }
            if (options.filters.typeOfResearch === 'keyword') {
                searchKeywords({
                    keyword: vm.keywordSearched,
                    filters: options.filters,
                    applyFilters: true,
                    typeOfResearch: 'keyword'
                });
            }
        }

        function createComponentExportAndCopyToClipboard(object) {
            vm.copyToClipboardOptions = {
                class: 'st-button__rounded--md--blue--outline',
                text: 'keyword.exp.copy.clipboard',
                showToast: true,
                icon: 'icon-copy-filled',
                data: [object.queriesName],
                mode: 'rows',
                buttonDisabled: !vm.searchCanStart || vm.keywordTableLoading
            };

            var formattedRows = object && object.queriesAllFields ? object.queriesAllFields : [];

            vm.exportCsvOptions = {
                stoTool: 'keyword-explorer',
                class: 'st-button__rounded--md--violet',
                text: $translate.instant('keyword.exp.export.csv'),
                icon: 'icon-download',
                data: ExportService.convertObjectToCsvArray(formattedRows),
                fileName: 'keywords',
                buttonDisabled: (!vm.searchCanStart || vm.keywordTableLoading || userInfo.account == 'offline')
            };
        }

        function exportSerpComponent(formattedRows) {
            vm.exportSerpOptions = {
                stoTool: 'serp-report',
                class: 'st-button__rounded--md--violet',
                text: $translate.instant('keyword.exp.export.serp'),
                icon: 'icon-download',
                data: ExportService.convertObjectToCsvArray(formattedRows),
                fileName: 'serp-keyword',
                buttonDisabled: !formattedRows.length
            };
        }

        function createComponentsSerp(serp, loading) {
            if (serp) {
                vm.serpLoading = loading;
                vm.serpLocked = vm.keywordLocked;
                vm.keywordDifficulty = serp.avgDifficulty ? string2Number(serp.avgDifficulty).toFixed(0) : 5;
                vm.backlinks = serp.backlinksAvg;
                vm.searchResults = serp.totalRows;
                vm.serpFeaturesList = serp.resultTypes;
                vm.trendSearchesData = serp.history ? serp.history : null;
                vm.avgVolume = serp.avgVolume || 0;
            } else {
                vm.serpLoading = loading;
                vm.serpLocked = vm.keywordLocked;
                vm.keywordDifficulty = null;
                vm.trendSearchesData = null;
                vm.backlinks = null;
                vm.searchResults = null;
                vm.serpFeaturesList = null;
                vm.avgVolume = null;
            }
        }

        function string2Number(val) {
            if (typeof val === 'string' || val instanceof String) {
                return parseInt(val);
            }
            return val;
        }

        vm.onKeyPressed = function(ev, isInvalidUrl) {
            var wasPressedEnter = ev.which == 13;

           if (wasPressedEnter && isInvalidUrl) {
                showCustomToast(
                    $translate.instant('keyword.list.component.error.title') + ':',
                    'icon-x st-err',
                    $translate.instant('Invalid URL')
                );
           }
        }

        function createInputComponents() {
            var templateKeywordUrl = $sce.getTrustedResourceUrl('app/modules/keywordexplorer/templates/input-keyword.html');
            $templateRequest(templateKeywordUrl)
                .then(function (template) {
                    vm.inputKeywordExplorer = {
                        template: template,
                        term: keywordListSelected,
                        searchKeywordsByKeywordList: vm.setKeyword,
                        searchKeywords: vm.setKeyword,
                        query: vm.keywordSearched,
                        setFields: vm.setFields,
                        removeKeywordList: vm.removeKeywordList
                    };
                });

            var templateDomainUrl = $sce.getTrustedResourceUrl('app/modules/keywordexplorer/templates/input-domain.html');
            $templateRequest(templateDomainUrl)
                .then(function (template) {
                    vm.inputDomain = {
                        template: template,
                        term: keywordListSelected,
                        searchKeywordsByDomain: vm.setKeyword,
                        query: domainSearched,
                        setFields: vm.setFields,
                        haveASub: userInfo && userInfo.account !== 'sub',
                        onKeyPressed: vm.onKeyPressed
                    };
                });
        }

        function isString(value) {
            if (typeof value === 'string') {
                return value;
            }
            return false;
        }

        function searchCountQueries() {
            vm.customFooterForKeywordsTable = '' +
                '<div flex ng-if="results.length||rows.length">' +
                '   {{results.length||rows.length}} {{\'results available\'|translate}}' +
                '</div>';
        }

        vm.getLockedRows = function () {
            if (!vm.userHasNoActiveSubscription || !vm.totalRows || vm.totalRows <= 10) {
                return 0;
            }
            vm.totalLockedResults = vm.totalRows;
            return 100;
        };

        vm.getTotalResults = function () {
            return vm.totalRows;
        }
    }

    Controller.$inject = [
        '__env',
        '$q',
        '$scope',
        '$window',
        '$localStorage',
        'KeywordExplorerService',
        'SerpCheckerService',
        'KeywordListService',
        'AuthService',
        'UserService',
        'ExportService',
        '$sce',
        '$templateRequest',
        '$state',
        '$stateParams',
        '$mdToast',
        '$translate',
        'userInfo',
        'ErrorMessageService',
        'LanguageService',
        'GoogleSuggestionsService',
        '$rootScope',
        'TrackEventService',
        'stToggleExpandSectionService',
        'stLimitMessage'
    ];
    return Controller;
});

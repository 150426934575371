/**
 * @ngdoc overview
 * @name seospider.pages.service:SeospiderPagesService
 */
'use strict';
define(['app'], function (app) {
    app.service('SeospiderPagesService', SeospiderPagesService);
    var DEFAULT_LIMIT = 100;
    SeospiderPagesService.$inject = ['API', 'Cache', '__env', '$q'];

    function SeospiderPagesService(API, Cache, __env, $q) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.seoSpiderAPI;

        return {
            findAllError: findAllError,
            findAllRedirect: findAllRedirect,
            findAll: findAll,
            findAllPageDuplicated: findAllPageDuplicated,
            findOne: findOne,
            taxonomies: taxonomies,
            hreflangs: hreflangs,
            findAllTitle: findAllTitle,
            findAllTitleDuplicated: findAllTitleDuplicated,
            findAllImages: findAllImages,
            findAllH1: findAllH1,
            findAllH1Duplicated: findAllH1Duplicated,
            findAllMetaDescription: findAllMetaDescription,
            findAllH2: findAllH2,
            findAllH2Duplicated: findAllH2Duplicated,
            findAllMetaDescriptionDuplicated: findAllMetaDescriptionDuplicated,
        };

        function taxonomies(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/taxonomies', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllError(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/error', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);

        }

        function findAllRedirect(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/redirect', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllTitle(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/title', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllTitleDuplicated(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/title/duplicate', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllMetaDescription(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/meta-description', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllH1(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/h1', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllImages(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/images', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllH2(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/h2', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllMetaDescriptionDuplicated(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/meta-description/duplicate', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);

        }

        function findAllH1Duplicated(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/h1/duplicate', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllH2Duplicated(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/h2/duplicate', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function hreflangs(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/hreflang', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAll(params) {//jobId,_fields,_limit,_offset) {
            var newParams = JSON.parse(JSON.stringify(params));
            if (newParams.jobId == undefined)
                return false;
            if (newParams.limit == undefined)
                newParams.limit = DEFAULT_LIMIT;
            if (newParams.offset == undefined)
                newParams.offset = 0;
            if (newParams.fields == undefined)
                newParams.fields = null;
            else newParams.fields = newParams.fields.join(",");

            return API.get(apiPath + '/' + newParams.jobId + '/page', {
                params: newParams,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findAllPageDuplicated(params) {
            if (params.jobId == undefined)
                return false;
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;

            return API.get(apiPath + '/' + params.jobId + '/page/duplicate', {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function findOne(params) {
            return API.get(apiPath + '/' + params.jobId + '/page/' + params.pageId, {
                params: params,
                cache: Cache,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }
    }
});

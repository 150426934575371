'use strict';
define([], function () {
    function Controller($scope, $mdDialog, invitation, WorkspaceService) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.invitation = invitation;

        function answerDialog(answer) {
            $scope.loading.post = true;
            WorkspaceService.declineInvitations(invitation.workspaceId).then(function (res) {
                hideDialog();
            }).catch(function (err) {
                $scope.loading.post = false;
                console.log(err)
            })
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject = ['$scope', '$mdDialog', 'invitation', 'WorkspaceService'];
    return Controller;
});

'use strict';
define(['angular'], function(angular) {
    function BusinesInfoCtrl($scope, UserService, accountData, $window, $mdToast, $filter, TrackEventService) {
        angular.element(document).ready(function () {
            document.getElementById("siteUrl").focus();
        });
        $scope.siteUrlRegex='^((http:\\/\\/|https:\\/\\/)?(www\\.|^\\.)?((([\\-|\\w|\\d]+)\\.)+([a-zA-Z]{1,10}))(\\/?(([a-zA-Z0-9_@]+\\.([a-zA-Z0-9]{3,6}))?(\\??[-A-Za-z0-9+:&@#\\/%=~\'_\\.?|]+))){0,1})$';
        $scope.jobsTitle = [
            "Entrepreneur",
            "Digital Strategist",
            "SEO/SEM Specialist",
            "Social Media Manager",
            "Consultant",
            "Copywriter",
            "Data Analyst",
            "Designer",
            "Sales Account",
            "Project Manager",
            "Web Developer",
            "Student",
            "Other"
        ];
        $scope.businessTypes = [
            "Web Agency",
            "E-commerce",
            "Company",
            "Freelance",
            "Consulting",
            "Blog",
            "Non-profit",
            "SaaS",
            "Other"
        ];
        $scope.user={
            domain:accountData.domain,
            jobTitle:accountData.jobTitle,
            businessType:accountData.businessType,
            acceptedReceiveOnboardingEmailAt:accountData.acceptedReceiveOnboardingEmailAt?$filter('date')(accountData.acceptedReceiveOnboardingEmailAt,'yyyy-MM-dd HH:mm:ss'):null,
            acceptedReceivePromotionalEmailAt:accountData.acceptedReceivePromotionalEmailAt?$filter('date')(accountData.acceptedReceivePromotionalEmailAt,'yyyy-MM-dd HH:mm:ss'):null,
            acceptedReceiveNewsletterAt:accountData.acceptedReceiveNewsletterAt?$filter('date')(accountData.acceptedReceiveNewsletterAt,'yyyy-MM-dd HH:mm:ss'):null
        };

        $scope.onboardingResponse=accountData.acceptedReceiveOnboardingEmailAt?"yes":$window.localStorage.getItem('onboardingResponse');
        $scope.promoResponse=accountData.acceptedReceivePromotionalEmailAt?"yes":$window.localStorage.getItem('promoResponse');
        $scope.newsletterResponse=accountData.acceptedReceiveNewsletterAt?"yes":$window.localStorage.getItem('newsletterResponse');

        $scope.loading={
            update:false
        };

        $scope.update = function(){
            $scope.loading.update=true;
            $scope.updated=$scope.errorDiv=false;
            if($scope.onboardingResponse=='yes'){
                $scope.user.acceptedReceiveOnboardingEmailAt=$filter('date')(new Date().getTime(),'yyyy-MM-dd HH:mm:ss');
            }else{
                $scope.user.acceptedReceiveOnboardingEmailAt=null;
            }
            if($scope.promoResponse=='yes'){
                $scope.user.acceptedReceivePromotionalEmailAt=$filter('date')(new Date().getTime(),'yyyy-MM-dd HH:mm:ss');
            }else{
                $scope.user.acceptedReceivePromotionalEmailAt=null;
            }
            if($scope.newsletterResponse=='yes'){
                $scope.user.acceptedReceiveNewsletterAt=$filter('date')(new Date().getTime(),'yyyy-MM-dd HH:mm:ss');
            }else{
                $scope.user.acceptedReceiveNewsletterAt=null;
            }
            UserService.updateProfile($scope.user).
            then(function(res){
                TrackEventService.event('Funnel Events', 'Profiling Completed', 'Settings');
                $scope.updated= true;
                $scope.loading.update=false;

                $mdToast.show({
                    template:
                    '<md-toast class="md-toast" >' +
                    '<span layout="row" layout-align="start center"><i class="material-icons st-green m-r">done</i>{{"settings.updated.info"|translate}}</span>' +
                    '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });

                if($scope.onboardingResponse=='yes'){
                    $window.localStorage.setItem('onboardingResponse', 'yes');
                }else{
                    $window.localStorage.setItem('onboardingResponse', 'no');
                }

                if($scope.promoResponse=='yes'){
                    $window.localStorage.setItem('promoResponse', 'yes');
                }else{
                    $window.localStorage.setItem('promoResponse', 'no');
                }

                if($scope.newsletterResponse=='yes'){
                    $window.localStorage.setItem('newsletterResponse', 'yes');
                }else{
                    $window.localStorage.setItem('newsletterResponse', 'no');
                }


            }).
            catch(function (err) {
                $mdToast.show({
                    template:
                    '<md-toast class="md-toast" >' +
                    '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i><span translate>{{"settings.problem.verified.retry"|tranlate}}</span></span>' +
                    '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
                $scope.loading.update=false;
                $scope.errorDiv = true;
            });
        };


            $scope.onboarding=function(value){
                if(value=="yes"){
                    $scope.onboardingResponse='yes';
                }else{
                    $scope.onboardingResponse='no';
                }
            };
            $scope.promo=function(value){
                if(value=="yes"){
                    $scope.promoResponse='yes';
                }else{
                    $scope.promoResponse='no';
                }
            };
            $scope.newsletter=function(value){
                if(value=="yes"){
                    $scope.newsletterResponse='yes';
                }else{
                    $scope.newsletterResponse='no';
                }
            };

    }
    BusinesInfoCtrl.$inject=['$scope', 'UserService', 'accountData', '$window', '$mdToast', '$filter', 'TrackEventService'];
    return BusinesInfoCtrl;
});

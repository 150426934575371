'use strict';
define([], function () {
    controller.$inject = ['$translate', '__env', '$scope', '$state', '$mdMedia', '$mdDialog', '$location', '$stateParams', '$rootScope', 'WorkspaceService', 'AuthService', 'UserService'];

    function controller($translate, __env, $scope, $state, $mdMedia, $mdDialog, $location, $stateParams, $rootScope, WorkspaceService, AuthService, UserService) {
        var vm = this;

        $scope.type = 'ai-credits';
        $scope.$mdMedia = $mdMedia;

        $scope.redirectTo = $state.current.name;
        $scope.$stateParams = $stateParams;

        $scope.fragment = 'alert-signup';
        $scope.getFragment = getFragment;
        $scope.setFragment = setFragment;

        $scope.getPrice = function (amount, type = 'PREFIX') {
            if (type === 'PREFIX') {
                return Math.floor(amount / 100);
            }
            if (type === 'SUFFIX') {
                return Math.floor(amount / 100);
            }
            return amount;
        };

        var addOnPlans = {
            'ai-credits': {
                title: 'Purchase new <strong>AI credits</strong> for your workspace',
                plans: [
                    {
                        id: 'addon-additional-ai-credits-100-annual',
                        name: '<strong>{{credit}}</strong> ' + $translate.instant('aiCredits'),
                        args: {
                            credit: 100,
                        },
                        interval: 'year',
                        trialPeriodDays: 0,
                        amount: 1490,
                        textualTarget: $translate.instant('Credits usable by'),
                        textualFeatures: [
                            {label: '{{numArticles}}' + $translate.instant('AI articles'), args: {numArticles: 5}},
                            {label: '{{numAiGenerations}}' + $translate.instant('AI generations'), args: {numAiGenerations: 50}}
                        ]
                    },
                    {
                        id: 'addon-additional-ai-credits-250-annual',
                        name: '<strong>{{credit}}</strong> ' + $translate.instant('aiCredits'),
                        args: {
                            credit: 250,
                        },
                        interval: 'year',
                        trialPeriodDays: 0,
                        amount: 2990,
                        textualTarget: $translate.instant('Credits usable by'),
                        textualFeatures: [
                            {label: '{{numArticles}}' + $translate.instant('AI articles'), args: {numArticles: 12}},
                            {label: '{{numAiGenerations}}' + $translate.instant('AI generations'), args: {numAiGenerations: 125}}
                        ]

                    },
                    {
                        id: 'addon-additional-ai-credits-500-annual',
                        name: '<strong>{{credit}}</strong> ' + $translate.instant('aiCredits'),
                        args: {
                            credit: 500,
                        },
                        interval: 'year',
                        trialPeriodDays: 0,
                        amount: 4990,
                        textualTarget: $translate.instant('Credits usable by'),
                        textualFeatures: [
                            {label: '{{numArticles}}' + $translate.instant('AI articles'), args: {numArticles: 25}},
                            {label: '{{numAiGenerations}}' + $translate.instant('AI generations'), args: {numAiGenerations: 250}}
                        ]
                    },
                ]
            },
        };

        function getFragment() {
            return location.hash;
        }

        function setFragment(fragToAdd) {
            $location.hash(null);
            $scope.fragment = fragToAdd;
            $location.hash(fragToAdd);
        }

        function checkIsWorkspaceOwner() {
            $scope.isWorkspaceOwner = WorkspaceService.isOwner();
        }

        function checkCanDoTrial() {
            if (!AuthService.isAuthenticated()) {
                $scope.userIsOffline = true;
                $scope.dataIsLoaded = true;
                setFragment('alert-signup');
                return;
            }

            UserService.limits()
                .then(function (res) {
                    if (!res || !res.data || !res.data.workspace ||
                        !res.data.workspace.name || !res.data.workspace.ownerEmail) {
                        return;
                    }

                    $scope.workspaceData = {
                        ownerEmail: res.data.workspace.ownerEmail,
                        workspaceName: res.data.workspace.name
                    };
                    $scope.userHasActiveSubscription = res.data.account === 'sub';
                    $scope.canDoTrial = res.data.canDoTrial;
                    $scope.dataIsLoaded = true;

                    if ($scope.canDoTrial) {
                        setFragment('alert-trial');
                    } else {
                        setFragment('alert-upgrade');
                    }
                })
                .catch(function (err) {
                    $scope.canDoTrial = false;
                    $scope.dataIsLoaded = true;
                    setFragment('alert-upgrade');
                });
        }

        $scope.plans = [];
        $scope.title = '';
        vm.$onInit = function () {
            if (vm.type && addOnPlans[vm.type]) {
                $scope.plans = addOnPlans[vm.type].plans;
                $scope.title = addOnPlans[vm.type].title;
            }

            $scope.dataIsLoaded = false;
            checkIsWorkspaceOwner();
            checkCanDoTrial();
        };

        $scope.isWorkspaceOwner = true;
        $scope.userIsOffline = true;
        $scope.canDoTrial = true;


        $scope.hide = function () {
            $rootScope.dialogIsOpen = false;
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $rootScope.dialogIsOpen = false;
            $mdDialog.cancel();
        };

        $scope.answer = function (answer) {
            $rootScope.dialogIsOpen = false;
            $mdDialog.hide(answer);
        };

        $scope.goToPricing = function () {
            $mdDialog.hide();
            $state.go('upgrade.choose-plan');
        };

        $scope.goToCheckout = function(planId) {
            $state.go('upgrade.checkout', {alias: planId});
            $mdDialog.hide();
        };
    }

    return {
        templateUrl: 'app/components/add-on/add-on.html',
        bindings: {
            type: '=',
        },
        controller: controller
    };
});

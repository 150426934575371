define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.leadgeneration", {
                abstract: true,
                url: "/lead-generation",
                templateUrl: './app/modules/leadgeneration/leadgeneration.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.leadgeneration.landing", {
                url: "",
                controller: 'LeadGenerationLandingCtrl',
                templateUrl: './app/modules/leadgeneration/landing/landing.html',
            })
            .state("app.leadgeneration.form", {
                controller: 'LeadGenerationFormCtrl',
                url: "/form/:formId",
                templateUrl: './app/modules/leadgeneration/form/form.html',
                access: {restricted: true},
                resolve: {
                    form: ['$stateParams', '$state', '$q', 'LeadGenerationService', function ($stateParams, $state, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getStatistics({formId: $stateParams.formId})
                            .then(function (form) {
                                if (form) {
                                    dfd.resolve(form);
                                    return;
                                }
                                // user is not logged, do not proceed
                                // instead, go to a different page
                                $state.go('app.leadgeneration.landing');
                                dfd.reject();
                            }, function (err) {
                                dfd.resolve(err.data);
                                $state.go('app.leadgeneration.landing');
                            });
                        return dfd.promise;
                    }],
                    currentLangCode: ['$translate', function ($translate) {
                        return $translate('currentLangCode');
                    }]
                }
            })
            .state("app.leadgeneration.form.overview", {
                url: "/overview",
                templateUrl: './app/modules/leadgeneration/form/overview/overview.html',
                access: {restricted: true},
                controller: 'LeadGenerationFormOverviewCtrl',
                resolve: {
                    form: ['$stateParams', '$q', 'LeadGenerationService', function ($stateParams, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getStatistics({formId: $stateParams.formId})
                            .then(function (form) {
                                dfd.resolve(form);
                                return;
                            }, function (err) {
                                dfd.resolve(err.data);
                            });
                        return dfd.promise;
                    }],
                }
            })
            .state("app.leadgeneration.form.settings", {
                url: '/settings',
                templateUrl: './app/modules/leadgeneration/form/settings/settings.html',
                access: {restricted: true},
                controller: 'LeadGenerationFormSettingsCtrl',
            })
            .state("app.leadgeneration.form.settings.general", {
                url: "/general",
                templateUrl: './app/modules/leadgeneration/form/settings/general/general.html',
                access: {restricted: true},
                controller: 'LeadGenerationFormSettingsGeneralCtrl',
                resolve: {
                    settings: ['$stateParams', 'LeadGenerationService', function ($stateParams, LeadGenerationService) {
                        return LeadGenerationService
                            .getSettings({
                                formId: $stateParams.formId,
                            })
                            .then(function (res) {
                                return {
                                    name: res.name,
                                    whitelist: res.whitelist,
                                    autoLeadUnlock: res.autoLeadUnlock
                                }
                            }, function (err) {
                                console.log('err', err);
                                return null;
                            });
                    }]
                }
            })
            .state("app.leadgeneration.form.settings.whitelist", {
                url: "/whitelist",
                templateUrl: './app/modules/leadgeneration/form/settings/whitelist/whitelist.html',
                access: {restricted: true},
                controller: 'LeadGenerationFormSettingsWhitelistCtrl',
                resolve: {
                    settings: ['$stateParams', 'LeadGenerationService', function ($stateParams, LeadGenerationService) {
                        return LeadGenerationService
                            .getSettings({
                                formId: $stateParams.formId,
                            })
                            .then(function (res) {
                                return {
                                    name: res.name,
                                    whitelist: res.whitelist,
                                    autoLeadUnlock: res.autoLeadUnlock
                                }
                            }, function (err) {
                                console.log('err', err);
                                return null;
                            });
                    }]
                }
            })
            .state("app.leadgeneration.form.settings.integrations", {
                url: "/integrations",
                templateUrl: './app/modules/leadgeneration/form/settings/integrations/integrations.html',
                access: {restricted: true},
                controller: 'LeadGenerationFormSettingsIntegrationsCtrl',
            })
            .state("app.leadgeneration.form.form-generator", {
                abstract: true,
                templateUrl: './app/modules/leadgeneration/form/form-generator/layout.html',
                access: {restricted: true},
            })
             //UNO
             .state("app.leadgeneration.form.form-generator.free-checker", {
                abstract: true,
                url: "/free-checker",
                controller: 'LeadGeneratorFormFreeFormCtrl',
                templateUrl: './app/modules/leadgeneration/form/form-generator/free/layout.html',
                access: {restricted: true},
                resolve: {
                    form: ['$stateParams', '$q', 'LeadGenerationService', function ($stateParams, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getStatistics({formId: $stateParams.formId})
                            .then(function (form) {
                                if (form.formTypeId == 1) {
                                    dfd.resolve(form);
                                    return;
                                }
                                // user is not logged, do not proceed
                                // instead, go to a different page
                                $state.go('app.leadgeneration.landing');
                                dfd.reject();
                            }, function (err) {
                                dfd.resolve(err.data);
                            });
                        return dfd.promise;
                    }],
                    currentLangCode: ['$translate', function ($translate) {
                        return $translate('currentLangCode');
                    }]
                }

            })
            .state("app.leadgeneration.form.form-generator.free-checker.form", {
                url: "/",
                templateUrl: './app/modules/leadgeneration/form/form-generator/free/edit-form.html',
                access: {restricted: true},
            })
            .state("app.leadgeneration.form.form-generator.free-checker.report", {
                url: "/report",
                templateUrl: './app/modules/leadgeneration/form/form-generator/free/edit-report.html',
                access: {restricted: true},
            })

            //DUE
            .state("app.leadgeneration.form.form-generator.whitelabel-report", {
                abstract: true,
                url: "/whitelabel-report",
                controller: 'LeadGeneratorFormReportCtrl',
                templateUrl: './app/modules/leadgeneration/form/form-generator/report/layout.html',
                access: {restricted: true},
                resolve: {
                    form: ['$stateParams', '$q', 'LeadGenerationService', function ($stateParams, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getStatistics({formId: $stateParams.formId})
                            .then(function (form) {
                                dfd.resolve(form);
                                if (form.formTypeId == 2) {
                                    dfd.resolve(form);
                                    return;
                                }
                                $state.go('app.leadgeneration.landing');
                                dfd.reject();

                            }, function (err) {
                                dfd.resolve(err.data);
                            });
                        return dfd.promise;
                    }],
                    currentLangCode: ['$translate', function ($translate) {
                        return $translate('currentLangCode');
                    }]
                }
            })
            .state("app.leadgeneration.form.form-generator.whitelabel-report.form", {
                url: "/",
                templateUrl: './app/modules/leadgeneration/form/form-generator/report/edit-form.html',
                access: {restricted: true},
            })
            .state("app.leadgeneration.form.form-generator.whitelabel-report.report", {
                url: "/report",
                templateUrl: './app/modules/leadgeneration/form/form-generator/report/edit-report.html',
                access: {restricted: true},
            })

             //TRE
             .state("app.leadgeneration.form.form-generator.custom-file", {
                abstract: true,
                url: "/custom-file",
                controller: 'LeadGeneratorFormCustomFileCtrl',
                templateUrl: './app/modules/leadgeneration/form/form-generator/custom-file/layout.html',
                access: {restricted: true},
                resolve: {
                    form: ['$stateParams', '$q', 'LeadGenerationService', function ($stateParams, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getStatistics({formId: $stateParams.formId})
                            .then(function (form) {
                                dfd.resolve(form);
                                if (form.formTypeId == 3) {
                                    dfd.resolve(form);
                                    return;
                                }
                                $state.go('app.leadgeneration.landing');
                                dfd.reject();

                            }, function (err) {
                                    dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    currentLangCode: ['$translate', function ($translate) {
                        return $translate('currentLangCode');
                    }]
                }
            })
            .state("app.leadgeneration.form.form-generator.custom-file.form", {
                url: "/",
                templateUrl: './app/modules/leadgeneration/form/form-generator/custom-file/edit-form.html',
                access: {restricted: true},
            })
            .state("app.leadgeneration.form.form-generator.custom-file.report", {
                url: "/report",
                templateUrl: './app/modules/leadgeneration/form/form-generator/custom-file/edit-file.html',
                access: {restricted: true}
            })

            //
            .state("app.leadgeneration.pricing", {
                url: "/pricing-leads",
                templateUrl: './app/modules/leadgeneration/pricing/pricing.html',
                controller: 'LeadGenerationFormAnalyticsLeadsPricingCtrl',
                access: {restricted: true},
                resolve: {
                    leadsLocked: ['$stateParams', '$q', 'LeadGenerationService', function ($stateParams, $q, LeadGenerationService) {
                        var dfd = $q.defer();
                        LeadGenerationService.getLockedLeads().then(function (leads) {
                            dfd.resolve(leads);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

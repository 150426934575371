'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/preview-desktop/preview-desktop.html',
        bindings: { src: '=' },
        controller:['$scope', function($scope) {


        }]
    };
});
'use strict';
define([
    'angular',
    'modules/serpchecker/tool/tool.controller',
    'modules/serpchecker/tool/backlink/backlink.controller',
    'modules/serpchecker/tool/social/social.controller',
    'modules/serpchecker/tool/copywriting/copywriting.controller',
], function (
    angular,
    SerpCheckerToolCtrl,
    SerpCheckerBacklinkBenchmarkCtrl,
    SerpCheckerSocialBenchmarkCtrl,
    SerpCheckerCopywritingBenchmarkCtrl) {
    var app = angular.module('app.serpchecker.tool', []);
    app.controller('SerpCheckerToolCtrl',SerpCheckerToolCtrl);
    app.controller('SerpCheckerBacklinkBenchmarkCtrl',SerpCheckerBacklinkBenchmarkCtrl);
    app.controller('SerpCheckerSocialBenchmarkCtrl',SerpCheckerSocialBenchmarkCtrl);
    app.controller('SerpCheckerCopywritingBenchmarkCtrl',SerpCheckerCopywritingBenchmarkCtrl);
    return app;
});

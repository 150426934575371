'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/description/description.routes',
    'modules/seospider/exam/analysis/description/description.controller',
    'modules/seospider/exam/analysis/description/table.component',
    'modules/seospider/exam/analysis/description/duplicated/duplicated.controller',
    'modules/seospider/exam/analysis/description/all/all.module',
    'modules/seospider/exam/analysis/description/below/below.module',
    'modules/seospider/exam/analysis/description/over/over.module',
    'modules/seospider/exam/analysis/description/missing/missing.module'
],function(
    angular,
    Routes,
    MetaDescriptionCtrl,
    genericTable,
    duplicatedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.description', [
        'app.seospider.exam.analysis.description.all',
        'app.seospider.exam.analysis.description.over',
        'app.seospider.exam.analysis.description.below',
        'app.seospider.exam.analysis.description.missing'
    ]);
    app.config(Routes);
    app.controller("MetaDescCtrl",MetaDescriptionCtrl);
    app.component("descriptionTable",genericTable);
    app.controller('MetaDescDuplicatedCtrl',duplicatedCtrl);
    return app;
});
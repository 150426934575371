'use strict';
define([
    'angular',
    'modules/seochecker/components/seo-onpage-score/seo-onpage-score.component',
    'modules/seochecker/components/category-analysis/category-analysis.component',
    'modules/seochecker/components/word-cloud/word-cloud.component',
    'modules/seochecker/components/google-preview/google-preview.component',
    'modules/seochecker/components/headings-preview/headings-preview.component',
    'modules/seochecker/components/top-relevant-keywords/top-relevant-keywords.component',
    'modules/seochecker/components/exams-navigator/exams-navigator.component',
    'modules/seochecker/components/core-web-vitals/core-web-vitals.component',
    'modules/seochecker/components/multicolor-indicator/multicolor-indicator.component',
], function (angular, seoOnpageScore, categoryAnalysis, wordCloud, googlePreview, headingsPreview, topRelevantKeywords, examsNavigator, coreWebVitals, multicolorIndicator) {
    var app = angular.module('app.seochecker.ui', []);
    app.component('seoOnpageScore', seoOnpageScore);
    app.component('categoryAnalysis', categoryAnalysis);
    app.component('wordCloud', wordCloud);
    app.component('googlePreview', googlePreview);
    app.component('headingsPreview', headingsPreview);
    app.component('topRelevantKeywords', topRelevantKeywords);
    app.component('examsNavigator', examsNavigator);
    app.component('coreWebVitals', coreWebVitals);
    app.component('multicolorIndicator', multicolorIndicator);

    return app;
});

'use strict';
define([], function () {
    function Controller($state, $scope) {
        var vm = $scope;

        vm.analysisUrl = {};
        vm.currentNavItem = 0;

        vm.sessionId = parseInt($state.$current.locals.globals.$stateParams.sessionId);
        vm.projectId = parseInt($state.$current.locals.globals.$stateParams.projectId);

        vm.screenshot = {
            desktop: null,
            mobile: null
        };
    }

    Controller.$inject = ['$state', '$scope'];
    return Controller;
});

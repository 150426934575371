define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider", {
                abstract: true,
                url: "/seo-spider",
                templateUrl: './app/modules/seospider/seospider.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })

            .state("app.seospider.list", {
                url: "",
                controller: 'SeoSpiderListCtrl',
                templateUrl: './app/modules/seospider/list/list.html',
            })

            .state("app.seospider.exam", {
                abstract: true,
                templateUrl: './app/modules/seospider/exam/exam.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis", {
                url: "/:projectId/session/:sessionId?:inCrawling",
                templateUrl: './app/modules/seospider/exam/analysis/analysis.html',
                access: {restricted: true},
                controller: 'SeospiderAnalysisCtrl',
                resolve: {
                    projects: ['SeospiderService', '$stateParams', function (SeospiderService) {
                        return SeospiderService.findJobs({})
                            .then(function (projects) {
                                return projects;
                            })
                            .catch(function () {
                                return [];
                            });
                    }],
                    sessions: ['SeospiderService', '$stateParams', function (SeospiderService, $stateParams) {
                        var projectId = parseInt($stateParams.projectId);
                        return SeospiderService
                            .getSessions(projectId)
                            .then(function (res) {
                                return res.data.sessions;
                            })
                            .catch(function () {
                                return [];
                            });
                    }],
                },
                params: {
                    inCrawling: {squash: true, value: null},
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

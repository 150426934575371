import app from 'app';
import XLSX from 'xlsx';
app.service('SeospiderExportService', SeospiderExportService);
SeospiderExportService.$inject = ['$q', '$translate', 'SeospiderPagesService', '$rootScope', '$http', '__env'];
function SeospiderExportService($q, $translate, SeospiderPagesService, $rootScope, $http, __env) {
    var self = this;
    self.abort = false;
    return {
        getExport: getExport,
        abort: abort,
        isAllowed: isAllowed
    };

    function isAllowed() {
        return $http.get(__env.apiGateway + '/api/v1/export-csv-xlsx');
    }

    function abort() {
        self.abort = true;
    }

    function getExport(jobId, categories) {
        var baseParams = {jobId: jobId};
        self.abort = false;
        return isAllowed().then(function () {
            return getExportAsync(baseParams, categories, switchExport);
        }).then(function (res) {
            return exportSheetsGenerator(res);
        }).catch(function (err) {
        });
    }

    function getExportAsync(baseParams, categories, parser) {
        var defer = $q.defer();
        var resultsExport = [];

        function waterfall(baseParams, resultsExport, categories, parser) {
            if (categories[0] && categories != undefined) {
                baseParams.category = categories[0];
                parser(baseParams).then(function (res) {
                    resultsExport.push(res);
                    waterfall(baseParams, resultsExport, categories.slice(1), parser);
                }).catch(function (err) {
                });
            } else {
                defer.resolve(resultsExport);
            }
        }

        waterfall(baseParams, resultsExport, categories, parser);
        return defer.promise;
    }


    function exportSheetsGenerator(arraySheets) {
        return $q(function (resolve, reject) {
            setTimeout(function () {
                try {
                    var wb = XLSX.utils.book_new();
                    var ws;
                    for (var i = 0; i < arraySheets.length; i++) {
                        if (arraySheets[i].data.length > 0) {
                            ws = XLSX.utils.json_to_sheet(arraySheets[i].data);
                        } else {
                            ws = XLSX.utils.aoa_to_sheet(arraySheets[i].data);
                        }
                        XLSX.utils.book_append_sheet(wb, ws, arraySheets[i].name.replace(/[\\/\?*\[\]]/g, ' '));
                    }
                    var wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'};
                    var fileXlsx = XLSX.write(wb, wopts);
                    resolve(fileXlsx);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }

            }, 1);
        });
    }


    function switchExport(baseParams) {
        var params = {jobId: baseParams.jobId, category: baseParams.category};
        switch (params.category) {
            case 'seospider.title.all':
                return exportGeneric(params, SeospiderPagesService.findAllTitle).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.title.over':
                params.size = 'over';
                return exportGeneric(params, SeospiderPagesService.findAllTitle).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.title.missing':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.findAllTitle).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.title.duplicate':
                params.duplicate = true;
                return exportGeneric(params, SeospiderPagesService.findAllTitleDuplicated).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'duplicated');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.all':
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'list');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.multiple':
                params.multiple = true;
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'list');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.missing':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.below':
                params.size = 'below';
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'list');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.over':
                params.size = 'over';
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'list');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.description.duplicate':
                params.duplicated = true;
                return exportGeneric(params, SeospiderPagesService.findAllMetaDescription).then(function (res) {
                    removeAttr(res, 'id');
                    removeAttr(res, 'duplicated');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h1.all':
                return exportGeneric(params, SeospiderPagesService.findAllH1).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h1.multiple':
                params.multiple = true;
                return exportGeneric(params, SeospiderPagesService.findAllH1).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h1.missing':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.findAllH1).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h1.over':
                params.size = 'overDesktop';
                return exportGeneric(params, SeospiderPagesService.findAllH1).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h1.duplicate':
                params.duplicate = true;
                return exportGeneric(params, SeospiderPagesService.findAllH1).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h2.all':
                return exportGeneric(params, SeospiderPagesService.findAllH2).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h2.missing':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.findAllH2).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h2.over':
                params.size = 'overDesktop';
                return exportGeneric(params, SeospiderPagesService.findAllH2).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.h2.duplicate':
                return exportGeneric(params, SeospiderPagesService.findAllH2Duplicated).then(function (res) {
                    removeAttr(res, 'pageId');
                    removeAttr(res, 'duplicated');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.all':
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.low':
                params.textLevel = 'low';
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.med':
                params.textLevel = 'med';
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.high':
                params.textLevel = 'high';
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.lowRatio':
                params.lowRatio = 'low';
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.content.highRatio':
                params.lowRatio = 'high';
                params.fields = ['url', 'title', 'depth', 'wordCount', 'ratioTextCode'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.images.all':
                return exportGeneric(params, SeospiderPagesService.findAllImages).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.images.missing':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.findAllImages).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.images.missingAlt':
                params.missingAlt = true;
                return exportGeneric(params, SeospiderPagesService.findAllImages).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.images.altOver':
                params.altSize = 'overDesktop';
                return exportGeneric(params, SeospiderPagesService.findAllImages).then(function (res) {
                    removeAttr(res, 'pageId');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.https.all':
                params.fields = ['url', 'title'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.https.secure':
                params.https = true;
                params.fields = ['url', 'title'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.https.insecure':
                params.https = false;
                params.fields = ['url', 'title'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.canonical.all':
                params.status = 200;
                params.fields = ['url', 'title', 'canonical'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.canonical.defined':
                params.status = 200;
                params.canonicalMissing = false;
                params.fields = ['url', 'title', 'canonical'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.canonical.withoutCanonical':
                params.status = 200;
                params.canonicalMissing = true;
                params.fields = ['url', 'title', 'canonical'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.status.found':
                params.status = 200;
                params.fields = ['url', 'title', 'status', 'depth'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.status.redirect':
                return exportGeneric(params, SeospiderPagesService.findAllRedirect).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.status.pageNotFound':
                return exportGeneric(params, SeospiderPagesService.findAllError).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.lang.allPages':
                return exportGeneric(params, SeospiderPagesService.hreflangs).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.lang.withoutXDefault':
                params.noXDefault = true;
                return exportGeneric(params, SeospiderPagesService.hreflangs).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.lang.withoutHreflang':
                params.missing = true;
                return exportGeneric(params, SeospiderPagesService.hreflangs).then(function (res) {
                    removeAttr(res, 'id');
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.robots.all':
                params.fields = ['url', 'title', 'metaRobots', 'metaGoogleBot'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.robots.NoIndex':
                params.fields = ['url', 'title', 'metaRobots', 'metaGoogleBot'];
                params.noIndex = true;
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.robots.Noodp':
                params.fields = ['url', 'title', 'metaRobots', 'metaGoogleBot'];
                params.metaRobots = 'noodp';
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.robots.Noydir':
                params.fields = ['url', 'title', 'metaRobots', 'metaGoogleBot'];
                params.metaRobots = 'noydir';
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.twittercard.all':
                params.fields = ['url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.twittercard.optimized':
                params.fields = ['url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard'];
                 return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return !(!page.metaOgTitle || !page.metaTwitterTitle || !page.metaTwitterDescription || !page.metaTwitterImage || !page.metaTwitterCard || !page.metaTwitterSite);
                    });
                     return {name: $translate.instant(params.category), data: results};
                });
            case 'seospider.twittercard.not-optimized':
                params.fields = ['url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return (!page.metaOgTitle || !page.metaTwitterTitle || !page.metaTwitterDescription || !page.metaTwitterImage || !page.metaTwitterCard || !page.metaTwitterSite);
                    });
                    return {name: $translate.instant(params.category), data: results};
                });
            case 'seospider.opengraph.all':
                params.fields = ['url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName' ];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.opengraph.optimized':
                params.fields = ['url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName' ];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return !(!page.metaOgTitle || !page.metaOgDescription || !page.metaOgImage || !page.metaOgType || !page.metaOgSiteName || !page.metaOgUrl);
                    });
                    return {name: $translate.instant(params.category), data: results};
                });
            case 'seospider.opengraph.not-optimized':
                params.fields = ['url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName' ];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return (!page.metaOgTitle || !page.metaOgDescription || !page.metaOgImage || !page.metaOgType || !page.metaOgSiteName || !page.metaOgUrl);
                    });
                    return {name: $translate.instant(params.category), data: results};
                });
            case 'seospider.snippets.all':
                params.fields = ['url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    return {name: $translate.instant(params.category), data: res};
                });
            case 'seospider.snippets.optimized':
                params.fields = ['url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return (page && page.title && page.metaDescription && (page.title.length >= 40 ) && (page.title.length <= 69 ) && (page.metaDescription.length > 70 && page.metaDescription.length <= 160 ));
                    });
                    return {name: $translate.instant(params.category), data: results};
                });
            case 'seospider.snippets.not-optimized':
                params.fields = ['url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount'];
                return exportGeneric(params, SeospiderPagesService.findAll).then(function (res) {
                    var results = res.filter(function(page){
                        return !(page && page.title && page.metaDescription && (page.title.length >= 40 ) && (page.title.length <= 69 ) && (page.metaDescription.length > 70 && page.metaDescription.length <= 160 ));
                    });
                    return {name: $translate.instant(params.category), data: results};
                });
            default:
                break;
        }

    }

    function removeAttr(list, attrId) {
        for (var i = 0; i < list.length; i++)
            delete list[i][attrId];
    }

    function exportGeneric(params, callback) {

        var results = [];
        var dfd = $q.defer();
        params.limit = params.limit || 2000;
        params.offset = params.offset || 0;

        function reloop(results, params) {
            if (!self.abort) {
                callback(params)
                    .then(function (res) {
                        if (res.data.length === params.limit) {
                            results = results.concat(res.data);
                            params.offset += params.limit;
                            $rootScope.$emit('updateExport', res);
                            reloop(results, params);
                        } else {
                            results = results.concat(res.data);
                            $rootScope.$emit('updateExport', res);
                            dfd.resolve(results);
                        }
                    })
                    .catch(function (err) {
                        dfd.reject(err);
                    });
            }
        }

        reloop(results, params);
        return dfd.promise;
    }
}

'use strict';
define([], function () {
    function Controller($window, $scope, project, keywordsLimit, $state, $stateParams, KeywordTrackerService, $mdToast, $translate, LanguageService) {
        var vm = $scope;

        vm.showCustomToast = showCustomToast;
        vm.project = project;

        // Keywords
        vm.keywords = [];
        vm.keywordsLimit = keywordsLimit;
        vm.keywordsAlreadyAddedCounter = 0;
        vm.keywordsCount = vm.keywords.length;

        // Locations
        vm.locations = [
            {searchEngineId: 1, countryCode: LanguageService.getCountryCodeSaveOnStorage() || 'US', deviceId: 1}
        ];
        vm.locationsCount = vm.locations.length || 1;

        vm.newKeywordsToAdd = [];
        vm.newLocationsToAdd = [
            {searchEngineId: 1, countryCode: LanguageService.getCountryCodeSaveOnStorage() || 'US', deviceId: 1}
        ];
        vm.step = 1;
        vm.loading = {
            post: false
        };

        vm.keywordsAlreadyAdded = [];
        if (project && project.keywords && project.keywords instanceof Array && project.keywords.length > 0) {
            vm.keywordsAlreadyAdded = project.keywords.map(function (obj) {
                return obj.keyword;
            });
        }

        vm.goToPreviousStep = function () {
            if (vm.step === 1) {
                $state.go('app.keywordtracker.overview', {project: $stateParams.trackerProjectId});
                return;
            }

            if (vm.step === 2) {
                vm.keywordsExceeded();
            }

            vm.step = vm.step - 1;
        };

        vm.goToNextStep = function () {
            if (vm.step === 1) {
                if (!vm.keywords || vm.keywords.length === 0) {
                    showCustomToast('project.wizard.add.one.keyword', 'icon-x st-red');
                    return;
                }
                vm.locationsExceeded();
            }

            if (vm.step === 2) {
                if (!vm.keywords || vm.keywords.length === 0 || vm.isLimitExceeded()) {
                    showCustomToast('project.wizard.add.one.configuration', 'icon-x st-red');
                    return;
                }

                addKeywords(vm.project.id, vm.keywords, vm.locations);
                return;
            }

            vm.step = vm.step + 1;
        };

        function goToProject(id) {
            $state.go(
                'app.keywordtracker.overview',
                {
                    action: 'GET',
                    project: id,
                    firstLoad: 'true'
                }
            );
        }

        function addKeywords(trackerProjectId, keywords, locations) {
            vm.loading.post = true;
            KeywordTrackerService
                .addKeywords(trackerProjectId, keywords, locations)
                .then(function () {
                    showCustomToast('project.wizard.project.saved', 'icon-checkmark st-green');
                    vm.loading.post = false;

                    $window.setTimeout(function () {
                        goToProject(trackerProjectId);
                    }, 1000);
                })
                .catch(function (err) {
                    showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                    vm.loading.post = false;
                    $window.console.log(err);
                });
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        vm.isLimitExceeded = function () {
            return (vm.keywordsCount * vm.locationsCount) + vm.keywordsAlreadyAddedCounter > vm.keywordsLimit;
        };

        vm.keywordsExceeded = function () {
            vm.keywordsCount = vm.keywords.length;
            vm.loading.exceeded = vm.isLimitExceeded() && vm.locations.length <= 1;
        };

        vm.locationsExceeded = function () {
            vm.locationsCount = (vm.locations && vm.locations.length) || 1;
            vm.loading.exceeded = vm.isLimitExceeded();
        };

        vm.$watchCollection('keywords', function () {
            if (vm.step === 1) {
                vm.keywordsExceeded();
            }
        });


        vm.$watchCollection('locations', function () {
            if (vm.step === 2) {
                vm.locationsExceeded();
            }
        });

        vm.getLimits = function () {
            KeywordTrackerService.keywordCountTracker()
                .then(function (res) {
                    if (!res || !res.data || !res.data.keywords) {
                        return;
                    }

                    vm.keywordsAlreadyAddedCounter = res.data.keywords;
                })
                .catch(function (err) {
                    $window.console.log(err);
                });
        };

        vm.getLimits();
    }

    Controller.$inject = [
        '$window',
        '$scope',
        'project',
        'keywordsLimit',
        '$state',
        '$stateParams',
        'KeywordTrackerService',
        '$mdToast',
        '$translate',
        'LanguageService',
    ];
    return Controller;
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog, sub, renewal, $filter, SubscriptionService, $mdToast, TrackEventService, $translate) {
        var vm = $scope;
        vm.step = 'choose-problem';
        vm.sub = sub;
        vm.langSelected = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        vm.nextRenewal = formatDate(sub);
        vm.planInfo = getPlanInfo(sub);
        vm.cancelDialog = cancelDialog;
        vm.setStep = setStep;
        vm.sendTicket = sendTicket;
        vm.setFlow = setFlow;
        vm.deactiveRenewal = deactiveRenewal;
        vm.redirectStep = redirectStep;

        function shuffle(array) {
            var currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }

            return array;
        }

        vm.problems = shuffle([
            {label: $translate.instant('churn.flow.choose.problem.bug'), value: '1'},
            {label: $translate.instant('churn.flow.choose.problem.suite'), value: '2'},
            {label: $translate.instant('churn.flow.choose.problem.price'), value: '3'},
            {label: $translate.instant('churn.flow.choose.problem.another.service'), value: '4'},
            {label: $translate.instant('churn.flow.choose.problem.think.about'), value: '5'},
        ]).concat([{label: $translate.instant('churn.flow.choose.problem.another.reason'), value: '12'}]);

        vm.products = shuffle([
            {label: 'SEO Checker', value: 'seo-checker'},
            {label: 'SEO Spider', value: 'seo-spider'},
            {label: 'Copy Metrics', value: 'copy-metrics'},
            {label: 'Lead Generation Tool', value: 'lead-generation-tool'},
            {label: 'Keyword Rank Tracker', value: 'keyword-rank-tracker'},
            {label: 'Keyword Explorer', value: 'keyword-explorer'},
            {label: 'Keyword List Manager', value: 'keyword-list-manager'},
            {label: 'SERP Checker 360°', value: 'serp-checker-360'},
            {label: 'SEO Editor', value: 'seo-editor'},
            {label: 'Export PDF', value: 'export-pdf'},
            {label: 'Dashboard', value: 'dashboard'},
            {label: 'Workspace', value: 'workspace'},
            {label: $translate.instant('churn.flow.settings'), value: 'settings'},
            {label: $translate.instant('churn.flow.billing.info'), value: 'billing-info'},
            {label: $translate.instant('churn.flow.data.quality'), value: 'data-quality'},
            {label: $translate.instant('churn.flow.amount.data'), value: 'data-amount'},
        ]).concat([{label: $translate.instant('churn.flow.other'), value: 'other'}]);

        vm.software = shuffle([
            {label: 'Semrush', value: 'semrush',},
            {label: 'Ahrefs', value: 'ahrefs',},
            {label: 'Mangools', value: 'mangools',},
            {label: 'Screaming Frog', value: 'screaming-frog',},
            {label: 'SEOZoom', value: 'seozoom',},
            {label: 'Majestic SEO', value: 'majestic-seo',},
            {label: 'Sistrix', value: 'sistrix',},
            {label: 'SEO Ranking', value: 'seo-ranking',},
            {label: 'SEO Power Suite', value: 'seo-power-suite',},
            {label: 'Neil Patel\'s Ubersuggest', value: 'neil-patels-ubersuggest',},
            {label: 'Rank Math', value: 'rank-math',},
            {label: 'All in One SEO', value: 'all-in-one-seo',},
            {label: 'Yoast SEO', value: 'yoast-seo',},
            {label: 'MOZ', value: 'moz',},
            {label: 'SpyFu', value: 'spyfu',},
            {label: 'Serpstat', value: 'serpstat',},
            {label: 'Similarweb', value: 'similarweb',},
            {label: 'Sitebulb', value: 'sitebulb',},
            {label: 'ContentKing', value: 'content-king',},
            {label: 'Woorank', value: 'woorank',},
            {label: 'Surfer', value: 'surfer',},
            {label: 'MorningScore', value: 'morning-score',},
            {label: 'Cognitive SEO', value: 'cognitive seo',},
        ]).concat([{label: $translate.instant('churn.flow.other'), value: 'other',}]);

        vm.reasons = shuffle([
            {label: $translate.instant('churn.flow.reason.smaller.plan'), value: 'smaller-plan'},
            {label: $translate.instant('churn.flow.reason.more.time.for.try'), value: 'no-time-to-try'},
            {label: $translate.instant('churn.flow.reason.more.time.for.use'), value: 'no-time-to-use'},
            {label: $translate.instant('churn.flow.reason.price.is.adequate'), value: 'price'},
            {label: $translate.instant('churn.flow.reason.platform'), value: 'platform'},
        ]).concat([{label: $translate.instant('churn.flow.other'), value: 'other'}]);

        vm.topics = shuffle([
            {
                label: $translate.instant('churn.flow.topic.position.monitoring'),
                value: 'positioning-monitoring',
            },
            {
                label: 'Keyword Research',
                value: 'keyword-research',
            },
            {
                label: $translate.instant('churn.flow.topic.competitor.analysis'),
                value: 'competitor-analysis',
            },
            {
                label: $translate.instant('churn.flow.topic.content.analysis'),
                value: 'content-analysis',
            },
            {
                label: 'Link building',
                value: 'link-building',
            },
            {
                label: $translate.instant('churn.flow.topic.technical.seo.analysis'),
                value: 'technical-seo-analysis',
            },
            {
                label: 'Performance',
                value: 'performance',
            }
        ]).concat([{
            label: $translate.instant('churn.flow.other'),
            value: 'other',
        }]);

        vm.typeOfUpdates = [
            {
                label: $translate.instant('churn.flow.all.updates.seo.tester.online'),
                value: 'all-updates',
            },
        ];

        vm.updates = vm.typeOfUpdates.concat(vm.topics);

        vm.bugReport = {
            flow: '',
            bugTypeProduct: '',
            bugDescription: '',
            bugImg: '',
            platformProblemTopic: '',
            platformProblemDescription: '',
            platformProblemImg: '',
            priceProblemSuggestedPrice: null,
            priceProblemSuggestedPlan: '',
            anotherServiceSoftwareType: '',
            anotherServiceDescription: '',
            anotherServiceSendUpdate: '',
            aboutItReason: '',
            anotherServiceSendUpdateTool: '',
            other: ''
        };

        vm.medias = {
            logo: {}
        };

        vm.reporting = {
            text: ''
        };

        vm.reportings = {
            1: 'technical-bug',
            2: 'platform-problem',
            3: 'price-problem',
            4: 'another-service',
            5: 'think-about-it',
            6: 'talk-customer-care',
            7: 'report-sent',
            8: 'before-leaving-us',
            9: 'goodbye',
            10: 'what-happens-if',
            11: 'what-if-we-told',
            12: 'other'
        };

        vm.reportingLabels = {
            'technical-bug': 'Bug',
            'price-problem': 'Price problem',
            'platform-problem': 'Platform problem',
            'another-service': 'Another Service',
            'other': 'Other',
            'think-about-it': 'Think About Its'
        }

        vm.loading = {
            post: false
        };

        vm.reportingSelected = {
            value: null,
        }

        vm.$watch('medias.logo', function (logo) {
            if (logo && logo.resourceUrl) {
                if (vm.bugReport.flow === 'technical-bug') {
                    vm.bugReport.bugImg = logo.resourceUrl;
                }
                if (vm.bugReport.flow === 'platform-problem') {
                    vm.bugReport.platformProblemImg = logo.resourceUrl;
                }
            }
        })

        function formatDate(subscription) {
            if (subscription && subscription.currentPeriodEnd) {
                return $filter('date')(subscription.currentPeriodEnd, 'dd/MM/yyyy');
            }
            return '';
        }

        function showCustomToast(string) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="icon-x st-err m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function setFlow(idFlow) {
            idFlow = Number(idFlow);
            vm.bugReport.flow = vm.reportings[idFlow];

            if (!idFlow) {
                showCustomToast('churn.flow.error.required.option');
                return;
            }

            //Flow Technical BUg
            if (idFlow === 1) {
                idFlow = 6;
            }

            if (idFlow === 12) {
                if (!vm.bugReport.other || vm.bugReport.other === '') {
                    showCustomToast('churn.flow.error.required.option');
                    return;
                }

                idFlow = 6;
            }

            setStep(idFlow);
        }

        function getPlanInfo(subscription) {
            if (subscription && subscription.plans && subscription.plans.length) {
                //TODO se ci fossero più piani?
                return {
                    name: subscription.plans[0].name + " - " + subscription.plans[0].statementDescriptor,
                    price: numberWithCommas(subscription.plans[0].amount / 12),
                };
            }
        }

        function redirectStep() {
            if (vm.bugReport.flow === 'technical-bug') {
                setStep(1);
                return;
            }
            sendTicket();
        }

        function numberWithCommas(x) {
            // Remove decimals
            x = x / 100;

            // Format currency
            x = x.toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            });

            // Remove currency symbol
            x = x.replace('€', '').trim();

            return x;
          }

        function sendTicket(nextStep = 7) {
            var feedBack = '';
            var image = '';
            var category = '';
            if (vm.bugReport.flow === 'technical-bug') {
                feedBack = 'Product: "' + vm.bugReport.bugTypeProduct + '" - ' + vm.bugReport.bugDescription;
                image = vm.bugReport.bugImg;
                category = vm.bugReport.bugTypeProduct;
            }

            if (vm.bugReport.flow === 'platform-problem') {
                feedBack = 'Topic: "' + vm.bugReport.platformProblemTopic + '" - ' + vm.bugReport.platformProblemDescription;
                image = vm.bugReport.platformProblemImg;
                category = vm.bugReport.platformProblemTopic;
            }

            if (vm.bugReport.flow === 'think-about-it') {
                feedBack = 'Reason: ' + vm.bugReport.aboutItReason;
            }

            SubscriptionService.feedBack({
                feedback: feedBack,
                object: 'Subchurn flow - ' + vm.reportingLabels[vm.bugReport.flow] + ' - ' + category,
                image: image
            }).then(function () {
                setStep(nextStep);
            }).catch(function (err) {
                showCustomToast('churn.flow.error.feedback.send')
                console.log(err)
            })
        }

        function deactiveRenewal() {
            SubscriptionService.setRenewal(Object.assign({
                subscriptionId: sub.id,
                renewal: false
            }, vm.bugReport))
                .then(function () {
                    TrackEventService.event('Funnel Events', 'Auto Renewal - Disabled');
                    TrackEventService.gaV2Event('auto_renewal_disabled');
                    sendTicket(9);
                })
                .catch(function (err) {
                    if (err && err.data && err.data.error && err.data.error.message === 'subscription removed') {
                        showCustomToast('error.removed.subscription');
                        cancelDialog('subscription-deactivated');
                        return;
                    }
                    showCustomToast('error.generic');
                });
        }

        function cancelDialog(answer = '') {
            $mdDialog.hide(answer);
        }

        function setStep(step) {
            vm.step = vm.reportings[step];
        }
    }

    Controller.$inject = ['$scope', '$mdDialog', 'sub', 'renewal', '$filter', 'SubscriptionService', '$mdToast', 'TrackEventService', '$translate'];
    return Controller;
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.serpchecker", {
                abstract: true,
                url: "/serp-checker",
                templateUrl: './app/modules/serpchecker/serpchecker.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.serpchecker.tool", {
                abstract: true,
                reloadOnSearch: false,
                controller: 'SerpCheckerToolCtrl',
                templateUrl: './app/modules/serpchecker/tool/tool.html',
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                },
            })
            .state("app.serpchecker.tool.benchmark", {
                abstract: true,
                templateUrl: './app/modules/serpchecker/abstract.html',
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                }
            })
            .state("app.serpchecker.tool.benchmark.backlink", {
                reloadOnSearch: false,
                url: "?:keyword&:deviceId&:searchEngineId&:countryCode&:benchmark",
                controller: 'SerpCheckerBacklinkBenchmarkCtrl',
                templateUrl: './app/modules/serpchecker/tool/backlink/backlink.html',
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                },
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                }
            })
            .state("app.serpchecker.tool.benchmark.seo", {
                url: "/audit-seo?:keyword&:deviceId&:searchEngineId&:countryCode&:benchmark",
                controller: 'SerpCheckerSEOBenchmarkCtrl',
                reloadOnSearch: false,
                templateUrl: './app/modules/serpchecker/tool/seo/seo.html',
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                },
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                }
            })
            .state("app.serpchecker.tool.benchmark.copywriting", {
                url: "/audit-copywriting?:keyword&:deviceId&:searchEngineId&:countryCode&:benchmark",
                controller: 'SerpCheckerCopywritingBenchmarkCtrl',
                reloadOnSearch: false,
                templateUrl: './app/modules/serpchecker/tool/copywriting/copywriting.html',
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                },
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                }
            })
            .state("app.serpchecker.tool.benchmark.social", {
                url: "/audit-social?:keyword&:deviceId&:searchEngineId&:countryCode&:benchmark",
                controller: 'SerpCheckerSocialBenchmarkCtrl',
                reloadOnSearch: false,
                cache: true, //required
                templateUrl: './app/modules/serpchecker/tool/social/social.html',
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                },
                params: {
                    keyword: null,
                    benchmark: null,
                    countryCode: null,
                    deviceId: null,
                    searchEngineId: null
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/description/over/over.controller',
],function(
    angular,
    MDOverCtrl
){
    var app=angular.module('app.seospider.exam.analysis.description.over', []);
    app.controller("MetaDescOverCtrl",MDOverCtrl);
    return app;
});
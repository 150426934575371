define([
    'angular',
    'modules/seospider/exam/analysis/https/https.routes',
    'modules/seospider/exam/analysis/https/https.controller',
    'modules/seospider/exam/analysis/https/all/all.controller',
    'modules/seospider/exam/analysis/https/insecure/insecure.controller',
    'modules/seospider/exam/analysis/https/secure/secure.controller',
    'modules/seospider/exam/analysis/https/https-table.component'
],function(angular, Routes, SSHttpsCtrl, SSHttpsAllCtrl, SSHttpsInsecureCtrl,SSHttpsSecureCtrl, SSHttpsTable){
    var app=angular.module('app.seospider.exam.analysis.https',[]);
    app.config(Routes);
    app.controller("SSHttpsCtrl",SSHttpsCtrl);
    app.controller("SSHttpsAllCtrl",SSHttpsAllCtrl);
    app.controller("SSHttpsInsecureCtrl",SSHttpsInsecureCtrl);
    app.controller("SSHttpsSecureCtrl",SSHttpsSecureCtrl)
    app.component("httpsTable",SSHttpsTable);
    return app;
});
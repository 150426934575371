'use strict';
define([
    'angular',
    'modules/dashboard/dashboard.routes',
    'modules/dashboard/dashboard.controller',
    'modules/dashboard/components/components.module',
    'modules/dashboard/not-verified/not-verified.module',
],function(angular, routes, DashboardCtrl){
    var app = angular.module('app.dashboard', [
        'app.dashboard.ui-components',
        'access.email-verification',
    ]);
    app.config(routes);
    app.controller('DashboardCtrl', DashboardCtrl);
    return app;
});

define([], function () {
    function config($stateProvider) {
        $stateProvider.state("app.keywordexplorer", {
            abstract: true,
            url: "/keyword-explorer",
            templateUrl: './app/modules/keywordexplorer/keywordexplorer.html',
            resolve: {
                notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                    return UserService.limits(true)
                        .then(function (res) {
                            var user = AuthService.getSessionUser();
                            if (res && res.data && !res.data.isActive && user && user.email) {
                                $state.go('access.email-verification', {
                                    token: btoa(user.email)
                                });
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }],
            }
        }).state("app.keywordexplorer.landing", {
            url: "", //EMPTY ROUTE TO MAINTAIN COMPATIBILITY WITH OLD EXTERNAL LINKS
            controller: 'KeywordLandingCtrl',
        }).state("app.keywordexplorer.search", {
                url: "/search?:typeOfResearch&:keywordListId&:keywordListName&:domain&:keyword&:mode&:countryCode&:minVolume&:maxVolume&:minDifficulty&:maxDifficulty&:minCpc&:maxCpc&:minCompetition&:maxCompetition&:excludedWords&:includedWords",
                controller: 'KeywordSearchCtrl',
                templateUrl: './app/modules/keywordexplorer/search/search.html',
                //access: {restricted: true},
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }],
                },
                params: {
                    typeOfResearch: {squash: true, value: null},
                    domain: {squash: true, value: null},
                    countryCode: {squash: true, value: null},
                    keyword: {squash: true, value: null}
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define([
    'angular',
    'modules/project/dialogs/delete-project/delete-project.controller',
    'modules/project/dialogs/delete-competitor/delete-competitor.controller',
    'modules/project/dialogs/delete-location/delete-location.controller'
], function (
    angular,
    ProjectDeleteProjectCtrl,
    ProjectDeleteCompetitorCtrl,
    ProjectDeleteLocationCtrl
) {
    var app = angular.module('app.project.dialogs', []);
    
    app.controller('ProjectDeleteProjectCtrl', ProjectDeleteProjectCtrl);
    app.controller('ProjectDeleteCompetitorCtrl', ProjectDeleteCompetitorCtrl);
    app.controller('ProjectDeleteLocationCtrl', ProjectDeleteLocationCtrl);
  
    return app;
});

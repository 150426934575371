import angular from 'angular';
import WriterAssistantOptimizationWizardCtrl from 'modules/writerassistant/wizard/wizard.controller';
import writerAssistantWizardStepsCreate from 'modules/writerassistant/wizard/steps/create/create.component';
import writerAssistantWizardStepsMarketAnalysis from 'modules/writerassistant/wizard/steps/market/market.component';
import writerAssistantWizardStepsCopyCompetitorsStrategies from 'modules/writerassistant/wizard/steps/copy-competitors-strategies/copy-competitors-strategies.component';
import writerAssistantWizardStepsContentStructure from 'modules/writerassistant/wizard/steps/content-structure/content-structure.component';
import writerAssistantWizardStepsBrandGuidelines from 'modules/writerassistant/wizard/steps/brand-guidelines/brand-guidelines.component';
import writerAssistantWizardStepsHeadings from 'modules/writerassistant/wizard/steps/headings/headings.component';
import writerAssistantWizardGenerateText from 'src/app/modules/writerassistant/wizard/steps/generate-text/generate-text.component';
import editHeadings from 'modules/writerassistant/wizard/steps/headings/edit-headings/edit-headings.component';

var app = angular.module('app.writerassistant.wizard', []);
app.controller('WriterAssistantOptimizationWizardCtrl', WriterAssistantOptimizationWizardCtrl);
app.component('writerAssistantWizardStepsCreate', writerAssistantWizardStepsCreate);
app.component('writerAssistantWizardStepsMarketAnalysis', writerAssistantWizardStepsMarketAnalysis);
app.component('writerAssistantWizardStepsCopyCompetitorsStrategies', writerAssistantWizardStepsCopyCompetitorsStrategies);
app.component('writerAssistantWizardStepsContentStructure', writerAssistantWizardStepsContentStructure);
app.component('writerAssistantWizardStepsBrandGuidelines', writerAssistantWizardStepsBrandGuidelines);
app.component('writerAssistantWizardStepsHeadings', writerAssistantWizardStepsHeadings);
app.component('writerAssistantWizardGenerateText', writerAssistantWizardGenerateText);
app.component('editHeadings', editHeadings);


export default app;


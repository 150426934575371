'use strict';
define([], function () {
    function controller($scope, $state, $mdMedia, PlanDetailsService, $window, $timeout) {
        var vm = $scope;
        var $ctrl = this;
        vm.isPromoTime = PlanDetailsService.isPromoTime() && PlanDetailsService.getCurrentPromo().promoType == 'all-platform-promo';

        vm.$mdMedia = $mdMedia;

        vm.switchElements = [
            {
                label: 'Annual',
                specialLabel: 'best.value',
                specialLabelIcon: 'star',
                value: 'annually'
            },
            {
                label: 'Monthly',
                value: 'monthly'
            }
        ];

        vm.featureGroups = [
            'platform',
            'seoAnalysis',
            'seoCopywritingAssistant',
            'ai',
            'onSiteSeoAudit',
            'keywordResearch',
            'backlink',
            'positionMonitoring',
            'report',
            'leadGeneration'
        ];

        $ctrl.$onInit = function () {
            vm.billingType = $ctrl.billingType;
            $timeout(function () {
                document.querySelectorAll("#sticky-header-switch .st-switch-item")[vm.billingType == 'monthly' ? 1 : 0].click();
            }, 400);
        };

        vm.getKeyName = function (obj, idx) {
            if ($ctrl.activePlansType == 'Enterprise' || !obj) {
                return;
            }

            return Object.keys(obj)[idx];
        };

        vm.getKeyValue = function (obj, idx) {
            if ($ctrl.activePlansType == 'Enterprise') {
                return;
            }
            var value = Object.values(obj)[idx];

            if (typeof value === "boolean" && value) {
                value = '<i class="material-icons st-green icon-feature-value">done</i>';
            } else if (typeof value === "boolean" && !value) {
                value = '<i class="material-icons icon-red icon-feature-value">close</i>';
            } else {
                value = '<span class="text-20 font-bold line-height-feature-value" style="heigth: 28px">' + value + '</span>';
            }
            return value;
        };

        vm.getNumPropertyForGroup = function (obj) {
            if ($ctrl.activePlansType == 'Enterprise' || !obj) {
                return;
            }
            return Object.keys(obj).length;
        };

        vm.$watch('$ctrl.activePlansType', function () {
            vm.activePlansType = $ctrl.activePlansType;
            vm.plans = PlanDetailsService.getPlansByType($ctrl.activePlansType);
        });

        vm.$watch('$ctrl.billingType', function () {
            vm.billingType = $ctrl.billingType;
        });

        vm.$watch('$ctrl.canDoTrial', function () {
            vm.canDoTrial = $ctrl.canDoTrial;
        });

        vm.goToCheckout = function (plan) {
            var coupon = '';
            var planAlias = plan.annualAlias;
            if (vm.billingType === 'monthly') {
                planAlias = plan.monthlyAlias;
            }

            if (vm.isPromoTime) {
                coupon = PlanDetailsService.getCurrentPromo().promoCoupon;
            }
            $state.go('upgrade.checkout', {alias: planAlias, coupon: coupon});
        };

        var startShowingSrickyHeader = document.getElementById('start-showing-sticky-header');
        var endShowingSrickyHeader = document.getElementById('end-showing-sticky-header');
        var stickyHeader = document.querySelector('#sticky-header-compare');
        var fixStickyStyle = document.querySelector('#fix-sticky-style');

        $window.addEventListener('scroll', function () {
            if (startShowingSrickyHeader.getBoundingClientRect().y < 0) {
                stickyHeader.classList.add("fix-header");

                fixStickyStyle.classList.remove("hidden");
            }
            if (endShowingSrickyHeader.getBoundingClientRect().y < 0 || startShowingSrickyHeader.getBoundingClientRect().y >= 0) {
                stickyHeader.classList.remove("fix-header");

                fixStickyStyle.classList.add("hidden");
            }
        });

        vm.switchBillingType = function () {
            $ctrl.onBillingTypeSwitch({billingType: vm.billingType});
        };
    }

    controller.$inject = ['$scope', '$state', '$mdMedia', 'PlanDetailsService', '$window', '$timeout'];
    return {
        templateUrl: 'app/modules/upgrade/components/compare-plans/compare-plans.html',
        controller: controller,
        bindings: {
            plans: '<',
            activePlansType: '<',
            billingType: '=',
            canDoTrial: '<',
            onBillingTypeSwitch: '&'
        },
    };
});


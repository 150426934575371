'use strict';
define([], function () {
	
    function controller($scope, $state, $translate) {
        var ctrl = this;
        var vm = $scope;

    }

    controller.$inject = ['$scope', '$state', '$translate'];
    return {
        templateUrl: 'app/modules/settings/components/header/header-subscriptions.html',
        controller: controller
    };
    
});
'use strict';
define(['angular'], function () {
    function Controller($scope, $state, form, $mdMedia, $mdDialog) {
        var vm = $scope;
        $mdDialog.cancel();
        vm.$state = $state;
        vm.$mdMedia = $mdMedia;
        vm.form = form;
    }

    Controller.$inject = ['$scope', '$state', 'form', '$mdMedia', '$mdDialog'];
    return Controller;
});

'use strict';
define([], function () {
    function controller($scope,$state) {
        var ctrl = this;
        $scope.step = 1;
        var colors = ['#B5269E', '#26B57F', '#FF6F00', '#0BC7B5', '#db3236'];
        $scope.setPositionCards = function (index) {
            if(angular.element(window).width() < 1280){
                return '0px';
            }
            if(index < 0){
                return '10px';
            }
            return '-' + (index * 10) + 'px';
        };
        $scope.setOrderCards = function (index) {
            var order = 4 - index;
            return order;
        };

        $scope.setOpacityCards = function (competitorName) {
            return ctrl.competitorsSelected.some(function (competitor) {
                return competitorName === competitor;
            });
        };

        $scope.setStep = function (step) {
            $scope.step = step;
        };

        $scope.setColorPerCards = function (competitor,index) {
            competitor.color = colors[index];
            return colors[index];
        };

        $scope.toggle = function (item, list) {
            var idx = list.indexOf(item.linkUrl);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item.linkUrl);
            }
        };

        $scope.exists = function (item, list) {
            return list.indexOf(item.linkUrl) > -1;
        };

        $scope.editCompetitors = function(){
            $state.go('app.project.settings',{
                projectId:ctrl.project.projectId,
                activeTab:'competitors'
            })
        }

        $scope.$watchCollection('$ctrl.competitorsSelected', function (competitorsSelected) {
            if(competitorsSelected && ctrl.competitors){
                ctrl.competitorsSelected = competitorsSelected.filter(function(competitorSelected){
                    return ctrl.competitors.some(function(competitor){
                        return competitor.linkUrl == competitorSelected;
                    })
                })
            }
        });
    }

    controller.$inject = ['$scope','$state'];

    return {
        templateUrl: 'app/modules/keywordtracker/components/benchmark/benchmark.html',
        bindings: {
            competitors: '<',
            competitorsSelected: '=',
            project:'<'
        },
        controller: controller,
    };
});

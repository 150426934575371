'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/canonical/canonical.routes',
    'modules/seospider/exam/analysis/canonical/canonical.controller',
    'modules/seospider/exam/analysis/canonical/all/all.module',
    'modules/seospider/exam/analysis/canonical/defined/defined.module',
    'modules/seospider/exam/analysis/canonical/missing/missing.module',
    'modules/seospider/exam/analysis/canonical/components/components.module'
],function(angular,routes,SPcanonicalCtrl){
    var app=angular.module('app.seospider.exam.analysis.canonical', [
        'app.seospider.exam.analysis.canonical.all',
        'app.seospider.exam.analysis.canonical.defined',
        'app.seospider.exam.analysis.canonical.missing',
        'app.seospider.exam.analysis.canonical.components'
    ]);
    app.config(routes);
    app.controller('SPcanonicalCtrl',SPcanonicalCtrl);
    return app;
});
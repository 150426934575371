'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/exam/exam.html',
        bindings: {
            exam: '<',
        },
        controller: ['__env', '$scope', 'stAlertLimitService', 'AuthService', '$mdMedia', '$translate', '$timeout',
            function (__env, $scope, stAlertLimit, AuthService, $mdMedia, $translate, $timeout) {
                var ctrl = this;
                var vm = $scope;
                vm.$mdMedia = $mdMedia;

                vm.isLogged = false;
                vm.canDoTrial = true;
                vm.trialPeriodDays = __env.trialPeriodDays;

                ctrl.$onInit = function () {
                    vm.isLogged = AuthService.isAuthenticated();
                };

                vm.$watch('$ctrl.exam', function () {
                    var examId = ctrl.exam.id.split('_')[1];
                    ctrl.exam.expand = {
                        problem: true,
                        suggest: false
                    };
                    ctrl.exam.title = ctrl.exam.title || 'header-exam.' + examId;
                    ctrl.exam.colorClass = getTextColor(ctrl.exam.score);
                    ctrl.exam.iconClass = getHeaderIconByScore(ctrl.exam.score);

                    if (examId == 'serp') {
                        setSerpTextLength(ctrl.exam);
                    }

                    if (examId != "favicon") {
                        //ctrl.exam.showMoreButton = true;
                        setShowMoreButton(ctrl.exam);
                    }
                });

                ctrl.openTrial = function () {
                    stAlertLimit.open('auth-login', {
                        error:
                            {
                                request: {
                                    alias: 'seo-checker-unlock-suggestions',
                                    offline: false,
                                }
                            }
                    });
                }

                function getTextColor(score) {
                    if (score == null || score < 0)
                        return 'blue-text';
                    if (score >= 75)
                        return 'st-green-2';
                    else if (score >= 50)
                        return 'st-orange-2';
                    else return 'st-red-2';
                }

                function getHeaderIconByScore(score) {
                    if (score == null || score < 0)
                        return '';
                    if (score >= 75)
                        return 'check_circle';
                    else if (score >= 50)
                        return 'warning';
                    else
                        return 'warning';
                }

                function setSerpTextLength(exam) {
                    if (exam.preview[0].args[0].value.title && exam.preview[0].args[0].value.title.length > 70)
                        exam.preview[0].args[0].value.title = exam.preview[0].args[0].value.title.substr(0, 65) + " ...";
                    if (exam.preview[0].args[0].value.description && exam.preview[0].args[0].value.description.length > 160)
                        exam.preview[0].args[0].value.description = exam.preview[0].args[0].value.description.substr(0, 155) + " ...";
                    if (exam.preview[0].args[0].value.url && exam.preview[0].args[0].value.url.length > 70)
                        exam.preview[0].args[0].value.url = exam.preview[0].args[0].value.url.substr(0, 70) + " ...";
                }

                function setShowMoreButton(exam) {
                    if (exam.preview && exam.preview.length
                        && ((exam.preview[0].args && exam.preview[0].args.length && exam.preview[0].args[0].value && exam.preview[0].args[0].value.length > 500)
                            || (exam.preview[0].formatHtml && exam.preview[0].formatHtml.length > 500))) {
                        exam.showMoreButton = true;
                    }
                }

                vm.showMore = function (elem, id, e) {
                    e.preventDefault();

                    var target = angular.element(document.querySelector('#' + id + '-preview'));
                    var button = angular.element(document.querySelector('#' + id + '-button'));

                    if (target.hasClass('exam-preview-closed')) {
                        target.removeClass('exam-preview-closed');
                        button.html($translate.instant('seochecker.show.less') + ' <i class="material-icons f-w-900 m-l-xs" style="font-size: 24px;">expand_less</i>');
                    } else {
                        target.addClass('exam-preview-closed');
                        button.html($translate.instant('seochecker.show.more') + ' <i class="material-icons f-w-900 m-l-xs" style="font-size: 24px;">expand_more</i>');
                    }

                }
            }]
    };
});

'use strict';
define(['app'], function (app) {
    app.service('ReviewsService', ReviewsService);
    ReviewsService.$inject = ['LanguageService'];

    function ReviewsService(LanguageService) {

        var self = this;
        var currentLanguage = LanguageService.getCodeAndCountrySaveOnStorage() || 'en-US';

        self.reviews = [
            {
                category: 'Agency',
                originalLanguage: 'it',
                name: 'Marialuisa Sanfilippo',
                message: 'reviews.marialuisa.sanfilippo',
                jobTitle: 'Inbound Strategist',
                agency: 'Oneminutesite.it',
                imgFile: 'marialuisa-sanfilippo.png',
            },
            {
                category: 'Agency',
                originalLanguage: 'it',
                name: 'Matteo Pogliani',
                message: 'reviews.matteo.pogliani',
                jobTitle: 'Head of Digital',
                agency: 'Open-Box S.r.l.',
                imgFile: 'matteo-pogliani.jpg',
            },
            {
                category: 'PMI',
                originalLanguage: 'it',
                name: 'Federico Papa',
                message: 'reviews.federico.papa',
                jobTitle: 'Co-Founder / COO',
                agency: 'Ludwig',
                imgFile: 'federico-papa.jpeg',
            },
            {
                category: 'PMI',
                originalLanguage: 'it',
                name: 'Alessandro La Rosa',
                message: 'reviews.alessandro.la.rosa',
                jobTitle: 'CEO',
                agency: 'CreationDose',
                imgFile: 'alessandro-la-rosa.jpeg',
            },
            {
                category: 'PMI',
                originalLanguage: 'it',
                name: 'Bernardo Mannelli',
                message: 'reviews.bernardo.mannelli',
                jobTitle: 'Founder',
                agency: 'toolperstartup.com',
                imgFile: 'bernardo-mannelli.jpg',
            },
            {
                category: 'Corporate',
                originalLanguage: 'it',
                name: 'Luca Rodino',
                message: 'reviews.luca.rodino',
                jobTitle: 'Digital Marketing Strategist',
                agency: 'Siteground',
                imgFile: 'luca-rodino.jpeg',
            },
            {
                category: 'Corporate',
                originalLanguage: 'it',
                name: 'Carlo Camusso',
                message: 'reviews.carlo.camusso',
                jobTitle: 'Imprenditore',
                agency: 'Fattura24',
                imgFile: 'carlo-camusso.jpeg',
                
            },
            {
                category: 'Corporate',
                originalLanguage: 'en',
                name: 'Aaron Moss',
                message: 'reviews.aaron.moss',
                jobTitle: 'Business Development',
                agency: 'SalesRep.ai',
                imgFile: 'aaron-moss.jpeg',
            },
            {
                category: 'Corporate',
                originalLanguage: 'it',
                name: 'Luca Reina',
                message: 'reviews.luca.reina',
                jobTitle: 'Entrepeneur - Marketer',
                agency: 'RiguardiBoutique',
                imgFile: 'luca-reina.jpeg',
            },
            {
                category: 'Freelance',
                originalLanguage: 'it',
                name: 'Manuel Strano',
                message: 'reviews.manuel.strano',
                jobTitle: 'Developer',
                imgFile: 'manuel-strano.jpeg',
            },
            {
                category: 'Freelance',
                originalLanguage: 'it',
                name: 'Francesco Ambrosino',
                message: 'reviews.francesco.ambrosino',
                jobTitle: 'Content Specialist',
                agency: 'Socialmediacoso.it',
                imgFile: 'francesco-ambrosino.jpeg',
            },
            {
                category: 'Freelance',
                originalLanguage: 'it',
                name: 'Vittoria Averni',
                message: 'reviews.vittoria.averni',
                jobTitle: 'Marketing Manager',
                agency: 'Freelance / HFarm School',
                imgFile: 'vittoria-averni.jpeg',
            },
            {
                category: 'Freelance',
                originalLanguage: 'it',
                name: 'Antonio Santoro',
                message: 'reviews.antonio.santoro',
                jobTitle: 'Business Strategist',
                agency: 'Trasporti Gianni Iuvarà',
                imgFile: 'antonio-santoro.jpeg',
            },
            {
                category: 'Freelance',
                originalLanguage: 'en',
                onlyEnglish: true,
                name: 'Bill Dockett',
                message: 'reviews.bill.dockett',
                jobTitle: 'Mental Health and Conflict Mitigation Consultant',
                agency: 'In Balance ADR Group',
                imgFile: 'bill-dockett.jpeg',
            },
            {
                category: 'E-Commerce',
                originalLanguage: 'it',
                name: 'Marco Cardile',
                message: 'reviews.marco.cardile',
                jobTitle: 'Lead Generation Manager',
                agency: 'ShippyPro',
                imgFile: 'marco-cardile.jpeg',
            },
        ];

        // filter some reviews we only show for english language
        if (currentLanguage != 'en-US') {
            self.reviews = self.reviews.filter(function(review) {
                return !review.onlyEnglish;
            });
        }

        return {
            getAll: getAll,
        };

        /**
         * @returns {*[]|*}
         */
        function getAll() {
            return self.reviews;
        }

    }
});

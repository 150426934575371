'use strict';
define(['jquery'], function ($) {
    controller.$inject = ['$scope'];

    function controller($scope) {
        var ctrl = this;
        $scope.master = {};
        $scope.showFilterBox = false;

        function setCountFiltersActive(filters) {
            var filtersActive = {};
            filters.forEach(function (field) {
                if (field.$modelValue && field.$modelValue !== 'null') {
                    if (field.$name.startsWith("min") || field.$name.startsWith("max")) {
                        filtersActive[field.$name.substring(3)] = 0;
                    } else {
                        filtersActive[field.$name] = 0;
                    }
                }
            });
            return Object.keys(filtersActive).length;
        }

        $scope.applyFilters = function () {
            ctrl.options.filters = $scope.filters;
            ctrl.countFiltersActive = setCountFiltersActive($scope.filterForm.$$controls);
            ctrl.options.callback(ctrl.options);
        };

        $scope.filtersActive = {
            volume: false,
            costPerClick: false,
            includeKeywords: false,
            competition: false,
            keywordDifficulty: false,
            excludeKeywords: false,
            filterByTag: false,
            myPosition: false,
            estimateVisits: false,
            urlRating: false,
            domainRating: false
        };

        $scope.reset = function () {
            $scope.filters = {};
            ctrl.options.filters = $scope.filters;
            ctrl.countFiltersActive = 0;
            ctrl.options.callback(ctrl.options);
        };

        $scope.$watch('$ctrl.showElement', function (showElement) {
            $scope.showFilterBox = showElement;
        });

        $scope.$watch('$ctrl.options', function (options) {
            ctrl.options = options;
            $scope.filters = options.filters;
            $(document).ready(function () {
                ctrl.countFiltersActive = setCountFiltersActive($scope.filterForm.$$controls);
            });
        });

        $scope.$watch('$ctrl.filtersActive', function (filtersActive) {
            $scope.filtersActive = filtersActive;
        });
    }

    return {
        templateUrl: 'app/components/st-box-filter/st-box-filter.html',
        bindings: {
            showElement: '<',
            filtersActive: '<',
            buttonClass: '<',
            options: "=",
            countFiltersActive: "="
        },
        controller: controller
    };
});

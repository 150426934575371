'use strict';
define([
    'angular',
    'modules/keywordexplorer/keywordexplorer.routes',
    'modules/keywordexplorer/components/components.module',
    'modules/keywordexplorer/search/search.module',
    'modules/keywordexplorer/landing/landing.module'
], function (angular, routes) {
    var app = angular.module('app.keywordexplorer', [
        'app.keywordexplorer.ui',
        'app.keywordexplorer.search',
        'app.keywordexplorer.landing'
    ]);
    app.config(routes);
    return app;
});

define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.robots",{
                controller:'SPRobotsCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/robots/robots.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.robots.all",{
                url:"/robots",
                templateUrl: './app/modules/seospider/exam/analysis/robots/all/all.html',
                access: {restricted: true},
                controller:'SPRobotsAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            fields:['id','url','title','metaRobots','metaGoogleBot']
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.robots.noindex",{
                url:"/robots/no-index",
                templateUrl: './app/modules/seospider/exam/analysis/robots/all/all.html',
                access: {restricted: true},
                controller:'SPRobotsNoIndexCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            noIndex:true,
                            fields:['id','url','title','metaRobots','metaGoogleBot']
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.robots.noodp",{
                url:"/robots/noodp",
                templateUrl: './app/modules/seospider/exam/analysis/robots/all/all.html',
                access: {restricted: true},
                controller:'SPRobotsNoodpCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            metaRobots: 'noodp',
                            fields:['id','url','title','metaRobots','metaGoogleBot']
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.robots.noydir",{
                url:"/robots/noydir",
                templateUrl: './app/modules/seospider/exam/analysis/robots/all/all.html',
                access: {restricted: true},
                controller:'SPRobotsNoydirCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            metaRobots: 'noydir',
                            fields:['id','url','title','metaRobots','metaGoogleBot']
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});

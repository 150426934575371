/**
 * @ngdoc overview
 * @name pagespeed.service:PagespeedService
 *
 * @description
 * Aggregate External function for Pagespeed Module
 */
'use strict';
define(['app'], function (app) {
    app.service('PagespeedService', PagespeedService);
    PagespeedService.$inject = ['$q', '__env', '$translate', 'CacheManagerService'];

    /**
     * Pagespeed Service
     * @namespace PagespeedService
     */
    function PagespeedService($q, __env, $translate, CacheManagerService) {
        var apiGoogleSpeed = __env.apiGoogleSpeed;

        var blankResult = {
            score: 0,
            screenshot: {
                src: '',
            },
            formattedResults: {
                ruleResults: {},
                coreWebVitals: {},
            },
        };

        var mostVisitedWebsite = [
            'wikipedia.org',
            'facebook.com',
            'youtube.com',
            'pinterest.com',
            'google.com',
            'amazon.com',
            'twitter.com',
            'instagram.com',
            'linkedin.com',
            'reddit.com',
            'quora.com',
            'wordpress.com',
            'fandom.com',
            'apple.com',
            'yahoo.com',
            'ebay.com',
            'walmart.com',
            'nytimes.com',
            'etsy.com',
            'nih.gov',
            'researchgate.net',
            'bbc.com',
            'aliexpress.com',
            'imdb.com',
            'indeed.com',
            'yelp.com',
            'medium.com',
            'theguardian.com',
            'microsoft.com',
            'indiatimes.com',
            'forbes.com',
            'dailymotion.com',
            'reverso.net',
            'foursquare.com',
            'findglocal.com',
            'britannica.com',
            'slideshare.net',
            'spotify.com',
            'alamy.com',
            'wikihow.com',
            'businessinsider.com',
            'shutterstock.com',
            'sciencedirect.com',
            'tripadvisor.com',
            'cnn.com',
            'target.com',
            'stackexchange.com',
            'flipkart.com',
            'soundcloud.com',
            'alibaba.com',
            'cambridge.org',
            'cnet.com',
            'washingtonpost.com',
            'healthline.com',
            'yahoo.co.jp',
            'tumblr.com',
            'jstor.org',
            'mapquest.com',
            'amazon.in',
            'indiamart.com',
            'thefreedictionary.com',
            'usatoday.com',
            'brainstudy.info',
            'goodreads.com',
            'scribd.com',
            'merriam-webster.com',
            'moovitapp.com',
            'justdial.com',
            'bbc.co.uk',
            'waze.com',
            'reuters.com',
            'webmd.com',
            'yellowpages.com',
            'github.com',
            'collinsdictionary.com',
            'ebay.co.uk',
            'vymaps.com',
            'ndtv.com',
            'independent.co.uk',
            'wikiwand.com',
            'dreamstime.com',
            'issuu.com',
            'bloomberg.com',
            'wordreference.com',
            'latimes.com',
            'amazon.co.uk',
            'usnews.com',
            'npr.org',
            'wiktionary.org',
            'dictionary.com',
            'homedepot.com',
            'amazon.de',
            'wiley.com',
            'europa.eu',
            'screenrant.com',
            'rakuten.co.jp',
            'springer.com',
            'yourdictionary.com',
            'flickr.com',
            'dailymail.co.uk',
            'weebly.com',
            'redbubble.com',
            'booking.com',
            'wsj.com',
            'nature.com',
            'msn.com',
            'mayoclinic.org',
            'stackoverflow.com',
            'tiktok.com',
            'vimeo.com',
            'google.co.in',
            'zoominfo.com',
            'tandfonline.com',
            'istockphoto.com',
            'cnbc.com',
            'amazon.co.jp',
            'ameblo.jp',
            'medicalnewstoday.com',
            'theverge.com',
            'netflix.com',
            'bab.la',
            'elpais.com',
            'yandex.ru',
            'hindustantimes.com',
            'maps123.net',
            'oup.com',
            'dnb.com',
            'ign.com',
            'tokopedia.com',
            'indianexpress.com',
            'study.com',
            'amazon.fr',
            'discogs.com',
            'bestbuy.com',
            'investopedia.com',
            'glosbe.com',
            'goo.ne.jp',
            'linguee.com',
            'wikimedia.org',
            'adobe.com',
            'line.me',
            'genius.com',
            'rottentomatoes.com',
            'nbcnews.com',
            'bbb.org',
            'techradar.com',
            'indiatoday.in',
            'time.com',
            'goodhousekeeping.com',
            'glassdoor.com',
            'harvard.edu',
            'buzzfeed.com',
            'quizlet.com',
            'dw.com',
            'picclick.com',
            'educalingo.com',
            'globo.com',
            'askinglot.com',
            'insider.com',
            'uol.com.br',
            'lexico.com',
            'sagepub.com',
            'google.ca',
            'pcmag.com',
            'player.fm',
            'vk.com',
            'wordhippo.com',
            'deviantart.com',
            'digitaltrends.com',
            'mercari.com',
            'news18.com',
            'rvwab.com',
            'lowes.com',
            'cdc.gov',
            'ikea.com',
            'india.com',
            'bustle.com',
            'aminoapps.com',
            'lefigaro.fr',
            'apnews.com',
            'yumpu.com',
            'google.co.uk',
            'thehindu.com',
            'cbc.ca',
            'wattpad.com',
            'wired.com',
            'popsugar.com',
            'cosmopolitan.com',
            'mercadolivre.com.br',
            'go.com',
            'definitions.net',
            'abc.net.au',
            'yellow.place',
            'houzz.com',
            'theconversation.com',
            'pixabay.com',
            'pinterest.es',
            'gamespot.com',
            'biomedcentral.com',
            'depositphotos.com',
            'amazon.es',
            'restaurantguru.com',
            'worldorgs.com',
            'google.de',
            'core.ac.uk',
            'urbandictionary.com',
            'semanticscholar.org',
            'republicworld.com',
            'findanyanswer.com',
            'lavanguardia.com',
            'chron.com',
            'prnewswire.com',
            'manta.com',
            'hotels.com',
            'shopee.co.id',
            'as.com',
            'medlineplus.gov',
            'mdpi.com',
            'amazon.ca',
            'business.site',
            'frontiersin.org',
            'apkpure.com',
            'medicinenet.com',
            'agoda.com',
            '123rf.com',
            'samsung.com',
            'ebay.de',
            'clevelandclinic.org',
            'wayfair.com',
            'thesun.co.uk',
            'joom.com',
            'groupon.com',
            'mit.edu',
            'theatlantic.com',
            'costco.com',
            'vocabulary.com',
            'ft.com',
            'tomsguide.com',
            'sears.com',
            'tistory.com',
            'kakaku.com',
            'dummies.com',
            'sfgate.com',
            'pinterest.de',
            'techcrunch.com',
            'rollingstone.com',
            'macmillandictionary.com',
            'mercadolibre.com.ar',
            'ca.gov',
            'vulture.com',
            'shabdkosh.com',
            'makeuseof.com',
            'mercadolibre.com.co',
            'psu.edu',
            'thesaurus.com',
            'today.com',
            'hollywoodreporter.com',
            'lumenlearning.com',
            'bukalapak.com',
            'chicagotribune.com',
            'tvtropes.org',
            'www.nhs.uk',
            'musixmatch.com',
            'barnesandnoble.com',
            'elle.com',
            'express.co.uk',
            'ew.com',
            'steamcommunity.com',
            'mercadolibre.com.mx',
            'thoughtco.com',
            'fc2.com',
            'lifewire.com',
            'who.int',
            'polygon.com',
            'refinery29.com',
            'poshmark.com',
            'naver.com',
            'verywellhealth.com',
            'abc.es',
            'freepik.com',
            'lazada.com.ph',
            'metacritic.com',
            'mapcarta.com',
            'kompas.com',
            'buzzfile.com',
            'ebay-kleinanzeigen.de',
            'espn.com',
            'telegraph.co.uk',
            'firstpost.com',
            'coursehero.com',
            'thespruce.com',
            'vox.com',
            'pinterest.fr',
            'patch.com',
            'accuweather.com',
            'stanford.edu',
            'academia.edu',
            'marketwatch.com',
            'chegg.com',
            'statista.com',
            'gettyimages.com',
            'scielo.br',
            'loc.gov',
            'mirror.co.uk',
            'mxtube.net',
            'made-in-china.com',
            'engadget.com',
            'metro.co.uk',
            'ibm.com',
            'prezi.com',
            'crunchbase.com',
            'prepona.info',
            'pons.com',
            'businesswire.com',
            'standard.co.uk',
            'toppr.com',
            'theofy.world',
            'huffpost.com',
            'zillow.com',
            'newsweek.com',
            'cbsnews.com',
            'expedia.com',
            'trip.com',
            'dragonrest.net',
            'google.co.jp',
            'elespanol.com',
            'nypost.com',
            'walmart.ca',
            'newsbreak.com',
            'variety.com',
            'elmundo.es',
            'fnac.com',
            'nymag.com',
            'tribunnews.com',
            'kidshealth.org',
            'livemint.com',
            'mvorganizing.org',
            'leparisien.fr',
            'brainly.in',
            'pinterest.co.uk',
            'realsimple.com',
            'steampowered.com',
            'cdiscount.com',
            'www.gov.uk',
            'deezer.com',
            'trustpilot.com',
            'zomato.com',
            'msdmanuals.com',
            'google.fr',
            'people.com',
            'plos.org',
            'magazineluiza.com.br',
            'realtor.com',
            'lyricstranslate.com',
            'jooble.org',
            'gamesradar.com',
            'khanacademy.org',
            'amazon.it',
            'gamerant.com',
            'ichacha.net',
            'bol.com',
            'shopee.ph',
            'everydayhealth.com',
            'detik.com',
            'womenshealthmag.com',
            'rome2rio.com',
            'opencorporates.com',
            'tr-ex.me',
            'un.org',
            'timeout.com',
            'harpersbazaar.com',
            'hinative.com',
            'hatenablog.com',
            'google.com.au',
            'cornell.edu',
            'answerstoall.com',
            'medscape.com',
            'nationalgeographic.com',
            'cookpad.com',
            'shazam.com',
            'prom.ua',
            'usda.gov',
            'cbslocal.com',
            'bhg.com',
            'economictimes.com',
            'billboard.com',
            'livescience.com',
            'tenor.com',
            'ouest-france.fr',
            'unirioja.es',
            'cbr.com',
            'encyclopedia.com',
            'kompass.com',
            'tvguide.com',
            'newegg.com',
            'abril.com.br',
            'unsplash.com',
            'liputan6.com',
            'ubereats.com',
            'business-standard.com',
            'prtimes.jp',
            'birdeye.com',
            'esquire.com',
            'oxfordlearnersdictionaries.com',
            'mashable.com',
            'hindawi.com',
            'pbs.org',
            'lemonde.fr',
            'ieee.org',
            'ozon.ru',
            'gaana.com',
            'deadline.com',
            'commonsensemedia.org',
            'americanas.com.br',
            'kijiji.ca',
            'last.fm',
            'excite.co.jp',
            'macys.com',
            'mynavi.jp',
            'countryliving.com',
            'ldoceonline.com',
            'bizjournals.com',
            'howstuffworks.com',
            'newyorker.com',
            'kohls.com',
            'google.com.br',
            'irishtimes.com',
            'redalyc.org',
            'sportskeeda.com',
            'nextdoor.com',
            'google.co.id',
            'umich.edu',
            'digitalspy.com',
            'uptodown.com',
            'instructables.com',
            'allrecipes.com',
            'loc8nearme.com',
            'marca.com',
            'shopee.com.my',
            'scmp.com',
            'fao.org',
            'drugs.com',
            'everythingwhat.com',
            'pixnet.net',
            'chordify.net',
            'overstock.com',
            'rakuten.com',
            'healthgrades.com',
            'pressreader.com',
            'consumerreports.org',
            'pcgamer.com',
            'apollo.io',
            'softonic.com',
            'nsw.gov.au',
            'infobae.com',
            'behance.net',
            'smithsonianmag.com',
            'pinterest.ca',
            'livejournal.com',
            'scientificamerican.com',
            'picclick.co.uk',
            'nordstrom.com',
            'bmj.com',
            '20minutos.es',
            'rateyourmusic.com',
            'lovetoknow.com',
            'thegamer.com',
            'parade.com',
            'umn.edu',
            'nikkei.com',
            'thrillist.com',
            'hamariweb.com',
            'restaurantji.com',
            'financialexpress.com',
            'byjus.com',
            'wish.com',
            'pagesjaunes.fr',
            'hinkhoj.com',
            'amazon.com.br',
            'amarujala.com',
            'abebooks.com',
            'translated.net',
            'mail.ru',
            'zendesk.com',
            'bhphotovideo.com',
            'theculturetrip.com',
            'bobvila.com',
            'oracle.com',
            'shopee.com.br',
            'giphy.com',
            'allmusic.com',
            'glamour.com',
            'azlyrics.com',
            'comicbook.com',
            'almaany.com',
            'ziprecruiter.com',
            'youm7.com',
            'berkeley.edu',
            'asos.com',
            'eurogamer.net',
            'hp.com',
            'nme.com',
            'collider.com',
            'jiosaavn.com',
            'foodnetwork.com',
            'b2byellowpages.com',
            'womply.com',
            'arxiv.org',
            'untappd.com',
            'twitch.tv',
            'bedbathandbeyond.com',
            'focus.de',
            'ctvnews.ca',
            'lazada.co.id',
            'bandcamp.com',
            'craigslist.org',
            'argos.co.uk',
            'instacart.com',
            'answers.com',
            'menshealth.com',
            'globalnews.ca',
            'pocket-lint.com',
            'picuki.com',
            'avito.ru',
            'company-information.service.gov.uk',
            'ebay.com.au',
            'udemy.com',
            'cinemablend.com',
            'lyrics.com',
            'techtarget.com',
            'linguee.es',
            'thespruceeats.com',
            'tasteofhome.com',
            'marthastewart.com',
            'canada.ca',
            'daum.net',
            'investing.com',
            'ebay.fr',
            'legacy.com',
            'tripadvisor.co.uk',
            'allegro.pl',
            'iheart.com',
            'ny.gov',
            'hopkinsmedicine.org',
            'boardgamestips.com',
            'caranddriver.com',
            'nasa.gov',
            'weblio.jp',
            'jagran.com',
            'cheatsheet.com',
            'mundodeportivo.com',
            'uchile.cl',
            'vice.com',
            'qz.com',
            'ultimate-guitar.com',
            'manualslib.com',
            'history.com',
            'elconfidencial.com',
            'vectorstock.com',
            'francetvinfo.fr',
            'columbia.edu',
            'si.com',
            'casasbahia.com.br',
            'rozetka.com.ua',
            'hubspot.com',
            'squarespace.com',
            'unam.mx',
            'teacherspayteachers.com',
            'chip.de',
            'alberta.ca',
            'fda.gov',
            'rentalsz.com',
            'letras.mus.br',
            'geeksforgeeks.org',
            'google.it',
            'radiotimes.com',
            'blibli.com',
            'walgreens.com',
            'travelocity.com',
            'mozilla.org',
            'alamy.es',
            'picclick.fr',
            'timesnownews.com',
            'france24.com',
            'zdnet.com',
            'openedition.org',
            'thetimes.co.uk',
            'aarp.org',
            'jamanetwork.com',
            'denofgeek.com',
            'zmenu.com',
            'vogue.com',
            'idealo.de',
            'goal.com',
            'hm.com',
            'infobel.com',
            'byrdie.com',
            'justia.com',
            'foxnews.com',
            'dhgate.com',
            'texas.gov',
            'cvs.com',
            'city-data.com',
            'mojim.com',
            'namebet.cc',
            'foodyas.com',
            'kroger.com',
            'moneycontrol.com',
            'bleacherreport.com',
            'pinterest.com.mx',
            'androidauthority.com',
            'biglobe.ne.jp',
            'howtogeek.com',
            'motortrend.com',
            'gutefrage.net',
            'johnlewis.com',
            'playstation.com',
            'washington.edu',
            'pinterest.jp',
            'sciencedaily.com',
            'gizmodo.com',
            'xataka.com',
            'apa.org',
            'worldcat.org',
            'smh.com.au',
            'letras.com',
            'navitime.co.jp',
            'farfetch.com',
            'ranker.com',
            'epa.gov',
            'rogerebert.com',
            'cylex.us.com',
            'ancestry.com',
            'yell.com',
            'airbnb.com',
            'self.com',
            'weather.com',
            'nps.gov',
            'indiatvnews.com',
            'ebay.ca',
            'kayak.com',
            'slate.com',
            'gq.com',
            'imgur.com',
            'bestreviews.guide',
            'wordnik.com',
            'orbitz.com',
            'pngwing.com',
            'cbssports.com',
            'picclick.de',
            'wikidata.org',
            'lww.com',
            'hgtv.com',
            'ed.gov',
            'indiewire.com',
            'hola.com',
            'pngtree.com',
            'pikiran-rakyat.com',
            'delish.com',
            'archive.org',
            'vanityfair.com',
            'merdeka.com',
            'si.edu',
            'familyhandyman.com',
            'societe.com',
            'dell.com',
            'linternaute.com',
            'southernliving.com',
            'hypebeast.com',
            'menupix.com',
            'urdupoint.com',
            't-online.de',
            'blog.jp',
            'tabelog.com',
            'euronews.com',
            'theknot.com',
            'slideplayer.com',
            'inews.co.uk',
            'cylex.de',
            'purdue.edu',
            'bilibili.com',
            'libretexts.org',
            'docplayer.org',
            'carousell.sg',
            'ufl.edu',
            'note.com',
            'eater.com',
            'nike.com',
            'kumparan.com',
            'worldbank.org',
            'englishtenses.pro',
            'asahi.com',
            'popularmechanics.com',
            'suara.com',
            'acs.org',
            'androidcentral.com',
            'wa.gov',
            'fortune.com',
            'allure.com',
            'mawdoo3.com',
            'over-blog.com',
            'rocketreach.co',
            'eonline.com',
            'seattletimes.com',
            'upenn.edu',
            'snapdeal.com',
            'nydailynews.com',
            'aa.com.tr',
            'scielo.org.mx',
            'nicovideo.jp',
            'artstation.com',
            'abplive.com',
            'inverse.com',
            'grubhub.com',
            'apartments.com',
            'news.com.au',
            'google.es',
            'mentalfloss.com',
            'decider.com',
            'azcentral.com',
            'tripadvisor.fr',
            'tripadvisor.co',
            'linguee.fr',
            'eleconomista.es',
            'sky.com',
            'extra.com.br',
            'livehindustan.com',
            'baidu.com',
            'rd.com',
            'nasdaq.com',
            'google.com.ua',
            'repubblica.it',
            'housebeautiful.com',
            'news-medical.net',
            'livestrong.com',
            'alphr.com',
            'techtudo.com.br',
            'thewrap.com',
            'pinterest.ru',
            'canva.com',
            'vedantu.com',
            'newindianexpress.com',
            'verizon.com',
            'fool.com',
            'simplyhired.com',
            'informer.com',
            'theglobeandmail.com',
            'tutorialspoint.com',
            'uptodate.com',
            'wikimapia.org',
            'olx.ua',
            'marriott.com',
            'idntimes.com',
            'livedoor.jp',
            'patrika.com',
            'arstechnica.com',
            'grid.id',
            'marktplaats.nl',
            'mappy.com',
            'wordsense.eu',
            'doordash.com',
            'samsclub.com',
            'redbull.com',
            'dailyadvent.com',
            'makemytrip.com',
            'rochester.edu',
            'irecommend.ru',
            'namu.wiki',
            'pngegg.com',
            'imore.com',
            'aljazeera.com',
            'gumtree.com.au',
            'mapion.co.jp',
            'loopnet.com',
            'oneindia.com',
            'brainly.lat',
            'nina.az',
            'songlyrics.com',
            'oprahdaily.com',
            'smule.com',
            'shpock.com',
            'canadiantire.ca',
            'tomshardware.com',
            'khandbahale.com',
            'aljazeera.net',
            'complex.com',
            'couponxoo.com',
            'arcgis.com',
            'wisc.edu',
            'shopee.tw',
            'foodandwine.com',
            'degruyter.com',
            'corriere.it',
            'yale.edu',
            'edmunds.com',
            'calameo.com',
            'windowscentral.com',
            'sap.com',
            'superpages.com',
            'thecut.com',
            'pantip.com',
            'otzovik.com',
            'signalhire.com',
            'hunker.com',
            'rg.ru',
            'hepsiburada.com',
            'pinterest.com.au',
            'quizizz.com',
            'purewow.com',
            'wondershare.com',
            'gramho.com',
            'pnas.org',
            'anchor.fm',
            'illinois.edu',
            'stern.de',
            'free.fr',
            'gumtree.com',
            'welt.de',
            'btp.ac.id',
            'inc.com',
            'yellowpages.ca',
            'oeffnungszeitenbuch.de',
            'latercera.com',
            'ceneo.pl',
            'hitc.com',
            'thekitchn.com',
            'terra.com.br',
            'betterhealth.vic.gov.au',
            'motor1.com',
            'lawinsider.com',
            'masterclass.com',
            'eltiempo.com',
            'ivoox.com',
            'songmeanings.com',
            'amazonaws.com',
            'sciencing.com',
            'psychologytoday.com',
            'uchicago.edu',
            'google.co.kr',
            'autoblog.com',
            'heb.com',
            'myfitnesspal.com',
            'msu.edu',
            'songfacts.com',
            'sbs.com.au',
            'hotpepper.jp',
            'impress.co.jp',
            'tidal.com',
            'giftly.com',
            'wikiqube.net',
            'bhaskar.com',
            'bbcgoodfood.com',
            'vecteezy.com',
            'epicurious.com',
            'udn.com',
            'lyst.com',
            'marieclaire.com',
            'rae.es',
            'hbr.org',
            'trulia.com',
            'uofmhealth.org',
            'fastcompany.com',
            'elcomercio.pe',
            'yelp.ca',
            'opentran.net',
            'angi.com',
            'worthpoint.com',
            'infoplease.com',
            'heavy.com',
            'cars.com',
            'travelandleisure.com',
            'ecured.cu',
            'ebay.it',
            'kbb.com',
            'globenewswire.com',
            'pcgamesn.com',
            'dnaindia.com',
            'ingles.com',
            'xbox.com',
            'gsmarena.com',
            'rt.com',
            'trshow.info',
            'nbcsports.com',
            'lenovo.com',
            'roku.com',
            'jalan.net',
            'verywellmind.com',
            'looper.com',
            'verywellfit.com',
            'showmelocal.com',
            'townandcountrymag.com',
            'journaldesfemmes.fr',
            'powerthesaurus.org',
            'audible.com',
            'locale.online',
            'mercurynews.com',
            'huawei.com',
            'pixiv.net',
            'justwatch.com',
            'aajtak.in',
            'superuser.com',
            'europapress.es',
            'tempo.co',
            'kmart.com',
            'manualzz.com',
            'menards.com',
            'venturebeat.com',
            'patient.info',
            'itmedia.co.jp',
            'learnersdictionary.com',
            'stuff.co.nz',
            'myntra.com',
            'utexas.edu',
            'sportingnews.com',
            'synonyms.com',
            'lensa.com',
            'vg247.com',
            'sciencemag.org',
            'bigl.ua',
            'songtexte.com',
            'spanishdict.com',
            'hurriyet.com.tr',
            'osu.edu',
            'cnnindonesia.com',
            'yellowpages.net',
            'baltimoresun.com',
            'skysports.com',
            'faz.net',
            'vagalume.com.br',
            'nhk.or.jp',
            'cargurus.com',
            'allocine.fr',
            'food.com',
            'fril.jp',
            'cancioneros.com',
            'apartmenttherapy.com',
            'ludwig.guru',
            'souq.com',
            'pinterest.it',
            'lyricsjonk.com',
            'archiveofourown.org',
            'cancer.gov',
            'allevents.in',
            'otto.de',
            'shopee.com.mx',
            'sulekha.com',
            'pasttenses.com',
            'firstcry.com',
            'scielo.org.co',
            'clarin.com',
            'hellomagazine.com',
            'pontofrio.com.br',
            'lonelyplanet.com',
            'mtv.com',
            'submarino.com.br',
            'almrsal.com',
            'tripsavvy.com',
            'kknews.cc',
            'top10place.com',
            'usgs.gov',
            'aafp.org',
            'prevention.com',
            'rxlist.com',
            'takealot.com',
            'linternaute.fr',
            'zara.com',
            'entrepreneur.com',
            'dribbble.com',
            'voanews.com',
            'planetofhotels.com',
            'sueddeutsche.de',
            'bu.edu',
            'eventbrite.com',
            'olx.com.br',
            'karger.com',
            'fineartamerica.com',
            'coursera.org',
            'usmagazine.com',
            'desertcart.in',
            'the-sun.com',
            'architecturaldigest.com',
            'tesco.com',
            'booktopia.com.au',
            'food52.com',
            'g2.com',
            'javatpoint.com',
            'tradeindia.com',
            'myrecipes.com',
            'synonym.com',
            'estadao.com.br',
            'elperiodico.com',
            'macrumors.com',
            'jimdofree.com',
            'rtve.es',
            'etonline.com',
            'teenvogue.com',
            'olx.in',
            'elsevier.com',
            'next.co.uk',
            'towardsdatascience.com',
        ];

        var hasScreenshot = function (pageSpeedResult) {
            if (!pageSpeedResult ||
                !pageSpeedResult.lighthouseResult ||
                !pageSpeedResult.lighthouseResult.audits
            ) {
                return false;
            }

            var audits = pageSpeedResult.lighthouseResult.audits;
            if (!('final-screenshot' in audits)) {
                return false;
            }

            var details = audits['final-screenshot'].details;
            if (!details) {
                return false;
            }

            var {data} = details;

            return !!data;
        };

        var getScreenshot = function (pageSpeedResult) {
            if (!hasScreenshot(pageSpeedResult)) {
                return {
                    src: '',
                };
            }

            var audits = pageSpeedResult.lighthouseResult.audits;
            return {
                src: audits['final-screenshot'].details.data,
            };
        };

        var pageSpeedHasScore = function (pageSpeedResult) {
            return !!(pageSpeedResult &&
                pageSpeedResult.lighthouseResult &&
                pageSpeedResult.lighthouseResult.categories &&
                pageSpeedResult.lighthouseResult.categories.performance &&
                pageSpeedResult.lighthouseResult.categories.performance.score);
        };

        var getScore = function (pageSpeedResult) {
            if (!pageSpeedHasScore(pageSpeedResult)) {
                return 0;
            }

            return (pageSpeedResult.lighthouseResult.categories.performance.score * 100);
        };

        var getPageSpeedLightUrl = function (url, device = 'desktop') {
            if (!url.startsWith('http')) {
                url = "https://" + url;
            }

            return apiGoogleSpeed + encodeURIComponent(url) + '&screenshot=true&strategy=' + device + '&locale=en';
        };

        var getPageSpeedUrl = function (url, device = 'desktop', language = 'en') {
            if (!url.startsWith('http')) {
                url = "https://" + url;
            }

            return apiGoogleSpeed + encodeURIComponent(url) +
                '&screenshot=true&strategy=' + device + '&category=performance&category=accessibility&category=best-practices&category=seo&locale=' + language + '';
        };

        function getDomain(url) {
            if (!url) {
                return '';
            }

            var result;
            var match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
            if (match) {
                result = match[1];
                match = result.match(/^[^\.]+\.(.+\..+)$/);
                if (match) {
                    result = match[1];
                }
            }
            return result;
        }

        this.getScore = getScore;

        this.getScreenshot = function (url, device, dayCache) {
            var deferred = $q.defer();
            var pageSpeedUrl = getPageSpeedLightUrl(url, device);
            var hash = CacheManagerService.hash(pageSpeedUrl + '-screenshot');

            if (!fetch) {
                return $q.resolve(blankResult);
            }

            var cachedContent = CacheManagerService.get(hash);
            if (cachedContent) {
                return $q.resolve(cachedContent);
            }

            fetch(pageSpeedUrl)
                .then(function (response) {
                    return response.json();
                })
                .then(function (res) {
                    var screenshot = getScreenshot(res);
                    if (screenshot && screenshot.src) {
                        CacheManagerService.set(hash, screenshot, 60 * 60 * 24 * (dayCache || 1));
                    }

                    deferred.resolve(screenshot);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(blankResult);
                });

            return deferred.promise;
        };

        this.getAnalysis = function (url, device, language) {
            var deferred = $q.defer();
            var pageSpeedUrl = getPageSpeedUrl(url, device, language);
            var hash = CacheManagerService.hash(pageSpeedUrl);

            if (!fetch) {
                return $q.resolve(blankResult);
            }

            var domain = getDomain(url);
            if (mostVisitedWebsite.indexOf(domain) >= 0) {
                var cachedContent = CacheManagerService.get(hash);

                if (cachedContent) {
                    return $q.resolve(cachedContent);
                }
            }

            fetch(pageSpeedUrl)
                .then(function (response) {
                    return response.json();
                })
                .then(function (res) {
                    if (getScore(res) > 0 && mostVisitedWebsite.indexOf(domain) >= 0) {
                        CacheManagerService.set(hash, res, 60 * 60 * 24 * 2);
                    }

                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(blankResult);
                });

            return deferred.promise;
        };
    }
});


define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.opengraph",{
                abstract:true,
                controller:'OpengraphCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/opengraph/opengraph.html',
                access: {restricted: true},                
            })            
            .state("app.seospider.exam.analysis.opengraph.all",{
                url:"/opengraph",
                templateUrl: './app/modules/seospider/exam/analysis/opengraph/all/all.html',
                access: {restricted: true},
                controller:'OpengraphAllCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findAll({
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })          
            
          
            .state("app.seospider.exam.analysis.opengraph.optimized",{
                url:"/opengraph/optimized",
                templateUrl: './app/modules/seospider/exam/analysis/opengraph/optimized/optimized.html',
                access: {restricted: true},
                controller:'OpengraphOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.opengraph.not-optimized",{
                url:"/opengraph/not-optimized",
                templateUrl: './app/modules/seospider/exam/analysis/opengraph/not-optimized/not-optimized.html',
                access: {restricted: true},
                controller:'OpengraphNotOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl', 'metaOgType', 'metaOgSiteName']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});



'use strict';
define(['app'], function (app) {
    app.component('stNgGrid', {
        templateUrl: 'app/components/st-ng-grid/st-ng-grid.html',
        controller: controller,
        bindings: {
            options: "="
        }
    });

    controller.$inject = ['__env', '$scope', '$filter', '$sce', '$templateRequest'];

    function controller(__env, $scope, $filter, $sce, $templateRequest) {
        var ctrl = this;
        var message;
        var pagedItems = [];

        $scope.columnsHeader = '';
        $scope.rowHeader = '';
        $scope.tableContent = '';
        $scope.tableFooter = '';
        $scope.itemsPerPage = 10;
        $scope.gap = 3;
        $scope.pagedItems = [];
        $scope.currentPage = 0;

        $scope.$watch('$ctrl.options', function (options) {
            $scope.sort = {
                column: "",
                descending: false
            };

            if (options) {
                buildHeader();
                ctrl.options = options;
                options.callback(options.query)
                    .then(function (res) {
                        message = res.data.message;
                        $scope.itemsPerPage = ctrl.options.numberOfElements;
                        $scope.totalRows = ctrl.options.totalRows;
                        $scope.itemsForTheTable = ctrl.options.queries = res.data.rows;
                        buildPagination($scope.itemsForTheTable, ctrl.options.sortBy, $scope.sort.descending);
                        buildFooter(res.data.rows.length);
                        ctrl.options.loading = false;
                    });
            }
        });

        function filtersItems(rows, sortBy, sortDescending) {
            var stringOperatorForOrder = '-';
            var stringOperatorForNull = '!';
            $scope.itemsForTheTable = $filter('orderBy')(rows, [
                '' + stringOperatorForNull.concat('', sortBy) + '',
                '' + stringOperatorForOrder.concat('', sortBy) + ''
            ], sortDescending);
            buildPagination($scope.itemsForTheTable);
        }

        function buildPagination(rows) {
            $scope.pagedItems = [];
            $scope.currentPage = 0;
            if (!rows || rows.length <= 0) {
                buildBody(rows);
                return;
            }

            for (var i = 0; i < $scope.itemsForTheTable.length; i++) {
                if (i % $scope.itemsPerPage === 0) {
                    pagedItems[Math.floor(i / $scope.itemsPerPage)] = [$scope.itemsForTheTable[i]];
                    $scope.pagedItems[Math.floor(i / $scope.itemsPerPage)] = [$scope.itemsForTheTable[i]];
                } else {
                    $scope.pagedItems[Math.floor(i / $scope.itemsPerPage)].push($scope.itemsForTheTable[i]);
                    pagedItems[Math.floor(i / $scope.itemsPerPage)].push($scope.itemsForTheTable[i]);
                }
            }
            /* Gap defines the number of pages shown */
            if ($scope.pagedItems.length === 1) {
                $scope.gap = 0;
            } else if ($scope.pagedItems.length === 2) {
                $scope.gap = 2;
            } else if ($scope.pagedItems.length >= 3) {
                $scope.gap = 3;
            }
            buildBody(pagedItems[$scope.currentPage]);
        }

        function buildHeader() {
            $scope.rowHeader = '';
            $scope.columnsHeader = '';
            for (var headerTemplate of ctrl.options.headerTemplates) {
                if (headerTemplate.customTemplate) {
                    $scope.columnsHeader += headerTemplate.customTemplate;
                    continue;
                }
                $scope.columnsHeader += defaultHeaderTemplates.text(
                    headerTemplate.title,
                    headerTemplate.sortBy,
                    headerTemplate.tooltip
                );
            }
            $scope.rowHeader = '<tr>' + $scope.columnsHeader + '</tr>';
        }

        var defaultHeaderTemplates = {
            text: function text(text, sortBy, tooltip) {
                var htmlTooltip = '';
                if (tooltip) {
                    htmlTooltip = '<md-tooltip class="md-tooltip-expandable text-xs p-sm max-width-tooltip" ' +
                        '               md-direction="top">{{"' + tooltip + '" | translate}}' +
                        '          </md-tooltip>';
                }
                if (sortBy) {
                    return '<th my-click-once ng-class=\'selectedCls(" ' + sortBy + ' ")\'' +
                        '   ng-click=\'changeSorting("' + sortBy + '")\'>' +
                        '   <div> ' + htmlTooltip + '' +
                        '       {{"' + text + '" | translate}}' +
                        '   </div>' +
                        '   <div ng-if=\'sort.column=="' + sortBy + '"\'>' +
                        '       <i ng-if=\'sort.descending\' class=\'icon-collapse-top\'></i>' +
                        '       <i ng-if=\'!sort.descending\' class=\'icon-collapse-down\'></i>' +
                        '   </div>' +
                        '</th>';
                } else {
                    return '' +
                        '<th>' +
                        '   <div>' + htmlTooltip + '' +
                        '       {{"' + text + '" | translate}}' +
                        '   </div>' +
                        ' </th>';
                }
            }
        };

        function buildBody(rows) {
            $scope.tableContent = '';
            if (!rows || rows.length <= 0) {
                defaultBodyTemplateRow.noData();
                return;
            }
            switch (message.code) {
                case 'LIMIT':
                    defaultBodyTemplateRow.limit(rows);
                    break;
                default:
                    $scope.tableContent += buildBodyRows(rows);
            }
        }

        var defaultBodyTemplateRow = {
            text: function (field) {
                field = field ? '<div>' + field + '</div>' : '<div>N/A</div>';
                return '<td>' + field + '</td>';
            },
            noData: function () {
                var templateUrl = '';
                switch (message.code) {
                    case 'NO_ENOUGH_CREDITS':
                        templateUrl = $sce.getTrustedResourceUrl('app/components/st-ng-grid/templates/row-no-data.html');
                        break;
                    case 'LIMIT':
                        templateUrl = $sce.getTrustedResourceUrl('app/components/st-ng-grid/templates/row-no-data.html');
                        break;
                    default:
                        templateUrl = $sce.getTrustedResourceUrl('app/components/st-ng-grid/templates/row-no-data.html');
                }
                $templateRequest(templateUrl).then(function (res) {
                    $scope.title = message.title;
                    $scope.subTitle = message.subTitle;
                    $scope.icon = message.icon;
                    $scope.templateCallToAction = message.templateCallToAction;
                    $scope.tableContent += res;
                });
                buildFooter(0);
            },
            limit: function (rows) {
                var templateUrl = $sce.getTrustedResourceUrl('app/components/st-ng-grid/templates/row-data-limit.html');
                $templateRequest(templateUrl).then(function (res) {
                    $scope.title = message.title;
                    $scope.subTitle = message.subTitle;
                    $scope.templateCallToAction = message.templateCallToAction;
                    $scope.tableContent = buildBodyRows(rows) + res;
                });
            },
        };

        function buildBodyRows(rows) {
            var index = 0;
            var tableRows = '';
            for (var row of rows) {
                var fields = '';
                for (var rowTemplate of ctrl.options.templateRow.fields) {
                    if (rowTemplate.customTemplateField) {
                        fields += rowTemplate.customTemplateField(row, row[rowTemplate.field], index);
                    } else {
                        fields += defaultBodyTemplateRow.text(row[rowTemplate.field]);
                    }
                }
                tableRows += ctrl.options.templateRow.customTemplate(row, fields)
                index++;
            }
            return tableRows;
        }

        function buildFooter(totalItem) {
            $scope.tableFooter = '';
            if (totalItem === 0 && ctrl.options.templateFooter) {
                defaultFooterTemplate.noData();
                return;
            }
            if ($scope.totalRows === 0) {
                $scope.totalRows = totalItem;
            }
            if (ctrl.options.templateFooter) {
                $scope.tableFooter = ctrl.options.templateFooter.customTemplate(
                    $scope.totalRows,
                    totalItem,
                    $scope.pagedItems[$scope.currentPage],
                    pagedItems.length
                );
            } else {
                $scope.tableFooter = '';
            }
        }

        var defaultFooterTemplate = {
            noData: function () {
                $scope.tableFooter = '<tr class="no-data"></tr>';
            },
        };

        $scope.prevPage = function () {
            if ($scope.currentPage > 0) {
                $scope.currentPage--;
            }
            buildBody(pagedItems[$scope.currentPage]);
        };

        $scope.range = function (size, start, end) {
            var ret = [];
            if (size < end) {
                end = size;
                start = size - $scope.gap;
            }
            for (var i = start; i < end; i++) {
                ret.push(i);
            }
            return ret;
        };

        $scope.nextPage = function () {
            if ($scope.currentPage < $scope.pagedItems.length - 1) {
                $scope.currentPage++;
            }
            buildBody(pagedItems[$scope.currentPage]);
        };

        $scope.setPage = function () {
            $scope.currentPage = this.n;
            buildBody(pagedItems[$scope.currentPage]);
        };

        $scope.selectedCls = function (column) {
            return column === $scope.sort.column && 'sort-' + $scope.sort.descending;
        };

        $scope.changeSorting = function (column) {
            var sort = $scope.sort;
            if (sort.column === column) {
                sort.descending = !sort.descending;
            } else {
                sort.column = column;
                sort.descending = false;
            }
            filtersItems($scope.itemsForTheTable, column, sort.descending);

        };
    }
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/keywordexplorer/components/trend-searches/trend-searches.html',
        bindings: {
            avgVolume: '<',
            history: '<',
            loading: '<',
            locked: '<',
        },
        controller: ['$scope', '$translate', function ($scope, $translate) {
            var maxVolume = 0;

            $scope.$watch('$ctrl.avgVolume', function (avgVolume) {
                $scope.monthly = avgVolume;
                $scope.yearly = avgVolume * 12;
            });

            $scope.$watch('$ctrl.history', function (history) {
                var scale = {
                    yAxes: [{
                        id: 'y-axis-1',
                        type: 'linear',
                        display: false,
                        position: 'right',
                        ticks: {
                            maxTicksLimit: 1,
                            fontSize: 13,
                            fontColor: "#6e7c8a",
                            fontFamily: " 'Helvetica Neue', Helvetica, Arial, sans-serif",
                            beginAtZero: true,
                        },
                        gridLines: {
                            color: "#EFF2F5",
                            drawBorder: false,
                            zeroLineColor: "#EFF2F5"
                        }
                    }],
                    xAxes: [{
                        id: 'x-axis-1',
                        display: false,
                    }]
                };

                $scope.chartLabels = ['January 2019', 'February 2019', 'March 2019', 'April 2019', 'May 2019', 'June 2019',
                    'July 2019', 'August 2019', 'September 2019', 'October 2019', 'November 2019', 'December 2019'
                ];
                $scope.chartSeries = [$translate.instant('keyword.exp.trend.searches')];
                $scope.chartData = [
                    [50, 24, 50, 70, 50, 60, 80, 32, 78, 42, 53, 50]
                ];
                $scope.chartColors = ["#C2D1D9"];
                $scope.chartOpts = {
                    scales: scale,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false,
                    },
                    hover: false,
                    elements: {
                        line: {
                            backgroundColor: 'transparent',
                            borderCapStyle: 'square',
                            borderColor: "#C2D1D9",
                            borderWidth: 2,
                            cubicInterpolationMode: 'monotone',
                            borderJoinStyle: 'miter',
                            fill: false,
                        },
                        point: {
                            radius: 0,
                            HitRadius: 1,
                            hoverRadius: 2,
                            borderWidth: 1,
                            borderColor: "#C2D1D9",
                            backgroundColor: "#C2D1D9",
                            hoverBackgroundColor: "#C2D1D9"
                        }
                    },
                };

                function trendParser(history) {
                    if (!history || history === '{}') {
                        history = {};
                    }

                    var isStringArray = history && typeof history === 'string' && history.indexOf('{') !== -1;
                    if (isStringArray) {
                        history = JSON.parse(history);
                    }

                    var detections = [];
                    try {
                        for (const datetime in history) {
                            var year = datetime.substring(0, 4);
                            var month = datetime.substring(datetime.length, datetime.length - 2);
                            var volume = null;

                            if (history[year + '' + month]) {
                                volume = history[year + '' + month];
                            } else if ((history[year + '-' + month])) {
                                volume = history[year + '-' + month];
                            }

                            detections.push({
                                timestamp: year + '-' + month,
                                // volume: Number(volume),
                                volume: volume,
                            });
                        }

                        detections.sort(function (a, b) {
                            return new Date(b.timestamp) - new Date(a.timestamp);
                        });
                    } catch (err) {
                        console.error(err);
                    }

                    var arrayVolume = [];
                    var arrayTimestamp = [];
                    var sumVolume = 0;
                    maxVolume = 0;

                    detections.forEach(function (detection) {
                        var formattedVolume = parseInt(detection.volume);

                        if (formattedVolume > maxVolume) {
                            maxVolume = formattedVolume;
                        }

                        arrayVolume.push(formattedVolume);
                        arrayTimestamp.push(detection.timestamp);
                        sumVolume += formattedVolume;
                    });

                    return {
                        volumes: arrayVolume,
                        timestampes: arrayTimestamp,
                        monthly: (sumVolume / 12).toFixed(0),
                        yearly: sumVolume
                    };
                }

                if (history) {
                    var detections = trendParser(history);
                    $scope.chartData = [detections.volumes];
                    $scope.chartLabels = detections.timestampes;
                    $scope.chartColors = ["#2296F3"];
                    $scope.chartSeries = [$translate.instant('keyword.exp.trend.searches')];

                    $scope.chartOpts.scales = scale;
                    $scope.chartOpts.scales.yAxes[0].ticks.max = parseInt((maxVolume + (maxVolume / 20)));

                    $scope.chartOpts.tooltips = {
                        titleFontFamily: "'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif",
                        titleFontSize: 13,
                        bodyFontFamily: "'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif",
                        bodyFontSize: 14,
                        bodyFontStyle: 'bold',
                        backgroundColor: "rgba(19, 13, 65, 0.9)",
                    };

                    $scope.chartOpts.elements = {
                        line: {
                            backgroundColor: '#C8E9FF',
                            borderCapStyle: 'square',
                            borderColor: "#2296F3",
                            borderWidth: 2,
                            cubicInterpolationMode: 'monotone',
                            borderJoinStyle: 'miter',
                            fill: true,
                            tension: 0.5,
                        },
                    };
                }
            });
        }]
    };
});

'use strict';
define([
    'angular',
    'modules/leadgeneration/components/dialogs/create-form/create-form.controller',
    'modules/leadgeneration/components/dialogs/rename-form/rename-form.controller',
    'modules/leadgeneration/components/dialogs/delete-form/delete-form.controller',
    'modules/leadgeneration/components/dialogs/get-embed-code/get-embed-code.controller',
    'modules/leadgeneration/components/dialogs/unlock-leads/unlock-leads.controller',
    'modules/leadgeneration/components/dialogs/add-custom-field/add-custom-field.controller',
    'modules/leadgeneration/components/dialogs/edit-custom-field/edit-custom-field.controller'
],function(
    angular,
    leadGenerationDialogCreateFormCtrl,
    leadGenerationDialogRenameFormCtrl,
    leadGenerationDialogDeleteFormCtrl,
    leadGenerationDialogGetEmbedCodeCtrl,
    leadGenerationDialogUnlockLeadCtrl,
    leadGenerationAddCustomFieldDialogCtrl,
    leadGenerationEditCustomFieldDialogCtrl){

    var app=angular.module('app.leadgeneration.ui.dialogs', []);
    app.controller('leadGenerationDialogCreateFormCtrl',leadGenerationDialogCreateFormCtrl);
    app.controller('leadGenerationDialogRenameFormCtrl',leadGenerationDialogRenameFormCtrl);
    app.controller('leadGenerationDialogDeleteFormCtrl',leadGenerationDialogDeleteFormCtrl);
    app.controller('leadGenerationDialogGetEmbedCodeCtrl',leadGenerationDialogGetEmbedCodeCtrl);
    app.controller('leadGenerationDialogUnlockLeadCtrl',leadGenerationDialogUnlockLeadCtrl);
    app.controller('leadGenerationAddCustomFieldDialogCtrl',leadGenerationAddCustomFieldDialogCtrl);
    app.controller('leadGenerationEditCustomFieldDialogCtrl',leadGenerationEditCustomFieldDialogCtrl);

    return app;
});

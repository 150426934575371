'use strict';
define(['angular'], function (ng) {
    function Controller($scope, $stateParams, $q, results, jobId, Pages, $rootScope, $translate) {
        var vm = $scope;
        vm.query = '';

        var notOptimizedOpengraph = [];
        notOptimizedOpengraph = results.data.filter(function (page) {
            return (!page.metaOgTitle || !page.metaOgDescription || !page.metaOgImage || !page.metaOgType || !page.metaOgSiteName || !page.metaOgUrl);
        });

        vm.pages = notOptimizedOpengraph;

        vm.jobId = jobId;
        vm.tabs = {
            categoryTitle: 'Opengraph',
            categoryState: 'app.seospider.exam.analysis.opengraph',
            fields: [
                {label: 'seospider.opengraph.all', icon: 'icon icon-list', uiSref: '.all'},
                {label: 'seospider.opengraph.optimized', icon: 'icon icon-list', uiSref: '.optimized'},
                {label: 'seospider.opengraph.not-optimized', icon: 'icon icon-list', uiSref: '.not-optimized'},
            ]
        };
        vm.getColorByTitleLength = getColorByTitleLength;
        vm.getColorByDescriptionLength = getColorByDescriptionLength;
        vm.getPercentageTitle = getPercentageTitle;
        vm.getPercentageDescription = getPercentageDescription;
        vm.secureUrl = secureUrl;

        function getPercentageTitle(len) {
            if (len > 90)
                return 100;
            return (len * 100) / 90;
        }

        function getPercentageDescription(len) {
            len = parseInt(len);
            if (isNaN(len)) return 0;
            if (len > 200)
                return 100;
            return (len * 100) / 200;
        }

        function getColorByTitleLength(titleLength) {
            if (titleLength >= 90 && titleLength >= 60)
                return 'green-text';
            if (titleLength < 60 && titleLength >= 30)
                return 'text-darken-1 orange-text';
            else return 'red-text';
        }

        function getColorByDescriptionLength(titleLength) {
            if (titleLength <= 200 && titleLength >= 155)
                return 'green-text';
            if (titleLength < 155 && titleLength >= 95)
                return 'text-darken-1 orange-text';
            else return 'red-text';
        }

        function secureUrl(url) {
            if (!url) return url;
            var root = $scope.analysis.url.replace(/\/$/g, '');
            if (isRelative(url)) {
                url = url.replace(/^\//g, '');
                return root + "/" + url;
            }
            return url;
        }

        function isRelative(url) {
            return !new RegExp(/^http[s]{0,1}\:\/\//i).test(url) && !new RegExp(/^\/\//i).test(url);
        }

        vm.pagination = {
            numElements: notOptimizedOpengraph.length,
            offset: 0,
            callback: pagingCallback
        };
        vm.currentSearch = {
            placeholder: $translate.instant('seospider.opengraph.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.opengraph.search'),
                callback: searchCallback
            };
        });

        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAll({
                jobId: $stateParams.sessionId,
                q: query,
                fields: ['id', 'url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl'],
                offset: 0
            }).then(function (res) {
                    vm.pagination.offset = 0;
                    vm.pagination.numElements = res.totalRows;
                    vm.pages = res.data;
                },
                function (err) {
                    vm.pages = err.data;
                });
        }

        function pagingCallback(limit, offset) {
            var dfd = $q.defer();
            Pages.findAll({
                jobId: $stateParams.sessionId,
                q: vm.query,
                fields: ['id', 'url', 'metaOgTitle', 'metaOgDescription', 'metaOgImage', 'metaOgUrl'],
                limit: limit,
                offset: offset
            }).then(function (res) {
                vm.pages = res.data;
            }, function (err) {
                vm.pages = err.data;
                dfd.resolve();
            });
            return dfd.promise;
        }
    }

    Controller.$inject = ['$scope', '$stateParams', '$q', 'results', 'jobId', 'SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

'use strict';
define([], function () {
    function LogsController($scope, $localStorage, LogService, $translate) {
        var vm = $scope;
        vm.workspace = $localStorage.currentUser.workspace;
        vm.rows = [];

        /**
         * Call Api and return logs by type
         * @param {'queries'|'activities'} type
         */
        function getLogs(type) {
            if (type !== 'queries' && type !== 'activities') {
                throw new Error('invalid params');
            }

            LogService.getAllLogs(type)
                .then(function (res) {
                    if (!res || !res.data || !res.data.allLogs) {
                        throw new Error('invalid result');
                    }
                    var allLogs = res.data.allLogs;

                    angular.forEach(res.data.allLogs, function (value, key) {
                        var payload = JSON.parse(value.payload);
                        allLogs[key].queries = payload.queries || '';
                        allLogs[key].activities = payload.activities || '';
                        allLogs[key].projectName = payload.projectName || '';
                        allLogs[key].project = '';
                        allLogs[key].message = '';

                        if (allLogs[key].queries != '') {
                            // TODO in futuro il project deve essere inserito per tutti
                            //  momentaneamente mettiamo query dove non ci sta project name
                            // Create message for frontends
                            switch (allLogs[key].eventType) {
                                // SERP
                                case 'serp-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.serp.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // SEO CHECKER
                                case 'seo-checker-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.checker.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // BACKLINK CHECKER
                                case 'backlink-checker':
                                case 'backlink-checker-rows':
                                    if (payload.rows && payload.rows > 0) {
                                        allLogs[key].message = $translate.instant('settings.logs.workspace.backlink.checker.analysis.with.rows', {
                                            rows: payload.rows,
                                        });
                                    } else {
                                        allLogs[key].message = $translate.instant('settings.logs.workspace.backlink.checker.analysis');
                                    }
                                    allLogs[key].project = payload.queries;
                                    break;

                                // KEYOWRD EXPLORER
                                case 'keyword-explorer-txt-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.explorer.txt.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-explorer-url-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.explorer.url.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-explorer-keywords-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.explorer.keywords.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-explorer-list-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.explorer.list.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // SPIDER
                                case 'seo-spider-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.spider.analysis');
                                    allLogs[key].project = allLogs[key].projectName;
                                    break;

                                // SEO PROJECT
                                case 'seo-project-create':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.project.create');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'seo-project-update':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.project.update');
                                    allLogs[key].project = allLogs[key].projectName;
                                    break;

                                // COPY METRICS
                                case 'copy-metrics-analysis':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.copy.metrics.analysis');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // SEO EDITOR
                                case 'seo-editor-create-article':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.editor.create.article');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'seo-editor-update-article':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.editor.update.article');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'seo-editor-delete-article':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.seo.editor.delete.article');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // KEYWORD LIST
                                case 'keyword-list-create':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.list.create');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-list-delete':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.list.delete');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-list-update':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.list.update');
                                    allLogs[key].project = payload.queries;
                                    break;
                                case 'keyword-list-clone':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.keyword.list.clone');
                                    allLogs[key].project = payload.queries;
                                    break;

                                // FOR ALL OTHER CASE NOT SPECIFY
                                default:
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.not.found');
                                    allLogs[key].project = 'Queries';
                                    break;
                            }
                        } else if (allLogs[key].queries == '' && allLogs[key].activities != '') {
                            switch (allLogs[key].eventType) {

                                // ACCOUNT UPDATE
                                case 'update-data-account':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.data.update');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'update-billing-info-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.billing.update');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'update-payment-methods-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.payment.methods.update');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'delete-payment-methods-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.payment.methods.delete');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'default-payment-methods-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.payment.methods.default');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'paypal-subscription-renewal-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.renewal.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'paypal-subscription-deactivated-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.deactivated.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'stripe-subscription-renewal-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.renewal.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'stripe-subscription-deactivated-data':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.deactivated.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'paypal-subscription-activated':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.activated.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'paypal-subscription-reactivated':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.reactivated.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'stripe-subscription-created':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.activated.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'paypal-subscription-trial':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.trial.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'stripe-subscription-trialing':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.trial.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'paypal-subscription-updated':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.updated.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'paypal-subscription-renew':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.renew.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;
                                case 'stripe-subscription-updated':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.update.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'paypal-subscription-cancelled':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.cancelled.subscription.paypal');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'stripe-subscription-deleted':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.account.deleted.subscription.stripe');
                                    allLogs[key].project = payload.activities;
                                    break;

                                case 'workspace-invitation-create':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.create');
                                    allLogs[key].project = allLogs[key].activities;
                                    break;
                                case 'workspace-invitation-delete':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.delete');
                                    allLogs[key].project = allLogs[key].activities;
                                    break;
                                case 'workspace-invitation-accept':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.accept');
                                    allLogs[key].project = '-';
                                    break;
                                case 'workspace-invitation-decline':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.decline');
                                    allLogs[key].project = '-';
                                    break;
                                case 'workspace-invitation-leave':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.leave');
                                    allLogs[key].project = '-';
                                    break;
                                case 'workspace-invitation-update':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.invitation.update');
                                    allLogs[key].project = allLogs[key].activities;
                                    break;
                                case 'workspace-delete-user':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.delete.user');
                                    allLogs[key].project = allLogs[key].activities;
                                    break;
                                case 'workspace-change-role':
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.change.role');
                                    allLogs[key].project = allLogs[key].activities;
                                    break;
                                // FOR ALL OTHER CASE NOT SPECIFY
                                default:
                                    allLogs[key].message = $translate.instant('settings.logs.workspace.not.found');
                                    allLogs[key].project = 'Workspace';
                                    break;
                            }
                        }
                    });
                    vm.rows = allLogs;
                });
        }

        /**
         * Default Call - Load Queries Log State
         */
        vm.loadQueriesLog = function () {
            getLogs('queries');
        };

        /**
         * Switch Call - Load Activities Log State
         */
        vm.loadActivitiesLog = function () {
            getLogs('activities');
        };

        vm.loadQueriesLog();
    }

    LogsController.$inject = ['$scope', '$localStorage', 'LogService', '$translate'];
    return LogsController;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/robots/robots.routes',
    'modules/seospider/exam/analysis/robots/robots.controller',
    'modules/seospider/exam/analysis/robots/no-index/no-index.controller',
    'modules/seospider/exam/analysis/robots/noodp/noodp.controller',
    'modules/seospider/exam/analysis/robots/noydir/noydir.controller',
    'modules/seospider/exam/analysis/robots/all/all.module',
    'modules/seospider/exam/analysis/robots/components/components.module'
],function(angular,routes,SPRobotsCtrl,SPRobotsNoIndexCtrl,SPRobotsNoodpCtrl,SPRobotsNoydirCtrl){
    var app=angular.module('app.seospider.exam.analysis.robots', [
        'app.seospider.exam.analysis.robots.all',
        'app.seospider.exam.analysis.robots.components'
    ]);
    app.config(routes);
    app.controller('SPRobotsCtrl',SPRobotsCtrl);
    app.controller('SPRobotsNoIndexCtrl',SPRobotsNoIndexCtrl);
    app.controller('SPRobotsNoodpCtrl',SPRobotsNoodpCtrl);
    app.controller('SPRobotsNoydirCtrl',SPRobotsNoydirCtrl);
    return app;
});
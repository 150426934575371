define([
        'angular',
        'components/st-number-input/st-number-input.component',
        'components/exam/exam.component',
        'components/api-textual-rendering/api-textual-rendering.component',
        'components/api-textual-rendering-pagespeed/api-textual-rendering-pagespeed.component',
        'components/exam-performance/exam-performance.component',
        'components/preview-desktop/preview-desktop.component',
        'components/preview-mobile/preview-mobile.component',
        'components/device-rendering/device-rendering.component',
        'components/url-split/url-split.component',
        'components/google-serp-preview/google-serp-preview.component',
        'components/opengraph-preview/opengraph-preview.component',
        'components/twittercard-preview/twittercard-preview.component',
        'components/collapsable/collapsable.component',
        'components/arrow-pagination/arrow-pagination.component',
        'components/vote/vote.component',
        'components/st-google-trends/st-google-trends.component',
        'components/access-components/st-signup/st-signup.component',
        'components/access-components/st-signin/st-signin.component',
        'components/access-components/st-email-verification/st-email-verification.component',
        'components/access-components/st-tailor-made/st-tailor-made.component',
        'components/access-components/st-get-started-now/st-get-started-now.component',
        'components/access-components/st-review-box/st-review-box.component',
        'components/access-components/st-plan-detail/st-plan-detail.component',
        'components/access-components/st-plans-suggestion/st-plans-suggestion.component',
        'components/access-components/st-promo-detail/st-promo-detail.component',
        'components/access-components/st-reached-limit/st-reached-limit.component',
        'components/st-auth-admin/st-auth-admin.component',
        'components/st-progress/st-progress.component',
        'components/st-alert-limit/st-alert-limit.component',
        'components/add-on/add-on.component',
        'components/st-collapse/st-collapse.component',
        'components/st-chart-score/st-chart-score.component',
        'components/st-collapse-score/st-collapse-score.component',
        'components/st-battery/st-battery.component',
        'components/st-badge-score/st-badge-score.component',
        'components/st-dropdown/st-dropdown.component',
        'components/st-countdown/st-countdown.component',
        'components/st-promo-countdown/st-promo-countdown.component',
        'components/st-promo-banner/st-promo-banner.component',
        'components/st-search-container/st-search-container.component',
        'components/report-pdf-button/report-pdf-button.component',
        'components/st-line-graph/st-line-graph.component',
        'components/st-card-combo/st-card-combo.component',
        // 'components/st-onboarding/st-onboarding.component',
        'components/st-media-upload/st-media-upload.component',
        'components/st-input/st-input.component',
        'components/st-keyword-list/st-keyword-list.component',
        'components/st-box-filter/st-box-filter.component',
        'components/st-sparkline/st-sparkline',
        'components/st-auth/st-auth.component',
        'components/st-step-navbar/st-step-navbar.component',
        'components/st-breadcrumbs/st-breadcrumbs.component',
        'components/st-user-manual/st-user-manual.component',
        'components/st-floating-button/st-floating-button.component',
        'angular-material',
        'angular-messages',
        'material-steppers',
        'vendors/ng-clipboard'
    ],
    function (angular,
              stNumberInput,
              exam,
              apiTextual,
              apiTextualPagespeed,
              examPerformance,
              previewDesktop,
              previewMobile,
              deviceRendering,
              urlSplit,
              googleSerpPreview,
              opengraphPreview,
              twittercardPreview,
              collapsable,
              arrowPagination,
              vote,
              stGoogleTrends,
              stSignup,
              stSignin,
              stEmailVerification,
              stTailorMade,
              stGetStartedNow,
              stReviewBox,
              stPlanDetail,
              stPlansSuggestion,
              stPromoDetail,
              stReachedLimit,
              stAuthAdmin,
              stProgress,
              stAlertLimit,
              addOn,
              stCollapse,
              stChartScore,
              stCollapseScore,
              stBattery,
              stBadgeScore,
              stDropdown,
              stCountdown,
              stPromoCountdown,
              stPromoBanner,
              stSearchContainer,
              reportPdfButton,
              stLineGraph,
              stCardCombo,
              // stOnboarding,
              stMediaUpload,
              stInput,
              stKeywordList,
              stBoxFilter,
              stSparkline,
              stAuth,
              stStepNavbar,
              stBreadcrumbs,
              stUserManual,
              stFloatingButton
    ) {
        var uisto = angular.module('app.uisto', [
            'ngMaterial',
            'ngMessages',
            'mdSteppers',
            'ng-clipboard'
        ]);
        /* ------- */
        /* Generics Components */
        /* ------- */
        uisto.component('stNumberInput', stNumberInput.default); //this is why we are mixing define with import
        uisto.component('stAuth', stAuth);
        uisto.component('exam', exam);
        uisto.component('apiTextual', apiTextual);
        uisto.component('apiTextualPagespeed', apiTextualPagespeed);
        uisto.component('examPerformance', examPerformance);
        uisto.component('previewDesktop', previewDesktop);
        uisto.component('previewMobile', previewMobile);
        uisto.component('deviceRendering', deviceRendering);
        uisto.component('urlSplit', urlSplit);
        uisto.component('googleSerpPreview', googleSerpPreview);
        uisto.component('opengraphPreview', opengraphPreview);
        uisto.component('twittercardPreview', twittercardPreview);
        uisto.component('collapsable', collapsable);
        uisto.component('arrowPagination', arrowPagination);
        uisto.component('vote', vote);
        uisto.component('stGoogleTrends', stGoogleTrends);
        uisto.component('stSignup', stSignup);
        uisto.component('stSignin', stSignin);
        uisto.component('stEmailVerification', stEmailVerification);
        uisto.component('stTailorMade', stTailorMade);
        uisto.component('stGetStartedNow', stGetStartedNow);
        uisto.component('stReviewBox', stReviewBox);
        uisto.component('stPlanDetail', stPlanDetail);
        uisto.component('stPlansSuggestion', stPlansSuggestion);
        uisto.component('stPromoDetail', stPromoDetail);
        uisto.component('stReachedLimit', stReachedLimit);
        uisto.component('stAuthAdmin', stAuthAdmin);
        uisto.component('stProgress', stProgress);
        uisto.component('stAlertLimit', stAlertLimit);
        uisto.component('addOn', addOn);
        uisto.component('stCollapse', stCollapse);
        uisto.component('stChartScore', stChartScore);
        uisto.component('stCollapseScore', stCollapseScore);
        uisto.component('stSearchContainer', stSearchContainer);
        uisto.component('reportPdfButton', reportPdfButton);
        uisto.component('stBattery', stBattery);
        uisto.component('stDropdown', stDropdown);
        uisto.component('stCountdown', stCountdown);
        uisto.component('stPromoCountdown', stPromoCountdown);
        uisto.component('stPromoBanner', stPromoBanner);
        uisto.component('stLineGraph', stLineGraph);
        uisto.component('stBadgeScore', stBadgeScore);
        uisto.component('stCardCombo', stCardCombo);
        uisto.component('stMediaUpload', stMediaUpload);
        uisto.component('stInput', stInput);
        uisto.component('stKeywordList', stKeywordList);
        uisto.component('stBoxFilter', stBoxFilter);
        uisto.component('stSparkline', stSparkline);
        uisto.component('stStepNavbar', stStepNavbar);
        uisto.component('stBreadcrumbs', stBreadcrumbs);
        uisto.component('stUserManual', stUserManual);
        uisto.component('stFloatingButton', stFloatingButton);
        return uisto;
    });


'use strict';
define([], function () {
    function SEOSpiderExamCtrl($scope, $state, angularLoad) {
        /*
        setTimeout(function () {
            // Load async external-bundle module vendors
            angularLoad.loadScript('./app/vendors/d3.min.js');
            angularLoad.loadScript('./app/vendors/xlsx.full.min.js');
        }, 1);*/
    }

    SEOSpiderExamCtrl.$inject = ['$scope', '$state', 'angularLoad'];
    return SEOSpiderExamCtrl;
});

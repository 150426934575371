'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/vote/vote.html',
        bindings: { score: '=',limit: '=',size: '@',label: '@', color:'=' },
        controller:['$scope',function($scope){
            var vm=this;
            function valuation(score) {
                if (score == null || score < 0)
                    return 'blue-text';
                if (score >= 75)
                    return 'green-text';
                else if (score >= 50)
                    return 'text-darken-1 orange-text';
                else
                    return 'red-text';
            }
            $scope.$watch('$ctrl.size',function (size) {
                switch(size) {
                    case 'sm':
                        vm.sizeClass = 'vote-sm';
                        break;
                    case 'xs':
                        vm.sizeClass = 'vote-xs';
                        break;
                }
            });
            $scope.$watch('$ctrl.label',function (label) {
                vm.label=label
            });
            $scope.$watch('$ctrl.score',function (score) {
                vm.addedClass=valuation(score)||vm.color;
            });
            $scope.$watch('$ctrl.color',function (color) {
                if(color!=undefined||vm.score!=undefined)
                    vm.addedClass=color||valuation(vm.score);
            });
        }]
    };
});
'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/access-components/st-email-verification/st-email-verification.html',
        bindings: {
            onVerificationRedirectTo: '<',
            onTailorMadeRedirectTo: '<',
            params: '<',
            email: '<',
            onAuth: '<',
        },
        controller: ['$scope', '$state', '$stateParams', 'AuthService', '$element', '$location', '$timeout',
            function ($scope, $state, $stateParams, AuthService, $element, $location, $timeout) {
                var ctrl = this;
                var verificationUrl;
                $scope.buildCode = buildCode;
                $scope.onKeyPressed = onKeyPressed;
                $scope.onPaste = onPaste;
                $scope.resendVerificationCode = resendVerificationCode;
                $scope.splittedCode = [];
                $scope.code = '';
                $scope.loading;
                $scope.loadingResendCode = false;
                $scope.newVerificationCodeWasSent = false;
                $scope.wrongCode = false;

                ctrl.$onInit = function () {
                    ctrl.onAuth = ctrl.onAuth || function () {};

                    if ($stateParams.email) {
                        $scope.email = $stateParams.email;
                    }

                    if (ctrl.email) {
                        $scope.email = ctrl.email;
                    }

                    if ($state.current.name !== 'access.email-verification') {
                        verificationUrl = $location.$$protocol + '://' + $location.$$host + $location.$$path;
                    }

                    ctrl.onAuth = ctrl.onAuth || function () {};
                    logout();
                };

                function onPaste(ev) {
                    var pastedText = ev.originalEvent.clipboardData.getData('text/plain').trim();

                    if (isNaN(pastedText) || pastedText.length > 6) {
                        return;
                    }

                    $scope.splittedCode = pastedText.split('');
                    buildCode();
                }

                function onKeyPressed(ev, index) {
                    var wasPressedNumber = (ev.which >= 48 && ev.which <= 57);
                    var wasPressedBackSpace = ev.which == 8;
                    var wasPressedArrowLeft = ev.which == 37;
                    var wasPressedArrowRight = ev.which == 39;

                    if (index < 5 && wasPressedArrowRight) {
                        $element[0].querySelector('#digit-' + (index + 1)).focus();
                    } else if (index > 0 && wasPressedArrowLeft) {
                        $element[0].querySelector('#digit-' + (index - 1)).focus();
                    } else if (index > 0 && wasPressedBackSpace) {
                        $scope.splittedCode[index] = null;
                        //$element[0].querySelector('#digit-'+ (index)).disabled = true;
                        $element[0].querySelector('#digit-' + (index - 1)).focus();
                    } else if (index < 5 && wasPressedNumber) {
                        //$element[0].querySelector('#digit-'+ (index+1)).disabled = false;
                        $scope.splittedCode[index] = parseInt(ev.key);
                        $timeout(function () {
                            $element[0].querySelector('#digit-' + (index + 1)).focus();
                        }, 10);
                    }
                }

                function buildCode() {
                    $scope.splittedCode = $scope.splittedCode.filter(function (el) {
                        return el != null;
                    });
                    $scope.code = $scope.splittedCode.join('');
                }

                function resendVerificationCode() {
                    var params = {};
                    if ($scope.loadingResendCode) {
                        return;
                    }
                    $scope.loadingResendCode = true;
                    params.email = $scope.email;
                    params.verificationUrl = verificationUrl;
                    AuthService.resendVerificationCode(params)
                        .then(function (res) {
                            $scope.loadingResendCode = false;
                            $scope.newVerificationCodeWasSent = true;
                        })
                        .catch(function (err) {
                            $scope.loadingResendCode = false;
                            if (err && err.data && err.data.error && err.data.error.code && err.data.error.code === "RATE_LIMITER") {
                                $scope.verificationAlreadySent = true;
                                $timeout(function () {
                                    $scope.verificationAlreadySent = false;
                                }, 4000);
                            }
                            if (err && err.data && err.data.message && err.data.message === 'you can send only one email every 120 minutes') {
                                $scope.newVerificationCodeWasSent = true;
                            }
                        });
                }

                $scope.$watch('code', function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        if (newValue && newValue.length == 6) {
                            $scope.loading = true;
                            var params = {}
                            params.code = newValue;
                            params.email = $scope.email;
                            params.verificationUrl = verificationUrl;

                            $timeout(function () {
                                AuthService.verifyCode(params)
                                    .then(function (res) {
                                        if (res.data && res.data.token && res.data.token.length) {
                                            localStorage.setItem('accessToken', res.data.token);
                                        }

                                        if (res.data && res.data.user) {
                                            AuthService.setSessionUser(res.data.user);
                                        }

                                        if (ctrl.onAuth && ctrl.onAuth instanceof Function) {
                                            ctrl.onAuth(res);
                                        }

                                        if (ctrl.onVerificationRedirectTo) {
                                            $state.go(ctrl.onVerificationRedirectTo);
                                            return;
                                        }

                                        if (ctrl.onTailorMadeRedirectTo) {
                                            $state.go('access.tailor-made', {
                                                redirectTo: ctrl.onTailorMadeRedirectTo
                                            });
                                            return;
                                        }

                                        $state.go('access.tailor-made');
                                    })
                                    .catch(function (err) {
                                        console.log('verifyCode err', err);
                                        $scope.wrongCode = true;
                                        $scope.loading = false;
                                        $scope.splittedCode[5] = null;
                                        buildCode();
                                        $element[0].querySelector('#digit-5').disabled = false;
                                        $element[0].querySelector('#digit-5').focus();
                                    });
                            }, 2000);

                        }
                    }
                });

                function logout() {
                    AuthService.logout();
                }
            }
        ]
    };
});

'use strict';
define(['app'], function (app) {
    app.component('stSearchConsole', {
        templateUrl: 'app/components/st-search-console/st-search.console.html',
        controller: controller,
        bindings: {
            keywords: '=',
            keywordLimit: '<',
        }
    });
    controller.$inject = ['$scope', '$translate', 'SearchConsoleService', '$mdToast'];

    function controller($scope, $translate, SearchConsoleService, $mdToast) {
        var ctrl = this;

        ctrl.keywords = [];
        var user = JSON.parse(localStorage.getItem("ngStorage-currentUser"));

        $scope.$watch('$ctrl.keywordLimit', function (keywordLimit) {
            localStorage.setItem('keywordLimit', (keywordLimit || 100));
        });

        $scope.getAuthUrl = function () {
            SearchConsoleService.generateAuthUrl()
                .then(function (res) {
                    if (res.data && res.data.url) {
                        window.open(res.data.url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        };

        $scope.$watchCollection('$ctrl.keywords', function (keywords) {
            if (keywords && keywords.length) {
                ctrl.keywords = keywords;
            }
        });

        function showToast() {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="icon-x m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant('error.notfound.keyword') + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        $scope.$on('socketGscKeywords', function (ev, data) {
            var keywords = [];
            if (!user || user.id !== data.userId) {
                return;
            }

            if (data && data.keywords && data.keywords.length > 0) {
                angular.forEach(data.keywords, function (keyword) {
                    if (ctrl.keywords.indexOf(keyword) < 0) {
                        ctrl.keywords.push(keyword);
                        keywords.push(keyword);
                    }
                });
            }

            if (!keywords.length) {
                showToast();
            }
        });
    }
});

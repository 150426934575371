'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$stateParams,$q,results,jobId, Pages, $rootScope, $translate) {
        var vm = $scope;
        vm.pages=results.data;
        vm.query='';
        vm.jobId=jobId;
        vm.pagination={
            numElements:results.totalRows,
            offset:0,
            callback: pagingCallback
        };
        vm.currentSearch={
            placeholder: $translate.instant('seospider.h2.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged',function () {
            vm.currentSearch={
                placeholder: $translate.instant('seospider.h2.search'),
                callback: searchCallback
            };
        });
        function searchCallback(query){
            vm.query=query;
            vm.pagination.offset=0;
            Pages.findAllH2({
                jobId:$stateParams.sessionId,
                q:query,
                size:'over',
                offset:0
            }).
            then(function(res){
                    vm.pagination.offset=0;
                    vm.pagination.numElements=res.totalRows;
                    vm.pages=res.data;
                },
                function(err){
                    vm.pages=err.data;
                });
        }
        function pagingCallback(limit,offset){
            var dfd = $q.defer();
            Pages.findAllH2({
                jobId:$stateParams.sessionId,
                q:vm.query,
                size:'over',
                limit:limit,
                offset:offset
            }).
            then(function(res){
                vm.pages=res.data;
            },function(err){
                vm.pages=err.data;
                dfd.resolve();
            });
            return dfd.promise;
        }
    }
    Controller.$inject=['$scope','$stateParams','$q','results','jobId','SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

'use strict';
define([], function () {
    controller.$inject = ['$scope'];

    function controller($scope) {
        const vm=this;
        // Access `mainKeyword` in the controller
        vm.$onInit = () => {
            if(vm.collapsed === undefined){
                vm.collapsed = false;
            }
        };
        $scope.collapse = collapse;

        function collapse() {
            vm.collapsed = !vm.collapsed;
        }
        vm.onCollapseClick = function (td) {
            vm.onCollapse(td);
        };

    }
    return {
        templateUrl: 'app/components/st-collapse-score/st-collapse-score.html',
        transclude: true,
        bindings: {
            titleClass: '@',
            collapsed: '<',
            titleText: '<',
            score: '<',
            onCollapse: '&',
        },
        controller: controller
    };
});

'use strict';
define(['jquery'], function ($) {
    function OverviewController($scope, $state, AuthService, FavIconService, $timeout) {
        var vm = $scope;
        vm.isCurrentTab = $state.$current == 'app.seochecker.results.overview';
        vm.getFavIcon = FavIconService.get;

        $scope.authenticated = AuthService.isAuthenticated();
        $scope.chromeExtension = window.location.href.indexOf('chrome_extension');
        $scope.userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if ($scope.chromeExtension != -1) {
            localStorage.setItem('chrome-extension', 'true');
        }

        $scope.openReportDialog = function () {
            $timeout(function () {
                $(document.querySelector('#report-pdf-button')).click();
            });
        }

        $scope.domainToAnalyze = $scope.analysis.url.replace(/(^\w+:|^)\/\//, '').split('/')[0];
        $scope.restOfUrl = $scope.analysis.url.replace(/(^\w+:|^)\/\//, '').replace($scope.domainToAnalyze, '');
    }

    OverviewController.$inject = ['$scope', '$state', 'AuthService', 'FavIconService', '$timeout'];
    return OverviewController;
});

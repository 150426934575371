define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.languages",{
                controller:'SPLanguagesCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/languages/languages.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.languages.all",{
                url:"/languages",
                templateUrl: './app/modules/seospider/exam/analysis/languages/all/all.html',
                access: {restricted: true},
                controller:'LanguagesAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.hreflangs({
                            jobId:$stateParams.sessionId,
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.languages.missing",{
                url:"/languages/missing",
                templateUrl: './app/modules/seospider/exam/analysis/languages/all/all.html',
                access: {restricted: true},
                controller:'LanguagesMissCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.hreflangs({
                            jobId:$stateParams.sessionId,
                            missing:true,
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.languages.noXDefault",{
                url:"/languages/x-default-missing",
                templateUrl: './app/modules/seospider/exam/analysis/languages/all/all.html',
                access: {restricted: true},
                controller:'LanguagesXDefaultMissCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.hreflangs({
                            jobId:$stateParams.sessionId,
                            noXDefault:true,
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});

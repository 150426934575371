'use strict';
define([
    'angular',
    'modules/keywordtracker/dialogs/delete-keywords/delete-keywords.controller'
], function (
    angular,
    KeywordTrackerDeleteKeywordsCtrl
) {
    var app = angular.module('app.keywordtracker.dialogs', []);
    app.controller('KeywordTrackerDeleteKeywordsCtrl', KeywordTrackerDeleteKeywordsCtrl);
   
    return app;
});

'use strict';
define([], function () {
    function NotVerifiedCtrl($scope, $debounce, AuthService, $translate, $mdToast) {
        $scope.resendVerificationMail = resendVerificationMail;
        $scope.emailAlreadySent = false;


        function resendVerificationMail() {
            $debounce(resendVerificationMailAction, 2000);
        }

        function resendVerificationMailAction() {
            AuthService.resendVerificationMail()
                .then(function(res) {
                    $scope.emailAlreadySent = true;
                })
                .catch(function (err) {
                    console.log(err);
                    showCustomToast('welcome.something.went.wrong', 'icon-x st-red');
                });
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast max-w">' +
                    '   <div class="p-t p-b"><i class="' + classIcon + ' m-r-sm"></i>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: document.querySelector('.toast-show')
            });
        }
    }


    NotVerifiedCtrl.$inject = ['$scope', '$debounce', 'AuthService', '$translate', '$mdToast'];
    return NotVerifiedCtrl;
});

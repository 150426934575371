'use strict';
define([], function () {

    return {
        templateUrl: 'app/modules/seospider/exam/analysis/components/analysis-radar/analysis-radar.html',
        bindings: {
            loading: '<',
            report:'<',
            data: '<'
        },
        controller: ['$scope', '$rootScope', '$translate', function ($scope, $rootScope, $translate) {
            var vm = $scope;
            var ctrl=this;
            vm.chartLabels = [];
            vm.chartData = [[]];
            vm.chartColors;


            vm.chartOptions = {
                maintainAspectRatio: true,
                scale: {
                    ticks: {
                        display: false,
                        min: 0, // minimum value
                        max: 100, // maximum value
                        fixedStepSize: 10
                    },
                    pointLabels: {
                        fontSize: 8,
                        fontColor: '#596679' 
                    },
                },
                elements: {
                    line: {
                        borderWidth: 1,
                        stepped: true

                    }
                },
                defaultFontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                legend: {
                    display: false
                }
            };

            vm.textColorClass = function (score) {
                if (score == null || score < 0)
                    return 'blue-text';
                if (score >= 75)
                    return 'green-text';
                else if (score >= 50)
                    return 'text-darken-1 orange-text';
                else
                    return 'red-text';

            };

            vm.chartLabels = [$translate.instant('seospider.base'),
                $translate.instant('seospider.content'),
                $translate.instant('seospider.structure'),
                $translate.instant('seospider.duplicated'),
                $translate.instant('seospider.social')
            ];

            $rootScope.$on("languageChanged", function () {
                $translate(['seospider.base',
                    'seospider.content',
                    'seospider.structure',
                    'seospider.duplicated',
                    'seospider.social'
                ]).then(function (translations) {
                    vm.chartLabels = [translations['seospider.base'],
                        translations['seospider.content'],
                        translations['seospider.structure'],
                        translations['seospider.duplicated'],
                        translations['seospider.social']
                    ];
                });
            });
            function setData(){
                vm.data = ctrl.data;
                if(typeof ctrl.report === 'string'){
                    try{
                        ctrl.report=JSON.parse(ctrl.report);
                    }catch(err){
                        console.log(err);
                        ctrl.report={base:0,content:0,structure:0,duplicated:0,social:0};
                    }
                }
                vm.report = ctrl.report;
                vm.loading = ctrl.loading;
                
                if (!vm.report){
                    vm.loading=true;
                }
                
                var score = 0;
                
                if (vm.loading) {
                    vm.chartData[0][0] = 0;
                    vm.chartData[0][1] = 0;
                    vm.chartData[0][2] = 0;
                    vm.chartData[0][3] = 0;
                    vm.chartData[0][4] = 0;
                    vm.chartColors = ["#dddddd"];
                } else {
                    vm.chartData[0][0]= getScoreByCategory(vm.report.base);
                    vm.chartData[0][1]= getScoreByCategory(vm.report.content);
                    vm.chartData[0][2]= getScoreByCategory(vm.report.structure);
                    vm.chartData[0][3]= getScoreByCategory(vm.report.duplicated);
                    vm.chartData[0][4]= getScoreByCategory(vm.report.social);
                    score += getScoreByCategory(vm.report.base);
                    score += getScoreByCategory(vm.report.content);
                    score +=  getScoreByCategory(vm.report.structure);
                    score += getScoreByCategory(vm.report.duplicated);
                    score += getScoreByCategory(vm.report.social);
                    score = score / 5;
                    vm.chartColors = [getColorGraphByScore(score)];
                }
            }
            ctrl.$onInit = setData;
            ctrl.$onChanges = setData;

            function getScoreByCategory(category) {
                if(!category){
                    return 0;
                }
                if(!isNaN(category)){
                    return parseInt(category);
                }
                if(category.score){
                    return parseInt(category.score);
                }
                return 0;
            }
            function getColorGraphByScore(score) {
                score = parseFloat(score);
                var greenColorHex = "#28b670";
                var orangeColorHex = "#fb8c00";
                var redColorHex = "#f44336";
            
                return score >= 75 ? greenColorHex :
                    score >= 50 ? orangeColorHex : redColorHex;
            }
            
        }]
    };
});

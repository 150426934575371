'use strict';
define([
    'angular',
    'modules/dashboard/not-verified/not-verified.controller'
], function (
    angular,
    NotVerifiedCtrl
) {
    var app = angular.module('access.email-verification', []);
    app.controller('NotVerifiedCtrl', NotVerifiedCtrl);
   
    return app;
});

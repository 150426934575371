'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/access-components/st-plans-suggestion/st-plans-suggestion.html',
        bindings: {
            redirectTo: '<',
            userCanDoTrial: '<',
            isPromoTime: '<',
            params: '<',
            limitAlias: '<',
            code: '<',
        },
        controller: ['$scope', '$state', 'UserService', '$stateParams', '$mdMedia', '$timeout', '$window', 'LanguageService', 'PlanDetailsService',
            function ($scope, $state, UserService, $stateParams, $mdMedia, $timeout, $window, LanguageService, PlanDetailsService) {
                var ctrl = this;
                var vm = $scope;
                vm.code = 'NO_ENOUGH_CREDITS';
                vm.filterPlanByIntervalAndPlanName = filterPlanByIntervalAndPlanName;
                vm.filterPlanByInterval = filterPlanByInterval;
                vm.goToCheckout = goToCheckout;
                vm.goToDashboard = goToDashboard;
                vm.goToContactUs = goToContactUs;
                vm.goToPricing = goToPricing;
                vm.formatAmount = formatAmount;
                vm.getFullPrice = getFullPrice;
                vm.isNumber = isNumber;
                vm.suggestedPlans = $stateParams.suggestedPlans;
                vm.value = 100;
                var userData = {};

                vm.swipeLeft = function (selectedPlan) {
                    if (vm.plansToShow[0].alias == selectedPlan) {
                        vm.selectedPlan = vm.plansToShow[1].alias;
                    } else if (vm.plansToShow[1].alias == selectedPlan) {
                        vm.selectedPlan = vm.plansToShow[0].alias;
                    }

                    //animation left
                };

                vm.swipeRight = function (selectedPlan) {
                    if (vm.plansToShow[0].alias == selectedPlan) {
                        vm.selectedPlan = vm.plansToShow[1].alias;
                    } else if (vm.plansToShow[1].alias == selectedPlan) {
                        vm.selectedPlan = vm.plansToShow[0].alias;
                    }
                };

                if ($window.localStorage.getItem('ngStorage-currentUser')) {
                    userData = JSON.parse($window.localStorage.getItem('ngStorage-currentUser'));
                }

                if (userData && userData.type) {
                    vm.type = userData.type;
                }

                var updatePlanWithPromo = function (plans) {
                    var updatedPlans = PlanDetailsService.getAll();
                    return plans.map(function (plan) {
                        updatedPlans.map(function (updatedPlan) {
                            if (updatedPlan.annualAlias === plan.alias) {
                                plan.amount = updatedPlan.annualAmount * 100 * 12;
                                plan.amountDecimal = updatedPlan.annualAmountDecimal;
                            }

                            if (updatedPlan.monthlyAlias === plan.alias) {
                                plan.amount = updatedPlan.monthlyAmount * 100;
                                plan.amountDecimal = updatedPlan.monthlyAmountDecimal;
                            }
                        });

                        return plan;
                    });
                };

                ctrl.$onInit = function () {
                    vm.plans = [];
                    vm.interval = 'year';
                    vm.planName = '';
                    vm.userCanDoTrial = ctrl.userCanDoTrial || $stateParams.userCanDoTrial;
                    vm.$mdMedia = $mdMedia;
                    vm.formatFullPrice = formatFullPrice;
                    vm.fullPrices = [];
                    vm.isPromoTime = false;
                    vm.limitAlias = this.limitAlias;
                    vm.code = this.code;

                    if (this.isPromoTime) {
                        vm.classModifier = PlanDetailsService.getCurrentPromo().currentPromoModifierClass;
                        vm.isPromoTime = this.isPromoTime;
                        vm.currentPromo = PlanDetailsService.getCurrentPromo();
                    }

                    UserService.getUserTypePlans()
                        .then(function (res) {
                            if (res.data && res.data.suggestedPlans && res.data.suggestedPlans.userType) {
                                if (res.data.suggestedPlans.userType === 'enterprise') {
                                    vm.type = 'enterprise';
                                } else if (res.data.suggestedPlans.userType === 'personal') {
                                    if (allPlansHasService(res.data.suggestedPlans.personal, vm.limitAlias)) {
                                        vm.plans = res.data.suggestedPlans.personal;
                                        vm.type = 'personal';
                                    } else if (allPlansHasService(res.data.suggestedPlans.personalPro, vm.limitAlias)) {
                                        vm.plans = res.data.suggestedPlans.personalPro;
                                        vm.type = 'personal pro';
                                    } else if (allPlansHasService(res.data.suggestedPlans.agency, vm.limitAlias)) {
                                        vm.plans = res.data.suggestedPlans.agency;
                                        vm.type = 'agency';
                                    }
                                } else if (res.data.suggestedPlans.userType === 'personal pro') {
                                    if (allPlansHasService(res.data.suggestedPlans.personalPro, vm.limitAlias)) {
                                        vm.plans = res.data.suggestedPlans.personalPro;
                                        vm.type = 'personal pro';
                                    } else if (allPlansHasService(res.data.suggestedPlans.agency, vm.limitAlias)) {
                                        vm.plans = res.data.suggestedPlans.agency;
                                        vm.type = 'agency';
                                    }
                                } else if (res.data.suggestedPlans.userType === 'agency' && allPlansHasService(res.data.suggestedPlans.agency, vm.limitAlias)) {
                                    vm.plans = res.data.suggestedPlans.agency;
                                    vm.type = 'agency';
                                }
                            }

                            if (vm.type !== 'enterprise') {
                                vm.plans = PlanDetailsService.addFeaturesDetail(vm.plans, vm.type);

                                if (vm.isPromoTime) {
                                    vm.plansToShow = updatePlanWithPromo(vm.plans.filter(function (plan) {
                                        return plan.interval == vm.interval;
                                    }));
                                } else {
                                    vm.plansToShow = vm.plans.filter(function (plan) {
                                        return plan.interval == vm.interval;
                                    });
                                }

                                vm.selectedPlan = vm.plansToShow[1].alias;
                            }
                        })
                        .catch(function (err) {
                            console.log(err);

                            vm.plans = [
                                    {
                                        "alias": "plan-advanced-monthly",
                                        "name": "Plan Advanced",
                                        "amount": 7200,
                                        "interval": "month",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-premium-monthly",
                                        "name": "Plan Premium",
                                        "amount": 14400,
                                        "interval": "month",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-advanced-annual",
                                        "name": "Plan Advanced",
                                        "amount": 57600,
                                        "interval": "year",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-premium-annual",
                                        "name": "Plan Premium",
                                        "statementDescriptor": "Annual Subscription",
                                        "amount": 115200,
                                        "interval": "year",
                                        "trialPeriodDays": 7,
                                    }
                                ];

                            vm.type = 'agency';
                            vm.plans = PlanDetailsService.addFeaturesDetail(vm.plans, vm.type);
                            if (vm.isPromoTime) {
                                vm.plansToShow = updatePlanWithPromo(vm.plans.filter(function (plan) {
                                    return plan.interval == vm.interval;
                                }));
                            } else {
                                vm.plansToShow = vm.plans.filter(function (plan) {
                                    return plan.interval == vm.interval;
                                });
                            }

                            vm.selectedPlan = vm.plansToShow[1].alias;
                        });
                };

                function allPlansHasService(plans, serviceAlias) {
                    var plansWithTheService = [];
                    plans.forEach(function (plan) {
                        plan.services.filter(function (service) {

                            if (service.alias === serviceAlias) {
                                plansWithTheService.push(plan);
                            }
                        });
                    });

                    return plansWithTheService.length == 4;
                }

                function animateValue(elID, start, end, duration) {
                    var obj = document.getElementById(elID);

                    if (!obj) {
                        return;
                    }
                    var startTimestamp = null;
                    var step = function (timestamp) {
                        if (!startTimestamp) {
                            startTimestamp = timestamp;
                        }
                        var progress = Math.min((timestamp - startTimestamp) / duration, 1);
                        obj.innerHTML = Math.floor(progress * (end - start) + start);
                        if (progress < 1) {
                            window.requestAnimationFrame(step);
                        }
                    };
                    window.requestAnimationFrame(step);
                }

                function animatePrices() {
                    var firstPriceId = '',
                        secondPriceId = '';
                    if ($mdMedia('gt-xs')) {
                        firstPriceId = 'first-price';
                        secondPriceId = 'second-price';
                    } else {
                        firstPriceId = 'first-price-mobile';
                        secondPriceId = 'second-price-mobile';
                    }

                    var firstFinalPrice = vm.interval != "year" ? vm.plansToShow[0].amount / 100 : (formatAmount(vm.plansToShow[0].amount, vm.interval));
                    var secondFinalPrice = vm.interval != "year" ? vm.plansToShow[1].amount / 100 : (formatAmount(vm.plansToShow[1].amount, vm.interval));

                    animateValue(firstPriceId, vm.currentFirstPrice, firstFinalPrice, 700);
                    animateValue(secondPriceId, vm.currentSecondPrice, secondFinalPrice, 700);
                }

                function filterPlanByInterval(interval) {
                    vm.interval = interval;

                    if (vm.isPromoTime) {
                        vm.plansToShow = updatePlanWithPromo(vm.plans.filter(function (plan) {
                            return plan.interval == interval;
                        }));
                    } else {
                        vm.plansToShow = vm.plans.filter(function (plan) {
                            return plan.interval == interval;
                        });
                    }

                    if (interval !== 'year') {
                        vm.selectedPlan = vm.selectedPlan.replace("annual", "monthly");
                    } else {
                        vm.selectedPlan = vm.selectedPlan.replace("monthly", 'annual');
                    }

                    animatePrices();

                    $timeout(function () {
                        angular.element('md-radio-button[value="' + vm.selectedPlan + '"]').click();
                    }, 50);
                }

                vm.setCurrentPrices = function () {
                    var firstPriceId, secondPriceId;
                    if ($mdMedia('gt-xs')) {
                        firstPriceId = 'first-price';
                        secondPriceId = 'second-price';
                    } else {
                        firstPriceId = 'first-price-mobile';
                        secondPriceId = 'second-price-mobile';
                    }
                    vm.currentFirstPrice = parseInt(document.getElementById(firstPriceId).innerText);
                    vm.currentSecondPrice = parseInt(document.getElementById(secondPriceId).innerText);
                };

                function filterPlanByIntervalAndPlanName(interval, planAlias) {
                    vm.interval = interval;
                    vm.plansToShow = vm.plans.filter(function (plan) {
                        return plan.interval == interval;
                    });

                    vm.selectedPlan = vm.plansToShow.filter(function (plan) {
                        return plan.alias == planAlias;
                    })[0].alias;
                }

                function goToCheckout() {
                    var coupon = '';

                    closeDialog();

                    if (ctrl.isPromoTime) {
                        coupon = PlanDetailsService.getCurrentPromo().promoCoupon;
                    }
                    if (vm.selectedPlan) {
                        $state.go('upgrade.checkout', {
                            alias: vm.selectedPlan,
                            coupon: coupon
                        });
                    }
                }

                function goToPricing() {
                    closeDialog();
                    $state.go('upgrade.choose-plan');
                }

                function goToDashboard() {

                    closeDialog();
                    if (ctrl.redirectTo) {
                        $state.go(ctrl.redirectTo);
                        return;
                    }

                    $state.go('app.welcome');
                }

                function closeDialog() {
                    vm.$parent.cancel();
                }

                function formatAmount(amount, interval) {
                    if (interval === 'year') {
                        amount = amount / 12;
                    }

                    var formattedAmount = amount / 100;
                    return formattedAmount.toString().split('.')[0];
                }

                function getFullPrice(alias, index) {
                    if (!alias) {
                        return;
                    }
                    var aliasToFilter = alias.replace("annual", "monthly");

                    var filteredPlan = vm.plans.filter(function (plan) {
                        return plan.alias == aliasToFilter;
                    })[0];

                    vm.fullPrices[index] = filteredPlan.amount;

                    return filteredPlan.amount;
                }


                function formatFullPrice(fullPrice) {
                    return fullPrice / 100;
                }

                function goToContactUs() {
                    var language = LanguageService.getCodeAndCountrySaveOnStorage();
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/contatti", '_blank');
                    } else if (language === 'es-ES') {
                        $window.open("https://es.seotesteronline.com/contacts", '_blank');
                    } else {
                        $window.open("https://www.seotesteronline.com/contacts", '_blank');
                    }
                }

                function isNumber(value) {
                    return angular.isNumber(value);
                }

            }
        ]
    };
});

'use strict';
define([
    'angular',
    'modules/settings/dialogs/not-partner/not-partner.controller',
    'modules/settings/dialogs/change-role/change-role.controller',
    'modules/settings/dialogs/invite-members/invite-members.controller',
    'modules/settings/dialogs/leave-workspace/leave-workspace.controller',
    'modules/settings/dialogs/accept-invitation/accept-invitation.controller',
    'modules/settings/dialogs/cancel-invitation/cancel-invitation.controller',
    'modules/settings/dialogs/delete-user/delete-user.controller',
    'modules/settings/dialogs/add-users/add-users.controller',
    'modules/settings/dialogs/invitation-sent/invitation-sent.controller',
    'modules/settings/dialogs/cant-add-new-users/cant-add-new-users.controller',
    'modules/settings/dialogs/churn-flow/churn-flow.controller',
], function (
    angular,
    NotPartnerCtrl,
    ChangeRoleCtrl,
    InviteMembersCtrl,
    LeaveWorkspaceCtrl,
    AcceptInvitationCtrl,
    CancelInvitationCtrl,
    DeleteUserCtrl,
    AddUsersCtrl,
    InvitationSentCtrl,
    CantAddNewUsersCtrl,
    ChurnFlowCtrl,
) {
    var app = angular.module('settings.dialogs', []);

    app.controller('NotPartnerCtrl', NotPartnerCtrl);
    app.controller('ChangeRoleCtrl', ChangeRoleCtrl);
    app.controller('InviteMembersCtrl', InviteMembersCtrl);
    app.controller('LeaveWorkspaceCtrl', LeaveWorkspaceCtrl);
    app.controller('AcceptInvitationCtrl', AcceptInvitationCtrl);
    app.controller('CancelInvitationCtrl', CancelInvitationCtrl);
    app.controller('DeleteUserCtrl', DeleteUserCtrl);
    app.controller('AddUsersCtrl', AddUsersCtrl);
    app.controller('InvitationSentCtrl', InvitationSentCtrl);
    app.controller('CantAddNewUsersCtrl', CantAddNewUsersCtrl);
    app.controller('ChurnFlowCtrl', ChurnFlowCtrl);

    return app;
});

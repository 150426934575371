/**
 * @ngdoc overview
 * @name usetiful.service
 *
 * @description
 * Check and Load data to pass at Usetiful Service
 *
 */
'use strict';
define(['app'], function (app) {
    app.service('UsetifulService', UsetifulService);

    /**
     * Language Service
     * @namespace LanguageService
     */
    function UsetifulService() {

        return {
            reset: reset,
            start: getBootstrapUsetiful,
        };

        /**
         * Dopo aver eseguito tutti i check sui valori
         * chiamo ed attacco Usetful al Head
         * @param compiledData
         */
        function addUsetiful(compiledData) {
            /**
             * Preparo i dati
             */
            window.usetifulTags = compiledData;
            var nameOfScript = 'usetifulScript';
            var scripts = document.getElementById(nameOfScript);

            /**
             * Rimuovo Script se ne
             * esistono altri cosi
             * ne tengo 1 per volta
             */
            if (scripts !== null) {
                var elem = document.getElementById(nameOfScript);
                elem.parentNode.removeChild(elem);
            }

            /**
             * Re-Istanzio l'SDK
             * per sicurezza ogni volta
             */
            (function (w, d, s) {
                var a = d.getElementsByTagName('head')[0];
                var r = d.createElement('script');
                r.async = 1;
                r.src = s;
                r.id = nameOfScript;
                r.setAttribute('id', 'usetifulScript');
                r.dataset.token = "8739dae1d1a842a3f447c322a4b4b5ac";
                a.appendChild(r);
            })(window, document, "https://www.usetiful.com/dist/usetiful.js");
        }

        function reset(language) {
            var account = 'offline';
            var canDoTrial = true;
            var isTrial = false;

            getBootstrapUsetiful(null, language, account, canDoTrial, isTrial);
        }

        /**
         *
         * CONDIZIONI DA RISPETTARE
         *
         * Condizione originale → res.data.account (offline/online/sub)
         *
         * SE Account: offline → Utente non loggato | value → not-logged
         * SE Account: online && canDoTrial: true   | value → free (quando ha un account e può fare il trial)
         * SE Account: online && canDoTrial: false  | value → trialed (ha un account con nessuna sub attiva ed ha già fatto il trial)
         * SE Account: sub && isTrial: true         | value → trialing (è in trial)
         * SE Account: sub && isTrial: false        | value → sub (ha un account e ha una sub attiva)
         *
         * @param win
         * @param doc
         */
        function getBootstrapUsetiful(userId, language, account, canDoTrial, isTrial) {
            var segment = '';
            var compiledData = {
                segment: 'not-logged',
                language,
                notLogged: true,
            };

            account = account || 'offline';
            canDoTrial = canDoTrial || true;
            isTrial = isTrial || false;

            /**
             * Utente ON-Line
             */
            if (account.toLowerCase() === "online") {
                if (canDoTrial) {
                    segment = 'free';
                } else {
                    segment = 'trialed';
                }
            }

            /**
             * Utente con SUB Attiva
             */
            if (account.toLowerCase() === "sub") {
                if (isTrial) {
                    segment = 'trialing';
                } else {
                    segment = 'sub';
                }
            }

            /**
             * Utente OFF-Line
             */
            if (account.toLowerCase() === "offline") {
                compiledData = {
                    segment: 'not-logged',
                    language,
                    notLogged: true,
                };
                addUsetiful(compiledData);
                return;
            }

            compiledData = {
                userId,
                segment,
                language,
                notLogged: false,
                isTrial,
                canDoTrial,
                account,
            };

            addUsetiful(compiledData);
        } // END getBootstrapUsetiful
    } // END UsetifulService
});

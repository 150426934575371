'use strict';
define([], function () {
    function Controller($scope, $filter) {
        var vm = $scope;
        vm.isLoading = true;

        vm.menuConfig = {
            singleOpening: false,
            linkFirstLevelMenuToTheFirstSubmenu: false,
            saveStateOnRefresh: false
        };

        vm.mobileMenuItems = [];

        vm.menuItems = [
            {
                name: 'leadgeneration.general',
                icon: 'settings',
                isOpened: false,
                mobileTabOrder: 1,
                link: {
                    type: 'internal',
                    urls: ['app.leadgeneration.form.settings.general'],
                },
            },
            {
                name: 'leadgeneration.test.your.form',
                icon: 'local_police',
                isOpened: false,
                mobileTabOrder: 2,
                link: {
                    type: 'internal',
                    urls: ['app.leadgeneration.form.settings.whitelist'],
                },
            },
            {
                name: 'leadgeneration.integrations',
                icon: 'workspaces_outline',
                isOpened: false,
                mobileTabOrder: 3,
                link: {
                    type: 'internal',
                    urls: ['app.leadgeneration.form.settings.integrations'],
                },
            },
        ];

        buildMobileMenu(vm.menuItems);

        function buildMobileMenu(menuItems) {
            for (var i = 0; i<menuItems.length; i+=1) {
                if (menuItems[i].link) {
                    vm.mobileMenuItems.push(menuItems[i]);
                }
                if (menuItems[i].submenu) {

                    for (var j = 0; j<menuItems[i].submenu.length; j+=1) {
                        if (menuItems[i].submenu[j].link) {
                            vm.mobileMenuItems.push(menuItems[i].submenu[j]);
                        }

                        if (menuItems[i].submenu[j].submenu) {
                            for (var k = 0; k<menuItems[i].submenu[j].submenu.length; k+=1) {
                                if (menuItems[i].submenu[j].submenu[k].link) {
                                    vm.mobileMenuItems.push(menuItems[i].submenu[j].submenu[k]);
                                }
                            }
                        }
                    }
                }

            }

            vm.mobileMenuItems = $filter('orderBy')(vm.mobileMenuItems, 'mobileTabOrder', false);
        }
    }

    Controller.$inject = ['$scope', '$filter'];
    return Controller;
});

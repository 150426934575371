'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/link-scanned/link-scanned.html',
        bindings: {
            links: '<',
            inAnimation: '<'
        },
        controller: ['$scope', '$mdMedia','$translate', function ($scope, $mdMedia,$translate) {
            var vm = $scope;
            var ctrl = this;
            vm.$mdMedia = $mdMedia;

            vm.internalFollow = 0;
            vm.internalNoFollow = 0;
            vm.externalFollow = 0;
            vm.externalNoFollow = 0;

            vm.labels = [
                $translate.instant('seospider.dashboard.link.internal'),
                $translate.instant('seospider.dashboard.link.external')
                ];
            vm.data = [
                [vm.internalFollow, vm.externalFollow],
                [vm.internalNoFollow, vm.externalNoFollow]
            ];
            vm.datasetOverride = [{
                label: "Dofollow",
                borderWidth: 0,
                backgroundColor: '#0BC7B5'
            },
                {
                    label: "No follow",
                    borderWidth: 0,
                    backgroundColor: '#FFE3D8'
                },
            ];

            vm.$watchCollection('$ctrl.links', function (links) {
                if (links && links.length) {
                    setLinks(links);
                }
                if (links && !links.length) {
                    vm.internalFollow = 0;
                    vm.internalNoFollow = 0;
                    vm.externalFollow = 0;
                    vm.externalNoFollow = 0;
                    vm.data = [
                        [vm.internalFollow, vm.externalFollow],
                        [vm.internalNoFollow, vm.externalNoFollow]
                    ];
                }
            });

            vm.$watch('$ctrl.inAnimation', function (inAnimation) {
                if (!inAnimation) {
                    vm.chartOpts.animation.duration = 0;
                }
            })

            vm.chartOpts = {
                responsive: false,
                maintainAspectRatio: false,
                showScale: false,
                animation: {},
                legend: {
                    display: false,
                    align: 'left',
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    boxWidth: 10,
                },
                title: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        display: true,
                        gridLines: {
                            color: 'rgba(118, 110, 148, 0.12)',
                            zeroLineColor: 'rgba(118, 110, 148, 0.12)',
                        },
                        ticks: {
                            suggestedMax: 100,
                            stepSize: 50,
                            callback: function(tick) {
                                return tick.toString() + '%';
                              }
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        barPercentage: 0.7,
                        display: true,
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent',
                        },
                        ticks: {
                            max: 100,
                            labelOffset: -25,
                            padding: -5,
                            mirror: true,
                        },
                    }]
                }
            };

            function setLinks(links) {
                if (!links || !links.length) {
                    return;
                }
                links.forEach(function (link) {
                    if (link.linkType === 'internal' && link.rel === 'nofollow') {
                        vm.internalNoFollow = link.count;
                    }
                    if (link.linkType === 'internal' && link.rel === 'follow') {
                        vm.internalFollow = link.count;
                    }
                    if (link.linkType === 'external' && link.rel === 'follow') {
                        vm.externalFollow = link.count;
                    }
                    if (link.linkType === 'external' && link.rel === 'nofollow') {
                        vm.externalNoFollow = link.count;
                    }
                });
                var totalLinks = vm.internalFollow + vm.externalFollow + vm.internalNoFollow + vm.externalNoFollow;
                vm.data = [
                    [parseInt(vm.internalFollow * 100 / totalLinks), parseInt(vm.externalFollow * 100 / totalLinks)],
                    [parseInt(vm.internalNoFollow * 100 / totalLinks), parseInt(vm.externalNoFollow * 100 / totalLinks)]
                ];
            }
        }]
    };
});

/**
 * @ngdoc overview
 * @name keywordtracker.service:KeywordTrackerService
 */
'use strict';
define(['app'], function (app) {
    app.service('KeywordTrackerService', KeywordTrackerService);
    KeywordTrackerService.$inject = ['API', '__env'];

    function KeywordTrackerService(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.keywordTrackerAPI;

        this.getAllTrackerProjects = function () {
            return API.get(apiPath, true, true);
        };

        this.getAllTrackerProjectPreviews = function () {
            return API.get(apiPath + '/preview', true, true);
        };

        this.getOneProject = function (idProject, filters) {
            return API.get(apiPath + '/' + idProject + '?filters=' + JSON.stringify(filters), true, true);
        };

        this.addKeywords = function (trackerProjectId, keywords, locations) {
            return API.put(apiPath + '/' + trackerProjectId + '/keywords', {
                keywords: keywords,
                locations: locations
            }, true);
        };

        this.deleteKeywords = function (trackerProjectId, queries) {
            return API.post(apiPath + '/' + trackerProjectId + '/keywords', {queries}, true);
        };

        this.getManyQueries = function (trackerProjectId, uuids, filters) {
            return API.post(apiPath + '/' + trackerProjectId + '/get-updated-results', {
                uuids: uuids,
                filters: filters,
            }, true);
        };

        this.keywordCountTracker = function () {
            return API.get(apiPath + '/keywords/count', true, true);
        }

        this.deleteLocation = function (trackerProjectId, location) {
            return API.delete(
                apiPath + '/' + trackerProjectId +
                '/location?&deviceId=' + location.deviceId +
                '&searchEngineId=' + location.searchEngineId +
                '&countryCode=' + location.countryCode,
                null,
                true);
        };

        this.getLocations = function (trackerProjectId) {
            return API.get(apiPath + '/' + trackerProjectId + '/location', true, true);
        };

        this.setReport = function (trackerProjectId, reportSettings) {
            return API.put(apiPath + '/' + trackerProjectId + '/report', {
                reportType: reportSettings.reportType,
                reportName: reportSettings.reportName,
                reportRecepients: reportSettings.reportRecepients,
                reportLanguage: reportSettings.reportLanguage
            }, true);
        };

    }
});

'use strict';
define([],function(){
    controller.$inject = ['$scope','$mdMedia'];
    function controller($scope,$mdMedia) {
        var ctrl = this;
        $scope.$mdMedia=$mdMedia;
        $scope.collapsed=false;
        $scope.collapse=collapse;
        function collapse(){
            $scope.collapsed=!$scope.collapsed;
        }
        ctrl.onCollapseClick=function(td){ctrl.onCollapse(td);};
        $scope.$watch('$ctrl.titleCollapse', function (title) {
            ctrl.titleCollapse=title;
        });
        $scope.$watch('$ctrl.ico', function (ico) {
            ctrl.ico=ico;
        });
        $scope.$watch('$ctrl.collapsed', function (collapsed) {
            $scope.collapsed=collapsed;
        });
    }
    return {
        templateUrl: 'app/components/st-collapse/st-collapse.html',
        transclude:true,
        bindings: {
            exam: '<',
            collapsed: '<',
            customClass: '<',
            titleCollapse: '<',
            ico: '<',
            design: '<',
            onCollapse: '&',
            version: '<'
        },
        controller: controller
    };
});

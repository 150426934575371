'use strict';
define(['angular', 'app'], function (angular, app) {
    app.directive('googlePlusSignin', ['$window', '$timeout', function ($window, $timeout) {
        var ending = /\.apps\.googleusercontent\.com$/;

        return {
            restrict: 'E',
            transclude: true,
            template: '<span></span>',
            replace: true,
            link: function (scope, element, attrs, ctrl, linker) {
                attrs.clientid += (ending.test(attrs.clientid) ? '' : '.apps.googleusercontent.com');
                attrs.$set('data-clientid', attrs.clientid);
                attrs.$set('theme', attrs.theme);

                // Some default values, based on prior versions of this directive
                var defaults = {
                    onsuccess: googleSigninSuccess,
                    onfailure: googleSigninFailure,
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email',
                    height: 'standard',
                    width: 'wide',
                    access_type: 'online',
                    longtitle: true,
                    state: ''
                };

                defaults.clientid = attrs.clientid;
                defaults.theme = attrs.theme;

                // Overwrite default values if explicitly set
                angular.forEach(Object.getOwnPropertyNames(defaults), function (propName) {
                    if (attrs.hasOwnProperty(propName)) {
                        defaults[propName] = attrs[propName];
                    }
                });

                // Default language
                // Supported languages: https://developers.google.com/+/web/api/supported-languages
                attrs.$observe('language', function (value) {
                    $window.___gcfg = {
                        lang: value ? value : 'en'
                    };
                });

                // Asynchronously load the G+ SDK.
                var po = document.createElement('script');
                po.type = 'text/javascript';
                po.async = true;
                po.src = 'https://apis.google.com/js/client:plusone.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(po, s);


                var formatButton = function () {
                    $timeout(function () {
                        angular.element('span[customtargetid="st-btn-signin-google"]').find('span[id^="not_signed"], span[id^="connected"]').html('<span class="font-bold st-blue">Google</span>');
                    }, 500);
                };

                linker(function (el) {
                    po.onload = function () {
                        if (el.length) {
                            element.append(el);
                        }

                        gapi.load('auth2', function () {
                            gapi.auth2.init({client_id: attrs.clientid})
                                .then(function () {
                                    if (gapi.auth2.getAuthInstance()) {
                                        gapi.auth2.getAuthInstance()
                                            .signOut()
                                            .then(function () {
                                                gapi.signin2.render(element[0], defaults);
                                                formatButton();
                                            });
                                    } else {
                                        gapi.signin2.render(element[0], defaults);
                                        formatButton();
                                    }
                                });
                        });
                    };
                });

                element.bind('click', function () {
                    gapi.auth.signOut();
                });
            }
        }
    }]).run(['$window', '$rootScope', function ($window, $rootScope) {
        $window.googleSigninSuccess = function (authResult) {
            var auth = authResult.getAuthResponse(true);
            $rootScope.$broadcast('event:google-plus-signin-success', auth.access_token);
        }

        $window.googleSigninFailure = function (authResult) {
            $rootScope.$broadcast('event:google-plus-signin-failure', authResult);
        }
    }]);

});

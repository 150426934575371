'use strict';

define([], function () {
    function Controller(__env, $mdMedia, $scope, isTrialing, $rootScope, $state, $mdSidenav, UserService,
                        currentUser, AuthService, stAcceptanceService, $filter, $window, LanguageService, $location, $localStorage, PlanDetailsService, userInfo) {
        var vm = $scope;
        vm.$mdMedia=$mdMedia;
        vm.isTrialing = isTrialing;
        vm.isNotInTrial = true;
        vm.isAuth = AuthService.isAuthenticated();
        vm.currentUser = currentUser;
        vm.accountPitchground = false;
        vm.isPromoTime = PlanDetailsService.isPromoTime();
        if (vm.isPromoTime) {
            vm.currentPromo = PlanDetailsService.getCurrentPromo();
        }
        vm.isOffline = vm.currentUser === false;

        vm.shouldShowWorkspaceNavbar=shouldShowWorkspaceNavbar;
        function shouldShowWorkspaceNavbar(){
            if($state.current.name.match('app.serpchecker.tool.benchmark')&&!$state.params.keyword){
                return true;
            }
            return ['app.seospider.landing',
                'app.seospider.list',
                'app.keywordtracker.landing',
                'app.seochecker.landing',
                'app.welcome',
                'app.keywordlistmanager.landing',
                'app.keywordlistmanager.list',
                'app.writeassistance.landing',
                'app.seoeditor.landing',
                'app.copymetrics.landing',
                'app.leadgeneration.landing'].indexOf($state.current.name) !== -1;

        }
        /*

        const st = {
            "businessType": "Freelance",
            "email": "admin@admin.com",
            "firstname": "Vittorio",
            "id": 1,
            "jobTitle": "CEO/Founder",
            "lastname": "Inzaghio",
            "locale": "en-US",
            "stripeCustomerId": "cus_J2afRJBB96bXOr",
            "teamMembers": "6-10",
            "type": "agency",
            "uuid": "28e4a07c-7501-11eb-a96e-069239494043",
            "verified": 1,
            "profileImgUrl": "https://stouserimage.s3.eu-west-1.amazonaws.com/sto-user-image-f3790d85d824c1074d8e3635d768b03a.png",
            "workspace": {
                "id": 1,
                "ownerId": 1,
                "name": "Stark Industries",
                "logoImgUrl": "https://stouserimage.s3.eu-west-1.amazonaws.com/sto-user-image-e1eb1903d77cb6f764fa4b995d399b56.png",
                "createdAt": "2021-02-22T11:29:00.000Z",
                "updatedAt": "2023-11-08T10:03:41.000Z",
                "deletedAt": null,
                "members": 1,
                "permissions": ["project_read", "project_edit", "keyword_list_read", "keyword_list_edit", "seo_checker_edit", "keyword_explorer_edit", "serp_checker_edit", "copy_metrics_edit", "editor_read", "editor_edit", "lead_gen_read", "lead_gen_edit", "report_read", "report_edit", "workspace_edit", "workspace_user_admin", "workspace_user_manager", "workspace_user_bookkeeper", "workspace_user_marketer", "workspace_user_client", "workspace_invoice_read", "workspace_invoice_edit", "workspace_log_read", "workspace_partner_program_read", "workspace_partner_program_edit", "workspace_payment_methods_read", "workspace_subscriptions_read"],
                "roleId": 1,
                "roleName": "owner",
                "ownerEmail": "admin@admin.com"
            }
        };

        */
        //Set Language and Country get from Storage or set from default config
        vm.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();
        vm.isItalianLanguage = false;
        vm.toggleSidebar = toggleSidebar;
        vm.switchLang = switchLang;
        vm.userPlan = userPlan;
        vm.location = $location;
        vm.workspace = [];
        vm.goToPromoLink = goToPromoLink;
        vm.userHasAnActiveSubscription = userHasAnActiveSubscription;
        vm.userInfo = userInfo;
        vm.monthlyPlans = [];
        vm.annualPlans = [];
        vm.userHasAnAnnualPlanLiteActive = false;


        vm.$watch(function () {
            return localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        }, function (currentLanguage, previousLanguage) {

            vm.isItalianLanguage = currentLanguage === 'it-IT';

            if (!vm.currentPromo || !vm.currentPromo.languageVisibility) {
                return;
            }

            if (vm.currentPromo.languageVisibility == 'all') {
                vm.promoButtonIsVisible = true;
            } else if (vm.currentPromo.languageVisibility == 'it' && vm.isItalianLanguage) {
                vm.promoButtonIsVisible = true;
            } else if (vm.currentPromo.languageVisibility == 'it' && !vm.isItalianLanguage) {
                vm.promoButtonIsVisible = false;
            }

            vm.userCanSeePromotion = vm.isPromoTime && vm.promoButtonIsVisible && (vm.isOffline || !vm.userHasAnActiveSubscription(vm.userInfo) || (vm.monthlyPlans.length > 0 && vm.annualPlans.length == 0) || (vm.annualPlans.length == 1 && vm.userHasAnAnnualPlanLiteActive));

        });

        function userHasAnActiveSubscription(currentUser) {
            if (!currentUser)
                return false;
            if (!currentUser.subscriptions || !currentUser.subscriptions.length)
                return false;
            var foundAnActiveSubscription = false;
            vm.monthlyPlans = [];
            vm.annualPlans = [];

            for (var i = 0; i < currentUser.subscriptions.length; i++) {

                if ((currentUser.subscriptions[i].status === 'active' || currentUser.subscriptions[i].status === 'trialing')) {
                    if (new Date() <= new Date(currentUser.subscriptions[i].currentPeriodEnd)) {
                        foundAnActiveSubscription = true;

                        if (currentUser.subscriptions[i].plans.length && currentUser.subscriptions[i].plans[0].interval === 'month') {
                            vm.monthlyPlans.push(currentUser.subscriptions[i].plans[0]);
                        }
                        if (currentUser.subscriptions[i].plans.length && currentUser.subscriptions[i].plans[0].interval === 'year') {
                            if (currentUser.subscriptions[i].plans[0].alias == 'plan-lite-annual') {
                                vm.userHasAnAnnualPlanLiteActive = true;
                            }
                            vm.annualPlans.push(currentUser.subscriptions[i].plans[0]);
                        }
                    }
                }
            }

            return foundAnActiveSubscription;
        }

        function goToPromoLink() {
            if (vm.currentPromo.bannerCTALink) {
                $window.open(vm.currentPromo.bannerCTALink + '&utm_medium=topbar', '_blank');
                return;
            }

            $state.go('upgrade.choose-plan');
        }

        //show button-bottom on mobile
        function userPlan() {
            return !vm.isAuth;
        }

        vm.isNotEasterEegNav = isNotEasterEegNav;

        function isNotEasterEegNav(currentUser) {
            return currentUser.email != 'v.urzi@seotesteronline.com' &&
                currentUser.email != 'g.sciuto@seotesteronline.com';
        }

        if (vm.isAuth) {
            UserService.accountInfo()
                .then(function (res) {
                    if ((new Date(res.data.acceptedPrivacyPolicyAt).getTime() < __env.privacyPolicy) ||
                        (new Date(res.data.acceptedCookiePolicyAt).getTime() < __env.cookiePolicy) ||
                        (new Date(res.data.acceptedDataProcessingAgreementAt).getTime() < __env.dataProcessingAgreement) ||
                        (new Date(res.data.acceptedTermsAndConditionsAt).getTime() < __env.termsAndConditions)) {
                        stAcceptanceService.open();
                    }
                    if ($filter('date')(new Date(res.data.lastLogin).getTime(), 'yyyy-MM-dd') != $filter('date')(new Date().getTime(), 'yyyy-MM-dd')) {
                        UserService.updateProfile({
                            lastLogin: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss')
                        });
                    }
                });
        }

        vm.$watchCollection('$state', function () {
            if ($state.current.name)
                vm.source = $state.current.name.replace('app.', '').replace(/\..*/gi, '').toLowerCase();
            else vm.source = 'not-specified';
        });

        function toggleSidebar() {
            $mdSidenav('left').toggle();
        }

        // It Called only when we are on sidebar-expandend
        function switchLang(language) {
            vm.selectedLang = language;

            try {
                $rootScope.setLang(language)
                    .catch(function (error) {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }

            if (AuthService.isAuthenticated()) {
                UserService.updateProfile({
                    locale: language
                })
                    .then(function (res) {
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        }

        vm.onSwipeLeft = function () {
            $mdSidenav('left').close();
        };

        vm.logout = function () {
            AuthService.logout();
            setTimeout(function () {
                $state.go('app.welcome', {}, {
                    reload: true
                });
            }, 1000);
        };

        vm.$on("switchWorkSpace", function (evt) {
            vm.currentUser = $localStorage.currentUser;
        });

        vm.ecf = $window.localStorage.getItem('ecf') ? false : true;

        vm.setEcf = function () {
            vm.ecf = false;
            $window.localStorage.setItem('ecf', 'true');
        };
    }

    Controller.$inject = ['__env', '$mdMedia','$scope', 'isTrialing', '$rootScope', '$state', '$mdSidenav', 'UserService', 'currentUser', 'AuthService', 'stAcceptanceService', '$filter',
        '$window', 'LanguageService', '$location', '$localStorage', 'PlanDetailsService', 'userInfo'
    ];
    return Controller;
});

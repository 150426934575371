'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('stMaintenanceBanner', stMaintenanceBanner);
    stMaintenanceBanner.$inject = ['$mdDialog', '$localStorage'];

    function stMaintenanceBanner($mdDialog, $localStorage) {
        return {
            open: open
        };

        function open() {
            if ($localStorage && $localStorage.maintenanceAccepted) {
                if (new Date().toLocaleDateString() !== '09/04/2021') {
                    return;
                }

                if (new Date().toLocaleDateString() === $localStorage.maintenanceAccepted) {
                    return;
                }
            }

            $mdDialog.show({
                controller: DialogController,
                templateUrl: 'app/components/st-maintenance-banner/st-maintenance-banner.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            })
                .then(function (answer) {

                }, function (err) {
                    console.log(err);
                });
        }
    }

    function DialogController($scope, $mdDialog, $localStorage) {
        $scope.agree = function () {
            $localStorage.maintenanceAccepted = new Date().toLocaleDateString();
            $mdDialog.hide();
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.answer = function (answer) {
            $mdDialog.hide(answer);
        };
    }

    DialogController.$inject = ['$scope', '$mdDialog', '$localStorage'];
});

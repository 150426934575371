'use strict';
define([],function(){
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/images/table.html',
        bindings: { pages: '=' },
        controller: ['$scope','$rootScope','$window','$translate', function($scope,$rootScope,$window,$translate){
            var vm=$scope;
            $scope.$watch('$ctrl.pages',function(pages){
                vm.pages=pages;
            });
            if(vm.pages==undefined) vm.pages=[];
            vm.openDetail=openDetail;
            vm.evalOccurences=evalOccurences;
            vm.evalLength=evalLength;
            vm.getRealSrc=getRealSrc;
            function getRealSrc(base,relative){
                try{
                    return new URL(relative, base).href;
                }catch(err){
                    return relative;
                }
            }
            function evalOccurences(score) {
                score=parseInt(score)||0;
                if (score == 1)
                    return 'green-text';
                if (score == 0)
                    return 'red-text';
                else  return 'text-darken-1 orange-text';
            }
            function evalLength(len) {
                len=parseInt(len)||0;
                if (len >70)
                    return 'red-text';
                if (len >15&&len<=70)
                    return 'green-text';
                if (len >=5&&len<=15)
                    return 'text-darken-1 orange-text';
                return 'red-text';
            }
            function openDetail(page){
                var url=new URL(page.url);
                var pathname=url.pathname.length?url.pathname:'/';
                $rootScope.$emit('openToolbarDetail',{
                    list:page.list,
                    templateType: 'multiple',
                    element: 'h1',
                    label: $translate.instant('seospider.images.multiple'),
                    placeholder: $translate.instant('seospider.search.images.in.page',{'pathname':pathname})
                });
                $rootScope.$on('languageChanged',function () {
                    $rootScope.$emit('openToolbarDetail', {
                            list:page.list,
                            element: 'img',
                            label: $translate.instant('seospider.images.multiple'),
                            placeholder: $translate.instant('seospider.search.images.in.page',{'pathname':pathname})
                        }
                    );
                });
            }
        }]
    };
});
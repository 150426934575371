'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/status-code-doughnut/status-code-doughnut.html',
        bindings: {
            codesCount: '<',
            inAnimation: '<'
        },
        controller: ['$scope', '$mdMedia', function ($scope, $mdMedia) {
            var vm = $scope;
            vm.$mdMedia = $mdMedia;

            $scope.colors = ['#0BC7B5', '#FFE3D8', '#FF0066'];
            $scope.labels = ["2xx", "3xx", "4xx"];
            $scope.data = [0, 0, 0];
            $scope.chartOpts = {
                fill: false,
                responsive: false,
                aspectRatio: 0.6,
                animation: {},
                maintainAspectRatio: false,
                circumference: 2 * Math.PI,
                rotation: -0.5 * Math.PI,
                cutoutPercentage: 65,
                legend: {
                    display: false,
                    align: 'left',
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                },
                title: {
                    display: false,
                    text: 'Status Code',
                    position: 'top',
                    fontStyle: 'bold',
                    fontSize: '14',
                    fontColor: '#130D41'
                },
                elements: {
                    arc: {
                        borderWidth: 1,
                    },
                },
            };

            $scope.$watchCollection('$ctrl.codesCount', function (codesCount) {
                if (codesCount && codesCount.length) {
                    setData(codesCount);
                }
                if (codesCount && !codesCount.length) {
                    $scope.data = [0, 0, 0];
                }
            });
            $scope.$watch('$ctrl.inAnimation', function (inAnimation) {
                if (!inAnimation) {
                    $scope.chartOpts.animation.duration = 0;
                }
            })

            function setData(codes) {
                angular.forEach(codes, function (code) {
                    if (code.status === 200) {
                        $scope.data[0] = code.count;
                    }
                    if (code.status === 301) {
                        $scope.data[1] = code.count;
                    }
                    if (code.status === 404) {
                        $scope.data[2] = code.count;
                    }
                })
            }


        }]
    };
});

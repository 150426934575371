'use strict';
define([
    'angular',
    'modules/keywordexplorer/components/serp-features/serp-features.component',
    'modules/keywordexplorer/components/keyword-difficulty/keyword-difficulty.component',
    'modules/keywordexplorer/components/trend-searches/trend-searches.component'
], function (angular, serpFeatures, keywordDifficulty, trendSearches) {
    var app = angular.module('app.keywordexplorer.ui', []);
    app.component('serpFeatures', serpFeatures);
    app.component('keywordDifficulty', keywordDifficulty);
    app.component('trendSearches', trendSearches);
    return app;
});

'use strict';
define([
    'angular',
    'modules/serpchecker/serpchecker.routes',
    'modules/serpchecker/components/components.module',
    'modules/serpchecker/dialogs/dialogs.module',
    'modules/serpchecker/tool/tool.module'
], function (angular, routes) {
    var app = angular.module('app.serpchecker', [
        'app.serpchecker.ui',
        'app.serpchecker.dialogs',
        'app.serpchecker.tool'
    ]);
    app.config(routes);
    return app;
});

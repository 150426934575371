'use strict';
define([], function () {
    function controller($scope, $element,$mdMedia) {
        var ctrl = this;
        $scope.$mdMedia=$mdMedia;

        ctrl.$onInit = function () {
            ctrl.fixedOnTop = $element[0].hasAttribute("fixed-on-top");
        }
    }

    controller.$inject = ['$scope', '$element','$mdMedia'];

    return {
        templateUrl: 'app/modules/keywordtracker/components/step-navbar/step-navbar.html',
        bindings: {
            onCreation: '=',
            fixedOnTop: '<',
            steps: '<',
            currentStep: '<'
        },
        controller: controller,
    }
});

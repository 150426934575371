'use strict';
define([], function () {
    function UpdateController($scope, $state, UserService, AuthService, $mdToast, $translate, $rootScope, $localStorage, $mdDialog, accountData, $element, $timeout, CountriesService,
        UtilitiesService, $filter) {
        var vm = $scope;
        vm.exportUserData = exportCSVFile;
        vm.loading = {
            update: false
        };
        vm.loadingToLogout = false;
        vm.emailTooltipIsVisible = false;

        vm.user = {
            email: accountData.email,
            profileImgUrl: accountData.profileImgUrl,
            firstname: accountData.firstname,
            lastname: accountData.lastname,
            country: accountData.country,
            billingPhonePrefix: accountData.billingPhonePrefix,
            billingPhone: accountData.billingPhone,
            domain: accountData.domain,
            teamMembers: accountData.teamMembers,
            businessType: accountData.businessType,
            jobTitle: accountData.jobTitle,
            acceptedReceiveOnboardingEmailAt: $filter('date')(accountData.acceptedReceiveOnboardingEmailAt, 'yyyy-MM-dd HH:mm:ss') || null,
        };

        if (vm.user.acceptedReceiveOnboardingEmailAt) {
            vm.newsletterCheckbox = true;
        }

        vm.file = {
            resourceUrl: vm.user.profileImgUrl
        }

        vm.countries = CountriesService.all();

        vm.businessTypesOptions = UtilitiesService.getBusinessTypesOptions();
        vm.jobsTitleOptions = UtilitiesService.getJobsTitleOptions();
        vm.teamMembersOptions = UtilitiesService.getTeamMembersOptions();

        vm.openMedia = function () {
            $timeout(function () {
                $element[0].querySelector('#media-button button').click();
            });
        }

        vm.showEmailTooptip = function () {
            vm.emailTooltipIsVisible = true;
            $timeout(function () {
                vm.emailTooltipIsVisible = false;
            }, 5000);
        }

        vm.switchNewsletter = function () {
            $timeout(function () {
                $element[0].querySelector('#switch-newsletter').click();
            });
        }


        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';
            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (array[i][index] != null) {
                        line += array[i][index];
                        line += ',';
                    }
                }
                str += line + '\r\n';
            }
            return str;
        }

        function exportCSVFile() {
            // Convert Object to JSON
            var arrayAccountData = [];
            for (var propName in accountData) {
                if (accountData[propName]) {
                    arrayAccountData.push(propName);
                    arrayAccountData.push(accountData[propName]);
                }
            }
            var jsonObject = JSON.stringify([arrayAccountData]);
            var csv = convertToCSV(jsonObject);
            var exportedFilenmae = $scope.user.email + '-' + currentData() + '.csv' || 'export.csv';
            var blob = new Blob([csv], {
                type: 'text/csv;charset=utf-8;'
            });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        function currentData() {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return dd + '/' + mm + '/' + yyyy;
        }

        vm.showCustomToast = function (string, classIcon) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        };

        vm.update = function (file) {

            if (file) {
                vm.user.profileImgUrl = file.resourceUrl;
            }

            vm.loading.update = true;

            /**
             * Aggiungendo i Log bisogna distinguere le chiamate da dove partono
             * e siccome sono tutte con lo stesso EndPoint diventa impossibile checkare tutto
             * quindi creo typeCall per identificare da dove parte.
             *
             * @type {string}
             */
            // vm.user.typeCall = 'update-data-account';

            UserService.updateProfile(vm.user).
            then(function (res) {
                vm.loading.update = false;
                var currentUser = $localStorage.currentUser;
                if (res.data && res.data.profileImgUrl) {
                    currentUser.profileImgUrl = res.data.profileImgUrl;
                    localStorage.setItem('currentUser', currentUser);
                }
                //TODO: it works but need improvements to avoid reload
                if (vm.file.resourceUrl !== vm.user.profileImgUrl) {
                    //$state.reload();
                }

                $mdToast.show({
                    template: '<md-toast class="md-toast" >' +
                        '<span layout="row" layout-align="start center"><i class="material-icons st-green m-r">done</i>{{"settings.updated.info"|translate}}</span>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });

                $rootScope.session.user = {
                    email: vm.user.email,
                    firstnameame: vm.user.firstname,
                    lastname: vm.user.lastname,
                };
            }).
            catch(function (err) {
                console.log('err', err);
                $mdToast.show({
                    template: '<md-toast class="md-toast" >' +
                        '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i><span translate>{{"settings.problem.verified.retry"|translate}}</span></span>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
                vm.loading.update = false;
                vm.errorDiv = true;
            });
        };

        vm.updateNewsletterCheckbox = function (value) {
            if (value) {
                vm.user.acceptedReceiveOnboardingEmailAt = $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss');
            } else {
                vm.user.acceptedReceiveOnboardingEmailAt = null;
            }
        };

        vm.deletePrompt = function (ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('settings.delete.account'))
                .htmlContent($translate.instant('settings.delete.confirm'))
                .placeholder('')
                .ariaLabel($translate.instant('settings.delete.account'))
                .initialValue('')
                .targetEvent(ev)
                .required(true)
                .ok($translate.instant('settings.update.confirm'))
                .cancel($translate.instant('settings.update.back'));
            $mdDialog.show(confirm).then(function (result) {
                if (result.trim() == 'DELETE') {
                    vm.loadingToLogout = true;
                    UserService.deleteAccount()
                        .then(function (res) {
                            $mdToast.show({
                                template: '<md-toast class="md-toast">' +
                                    '<span layout="row" layout-align="start center"><i class="material-icons st-green fa-check">done</i>{{"settings.account.delete.success"|translate}}</span>' +
                                    '</md-toast>',
                                hideDelay: 4000,
                                position: 'top right'
                            });

                            $timeout(function () {
                                logout();
                            }, 3000);
                        })
                        .catch(function (err) {
                            console.log(err);
                            $mdToast.show({
                                template: '<md-toast class="md-toast" >' +
                                    '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i>{{"settings.account.delete.error"|translate}}</span>' +
                                    '</md-toast>',
                                hideDelay: 5000,
                                position: 'top right'
                            });
                        });
                } else {
                    $mdToast.show({
                        template: '<md-toast class="md-toast" >' +
                            '<span layout="row" layout-align="start center" translate><i class="material-icons st-red m-r">clear</i>{{"settings.update.delete.not.correct"|translate}}</span>' +
                            '</md-toast>',
                        hideDelay: 5000,
                        position: 'top right'
                    });
                }
            }, function () {

            });
        };

        function logout() {
            AuthService.logout();
            $state.go('access.signin');
        }


    }

    UpdateController.$inject = ['$scope', '$state', 'UserService', 'AuthService', '$mdToast', '$translate', '$rootScope', '$localStorage', '$mdDialog', 'accountData', '$element', '$timeout',
        'CountriesService', 'UtilitiesService', '$filter'
    ];
    return UpdateController;
});

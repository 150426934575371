'use strict';
define([], function() {
    function SinglePageKeywordsCtrl($scope,$window,KeywordDensity) {
        KeywordDensity.setText($scope.detail.contentText);
        $scope.setTab=function(tab){
            $scope.currentNavItem=tab;
        };
        $scope.currentNavItem='oneWord';
        $scope.density={
            oneWord:KeywordDensity.oneWord(),
            twoWords:KeywordDensity.twoWords(),
            threeWords:KeywordDensity.threeWords(),
            fourWords:KeywordDensity.fourWords()
        };
        $scope.graph={
            one:getGraphData($scope.density.oneWord),
            two:getGraphData($scope.density.twoWords),
            three:getGraphData($scope.density.threeWords),
            four:getGraphData($scope.density.fourWords)
        };
        var fontSize=9;
        var positionLabel="bottom";
        if( $window.outerWidth>1280){
            fontSize=11;
            positionLabel="left";
        }
        $scope.chartKwOptions={

            legend:{
                display:true,
                position:positionLabel,
                labels:{
                    usePointStyle:true,
                    fontSize:fontSize
                }
            },

            responsive: true,
            maintainAspectRatio: true,
            cutoutPercentage:66,
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {

                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            if (i == 3) { // Darker text color for lighter background
                                ctx.fillStyle = '#444';
                            }
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            // Display percent in another line, line break doesn't work for fillText
                            ctx.fillText(percent, model.x + x, model.y + y + 5);
                        }
                    });
                }
            }
        };
    }
    function getGraphData(arrayDensity){
        var other=arrayDensity.count;
        var labels=[];
        var values=[];
        for(var i=0;i<arrayDensity.density.length&&i<13;i++){
            labels.push(arrayDensity.density[i].word);
            values.push(arrayDensity.density[i].count);
            other-=arrayDensity.density[i].count;
        }
    /*values.push(other);
    labels.push("others");*/
        return {labels:labels,data:values};
    }
    SinglePageKeywordsCtrl.$inject=['$scope','$window','KeywordDensityService'];
    return SinglePageKeywordsCtrl;
});

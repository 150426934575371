define([], function () {
    controller.$inject = ['$scope', '$rootScope', '$translate', '$state', '$mdSidenav', 'AuthService', 'UserService', '$location'];

    function controller($scope, $rootScope, $translate, $state, $mdSidenav, AuthService, UserService, $location) {
        $scope.allLanguages = $translate.getAvailableLanguageKeys();

        $translate('currentLangCode')
            .then(function (translated) {
                $scope.selectedLang = translated;
            });

        $scope.switchLang = switchLang;
        $scope.$state = $state;
        $scope.location = $location;

        function switchLang(language) {
            $scope.selectedLang = language;
            $rootScope.setLang(language);
            if (!AuthService.isAuthenticated()) {
                return;
            }

            UserService.updateProfile({locale: language})
                .catch(function (err) {
                    console.log(err);
                });
        }

        $scope.onSwipeLeft = function () {
            $mdSidenav('left').close();
        };

        var ctrl = this;
        $scope.$watch('$ctrl.pitchground', function (pitchground) {
            ctrl.pitchground = pitchground;
        });
        $scope.$watch('$ctrl.currentUser', function (currentUser) {
            $scope.currentUser = currentUser;
        });

    }

    return {
        templateUrl: 'app/modules/template/components/st-sidebar-mini/st-sidebar-mini.html',
        controller: controller,
        bindings: {pitchground: '=', currentUser:'='}
    };
});

'use strict';
define(['angular'], function(ng) {
    function Controller($scope, $mdDialog, currentFields, on_complete, $timeout) {
        var vm = $scope;
        vm.loading = {
            post: false
        };

        vm.currentFields =  Object.assign({}, currentFields);

        vm.fieldOptions = [];
        vm.selectedOption = null;

        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;
        vm.allFieldType = allFieldType;
        vm.showErrorMessage = false;

        vm.onDrop = function(srcList, srcIndex, targetList, targetIndex) {
            // Copy the item from source to target.
            targetList.splice(targetIndex, 0, srcList[srcIndex]);
            // Remove the item from the source, possibly correcting the index first.
            // We must do this immediately, otherwise ng-repeat complains about duplicates.
            if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
            srcList.splice(srcIndex, 1);
            // By returning true from dnd-drop we signalize we already inserted the item.
            return true;
        };

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            $mdDialog.cancel();
        }
        function answer(form, field) {


            var fieldName = form.field_name.$viewValue;

            for (let key in vm.currentFields) {
                if (fieldName.toLowerCase().trim() == vm.currentFields[key]['name'].toLowerCase().trim()) {
                    vm.showErrorMessage = true;
                    vm.errorMessage = 'leadgeneration.you.already.have.a.field.with.this.name';
                    $timeout(function(){
                        vm.showErrorMessage = false;
                    }, 3000);
                    return;
                }
            }

            var fieldOptions = vm.fieldOptions.filter(
                function(option) {
                    return option !== '';
                }
            );

            /*
            fieldOptions =  vm.fieldOptions.filter(
                function(option,pos) {
                    return fieldOptions.indexOf(option) == pos
                }
            );
            */

            vm.loading.post = true;

            if (getType(form.field_type.$viewValue) == 'select' && fieldOptions.length <= 0) {
                vm.showErrorMessage = true;
                vm.errorMessage = 'leadgeneration.you.need.at.least.one.option.for.this.field.type';
                $timeout(function(){
                    vm.showErrorMessage = false;
                    vm.loading.post=false;
                }, 3000);
                return;
            }

            if (form.$valid) {
                vm.loading.post = false;
                hide();

                vm.newCustomField = {
                    id: fieldName + '_id',
                    name: fieldName.toLowerCase(),
                    title: form.field_title ? form.field_title.$viewValue : null,
                    type: getInputType(form.field_type.$viewValue),
                    inputType: getType(form.field_type.$viewValue),
                    placeholder: form.field_placeholder ? form.field_placeholder.$viewValue : null,
                    deletable: true,
                    required: form.field_type !== 'Hidden',
                    show: true,
                    value: form.field_value ? form.field_value.$viewValue : null,
                    options: fieldOptions
                };
            }
        }

        function getInputType(fieldType) {
           switch(fieldType) {
                case 'Short Text':
                    return 'text';
                case 'Link':
                    return 'url';
                case 'Email':
                    return 'email';
                case 'Hidden':
                    return 'hidden';
                case 'Long Text':
                    return 'textarea';
                case 'Select':
                    return 'normal';
                case 'Multiselect':
                    return 'multiple';
            }
        }

        function getType(fieldType) {
            switch(fieldType) {
                 case 'Short Text':
                 case 'Link':
                 case 'Email':
                 case 'Hidden':
                     return 'input';
                 case 'Long Text':
                     return 'textarea';
                 case 'Select':
                 case 'Multiselect':
                     return 'select';
             }
         }

        function allFieldType() {
            return [
                "Short Text",
                "Long Text",
                "Link",
                "Email",
                "Select",
                "Hidden",
                //"Multiselect",
            ];
        }

        vm.getFieldIconByFieldType = function (fieldType) {
            switch (fieldType) {
                case 'Link': {
                    return 'link';
                }
                case 'Short Text': {
                    return 'short_text';
                }
                case 'Long Text': {
                    return 'notes';
                }
                case 'Email': {
                    return 'email';
                }
                case 'Select': {
                    return 'arrow_drop_down_circle';
                }
                /*
                case 'multiple': {
                    return 'check_box';
                }
                */
                case 'Hidden': {
                    return 'visibility_off'
                }
            }
        }

        vm.addOption = function(optionToAdd) {
            vm.insertNewOption(optionToAdd);
            if (vm.fieldOptions && vm.fieldOptions.indexOf('')>=0) {
                return;
            }

        }

        vm.insertNewOption = function(option) {
            if (option == "") {
                return;
            }

            if (vm.fieldOptions && vm.fieldOptions.indexOf(option)>=0) {
                vm.showErrorMessage = true;
                vm.errorMessage = 'leadgeneration.you.already.have.this.option';
                $timeout(function(){
                    vm.showErrorMessage = false;
                    vm.optionToAdd = "";
                }, 3000);

                return;
            }

            vm.fieldOptions.push(option);
            vm.optionToAdd = "";
        }

        vm.deleteOption = function(optionIndexToDelete) {
            vm.fieldOptions.splice(optionIndexToDelete, 1);
        }

        vm.editOption = function(option, index) {
            if (option=="") {
                vm.deleteOption(index);
                return;
            }
            if (vm.fieldOptions.indexOf(option)>=0 && vm.fieldOptions.indexOf(option)!==index) {
                vm.showErrorMessage = true;
                vm.errorMessage = 'leadgeneration.you.already.have.this.option';
                $timeout(function(){
                    vm.showErrorMessage = false;
                    vm.optionToAdd = "";
                }, 3000);

                return;
            }
            vm.fieldOptions[index] = option;
        }

        vm.onChangeName = function () {
            vm.nameFieldWasChanged = true;
        };

        vm.onChangeLabel = function () {
            if (!vm.nameFieldWasChanged) {
                vm.field.field_name = vm.field.field_title.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '-').toLowerCase().substring(0, 25);
            }
        };

        vm.$on('$destroy', function() {
            on_complete(vm.newCustomField || null);
        });

        function clearName(name) {
            return name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '-').toLowerCase();
        }

    }
    Controller.$inject=['$scope', '$mdDialog', 'currentFields', 'on_complete', '$timeout'];
    return Controller;
});

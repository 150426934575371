'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/copymetrics/components/copymetrics-heatmap/copymetrics-heatmap.html',
        bindings: {
            isLoading: '=',
            textualContent: '=',
            relevance: '='
        },
        controller: [ '$scope','$timeout', function ( $scope,$timeout) {
            var vm =  $scope;
            var $this=this;

            vm.colorByScore=colorByScore;
            vm.matchNewLine=matchNewLine;
            $this.$onInit = constructor;

            function matchNewLine(s) {
                return s && s.match(/\n/);
            }

            function constructor() {
                vm.textualContent=$this.textualContent;
                vm.relevance=$this.relevance;
                vm.isLoading=$this.isLoading;
                renderNewHeatmap();
            }

            function calculateKeywordHeatmap(text, topics) {
                vm.isLoading=true;
                if (!topics) topics = {};
                var words = text.replace(/\s{1,}/gi, ' ').trim().split(' ');
                var heatmap = [];
                for (var i = 0; i < words.length; i++) {
                    heatmap[i] = {
                        word: words[i],
                        high: false,
                        low: false,
                        med: false,
                        keywords: {},
                        heat: 0
                    };
                    if (words[i] === "&" || words[i] === "e" || words[i] === "x" || words[i] === "X" || words[i].length > 1 || words[i].match(/^(pro|id|[xsabcd]{1})$/i)) {
                        var keywordToTest = [];
                        var tmpKw = '';
                        // -----------
                        // check word range and looking for keywords
                        // -----------
                        for (var numWords = 0; numWords <= 10; numWords++) {
                            for (var j = -numWords; j <= 0; j++) {
                                tmpKw = '';
                                for (var k = 0; k <= numWords; k++) {
                                    if (words[i + (k + j)] !== undefined)
                                        tmpKw += words[i + (k + j)] + " ";
                                }
                                keywordToTest.push(tmpKw);
                            }
                        }
                        // -----------
                        // check if is a heat value
                        // -----------
                        for (var z = 0; z < keywordToTest.length; z++) {
                            isAHeatValue(words[i], topics, keywordToTest[z], heatmap[i]);
                        }
                    }
                }
                vm.heatmap = cloneObject(heatmap);
                $timeout(function () {
                    vm.isLoading=false;
                },500);
            }

            function convertFrequenciesToTopics(frequencies) {
                var topics={};
                for(var i=0;i<frequencies.length;i++){
                    topics[frequencies[i].word]=frequencies[i];
                }
                return topics;
            }

            function isAHeatValue(word, topKeywords, keyword, heatmap) {
                keyword = cleanWord(keyword);
                if (topKeywords.hasOwnProperty(keyword)&&topKeywords[keyword].relevancy>40) {
                    heatmap.keywords[keyword] = topKeywords[keyword];
                    heatmap.heat++;
                    heatmap.word = word;
                    if (topKeywords[keyword].relevancy >= 80) {
                        heatmap.high = true;
                        return;
                    }
                    if (topKeywords[keyword].relevancy >= 70) {
                        heatmap.med = true;
                        return;
                    }
                    heatmap.low = true;
                }
            }
            function cloneObject(obj) {
                return JSON.parse(JSON.stringify(obj));
            }
            function cleanWord(text) {
                return text.replace(/[inltd]{1}[’']{1}/gi, " ")
                    .replace(/ [^xeèiABCD] /gi, " ")
                    .replace(/[^\sa-zéèòàáíóúùì&\/0-9]/gmi, ' ')
                    .replace(/(?:(?!\n)\s){2,}/gm, ' ')
                    .trim().toLowerCase();
            }
            function colorByScore(score) {
                if (score > 80) {
                    return 'st-red';
                }
                if (score > 60) {
                    return 'md-orange';
                }
                return 'st-green';
            }

            function renderNewHeatmap(){
                if(vm.relevance&&vm.textualContent){
                    calculateKeywordHeatmap(
                        vm.textualContent,
                        convertFrequenciesToTopics(vm.relevance)
                    );
                }else{
                    vm.heatmap=false;
                }
            }
            vm.$watch('$ctrl.isLoading',function (isLoading) {
                vm.isLoading=isLoading;
            });
            vm.$watch('$ctrl.textualContent',function (textualContent) {
                vm.textualContent=textualContent;
                renderNewHeatmap();
            });
            vm.$watch('$ctrl.relevance',function (relevance) {
                vm.relevance=relevance;
                renderNewHeatmap();
            });
        }]
    };
});

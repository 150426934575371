export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/generate-text/generate-text.html',
    bindings: {
        currentStep: "=",
        articleDataSet: "=",
        wizardHandler: "=",
    },
    controller: ['$scope', '$timeout', 'WriterAssistantService', function ($scope, $timeout, WriterassistantService) {
        var vm = this;
        // Access `mainKeyword` in the controller
        vm.$onInit = async function () {
            try{
                if (!vm.articleDataSet.content || !vm.articleDataSet.content.html) {
                    vm.articleDataSet.content = await WriterassistantService.generateContentWithAI(vm.articleDataSet.id, vm.articleDataSet);
                }
                await vm.wizardHandler.completeWizard();
            }catch (e) {
                console.log(e);

            }
        };
    }]
};

'use strict';
define([], function() {
    function Controller($scope, $mdDialog,leadsId,$stateParams,LeadGenerationService,$state,TrackEventService) {
        var vm=$scope;
        vm.hide = hideDialog;
        vm.countLeadsUnlock = leadsId.length;
        vm.cancel = cancelDialog;
        vm.answer = answerDialog;

        function answerDialog(answer) {
            if(vm.countLeadsUnlock>0){
                LeadGenerationService.unlockLeads({leadsId:leadsId,countLeadsUnlock:leadsId.length,formId:$stateParams.formId})
                    .then(function (res) {
                        TrackEventService.event(
                            'Suite Usages',
                            'Lead Gen - Unlock Leads');
                        TrackEventService.gaV2Event(
                            'leads_unlock',{
                                tool: "Lead Generation Tool"
                            });
                        $state.reload();
                        $mdDialog.hide();
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }else{
                $mdDialog.hide();
            }

        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject=['$scope', '$mdDialog','leadsId','$stateParams','LeadGenerationService','$state','TrackEventService'];
    return Controller;
});

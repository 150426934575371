'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/h1/h1.routes',
    'modules/seospider/exam/analysis/h1/h1.controller',
    'modules/seospider/exam/analysis/h1/all/all.controller',
    'modules/seospider/exam/analysis/h1/missing/missing.controller',
    'modules/seospider/exam/analysis/h1/multiple/multiple.controller',
    'modules/seospider/exam/analysis/h1/duplicated/duplicated.controller',
    'modules/seospider/exam/analysis/h1/over/over.controller',
    'modules/seospider/exam/analysis/h1/h1table.component'
],function(
    angular,
    Routes,
    H1Ctrl,
    AllCtrl,
    MissCtrl,
    MultipleCtrl,
    DuplicatedCtrl,
    OverCtrl,
    h1Table
){
    var app=angular.module('app.seospider.exam.analysis.h1',[]);
    app.config(Routes);
    app.controller("H1Ctrl",H1Ctrl);
    app.controller("H1AllCtrl",AllCtrl);
    app.controller("H1MissingCtrl",MissCtrl);
    app.controller("H1MultipleCtrl",MultipleCtrl);
    app.controller("H1OverCtrl",OverCtrl);
    app.controller("H1DuplicatedCtrl",DuplicatedCtrl);
    app.component("h1Table",h1Table);
    return app;
});
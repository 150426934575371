/**
 * @ngdoc overview
 * @name tax.service
 *
 * @description
 * Check and Load data to pass at Tax Service
 *
 */
'use strict';
define(['app'], function (app) {
    app.service('TaxService', TaxService);
    /**
     * Tax Service
     * @namespace TaxService
     */
    function TaxService() {

        return {
            getTax: getTax,
        };

        function getEuropeanTax(countryCode) {
            var taxes = {
                'AT': 20,
                'BE': 21,
                'BG': 20,
                'HR': 25,
                'CY': 19,
                'CZ': 21,
                'DK': 25,
                'EE': 20,
                'FI': 24,
                'FR': 20,
                'DE': 19,
                'GR': 24,
                'HU': 27,
                'IE': 21,
                'IT': 22,
                'LV': 21,
                'LT': 21,
                'LU': 17,
                'MT': 18,
                'NL': 21,
                'PL': 23,
                'PT': 23,
                'RO': 19,
                'SK': 20,
                'SI': 22,
                'ES': 21,
                'SE': 25,
                'GB': 20
            }

            if (taxes[countryCode]) {
                return taxes[countryCode];
            }

            return 22;
        }

        function getTax(countryCode, isEu, isCompany) {
            /*
                Caso Italia:
                - Privato - IVA 22%
                - Azienda - Ragione sociale obbligatorio - VAT obbligatorio - IVA 22%

                Caso Estero - UE
                - Privato - IVA Custom in base al paese - Riga IVA su Fatture24 (e codice N7)
                - Azienda - Ragione sociale obbligatorio - VAT obbligatorio (ma meno stringente) - IVA 0%

                Caso Estero - NON UE
                - Privato - IVA 22%
                - Azienda - Ragione sociale obbligatorio - VAT NON obbligatorio - IVA 0%
            */

            // Caso Italia
            if (countryCode === 'IT') {
                return 22;
            }

            // Caso Europa
            if (isEu) {
                // Azienda
                if (isCompany) {
                    return 0;
                }

                // Privato
                return getEuropeanTax(countryCode);
            }

            // Caso Estero
            // Azienda
            if (isCompany) {
                return 0;
            }

            // Privato
            return 22;
        }
    }
});

/**
 * @ngdoc overview
 * @name seochecker.service:SeocheckerService
 *
 * @description
 * Aggregate External function for Module SeoChecker
 */
'use strict';
define(['app'], function (app) {
    app.service('SeocheckerService', SeocheckerService);
    SeocheckerService.$inject = ['$http', '$q', '$rootScope', '$translate', '__env', 'PagespeedService', 'LanguageService', 'API'];

    /**
     * Seochecker Service
     * @namespace SeocheckerService
     */
    function SeocheckerService($http, $q, $rootScope, $translate, __env, PagespeedService, LanguageService, API) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.seoCheckerAPI;

        return {
            testTranslations: testTranslations,
            pageSpeed: pageSpeed,
            customAudit: customAudit,
            audit: audit,
        };

        /**
         * @name testTranslations
         * @returns {Object}
         * @memberOf SeocheckerService
         */
        function testTranslations() {
            var dfd = $q.defer();

            API.get(apiPath + '/test-translations', true)
                .then(function (result) {
                    if (result && result.data) {
                        dfd.resolve(result.data);
                        return;
                    }

                    dfd.reject(new Error('Invalid response'));
                })
                .catch(function (err) {
                    dfd.reject(err);
                    console.log(err);
                });

            return dfd.promise;
        }

        /**
         * @name pageSpeed
         * @param {String} url
         * @param {String} locale
         * @param {String} strategy
         * @param {Object} rowPagespeedResult
         * @returns {Object}
         * @memberOf SeocheckerService
         */
        function pageSpeed(url, locale, strategy, rowPagespeedResult) {
            var dfd = $q.defer();

            var data = {
                url: url,
                locale: locale,
                strategy: strategy,
                rowPagespeedResult: rowPagespeedResult,
            };

            API.post(apiPath + '/page-speed', data, true)
                .then(function (result) {
                    if (result && result.data) {
                        dfd.resolve(result.data);
                        return;
                    }
                    dfd.reject(new Error('Invalid response'));
                })
                .catch(function (err) {
                    dfd.reject(err);
                    console.log(err);
                });

            return dfd.promise;
        }

        /**
         * @name customAudit
         * @desc Call API POST and return html of exams
         * @param {String} url
         * @param {String} locale
         * @returns {Object}
         * @memberOf SeocheckerService
         */
        function customAudit(url, locale) {
            var dfd = $q.defer();

            var data = {
                url: url,
                locale: locale,
            };

            API.post(apiPath + '/custom-audit', data, true)
                .then(function (result) {
                    if (result && result.data) {
                        dfd.resolve(result.data);
                        return;
                    }

                    dfd.reject(new Error('Invalid response'));
                })
                .catch(function (err) {
                    dfd.reject(err);
                    console.log(err);
                });

            return dfd.promise;
        }

        /**
         * @name formatExams
         * @desc Call API POST and return html of exams
         * @param {String} url
         * @param {object} customAudit
         * @param {object} lighthouse
         * @returns {Object}
         * @memberOf SeocheckerService
         */
        function formatExams(url, customAudit, lighthouse) {
            var dfd = $q.defer();

            var data = {
                url: url,
                customAudit: JSON.stringify(customAudit),
                lighthouse: JSON.stringify(lighthouse),
            };

            API.post(apiPath + '/format-exams', data, true)
                .then(function (result) {
                    if (result && result.data) {
                        dfd.resolve(result.data);
                        return;
                    }

                    dfd.reject(new Error('Invalid response'));
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        }

        /**
         *
         * @param deferred
         * @param url
         * @param type
         * @param language
         * @param attempt
         * @param rowPagespeedResult
         */
        function performanceFromBackend(deferred, url, type, language, attempt, rowPagespeedResult) {
            if (!attempt) {
                attempt = 0;
            }

            pageSpeed(url, language, type, rowPagespeedResult)
                .then(function (res) {
                    if (res) {
                        deferred.resolve(res);
                        return;
                    }
                    performanceFromBackend(deferred, url, type, language, (attempt + 1), rowPagespeedResult)
                })
                .catch(function (err) {
                    if (attempt >= 3) {
                        deferred.reject(err);
                        return;
                    }
                    performanceFromBackend(deferred, url, type, language, (attempt + 1), rowPagespeedResult)
                });
        }

        /**
         *
         * @param url
         * @param type
         * @param language
         * @returns {Promise<any>}
         */
        function performance(url, type, language) {
            var deferred = $q.defer();
            PagespeedService.getAnalysis(url, type, language)
                .then(function (res) {
                    var score = PagespeedService.getScore(res);

                    if (res && score > 0) {
                        performanceFromBackend(deferred, url, type, language, 0, res);
                        return;
                    }
                    performanceFromBackend(deferred, url, type, language);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        /**
         * @name audit
         * @desc First Audit, performs and returns a generic and quick overview of the scan performed.
         * @param {String} url es: www.mysite.com
         * @returns {String}
         * @memberOf SeocheckerService
         */
        function audit(url) {
            var deferred = $q.defer();
            var resolveResults = {
                overview: {},
                performance: {
                    desktop: {},
                    mobile: {},
                }
            };
            var currentLang = LanguageService.getLanguageCodeSaveOnStorage();
            $q.all([
                customAudit(url, currentLang),
                performance(url, 'desktop', currentLang),
                performance(url, 'mobile', currentLang),
            ])
                .then(function ([resCustomAudit, resDesktop, resMobile]) {

                    /**
                     * N.B.: resCustomAudit.overview non è necessario e viene già gestito
                     */
                    if (!resCustomAudit || !resCustomAudit.audition) {
                        deferred.reject(new Error('Invalid SEO Checker response'));
                        return;
                    }
                    resolveResults.overview = resCustomAudit.overview;
                    if (resDesktop || resDesktop.pagespeed || resMobile || resMobile.pagespeed) {
                        resolveResults.performance = {
                            desktop: resDesktop.pagespeed,
                            mobile: resMobile.pagespeed,
                        };
                    }

                    var lighthouse = {};
                    if (resMobile && resMobile.lighthouse) {
                        lighthouse = resMobile.lighthouse;
                    }
                    return formatExams(url, resCustomAudit.audition, lighthouse);
                })
                .then(function (formattedResult) {
                    resolveResults.audit = formattedResult;
                    deferred.resolve(resolveResults);
                })
                .catch(function (err) {
                    deferred.reject(err);
                    console.log(err);
                });

            return deferred.promise;
        }
    }
});

'use strict';
define(['angular', 'app'], function (angular, app) {
    app.directive('template', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, element, attrs) {
                scope.$watch(attrs.template, function (value) {
                    if (value) {
                        element.html(value);
                        $compile(element.contents())(scope);
                    }
                });
            }
        };
    }]);
});

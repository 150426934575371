'use strict';
define([
    'angular',
    'modules/seospider/seospider.routes',
    'modules/seospider/components/spider-card/spider-card.component',
    'modules/seospider/components/pages-downloaded/pages-downloaded.component',
    'modules/seospider/components/status-code-doughnut/status-code-doughnut.component',
    'modules/seospider/components/link-scanned/link-scanned.component',
    'modules/seospider/components/status-code-bar/status-code-bar.component',
    'modules/seospider/components/select-session/select-session.component',
    'modules/seospider/components/select-project/select-project.component',
    'modules/seospider/list/list.module',
    'modules/seospider/exam/exam.module',
], function (
    angular,
    routes,
    seoSpiderProjectCard,
    seoSpiderPagesDownloaded,
    seoSpiderStatusCodeDoughnut,
    seoSpiderLinkScanned,
    seoSpiderStatusCodeBar,
    seoSpiderSelectSession,
    seoSpiderSelectProject
    ) {
    var app = angular.module('app.seospider', [
        'app.seospider.list',
        'app.seospider.exam'        
    ]);

    app.component('seoSpiderProjectCard', seoSpiderProjectCard);
    app.component('seoSpiderPagesDownloaded',seoSpiderPagesDownloaded);
    app.component('seoSpiderStatusCodeDoughnut',seoSpiderStatusCodeDoughnut);
    app.component('seoSpiderLinkScanned',seoSpiderLinkScanned);
    app.component('seoSpiderStatusCodeBar',seoSpiderStatusCodeBar);
    app.component('seoSpiderSelectSession',seoSpiderSelectSession);
    app.component('seoSpiderSelectProject',seoSpiderSelectProject);
    
    app.config(routes);
    return app;
});

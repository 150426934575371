import globalLanguages from '../../../locales/global-languages.json';

function SELandingCtrl($scope, AuthService, $mdMedia, $mdDialog, $state, WriterAssistantService, UserService) {
    var vm = $scope;
    vm.$mdMedia = $mdMedia;
    vm.filterList = filterList;
    vm.langs = globalLanguages;
    window.onbeforeunload = null;
    vm.ctrlTable = {
        deleteArticle: deleteArticle
    };
    vm.createArticle = createArticle;
    vm.deleteArticle = deleteArticle;
    vm.getLimitBasedOnWorkspace = getLimitBasedOnWorkspace;
    vm.isLoading = true;

    vm.articleDataSet = {
        id: null,
        mode: 'WRITE_WITH_AI',
        mainKeyword: null,
        country: 'IT',
        language: 'it',
        device: 'mobile',
    };


    vm.articles = [];

    function getArticles() {
        if (!AuthService.isAuthenticated()) {
            vm.articles = [];
            vm.isLoading = false;
            return;
        }
        WriterAssistantService.getArticles()
            .then(result => {
                if (!result || !result.data || !result.data.length) {
                    vm.isLoading = false;
                    return;
                }
                vm.articles = result.data;
                vm.isLoading = false;
            })
            .catch(err => {
                vm.isLoading = false;
            });
    }

    getArticles();

    function getLimitBasedOnWorkspace() {
        return 1000;
    }

    function filterList($event, value) {
        var keywordResearch = value.toUpperCase();
        var rowTable = document.getElementsByTagName("tr");
        var titlesOfArticles = document.getElementsByClassName("title-article");
        for (var i = 0; i < titlesOfArticles.length; i++) {
            var txtValue = titlesOfArticles[i].textContent || titlesOfArticles[i].innerText;
            if (txtValue.toUpperCase().indexOf(keywordResearch) > -1) {
                rowTable[i + 1].style.display = "";
            } else {
                rowTable[i + 1].style.display = "none";
            }
        }
    }

    function deleteArticle(articleId, ev) {
        $mdDialog.show({
            controller: 'WriterAssistantDeleteArticleCtrl',
            templateUrl: "app/modules/writerassistant/editor/dialog/delete-article/delete-article.html",
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                articleId: articleId,
            }
        }).then(function () {
            // Remove the article from vm.articles array where id = articleId
            vm.articles = vm.articles.filter(function (article) {
                return article.id !== articleId;
            });
        });
    }

    function createArticle() {
        $state.go('app.writerassistant.wizard', vm.articleDataSet);
    }

    vm.analysisLimit = 0;
    vm.aiCreditsLimit = 0;

    function updateLimits() {
        UserService.limits()
            .then(function (res) {
                vm.analysisLimit = res.data.limits.find(function (service) {
                    return service.alias === 'analysis';
                });

                vm.aiCreditsLimit = res.data.limits.find(function (service) {
                    return service.alias === 'ai-credits';
                });
            })
            .catch(function (err) {
                console.log(err);
                return null;
            });
    }

    updateLimits();
}

SELandingCtrl.$inject = ['$scope', 'AuthService', '$mdMedia', '$mdDialog', '$state', 'WriterAssistantService', 'UserService'];
export default SELandingCtrl;


'use strict';
define([], function () {


    return {
        templateUrl: 'app/modules/seochecker/components/multicolor-indicator/multicolor-indicator.html',
        bindings: {
            loading: '<',
            value: '<',
            ranges: '<',
            showUnit: '<'
        },
        controller: ['$scope', '$translate', '$location', 'UtilitiesService', '$window', '$element', '$timeout', '$filter', function ($scope, $translate, $location, UtilitiesService, $window, $element, $timeout, $filter) {
            var vm = $scope;

            var maxRange;

            this.$onInit = function () {
                vm.ranges = this.ranges;
                vm.showUnit = this.showUnit;
                maxRange = vm.ranges.poor + (vm.ranges.poor - vm.ranges.medium);
                vm.loading = this.loading;
                vm.elementId = guidGenerator();

                if(!vm.loading && vm.ranges && vm.ranges.ratio ) {
                    vm.animateIndicator = {'left': convertValueToPercentage(vm.value*vm.ranges.ratio) + '%', 'opacity': 1};

                    animateValue( 0, vm.value, 2000);
                    vm.currentValue = vm.value;
                }
            }

            this.$onChanges = function () {
                vm.loading = this.loading;
                vm.value = this.value;

                if(!vm.loading && vm.ranges && vm.ranges.ratio ) {
                    vm.animateIndicator = {'left': convertValueToPercentage(vm.value*vm.ranges.ratio) + '%', 'opacity': 1};

                    animateValue(vm.currentValue || 0, vm.value, 2000);
                    vm.currentValue = vm.value;
                }

            }

            function animateValue(start, end, duration) {
                var obj = document.getElementById(vm.elementId);

                if (!obj) {
                    return;
                }
                var startTimestamp = null;
                var step = function(timestamp) {
                    if (!startTimestamp) {
                        startTimestamp = timestamp;
                    }
                    var progress = Math.min((timestamp - startTimestamp) / duration, 1);
                    obj.innerHTML = Number((progress * (end - start) + start)).toFixed(2);
                    if (progress < 1) {
                        window.requestAnimationFrame(step);
                    }
                };
                window.requestAnimationFrame(step);
            }

            function guidGenerator() {
                var S4 = function() {
                   return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
                };
                return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
            }

            function convertValueToPercentage(value) {
                if (value < 0) {
                    return 0;
                } else if (value > maxRange) {
                    return 100;
                } else if (value == (vm.ranges.medium + (vm.ranges.poor - vm.ranges.medium) /2)) {
                    return 50;
                } else if (value <= vm.ranges.medium) {
                    return Math.floor(value * 33.3 / vm.ranges.medium);
                } else if (value <= vm.ranges.poor) {
                    return Math.floor(value * 66.6 / vm.ranges.poor);
                } else {
                    return Math.floor((value) * 100 / maxRange);
                }
            }

        }]
    };
});

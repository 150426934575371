'use strict';
define([], function () {
    function Controller($rootScope, $scope, $timeout, $translate, ExportService, $mdDialog, KeywordTrackerService, EstimatedVisitService, $state, $stateParams, $interval, SocketFactory,
                        stToggleExpand, projects, UserService, LanguageService, $window, $filter) {
        function _constructor() {
            getOneProject();
            if ($scope.onBoarding) {
                openWelcomeToProjectDialogs();
            }
        }

        $scope.projects = projects;
        $scope.numberOfKeywordsAllProject = keywordsForProject(projects);
        $scope.firstLoad = $stateParams.firstLoad ? $stateParams.firstLoad : false;
        $scope.onBoarding = $stateParams.onBoarding ? $stateParams.onBoarding : false;
        $scope.messageEmptyDataPosition = '<i class="icon-no-data-kw custom-message-template-icon"></i>' +
            '<div class="md-subhead m-b-xs">' +
            '{{\'keyword.tracker.nodata.keywords\'|translate}}' +
            '</div>';
        $scope.messageEmptyDataSerp = '<i class="icon-no-data-kw custom-message-template-icon"></i>' +
            '<div class="md-subhead m-b-xs">' +
            '{{\'keyword.tracker.nodata.pages\'|translate}}' +
            '</div>';

        $stateParams = setStateParamsFromLocalStorage($stateParams);

        var domainTypes = [{
            id: 'exact_url',
            label: 'project.wizard.exact.url',
            detail: 'project.wizard.only.specified.url',
            example: 'project.wizard.url.example'
        },
            {
                id: 'prefix',
                label: 'project.wizard.path',
                detail: 'project.wizard.path.including.subfolders',
                example: 'project.wizard.path.example'
            },
            {
                id: 'domain_without_subdomains',
                label: 'project.wizard.domain',
                detail: 'project.wizard.only.specified.domain',
                example: 'project.wizard.domain.example'
            },
            {
                id: 'domain_with_subdomains',
                label: 'project.wizard.analyze.all',
                detail: 'project.wizard.domain.including.subdomains',
                example: 'project.wizard.subdomains.example'
            },
        ];
        var getOneProjectIsRun = false;
        var settings = {
            setDevice: function (idDevice) {
                if (!idDevice) {
                    return;
                }
                var listDevice = [{
                    id: 1,
                    name: 'desktop'
                }, {
                    id: 2,
                    name: 'mobile'
                }];
                var deviceName = '';
                angular.forEach(listDevice, function (obj) {
                    if (obj.id === idDevice) {
                        deviceName = obj.name;
                    }
                });
                return deviceName;
            },
            setSearchEngine: function (idSearchEngine) {
                if (!idSearchEngine) {
                    return;
                }
                var listSearchEngine = [{
                    id: 1,
                    name: 'Google'
                }, {
                    id: 2,
                    name: 'Yahoo'
                }, {
                    id: 3,
                    name: 'Yandex'
                }, {
                    id: 4,
                    name: 'Bing'
                }];
                var searchEngineName = '';
                angular.forEach(listSearchEngine, function (obj) {
                    if (obj.id === idSearchEngine) {
                        searchEngineName = obj.name;
                    }
                });
                return searchEngineName;
            },
        };
        $scope.expandSection = stToggleExpand.isExpanded('keyword-tracker-toggle') || false;
        $scope.tableInLoading = true;
        $scope.tablePercInLoading = true;
        $scope.tableSerpLoading = true;
        $scope.keywordsRankTracker = 0;
        $scope.range = {
            startDate: new Date($stateParams.start),
            endDate: new Date($stateParams.end)
        };
        $scope.isEmpty = isEmpty;
        $scope.applyRangeOfDate = applyRangeOfDate;
        $scope.countAllKeywords = countAllKeywords;
        $scope.getOneProject = getOneProject;
        $scope.allKeywordPerTrend = allKeywordPerTrend;
        $scope.selectOneProject = selectOneProject;
        $scope.cleanUrl = cleanUrl;
        $scope.compareDateProject = compareDateProject;
        $scope.competitorsSelected = $stateParams.competitors ? JSON.parse($stateParams.competitors) : [];
        $scope.locationsSelected = $stateParams.locations ? JSON.parse($stateParams.locations) : [];
        $scope.isExpanded = stToggleExpand.isExpanded;
        $scope.grow = {
            up: 0,
            down: 0,
            unchanged: 0
        };
        $scope.position = {
            competitors: []
        };
        $scope.keywords = {
            rows: [],
            rowsSelected: []
        };
        $scope.copyToClipboardOptions = {
            class: 'st-button__rounded--md--blue--outline',
            text: 'keyword.exp.copy.clipboard',
            showToast: true,
            icon: 'icon-copy-filled',
            data: [],
            mode: 'rows',
        };
        $scope.exportCsvOptions = {
            stoTool: 'rank-tracker',
            class: 'st-button__rounded--md--violet md-button hide-xs',
            text: $translate.instant('keyword.exp.export.csv'),
            icon: 'icon-download',
            data: [],
            fileName: 'keywords',
        };
        var filters = validateFilters($stateParams);


        $scope.serpController = {
            getEstimatedVisits: function (visits, position) {
                return EstimatedVisitService.get(visits, position);
            }
        };

        $scope.$watchCollection('locationsSelected', function (newValue, oldValue) {
            filters.locations = newValue;
            updateUrlParams(filters);
            if (newValue !== oldValue) {
                filters.queryId = '';
                $scope.keywordSelectedPerTrend = {};
                getOneProject();
            }
        });

        $scope.searchInTable = function () {
            $scope.keywords.rows = $filter("filter")($scope.keywords.rows, $scope.searchQuery);
        };

        function keywordsForProject(projects) {
            var numberOfKeywords = 0;
            angular.forEach(projects, function (project) {
                if (project.numberOfKeywords) {
                    numberOfKeywords += project.numberOfKeywords;
                }
            });
            return numberOfKeywords;
        }

        function setStateParamsFromLocalStorage(params) {
            var filterOnLocalStorage = $window.localStorage.getItem('project-filters' + params.project) ?
                JSON.parse($window.localStorage.getItem('project-filters' + params.project)) : undefined;

            if (!filterOnLocalStorage) {
                return params;
            }

            if (!params.competitors && !params.excludedWords && !params.includedWords && !params.maxEv &&
                !params.minEv && !params.minVolume && !params.maxVolume &&
                !params.maxPosition && !params.minPosition && !params.locations && !params.start &&
                !params.end && !params.queryId && !params.tag
            ) {
                params = filterOnLocalStorage;
            }
            return params;
        }

        function applyRangeOfDate(range) {
            $scope.range = {
                startDate: new Date(range.startDate),
                endDate: new Date(range.endDate)
            };
            filters.startDate = range.startDate;
            filters.endDate = range.endDate;
            updateUrlParams(filters);
            getOneProject();
        }

        function selectOneProject(id) {
            if (id === $scope.project.id) {
                return;
            }

            var params = {
                competitors: undefined,
                includedWords: undefined,
                maxEv: undefined,
                queryId: undefined,
                tag: undefined,
                minEv: undefined,
                minVolume: undefined,
                maxVolume: undefined,
                maxPosition: undefined,
                minPosition: undefined,
                excludedWords: undefined,
                end: undefined,
                locations: undefined,
                project: id,
                start: undefined
            };

            $state.go('app.keywordtracker.overview', params);
        }

        function validateFilters(params) {
            var object = {
                queryId: !isEmpty($scope.keywordSelectedPerTrend) ? $scope.keywordSelectedPerTrend.id : params.queryId ? params.queryId : undefined,
            };

            if ($scope.locationsSelected && $scope.locationsSelected.length) {
                object.locations = $scope.locationsSelected;
            }

            if ($scope.competitors && $scope.competitors.length) {
                object.competitors = $scope.competitors;
            }

            if (params.start) {
                object.startDate = params.start;
            }

            if (params.end) {
                object.endDate = params.endDate;
            }

            if (params.excludedWords && params.excludedWords !== 'null') {

                object.excludedWords = formattedInArray(params.excludedWords);
            }
            if (params.includedWords && params.includedWords !== 'null') {

                object.includedWords = formattedInArray(params.includedWords);
            }
            if (params.tag && params.tag !== 'null') {

                object.includedWords = formattedInArray(params.tag);
            }

            object = setMinMaxFilter(object, 'position', params.minPosition, params.maxPosition);
            object = setMinMaxFilter(object, 'volume', params.minVolume, params.maxVolume);
            object = setMinMaxFilter(object, 'ev', params.minEv, params.maxEv);
            return object;
        }

        function applyFiltersFromBox(params) {
            $scope.showFilter = false;
            filters = validateFilters(params.filters);
            updateUrlParams(filters);
            getOneProject();
        }

        function formattedInArray(string) {
            if (string) {
                return string.split(',');
            }
            return null;
        }

        function compareDateProject(createdAt) {
            if (!createdAt) {
                return true;
            }
            return new Date().getFullYear() === new Date(createdAt).getFullYear() &&
                new Date().getDate() === new Date(createdAt).getDate() &&
                new Date().getMonth() === new Date(createdAt).getMonth();
        }

        function getOneProject() {
            if (getOneProjectIsRun) {
                return;
            }
            getOneProjectIsRun = true;
            if (!$scope.onBoarding) {
                $scope.tableInLoading = true;
                $scope.tablePercInLoading = true;
            }
            KeywordTrackerService.getOneProject($stateParams.project, filters)
                .then(function (res) {
                    updateUrlParams(filters);
                    $scope.project = res.data.trackerProject;
                    $scope.keywords = {
                        rows: sortSearchesAndSetLoad($scope.project.keywords) || [],
                        rowsSelected: []
                    };
                    $scope.firstLoad = false;
                    $scope.grow = {
                        up: 0,
                        down: 0,
                        unchanged: 0
                    };
                    $scope.keywordSelectedPerTrend = getKeywordById($stateParams.queryId);

                    setRowsForCopyToClipboardAndCSV($scope.project.keywords);
                    $scope.position.competitors = countOccurencesForPosition();
                    if (!$scope.onBoarding) {
                        $timeout(function () {
                            $scope.tableInLoading = false;
                            $scope.tablePercInLoading = false;
                        }, 2000);
                    }
                    getOneProjectIsRun = false;
                }).catch(function (err) {
                // $window.console.error(err);
                getOneProjectIsRun = false;
                $scope.tableInLoading = false;
                $scope.tablePercInLoading = false;
            });
        }

        function sortSearchesAndSetLoad(keywords) {
            keywords = keywords.map(function (keyword) {
                if (keyword.searches && keyword.searches.length > 0) {
                    keyword.searches = keyword.searches.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                }

                keyword.difference = getDifferenceRanked(keyword);

                if (keyword.competitorSearches) {
                    var idCompetitors = Object.keys(keyword.competitorSearches);
                    angular.forEach(idCompetitors, function (id) {
                        if (!keyword.competitorSearches || !keyword.competitorSearches[id] || !(keyword.competitorSearches[id] instanceof Array)) {
                            return;
                        }

                        keyword.competitorSearches[id] = keyword.competitorSearches[id].sort(function (a, b) {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                        });
                    });
                }

                return keyword;
            });

            return keywords;
        }

        function setMinMaxFilter(filters, filterType, minFilter, maxFilter) {
            if ((!minFilter || minFilter === 'null') && (!maxFilter || maxFilter === 'null')) {
                return filters;
            }

            if (!filters[filterType]) {
                filters[filterType] = {};
            }

            if (minFilter && minFilter !== 'null') {
                filters[filterType].min = minFilter;
            } else {
                filters[filterType].min = null;
            }

            if (maxFilter && maxFilter !== 'null') {
                filters[filterType].max = maxFilter;
            } else {
                filters[filterType].max = null;
            }

            return filters;
        }

        $scope.$watch('keywordSelectedPerTrend', function (keywordSelectedPerTrend) {
            if (!isEmpty(keywordSelectedPerTrend)) {
                filters.queryId = keywordSelectedPerTrend.id;
                var interval = $interval(function () {
                    setSerpRowsForKeywordSelected(keywordSelectedPerTrend);
                    $interval.cancel(interval);
                }, 200);
            }
            updateUrlParams(filters);
        });

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }

        function getKeywordById(queryId) {
            if (!queryId) {
                return {};
            }
            if (!$scope.project) {
                return queryId;
            }
            var query;
            angular.forEach($scope.project.keywords, function (keyword) {
                if (JSON.stringify(keyword.id) === queryId) {
                    query = keyword;
                }
            });
            if (!query) {
                return {};
            }
            focusOnKeyword(query, true);
            return query;
        }

        $scope.competitorIsVisible = function (name) {
            return $scope.competitorsSelected.some(function (competitor) {
                return competitor === name;
            });
        };

        $scope.$watchCollection('keywords.rowsSelected', function (rowsSelected) {
            setRowsForCopyToClipboardAndCSV(rowsSelected);
        });

        $scope.$watchCollection('competitorsSelected', function (competitorsSelected) {
            filters.competitors = competitorsSelected;
            updateUrlParams(filters);
            if (!isEmpty($scope.keywordSelectedPerTrend)) {
                $scope.tableSerpLoading = true;
                var interval = $interval(function () {
                    setSerpRowsForKeywordSelected($scope.keywordSelectedPerTrend);
                    $interval.cancel(interval);
                }, 200);
            }
        });

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function setSerpRowsForKeywordSelected(keywordSelected) {
            var rows = [];

            if (!keywordSelected.searches && keywordSelected.searches.length <= 0 && !keywordSelected.searches.ranked) {
                $scope.tableSerpLoading = false;
                return;
            }

            var searchesPrincipal = {
                linkUrlKeyword: '',
                position: '',
                difference: 0,
                color: '#2296f3',
                serpFeatures: []
            };

            if (keywordSelected.avgVolume) {
                searchesPrincipal.avgVolume = keywordSelected.avgVolume;
            }
            if (keywordSelected.searches.ranked && keywordSelected.searches.ranked.linkUrl) {
                searchesPrincipal.linkUrlKeyword = keywordSelected.searches.ranked.linkUrl;
            }
            if (keywordSelected.searches.ranked && keywordSelected.searches.ranked.position) {
                searchesPrincipal.position = keywordSelected.searches.ranked.position;
            }
            if (keywordSelected.searches.ranked && keywordSelected.searches.ranked.difference) {
                searchesPrincipal.difference = keywordSelected.searches.ranked.difference;
            }
            if (keywordSelected.searches.ranked && keywordSelected.searches.ranked.difference) {
                searchesPrincipal.difference = keywordSelected.searches.ranked.difference;
            }
            if (keywordSelected.searches && keywordSelected.searches.length && keywordSelected.searches[0].extraParams) {
                var extraParams = JSON.parse(keywordSelected.searches[0].extraParams);
                searchesPrincipal.serpFeatures = extraParams.serpFeatures && extraParams.serpFeatures.length ? extraParams.serpFeatures : [];
            }
            if (searchesPrincipal.linkUrlKeyword) {
                rows.push(searchesPrincipal);
            }

            // Add indexed pages in addition to the first
            if (keywordSelected.searches[0] && keywordSelected.searches[0].count > 0 && keywordSelected.searches[0].pages) {
                var formattedPages = keywordSelected.searches[0].pages;
                if (typeof formattedPages === 'string') {
                    formattedPages = JSON.parse(formattedPages);
                }

                if (formattedPages && formattedPages.length > 0) {
                    angular.forEach(formattedPages, function (page, i) {
                        if (i === 0) return;
                        var pageIndexed = {
                            linkUrlKeyword: '',
                            position: '',
                            difference: 0,
                            color: '#2296f3',
                            serpFeatures: []
                        };
                        if (page.url) {
                            pageIndexed.linkUrlKeyword = page.url;
                        }

                        if (page.position) {
                            pageIndexed.position = page.position;
                        }

                        if (page.difference) {
                            pageIndexed.difference = page.difference;
                        }

                        if (keywordSelected.avgVolume) {
                            pageIndexed.avgVolume = keywordSelected.avgVolume;
                        }
                        if (pageIndexed.linkUrlKeyword) {
                            rows.push(pageIndexed);
                        }
                    });
                }
            }

            //Add competitors searches
            if ($scope.competitorsSelected.length > 0) {
                for (var i = 0, j = $scope.competitorsSelected.length; i < j; i++) {
                    var competitor;
                    if ($scope.project.competitors && $scope.project.competitors.length > 0) {
                        angular.forEach($scope.project.competitors, function (obj) {
                            if (obj.linkUrl == $scope.competitorsSelected[i]) {
                                competitor = obj;
                            }
                        });
                    }

                    if (!competitor || !keywordSelected.competitorSearches || !keywordSelected.competitorSearches[competitor.id]) {
                        $scope.tableSerpLoading = false;
                        continue;
                    }

                    //Add indexed pages in addition to the first for competitors
                    if (keywordSelected.competitorSearches[competitor.id] &&
                        keywordSelected.competitorSearches[competitor.id][0] &&
                        keywordSelected.competitorSearches[competitor.id][0].pages
                    ) {
                        var competitorFormattedPages = keywordSelected.competitorSearches[competitor.id][0].pages;
                        if (typeof competitorFormattedPages === 'string') {
                            competitorFormattedPages = JSON.parse(competitorFormattedPages);
                        }

                        if (competitorFormattedPages && competitorFormattedPages.length >= 0) {
                            angular.forEach(competitorFormattedPages, function (page, i) {
                                var pageIndexedCompetitors = {
                                    linkUrlKeyword: '',
                                    position: '',
                                    difference: 0,
                                    color: '#ffff',
                                    serpFeatures: []
                                };
                                if (page.url) {
                                    pageIndexedCompetitors.linkUrlKeyword = page.url;
                                }
                                if (page.position) {
                                    pageIndexedCompetitors.position = page.position;
                                }
                                if (competitor.color) {
                                    pageIndexedCompetitors.color = competitor.color;
                                }
                                if (page.difference) {
                                    pageIndexedCompetitors.difference = page.difference;
                                }
                                if (keywordSelected.avgVolume) {
                                    pageIndexedCompetitors.avgVolume = keywordSelected.avgVolume;
                                }
                                if (pageIndexedCompetitors.linkUrlKeyword) {
                                    rows.push(pageIndexedCompetitors);
                                }
                            });
                        }
                    }
                }
            }

            rows = rows.sort(function (elem1, elem2) {
                if (elem1.position > elem2.position) {
                    return 1;
                }

                if (elem1.position < elem2.position) {
                    return -1;
                }

                return elem1.position - elem2.position;
            });

            $scope.serp = {
                rows,
            };
            $scope.tableSerpLoading = false;
        }

        function getDifferenceRanked(row) {
            if (!row || !row.searches || row.searches.length <= 0) {
                return {
                    positionForSortBy: 101
                };
            }

            var difference = 0;
            if (row.searches.length > 1) {
                difference = row.searches[row.searches.length - 1].position - row.position;
            }

            return difference || 0.1;
        }

        function setRowsForCopyToClipboardAndCSV(queries) {
            var competitors;
            var keywordsName = [];
            var lastItem = {
                position: '',
                linkUrl: ''
            };

            if (!queries.length) {
                return [];
            }
            var formattedQueriesForCSV = [];

            queries.forEach(function (query) {
                var formattedCSVRow = {
                    position: 0,
                    difference: '',
                    device: 'desktop',
                    searchEngine: 'google',
                    avgCompetition: 0,
                    avgVolume: 0,
                    topRanked: '',
                    keyword: '',
                    avgCpc: 0
                };

                if (!query) {
                    return;
                }
                keywordsName.push(query.keyword);

                if (query && query.searches && query.searches.length) {
                    lastItem = query.searches[0];
                }

                formattedCSVRow.keyword = query.keyword.replace(/(\r\n|\n|\r)/gm, "");
                formattedCSVRow.avgCpc = query.avgCpc;
                formattedCSVRow.avgCompetition = query.avgCompetition;
                formattedCSVRow.avgVolume = query.avgVolume;
                formattedCSVRow.countryCode = query.countryCode;
                formattedCSVRow.topRanked = lastItem.linkUrl;
                formattedCSVRow.position = lastItem.position;
                formattedCSVRow.difference = getDifferenceRanked(query);

                if (query.deviceId) {
                    formattedCSVRow.device = settings.setDevice(query.deviceId);
                }

                if (query.searchEngineId) {
                    formattedCSVRow.searchEngine = settings.setSearchEngine(query.searchEngineId);
                }

                if (!isEmpty(query.competitorSearches)) {
                    competitors = setCompetitorsField(query.competitorSearches);
                    formattedCSVRow = Object.assign(formattedCSVRow, competitors);
                }

                formattedQueriesForCSV.push(formattedCSVRow);
            });

            $scope.copyToClipboardOptions.data = [keywordsName];
            $scope.exportCsvOptions.data = ExportService.convertObjectToCsvArray(formattedQueriesForCSV);
        }

        function setCompetitorsField(competitorSearches) {
            var searches;
            var obj = {};
            $scope.project.competitors.forEach(function (competitor) {

                searches = competitorSearches[competitor.id];
                var lastItem = {
                    position: '',
                    linkUrl: ''
                };
                if (searches && searches.length) {
                    lastItem = searches[searches.length - 1];
                }
                obj['' + competitor.linkUrl + '(position)'] = lastItem.position;
                obj['' + competitor.linkUrl + '(topRanked)'] = lastItem.linkUrl;
            });
            return obj;
        }

        $scope.deleteKeywords = function (ev, queriesSelected) {
            if (!queriesSelected.length) {
                return;
            }
            $mdDialog.show({
                templateUrl: "app/modules/keywordtracker/dialogs/delete-keywords/delete-keywords.html",
                controller: "KeywordTrackerDeleteKeywordsCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    queriesSelected: queriesSelected,
                    projectId: $scope.project.id
                }
            }).then(function (answer) {
                var keywordsClear = $scope.keywords.rows.filter(function (row) {
                    return !queriesSelected.some(function (querySelected) {
                        //Remove focus on keyword
                        if (filters.queryId === querySelected.id) {
                            allKeywordPerTrend();
                        }
                        return row.id === querySelected.id;
                    });
                });
                $scope.keywords.rows = keywordsClear;
                $scope.project.keywords = keywordsClear;
                $scope.position.competitors = countOccurencesForPosition();
                $scope.grow = {
                    up: 0,
                    down: 0,
                    unchanged: 0
                };
                setRowsForCopyToClipboardAndCSV($scope.keywords.rows);
                $rootScope.$broadcast("updateKeywords", $scope.keywords.rows);
            }, function () {
            });
        };

        $scope.setDomainType = function (type) {
            if (!type) {
                return;
            }
            var stringType;
            angular.forEach(domainTypes, function (x) {
                if (x.id === type) {
                    stringType = x.label;
                }
            });
            return $translate.instant(stringType);
        };

        function allKeywordPerTrend() {
            filters.queryId = undefined;
            focusOnKeyword($scope.keywordSelectedPerTrend, false);
            $scope.keywordSelectedPerTrend = {};
        }

        function focusOnKeyword(keywordSelectedPerTrend, select) {
            var index = $scope.keywords.rows.findIndex(function (row) {
                return row === keywordSelectedPerTrend;
            });
            $scope.keywords.rows[index].focus = select;
        }

        $scope.showFilters = false;
        $scope.filtersComponent = {
            filters: $stateParams || {},
            callback: applyFiltersFromBox
        };

        function countOccurencesForPosition() {
            if (!$scope.project || !$scope.project.keywords || $scope.project.keywords.length <= 0) {
                return [];
            }

            var lastPositionsForKeyword = [];
            angular.forEach($scope.project.keywords, function (keyword) {
                if (keyword && keyword.searches && keyword.searches.length && keyword.searches[0].position) {
                    lastPositionsForKeyword.push(keyword.searches[0].position);
                    return;
                }

                lastPositionsForKeyword.push(0);
            });

            var counter1st = 0,
                counter2nd_3rd = 0,
                counter4th_10th = 0,
                counter11th_20th = 0,
                counter21th_100th = 0,
                counter101th = 0;
            for (var i = 0; i < lastPositionsForKeyword.length; i++) {
                if (lastPositionsForKeyword[i] == 1) {
                    counter1st++;
                } else if (lastPositionsForKeyword[i] >= 2 && lastPositionsForKeyword[i] <= 3) {
                    counter2nd_3rd++;
                } else if (lastPositionsForKeyword[i] >= 4 && lastPositionsForKeyword[i] <= 10) {
                    counter4th_10th++;
                } else if (lastPositionsForKeyword[i] >= 11 && lastPositionsForKeyword[i] <= 20) {
                    counter11th_20th++;
                } else if (lastPositionsForKeyword[i] >= 21 && lastPositionsForKeyword[i] <= 100) {
                    counter21th_100th++;
                } else {
                    counter101th++;
                }
            }
            $scope.tablePercInLoading = false;

            return [{
                position: '1st',
                hash: counter1st,
                get perc() {
                    return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                }
            },
                {
                    position: '2nd - 3rd',
                    hash: counter2nd_3rd,
                    get perc() {
                        return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                    }
                },
                {
                    position: '4th - 10th',
                    hash: counter4th_10th,
                    get perc() {
                        return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                    }
                },
                {
                    position: '11th - 20th',
                    hash: counter11th_20th,
                    get perc() {
                        return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                    }
                },
                {
                    position: '21th - 100th',
                    hash: counter21th_100th,
                    get perc() {
                        return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                    }
                },
                {
                    position: '101th+',
                    hash: counter101th,
                    get perc() {
                        return Math.floor(this.hash * 100 / lastPositionsForKeyword.length) || 0;
                    }
                },
            ];

        }

        function countAllKeywords() {
            if ($scope.project) {
                return $scope.numberOfKeywordsAllProject;
            }
            return 0;
        }

        function updateUrlParams(objectFilters) {
            var params = {
                project: $stateParams.project,
                start: undefined,
                end: undefined,
                locations: undefined,
                competitors: undefined,
                queryId: undefined,
                excludedWords: undefined,
                includedWords: undefined,
                tag: undefined,
                minVolume: undefined,
                maxVolume: undefined,
                minEv: undefined,
                maxEv: undefined,
                minPosition: undefined,
                maxPosition: undefined,
            };

            //Filter Calendar
            params.start = objectFilters.startDate ? objectFilters.startDate : $stateParams.start;
            params.end = objectFilters.endDate ? objectFilters.endDate : $stateParams.end;

            //Filter locations
            if (objectFilters.locations) {
                params.locations = objectFilters.locations.length ? JSON.stringify(objectFilters.locations) : undefined;
            }
            if (objectFilters.competitors) {
                params.competitors = objectFilters.competitors.length ? JSON.stringify(objectFilters.competitors) : undefined;
            }
            if (objectFilters.queryId) {
                params.queryId = objectFilters.queryId ? objectFilters.queryId : undefined;
            }

            if (objectFilters.excludedWords) {
                params.excludedWords = objectFilters.excludedWords.join();
            }
            if (objectFilters.includedWords) {
                params.includedWords = objectFilters.includedWords.join();
            }
            if (objectFilters.tag) {
                params.tag = objectFilters.tag.join();
            }

            if (objectFilters.volume && objectFilters.volume.min) {
                params.minVolume = objectFilters.volume.min;
            }

            if (objectFilters.volume && objectFilters.volume.max) {
                params.maxVolume = objectFilters.volume.max;
            }

            if (objectFilters.ev && objectFilters.ev.min) {
                params.minEv = objectFilters.ev.min;
            }

            if (objectFilters.ev && objectFilters.ev.max) {
                params.maxEv = objectFilters.ev.max;
            }

            if (objectFilters.position && objectFilters.position.min) {
                params.minPosition = objectFilters.position.min;
            }

            if (objectFilters.position && objectFilters.position.max) {
                params.maxPosition = objectFilters.position.max;
            }

            $state.go('app.keywordtracker.overview', params, {
                notify: false
            });
            $stateParams = params;
            $window.localStorage.setItem('project-filters' + params.project, JSON.stringify(params));
            return params;
        }

        UserService.limits()
            .then(function (res) {
                if (res && res.data && res.data.limits && res.data.limits.length) {
                    angular.forEach(res.data.limits, function (service) {
                        if (service.alias === 'rank-tracker-keywords') {
                            $scope.keywordTrackerLimits = service;
                        }
                        if (service.alias === 'seo-project-limit') {
                            $scope.projectLimit = service;
                        }
                    });
                }
            })
            .catch(function (err) {
                $window.console.error(err);
                return null;
            });

        KeywordTrackerService.keywordCountTracker()
            .then(function (res) {
                if (!res || !res.data || !res.data.keywords) {
                    return;
                }

                $scope.keywordsRankTracker = res.data.keywords;
            })
            .catch(function (err) {
                $window.console.error(err);
            });

        $scope.updateAttempts = 0;
        $scope.updateInProgress = false;
        $scope.keywordsToUpdates = [];
        $scope.keywordsUpdateInProgress = [];

        function isInLoadingProject() {
            $scope.isRunningMonitoring = true;
            $timeout(function () {
                $scope.isRunningMonitoring = false;
            }, 240000);
        }

        $scope.updateSearches = function () {
            if ($scope.updateInProgress || $scope.updateAttempts >= 5) {
                return;
            }

            $scope.updateInProgress = true;
            $scope.updateAttempts += 1;
            $timeout(function () {
                $scope.keywordsUpdateInProgress = $scope.keywordsToUpdates;
                $scope.keywordsToUpdates = [];
                KeywordTrackerService
                    .getManyQueries($scope.project.id, $scope.keywordsUpdateInProgress, filters)
                    .then(function (res) {
                        $scope.keywordsUpdateInProgress = [];
                        $scope.updateInProgress = false;
                        $scope.updateAttempts = 0;

                        if (!res || !res.data || !res.data.keywords) {
                            return;
                        }

                        var hasUpdate = false;
                        var orderedKeywords = sortSearchesAndSetLoad(res.data.keywords);

                        for (var i = 0; i < orderedKeywords.length; i += 1) {
                            var rowFromApi = orderedKeywords[i];
                            for (var j = 0; j < $scope.keywords.rows.length; j += 1) {
                                var row = $scope.keywords.rows[j];
                                if (row.id === rowFromApi.id &&
                                    row.queryId === rowFromApi.queryId &&
                                    row.deviceId === rowFromApi.deviceId) {
                                    hasUpdate = true;
                                    $scope.keywords.rows[j] = rowFromApi;
                                }
                            }
                        }

                        if (hasUpdate) {
                            $scope.isRunningMonitoring = false;
                            $rootScope.$broadcast("updateKeywords", $scope.keywords.rows);
                            $scope.position.competitors = countOccurencesForPosition();
                        }
                    })
                    .catch(function (err) {
                        angular.forEach($scope.keywordsUpdateInProgress, function (queryId) {
                            $scope.keywordsToUpdates.push(queryId);
                        });
                        $scope.updateInProgress = false;
                        $scope.updateSearches();
                        $window.console.error(err);
                    });
            }, 15000);
        };

        $scope.$on('socketSearchResult', function (event, data) {
            if (!data || !data.queryIds || !(data.queryIds instanceof Array) || data.queryIds.length < 0 ||
                !$scope.project || !$scope.project.id || !$scope.keywords || !$scope.keywords.rows) {
                return;
            }

            angular.forEach(data.queryIds, function (queryId) {
                $scope.keywordsToUpdates.push(queryId);
            });

            $scope.updateSearches();
        });

        function openWelcomeToProjectDialogs() {
            $scope.tableInLoading = true;
            $scope.tablePercInLoading = true;
            var isOnboardingAllowed = $window.localStorage.getItem('onboarding-tracker-disabled');
            if (!isOnboardingAllowed || isOnboardingAllowed === 'false') {
                var parentEl = angular.element(document.body);
                $mdDialog.show({
                    parent: parentEl,
                    templateUrl: "app/modules/keywordtracker/dialogs/welcome/welcome.html",
                    controller: ['$scope', '$mdDialog', '$timeout', function ($scope, $mdDialog, $timeout) {
                        $scope.removeOnboarding = false;
                        $scope.$watch("removeOnboarding", function (isChecked) {
                            $window.localStorage.setItem('onboarding-tracker-disabled', isChecked);
                        });
                        $scope.onboardingLoaded = false;
                        $timeout(function () {
                            $scope.onboardingLoaded = true;
                        }, 3300);
                        this.closeDialog = function () {
                            $mdDialog.hide();
                        };
                    }],
                    controllerAs: 'OnboardingTrackerCtrl'
                }).then(function () {
                    $timeout(function () {
                        $scope.tableInLoading = false;
                        $scope.tablePercInLoading = false;
                        isInLoadingProject();
                    }, 8000);
                });
            } else {
                $timeout(function () {
                    $scope.tableInLoading = false;
                    $scope.tablePercInLoading = false;
                }, 8000);
            }
        }

        function deepClone(obj) {
            return JSON.parse(JSON.stringify(obj));
        }

        _constructor();
    }

    Controller.$inject = [
        '$rootScope',
        '$scope',
        '$timeout',
        '$translate',
        'ExportService',
        '$mdDialog',
        'KeywordTrackerService',
        'EstimatedVisitService',
        '$state',
        '$stateParams',
        '$interval',
        'SocketFactory',
        'stToggleExpandSectionService',
        'projects',
        'UserService',
        'LanguageService',
        '$window',
        '$filter'
    ];
    return Controller;
});

'use strict';
define(['app'], function (app) {
    var DIFFICULTY_SCORE_BEHAVIOR=[
        {
            label: "Easy",
            from: 0,
            until: 10,
            color: '#6CBF11',
        },
        {
            label: "Medium",
            from: 10,
            until: 30,
            color: '#FFBE00'
        },
        {
            label: "Hard",
            from: 30,
            until: 70,
            color: '#EE671A'
        },
        {
            label: 'Very Hard',
            from:  70,
            until: 100,
            color: '#DB3236'
        }
    ];
    app.component('stScoreRadar', {
        templateUrl: 'app/components/st-score-radar/st-score-radar.html',
        transclude: true,
        bindings: {
            score: '<',
            chartHeight:'<',
            label: '<',
            loading: '<',
            locked: '<',
            maxValue: '<',
        },

        controller: ['$scope', function ($scope) {
            var vm=$scope;
            var $this=this;
            $this.$onInit=function(){
                if($this.maxValue){
                    if($this.score>$this.maxValue){
                        $this.score=$this.maxValue;
                    }
                    $this.score=parseInt(($this.score*100)/$this.maxValue);
                }
            };
            var getDatasetByDifficultyScore = function (score) {
                var analyzedRange;
                var remainingToCheck=score;
                var chartData=[];
                var chartColors=[];
                var i;
                for(i=0;i<DIFFICULTY_SCORE_BEHAVIOR.length;i++){
                    analyzedRange=DIFFICULTY_SCORE_BEHAVIOR[i];
                    var totalRangeSize=analyzedRange.until-analyzedRange.from;
                    var isLessThanRangeSize=remainingToCheck-totalRangeSize>0;
                    if(isLessThanRangeSize){
                        /* fill the pie chart with color sized
                         with the current amount of range size
                          in exceeded */
                        remainingToCheck-=totalRangeSize;
                        chartData.push(totalRangeSize);
                        chartColors.push(analyzedRange.color);

                    }
                    else{
                        /* fill the pie chart with the remaining part
                        of current amount and fill the rest with the
                         no data color */
                        chartData.push(remainingToCheck);
                        chartColors.push(analyzedRange.color);
                        chartData.push(100-score);
                        chartColors.push("#C2D1D9");
                        break;
                    }
                }
                var finalLabel='';
                var isTheCorrectRange;
                for(i=0;i<DIFFICULTY_SCORE_BEHAVIOR.length;i++){
                    isTheCorrectRange=score>analyzedRange.from&&score<=analyzedRange.until;
                    if(isTheCorrectRange){
                        finalLabel=analyzedRange.label;
                    }
                }
                $scope.metricLabel=finalLabel;
                $scope.chartData=chartData;
                $scope.chartColors=chartColors;
            };
            $scope.chartOpts = {
                showTooltips: false,
                /*
                    circumference: 1.5 * Math.PI,
                    rotation: -1.25 * Math.PI,
                    circumference: 1.45*Math.PI,
                    rotation: 0.78*Math.PI,
                */
                circumference: 1.35*Math.PI,
                rotation: 0.83*Math.PI,
                cutoutPercentage: 60,
                legend: {
                    display: false
                },

                elements: {
                    arc: {
                        borderWidth: 1
                    }
                },
                tooltips: {
                    enabled: false
                },segmentShowStroke: false,
                hover: false
            };
            $scope.$watch('$ctrl.score', function (score) {
                getDatasetByDifficultyScore(score);
            });
            vm.checkDifficultyFormat=function(score){
                if(!score || score==null || score==undefined)
                    return 5;
                if(score<=5)
                    return 5;
                if(score>=100)
                    return 99;
                return score;
            };
        }]
    });
});

define(['angular','app'],function(angular,app) {
    app.filter('unique', [ function() {
        return function(collection) {
            var output = [];
            var keys = [];
            angular.forEach(collection, function(item) {
                var key = item;
                if(keys.indexOf(key) == -1) {
                    keys.push(key);
                    output.push(item);
                }
            });
            return output;
        };
    }]);
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/h2/h2.routes',
    'modules/seospider/exam/analysis/h2/h2.controller',
    'modules/seospider/exam/analysis/h2/all/all.controller',
    'modules/seospider/exam/analysis/h2/over/over.controller',
    'modules/seospider/exam/analysis/h2/duplicated/duplicated.controller',
    'modules/seospider/exam/analysis/h2/missing/missing.controller',
    'modules/seospider/exam/analysis/h2/h2table.component',
],function(
    angular,
    Routes,
    H2Ctrl,
    AllCtrl,
    OverCtrl,
    DupliCtrl,
    H2MissingCtrl,
    H2Table
){
    var app=angular.module('app.seospider.exam.analysis.h2',[]);
    app.config(Routes);
    app.controller("H2Ctrl",H2Ctrl);
    app.controller("H2AllCtrl",AllCtrl);
    app.controller("H2OverCtrl",OverCtrl);
    app.controller("H2DuplicatedCtrl",DupliCtrl);
    app.controller("H2MissingCtrl",H2MissingCtrl)
    app.component("h2Table",H2Table);
    return app;
});
'use strict';
define([
    'angular',
    'modules/searchconsole/searchconsole.routes',
    'modules/searchconsole/sites/sites.module'
], function (angular, routes) {
    var app = angular.module('app.searchconsole', [
        'app.searchconsole.sites',
    ]);
    app.config(routes);
    return app;
});

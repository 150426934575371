/**
 * @ngdoc overview
 * @name seospider.service:SeospiderService
 */
'use strict';
define(['app'], function (app) {
    app.service('SeospiderService', SeospiderService);
    SeospiderService.$inject = ['API', '$http', '$q', 'Cache', '__env', 'sessionHistoryCache', 'CacheManagerService'];

    function SeospiderService(API, $http, $q, Cache, __env, sessionHistoryCache, CacheManagerService) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.seoSpiderAPI;

        return {
            report: report,
            getIpAddress: getIpAddress,
            resourcesLimit: resourcesLimit,
            update: update,
            findJobs: findJobs,
            status: status,
            analysisStats: analysisStats,
            saveSettings: saveSettings,
            getSessions: getSessions,
            stopCrawl: stopCrawl,
            getSessionsUpdate: getSessionsUpdate,
            getStates:getStates
        };

        function getStates() {
            return {
                FINISHED: 'finished',
                RUNNING: 'running',
                STOPPING: 'deleting'
            };
        }
        function analysisStats(jobId) {
            return API.get(apiPath + '/' + jobId + '/detail', {
                cache: Cache
            }, true);
        }

        function resourcesLimit() {
            return API.get(apiPath + '/resources-limit', {}, true);
        }

        function report(params) {
            var deferred = $q.defer();
            API.get(apiPath + '/' + params.jobId + '/report?lang=' + (params.lang || 'en'), {}, true)
                .then(function (res) {
                    res = res && res.reportResult ? res.reportResult : res;
                    // CacheManagerService.set(hash, res, 60 * 60 * 24);
                    deferred.resolve(res);
                })
                .catch(function(err) {
                    deferred.reject(err);
                })

            return deferred.promise;
        }

        function findJobs(params) {
            Cache.removeAll();
            return API.get(apiPath, {
                params: params
            }, true);
        }

        function update(params) {
            var deferred = $q.defer();
            API.put(apiPath + '/' + params.id, params, true)
                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    if (err.data && err.data.error && err.data.error.message) {
                        deferred.reject(new Error(err.data.error.message));
                        return;
                    }

                    deferred.reject(err);
                });

            return deferred.promise;
        }

        function getSessions(projectId, sessionId) {
            return API.get(apiPath + '/' + projectId + '/sessions/' + (sessionId || ''), true, true);
        }

        function stopCrawl(projectId, sessionId) {
            if (!projectId || !sessionId) {
                throw new Error('Session not found');
            }
            return API.delete(apiPath + '/' + projectId + '/sessions/' + sessionId);
        }

        function getSessionsUpdate(projectId, sessionId = '', datetime) {
            return API.get(apiPath + '/' + projectId + '/sessions/' + sessionId + '/update?datetime=' + datetime, true, true);
        }

        function getIpAddress() {
            var deferred = $q.defer();
            var cacheId = 'get-ip-address';

            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached) {
                return $q.resolve(existCached);
            }

            API.get(apiPath + '/ip-addresses')
                .then(function (res) {
                    if (!res || !res.data || !res.data.ipAddresses) {
                        deferred.reject(new Error('ip addresses not found'));
                        return;
                    }

                    sessionHistoryCache.put(cacheId, res.data.ipAddresses);
                    deferred.resolve(res.data.ipAddresses);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        function status(seospiderId) {
            var deferred = $q.defer();
            $http.get(__env.apiGateway + '/api/v1/seo-spider/' + seospiderId + '')
                .then(function (res) {
                    deferred.resolve(res.data);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        function saveSettings(projectId, settings) {
            return API.post(apiPath + '/' + projectId + '/settings', settings, true, true);
        }

    }
});

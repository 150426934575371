'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/description/below/below.controller',
],function(
    angular,
    MDBelowCtrl
){
    var app=angular.module('app.seospider.exam.analysis.description.below', []);
    app.controller("MetaDescBelowCtrl",MDBelowCtrl);
    return app;
});
'use strict';
define(['app'], function (app) {
    app.component('stPagination', {
        templateUrl: 'app/components/st-pagination/st-pagination.html',
        controller: controller,
        transclude: true,
        bindings: {
            items: '<',
            pageSize:'=',
            currentPage:'='
        }
    });
    controller.$inject = ['__env', '$scope'];

    function controller(__env, $scope) {
        var DEFAULT_TABLE_VALUES = {
            GAP: 4,
            PAGE_SIZE: 5,
            STARTING_PAGE: 0,
        };
        var vm = $scope;
        vm.numberOfPages = numberOfPages;
        vm.setCurrentPage = setCurrentPage;
        vm.isTheLastPage = isTheLastPage;
        vm.range = range;
        vm.paginationRangeGenerator = paginationRangeGenerator;

        var ctrl = this;
        ctrl.items = [];

        vm.gap = DEFAULT_TABLE_VALUES.GAP;
        ctrl.pageSize = DEFAULT_TABLE_VALUES.PAGE_SIZE;
        ctrl.currentPage = DEFAULT_TABLE_VALUES.STARTING_PAGE;

        function range(size, start, end) {
            start = Math.min(start, numberOfPages(size) - vm.gap);
            if (start < 0) start = 0;
            end = Math.min(end, numberOfPages(size));
            var ret = [];
            if (size < end) {
                end = size;
                start = size - vm.gap;
            }
            for (var i = start; i < end; i++) {
                ret.push(i);
            }
            return ret;
        }

        function numberOfPages(resultsCount) {
            if (resultsCount){
                return Math.ceil(resultsCount / ctrl.pageSize);
            }
            return Math.ceil(ctrl.items.length / ctrl.pageSize);
        }

        function isTheLastPage(filteredCountRows) {
            var totalRows = ctrl.items.length;
            if (filteredCountRows) {
                totalRows = filteredCountRows;
            }
            return totalRows - (ctrl.currentPage * ctrl.pageSize) <= ctrl.pageSize;
        }

        function paginationRangeGenerator(size, start, end) {
            var ret = [];
            if (size < end) {
                end = size;
                start = size - $scope.gap;
            }
            for (var i = start; i < end; i++) {
                ret.push(i);
            }
            return ret;
        }

        function setCurrentPage(currentPage, filteredCountRows) {
            if (currentPage < 0) {
                currentPage = 0;
            }
            var totalRows = ctrl.items.length;
            if (filteredCountRows) {
                totalRows = filteredCountRows;
            }
            if (currentPage > Math.ceil(totalRows / ctrl.pageSize) - 1) {
                ctrl.currentPage = Math.min(currentPage, Math.ceil(totalRows / ctrl.pageSize) - 1);
                return;
            }
            $scope.$emit("current-page-competitors", currentPage);
            ctrl.currentPage = currentPage;
        }
    }

});

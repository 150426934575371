'use strict';
define([
    'angular',
    'modules/keywordlistmanager/keywordlist/keywordlist.controller'
], function (angular, KeywordListCtrl) {
    var app = angular.module('app.keywordlistmanager.keywordlist', []);
    app.controller('KeywordListCtrl', KeywordListCtrl);
    return app;
});


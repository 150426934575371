function config($stateProvider) {
    $stateProvider
        .state("app.writerassistant", {
            abstract: true,
            url: '/writer-assistant-ai',
            templateUrl: './app/modules/writerassistant/writerassistant.html',
            resolve: {
                notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                    return UserService.limits(true)
                        .then(function (res) {
                            var user = AuthService.getSessionUser();
                            if (res && res.data && !res.data.isActive && user && user.email) {
                                $state.go('access.email-verification', {
                                    token: btoa(user.email)
                                });
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }],
            }
        })
        .state("app.writerassistant.wizard", {
            url: "/brief?id&mode&mainKeyword&country&language&device",
            controller: 'WriterAssistantOptimizationWizardCtrl',
            templateUrl: './app/modules/writerassistant/wizard/wizard.html',
            resolve: {
                article: ['WriterAssistantService', '$stateParams', '$q', function (WriterAssistantService, $stateParams, $q) {
                    var deferred = $q.defer();

                    if (!$stateParams.id) {
                        return $q.resolve(null);
                    }

                    WriterAssistantService.getArticle($stateParams.id)
                        .then(function (res) {
                            deferred.resolve(res);
                        })
                        .catch(function (err) {
                            console.log(err);
                            deferred.resolve(null);
                        });

                    return deferred.promise;
                }],
            }
        })
        .state("app.writerassistant.landing", {
            url: "/",
            controller: 'WriterAssistantLandingCtrl',
            templateUrl: './app/modules/writerassistant/landing/landing.html',
        })
        .state("app.writerassistant.main", {
            url: "",
            controller: function ($state) {
                if ($state.current.name === 'app.writerassistant.main') {
                    $state.go('app.writerassistant.landing');
                }
            }
        })
        .state("app.writerassistant.editor", {
            url: "/editor/:id",
            templateUrl: './app/modules/writerassistant/editor/editor.html',
            controller: 'WriterAssistantCtrl',
            resolve: {
                userLangCode: ['$translate', function ($translate) {
                    return $translate('currentLangCode');
                }],
                userCountryCode: ['$translate', function ($translate) {
                    return $translate('languages.current.country');
                }],
                article: ['WriterAssistantService', '$stateParams', 'AuthService', '$mdDialog', '$state', '$q',
                    function (WriterAssistantService, $stateParams, AuthService, $mdDialog, $state, $q) {
                        var deferred = $q.defer();
                        if (!AuthService.isAuthenticated()) {
                            return $q.resolve(null);
                        }

                        WriterAssistantService.getArticle($stateParams.id)
                            .then(function (article) {
                                if (!article) {
                                    $state.go('app.writerassistant.landing');
                                    return;
                                }

                                if (
                                    !article.marketAnalysis ||
                                    !article.contentStrategy ||
                                    !article.headings
                                ) {
                                    $state.go('app.writerassistant.wizard', {
                                        id: article.id,
                                        mode: article.mode || 'WRITE_WITH_AI',
                                        mainKeyword: article.mainKeyword || null,
                                        country: article.country || 'IT',
                                        language: article.language || 'it',
                                        device: article.device || 'mobile',
                                    });
                                    return;
                                }

                                deferred.resolve(article);
                            })
                            .catch(function (err) {
                                console.log('Article error', err);

                                $mdDialog.show({
                                    controller: 'WriterAssistantErrorArticleCtrl',
                                    templateUrl: "app/modules/writerassistant/editor/dialog/error/error.html",
                                    clickOutsideToClose: true,
                                    locals: {
                                        errorCode: err
                                    }
                                })
                                    .then(function () {
                                        deferred.resolve(null);
                                    })
                                    .catch(function (err) {
                                        console.log('Dialog error', err);
                                        deferred.resolve(null);
                                    });
                            });

                        return deferred.promise;
                    }]
            }
        });
}

config.$inject = ['$stateProvider'];
export default config;

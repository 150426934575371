'use strict';
define([], function () {
    function controller(
        $scope,
        $translate,
        $mdToast,
        ProjectsService,
        FavIconService,
        $mdDialog,
        $debounce,
        $stateParams,
        TrackEventService,
        $rootScope,
    ) {
        var ctrl = this;

        $scope.showCustomToast = showCustomToast;
        $scope.showDropAlert = showDropAlert;

        $scope.getFavIcon = FavIconService.get;
        $scope.competitor = {
            url: '',
            domainType: 'domain_with_subdomains'
        };

        $scope.currentPage = 0;
        $scope.pageSize = 5;

        $scope.domainTypes = [{
            id: 'domain_with_subdomains',
            icon: 'subdomains.svg',
            label: 'project.wizard.analyze.all',
            detail: 'project.wizard.domain.including.subdomains',
            example: 'project.wizard.subdomains.example'
        },
        {
            id: 'domain_without_subdomains',
            icon: 'domain.svg',
            label: 'project.wizard.domain',
            detail: 'project.wizard.only.specified.domain',
            example: 'project.wizard.domain.example'
        },
        {
            id: 'prefix',
            icon: 'path.svg',
            label: 'project.wizard.path',
            detail: 'project.wizard.path.including.subfolders',
            example: 'project.wizard.path.example'
        },
        {
            id: 'exact_url',
            icon: 'exact-url.svg',
            label: 'project.wizard.exact.url',
            detail: 'project.wizard.only.specified.url',
            example: 'project.wizard.url.example'
        },
    ];

        $scope.emptyConfiguration = '<i class="icon-blackhole custom-message-template-icon"></i>' +
            '<div class="md-subhead m-b p-l p-r">' +
            $translate.instant('project.wizard.no.configuration') +
            '</div>';

        $scope.$on('current-page-competitors', function (evt, currentPage) {
            $scope.currentPage = currentPage
        })

        $scope.competitorController = {
            getFavIcon: FavIconService.get,
            removeCompetitor: function (index, ev) {
                if ($scope.wizardMode) {
                    $scope.competitors.splice(index, 1);
                    updateCompetitorsSuggested($scope.suggestedCompetitors);
                } else {
                    var competitorIdToRemove = $scope.competitors[index].id;
                    updateCompetitorsSuggested($scope.suggestedCompetitors);
                    showDropAlert($scope.projectId, competitorIdToRemove, index, ev);
                }
            },
            getLabelForTypeDomain: function (id) {
                var label;
                angular.forEach($scope.domainTypes, function (type) {
                    if (type.id === id) {
                        label = type.label;
                    }
                });
                return label;
            }
        };

        $scope.competitorIsInList = function (competitor) {
            var isInList = false;
            $scope.competitors.forEach(function (row) {
                if (row.url === competitor.url) {
                    isInList = true;
                }
            });
            return isInList;
        };

        $scope.addCompetitor = function (competitor) {
            $scope.competitorToAdd = competitor;
            $debounce(addCompetitorAction, $scope.competitors.length >= 5 ? 500 : 0);
        }

        function addCompetitorAction() {
            if (!$scope.competitorToAdd || !$scope.competitorToAdd.url || !$scope.competitorToAdd.domainType) {
                return;
            }

            var isPresent = false;
            angular.forEach($scope.competitors, function (comp) {
                if (comp.url === $scope.competitorToAdd.url && comp.domainType === $scope.competitorToAdd.domainType) {
                    isPresent = true;
                }
            });

            if (isPresent) {
                showCustomToast('project.wizard.competitor.already.present', 'icon-x st-red');
                return;
            }
            if ($scope.competitors.length === 5) {
                showCustomToast('project.wizard.max.competitor.update', 'icon-x st-red');
                return;
            }

            // in wizard mode we need to update only the frontend because the POST request will be execute
            // only at the end of the wizard, in stand-alone mode everytime we add a new competitor
            // we also need to make a request for db updating
            if ($scope.wizardMode) {
                $scope.competitors.push($scope.competitorToAdd);
                updateCompetitorsSuggested($scope.suggestedCompetitors);
            } else {
                ProjectsService
                    .addCompetitor($scope.projectId, $scope.competitorToAdd)
                    .then(function (res) {
                        $scope.competitorToAdd.id = res.data.competitorId;
                        $scope.competitors.push($scope.competitorToAdd);
                        showCustomToast('project.wizard.competitor.saved', 'icon-checkmark st-green');
                    })
                    .catch(function (err) {
                        console.log(err);
                        showCustomToast('project.wizard.something.went.wrong', 'icon-x st-red');
                    });
            }
        }

        $scope.removeSuggestedCompetitor = function (index) {
            var suggestedCompetitorToRemove = $scope.suggestedCompetitors[index + ($scope.pageSize*$scope.currentPage)];

            for (var i = $scope.competitors.length - 1; i >= 0; --i) {
                if ($scope.competitors[i].url === suggestedCompetitorToRemove.url
                    && $scope.competitors[i].domainType === suggestedCompetitorToRemove.domainType) {
                    $scope.competitors.splice(i, 1);
                    break;
                }
            }

            updateCompetitorsSuggested($scope.suggestedCompetitors);
        }

        function updateCompetitorsSuggested(suggestedCompetitors) {
            $scope.suggestedCompetitors = suggestedCompetitors;
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast max-w">' +
                    '   <div class="p-t p-b"><i class="' + classIcon + ' m-r-sm"></i>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: angular.element('.toast-show')
            });
        }

        function showDropAlert(projectId, competitorId, index, ev) {
            $mdDialog.show({
                controller: 'ProjectDeleteCompetitorCtrl',
                templateUrl: "app/modules/project/dialogs/delete-competitor/delete-competitor.html",
                targetEvent: ev,
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    projectId: projectId,
                    competitorId: competitorId,
                }
            }).then(function () {
                $scope.competitors.splice(index, 1);
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        $scope.$watchCollection('$ctrl.suggestedCompetitors', function (suggestedCompetitors) {
            $scope.suggestedCompetitors = suggestedCompetitors;
        });

        ctrl.$onInit = function () {
            $scope.suggestedCompetitors = ctrl.suggestedCompetitors;
            $scope.projectId = ctrl.projectId;
            $scope.wizardMode = ctrl.wizardMode;
            $scope.competitors = ctrl.competitors || [];
            $scope.competitorListWidth = $scope.wizardMode ? 80 : 65;

            // TRACKING
            var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';
            var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
            if ($scope.wizardMode) {
                TrackEventService.event(
                    'Suite Usages',
                    'SEO Project - Step - Choose Competitors', eventLabel);
                TrackEventService.gaV2Event(
                    'project_wizard', {
                        step: 'Choose Competitors',
                        from: $rootScope.stoFrom,
                        type: stoTool
                    });
            }
            //


            if (!$scope.wizardMode) {
                ProjectsService.getCompetitors($scope.projectId)
                    .then(function (res) {
                        if (!res || !res.data || !res.data.competitors) {
                            return;
                        }

                        $scope.competitors = [];
                        angular.forEach(res.data.competitors, function (competitor) {
                            $scope.competitors.push({
                                id: competitor.id,
                                url: competitor.linkUrl,
                                name: competitor.name,
                                domainType: competitor.domainType
                            });
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        };
    }

    controller.$inject = [
        '$scope',
        '$translate',
        '$mdToast',
        'ProjectsService',
        'FavIconService',
        '$mdDialog',
        '$debounce',
        '$stateParams',
        'TrackEventService',
        '$rootScope',
    ];

    return {
        templateUrl: 'app/modules/project/wizard/steps/choose-competitors/choose-competitors.html',
        bindings: {
            projectId: '<',
            competitors: '<',
            wizardMode: '<',
            suggestedCompetitors: '<',
        },
        controller: controller,
    }
});

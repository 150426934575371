define([], function(){
    function config($stateProvider) {
        $stateProvider
        .state("app.seospider.exam.analysis.https",{
            controller:'SSHttpsCtrl',
            abstract:true,
            templateUrl: './app/modules/seospider/exam/analysis/https/https.html',
            access: {restricted: true}
        })
        .state("app.seospider.exam.analysis.https.all",{
            url:"/https",
            templateUrl: './app/modules/seospider/exam/analysis/https/all/all.html',
            access: {restricted: true},
            controller:'SSHttpsAllCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.https.secure",{
            url:"/https/secure",
            templateUrl: './app/modules/seospider/exam/analysis/https/all/all.html',
            access: {restricted: true},
            controller:'SSHttpsSecureCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        https:true,
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.https.insecure",{
            url:"/https/insecure",
            templateUrl: './app/modules/seospider/exam/analysis/https/all/all.html',
            access: {restricted: true},
            controller:'SSHttpsInsecureCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        jobId:$stateParams.sessionId,
                        https:false,
                        fields:['id','url','title'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
    };
    config.$inject=['$stateProvider'];
    return config;
});

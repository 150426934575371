'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/http-status/200/200.controller',
],function(
    angular,
    SPHttpStatus200Ctrl
){
    var app=angular.module('app.seospider.exam.analysis.httpstatus.200', []);
    app.controller("SPHttpStatus200Ctrl",SPHttpStatus200Ctrl);
    return app;
});
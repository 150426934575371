'use strict';
define([], function () {
    function SigninZendeskController($scope, AuthService, $window) {
        AuthService.signinZendesk()
            .then(function (res) {
                $window.location.href = res.redirectUrl;
            }).catch(function (err) {
                console.log(err);
            });

    }
    SigninZendeskController.$inject = ['$scope', 'AuthService', '$window'];
    return SigninZendeskController;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/title/title.routes',
    'modules/seospider/exam/analysis/title/title.controller',
    'modules/seospider/exam/analysis/title/table.component',
    'modules/seospider/exam/analysis/title/all/all.module',
    'modules/seospider/exam/analysis/title/over/over.module',
    'modules/seospider/exam/analysis/title/missing/missing.module',
    'modules/seospider/exam/analysis/title/duplicated/duplicated.module',
],function(
    angular,
    Routes,
    TitleCtrl,
    titleTable
){
    var app=angular.module('app.seospider.exam.analysis.title', [
        'app.seospider.exam.analysis.title.all',
        'app.seospider.exam.analysis.title.over',
        'app.seospider.exam.analysis.title.missing',
        'app.seospider.exam.analysis.title.duplicated'
    ]);
    app.config(Routes);
    app.component("titleTable",titleTable);
    app.controller("TitleCtrl",TitleCtrl);
    return app;
});
'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/analysis.controller',
    'modules/seospider/exam/analysis/analysis.routes',
    'modules/seospider/exam/analysis/components/components.module',
    'modules/seospider/exam/analysis/report/report.module',
    'modules/seospider/exam/analysis/detail/detail.module',
    'modules/seospider/exam/analysis/taxonomies/taxonomies.module',
    'modules/seospider/exam/analysis/page/page.module',
    'modules/seospider/exam/analysis/title/title.module',
    'modules/seospider/exam/analysis/h1/h1.module',
    'modules/seospider/exam/analysis/h2/h2.module',
    'modules/seospider/exam/analysis/images/images.module',
    'modules/seospider/exam/analysis/content/content.module',
    'modules/seospider/exam/analysis/description/description.module',
    'modules/seospider/exam/analysis/http-status/http-status.module',
    'modules/seospider/exam/analysis/https/https.module',
    'modules/seospider/exam/analysis/snippets/snippets.module',
    'modules/seospider/exam/analysis/robots/robots.module',
    'modules/seospider/exam/analysis/opengraph/opengraph.module',
    'modules/seospider/exam/analysis/twittercard/twittercard.module',
    'modules/seospider/exam/analysis/canonical/canonical.module',
    'modules/seospider/exam/analysis/languages/languages.module'
], function (ng, SeospiderAnalysisCtrl, Routes) {
    var app = ng.module('app.seospider.exam.analysis', [
        'app.seospider.exam.analysis.components',
        'app.seospider.exam.analysis.report',
        'app.seospider.exam.analysis.taxonomies',
        'app.seospider.exam.analysis.snippets',
        'app.seospider.exam.analysis.robots',
        'app.seospider.exam.analysis.languages',
        'app.seospider.exam.analysis.page',
        'app.seospider.exam.analysis.title',
        'app.seospider.exam.analysis.description',
        'app.seospider.exam.analysis.h1',
        'app.seospider.exam.analysis.h2',
        'app.seospider.exam.analysis.images',
        'app.seospider.exam.analysis.content',
        'app.seospider.exam.analysis.opengraph',
        'app.seospider.exam.analysis.detail',
        'app.seospider.exam.analysis.httpstatus',
        'app.seospider.exam.analysis.https',
        'app.seospider.exam.analysis.canonical',
        'app.seospider.exam.analysis.twittercard'
    ]);
    app.controller('SeospiderAnalysisCtrl', SeospiderAnalysisCtrl);
    app.config(Routes);
    return app;
});

'use strict';
define([], function() {
    function Controller($scope, $mdDialog, projectId, competitorId, ProjectsService) {
        $scope.projectId = projectId;
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;
        
        function answerDialog() {
            ProjectsService.deleteCompetitor(projectId, competitorId)
                .then(function() {
                    hideDialog();
                })
                .catch(function(err) {
                    console.log(err);
                });
        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject=['$scope', '$mdDialog', 'projectId', 'competitorId', 'ProjectsService'];
    return Controller;
});
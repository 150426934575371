define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.welcome", {
                url: "/",
                controller: "DashboardCtrl",
                templateUrl: './app/modules/dashboard/dashboard.html',
                resolve: {
                    userInfo: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits()
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                    return null;
                                }

                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                },
            })
            .state('app.not-verified', {
                url: "/not-verified",
                controller: "NotVerifiedCtrl",
                templateUrl: './app/modules/dashboard/not-verified/not-verified.html',
                access: {restricted: false},
                resolve: {
                    notVerifiedRedirect: ['UserService', '$state', function (UserService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                if (res && res.data && res.data.isActive) {
                                    $state.go('app.welcome');
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

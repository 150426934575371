'use strict';
define(['app'], function (app) {
    app.service('CountriesService', CountriesService);
    CountriesService.$inject = [];

    function CountriesService() {
        var self = this;

        self.countryLanguages = {
            "DK": ["da"],
            "DE": ["de"],
            "AT": ["de"],
            "CH": ["de", "fr"],
            "CA": ["en", "fr"],
            "US": ["en", 'es'],
            "AU": ["en"],
            "GB": ["en"],
            "ID": ["en"],
            "IN": ["en"],
            "MY": ["en"],
            "NZ": ["en"],
            "PH": ["en"],
            "ZA": ["en"],
            "ES": ["es"],
            "AR": ["es"],
            "CL": ["es"],
            "MX": ["es"],
            "FI": ["fi"],
            "FR": ["fr"],
            "IT": ["it"],
            "JP": ["ja"],
            "KR": ["ko"],
            "NL": ["nl"],
            "BE": ["nl", "fr"],
            "NO": ["no"],
            "BR": ["pt"],
            "PL": ["pl"],
            "RU": ["ru"],
            "SE": ["sv"],
            "TR": ["tr"],
            "TW": ["zh"],
            "CN": ["zh"],
            "HK": ["zh"]
        };

        self.euCountries = {
            'AT': {country: 'Austria', code: 'AT', vat: 20},
            'BE': {country: 'Belgium', code: 'BE', vat: 21},
            'BG': {country: 'Bulgaria', code: 'BG', vat: 20},
            'HR': {country: 'Croatia', code: 'HR', vat: 25},
            'CY': {country: 'Cyprus', code: 'CY', vat: 19},
            'CZ': {country: 'Czech Republic', code: 'CZ', vat: 21},
            'DK': {country: 'Denmark', code: 'DK', vat: 25},
            'EE': {country: 'Estonia', code: 'EE', vat: 20},
            'FI': {country: 'Finland', code: 'FI', vat: 24},
            'FR': {country: 'France', code: 'FR', vat: 20},
            'DE': {country: 'Germany', code: 'DE', vat: 19},
            'EL': {country: 'Greece', code: 'EL', vat: 24},
            'HU': {country: 'Hungary', code: 'HU', vat: 27},
            'IE': {country: 'Ireland', code: 'IE', vat: 23},
            'IT': {country: 'Italy', code: 'IT', vat: 22},
            'LV': {country: 'Latvia', code: 'LV', vat: 21},
            'LT': {country: 'Lithuania', code: 'LT', vat: 21},
            'LU': {country: 'Luxembourg', code: 'LU', vat: 17},
            'MT': {country: 'Malta', code: 'MT', vat: 18},
            'NL': {country: 'Netherlands', code: 'NL', vat: 21},
            'PL': {country: 'Poland', code: 'PL', vat: 23},
            'PT': {country: 'Portugal', code: 'PT', vat: 23},
            'RO': {country: 'Romania', code: 'RO', vat: 20},
            'SK': {country: 'Slovakia', code: 'SK', vat: 20},
            'SI': {country: 'Slovenia', code: 'SI', vat: 22},
            'ES': {country: 'Spain', code: 'ES', vat: 21},
            'SE': {country: 'Sweden', code: 'SE', vat: 25},
        };

        self.countriesDb = [
            {
                "googleTldCode": "AR",
                "googleTld": "google.com.ar",
                "name": "Argentina",
                "code": "AR",
                "dial_code": "+54"
            }, {
                "googleTldCode": "AU",
                "googleTld": "google.com.au",
                "name": "Australia",
                "code": "AU",
                "dial_code": "+61"
            }, {
                "googleTldCode": "AT",
                "googleTld": "google.at",
                "name": "Austria",
                "code": "AT",
                "dial_code": "+43"
            }, {
                "googleTldCode": "BE",
                "googleTld": "google.be",
                "name": "Belgium",
                "code": "BE",
                "dial_code": "+32"
            }, {
                "googleTldCode": "BR",
                "googleTld": "google.com.br",
                "name": "Brazil",
                "code": "BR",
                "dial_code": "+55"
            }, {
                "googleTldCode": "CA",
                "googleTld": "google.ca",
                "name": "Canada",
                "code": "CA",
                "dial_code": "+1"
            }, {
                "googleTldCode": "CZ",
                "googleTld": "google.cz",
                "name": "Czech Republic",
                "code": "CZ",
                "dial_code": "+420"
            }, {
                "googleTldCode": "DK",
                "googleTld": "google.dk",
                "name": "Denmark",
                "code": "DK",
                "dial_code": "+45"
            }, {
                "googleTldCode": "FI",
                "googleTld": "google.fi",
                "name": "Finland",
                "code": "FI",
                "dial_code": "+358"
            }, {
                "googleTldCode": "FR",
                "googleTld": "google.fr",
                "name": "France",
                "code": "FR",
                "dial_code": "+33"
            }, {
                "googleTldCode": "DE",
                "googleTld": "google.de",
                "name": "Germany",
                "code": "DE",
                "dial_code": "+49"
            }, {
                "googleTldCode": "HK",
                "googleTld": "google.com.hk",
                "name": "Hong Kong",
                "code": "HK",
                "dial_code": "+852"
            }, {
                "googleTldCode": "HU",
                "googleTld": "google.hu",
                "name": "Hungary",
                "code": "HU",
                "dial_code": "+36"
            }, {
                "googleTldCode": "IN",
                "googleTld": "google.co.in",
                "name": "India",
                "code": "IN",
                "dial_code": "+91"
            }, {
                "googleTldCode": "ID",
                "googleTld": "google.co.id",
                "name": "Indonesia",
                "code": "ID",
                "dial_code": "+62"
            }, {
                "googleTldCode": "IE",
                "googleTld": "google.ie",
                "name": "Ireland",
                "code": "IE",
                "dial_code": "+353"
            }, {
                "googleTldCode": "IL",
                "googleTld": "google.co.il",
                "name": "Israel",
                "code": "IL",
                "dial_code": "+972"
            }, {
                "googleTldCode": "IT",
                "googleTld": "google.it",
                "name": "Italy",
                "code": "IT",
                "dial_code": "+39"
            }, {
                "googleTldCode": "JP",
                "googleTld": "google.co.jp",
                "name": "Japan",
                "code": "JP",
                "dial_code": "+81"
            }, {
                "googleTldCode": "MX",
                "googleTld": "google.com.mx",
                "name": "Mexico",
                "code": "MX",
                "dial_code": "+52"
            }, {
                "googleTldCode": "NL",
                "googleTld": "google.nl",
                "name": "Netherlands",
                "code": "NL",
                "dial_code": "+31"
            }, {
                "googleTldCode": "NZ",
                "googleTld": "google.co.nz",
                "name": "New Zealand",
                "code": "NZ",
                "dial_code": "+64"
            }, {
                "googleTldCode": "NO",
                "googleTld": "google.no",
                "name": "Norway",
                "code": "NO",
                "dial_code": "+47"
            }, {
                "googleTldCode": "PL",
                "googleTld": "google.pl",
                "name": "Poland",
                "code": "PL",
                "dial_code": "+48"
            },
            {
                "googleTldCode": "PT",
                "googleTld": "google.pt",
                "name": "Portugal",
                "code": "PT",
                "dial_code": "+351",
            },
            {
                "googleTldCode": "RU",
                "googleTld": "google.ru",
                "name": "Russian Federation",
                "code": "RU",
                "dial_code": "+7"
            }, {
                "googleTldCode": "SG",
                "googleTld": "google.com.sg",
                "name": "Singapore",
                "code": "SG",
                "dial_code": "+65"
            }, {
                "googleTldCode": "SK",
                "googleTld": "google.sk",
                "name": "Slovakia",
                "code": "SK",
                "dial_code": "+421"
            }, {
                "googleTldCode": "ES",
                "googleTld": "google.es",
                "name": "Spain",
                "code": "ES",
                "dial_code": "+34"
            }, {
                "googleTldCode": "SE",
                "googleTld": "google.se",
                "name": "Sweden",
                "code": "SE",
                "dial_code": "+46"
            }, {
                "googleTldCode": "CH",
                "googleTld": "google.ch",
                "name": "Switzerland",
                "code": "CH",
                "dial_code": "+41"
            }, {
                "googleTldCode": "TH",
                "googleTld": "google.co.th",
                "name": "Thailand",
                "code": "TH",
                "dial_code": "+66"
            }, {
                "googleTldCode": "TR",
                "googleTld": "google.com.tr",
                "name": "Turkey",
                "code": "TR",
                "dial_code": "+90"
            }, {
                "googleTldCode": "UA",
                "googleTld": "google.com.ua",
                "name": "Ukraine",
                "code": "UA",
                "dial_code": "+380"
            }, {
                "googleTldCode": "UK",
                "googleTld": "google.co.uk",
                "code": "GB",
                "name": "United Kingdom",
                "dial_code": "+44"
            }, {
                "googleTldCode": "US",
                "googleTld": "google.com",
                "name": "United States",
                "code": "US",
                "dial_code": "+1"
            }, {
                "googleTldCode": "VN",
                "googleTld": "google.com.vn",
                "name": "Viet Nam",
                "code": "VN",
                "dial_code": "+84"
            },
        ];

        self.countries = [
            {
                "googleTldCode": "AF",
                "googleTld": "google.com.af",
                "name": "Afghanistan",
                "code": "AF",
                "dial_code": "+93",
            }, {
                "googleTldCode": "AL",
                "googleTld": "google.al",
                "name": "Albania",
                "code": "AL",
                "dial_code": "+355",
            }, {
                "googleTldCode": "DZ",
                "googleTld": "google.dz",
                "name": "Algeria",
                "code": "DZ",
                "dial_code": "+213",
            }, {
                "googleTldCode": "AO",
                "googleTld": "google.co.ao",
                "name": "Angola",
                "code": "AO",
                "dial_code": "+244",
            }, {
                "googleTldCode": "AR",
                "googleTld": "google.com.ar",
                "name": "Argentina",
                "code": "AR",
                "dial_code": "+54",
            }, {
                "googleTldCode": "AM",
                "googleTld": "google.am",
                "name": "Armenia",
                "code": "AM",
                "dial_code": "+374",
            }, {
                "googleTldCode": "AU",
                "googleTld": "google.com.au",
                "name": "Australia",
                "code": "AU",
                "dial_code": "+61",
            }, {
                "googleTldCode": "AT",
                "googleTld": "google.at",
                "name": "Austria",
                "code": "AT",
                "dial_code": "+43",
            }, {
                "googleTldCode": "AZ",
                "googleTld": "google.az",
                "name": "Azerbaijan",
                "code": "AZ",
                "dial_code": "+994",
            }, {
                "googleTldCode": "BS",
                "googleTld": "google.bs",
                "name": "Bahamas",
                "code": "BS",
                "dial_code": "+1242",
            }, {
                "googleTldCode": "BH",
                "googleTld": "google.com.bh",
                "name": "Bahrain",
                "code": "BH",
                "dial_code": "+973",
            }, {
                "googleTldCode": "BD",
                "googleTld": "google.com.bd",
                "name": "Bangladesh",
                "code": "BD",
                "dial_code": "+880",
            }, {
                "googleTldCode": "BY",
                "googleTld": "google.by",
                "name": "Belarus",
                "code": "BY",
                "dial_code": "+375",
            }, {
                "googleTldCode": "BE",
                "googleTld": "google.be",
                "name": "Belgium",
                "code": "BE",
                "dial_code": "+32",
            }, {
                "googleTldCode": "BZ",
                "googleTld": "google.com.bz",
                "name": "Belize",
                "code": "BZ",
                "dial_code": "+501",
            }, {
                "googleTldCode": "BO",
                "googleTld": "google.com.bo",
                "name": "Bolivia",
                "code": "BO",
                "dial_code": "+591",
            }, {
                "googleTldCode": "BA",
                "googleTld": "google.ba",
                "name": "Bosnia and Herzegovina",
                "code": "BA",
                "dial_code": "+387",
            }, {
                "googleTldCode": "BW",
                "googleTld": "google.co.bw",
                "name": "Botswana",
                "code": "BW"
            }, {
                "googleTldCode": "BR",
                "googleTld": "google.com.br",
                "name": "Brazil",
                "code": "BR",
                "dial_code": "+55",
            }, {
                "googleTldCode": "BN",
                "googleTld": "google.com.bn",
                "name": "Brunei Darussalam",
                "code": "BN",
                "dial_code": "+673",
            }, {
                "googleTldCode": "BG",
                "googleTld": "google.bg",
                "name": "Bulgaria",
                "code": "BG",
                "dial_code": "+359",
            }, {
                "googleTldCode": "KH",
                "googleTld": "google.com.kh",
                "name": "Cambodia",
                "code": "KH",
                "dial_code": "+855",
            }, {
                "googleTldCode": "CM",
                "googleTld": "google.cm",
                "name": "Cameroon",
                "code": "CM",
                "dial_code": "+237",
            }, {
                "googleTldCode": "CA",
                "googleTld": "google.ca",
                "name": "Canada",
                "code": "CA",
                "dial_code": "+1",
            }, {
                "googleTldCode": "CV",
                "googleTld": "google.cv",
                "name": "Cape Verde",
                "code": "CV",
                "dial_code": "+238",
            }, {
                "googleTldCode": "CL",
                "googleTld": "google.cl",
                "name": "Chile",
                "code": "CL",
                "dial_code": "+56",
            }, {
                "googleTldCode": "CO",
                "googleTld": "google.com.co",
                "name": "Colombia",
                "code": "CO",
                "dial_code": "+57",
            }, {
                "googleTldCode": "CD",
                "googleTld": "google.cd",
                "name": "Congo, The Democratic Republic of the",
                "code": "CD",
                "dial_code": "+243",
            }, {
                "googleTldCode": "CR",
                "googleTld": "google.co.cr",
                "name": "Costa Rica",
                "code": "CR",
                "dial_code": "+506",
            }, {
                "googleTldCode": "HR",
                "googleTld": "google.hr",
                "name": "Croatia",
                "code": "HR",
                "dial_code": "+385",
            }, {
                "googleTldCode": "CY",
                "googleTld": "google.com.cy",
                "name": "Cyprus",
                "code": "CY",
                "dial_code": "+357",
            }, {
                "googleTldCode": "CZ",
                "googleTld": "google.cz",
                "name": "Czech Republic",
                "code": "CZ",
                "dial_code": "+420",
            }, {
                "googleTldCode": "DK",
                "googleTld": "google.dk",
                "name": "Denmark",
                "code": "DK",
                "dial_code": "+45",
            }, {
                "googleTldCode": "DO",
                "googleTld": "google.com.do",
                "name": "Dominican Republic",
                "code": "DO",
                "dial_code": "+1849",
            }, {
                "googleTldCode": "EC",
                "googleTld": "google.com.ec",
                "name": "Ecuador",
                "code": "EC",
                "dial_code": "+593",
            }, {
                "googleTldCode": "EG",
                "googleTld": "google.com.eg",
                "name": "Egypt",
                "code": "EG",
                "dial_code": "+20",
            }, {
                "googleTldCode": "SV",
                "googleTld": "google.com.sv",
                "name": "El Salvador",
                "code": "SV",
                "dial_code": "+503",
            }, {
                "googleTldCode": "EE",
                "googleTld": "google.ee",
                "name": "Estonia",
                "code": "EE",
                "dial_code": "+372",
            }, {
                "googleTldCode": "ET",
                "googleTld": "google.com.et",
                "name": "Ethiopia",
                "code": "ET",
                "dial_code": "+251",
            }, {
                "googleTldCode": "FI",
                "googleTld": "google.fi",
                "name": "Finland",
                "code": "FI",
                "dial_code": "+358",
            }, {
                "googleTldCode": "FR",
                "googleTld": "google.fr",
                "name": "France",
                "code": "FR",
                "dial_code": "+33",
            }, {
                "googleTldCode": "GE",
                "googleTld": "google.ge",
                "name": "Georgia",
                "code": "GE",
                "dial_code": "+995",
            }, {
                "googleTldCode": "DE",
                "googleTld": "google.de",
                "name": "Germany",
                "code": "DE",
                "dial_code": "+49",
            }, {
                "googleTldCode": "GH",
                "googleTld": "google.com.gh",
                "name": "Ghana",
                "code": "GH",
                "dial_code": "+233",
            }, {
                "googleTldCode": "GR",
                "googleTld": "google.gr",
                "name": "Greece",
                "code": "GR",
                "dial_code": "+30",
            }, {
                "googleTldCode": "GT",
                "googleTld": "google.com.gt",
                "name": "Guatemala",
                "code": "GT",
                "dial_code": "+502",
            }, {
                "googleTldCode": "GY",
                "googleTld": "google.gy",
                "name": "Guyana",
                "code": "GY",
                "dial_code": "+595",
            }, {
                "googleTldCode": "HT",
                "googleTld": "google.ht",
                "name": "Haiti",
                "code": "HT",
                "dial_code": "+509",
            }, {
                "googleTldCode": "HN",
                "googleTld": "google.hn",
                "name": "Honduras",
                "code": "HN",
                "dial_code": "+504",
            }, {
                "googleTldCode": "HK",
                "googleTld": "google.com.hk",
                "name": "Hong Kong",
                "code": "HK",
                "dial_code": "+852",
            }, {
                "googleTldCode": "HU",
                "googleTld": "google.hu",
                "name": "Hungary",
                "code": "HU",
                "dial_code": "+36",
            }, {
                "googleTldCode": "IS",
                "googleTld": "google.is",
                "name": "Iceland",
                "code": "IS",
                "dial_code": "+354",
            }, {
                "googleTldCode": "IN",
                "googleTld": "google.co.in",
                "name": "India",
                "code": "IN",
                "dial_code": "+91",
            }, {
                "googleTldCode": "ID",
                "googleTld": "google.co.id",
                "name": "Indonesia",
                "code": "ID",
                "dial_code": "+62",
            }, {
                "googleTldCode": "IE",
                "googleTld": "google.ie",
                "name": "Ireland",
                "code": "IE",
                "dial_code": "+353",
            }, {
                "googleTldCode": "IL",
                "googleTld": "google.co.il",
                "name": "Israel",
                "code": "IL",
                "dial_code": "+972",
            }, {
                "googleTldCode": "IT",
                "googleTld": "google.it",
                "name": "Italy",
                "code": "IT",
                "dial_code": "+39",
            }, {
                "googleTldCode": "JM",
                "googleTld": "google.com.jm",
                "name": "Jamaica",
                "code": "JM",
                "dial_code": "+1876",
            }, {
                "googleTldCode": "JP",
                "googleTld": "google.co.jp",
                "name": "Japan",
                "code": "JP",
                "dial_code": "+81",
            }, {
                "googleTldCode": "JO",
                "googleTld": "google.jo",
                "name": "Jordan",
                "code": "JO",
                "dial_code": "+962",
            }, {
                "googleTldCode": "KZ",
                "googleTld": "google.kz",
                "name": "Kazakhstan",
                "code": "KZ",
                "dial_code": "+77",
            }, {
                "googleTldCode": "KR",
                "googleTld": "google.co.kr",
                "name": "Korea, Republic of",
                "code": "KR",
                "dial_code": "+82",
            }, {
                "googleTldCode": "KW",
                "googleTld": "google.com.kw",
                "name": "Kuwait",
                "code": "KW",
                "dial_code": "+965",
            }, {
                "googleTldCode": "LV",
                "googleTld": "google.lv",
                "name": "Latvia",
                "code": "LV",
                "dial_code": "+371",
            }, {
                "googleTldCode": "LB",
                "googleTld": "google.com.lb",
                "name": "Lebanon",
                "code": "LB",
                "dial_code": "+961",
            }, {
                "googleTldCode": "LY",
                "googleTld": "google.com.ly",
                "name": "Libyan Arab Jamahiriya",
                "code": "LY",
                "dial_code": "+218",
            }, {
                "googleTldCode": "LT",
                "googleTld": "google.lt",
                "name": "Lithuania",
                "code": "LT",
                "dial_code": "+370",
            }, {
                "googleTldCode": "LU",
                "googleTld": "google.lu",
                "name": "Luxembourg",
                "code": "LU",
                "dial_code": "+352",
            }, {
                "googleTldCode": "MG",
                "googleTld": "google.mg",
                "name": "Madagascar",
                "code": "MG",
                "dial_code": "+261",
            }, {
                "googleTldCode": "MY",
                "googleTld": "google.com.my",
                "name": "Malaysia",
                "code": "MY",
                "dial_code": "+60",
            }, {
                "googleTldCode": "MT",
                "googleTld": "google.com.mt",
                "name": "Malta",
                "code": "MT",
                "dial_code": "+356",
            }, {
                "googleTldCode": "MU",
                "googleTld": "google.mu",
                "name": "Mauritius",
                "code": "MU",
                "dial_code": "+230",
            }, {
                "googleTldCode": "MX",
                "googleTld": "google.com.mx",
                "name": "Mexico",
                "code": "MX",
                "dial_code": "+52",
            }, {
                "googleTldCode": "MD",
                "googleTld": "google.md",
                "name": "Moldova, Republic of",
                "code": "MD",
                "dial_code": "+373",
            }, {
                "googleTldCode": "MN",
                "googleTld": "google.mn",
                "name": "Mongolia",
                "code": "MN",
                "dial_code": "+976",
            }, {
                "googleTldCode": "ME",
                "googleTld": "google.me",
                "code": "ME",
                "name": "Montenegro",
                "dial_code": "+382",
            }, {
                "googleTldCode": "MA",
                "googleTld": "google.co.ma",
                "name": "Morocco",
                "code": "MA",
                "dial_code": "+212",
            }, {
                "googleTldCode": "MZ",
                "googleTld": "google.co.mz",
                "name": "Mozambique",
                "code": "MZ",
                "dial_code": "+258",
            }, {
                "googleTldCode": "NA",
                "googleTld": "google.com.na",
                "name": "Namibia",
                "code": "NA",
                "dial_code": "+264",
            }, {
                "googleTldCode": "NP",
                "googleTld": "google.com.np",
                "name": "Nepal",
                "code": "NP",
                "dial_code": "+977",
            }, {
                "googleTldCode": "NL",
                "googleTld": "google.nl",
                "name": "Netherlands",
                "code": "NL",
                "dial_code": "+31",
            }, {
                "googleTldCode": "NZ",
                "googleTld": "google.co.nz",
                "name": "New Zealand",
                "code": "NZ",
                "dial_code": "+64",
            }, {
                "googleTldCode": "NI",
                "googleTld": "google.com.ni",
                "name": "Nicaragua",
                "code": "NI",
                "dial_code": "+505",
            }, {
                "googleTldCode": "NG",
                "googleTld": "google.com.ng",
                "name": "Nigeria",
                "code": "NG",
                "dial_code": "+234",
            }, {
                "googleTldCode": "NO",
                "googleTld": "google.no",
                "name": "Norway",
                "code": "NO",
                "dial_code": "+47",
            }, {
                "googleTldCode": "OM",
                "googleTld": "google.com.om",
                "name": "Oman",
                "code": "OM",
                "dial_code": "+968",
            }, {
                "googleTldCode": "PK",
                "googleTld": "google.com.pk",
                "name": "Pakistan",
                "code": "PK",
                "dial_code": "+92",
            }, {
                "googleTldCode": "PY",
                "googleTld": "google.com.py",
                "name": "Paraguay",
                "code": "PY",
                "dial_code": "+595",
            }, {
                "googleTldCode": "PE",
                "googleTld": "google.com.pe",
                "name": "Peru",
                "code": "PE",
                "dial_code": "+51",
            }, {
                "googleTldCode": "PH",
                "googleTld": "google.com.ph",
                "name": "Philippines",
                "code": "PH",
                "dial_code": "+63",
            }, {
                "googleTldCode": "PL",
                "googleTld": "google.pl",
                "name": "Poland",
                "code": "PL",
                "dial_code": "+48",
            }, {
                "googleTldCode": "PT",
                "googleTld": "google.pt",
                "name": "Portugal",
                "code": "PT",
                "dial_code": "+351",
            }, {
                "googleTldCode": "RO",
                "googleTld": "google.ro",
                "name": "Romania",
                "code": "RO",
                "dial_code": "+40",
            }, {
                "googleTldCode": "RU",
                "googleTld": "google.ru",
                "name": "Russian Federation",
                "code": "RU",
                "dial_code": "+7",
            }, {
                "googleTldCode": "SA",
                "googleTld": "google.com.sa",
                "name": "Saudi Arabia",
                "code": "SA",
                "dial_code": "+966",
            }, {
                "googleTldCode": "SM",
                "googleTld": "google.com.sm",
                "name": "San Marino",
                "code": "SM",
                "dial_code": "+378",
            }, {
                "googleTldCode": "SN",
                "googleTld": "google.sn",
                "name": "Senegal",
                "code": "SN",
                "dial_code": "+221",
            }, {
                "googleTldCode": "RS",
                "googleTld": "google.rs",
                "code": "RS",
                "name": "Serbia",
                "dial_code": "+381",
            }, {
                "googleTldCode": "SG",
                "googleTld": "google.com.sg",
                "name": "Singapore",
                "code": "SG",
                "dial_code": "+65",
            }, {
                "googleTldCode": "SK",
                "googleTld": "google.sk",
                "name": "Slovakia",
                "code": "SK",
                "dial_code": "+421",
            }, {
                "googleTldCode": "SI",
                "googleTld": "google.si",
                "name": "Slovenia",
                "code": "SI",
                "dial_code": "+386",
            }, {
                "googleTldCode": "ZA",
                "googleTld": "google.co.za",
                "name": "South Africa",
                "code": "ZA",
                "dial_code": "+27",
            }, {
                "googleTldCode": "ES",
                "googleTld": "google.es",
                "name": "Spain",
                "code": "ES",
                "dial_code": "+34",
            }, {
                "googleTldCode": "LK",
                "googleTld": "google.lk",
                "name": "Sri Lanka",
                "code": "LK",
                "dial_code": "+94",
            }, {
                "googleTldCode": "SE",
                "googleTld": "google.se",
                "name": "Sweden",
                "code": "SE",
                "dial_code": "+46",
            }, {
                "googleTldCode": "CH",
                "googleTld": "google.ch",
                "name": "Switzerland",
                "code": "CH",
                "dial_code": "+41",
            }, {
                "googleTldCode": "TH",
                "googleTld": "google.co.th",
                "name": "Thailand",
                "code": "TH",
                "dial_code": "+66",
            }, {
                "googleTldCode": "TT",
                "googleTld": "google.tt",
                "name": "Trinidad and Tobago",
                "code": "TT",
                "dial_code": "+1868",
            }, {
                "googleTldCode": "TN",
                "googleTld": "google.tn",
                "name": "Tunisia",
                "code": "TN",
                "dial_code": "+216",
            }, {
                "googleTldCode": "TR",
                "googleTld": "google.com.tr",
                "name": "Turkey",
                "code": "TR",
                "dial_code": "+90"
            }, {
                "googleTldCode": "UA",
                "googleTld": "google.com.ua",
                "name": "Ukraine",
                "code": "UA",
                "dial_code": "+380",
            }, {
                "googleTldCode": "AE",
                "googleTld": "google.ae",
                "name": "United Arab Emirates",
                "code": "AE",
                "dial_code": "+971",
            }, {
                "googleTldCode": "UK",
                "googleTld": "google.co.uk",
                "code": "GB",
                "name": "United Kingdom",
                "dial_code": "+44",
            }, {
                "googleTldCode": "US",
                "googleTld": "google.com",
                "name": "United States",
                "code": "US",
                "dial_code": "+1",
            }, {
                "googleTldCode": "UY",
                "googleTld": "google.com.uy",
                "name": "Uruguay",
                "code": "UY",
                "dial_code": "+598",
            }, {
                "googleTldCode": "VE",
                "googleTld": "google.co.ve",
                "name": "Venezuela",
                "code": "VE",
                "dial_code": "+58",
            }, {
                "googleTldCode": "VN",
                "googleTld": "google.com.vn",
                "name": "Viet Nam",
                "code": "VN",
                "dial_code": "+84",
            }, {
                "googleTldCode": "ZM",
                "googleTld": "google.co.zm",
                "name": "Zambia",
                "code": "ZM",
                "dial_code": "+260",
            }, {
                "googleTldCode": "ZW",
                "googleTld": "google.co.zw",
                "name": "Zimbabwe",
                "code": "ZW",
                "dial_code": "+263",
            },
        ];

        return {
            all: all,
            allWorld: allWorld,
            getByCode: getByCode,
            getLangByCountry: getLangByCountry,
            getDialCodeByCountryCode: getDialCodeByCountryCode,
            isEU: isEU,
        };

        /**
         * @param countryCode
         * @returns {*}
         */
        function getByCode(countryCode) {
            for (var i = 0; i < self.countries.length; i++)
                if (self.countries[i].code.toLowerCase() == countryCode.toLowerCase())
                    return self.countries[i];
            return -1;
        }

        /**
         * @returns {*[]|*}
         */
        function all() {
            return self.countriesDb;
        }

        /**
         * @returns {*[]|*}
         */
        function allWorld() {
            return self.countries;
        }

        /** EUROPEAN COUNTRIES MANAGER **/

        /**
         * @param countryCode
         * @returns {boolean}
         */
        function isEU(countryCode) {
            try {
                countryCode = countryCode.toUpperCase();
            } catch (err) {
                return false;
            }
            return self.euCountries[countryCode] == undefined ? false : true;
        }

        /**
         *
         * @param countryCode
         * @returns {*}
         */
        function getLangByCountry(countryCode) {
            countryCode = countryCode.toUpperCase();
            if (self.countryLanguages[countryCode])
                return self.countryLanguages[countryCode][0];
            return 'en';
        }

        /**
         *
         * @param countryCode
         * @returns {*}
         */
        function getDialCodeByCountryCode(countryCode) {
            countryCode = countryCode.toUpperCase();
            return self.countriesDb.filter(function (country) {
                return country.code == countryCode;
            })[0].dial_code;
        }
    }
});

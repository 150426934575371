'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seochecker/components/core-web-vitals/core-web-vitals.html',
        bindings: {
            overview: '<',
            performance: '<',
            loading: '<',
            dashboardMode: '<',
            showTab: '<'
        },
        controller: ['$scope', '$mdMedia', function ($scope, $mdMedia) {
            var vm = $scope;
            vm.$mdMedia = $mdMedia;
            vm.showTab = 'mobile';

            this.$onInit = function () {
                if (this.showTab) {
                    vm.showTab = this.showTab;
                }
            }

            this.$onChanges = function () {
                vm.loading = this.loading;
                vm.dashboardMode = this.dashboardMode;
                vm.coreWebVitalsAreNotPresent = false;

                vm.performance = this.performance;
                if (this.showTab) {
                    vm.showTab = this.showTab;
                }
                vm.switchType();

                if (this.overview && this.overview.relevantKeywords) {
                    vm.relevantKeywords = this.overview.relevantKeywords;
                    vm.wordCount = this.overview.wordCount;
                    vm.readingTime = this.overview.readingTime;
                }
            }

            function getDisplayValue(performance, strategy, examKey) {
                if (!performance ||
                    !performance[strategy] ||
                    !performance[strategy].exams ||
                    !performance[strategy].exams[examKey] ||
                    !performance[strategy].exams[examKey].displayValue) {
                    return null;
                }

                return performance[strategy].exams[examKey].displayValue;
            }

            function cleanValue(performance, strategy, examKey) {
                var displayValue = getDisplayValue(performance, strategy, examKey);

                if (!displayValue) {
                    return 'ND';
                }

                return parseFloat(displayValue
                    .replace(/[^0-9.,]/g, "")
                    .replace(/ +/, " ")
                    .replace(',', '.'));
            }

            function areCoreWebVitalsPresent(performance) {
                return performance &&
                    (
                        (
                            performance.desktop &&
                            performance.desktop.coreWebVitals &&
                            Object.entries(vm.performance.desktop.coreWebVitals).length > 0) ||
                        (performance.mobile &&
                            performance.mobile.coreWebVitals &&
                            Object.entries(vm.performance.mobile.coreWebVitals).length > 0
                        )
                    )
            }

            function getCoreWebVitalScore(performance, strategy, coreWebVitalKey) {
                if (!performance ||
                    !performance[strategy] ||
                    !performance[strategy].coreWebVitals ||
                    !performance[strategy].coreWebVitals[coreWebVitalKey] ||
                    !performance[strategy].coreWebVitals[coreWebVitalKey].hasOwnProperty('percentile')
                ) {
                    return null;
                }


                return performance[strategy].coreWebVitals[coreWebVitalKey].percentile;
            }

            function isTest28daysPassed(performance, strategy) {
                if (!performance ||
                    !performance[strategy] ||
                    !performance[strategy].coreWebVitals ||
                    !performance[strategy].coreWebVitals.lcp ||
                    !performance[strategy].coreWebVitals.lcp.category ||
                    !performance[strategy].coreWebVitals.fid ||
                    !performance[strategy].coreWebVitals.fid.category ||
                    !performance[strategy].coreWebVitals.cls ||
                    !performance[strategy].coreWebVitals.cls.category
                ) {
                    return false;
                }

                return performance.desktop.coreWebVitals.lcp.category === 'FAST' &&
                    performance.desktop.coreWebVitals.fid.category === 'FAST' &&
                    performance.desktop.coreWebVitals.cls.category === 'FAST'
            }

            function getScreenshot(performance, strategy) {
                var screenshot = '../assets/img/seochecker/screen-placeholder/mobile.png';
                if(strategy === 'desktop') {
                    screenshot = '../assets/img/seochecker/screen-placeholder/desktop.png';
                }

                if(performance && performance[strategy] && performance[strategy].screenshot && performance[strategy].screenshot.src) {
                    screenshot = performance[strategy].screenshot.src;
                }

                return screenshot;
            }

            vm.switchType = function () {
                vm.test28daysPassed = false;
                vm.coreWebVitalsAreNotPresent = false;

                // Set strategy
                var strategy = 'mobile';
                if (vm.showTab === 'desktop' || this.showTab === 'desktop') {
                    strategy = 'desktop';
                }

                vm.screenshot = getScreenshot(vm.performance, strategy);

                // Get Main categories value
                vm.firstContentfulPaint = cleanValue(vm.performance, strategy, 'first-contentful-paint');
                vm.timeToInteractive = cleanValue(vm.performance, strategy, 'interactive');
                vm.speedIndex = cleanValue(vm.performance, strategy, 'speed-index');
                vm.totalBlockingTime = cleanValue(vm.performance, strategy, 'total-blocking-time');
                vm.largestContentfulPaint = cleanValue(vm.performance, strategy, 'largest-contentful-paint');
                vm.cumulativeLayoutShift = cleanValue(vm.performance, strategy, 'cumulative-layout-shift');

                // Check if CWV exists
                if (!areCoreWebVitalsPresent(vm.performance)) {
                    vm.coreWebVitalsAreNotPresent = true;
                    return;
                }

                // Get CWV scores
                vm.lcpValue = getCoreWebVitalScore(vm.performance, strategy, 'lcp');
                if (vm.lcpValue&&vm.lcpValue>=0) {
                    vm.lcpValue /= 1000;
                }
                vm.fidValue = getCoreWebVitalScore(vm.performance, strategy, 'fid');
                if (vm.fidValue&&vm.fidValue>=0) {
                    vm.fidValue /= 100;
                }
                vm.clsValue = getCoreWebVitalScore(vm.performance, strategy, 'cls');
                if (vm.clsValue&&vm.clsValue>=0) {
                    vm.clsValue /= 100;
                }
                vm.test28daysPassed = isTest28daysPassed(vm.performance, strategy);
            }
        }]
    };
});

'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$mdDialog,errorCode) {
        var vm=$scope;
        vm.error = errorCode.data.errors.message;
        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            $mdDialog.cancel();
        }
        function answer() {
            $mdDialog.answer();
        }
    }
    Controller.$inject=['$scope','$mdDialog','errorCode'];
    return Controller;
});

'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/st-auth-admin/st-auth.html',
        bindings: {
            title: '=',
            subtitle: '=',
            cta: '=',
            action: '=',
            transition: '=',
            social: '=',
            service: '=',
            onAuth: '=',
            onSignup: '='
        },
        controller: ['$scope', '$state', 'AuthService', '$timeout', '$location',

            function ($scope, $state, Auth, $timeout, $location) {
                const queryParams = $location.search();

                $scope.action = 'signin';
                $scope.transition = false;
                $scope.onAuth = null;
                $scope.passType = 'password';
                $scope.$watch('$ctrl.social', setSocial);
                $scope.$watch('$ctrl.title', setTitle);
                $scope.$watch('$ctrl.subtitle', setSubtitle);
                $scope.$watch('$ctrl.cta', setCallToAction);
                $scope.$watch('$ctrl.action', setAction);
                $scope.$watch('$ctrl.transition', setTransition);
                $scope.$watch('$ctrl.service', setService);
                $scope.$watch('$ctrl.onAuth', setOnAuth);

                $scope.error = {
                    signin: null,
                };

                $scope.loading = {
                    forgot: false,
                    signin: false,
                    signup: false,
                    reset: false
                };

                $scope.data = {
                    signin: {
                        email: null,
                        pass: null,
                        lang: 'en',
                        loginAs: null
                    },
                };

                if (queryParams && queryParams.email) {
                    $scope.data.signin.loginAs = queryParams.email;
                }

                $scope.signin = signin;
                $scope.setPassType = setPassType;
                $scope.goDashboard = goDashboard;
                var thisTimeout;

                $scope.$onAuth = function () {
                    if ($scope.onAuth) {
                        $scope.onAuth();
                    } else {
                        $state.go('app.welcome');
                    }
                };

                function signin() {
                    if (!$scope.loading.signin) {
                        $scope.loading.signin = true;
                        $timeout.cancel(thisTimeout);
                        Auth.loginAs($scope.data.signin, $scope.data.signin.loginAs)
                            .then(function (res) {
                                $scope.loading.signin = false;
                                $scope.$onAuth();
                            })
                            .catch(function (err) {
                                $scope.loading.signin = false;
                                $scope.error.signin = err;
                                thisTimeout = $timeout(function () {
                                    $scope.error.signin = null;
                                }, 6000);
                            });
                    }
                }

                function setAction(value) {
                    $scope.action = value || 'signin';
                    switch (value) {
                        case 'signup-confirm':
                            goDashboard();
                            break;
                    }
                }

                function setCallToAction(callToAction) {
                    $scope.callToAction = callToAction || null;
                }

                function setSocial(value) {
                    $scope.social = value || 'expand';
                }

                function setTitle(value) {
                    $scope.title = value === false ? false : (value || null);
                }

                function setSubtitle(value) {
                    $scope.subtitle = value === false ? false : (value || null);
                }

                function setTransition(value) {
                    $scope.transition = value;
                }

                function setService(value) {
                    $scope.service = value;
                }

                function setOnAuth(value) {
                    $scope.onAuth = value;
                }

                function goDashboard() {
                    $timeout(function () {
                        $state.go('app.welcome');
                    }, 3500);
                }

                function setPassType(value) {
                    $scope.passType = value;
                }
            }
        ]
    };
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/page/table.html',
        bindings: {
            pages: '=',
            type: '<',
        },
        controller: ['$scope', function ($scope) {
            var vm = $scope;
            $scope.$watch('$ctrl.pages', function (pages) {
                vm.pages = pages;
            });

            if (!vm.pages) {
                vm.pages = [];
            }

            vm.evalOccurences = evalOccurences;
            vm.evalLength = evalLength;

            function evalOccurences(score) {
                switch (parseInt(score) || 0) {
                    case 1:
                        return 'green-text';
                    case 0:
                        return 'red-text';
                    default:
                        return 'text-darken-1 orange-text';
                }
            }

            function evalLength(len) {
                len = parseInt(len) || 0;
                if (len > 70)
                    return 'red-text';
                if (len > 15 && len <= 70)
                    return 'green-text';
                if (len >= 5 && len <= 15)
                    return 'text-darken-1 orange-text';
                return 'red-text';
            }
        }]
    };
});

'use strict';
define([], function () {
    function PageDetailCtrl($scope, $window, $rootScope, $state, detail, $mdMedia) {
        $scope.$mdMedia = $mdMedia;
        $scope.detail = detail;
        $scope.pageId = detail.id;
        $scope.backInHistory = backInHistory;
        $scope.changeDetailCategory = changeDetailCategory;
        $scope.seoAudit = seoAudit;
        $scope.castUrl = castUrl;
        $scope.backState = $rootScope.previousState;
        if ($scope.backState == '') {
            $scope.backState = undefined;
        }

        $scope.cleanUrl = function (url) {
            if (!url) {
                return;
            }
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        };

        $scope.currentDetailCategory = $state.current.name;
        $scope.tabs = {
            categoryState: 'app.seospider.exam.analysis.detail',
            fields: [
                {label: 'seospider.snippet.summary', uiSref: '.summary'},
                {label: 'seospider.text.and.titles', uiSref: '.body'},
                {label: 'seospider.links.detail', uiSref: '.links'},
                {label: 'seospider.keywords.detail', uiSref: '.keywords'},
                {label: 'seospider.language.set', uiSref: '.lang'},
                {label: 'seospider.all.images', uiSref: '.images'}
            ]
        };

        function backInHistory() {

            $window.history.back();

        }

        function changeDetailCategory() {
            $state.go($scope.currentDetailCategory);
        }

        function isValidUrl(url) {
            var correct_url = new RegExp(/^((http:\/\/|https:\/\/)?(www\.|^\.)?((([\-|\w|\d]+)\.)+([a-zA-Z]{1,10}))(\/?(([a-zA-Z0-9_@]+\.([a-zA-Z0-9]{3,6}))?(\??[-A-Za-z0-9+:&@#\/%=~'_\.?|]+))){0,1})$/gim);
            return url != undefined && url.length > 4 && url.match(correct_url);
        }

        function castUrl(url) {
            return encodeURIComponent(btoa(url));
        }

        function seoAudit(url) {
            var correct_url = new RegExp(/^((http:\/\/|https:\/\/)?(www\.|^\.)?((([\-|\w|\d]+)\.)+([a-zA-Z]{1,10}))(\/?(([a-zA-Z0-9_@]+\.([a-zA-Z0-9]{3,6}))?(\??[-A-Za-z0-9+:&@#\/%=~'_\.?|]+))){0,1})$/gim);
            if (url != undefined && url.length > 4 && url.match(correct_url)) {
                $state.go('app.seochecker.results.overview', {url: encodeURIComponent(btoa(url))});
            }
        }
    }

    PageDetailCtrl.$inject = ['$scope', '$window', '$rootScope', '$state', 'detail', '$mdMedia'];
    return PageDetailCtrl;
});


'use strict';
define(['angular'], function (ng) {
    function Controller($scope, $stateParams, $q, results, jobId, Pages, $rootScope, $translate) {
        var vm = $scope;
        vm.pages = results.data;
        vm.query = '';
        vm.jobId = jobId;
        vm.pagination = {
            numElements: results.totalRows,
            offset: 0,
            callback: pagingCallback
        };
        vm.openDetail = openDetail;
        vm.currentSearch = {
            placeholder: $translate.instant('seospider.description.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.description.search'),
                callback: searchCallback
            };
        });

        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAllMetaDescriptionDuplicated({
                jobId: $stateParams.sessionId,
                q: query,
                offset: 0
            }).then(function (res) {
                    vm.pagination.offset = 0;
                    vm.pagination.numElements = res.totalRows;
                    vm.pages = res.data;
                },
                function (err) {
                    vm.pages = err.data;
                });
        }

        function openDetail(info) {
            $rootScope.$emit('openToolbarDetail', {
                list: info.duplicated,
                element: 'meta name="description"',
                label: $translate.instant('seospider.duplicated.in.page'),
                placeholder: $translate.instant('seospider.title.filter', {'title': info.metaDescription})
            });
            $rootScope.$on('languageChanged', function () {
                $rootScope.$emit('openToolbarDetail', {
                        list: info.duplicated,
                        element: 'meta name="description"',
                        label: $translate.instant('seospider.duplicated.in.page'),
                        placeholder: $translate.instant('seospider.title.filter', {'title': info.metaDescription})
                    }
                );
            });
        }

        function pagingCallback(limit, offset) {
            var dfd = $q.defer();
            Pages.findAllMetaDescriptionDuplicated({
                jobId: $stateParams.sessionId,
                q: vm.query,
                limit: limit,
                offset: offset
            }).then(function (res) {
                vm.pages = res.data;
            }, function (err) {
                vm.pages = err.data;
                dfd.resolve();
            });
            return dfd.promise;
        }
    }

    Controller.$inject = ['$scope', '$stateParams', '$q', 'results', 'jobId', 'SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

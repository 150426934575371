'use strict';
define([], function() {
    function Controller($scope, $state, $mdDialog, project, ProjectsService, TrackEventService) {
        $scope.project = project;
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;

        function answerDialog() {
            ProjectsService.deleteProject($scope.project.projectId)
                .then(function (res){
                    if ($state.current.name == 'app.seospider.list') {
                        $state.go('app.seospider.list');
                    }
                    
                    TrackEventService.event(
                        'Suite Usages',
                        'SEO Project - Deleted');
                    TrackEventService.gaV2Event('project_delete');
                    hideDialog();
                })
                .catch(function(err){
                    console.log(err);
                });
        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject=['$scope', '$state', '$mdDialog', 'project', 'ProjectsService', 'TrackEventService'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/settings/settings.controller',
    'modules/settings/settings.routes',
    'modules/settings/components/paypal/paypal-subscriptions-info.component',
    'modules/settings/components/header/header-subscriptions.component',
    'modules/settings/components/subscriptionsNotPresents/alert-subscriptions-not-presents.component',
    'modules/settings/components/st-select-workspace/st-select-workspace.component',
    'modules/settings/update/update.module',
    'modules/settings/overview/overview.module',
    'modules/settings/changePassword/changePassword.module',
    'modules/settings/billingInfo/billingInfo.module',
    'modules/settings/businessInfo/businessInfo.module',
    'modules/settings/subscriptions/subscriptions.module',
    'modules/settings/paymentsMethods/paymentsMethods.module',
    'modules/settings/digitalGiftBox/digitalGiftBox.module',
    'modules/settings/partnersArea/partnersArea.module',
    'modules/settings/invitations/invitations.module',
    'modules/settings/customizations/customizations.module',
    'modules/settings/dialogs/dialogs.module',
    'modules/settings/users/users.module',
    'modules/settings/logs/logs.module',
    'modules/settings/invoices/invoices.module',
    'modules/settings/api/api.module',
], function(
        angular,
        SettingsController,
		routes,
		paypalSubscriptionsInfo,
		headerSubscriptions,
        alertSubscriptionsNotPresents,
        stSelectWorkspace
) {
    var app = angular.module('app.settings', [
        'settings.update',
        'settings.overview',
        'settings.changePassword',
        'settings.billingInfo',
        'settings.businessInfo',
        'settings.subscriptions',
        'settings.paymentsMethods',
        'settings.digitalGiftBox',
        'settings.partnersArea',
        'settings.invitations',
        'settings.customizations',
        'settings.dialogs',
        'settings.users',
        'settings.logs',
        'settings.invoices',
        'settings.api'
    ]);

    app.controller('SettingsController', SettingsController);

    app.component('paypalSubscriptionsInfo', paypalSubscriptionsInfo);
    app.component('headerSubscriptions', headerSubscriptions);
    app.component('alertSubscriptionsNotPresents', alertSubscriptionsNotPresents);
    app.component("stSelectWorkspace",stSelectWorkspace);
    app.config(routes);
    return app;
});

define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.canonical",{
                controller:'SPcanonicalCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/canonical/canonical.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.canonical.all",{
                url:"/canonical",
                templateUrl: './app/modules/seospider/exam/analysis/canonical/all/all.html',
                access: {restricted: true},
                controller:'SPCanonicalAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            fields:['id','url','title','canonical'],
                            status: 200
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.canonical.missing",{
                url:"/canonical/missing",
                templateUrl: './app/modules/seospider/exam/analysis/canonical/all/all.html',
                access: {restricted: true},
                controller:'SPCanonicalMissCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            fields:['id','url','title','canonical'],
                            canonicalMissing:true,
                            status: 200
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.canonical.defined",{
                url:"/canonical/defined",
                templateUrl: './app/modules/seospider/exam/analysis/canonical/all/all.html',
                access: {restricted: true},
                controller:'SPCanonicalDefinedCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAll({
                            jobId:$stateParams.sessionId,
                            fields:['id','url','title','canonical'],
                            canonicalMissing:false,
                            status: 200
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            });
    }
    config.$inject=['$stateProvider'];
    return config;
});

/**
 * @ngdoc overview
 * @name estimatedVisitService.service:EstimatedVisitService
 */
'use strict';
define(['app'], function (app) {
    app.service('EstimatedVisitService', EstimatedVisitService);
    EstimatedVisitService.$inject = [];

    function EstimatedVisitService() {
        var percents = {
            1: 31.73,
            2: 24.71,
            3: 18.66,
            4: 13.60,
            5: 9.51,
            6: 6.23,
            7: 4.15,
            8: 3.12,
            9: 2.97,
            10: 3.09,
        };

        return {
            get: get,
        };

        function get(visists, position) {

            if (!visists || !position) {
                return 0;
            }

            var percent = percents[position] || 2;
            var ev = (visists * percent) / 100;
            return ev.toFixed(0);
        }
    }
});

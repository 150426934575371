'use strict';
define([], function () {
    controller.$inject = ['$scope'];

    function controller($scope) {
        var ctrl = this;


        $scope.$watch('$ctrl.data', function (data) {
            ctrl.data = data;
        });
        $scope.$watch('$ctrl.type', function (type) {
            ctrl.type = type;
        });
    }

    return {
        templateUrl: 'app/components/st-sparkline/st-sparkline.html',
        bindings: {
            data: "=",
            type: "=",
        },
        controller: controller
    };
});

/**
 * @ngdoc overview
 * @name language.service
 *
 * @description
 * Load, convert and creates var & path for all typical function,
 * this help for manage all Language and relative code, translate and call inside this APP
 *
 */
'use strict';
define(['app'], function (app) {
    app.service('LanguageService', LanguageService);
    LanguageService.$inject = ['$http', '$rootScope', '$translate', '__env'];
    var DEFAULT_LOCALE_AND_COUNTRY = 'en-US';
    var languageCodeToLocale = {
        'it': 'it-IT',
        'en': 'en-US',
        'es': 'es-ES',
        'pl': 'pl-PL',
        'de': 'de-DE',
        'fr': 'fr-FR',
    };
    var localeToLanguageCode = {
        'it-IT': 'it',
        'en-US': 'en',
        'es-ES': 'es',
        'pl-PL': 'pl',
        'de-DE': 'de',
        'fr-FR': 'fr',
    };

    /**
     * Language Service
     * @namespace LanguageService
     */
    function LanguageService($http, $rootScope, $translate, __env) {
        return {
            getLanguageCodeSaveOnStorage: getLanguageCodeSaveOnStorage,
            getCountryCodeSaveOnStorage: getCountryCodeSaveOnStorage,
            getCodeAndCountrySaveOnStorage: getCodeAndCountrySaveOnStorage,
            setCodeAndCountryOnStorage: setCodeAndCountryOnStorage,
            getLocaleFromLanguageCode: getLocaleFromLanguageCode,
            getLanguageCodeFromLocale: getLanguageCodeFromLocale,
            getLocale: getLocale,
        };

        function getLocaleFromLanguageCode(languageCode) {
            if (!languageCode || !languageCodeToLocale[languageCode]) {
                return 'en-US';
            }

            return languageCodeToLocale[languageCode];
        }

        function getLanguageCodeFromLocale(locale) {
            if (!locale || !localeToLanguageCode[locale]) {
                return 'en';
            }

            return localeToLanguageCode[locale];
        }

        function convertOldLanguageCode(languageCode) {
            switch (languageCode) {
                case 'it':
                    setCodeAndCountryOnStorage('it-IT');
                    return 'it-IT';
                case 'es':
                    setCodeAndCountryOnStorage('es-ES');
                    return 'es-ES';
                case 'pl':
                    setCodeAndCountryOnStorage('pl-PL');
                    return 'pl-PL';
                case 'de':
                    setCodeAndCountryOnStorage('de-DE');
                    return 'de-DE';
                case 'fr':
                    setCodeAndCountryOnStorage('fr-FR');
                    return 'fr-FR';
                default:
                    setCodeAndCountryOnStorage('en-US');
                    return 'en-US';
            }
        }

        function getLocaleAndCountryCode() {
            var localLanguage = localStorage.getItem('NG_TRANSLATE_LANG_KEY');

            if (typeof localLanguage === 'string') {
                if (localLanguage.indexOf('-') < 0) {
                    localLanguage = convertOldLanguageCode(localLanguage);
                }

                localLanguage = localLanguage.split('-');
            }

            if (typeof localLanguage === 'object') {
                return localLanguage;
            }

            if (navigator && navigator.language) {
                return navigator.language.split('-');
            }

            return DEFAULT_LOCALE_AND_COUNTRY.split('-');
        }

        function getLocale() {
            return getLocaleAndCountryCode()[0] + '_' + getLocaleAndCountryCode()[1];
        }

        /**
         * Return Code Language save inside Local Storage on Browser
         * example: en
         * @return string
         */
        function getLanguageCodeSaveOnStorage() {
            return getLocaleAndCountryCode()[0];
        }

        /**
         * Return Country code save inside Local Storage on Browser
         * example: US
         * @return string
         */
        function getCountryCodeSaveOnStorage() {
            return getLocaleAndCountryCode()[1];
        }

        /**
         * Return the Code and Country save inside Local Storage on Browser
         * example: en-US
         * @return string
         */
        function getCodeAndCountrySaveOnStorage() {
            return localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        }

        /**
         * Set the Code and Country inside Local Storage on Browser
         * example: en-US
         * @return string
         */
        function setCodeAndCountryOnStorage(codeAndCountry) {
            // Set Language chosed from User
            localStorage.setItem('NG_TRANSLATE_LANG_KEY', codeAndCountry);
            return true;
        }

        /**
         * Clear Language
         * example: en-US
         * @return string
         */
        function setClearCodeAndCountrySaveOnStorage() {
            localStorage.removeItem('NG_TRANSLATE_LANG_KEY');
        }

    }
});

'use strict';
define(['angular'], function () {
    function Controller($scope, $mdDialog,$mdMedia, $state, LeadGenerationService, TrackEventService) {
        var vm = $scope;
        vm.$mdMedia=$mdMedia;
        vm.loading = {
            post: false
        }
        vm.form = {
            formTypeId: 1,
            name: null
        };
        vm.createForm = createForm;
        vm.openCreateFormDialog = openCreateFormDialog;
        vm.isActive = isActive;
        vm.setFormTypeId = setFormTypeId;

        function createForm() {
            vm.loading.post = true;
            if (!vm.form.name||vm.form.name.length<1) {
                vm.loading.post = false;
                return;
            }

            var formData = {
                name: vm.form.name.trim(),
                formTypeId: vm.form.formTypeId
            }

            LeadGenerationService.createForm(formData)
                .then(function(res){
                    TrackEventService.event(
                        'Suite Usages',
                        'Lead Gen - Campaign Created');
                    TrackEventService.gaV2Event(
                        'campaign_create',{
                            tool: "Lead Generation Tool"
                        });

                    if (res.id) {
                        switch(vm.form.formTypeId) {
                            case 1:
                                $state.go('app.leadgeneration.form.form-generator.free-checker.form', {"formId": res.id});
                                break;
                            case 2:
                                $state.go('app.leadgeneration.form.form-generator.whitelabel-report.form', {"formId": res.id});
                                break;
                            case 3:
                                $state.go('app.leadgeneration.form.form-generator.custom-file.form', {"formId": res.id});
                                break
                            default:
                                $state.go('app.leadgeneration.landing');
                        }
                    }
                })
                .catch(function(err){
                    vm.loading.post=false;
                    if (err && err.errors && err.errors.length>0 && err.errors[0].code=='ER_DUP_ENTRY') {
                        vm.duplicatedNameError = true;
                    }
                });
        }

        vm.firstLoad = true;
        vm.forms = [];

        function getAllForms() {
            LeadGenerationService.getAllForms()
                .then(function (list) {
                    vm.forms = list;
                    vm.firstLoad = false;
                })
                .catch(function (err) {
                    console.log(err);
                    vm.firstLoad = false;
                });
        }

        function init() {
            getAllForms();
        }

        init();

        function isActive(id) {
            return vm.form.formTypeId == id;
        }

        function setFormTypeId(id) {
            vm.form.formTypeId = id;
        }

        function openCreateFormDialog(ev) {
            $mdDialog.show({
                templateUrl: "app/modules/leadgeneration/components/dialogs/create-form/create-form.html",
                controller: "leadGenerationDialogCreateFormCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            });
        }

    }

    Controller.$inject = ['$scope', '$mdDialog','$mdMedia', '$state', 'LeadGenerationService', 'TrackEventService'];
    return Controller;
});

'use strict';
define([], function() {
    function Controller($scope, $mdDialog, keywordListIds, KeywordListService, $state, $translate, TrackEventService) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };

        var keywordListCount = keywordListIds.length;
        if (keywordListCount  > 1) {
            $scope.modalTitle = $translate.instant('keyword.list.component.delete.all.lists', { count: keywordListCount });
            $scope.modalSubtitle = $translate.instant('keyword.list.component.sure.to.delete.selected.lists');
        } else {
            $scope.modalTitle = $translate.instant('keyword.list.component.delete.this.list');
            $scope.modalSubtitle = $translate.instant('keyword.list.component.sure.to.delete.this.list');
        }

        function answerDialog(answer) {
            $scope.loading.post = true;

            KeywordListService.deleteKeywordList(keywordListIds).then(function(res){
                TrackEventService.event(
                    'Suite Usages',
                    'Keyword List Manager - List Deleted');
                TrackEventService.gaV2Event(
                    'list_delete',{
                        tool: "Keyword List Manager"
                    });
                $state.reload();
                $scope.loading.post = false;
                $mdDialog.hide();
            }).catch(function(err){
                $scope.loading.post = false;
            });
        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject=['$scope', '$mdDialog', 'keywordListIds', 'KeywordListService', '$state', '$translate', 'TrackEventService'];
    return Controller;
});

'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/st-line-graph/st-line-graph.html',
        bindings: {
            params: '=',
        },
        controller:['$scope',function($scope){
            $scope.$watch('$ctrl.params', function(params) {
                $scope.params = params;
                setGraph(params);
            });
            function setGraph(params) {
                $scope.line = {
                    labels: params.labels,
                    data: params.data,
                    onClick: function () {},
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    fontColor: '#a1aeb7',
                                    fontSize: 14,
                                    maxTicksLimit: 3,
                                },
                                gridLines: {
                                    display: false,
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    maxTicksLimit: 3,
                                    fontColor: "#a1aeb7",
                                    fontSize: 14,
                                },
                                gridLines: {
                                    drawBorder: false,
                                },
                                fontColor: '#fafafa',
                                position: 'right',
                                scalePositionRight: true
                            }]
                        }
                    },
                    datasetOverride: [{
                        label: "Leads",
                        borderWidth: 2,
                        pointBorderWidth: 1,
                        //lineTension:0,
                        pointBackgroundColor: "rgba(255, 0, 102,1)",
                        hoverBackgroundColor: "rgba(255, 0, 102,0.4)",
                        hoverBorderColor: "rgba(255, 0, 102,1)",
                        backgroundColor: "rgba(255, 0, 102,0.1)",
                        borderColor: "rgba(255, 0, 102,1)",
                        type: 'line'
                    }]
                };
            }
        }]
    };
});
define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("pitchground",{
                url: "/pitchground",
                controller:"PitchgroundController",
                templateUrl:'./app/modules/pitchground/pitchground.html',
                access: {restricted: false},
            });
    }
    config.$inject=['$stateProvider'];
    return config;
});

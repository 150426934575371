define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.taxonomies", {
                url: "/taxonomies",
                templateUrl: './app/modules/seospider/exam/analysis/taxonomies/taxonomies.html',
                access: {restricted: true},
                controller: 'TaxonomiesCtrl',
                resolve: {
                    taxonomies: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.taxonomies({
                            jobId: $stateParams.sessionId
                        });
                    }],
                }
            })
            .state("app.seospider.exam.analysis.detail", {
                abstract: true,
                url: "/detail/:pageId",
                templateUrl: './app/modules/seospider/exam/analysis/detail/detail.html',
                controller: 'PageDetailCtrl',
                access: {restricted: true},
                resolve: {
                    detail: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findOne({
                            jobId: $stateParams.sessionId,
                            pageId: $stateParams.pageId
                        });
                    }]
                }
            })
            .state("app.seospider.exam.analysis.detail.summary", {
                url: "/summary",
                templateUrl: './app/modules/seospider/exam/analysis/detail/summary/summary.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.body", {
                url: "/body",
                templateUrl: './app/modules/seospider/exam/analysis/detail/body/body.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.lang", {
                url: "/lang",
                controller: 'SinglePageLangCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/detail/lang/lang.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.keywords", {
                url: "/keywords",
                controller: 'SinglePageKeywordsCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/detail/keywords/keywords.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.images", {
                url: "/images",
                controller: 'SinglePageImagesCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/detail/images/images.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.links", {
                url: "/links",
                templateUrl: './app/modules/seospider/exam/analysis/detail/links/links.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.detail.browser", {
                url: "/browser",
                templateUrl: './app/modules/seospider/exam/analysis/detail/browser/browser.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer", {
                url: "/explorer",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/explorer.html',
                access: {restricted: true},
                controller: 'SPExplorerCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findAll({
                            jobId: $stateParams.sessionId
                        });
                    }]
                }
            })
            .state("app.seospider.exam.analysis.explorer.detail", {
                abstract: true,
                url: "/:pageId",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/detail.html',
                controller: 'SinglePageDetailCtrl',
                access: {restricted: true},
                resolve: {
                    detail: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findOne({
                            jobId: $stateParams.sessionId,
                            pageId: $stateParams.pageId
                        });
                    }]
                }
            })

            .state("app.seospider.exam.analysis.explorer.detail.summary", {
                url: "/summary",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/summary/summary.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.body", {
                url: "/body",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/body/body.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.lang", {
                url: "/lang",
                controller: 'SinglePageLangCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/lang/lang.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.keywords", {
                url: "/keywords",
                controller: 'SinglePageKeywordsCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/keywords/keywords.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.images", {
                url: "/images",
                controller: 'SinglePageImagesCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/images/images.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.links", {
                url: "/links",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/links/links.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.explorer.detail.browser", {
                url: "/browser",
                templateUrl: './app/modules/seospider/exam/analysis/explorer/detail/browser/browser.html',
                access: {restricted: true}
            });
            
            
    }

    config.$inject = ['$stateProvider'];
    return config;
});

import {saveAs} from 'fileSaver';
export default {
    templateUrl: 'app/modules/seospider/exam/analysis/components/export-dialog/export-dialog.html',
    bindings: {
        categories: '='
    },
    controller: ['$scope', '$mdDialog', '$stateParams', 'SeospiderExportService',
        function ($scope, $mdDialog, $stateParams, SeospiderExportService) {
            var vm = this;
            $scope.$watchCollection('$ctrl.categories', function (categories) {
                vm.categories = categories;
            });
            $scope.showExport = function (categories, ev) {
                $mdDialog.show({
                    controller: dialogController,
                    templateUrl: 'app/modules/seospider/exam/analysis/components/export-dialog/exportModal.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    locals: {
                        categories: categories,
                    }
                })
                    .then(function (answer) {

                    }, function () {
                        SeospiderExportService.abort();
                    });
            };

            function dialogController($scope, $mdDialog, categories, SeospiderExportService, $rootScope, SeospiderService, TrackEventService) {
                $scope.categories = categories;
                $scope.exportedElements = 0;
                $scope.exportedElementsTotal = 0;
                $scope.determinateValue = 0;
                $scope.activated = false;
                $scope.initialization = false;
                $scope.exporting = false;
                $scope.downloading = false;
                $scope.selectedCategories = [];
                $scope.completedCategories = 1;
                $rootScope.$on('updateExport', function (event, dataExport) {
                    $scope.initialization = false;
                    $scope.exporting = true;
                    $scope.exportedElementsTotal = dataExport.totalRows;
                    $scope.exportedElements = $scope.exportedElements + dataExport.data.length;
                    $scope.determinateValue = (100 * ($scope.exportedElements / $scope.exportedElementsTotal));
                    if ($scope.exportedElements == $scope.exportedElementsTotal) {
                        $scope.exportedElements = 0;
                        $scope.determinateValue = 0;
                        $scope.completedCategories = $scope.completedCategories + 1;
                    }
                    if ($scope.completedCategories == ($scope.selectedCategories.length + 1)) {
                        $scope.exporting = false;
                        $scope.downloading = true;
                    }
                });

                $scope.hide = function () {
                    $mdDialog.hide();
                };
                $scope.cancel = function () {
                    SeospiderExportService.abort();
                    $mdDialog.cancel();
                };
                $scope.isChecked = function () {
                    return $scope.selectedCategories.length === $scope.categories.fields.length;
                };

                $scope.toggleAll = function () {
                    if ($scope.selectedCategories.length === $scope.categories.fields.length) {
                        $scope.selectedCategories = [];
                    } else if ($scope.selectedCategories.length === 0 || $scope.selectedCategories.length > 0) {
                        for (var i = 0; i < $scope.categories.fields.length; i++) {
                            var idx = $scope.selectedCategories.indexOf($scope.categories.fields[i].label);
                            if (idx === -1) {
                                $scope.selectedCategories.push($scope.categories.fields[i].label);
                            }
                        }
                    }
                };
                $scope.exportButton = function () {
                    $scope.activated = true;
                    $scope.initialization = true;
                    $scope.exportedElements = 0;
                    $scope.determinateValue = 0;
                    SeospiderExportService.getExport($stateParams.sessionId, $scope.selectedCategories).then(function (sheets) {
                        console.log('shweets', sheets)
                        $scope.fileXlsx = sheets;
                    });
                };
                $scope.download = function (answer, wbout) {

                    function s2ab(s) {
                        if (s == undefined) s = '';
                        var buf = new ArrayBuffer(s.length);
                        var view = new Uint8Array(buf);
                        for (var i = 0; i != s.length; ++i) {
                            view[i] = s.charCodeAt(i) & 0xFF;
                        }
                        return buf;
                    }

                    SeospiderService.analysisStats($stateParams.sessionId).then(function (res) {
                        var url = res.url;
                        if (res.url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gim)) {
                            url = new URL(res.url).hostname;
                        }
                        url = url.replace(/\./g, "-");
                        var date = new Date();
                        if ((date.getMonth() + 1) == 10 || (date.getMonth() + 1) == 11 || (date.getMonth() + 1) == 12) {
                            date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
                        } else {
                            date = date.getDate() + "-0" + (date.getMonth() + 1) + "-" + date.getFullYear();
                        }
                        var fileName = $scope.categories.categoryState.replace('app.seospider.exam.analysis.', '') + "-" +
                            url + "-" + date;

                        saveAs(new Blob([s2ab(wbout)], {
                            type: "application/octet-stream"
                        }), fileName + ".xlsx");
                        TrackEventService.event(
                            'Suite Usages',
                            'Export CSV & XLSX', 'SEO Spider');
                        TrackEventService.gaV2Event(
                            'export', {
                                tool: "SEO Spider",
                                type: "xlsx"
                            });

                    });

                    $mdDialog.hide(answer);
                };
                $scope.toggle = function (item, list) {
                    var idx = list.indexOf(item);
                    if (idx > -1) {
                        list.splice(idx, 1);
                    } else {
                        list.push(item);
                    }
                };
                $scope.exists = function (item, list) {
                    return list.indexOf(item) > -1;
                };
            }

            dialogController.$inject = ['$scope', '$mdDialog', 'categories', 'SeospiderExportService', '$rootScope', 'SeospiderService', 'TrackEventService'];
        }
    ]
};

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/languages/missing/missing.controller',
],function(
    angular,
    LanguagesMissCtrl
){
    var app=angular.module('app.seospider.exam.analysis.languages.missing', []);
    app.controller("LanguagesMissCtrl",LanguagesMissCtrl);
    return app;
});
/**
 * @ngdoc overview
 * @name pitchGround.service:PitchGroundService
 */
'use strict';
define(['app'], function (app) {
    app.service('PitchGroundService', PitchGroundService);
    PitchGroundService.$inject = ['$http', '__env'];

    function PitchGroundService($http, __env) {
        return {
            redeem: redeem,
        };

        function redeem(form) {
            return $http.post(__env.apiGateway + '/api/v1/pitchground/redeem', form)
        }
    }
});

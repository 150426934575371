'use strict';
define(['angular'], function (ng) {
    function Controller($scope) {
        var vm = $scope;
        vm.tabs = {
            categoryTitle: 'seospider.pages',
            categoryState: 'app.seospider.exam.analysis.page',
            fields: [
                {label: 'seospider.title.all', icon: 'icon icon-list', uiSref: '.all'},
                {label: 'seospider.title.duplicate', icon: 'icon icon-docs', uiSref: '.duplicated'},
            ]
        };
    }

    Controller.$inject = ['$scope'];
    return Controller;
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.keywordlistmanager", {
                abstract: true,
                url: "/keyword-list-manager",
                templateUrl: './app/modules/keywordlistmanager/keywordlistmanager.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.keywordlistmanager.landing", {
                url: "",
                controller: 'KeywordListManagerLandingCtrl',
                templateUrl: './app/modules/keywordlistmanager/landing/landing.html',
                resolve: {
                    lists: ['$stateParams', '$state', 'KeywordListService', 'AuthService',
                        function ($stateParams, $state, KeywordListService, AuthService) {
                            if(!AuthService.isAuthenticated()) return false;
                            return KeywordListService.getAllKeywordList()
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.rows || !res.data.rows.length) {
                                        return;
                                    }
                                    $state.go('app.keywordlistmanager.list');
                                })
                                .catch(function (err) {
                                    console.log(err);
                                });
                        }],
                },
            })
            .state("app.keywordlistmanager.list", {
                url: "/list",
                controller: 'KeywordListManagerListCtrl',
                templateUrl: './app/modules/keywordlistmanager/list/list.html',
                access: {restricted: true},
                resolve: {
                    listsLimit: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var listsLimit = 1;
                                if (res && res.data && res.data.limits && res.data.limits.length) {
                                    angular.forEach(res.data.limits, function (service) {
                                        if (service.alias === 'keyword-list-manager-lists') {
                                            listsLimit = service.qtyAllowed;
                                        }
                                    });
                                }
                                return listsLimit;
                            })
                            .catch(function (err) {
                                console.log(err);
                                $state.go('app.keywordlistmanager.landing');
                            });
                    }],
                    lists: ['$stateParams', '$state', 'KeywordListService',
                        function ($stateParams, $state, KeywordListService) {
                            return KeywordListService.getAllKeywordList()
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.rows || !res.data.rows.length) {
                                        $state.go('app.keywordlistmanager.landing');
                                        return;
                                    }
                                    return res.data;
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    $state.go('app.keywordlistmanager.landing');
                                });
                        }],
                },
            })
            .state("app.keywordlistmanager.keywordlist", {
                url: "/list/:keywordListId",
                controller: 'KeywordListCtrl',
                templateUrl: './app/modules/keywordlistmanager/keywordlist/keywordlist.html',
                access: {restricted: true},
                resolve: {
                    keywordsLimit: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var keywordsLimit = 1000;
                                if (res && res.data && res.data.limits && res.data.limits.length) {
                                    angular.forEach(res.data.limits, function (service) {
                                        if (service.alias === 'keyword-list-manager-queries') {
                                            keywordsLimit = service.qtyAllowed;
                                        }
                                    });
                                }
                                return keywordsLimit;
                            })
                            .catch(function (err) {
                                console.log(err);
                                $state.go('app.keywordlistmanager.landing');
                            });
                    }],
                    keywordList: ['KeywordListService', '$stateParams', '$state',
                        function (KeywordListService, $stateParams, $state) {
                            return KeywordListService.getOneKeywordList($stateParams.keywordListId)
                                .then(function (res) {
                                    if (!res || !res.data) {
                                        $state.go('app.keywordlistmanager.landing');
                                        return;
                                    }
                                    return res.data;
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    $state.go('app.keywordlistmanager.landing');
                                });
                        }],
                },
            })
            .state("app.keywordlistmanager.wizard", {
                url: "/wizard/:keywordListId/:step",
                controller: 'KeywordListManagerWizardCtrl',
                templateUrl: './app/modules/keywordlistmanager/wizard/wizard.html',
                access: {restricted: true},
                resolve: {
                    keywordList: ['$stateParams', '$state', 'KeywordListService',
                        function ($stateParams, $state, KeywordListService) {
                            return KeywordListService.getOneKeywordList($stateParams.keywordListId)
                                .then(function (res) {
                                    if (!res || !res.data) {
                                        $state.go('app.keywordlistmanager.landing');
                                        return;
                                    }

                                    return res.data;
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    $state.go('app.keywordlistmanager.landing');
                                });
                        }],
                },
            })
            .state("app.keywordlistmanager.shared", {
                url: "/shared/:token",
                controller: 'KeywordListManagerSharedCtrl',
                templateUrl: './app/modules/keywordlistmanager/shared/shared.html',
                resolve: {
                    userInfo: ['UserService', '$state', function (UserService) {
                        return UserService.limits()
                            .then(function (res) {
                                return res.data;
                            })
                            .catch(function (err) {
                                return null;
                            });
                    }],
                    keywordList: ['$stateParams', '$state', 'KeywordListService',
                        function ($stateParams, $state, KeywordListService) {
                            return KeywordListService.getSharedKeywordList($stateParams.token)
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.queries ||
                                        !res.data.queries.rows || !res.data.queries.rows.length) {
                                        $state.go('app.keywordlistmanager.landing');
                                        return;
                                    }

                                    return res.data;
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    $state.go('app.keywordlistmanager.landing');
                                });
                        }],
                },
                params: {
                    token: {
                        value: null,
                        squash: true
                    }
                }
            })
    }

    config.$inject = ['$stateProvider'];
    return config;
});

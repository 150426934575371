'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/leadgeneration/components/leadgeneration-preview/leadgeneration-preview.html',
        bindings: {
            form: '<',
            isLoading: '<',
        },
        controller: ['$scope', '$mdDialog', '$state', function ($scope, $mdDialog, $state) {
            $scope.showRenameAlert = showRenameAlert;
            $scope.showDropAlert = showDropAlert;

            $scope.$watch('$ctrl.form', function (form) {
                $scope.form = form;
                var labels = [];
                var data = [];

                if ($scope.form) {
                    for (var i = 0; i < $scope.form.trend.length; i++) {
                        data.push($scope.form.trend[i].total);
                        labels.push($scope.form.trend[i].acquiredAt);
                    }
                }

                setGraph({
                    labels: labels.reverse(),
                    data: data.reverse()
                });
            });

            function showDropAlert(form, ev) {
                $mdDialog.show({
                    controller: 'leadGenerationDialogDeleteFormCtrl',
                    templateUrl: "app/modules/leadgeneration/components/dialogs/delete-form/delete-form.html",
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    parent: angular.element(document.body),
                    locals: {
                        form: form
                    }
                }).then(function () {
                    $state.reload();
                }, function () {
                    $scope.status = 'You cancelled the dialog.';
                });
            }

            function showRenameAlert(form, ev) {
                $mdDialog.show({
                    controller: 'leadGenerationDialogRenameFormCtrl',
                    templateUrl: "app/modules/leadgeneration/components/dialogs/rename-form/rename-form.html",
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    parent: angular.element(document.body),
                    locals: {
                        form: form
                    }
                }).then(function (answer) {
                    $state.reload();
                }, function () {
                    $scope.status = 'You renamed the dialog.';
                });
            }

            function setGraph(params) {
                $scope.line = {
                    labels: params.labels,
                    data: [params.data],
                    onClick: function () {
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                display: false //this will remove all the x-axis grid lines
                            }],
                            yAxes: [{
                                display: false //this will remove all the x-axis grid lines
                            }]
                        }
                    },
                    datasetOverride: [{
                        label: "Leads",
                        borderWidth: 2,
                        pointBorderWidth: 1,
                        //lineTension:0,
                        pointBackgroundColor: "rgba(255, 0, 102,1)",
                        hoverBackgroundColor: "rgba(255, 0, 102,0.4)",
                        hoverBorderColor: "rgba(255, 0, 102,1)",
                        backgroundColor: "rgba(255, 0, 102,0.1)",
                        borderColor: "rgba(255, 0, 102,1)",
                        type: 'line'
                    }]
                };

            }

        }]
    };
});

/**
 * @ngdoc overview
 * @name subscription.service:SubscriptionService
 */
'use strict';
define(['app', 'angular'], function (app, ng) {
    app.service('SubscriptionService', SubscriptionService);

    SubscriptionService.$inject = ['API', '$http', '$q', '__env'];

    /**
     * Subscription Service
     * @namespace SubscriptionService
     */
    function SubscriptionService(API, $http, $q, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.subscriptionsAPI;

        return {
            checkStripeCard: checkStripeCard,
            info: info,
            plans: plans,
            items: items,
            limits: limits,
            credits: credits,
            cancel: cancel,
            setRenewal: setRenewal,
            feedBack: feedBack
        };

        function info() {
            return API.get(apiPath);
        }

        function items(subscriptionId) {
            var deferred = $q.defer();
            $http.get(__env.apiGateway + '/api/v1/subscriptions/items/' + subscriptionId)
                .then(function (res) {
                    deferred.resolve(res);
                }).catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        /**
         * @name checkStripeCard
         * @desc Get the default Stripe card id if user is logged and used Stripe checkout
         * @memberOf SubscriptionService
         * @returns {any}
         */
        function checkStripeCard() {
            var deferred = $q.defer();
            $http.get(apiPath + '/default-stripe-card')
                .then(function (result) {
                    deferred.resolve(result.data);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(null);
                })
            return deferred.promise;
        }

        /**
         * @name plans
         * @desc return list of plan
         * @returns {Object}
         * @memberOf SubscriptionService
         */
        function plans() {
            return API.get(apiPath + '/plans');
        }

        function limits() {
            var deferred = $q.defer();
            $http.get(__env.apiGateway + '/api/v1/subscriptions/limits')
                .then(function (res) {
                    deferred.resolve(res);
                }).catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        /**
         * @name credits
         * @desc return credits of user
         * @returns {Object}
         * @memberOf SubscriptionService
         */
        function credits() {
            return API.get(apiPath + '/credits');
        }

        /**
         * @name cancel
         * @desc cancel a subscription
         * @param {String} subscriptionId
         * @returns {Object}
         * @memberOf SubscriptionService
         *
         * TODO: verify where and when it is called before change it
         */
        function cancel(subscriptionId) {
            var deferred = $q.defer();
            $http.delete(__env.apiGateway + '/api/v1/subscriptions/' + subscriptionId).then(function (res) {
                deferred.resolve(res);
            }).catch(function (err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        /**
         * @name setRenewal
         * @desc DeActivated Subscription: Stripe or PayPal
         * @param {Object} params
         * @returns {Json}
         * @memberOf SubscriptionService
         */
        function setRenewal(params) {
            return API.put(apiPath + '/renewal', params);
        }

        /**
         * @name feedback
         * @desc Send Feedback to a Customer Care
         * @param {Object} params
         * @returns {Json}
         * @memberOf SubscriptionService
         */

        function feedBack(params) {
            return API.post(apiPath + '/feedback', {
                image: params.image,
                feedback: params.feedback,
                object: params.object
            });
        }
    }
});

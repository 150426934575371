define(['app','jquery'],function(app,$) {

    app.directive('preventBackHistoryOnScroll', preventBackHistoryOnScroll);
    function preventBackHistoryOnScroll() {
        return function (scope, elm, attr) {
            $(document).ready(function(){
                $(window).on('touchmove',function(e){e.preventDefault();});
            });

        }
    }
});

'use strict';
define(['app'], function (app) {
    app.service('TimezoneService', TimezoneService);
    TimezoneService.$inject = [];

    function TimezoneService() {
        var self = this;

        self.timeZoneOptions = [
            {
               "offset": -12,
               "text": "(GMT -12:00) Eniwetok, Kwajalein"
            },
            {
               "offset": -11,
               "text": "(GMT -11:00) Midway Island, Samoa"
            },
            {
               "offset": -10,
               "text": "(GMT -10:00) Hawaii"
            },
            {
               "offset": -9,
               "text": "(GMT -9:00) Alaska"
            },
            {
               "offset": -8,
               "text": "(GMT -8:00) Pacific Time (US & Canada)"
            },
            {
               "offset": -7,
               "text": "(GMT -7:00) Mountain Time (US & Canada)"
            },
            {
               "offset": -6,
               "text": "(GMT -6:00) Central Time (US & Canada), Mexico City"
            },
            {
               "offset": -5,
               "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
            },
            {
               "offset": -4,
               "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
            },
            {
               "offset": -3.5,
               "text": "(GMT -3:30) Newfoundland"
            },
            {
               "offset": -3,
               "text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
            },
            {
               "offset": -2,
               "text": "(GMT -2:00) Mid-Atlantic"
            },
            {
               "offset": -1,
               "text": "(GMT -1:00) Azores, Cape Verde Islands"
            },
            {
               "offset": 0,
               "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca"
            },
            {
               "offset": 1,
               "text": "(GMT +1:00) Rome, Brussels, Copenhagen, Madrid, Paris"
            },
            {
               "offset": 2,
               "text": "(GMT +2:00) Kaliningrad, South Africa"
            },
            {
               "offset": 3,
               "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
            },
            {
               "offset": 3.5,
               "text": "(GMT +3:30) Tehran"
            },
            {
               "offset": 4,
               "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
            },
            {
               "offset": 4.5,
               "text": "(GMT +4:30) Kabul"
            },
            {
               "offset": 5,
               "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
            },
            {
               "offset": 5.5,
               "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
            },
            {
               "offset": 5.75,
               "text": "(GMT +5:45) Kathmandu"
            },
            {
               "offset": 6,
               "text": "(GMT +6:00) Almaty, Dhaka, Colombo"
            },
            {
               "offset": 7,
               "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta"
            },
            {
               "offset": 8,
               "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
            },
            {
               "offset": 9,
               "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
            },
            {
               "offset": 9.5,
               "text": "(GMT +9:30) Adelaide, Darwin"
            },
            {
               "offset": 10,
               "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
            },
            {
               "offset": 11,
               "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
            },
            {
               "offset": 12,
               "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
            }
         ];

         self.timeOptions = [
            {
                "value": 1,
                "text24HFormat": "01:00",
                "text12HFormat": "01:00 AM",
            },
            {
                "value": 2,
                "text24HFormat": "02:00",
                "text12HFormat": "02:00 AM",
            },
            {
                "value": 3,
                "text24HFormat": "03:00",
                "text12HFormat": "03:00 AM",
            },
            {
                "value": 4,
                "text24HFormat": "04:00",
                "text12HFormat": "04:00 AM",
            },
            {
                "value": 5,
                "text24HFormat": "05:00",
                "text12HFormat": "05:00 AM",
            },
            {
                "value": 6,
                "text24HFormat": "06:00",
                "text12HFormat": "06:00 AM",
            },
            {
                "value": 7,
                "text24HFormat": "07:00",
                "text12HFormat": "07:00 AM",
            },
            {
                "value": 8,
                "text24HFormat": "08:00",
                "text12HFormat": "08:00 AM",
            },
            {
                "value": 9,
                "text24HFormat": "09:00",
                "text12HFormat": "09:00 AM",
            },
            {
                "value": 10,
                "text24HFormat": "10:00",
                "text12HFormat": "10:00 AM",
            },
            {
                "value": 11,
                "text24HFormat": "11:00",
                "text12HFormat": "11:00 AM",
            },
            {
                "value": 12,
                "text24HFormat": "12:00",
                "text12HFormat": "12:00 PM",
            },
            {
                "value": 13,
                "text24HFormat": "13:00",
                "text12HFormat": "01:00 PM",
            },
            {
                "value": 14,
                "text24HFormat": "14:00",
                "text12HFormat": "02:00 PM",
            },
            {
                "value": 15,
                "text24HFormat": "15:00",
                "text12HFormat": "03:00 PM",
            },
            {
                "value": 16,
                "text24HFormat": "16:00",
                "text12HFormat": "04:00 PM",
            },
            {
                "value": 17,
                "text24HFormat": "17:00",
                "text12HFormat": "05:00 PM",
            },
            {
                "value": 18,
                "text24HFormat": "18:00",
                "text12HFormat": "06:00 PM",
            },
            {
                "value": 19,
                "text24HFormat": "19:00",
                "text12HFormat": "07:00 PM",
            },
            {
                "value": 20,
                "text24HFormat": "20:00",
                "text12HFormat": "08:00 PM",
            },
            {
                "value": 21,
                "text24HFormat": "21:00",
                "text12HFormat": "09:00 PM",
            },
            {
                "value": 22,
                "text24HFormat": "22:00",
                "text12HFormat": "10:00 PM",
            },
            {
                "value": 23,
                "text24HFormat": "23:00",
                "text12HFormat": "11:00 PM",
            },
            {
                "value": 24,
                "text24HFormat": "00:00",
                "text12HFormat": "12:00 AM",
            },
            
        ];

        return {
            getAllTimezoneOptions: getAllTimezoneOptions,
            getAllTimeOptions: getAllTimeOptions
        };

        /**
         * @returns {*[]|*}
         */
        function getAllTimezoneOptions() {
            return self.timeZoneOptions;
        }

        /**
         * @returns {*[]|*}
         */
        function getAllTimeOptions() {
            return self.timeOptions;
        }

    }
});

'use strict';
define([],function(){
    var MAX_KEYWORDS_ALLOWED=5;
    controller.$inject = ['$scope','$sce','CountriesService'];
    function controller($scope,$sce,Countries) {
       var ctrl=this;
        ctrl.allCountry=Countries.all();
        ctrl.allTime= [{name:'pastHour',code:"now 1-H"},
            {name:'past4Hours',code:"now 4-H"},
            {name:'pastDay',code:"now 1-d"},
            {name:'past7Days',code:"now 7-d"},
            {name:'past30Days',code:"today 1-m"},
            {name:'past90Days',code:"today 3-m"},
            {name:'past12Months',code:"today 12-m"},
            {name:'past5Years',code:"today 5-y"},
            {name:'2004',code:"all"}
        ];
        ctrl.allCategory=[
            {name:'All categories',code:0},
            {name:'Arts & Entertainment',code:3},
            {name:'Autos & Vehicles',code:47},
            {name:'Beauty & Fitness',code:44},
            {name:'Books & Literature',code:22},
            {name:'Business & Industrial',code:12},
            {name:'Computers & Eletronics',code:5},
            {name:'Finance',code:7},
            {name:'Food & Drink',code:71},
            {name:'Games',code:8},
            {name:'Health',code:45},
            {name:'Hobbies & Leisure',code:65},
            {name:'Home & Garden',code:11},
            {name:'Internet & Telecom',code:13},
            {name:'Jobs & Education',code:958},
            {name:'Law & Government',code:19},
            {name:'News',code:16},
            {name:'Online Communities',code:299},
            {name:'People & Society',code:14},
            {name:'Pets & Animals',code:66},
            {name:'Real Estate',code:29},
            {name:'Reference',code:533},
            {name:'Science',code:174},
            {name:'Shopping',code:18},
            {name:'Sports',code:20},
            {name:'Travel',code:67}
        ];
        ctrl.allProperty=[
            {name:'webSearch',code:''},
            {name:'imageSearch',code:'images'},
            {name:'newsSearch',code:'news'},
            {name:'googleShopping',code:'froogle'},
            {name:'youTubeSearch',code:'youtube'}
        ];
        ctrl.setCountry=setCountry;
        ctrl.setTime=setTime;
        ctrl.setCategory=setCategory;
        ctrl.setProperty=setProperty;
        ctrl.setSelectedKeyword=setSelectedKeyword;
        ctrl.setLastSelected=setLastSelected;
        ctrl.setDisabled=setDisabled;
        $scope.$watchCollection('$ctrl.keywords', function (keywords) {
            ctrl.keywords=keywords;
            updateGoogleTrends();
        });
        $scope.$watchCollection('$ctrl.country', function (country) {
            ctrl.country=country||'';
            ctrl.countryChoosen=ctrl.allCountry[findQueryInData(ctrl.allCountry,ctrl.country)];
            updateGoogleTrends();
        });
        $scope.$watchCollection('$ctrl.time', function (time) {
            ctrl.time=time||'today 12-m';
            ctrl.timeChoosen=ctrl.allTime[findQueryInData(ctrl.allTime,ctrl.time)];
            updateGoogleTrends();
        });
        $scope.$watchCollection('$ctrl.category', function (category) {
            ctrl.category=category||0;
            ctrl.categoryChoosen=ctrl.allCategory[findQueryInData(ctrl.allCategory,ctrl.category)];
            updateGoogleTrends();
        });
        $scope.$watchCollection('$ctrl.property', function (property) {
            ctrl.property=property||'';
            ctrl.propertyChoosen=ctrl.allProperty[findQueryInData(ctrl.allProperty,ctrl.property)];
            updateGoogleTrends();
        });
        $scope.$watch('$ctrl.lang', function (lang) {
            ctrl.lang=lang||'en';
            updateGoogleTrends();
        });
        $scope.$watchCollection('$ctrl.toolbar', function (toolbar) {
            ctrl.toolbar=toolbar||false;
        });
        function setCountry() {
            ctrl.country=ctrl.countryChoosen.code;
        }
        function setTime() {
            ctrl.time=ctrl.timeChoosen.code;
        }
        function setCategory(){
            ctrl.category=ctrl.categoryChoosen.code;
        }
        function setProperty() {
            ctrl.property=ctrl.propertyChoosen.code;
        }
        var lastSelected;
        function setLastSelected(value){
            lastSelected=value;
        }
        function setDisabled(value){
            if(ctrl.keywordChoosen>=5&&ctrl.keywordChoosen.indexOf(value)==-1){
                return true;
            }
            else{
                return false;
            }
        }
        function setSelectedKeyword(){
            if(!ctrl.keywordChoosen.length) {
                ctrl.keywordChoosen.push(lastSelected);
            }
            if(ctrl.keywordChoosen.length>5){
                ctrl.keywordChoosen.pop();
            }
            updateGoogleTrends(ctrl.keywordChoosen);
        }
        function findQueryInData(data,query) {
            return data.findIndex(function (ele) {
                return decodeURIComponent(ele.code).toLowerCase()
                    == decodeURIComponent(query).toLowerCase();
            });
        }
        function updateGoogleTrends(_selectedKeywords) {
            var keywordArray=[];
            var keywordComparison=[];
            if(_selectedKeywords!=undefined&&_selectedKeywords.length!=0){
                keywordArray=_selectedKeywords;
            }
            else{
                angular.forEach(ctrl.keywords, function(item) {
                    var key = item;
                    if(keywordArray.indexOf(key) == -1) {
                        keywordArray.push(key);
                    }
                });
            }
            if(ctrl.keywords!=undefined){
                for(var i=0;i<keywordArray.length&&i<MAX_KEYWORDS_ALLOWED;i++){
                    keywordComparison.push({keyword: keywordArray[i], geo: ctrl.country, time: ctrl.time});
                }
                var resJson = {
                    comparisonItem: keywordComparison,
                    category: ctrl.category,
                    property: ctrl.property
                };
                resJson = JSON.stringify(resJson);
                var encodeResJson = encodeURIComponent(resJson);
                ctrl.urlGoogleTrends = "https://www.google.com/trends/embed/explore/TIMESERIES?req=" +
                    encodeResJson + "&tz=-60&hl="+ctrl.lang;
                ctrl.urlGoogleTrends = $sce.trustAsResourceUrl(ctrl.urlGoogleTrends);
            }else ctrl.urlGoogleTrends=false;
        }
    }
    return {
        templateUrl: 'app/components/st-google-trends/st-google-trends.html',
        bindings: {
            keywords:'<',
            country:'<',
            time:'<',
            category:'<',
            property:'<',
            lang:'<',
            toolbar:'<'
        },
        controller: controller
    };
});
'use strict';
define(['angular'], function (ng) {
    function Controller($scope, $window, $mdDialog, WriterAssistantService, articleId, TrackEventService) {
        var vm = $scope;

        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;
        vm.deleteLoading = false;

        function hide() {
            $mdDialog.hide();
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function answer() {
            vm.deleteLoading = true;
            WriterAssistantService.deleteArticle(articleId)
                .then(function (result) {
                    vm.deleteLoading = false;
                    TrackEventService.event(
                        'Suite Usages',
                        'SEO Editor - Article Deleted');
                    TrackEventService.gaV2Event(
                        'article_delete', {
                            tool: "SEO Editor"
                        });
                    $mdDialog.hide();
                })
                .catch(function (err) {
                    vm.deleteLoading = false;
                    $window.console.log(err);
                });
        }
    }

    Controller.$inject = ['$scope', '$window', '$mdDialog', 'WriterAssistantService', 'articleId', 'TrackEventService'];
    return Controller;
});

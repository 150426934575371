'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/languages/x-default-missing/x-default-missing.controller',
],function(
    angular,
    LanguagesXDefaultMissCtrl
){
    var app=angular.module('app.seospider.exam.analysis.languages.noXDefault', []);
    app.controller("LanguagesXDefaultMissCtrl",LanguagesXDefaultMissCtrl);
    return app;
});
'use strict';
define(['angular','jquery'], function (ng,$) {
    function SEOCheckerResultsCtrl(__env, FavIconService,$rootScope, stLimitMessage, $scope, $window, $state, $timeout, $stateParams, SeocheckerService, ReportPdfService, userInfo, LanguageService, TrackEventService, $mdMedia, SubscriptionService) {
        var vm = $scope;
        vm.$mdMedia = $mdMedia;
        vm.getFavIcon = FavIconService.get;
        vm.isAuthenticated = userInfo && userInfo.account && userInfo.account !== 'offline';
        vm.canDoTrial = userInfo.canDoTrial;
        vm.trialPeriodDays = __env.trialPeriodDays || 7;
        vm.typeOfAnalysis = 'seo-checker';
        vm.badUrl = false;
        vm.searchInputIsFocused = false;
        vm.loading = true;
        vm.typeformEmbedIsVisible = false;
        var userHasPartnerProgramPlan = false;

        $('.tf-v1-sidetab-button').css('display', 'none');
        vm.messages = {
            messageWhenEmptyDataTitle: null, messageWhenEmptyDataSubtitle: null, messageWhenEmptyDataIcon: null, messageWhenEmptyDataCta: null
        };
        vm.emailUser = userInfo.email;
        vm.userInfo = userInfo;
        vm.currentUser = userInfo;
        vm.$state = $state;

        //In this case we pass the only code language to google for API service
        var languageCodeSaveOnStorage = LanguageService.getLanguageCodeSaveOnStorage();

        var userData = {};

        if ($window.localStorage.getItem('ngStorage-currentUser')) {
            userData = JSON.parse($window.localStorage.getItem('ngStorage-currentUser'));
        }

        var userIsOffline = userInfo.account == 'offline';
        var userIsDigitalAgency = userData && userData.businessType == 'Digital Agency';
        var analysis = {
            url: null
        };

        try {
            analysis.url = atob(decodeURIComponent($stateParams.url));
        } catch (err) {
            analysis.url = '';
        }

        $scope.analysis = analysis;
        if ($scope.analysis.url.trim() == "") {
            $state.go('app.seochecker.landing');
        }

        $scope.lastQuery = ng.copy($scope.analysis.url);
        $scope.urlInAction = encodeURIComponent(btoa($scope.analysis.url));

        var correctUrlRegex = new RegExp(/^((http:\/\/|https:\/\/)?(www\.|^\.)?((([\-|\wæÆØøÅå|\d]+)\.)+([a-zA-ZæÆØøÅå]{1,10}))(\/?(([a-zA-ZZæÆØøÅå0-9_@]+\.([a-zA-ZZæÆØøÅå0-9]{3,6}))?(\??[-A-Za-zæÆØøÅå0-9+:&@#\/%=~'_\.?|]+))){0,1})$/gim);

        checkIfUserHasPartnerProgramPlan();

        function checkIfUserHasPartnerProgramPlan() {
            if (userIsOffline) {
                return;
            }

            SubscriptionService.plans()
                .then(function (res) {
                    var subscriptionList = res.data;
                    var subscriptionActive = [];
                    var planPartnerProgram = [];
                    subscriptionList.forEach(function (subscription) {
                        if (subscription.status === 'active') {
                            subscriptionActive.push(subscription);
                        }
                    });

                    if (subscriptionActive.length) {
                        subscriptionActive.forEach(function (subscription) {
                            subscription.plans.forEach(function (plan) {
                                //Control plan
                                if (plan.alias.indexOf('partner-program') > 0) {
                                    planPartnerProgram.push(plan);
                                }
                            });
                        });
                    }

                    if (planPartnerProgram.length) {
                        userHasPartnerProgramPlan = true;
                    }

                }).catch(function (err) {
                console.log(err);
            });
        }

        $scope.newAnalysis = function () {

            if (!$scope.analysis || !$scope.analysis.url) {
                return;
            }

            if ($scope.typeOfAnalysis === 'seo-spider') {
                $state.go('app.project.wizard', {url:$scope.analysis.url, from: 'seo-checker-landing', tool: 'seo-spider', step: 'create-project'});
                return;
            }


            if ($scope.lastQuery === $scope.analysis.url) {
                $scope.refresh();
                return;
            }

            $state.go('app.seochecker.results.overview', {
                url: encodeURIComponent(btoa($scope.analysis.url)), 'open-dialog': null
            }, {
                notify: true
            });

            $scope.lastQuery = ng.copy($scope.analysis.url);
        };

        $scope.refresh = function () {
            window.location.reload();
        };

        $scope.seochecker = function () {
            var isValidSearch = ($scope.analysis.url != undefined && $scope.analysis.url.length != 0 && $scope.analysis.url.match(correctUrlRegex));

            if (isValidSearch) $state.go('app.seochecker.results.overview', {
                url: encodeURIComponent(btoa($scope.analysis.url))
            }); else $state.go('app.seochecker.landing');
        };

        seocheckerAudit();

        function seocheckerAudit() {
            $('.tf-v1-sidetab-button').css('display', 'none');
            $scope.reportConfiguration = {
                config: {
                    version: "1.0", params: {
                        url: $scope.analysis.url.trim(), locale: languageCodeSaveOnStorage, email: {
                            to: userData ? userData.email : ''
                        }, categories: ReportPdfService.getCategories({
                            type: 'seo_checker', score: true, preview: true, suggests: true, problems: true
                        })
                    }, default: true,
                }, type: "seo_checker", data: {}
            };
            setMessages();

            SeocheckerService.audit($scope.analysis.url)
                .then(function (res) {
                    if (res.audit.error) {
                        $scope.badUrl = true;
                    }

                    var audit = {
                        base: {}, content: {}, social: {}, categories: {},
                    };
                    if (res.audit) {
                        audit = res.audit;
                    }

                    var performance = {
                        desktop: {}, mobile: {},
                    };
                    if (res.performance && res.performance.desktop && res.performance.mobile) {
                        performance = res.performance;
                    }

                    var overview = {};
                    if (res.overview) {
                        overview = res.overview;
                    }

                    TrackEventService.event('Suite Usages', 'SEO Checker - Audit');
                    TrackEventService.gaV2Event('seo_audit', {
                        type: "URL", tool: "SEO Checker"
                    });

                    $scope.loading = false;

                    if (!$scope.loading && (userIsOffline || (languageCodeSaveOnStorage == 'it' && !userHasPartnerProgramPlan && !userIsDigitalAgency))) {
                        vm.typeformEmbedIsVisible = true;
                        //bolded typeform embed title
                        $timeout(function () {
                            $('.tf-v1-sidetab-button-text').css('fontWeight', 900);
                        }, 400);
                    }

                    // Set all results from exams
                    $scope.base = audit.base;
                    $scope.content = audit.content;
                    $scope.social = audit.social;
                    $scope.overview = overview;
                    $scope.performance = performance;

                    // Calculate and set score on "$scope.score" variable
                    setGlobalScore();

                    // Set categories to format all tabs
                    if (audit.categories.base) {
                        $scope.baseCategories = audit.categories.base;
                    }

                    if (audit.categories.content) {
                        $scope.contentCategories = audit.categories.content;
                    }

                    if ($scope.reportConfiguration) {
                        $scope.reportConfiguration.data = {
                            base: Object.assign({}, audit.base),
                            content: Object.assign({}, audit.content),
                            social: Object.assign({}, audit.social),
                            overview: Object.assign({}, audit.overview),
                            performance: performance,
                            score: $scope.score,
                        }
                    }

                    if ($stateParams['open-dialog']) {
                        $scope.openReportDialog();
                    }
                })
                .catch(function (err) {
                    $scope.badUrl = true;
                    if (err.status === 429 || err.status === 426) {
                        setMessages(stLimitMessage.getFromError(err, userInfo).title, stLimitMessage.getFromError(err, userInfo).subtitle, stLimitMessage.getFromError(err, userInfo).icon, stLimitMessage.getFromError(err, userInfo).cta,);
                    }

                });
        }

        $scope.openReportDialog = function () {
            $timeout(function () {
                $(document.querySelector('#report-pdf-button')).click();
            });
        };

        function setMessages(title, subtitle, icon, cta) {
            $scope.messages.messageWhenEmptyDataTitle = title || null;
            $scope.messages.messageWhenEmptyDataSubtitle = subtitle || null;
            $scope.messages.messageWhenEmptyDataIcon = icon || null;
            $scope.messages.messageWhenEmptyDataCta = cta || null;
        }

        $rootScope.$on('languageChanged', function (event, lang) {
            var data = $scope.reportConfiguration.data;
            $scope.reportConfiguration = {
                "config": {
                    "version": "1.0", "params": {
                        "url": $scope.analysis.url.trim(), "locale": languageCodeSaveOnStorage, "email": {
                            "to": userData ? userData.email : ''
                        }, "categories": ReportPdfService.getCategories({
                            type: 'seo_checker', score: true, preview: true, suggests: true, problems: true
                        })
                    }, "default": true
                }, "type": "seo_checker", data: data,
            };
        });

        function setGlobalScore() {
            var sum = 0;
            sum += Number($scope.base.score || 0);
            sum += Number($scope.social.score || 0);
            sum += Number($scope.content.score || 0);
            sum += Number($scope.performance.desktop.score || 0);
            sum += Number($scope.performance.mobile.score || 0);
            var examLen = 0;
            examLen += Number($scope.base.score ? 1 : 0);
            examLen += Number($scope.social.score ? 1 : 0);
            examLen += Number($scope.content.score ? 1 : 0);
            examLen += Number($scope.performance.desktop.score ? 1 : 0);
            examLen += Number($scope.performance.mobile.score ? 1 : 0);
            $scope.score = examLen > 0 ? (parseFloat((sum) / examLen)).toFixed(1) : 0;
        }

        $scope.$on('$destroy', function iVeBeenDismissed() {
            vm.typeformEmbedIsVisible = false;
            $('.tf-v1-sidetab-button').css('display', 'none');
        });

    }


    SEOCheckerResultsCtrl.$inject = ['__env', 'FavIconService','$rootScope', 'stLimitMessage', '$scope', '$window', '$state', '$timeout', '$stateParams', 'SeocheckerService', 'ReportPdfService', 'userInfo', 'LanguageService', 'TrackEventService', '$mdMedia', 'SubscriptionService'];
    return SEOCheckerResultsCtrl;
});

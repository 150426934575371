'use strict';
define([], function () {
    function IndexController($scope, canDoTrial, BetaProgramService, __env, userInfo,
                             subscriptionCredits, userCredits, isTrialing, AuthService,
                             SubscriptionService, subscriptionsInfo, UserService, LanguageService, WorkspaceService) {

        var vm = $scope;
        vm.trialPeriodDays = __env.trialPeriodDays;
        vm.canDoTrial = canDoTrial;
        vm.emailUser = userInfo.email;
        vm.isTrialing = isTrialing;
        //Set on APP Language and Country get from Storage or set from default config
        vm.lang = LanguageService.getCodeAndCountrySaveOnStorage();
        vm.subscriptionNotEnd = true;
        vm.userHasActiveSubscription = !!subscriptionCredits;
        vm.limits = subscriptionCredits || userCredits;

        vm.subscriptionsInfo = false;
        vm.maxMembers = 1;
        vm.users = [];
        vm.renewalActive = true;
        vm.isAuth = AuthService.isAuthenticated();

        vm.categoryLimits = {
            platform: {
                category: 'settings.overview.platform',
                limits: [],
                order: 1,
            },
            seoAnalysis:
                {
                    category: 'settings.overview.seo.analysis',
                    limits: [],
                    order: 2,
                },
            siteAudit:
                {
                    category: 'settings.overview.site.audit',
                    limits: [],
                    order: 3
                },
            keywordResearch:
                {
                    category: 'settings.overview.keyword.research',
                    limits: [],
                    order: 4
                },
            backlink:
                {
                    category: 'backlink',
                    limits: [],
                    order: 5
                },
            ai:
                {
                    category: 'settings.overview.ai',
                    limits: [],
                    order: 6,
                },
            positionMonitoring: {
                category: 'settings.overview.position.monitoring',
                limits: [],
                order: 7,
            },
            report:
                {
                    category: 'settings.overview.reporting',
                    limits: [],
                    order: 8,
                },
            seoAssistant:
                {
                    category: 'settings.overview.seo.assistant',
                    limits: [],
                    order: 9,
                },
            leadGeneration:
                {
                    category: 'settings.overview.lead.generation',
                    limits: [],
                    order: 10,
                },
        };

        /**
         *
         */
        function setDataForCreditsAndLimits() {
            /**
             * Su cicla per ciascuna
             * row i dati da spalmare
             * nella tabella
             */
            for (var i = 0; i < vm.limits.length; i += 1) {
                if (vm.limits[i]) {
                    vm.limits[i].qtyConsumed = vm.limits[i].qtyAllowed - vm.limits[i].qtyRemaining;
                    vm.limits[i].completedPercentage = vm.limits[i].qtyConsumed / vm.limits[i].qtyAllowed * 100;

                    /**
                     * ROW 1
                     * WorkSpace
                     */
                    if (vm.limits[i].alias === 'workspace-users-limit') {
                        vm.limits[i].textToShow = 'users';
                        /**
                         * User senza SUB
                         */
                        if (!vm.userHasActiveSubscription) {
                            // Add the owner
                            vm.limits[i].qtyConsumed = vm.limits[i].qtyConsumed + 1;
                            vm.limits[i].qtyAllowed = vm.limits[i].qtyAllowed;
                            vm.limits[i].qtyRemaining = vm.limits[i].qtyAllowed - vm.limits[i].qtyConsumed;
                            /**
                             * User con SUB
                             */
                        } else {
                            /**
                             * Solo per questa casistica si usa Users Lenght
                             * @type {number}
                             */
                            vm.limits[i].qtyConsumed = vm.users.length; // nel caso di Sub prendo da UserInfo - potrebbe aver condiviso il workspace
                            /**
                             * C'è una casistica in cui anche se
                             * si ha 1 piano si ha solo
                             * 1 utente e se si fa la somma
                             * c'è 1 utente extra
                             * cosi IF si risolve
                             * @type {number|*}
                             */
                            vm.limits[i].qtyAllowed = (vm.limits[i].qtyAllowed == 1) ? vm.limits[i].qtyAllowed : vm.limits[i].qtyAllowed + 1;
                            vm.limits[i].qtyRemaining = vm.limits[i].qtyAllowed - vm.limits[i].qtyConsumed;
                        }
                        vm.limits[i].completedPercentage = vm.limits[i].qtyConsumed / vm.limits[i].qtyAllowed * 100;
                        vm.limits[i].aliasToCheckout = 'addon-workspace-plus-5-user-annual-v1';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.limits[i].order = 1;
                        vm.categoryLimits.platform.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'seo-project-limit') {
                        vm.limits[i].textToShow = 'projects';
                        vm.limits[i].aliasToCheckout = 'addon-workspace-plus-5-user-annual-v1';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.platform.limits.push(vm.limits[i]);
                        vm.limits[i].order = 2;
                    } else if (vm.limits[i].alias === 'analysis') {
                        vm.limits[i].textToShow = 'analisysPerDay';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.seoAnalysis.limits.push(vm.limits[i]);
                        vm.limits[i].order = 1;
                    } else if (vm.limits[i].alias === 'seo-checker') {
                        vm.limits[i].textToShow = 'settings.overview.seo.on.page';
                        vm.categoryLimits.seoAnalysis.limits.push(vm.limits[i]);
                        vm.limits[i].order = 2;
                    } else if (vm.limits[i].alias === 'keyword-explorer') {
                        vm.categoryLimits.seoAnalysis.limits.push(vm.limits[i]);
                        vm.limits[i].textToShow = 'settings.overview.keyword.research';
                        vm.limits[i].order = 3;
                    } else if (vm.limits[i].alias === 'serp-report') {
                        vm.categoryLimits.seoAnalysis.limits.push(vm.limits[i]);
                        vm.limits[i].textToShow = 'settings.overview.competitor.analysis';
                        vm.limits[i].order = 4;
                    } else if (vm.limits[i].alias === 'copymetrics') {
                        vm.categoryLimits.seoAnalysis.limits.push(vm.limits[i]);
                        vm.limits[i].textToShow = 'settings.overview.seo.content.analysis';
                        vm.limits[i].order = 5;
                    } else if (vm.limits[i].alias === 'seo-spider-pages') {
                        vm.categoryLimits.siteAudit.limits.push(vm.limits[i]);
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.limits[i].textToShow = 'settings.overview.resources.scannable.per.month';
                        vm.limits[i].tooltipText = null;
                        vm.limits[i].order = 1;
                    } else if (vm.limits[i].alias === 'seo-spider-limit') {
                        vm.categoryLimits.siteAudit.limits.push(vm.limits[i]);
                        vm.limits[i].textToShow = 'settings.overview.max.scanned.pages.per.project';
                        vm.limits[i].order = 2;
                        vm.limits[i].type = 'feature_max_limit'; //force type on frontend
                    } else if (vm.limits[i].alias === 'seo-project-data-retention') {
                        vm.categoryLimits.siteAudit.limits.push(vm.limits[i]);
                        vm.limits[i].textToShow = 'settings.overview.data.retention.months';
                        vm.limits[i].order = 3;
                    } else if (vm.limits[i].alias === 'keyword-list-manager-lists') {
                        vm.limits[i].textToShow = 'settings.overview.makable.keyword.lists';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.keywordResearch.limits.push(vm.limits[i]);
                        vm.limits[i].order = 1;
                    } else if (vm.limits[i].alias === 'keyword-explorer-data') {
                        vm.limits[i].textToShow = 'settings.overview.max.results.per.keyword';
                        vm.categoryLimits.keywordResearch.limits.push(vm.limits[i]);
                        vm.limits[i].order = 2;
                    } else if (vm.limits[i].alias === 'keyword-explorer-domain-rows') {
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.limits[i].textToShow = 'settings.overview.keywordPerDomainPerMonth';
                        vm.categoryLimits.keywordResearch.limits.push(vm.limits[i]);
                        vm.limits[i].order = 4;
                    } else if (vm.limits[i].alias === 'rank-tracker-frequency') {
                        vm.limits[i].textToShow = 'settings.overview.update.frequency';
                        vm.categoryLimits.positionMonitoring.limits.push(vm.limits[i]);
                        vm.limits[i].order = 1;
                    } else if (vm.limits[i].alias === 'rank-tracker-keywords') {
                        vm.limits[i].textToShow = 'settings.overview.tracked.keyword';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.positionMonitoring.limits.push(vm.limits[i]);
                        vm.limits[i].order = 2;
                    } else if (vm.limits[i].alias === 'export-csv-xlsx') {
                        vm.limits[i].textToShow = 'settings.overview.export.csv.per.day';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.limits[i].order = 1;
                        vm.categoryLimits.report.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'seo-report-export') {
                        vm.limits[i].textToShow = 'settings.overview.downloadable.pdf.reports.per.month';
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.limits[i].order = 2;
                        vm.categoryLimits.report.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'seo-report-white-label') {
                        vm.limits[i].textToShow = 'settings.overview.whitelabel.seo.reports';
                        vm.limits[i].order = 3;
                        vm.categoryLimits.report.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'seo-editor') {
                        vm.limits[i].textToShow = 'settings.overview.seo.writing.assistant.tool';
                        vm.categoryLimits.seoAssistant.limits.push(vm.limits[i]);
                        vm.limits[i].order = 1;
                    } else if (vm.limits[i].alias === 'lead-gen-tool-leads-unlock') {
                        vm.limits[i].textToShow = 'settings.overview.leads.included.per.month';
                        vm.limits[i].order = 1;
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.leadGeneration.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'lead-gen-tool-white-label-embed') {
                        vm.limits[i].textToShow = 'leadgeneration.seo.report.with.your.brand';
                        vm.limits[i].order = 2;
                        vm.categoryLimits.leadGeneration.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'backlink-checker-rows') {
                        vm.limits[i].textToShow = 'backlinkRowsPerMonth';
                        vm.limits[i].order = 1;
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.backlink.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'backlink-checker-requests') {
                        vm.limits[i].textToShow = 'analisysPerMonth';
                        vm.limits[i].order = 2;
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.backlink.limits.push(vm.limits[i]);
                    } else if (vm.limits[i].alias === 'ai-credits') {
                        vm.limits[i].textToShow = 'settings.overview.ai-credits';
                        vm.limits[i].order = 1;
                        vm.limits[i].showProgressBar = vm.limits[i].qtyAllowed > 0;
                        vm.categoryLimits.ai.limits.push(vm.limits[i]);
                    }
                }
            }

            // remove empty categories
            for (var prop in vm.categoryLimits) {
                if (vm.categoryLimits[prop].limits.length === 0) {
                    delete vm.categoryLimits[prop];
                }
            }

            try {
                vm.isNotInTrial = subscriptionsInfo.isNotInTrial;
            } catch (error) {
                vm.isNotInTrial = false;
            }

            if (vm.isAuth) {
                UserService.accountInfo().then(function (res) {
                    vm.firstName = res.data.billingFirstname;
                });

                if (subscriptionsInfo) {
                    vm.subscriptionsInfo = subscriptionsInfo;
                    vm.subscriptionNotEnd = subscriptionsInfo.subscriptionNotEnd;
                    vm.widthBar = subscriptionsInfo.bar.width;
                    vm.colorBar = subscriptionsInfo.bar.class;
                    vm.accountPitchground = subscriptionsInfo.accountPitchground;
                    vm.daysLeft = subscriptionsInfo.diffDays;
                    vm.monthlySub = subscriptionsInfo.monthlySub;
                    vm.annualSub = subscriptionsInfo.annualSub;
                }

                SubscriptionService.plans()
                    .then(function (result) {
                        if (!result || !result.data) {
                            return;
                        }

                        var subscriptionList = result.data;
                        if (subscriptionList.length <= 0) {
                            return;
                        }

                        /**
                         * Se l'utente è FREE
                         * e non ha Piani - all'interno
                         * dell'oggetto PlanImportant
                         * non ci sarà Name ne dateEnd
                         *
                         */
                        if (subscriptionsInfo.planImportant !== null) {
                            vm.nameSub = subscriptionsInfo.planImportant.name;
                            setDate(subscriptionsInfo.planImportant.dateEnd);
                        }
                    });
            }
        } // End.setDataForCreditsAndLimits

        /**
         *
         * @param subEnd
         */
        function setDate(subEnd) {
            if (subEnd === 'LIFETIME') {
                return;
            }
            var newDate = new Date().getTime() - new Date(subEnd).getTime();
            var timeDiff = Math.abs(newDate);
            if (timeDiff <= 0) {
                return;
            }

            vm.day = new Date(subEnd).getUTCDate();
            vm.month = new Date(subEnd).getUTCMonth() + 1;
            vm.year = new Date(subEnd).getFullYear();
        }

        /**
         *
         */
        function forceSomeLimits() {
            /* force seo-report-whitelabel limit if is empy
            var seoReportWhitelabelLimit = vm.categoryLimits.report.limits.filter(function(limit){
                return limit.alias === 'seo-report-white-label';
            });
            if (!seoReportWhitelabelLimit.length) {
                vm.categoryLimits.report.limits.push({
                    "alias": "seo-report-white-label",
                    "type": "feature",
                    "qtyRemaining": 0,
                    "textToShow": 'settings.overview.whitelabel.seo.reports',
                });
            }
            */

            // force seo-editor limit if is empy
            var seoEditorLimit = vm.categoryLimits.seoAssistant.limits.filter(function (limit) {
                return limit.alias === 'seo-editor';
            });

            if (!seoEditorLimit.length) {
                vm.categoryLimits.seoAssistant.limits.push(
                    {
                        "alias": "seo-editor",
                        "type": "feature",
                        "qtyRemaining": 0,
                        "textToShow": 'settings.overview.seo.writing.assistant.tool',
                    });
            }

            // force workspace-users-limit limit if is empy
            var workspaceUserLimit = vm.categoryLimits.platform.limits.filter(function (limit) {
                return limit.alias === 'workspace-users-limit';
            });

            if (!workspaceUserLimit.length) {
                vm.categoryLimits.platform.limits.push(
                    {

                        "alias": "workspace-users-limit",
                        "type": "feature_limit",
                        "qtyAllowed": 1,
                        "qtyConsumed": 1,
                        "qtyRemaining": 1,
                        "completedPercentage": 100,
                        "textToShow": 'users',
                        "showProgressBar": true
                    });
            }

            // force seo-checker limit if is empy
            var seoCheckerLimit = vm.categoryLimits.seoAnalysis.limits.filter(function (limit) {
                return limit.alias === 'seo-checker';
            });

            if (!seoCheckerLimit.length) {
                vm.categoryLimits.seoAnalysis.limits.push(
                    {
                        alias: 'seo-checker',
                        qtyAllowed: 0,
                        qtyRemaining: 0,
                        type: 'feature',
                        order: 2,
                        textToShow: 'settings.overview.seo.on.page'
                    },
                );
            }

            // force keyword-explorer limit if is empy
            var keywordExplorerLimit = vm.categoryLimits.seoAnalysis.limits.filter(function (limit) {
                return limit.alias === 'keyword-explorer';
            });

            if (!keywordExplorerLimit.length) {
                vm.categoryLimits.seoAnalysis.limits.push(
                    {
                        alias: 'keyword-explorer',
                        qtyAllowed: 0,
                        qtyRemaining: 0,
                        type: 'feature',
                        order: 3,
                        textToShow: 'settings.overview.keyword.research'
                    },
                );
            }

            // force serp-report limit if is empy
            var serpReportLimit = vm.categoryLimits.seoAnalysis.limits.filter(function (limit) {
                return limit.alias === 'serp-report';
            });

            if (!serpReportLimit.length) {
                vm.categoryLimits.seoAnalysis.limits.push(
                    {
                        alias: 'serp-report',
                        qtyAllowed: 0,
                        qtyRemaining: 0,
                        type: 'feature',
                        order: 4,
                        textToShow: 'settings.overview.competitor.analysis'
                    },
                );
            }

            // force copymetrics limit if is empy
            var copymetricsLimit = vm.categoryLimits.seoAnalysis.limits.filter(function (limit) {
                return limit.alias === 'copymetrics';
            });

            if (!copymetricsLimit.length) {
                vm.categoryLimits.seoAnalysis.limits.push(
                    {
                        alias: 'copymetrics',
                        qtyAllowed: 0,
                        qtyRemaining: 0,
                        type: 'feature',
                        order: 5,
                        textToShow: 'settings.overview.seo.content.analysis'
                    },
                );
            }
        } // end.forceSomeLimits

        /**
         * Carico il Numero massimo
         * di membri per workspace
         */
        function loadMemberLimit() {
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'workspace-users-limit') {
                                vm.maxMembers = service.qtyAllowed + 1; // + 1 è il proprietario
                            }
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }

        /**
         * Per sapere se ci sono
         * altri utenti aggiungi
         * e calcolare gli utenti residui
         * devo chiamare il WorkSpace
         */
        function getDetailWorkspace() {
            WorkspaceService.getDetailWorkspace({
                id: userInfo.workspace.id
            }).then(function (res) {
                if (res && res.users && res.users.length) {
                    vm.users = res.users.filter(function (user) {
                        return !user.refusedAt;
                    });
                }
                setDataForCreditsAndLimits();
                forceSomeLimits();
            }).catch(function (err) {
                console.log(err);
            });
        }

        loadMemberLimit();
        getDetailWorkspace();
    }

    IndexController.$inject = ['$scope', 'canDoTrial', 'BetaProgramService', '__env', 'userInfo', 'subscriptionCredits',
        'userCredits', 'isTrialing', 'AuthService', 'SubscriptionService', 'subscriptionsInfo', 'UserService', 'LanguageService', 'WorkspaceService'];
    return IndexController;
});

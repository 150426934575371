'use strict';
define([], function () {
    var SEO_CHECKER_EXAMS = {
        base: ['serp', 'title', 'description', 'h1', 'h2', 'https', 'urlSeoFriendly', 'metaRobots', 'robots', 'sitemap'],
        content: ['uniqueKeywords', 'titleCoherence', 'h1Coherence', 'ratioTextCode', 'pageSize', 'altInTagImage', 'titleInTagA', 'microdata'],
        speed: ['desktop', 'mobile'],
        social: ['openGraph', 'twitterCard', 'socialPlugin', 'fbPopularity'],
        performanceWeb: ['largest-contentful-paint', 'interactive', 'first-contentful-paint', 'total-blocking-time', 'cumulative-layout-shift', 'speed-index'],
        performanceMobile: ['largest-contentful-paint', 'interactive', 'first-contentful-paint', 'total-blocking-time', 'cumulative-layout-shift', 'speed-index'],
    };
    var ISSUES_TRANSLATION_KEYS = {
        "serp": "Google Snippet",
        "title": "leadgeneration.title",
        "description": "leadgeneration.meta.description",
        "h1": "H1",
        "h2": "H2",
        "https": "leadgeneration.https",
        "urlSeoFriendly": "leadgeneration.seo.friendly.url",
        "metaRobots": "leadgeneration.meta.robots",
        "robots": "leadgeneration.robots.txt",
        "sitemap": "leadgeneration.sitemap",
        "uniqueKeywords": "leadgeneration.unique.keywords",
        "titleCoherence": "leadgeneration.title.coherence",
        "h1Coherence": "leadgeneration.h1.coherence",
        "ratioTextCode": "leadgeneration.text.code.ratio",
        "pageSize": "leadgeneration.page.size",
        "altInTagImage": "leadgeneration.image.missing.alt",
        "titleInTagA": "leadgeneration.title.tag.a",
        "microdata": "leadgeneration.microdata",
        "desktop": "leadgeneration.desktop",
        "mobile": "leadgeneration.mobile",
        "openGraph": "leadgeneration.opengraph",
        "twitterCard": "leadgeneration.twittercard",
        "socialPlugin": "leadgeneration.social.plugin",
        "fbPopularity": "leadgeneration.facebook.popularity"
    };

    function Controller($scope, $q, $sce, MediaService, ReportPdfService, form, $translate, LanguageService, LeadGenerationService, $stateParams, $timeout, currentLangCode) {
        var vm = $scope;
        var ctrl = this;
        vm.ISSUES_TRANSLATION_KEYS = ISSUES_TRANSLATION_KEYS;
        vm.findField = findField;
        vm.fieldIsChecked = fieldIsChecked;
        vm.isChecked = isChecked;
        vm.checkUncheckAll = toggleAll;
        vm.isChecked = isChecked;
        vm.medias = {
            logo: {},
        };
        vm.checkUncheckAll = toggleAll;
        vm.form = form;

        $.trumbowyg.svgAbsoluteUseHref = true;

        vm.editorConfig = {
            svgPath: 'assets/img/editor-icons.svg',
            tagsToKeep: ['strong', 'h1', 'h2', 'h3', 'h4', 'img', 'b', 'i', 'u', 'ul', 'li', 'ol', 'li', 'p', 'span'],
            tagsToRemove: ['script', 'video', 'link', 'iframe'],
            resetCss: true,
            semantic: true,
            removeformatPasted: true,
            imageWidthModalEdit: true,
            btns: [
                ['formatting'],
                ['undo', 'redo'],
                ['strong', 'em', 'del'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['fullscreen']
            ]
        };

        vm.workingSessionModel = {
            form: null
        };

        vm.checkAll = {
            base: true,
            content: true,
            speed: true,
            social: true
        };

        if (!vm.form || !vm.form.magnet) {
            vm.form.magnet = {
                type: "report",
                config: {
                    params: {
                        logo: null,
                        locale: 'en',
                        categories: getDefaultCategoriesObject(true, true),
                        exams: {
                            score: true,
                            suggests: true,
                            problems: true
                        }
                    },
                    default: false,
                    version: "1.0"
                }
            };
        }

        if (vm.form && vm.form.magnet && vm.form.magnet.config && vm.form.magnet.config.params && vm.form.magnet.config.params.locale) {
            vm.magnetLocale = LanguageService.getLocaleFromLanguageCode(vm.form.magnet.config.params.locale);
        }

        vm.imagePresent = false;
        vm.customFile = {};
        vm.updateMedia = updateMedia;
        vm.switchLangMagnet = switchLangMagnet;
        vm.getRandomNumber = function (min, max) { // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min);
        };
        ctrl.$onInit = function () {
            currentLangCode = $translate.instant('currentLangCode') || 'en';
            if (vm.form.magnet) {
                resetCategories(false);
            } else {
                resetCategories(true);
            }

            vm.$watch('form.magnet.config.params.exams', function () {
                vm.form.magnet.config.params.categories = getMagnetCategories();
            }, true);

            vm.$watch('categories', function () {
                vm.form.magnet.config.params.categories = getMagnetCategories();
            }, true);

            vm.$watchCollection('medias.logo', function (logo) {
                if (logo) {
                    vm.form.magnet.config.params.logo = logo.id || null;
                    saveEmbed();
                } else {
                    vm.form.magnet.config.params.logo = null;
                    saveEmbed();
                }
            });

            setImageMedia();
            autosavePooling();
        };
        ctrl.$onDestroy = function () {
            currentPoolingSessionSaveDataAborted = true;
            $timeout.cancel(currentPoolingSessionSaveData);
        };

        function toggleAll(section) {
            Object.keys(vm.categories[section]).forEach(function (key) {
                for (key in vm.categories[section])
                    vm.categories[section][key] = !vm.checkAll[section];
            });
        }


        function isChecked(section) {
            var isCheked = false;
            Object.values(vm.categories[section]).forEach(function (values) {
                if (values == true) {
                    isCheked = true;
                    return;
                }
            });
            return isCheked;
        }

        function transformCategoryInReportObject(categories, categoryId) {
            var tmp = 0;
            Object.keys(vm.categories[categoryId]).forEach(function (key) {
                if (vm.categories[categoryId][key] === false) {
                    delete categories[categoryId].exams[key];
                    tmp++;
                }
            });
            if (Object.keys(vm.categories[categoryId]).length === tmp) {
                delete categories[categoryId];
            }
        }

        function getMagnetCategories() {
            var tmp = 0;
            var categories = ReportPdfService.getCategories({
                type: 'lead_gen',
                score: vm.form.magnet.config.params.exams.score,
                preview: true,
                suggests: vm.form.magnet.config.params.exams.suggests,
                problems: vm.form.magnet.config.params.exams.problems,
            });
            transformCategoryInReportObject(categories, 'base');
            transformCategoryInReportObject(categories, 'content');
            transformCategoryInReportObject(categories, 'social');

            if (vm.desktop == false) {
                delete categories.performanceWeb;
            }
            if (vm.mobile == false) {
                delete categories.performanceMobile;
            }
            return categories;
        }

        function switchLangMagnet(lang) {
            vm.magnetLocale = lang;
            vm.form.magnet.config.params.locale = LanguageService.getLanguageCodeFromLocale(lang);
            saveEmbed();
        }


        function setImageMedia() {
            if (vm.form.magnet && vm.form.magnet.config.params.logo) {
                vm.logoLoading = true;
                MediaService.findOne({
                        id: vm.form.magnet.config.params.logo
                    })
                    .then(function (res) {
                        vm.logoLoading = false;
                        vm.medias.logo = res.data;
                    })
                    .catch(function (err) {
                        vm.logoLoading = false;
                    });
            }

        }

        function updateMedia(media) {
            if (vm.form && vm.form.magnet && vm.form.magnet.config && vm.form.magnet.config.params && media && media.resourceUrl) {
                vm.media = media;
                vm.form.magnet.config.params.resourceUrl = media.resourceUrl;
                saveEmbed();
            }
        }

        function findField(field, fields) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id == field.id)
                    return i;
            }
            return -1;
        }

        function fieldIsChecked(field) {
            return findField(field, vm.form.config.fields) != 1;
        }

        function resetCategories(isNotInit) {
            var basicDefaultCategories = getDefaultCategoriesObject(isNotInit);
            if (isNotInit == false) {
                setDefaultCategory(basicDefaultCategories, 'base');
                setDefaultCategory(basicDefaultCategories, 'content');
                setDefaultCategory(basicDefaultCategories, 'social');

                if (vm.form && vm.form.magnet && vm.form.magnet.config && vm.form.magnet.config.params && vm.form.magnet.config.params.categories) {
                    if (vm.form.magnet.config.params.categories.performanceMobile) {
                        basicDefaultCategories.speed.mobile = true;
                    }

                    if (vm.form.magnet.config.params.categories.performanceWeb) {
                        basicDefaultCategories.speed.desktop = true;
                    }
                }
            }
            vm.categories = basicDefaultCategories;
        }

        function setDefaultCategory(basicDefaultCategories, category) {
            if (!vm.form || !vm.form.magnet || !vm.form.magnet.config || !vm.form.magnet.config.params || !vm.form.magnet.config.params.categories) {
                return;
            }

            var thisCategory = vm.form.magnet.config.params.categories[category];
            if (thisCategory) {
                for (var examId in thisCategory.exams) {
                    basicDefaultCategories[category][examId] = true;
                }
            }
        }

        function getDefaultCategoriesObject(initValue, isReportObject) {
            var categories = {};
            var tmpExamId;
            for (var category in SEO_CHECKER_EXAMS) {
                categories[category] = {};
                if (isReportObject) {
                    categories[category] = {
                        exams: {},
                        score: true
                    };
                }

                for (var i in SEO_CHECKER_EXAMS[category]) {
                    tmpExamId = SEO_CHECKER_EXAMS[category][i];
                    if (!isReportObject) {
                        categories[category][tmpExamId] = initValue;
                    } else {
                        categories[category].exams[tmpExamId] = {
                            score: initValue,
                            preview: true,
                            problems: initValue,
                            suggests: initValue
                        };
                    }
                }
            }

            if (isReportObject) {
                delete categories['speed'];
                categories.performanceWeb = {
                    score: initValue,
                    problems: initValue,
                    exams: categories.performanceWeb.exams
                };

                categories.performanceMobile = {
                    score: initValue,
                    problems: initValue,
                    exams: categories.performanceMobile.exams
                };
            }

            return categories;
        }

        var lastSavedForm = null;
        var currentPoolingSessionSaveData;
        var currentPoolingSessionSaveDataAborted = false;

        function autosavePooling() {
            if (currentPoolingSessionSaveDataAborted) {
                return;
            }
            var dfd = $q.defer();
            saveEmbed().then(function () {
                dfd.resolve(true);
                currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
            }).catch(function () {
                dfd.resolve(true);
                currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
            });
            return dfd.promise;
        }

        function saveEmbed() {
            var dfd = $q.defer();

            vm.form.magnet.config.params.locale = LanguageService.getLanguageCodeFromLocale(vm.magnetLocale);

            var formToSave = {
                id: Number($stateParams.formId),
                name: vm.form.name,
                config: vm.form.config,
                magnet: vm.form.magnet
            };

            if (vm.workingSessionModel.form) {
                formToSave = {
                    id: Number($stateParams.formId),
                    name: vm.form.name,
                    config: vm.workingSessionModel.form.config,
                    magnet: vm.form.magnet
                };
            }


            if (lastSavedForm === JSON.stringify(formToSave)) {
                $timeout(function () {
                    dfd.resolve(true);
                }, 1000);
                return dfd.promise;
            }

            lastSavedForm = JSON.stringify(formToSave);
            LeadGenerationService
                .updateForm(formToSave)
                .then(function (res) {
                    dfd.resolve(res);
                }).catch(function (err) {
                    dfd.resolve(err);
                });
            return dfd.promise;
        }

        vm.noteCheck = function() {

        }

        vm.trustAsHtml = function(string) {
            return $sce.trustAsHtml(string);
        };
    }
    Controller.$inject = ['$scope', '$q', '$sce', 'MediaService', 'ReportPdfService', 'form', '$translate', 'LanguageService', 'LeadGenerationService', '$stateParams', '$timeout', 'currentLangCode'];
    return Controller;
});

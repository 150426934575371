define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("upgrade", {
                url: "/upgrade",
                abstract: true,
                controller: 'UpgradeCtrl',
                templateUrl: './app/modules/upgrade/upgrade.html',
                params: {
                    coupon: { squash: true },
                }
            })

            // Will be new pricing page
            .state("upgrade.choose-plan", {
                url: "/choose-plan?:dealId?:saleOwner?:type?:periods?",
                controller: 'UpgradeChoosePlanCtrl',
                templateUrl: './app/modules/upgrade/choose-plan/choose-plan.html',
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits()
                            .then(function (res) {
                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                }
            })

            // Checkout
            .state("upgrade.checkout", {
                controller: 'UpgradeCheckoutCtrl',
                url: "/choose-plan/:alias/checkout?:dealId:saleOwner:coupon",
                templateUrl: './app/modules/upgrade/checkout/checkout.html',
                resolve: {
                    plan: ['$stateParams', '$state', 'PlanService', 'TrackEventService', function ($stateParams, $state, PlanService, TrackEventService) {
                        if (!$stateParams || !$stateParams.alias) {
                            $state.go('upgrade.unavailable', {
                                alias: 'not-defined',
                            });
                            return;
                        }

                        var alias = $stateParams.alias;
                        return PlanService.findOne($stateParams)
                            .then(function (res) {
                                TrackEventService.event('Funnel Events', 'Entered Billing Info', res.data.alias);
                                TrackEventService.gaV2Event('screen_view', {
                                    screen_name: 'billing-info',
                                    plan: res.data.alias
                                });
                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                $state.go('upgrade.unavailable', {
                                    alias: alias,
                                });
                            });
                    }],
                    isTrialing: ['$stateParams', '$state', 'CheckoutService', 'AuthService', '$q', function ($stateParams, $state, CheckoutService, AuthService, $q) {
                        var deferred = $q.defer();

                        var userId = null;
                        var user = AuthService.getSessionUser();

                        if (user && user.id) {
                            userId = user.id
                        }
                        CheckoutService.checkTrial(userId, $stateParams.alias)
                            .then(function (res) {
                                deferred.resolve(res.data.canDoTrial);
                            })
                            .catch(function (err) {
                                console.log(err);
                                deferred.resolve(false);
                            });

                        return deferred.promise;
                    }],
                    info: ['$stateParams', '$state', 'UserService', '$q', 'AuthService', function ($stateParams, $state, UserService, $q, AuthService) {
                        var deferred = $q.defer();

                        if (!AuthService.isAuthenticated()) {
                            return;
                        }

                        UserService.accountInfo()
                            .then(function (userInfo) {
                                if (userInfo.data.billingIsEU === 1)
                                    userInfo.data.billingIsEU = true;
                                if (userInfo.data.billingIsEU === 0)
                                    userInfo.data.billingIsEU = false;
                                deferred.resolve(userInfo.data);
                            })
                            .catch(function () {
                                deferred.resolve();
                            });

                        return deferred.promise;
                    }],
                    coupon: ['$window', 'CheckoutService', '$q', '$location', 'AuthService', '$stateParams', function ($window, CheckoutService, $q, $location, AuthService, $stateParams) {
                        var deferred = $q.defer();
                        var couponInLink = $location.search().coupon || $stateParams.coupon;

                        if (couponInLink) {
                            $window.localStorage.setItem('coupon', couponInLink);
                        }

                        var coupon = {name: null, hide: true, data: null};
                        var couponStorage = $window.localStorage.getItem('coupon');
                        if (couponStorage) {
                            var redeemedCouponHistory = $window.localStorage.getItem('redeemedCouponHistory');
                            if (!redeemedCouponHistory || !redeemedCouponHistory[couponStorage]) {
                                CheckoutService
                                    .checkCoupon({code: couponStorage})
                                    .then(function (res) {
                                        coupon.name = couponStorage;
                                        coupon.hide = false;
                                        coupon.data = res.data;
                                        deferred.resolve(coupon);
                                    })
                                    .catch(function () {
                                        $window.localStorage.removeItem('coupon');
                                        coupon.name = null;
                                        coupon.hide = true;
                                        coupon.data = null;
                                        deferred.resolve(coupon);
                                    });
                                return deferred.promise;
                            } else {
                                $window.localStorage.removeItem('coupon');
                                return coupon;
                            }
                        } else {
                            return coupon;
                        }
                    }]
                }
            })

            // Plan unavailable
            .state("upgrade.unavailable", {
                url: "/choose-plan/:alias/unavailable",
                controller: 'UpgradeUnavailableCtrl',
                templateUrl: './app/modules/upgrade/unavailable/unavailable.html',
            })

            // On success
            .state("upgrade.thankyou", {
                url: "/choose-plan/:alias/thank-you",
                controller: 'UpgradeThankYouCtrl',
                templateUrl: './app/modules/upgrade/thank-you/thank-you.html',
                params: {
                    alias: {squash: true, value: null},
                    isTrialing: null,
                    trialEndDate: null
                },
            })

            // On fail
            .state("upgrade.failed", {
                url: "/choose-plan/:alias/payment-failed",
                controller: 'UpgradePaymentFailedCtrl',
                templateUrl: './app/modules/upgrade/payment-failed/payment-failed.html',
                params: {
                    cantDoTrial: null,
                },
                resolve: {
                    canDoTrial: ['$stateParams', 'CheckoutService', 'AuthService', '$q', function ($stateParams, CheckoutService, AuthService, $q) {
                        var deferred = $q.defer();

                        var userId = null;
                        var user = AuthService.getSessionUser();

                        if (user && user.id) {
                            userId = user.id
                        }

                        CheckoutService.checkTrial(userId, $stateParams.alias)
                            .then(function (res) {
                                deferred.resolve(res.data.canDoTrial);
                            })
                            .catch(function (err) {
                                console.log(err);
                                deferred.resolve(false);
                            });

                        return deferred.promise;
                    }],
                }
            })
    }

    config.$inject = ['$stateProvider'];
    return config;
});

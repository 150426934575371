'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seochecker/components/seo-onpage-score/seo-onpage-score.html',
        bindings: {
            loading: '<',
            score: '<',
            badExams: '<',
            tool: '@',
            inverseScore: '<',
            noMargin: '=',
            size: '<'
        },
        controller: ['$scope', function ($scope) {

            $scope.chartOpts = {
                showTooltips: false,
                /*
                    circumference: 1.5 * Math.PI,
                    rotation: -1.25 * Math.PI,
                    circumference: 1.45*Math.PI,
                    rotation: 0.78*Math.PI,
                */
                circumference: 1.35*Math.PI,
                rotation: 0.83*Math.PI,
                cutoutPercentage: 70,
                /*
                legend: {
                    display: true
                },
                */

                elements: {
                    arc: {
                        borderWidth: 1
                    }
                },
                tooltips: {
                    enabled: false
                },
                segmentShowStroke: false,
                hover: false
            };
            const vm=this;
            const colors=[
                '#049961',
                '#4ca74d',
                '#91b73a',
                '#c5be22',
                '#eebd00',
                '#ffad02',
                '#fe8b04',
                '#fc6214',
                '#ff0f00',
                '#cc0605',
            ];
            let SEO_ONPAGE_SCORE_BEHAVIOR=[
                {
                    label: "Easy",
                    from: 0,
                    until: 10,
                    color: colors[0],
                },
                {
                    label: "Easy",
                    from: 10,
                    until: 20,
                    color: colors[1],
                },
                {
                    label: "Easy",
                    from: 20,
                    until: 30,
                    color: colors[2],
                },
                {
                    label: "Medium",
                    from: 30,
                    until: 40,
                    color: colors[3],
                },
                {
                    label: "Medium",
                    from: 40,
                    until: 50,
                    color: colors[4],
                },
                {
                    label: "Hard",
                    from: 50,
                    until: 60,
                    color: colors[5],
                },
                {
                    label: "Hard",
                    from: 60,
                    until: 70,
                    color: colors[6],
                },
                {
                    label: "Hard",
                    from: 70,
                    until: 80,
                    color: colors[7],
                },
                {
                    label: 'Very Hard',
                    from: 80,
                    until: 90,
                    color: colors[8],
                },
                {
                    label: 'Very Hard',
                    from:  90,
                    until: 100,
                    color: colors[9],
                }
            ];
            this.$onInit = function() {
                if(vm.inverseScore){
                    SEO_ONPAGE_SCORE_BEHAVIOR.forEach((el,i)=>{
                        el.color=colors[9-i];
                    });
                }


                $scope.loading = this.loading;
                $scope.scoreLabel = "";
                if (this.score) {
                    this.score = Math.floor(this.score);
                }
                getDatasetBySeoOnpageScore(this.score);

                if (this.score>=75) {
                    $scope.scoreLabel = "seochecker.great";
                } else if(this.score>=50) {
                    $scope.scoreLabel = "seochecker.good";
                } else if(this.score>=20) {
                    $scope.scoreLabel = "seochecker.mediocre";
                } else {
                    $scope.scoreLabel = "seochecker.bad";
                }

            };

            this.$onChanges = function () {
                $scope.loading = this.loading;
                $scope.scoreLabel = "";
                if (this.score) {
                    this.score = Math.floor(this.score);
                }
                getDatasetBySeoOnpageScore(this.score);

                if (this.score>=75) {
                    $scope.scoreLabel = "seochecker.great";
                } else if(this.score>=50) {
                    $scope.scoreLabel = "seochecker.good";
                } else if(this.score>=20) {
                    $scope.scoreLabel = "seochecker.mediocre";
                } else {
                    $scope.scoreLabel = "seochecker.bad";
                }

            };

            var getDatasetBySeoOnpageScore = function (score) {

                var analyzedRange;
                var remainingToCheck=score;
                var chartData=[];
                var chartColors=[];
                var i;
                for(i=0;i<SEO_ONPAGE_SCORE_BEHAVIOR.length;i++){
                    analyzedRange=SEO_ONPAGE_SCORE_BEHAVIOR[i];
                    var totalRangeSize=analyzedRange.until-analyzedRange.from;
                    var isLessThanRangeSize=remainingToCheck-totalRangeSize>0;
                    if(isLessThanRangeSize){
                        /* fill the pie chart with color sized
                         with the current amount of range size
                          in exceeded */
                        remainingToCheck-=totalRangeSize;
                        chartData.push(totalRangeSize);
                        chartColors.push(analyzedRange.color);

                    }
                    else{
                        /* fill the pie chart with the remaining part
                        of current amount and fill the rest with the
                         no data color */
                        chartData.push(remainingToCheck);
                        chartColors.push(analyzedRange.color);
                        chartData.push(100-score);
                        chartColors.push("#C2D1D9");
                        break;
                    }
                }

                if ($scope.loading) {
                    $scope.chartData=[100];
                    $scope.chartColors=["#ECF0F3"];
                } else {
                    $scope.chartData=chartData;
                    $scope.chartColors=chartColors;
                }


            };

            $scope.$watch('$ctrl.badExams', function (badExams) {
                $scope.badExams = badExams;
            });

        }]
    };
});

'use strict';
define([],function(){
    controller.$inject = ['$scope','$mdMedia'];
    function controller($scope,$mdMedia) {
        $scope.$mdMedia=$mdMedia;
        var $this=this;

        $this.$onInit=function () {
            if(typeof $this.elements==='string'){
                try{
                    $this.elements=JSON.parse($this.elements);
                }
                catch(e){
                    $this.elements=[];
                }
            }
        };
    }
    return {
        templateUrl: 'app/components/st-breadcrumbs/st-breadcrumbs.html',
        bindings: {
            elements: '<',
        },
        controller: controller
    };
});

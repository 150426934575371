'use strict';
define(['jquery'], function ($) {
    return {
        templateUrl: 'app/components/access-components/st-get-started-now/st-get-started-now.html',
        bindings: {
            service: '=',
            onAuth: '=',
            redirectTo: '<',
            dialogMode: '<',
            userCanDoTrial: '<',
            isPromoTime: '<',
            params: '<',
        },
        controller: ['$scope', '$state', 'UserService', '$stateParams', '$mdMedia', '$timeout', '$window', 'LanguageService', 'PlanDetailsService',
            function ($scope, $state, UserService, $stateParams, $mdMedia, $timeout, $window, LanguageService, PlanDetailsService) {
                var ctrl = this;

                $scope.filterPlanByIntervalAndPlanName = filterPlanByIntervalAndPlanName;
                $scope.filterPlanByInterval = filterPlanByInterval;
                $scope.goToCheckout = goToCheckout;
                $scope.goToDashboard = goToDashboard;
                $scope.goToContactUs = goToContactUs;
                $scope.formatAmount = formatAmount;
                $scope.getFullPrice = getFullPrice;
                $scope.isNumber = isNumber;
                $scope.suggestedPlans = $stateParams.suggestedPlans;
                $scope.value = 100;

                ctrl.$onInit = function () {
                    $scope.plans = [];
                    $scope.interval = 'year';
                    $scope.planName = '';
                    $scope.userCanDoTrial = ctrl.userCanDoTrial || $stateParams.userCanDoTrial;
                    $scope.$mdMedia = $mdMedia;
                    $scope.formatFullPrice = formatFullPrice;
                    $scope.fullPrices = [];
                    $scope.isPromoTime = false;

                    if (this.isPromoTime) {
                        $scope.classModifier = PlanDetailsService.getCurrentPromo().currentPromoModifierClass;
                        $scope.isPromoTime = this.isPromoTime;
                        $scope.promoLabel = PlanDetailsService.getCurrentPromo().name;
                    }

                    if ($scope.suggestedPlans && $scope.suggestedPlans.length == 0) {
                        $scope.type = 'enterprise';
                        return;
                    }

                    if ($scope.suggestedPlans && $scope.suggestedPlans.length == 4) {
                        $scope.plans = $scope.suggestedPlans;
                        $scope.plans = PlanDetailsService.addFeaturesDetail($scope.plans, $scope.type);

                        $scope.plansToShow = $scope.plans.filter(function (plan) {
                            return plan.interval == $scope.interval;
                        });

                        $scope.selectedPlan = $scope.plansToShow[1].alias;
                    } else {
                        UserService.getSuggestedPlans(true)
                            .then(function (res) {
                                if (res.data && res.data.suggestedPlans && res.data.suggestedPlans.length == 0) {
                                    $scope.type = 'enterprise';
                                    return;
                                }

                                if (res.data && res.data.suggestedPlans && res.data.suggestedPlans.length == 4) {
                                    $scope.plans = res.data.suggestedPlans;
                                    $scope.plans = PlanDetailsService.addFeaturesDetail($scope.plans);

                                    $scope.plansToShow = $scope.plans.filter(function (plan) {
                                        return plan.interval == $scope.interval;
                                    });

                                    $scope.selectedPlan = $scope.plansToShow[1].alias;
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                                $scope.plans = [{
                                        "alias": "plan-lite-annual",
                                        "name": "Plan Lite",
                                        "amount": 21600,
                                        "interval": "year",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-lite-monthly",
                                        "name": "Plan Lite",
                                        "amount": 2600,
                                        "interval": "month",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-pro-annual",
                                        "name": "Plan Pro",
                                        "amount": 31200,
                                        "interval": "year",
                                        "trialPeriodDays": 7,
                                    },
                                    {
                                        "alias": "plan-pro-monthly",
                                        "name": "Plan Pro",
                                        "amount": 3800,
                                        "interval": "month",
                                        "trialPeriodDays": 7,
                                    }
                                ];
                            });
                    }

                };

                function animateValue(elID, start, end, duration) {
                    var obj = document.getElementById(elID);

                    if (!obj) {
                        return;
                    }
                    var startTimestamp = null;
                    var step = function (timestamp) {
                        if (!startTimestamp) {
                            startTimestamp = timestamp;
                        }
                        var progress = Math.min((timestamp - startTimestamp) / duration, 1);
                        obj.innerHTML = Math.floor(progress * (end - start) + start);
                        if (progress < 1) {
                            window.requestAnimationFrame(step);
                        }
                    };
                    window.requestAnimationFrame(step);
                }

                function animatePrices() {
                    var firstPriceId = '',
                        secondPriceId = '';
                    if ($mdMedia('gt-xs')) {
                        firstPriceId = 'first-price';
                        secondPriceId = 'second-price';
                    } else {
                        firstPriceId = 'first-price-mobile';
                        secondPriceId = 'second-price-mobile';
                    }

                    var firstFinalPrice = $scope.interval != "year" ? $scope.plansToShow[0].amount / 100 / 2 : (formatAmount($scope.plansToShow[0].amount) / 2);
                    var secondFinalPrice = $scope.interval != "year" ? $scope.plansToShow[1].amount / 100 / 2 : (formatAmount($scope.plansToShow[1].amount) / 2);

                    animateValue(firstPriceId, $scope.currentFirstPrice, firstFinalPrice, 700);
                    animateValue(secondPriceId, $scope.currentSecondPrice, secondFinalPrice, 700);
                }

                function filterPlanByInterval(interval) {
                    $scope.interval = interval;
                    $scope.plansToShow = $scope.plans.filter(function (plan) {
                        return plan.interval == interval;
                    });

                    if (interval !== 'year') {
                        $scope.selectedPlan = $scope.selectedPlan.replace("annual", "monthly");
                    } else {
                        $scope.selectedPlan = $scope.selectedPlan.replace("monthly", 'annual');
                    }
                    if ($scope.isPromoTime) {
                        animatePrices();
                    }

                    $timeout(function () {
                        $('md-radio-button[value="' + $scope.selectedPlan + '"]').click();
                    }, 50);
                }

                $scope.setCurrentPrices = function () {
                    if ($scope.isPromoTime) {
                        var firstPriceId, secondPriceId;
                        if ($mdMedia('gt-xs')) {
                            firstPriceId = 'first-price';
                            secondPriceId = 'second-price';
                        } else {
                            firstPriceId = 'first-price-mobile';
                            secondPriceId = 'second-price-mobile';
                        }
                        $scope.currentFirstPrice = parseInt(document.getElementById(firstPriceId).innerText);
                        $scope.currentSecondPrice = parseInt(document.getElementById(secondPriceId).innerText);
                    }
                }

                function filterPlanByIntervalAndPlanName(interval, planAlias) {
                    $scope.interval = interval;
                    $scope.plansToShow = $scope.plans.filter(function (plan) {
                        return plan.interval == interval;
                    });

                    $scope.selectedPlan = $scope.plansToShow.filter(function (plan) {
                        return plan.alias == planAlias;
                    })[0].alias;
                }

                function goToCheckout() {
                    var coupon = '';
                    if (ctrl.dialogMode) {
                        $scope.$parent.cancel();
                    }
                    if (ctrl.isPromoTime) {
                        coupon = PlanDetailsService.getCurrentPromo().promoCoupon;
                    }
                    if ($scope.selectedPlan) {
                        $state.go('upgrade.checkout', {
                            alias: $scope.selectedPlan,
                            coupon: coupon
                        });
                    }
                }

                function goToDashboard() {
                    if (ctrl.dialogMode) {
                        $scope.$parent.cancel();
                    }

                    if (ctrl.redirectTo) {
                        $state.go(ctrl.redirectTo);
                        return;
                    }

                    $state.go('app.welcome');
                }

                function formatAmount(amount) {
                    var formattedAmount = amount / 12 / 100;
                    return formattedAmount.toString().split('.')[0];
                }

                function getFullPrice(alias, index) {
                    if (!alias) {
                        return;
                    }
                    var aliasToFilter = alias.replace("annual", "monthly");

                    var filteredPlan = $scope.plans.filter(function (plan) {
                        return plan.alias == aliasToFilter;
                    })[0];

                    $scope.fullPrices[index] = filteredPlan.amount;

                    return filteredPlan.amount;
                }


                function formatFullPrice(fullPrice) {
                    return fullPrice / 100;
                }

                function goToContactUs() {
                    var language = LanguageService.getCodeAndCountrySaveOnStorage();
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/contatti", '_blank');
                    } else if (language === 'es-ES') {
                        $window.open("https://es.seotesteronline.com/contacts", '_blank');
                    } else {
                        $window.open("https://www.seotesteronline.com/contacts", '_blank');
                    }
                }

                function isNumber(value) {
                    return angular.isNumber(value);
                }

            }
        ]
    };
});

'use strict';
define([], function () {
    controller.$inject = ['$scope', 'LanguageService', '$window', 'TrackEventService', '$translate'];

    function controller($scope, LanguageService, $window, TrackEventService, $translate) {
        var ctrl = this;
        var vm = $scope;
        vm.url = null;

        ctrl.$onInit=function () {
           vm.tool = ctrl.tool;
        };

        vm.goToKnowledgeBase = function() {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();

            switch (vm.tool) {
                case 'backlink-checker': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-backlink-checker/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-backlink-checker/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/backlink-checker-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/backlink-checker-guide/";
                    }
                    break;
                }

                case 'copy-metrics': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-copy-metrics/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-copy-metrics/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/copy-metrics-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/copy-metrics-guide/";
                    }
                    break;
                }

                case 'keyword-explorer': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-keyword-explorer/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-keyword-explorer/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/keyword-explorer-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/keyword-explorer-guide/";
                    }
                    break;
                }

                case 'keyword-list-manager': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/keyword-list-manager/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/keyword-list-manager/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/keyword-list-manager-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/keyword-list-manager-guide/";
                    }
                    break;
                }

                case 'keyword-tracker': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-keyword-rank-tracker/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-keyword-rank-tracker/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/keyword-rank-tracker-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/keyword-rank-tracker-guide/";
                    }
                    break;
                }

                case 'lead-generation': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-lead-generation-tool/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-lead-generation-tool/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/lead-generation-tool-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/lead-generation-tool-guide/";
                    }
                    break;
                }

                case 'seo-checker': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-seo-checker/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-seo-checker/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/seo-checker-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/seo-checker-guide/";
                    }
                    break;
                }

                case 'seo-editor': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-seo-editor/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-seo-editor/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/seo-editor-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/seo-editor-guide/";
                    }
                    break;
                }

                case 'seo-spider': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-seo-spider/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-seo-spider/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/seo-spider-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/seo-spider-guide/";
                    }
                    break;
                }

                case 'serp-checker': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/guida-serp-checker/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/guida-serp-checker/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/serp-checker-guide/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/serp-checker-guide/";
                    }
                    break;
                }

                case 'workspace-user': {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/workspace/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/workspace/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/workspace/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/workspace/";
                    }
                    break;
                }

                default: {
                    if (language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/";
                    }
                }

            }

            TrackEventService.event(
                'Interactions',
                'Click User Manual Button',
                vm.tool
            );

            TrackEventService.gaV2Event(
                'click',
                {
                    type: 'User Manual Button',
                    link_id: vm.tool,
                    link_text: $translate.instant('user.manual'),
                    link_url: vm.url,
                }
            );

        }

    }

    return {
        templateUrl: 'app/components/st-user-manual/st-user-manual.html',
        bindings: {
            tool: '@',
            textVisibility: '@',
            containerClasses: '@'
        },
        controller: controller
    };
});


'use strict';
define(['angular'], function (angular) {
    function Controller($scope, UserService, CountriesService, stAlertLimitService, CopyMetrics, stLimitMessage, userInfo, stToggleExpand, ExportService, $translate, __env, $timeout, $sce, $mdDialog, SerpCTRService, SERPChecker, $stateParams, TrackEventService) {
        function _constructor() {
            newAnalysis();
            setEventsListeners();
            updateLimits();
        }

        var vm = $scope;
        vm.query = {
            keyword: $stateParams.keyword || null,
            deviceId: $stateParams.deviceId || SERPChecker.config.defaultDeviceId,
            searchEngineId: $stateParams.searchEngineId || SERPChecker.config.defaultSearchEngineId,
            countryCode: $stateParams.countryCode || SERPChecker.config.defaultCountryCode,
            benchmark: getBenchmarkFromStateParams() || []
        };
        vm.expandSection = stToggleExpand.isExpanded('serp-checker-toggle') || false;
        vm.toInteger = toInteger;
        vm.onFocusChange = onFocusChange;
        vm.rowSelected = false;
        var currentQueryParams = vm.query;
        var lastRequestParams = null;
        var lastRequestDone = null;
        var customTableController = {
            getLangByCountry: CountriesService.getLangByCountry,
            removeFixed: removeFixed,
            analyzeUrlBySEOChecker: analyzeUrlBySEOChecker,
            query: currentQueryParams,
            getCTRByPosition: SerpCTRService.getCTRByPosition,
        };
        var settings = {
            setDevice: function (idDevice) {
                if (!idDevice) {
                    return;
                }
                var listDevice = [
                    {id: 1, name: 'desktop'}, {id: 2, name: 'mobile'}
                ];
                var device = listDevice.find(function (x) {
                    return x.id === idDevice;
                });
                if (device) {
                    return device.name;
                }
                return '';
            },
            setSearchEngine: function (idSearchEngine) {
                if (!idSearchEngine) {
                    return;
                }
                var listSearchEngine = [
                    {id: 1, name: 'google'}, {id: 2, name: 'yahoo'},
                    {id: 3, name: 'yandex'}, {id: 4, name: 'bing'}
                ];
                var searchEngine = listSearchEngine.find(function (x) {
                    return x.id === idSearchEngine;
                });
                if (searchEngine) {
                    return searchEngine.name;
                }
                return '';
            }
        };
        vm.results = {
            isLoading: false,
            competitorsRowsAreLoading: false,
            export: {
                stoTool: 'serp-report',
                class: 'st-button__rounded--md--violet',
                text: 'Export CSV',
                icon: 'icon-download',
                data: [],
                fileName: 'serp-keyword',
                buttonDisabled: true
            },
            controller: customTableController,
            rows: [],
            fixedRows: []
        };

        function analyzeUrlBySEOChecker(url) {
            return encodeURIComponent(btoa(url));
        }

        function updateLimits() {
            UserService.limits().then(function (res) {
                userInfo = res.data;
                vm.analysisLimit = userInfo.limits.find(function (service) {
                    return service.alias === 'analysis';
                });
            })
                .catch(function (err) {
                    return null;
                });
        }

        function getBenchmarkFromStateParams() {
            var stateParamsBenchmark = [];
            if ($stateParams.benchmark && isString($stateParams.benchmark))
                stateParamsBenchmark = [$stateParams.benchmark];
            if ($stateParams.benchmark && !isString($stateParams.benchmark) && $stateParams.benchmark.length)
                stateParamsBenchmark = $stateParams.benchmark;
            return stateParamsBenchmark;
        }

        function onFocusChange(rowSelected) {
            vm.rowSelected = rowSelected;
        }

        function removeFixed(index) {
            vm.results.fixedRows.splice(index, 1);
            vm.$emit('SERPCheckerOnCompetitorsChanged', index);
        }

        function setLoadingState() {
            vm.results.isLoading = true;
            vm.results.competitorsRowsAreLoading = false;
            vm.results.export.buttonDisabled = true;
        }

        function setRowsForExportCSV(queries) {
            if (!queries.length) {
                return [];
            }
            var formattedQueriesForCSV = [];

            queries.forEach(function (query) {
                var formattedCSVRow = {
                    position: 0,
                    rankPosition: 0,
                    breadcrumb: '',
                    title: '',
                    language: '',
                    device: 'desktop',
                    wordCount: 0,
                    translations: 1,
                    author: '',
                };

                if (!query) {
                    return;
                }

                formattedCSVRow.position = query.position;
                formattedCSVRow.rankPosition = query.rankPosition;
                formattedCSVRow.breadcrumb = query.breadcrumb;

                if (query.title && typeof query.title === 'string') {
                    formattedCSVRow.title = query.title.replace(',', '');
                }

                if (query.deviceId) {
                    formattedCSVRow.device = settings.setDevice(query.deviceId);
                }

                if (!query.extraParams) {
                    formattedQueriesForCSV.push(formattedCSVRow);
                    return;
                }

                formattedCSVRow.language = query.extraParams.language || '';
                formattedCSVRow.wordCount = query.extraParams.wordCount || 0;
                formattedCSVRow.author = query.extraParams.author || '';

                if (query.extraParams.translations
                    && query.extraParams.translations instanceof Array
                    && query.extraParams.translations > 0) {
                    formattedCSVRow.translations = query.extraParams.translations.length
                }

                formattedQueriesForCSV.push(formattedCSVRow);
            });

            return formattedQueriesForCSV;
        }

        function analysis(currentRequestParams) {
            SERPChecker.analyzeCopywriting(currentRequestParams)
                .then(function (serpAnalysisResults) {
                    lastRequestDone = null;
                    TrackEventService.event(
                        'Suite Usages',
                        'SERP Checker - Audit', 'Copywriting');
                    TrackEventService.gaV2Event(
                        'serp_audit',{
                            type: "Copywriting",
                            tool: "SERP Checker"
                        });
                    if (angular.equals(currentRequestParams, lastRequestParams)) {
                        vm.results.isLoading = false;
                        vm.results.rows = serpAnalysisResults.data.rows;
                        vm.results.export.data = ExportService.convertObjectToCsvArray(setRowsForExportCSV(vm.results.rows));
                        serpAnalysisResults.data.rows[0].focus = true;
                        onFocusChange(serpAnalysisResults.data.rows[0]);
                        vm.results.export.buttonDisabled = false;
                        SERPCheckerAuditCompetitors();
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    if (angular.equals(currentRequestParams, lastRequestParams)) {
                        vm.results.isLoading = false;
                        vm.results.rows = [];
                        vm.results.fixedRows = [];
                        if (err.status === 429 || err.status === 426) {
                            vm.results.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                            vm.results.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                            vm.results.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                            vm.results.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                            err.data.canClose = true;
                            stAlertLimitService.open('auth-login', err.data);
                        }
                    }
                });
        }

        function newAnalysis() {
            if (lastRequestDone === JSON.stringify(currentQueryParams)) {
                return;
            }
            if (!currentQueryParams.keyword) {
                return;
            }
            var currentRequestParams = SERPChecker.castQueryToAPIFormat(currentQueryParams);
            if (JSON.stringify(currentRequestParams) === JSON.stringify(lastRequestParams))
                return;
            lastRequestDone = JSON.stringify(currentQueryParams);
            lastRequestParams = SERPChecker.castQueryToAPIFormat(currentQueryParams);
            setLoadingState();
            analysis(currentRequestParams);
        }

        function toInteger(val) {
            return parseInt(val);
        }

        function isString(value) {
            return typeof value === 'string';
        }

        function SERPCheckerAuditCompetitors() {
            if (vm.analysisLimit && vm.analysisLimit.qtyRemaining <= 0)
                return;
            if (!vm.query || !vm.query.benchmark || !vm.query.benchmark.length) {
                vm.results.fixedRows = [];
                vm.results.competitorsRowsAreLoading = false;
                return;
            }
            vm.results.competitorsRowsAreLoading = true;
            SERPChecker.analyzeCopywritingFromURLs({
                urls: vm.query.benchmark,
                forceStatisticsUsingCachedSERP: vm.results.rows
            }).then(function (competitorsResponse) {
                vm.results.fixedRows = competitorsResponse.data.rows;
                vm.results.competitorsRowsAreLoading = false;
            }).catch(function (err) {
                vm.results.competitorsRowsAreLoading = false;
                vm.results.fixedRows = [];
            });
        }

        function setEventsListeners() {
            vm.$on('SERPCheckerAudit', function (evt, query) {
                if (query)
                    vm.query = query;
                newAnalysis();
            });
            vm.$on('SERPCheckerAuditCompetitors', function (evt, query) {
                if (query)
                    vm.query = query;
                SERPCheckerAuditCompetitors();
            });
        }

        _constructor();
    }

    Controller.$inject = ['$scope', 'UserService', 'CountriesService', 'stAlertLimitService', 'CopyMetricsService', 'stLimitMessage', 'userInfo', 'stToggleExpandSectionService', 'ExportService', '$translate', '__env', '$timeout', '$sce', '$mdDialog', 'SerpCTRService', 'SerpCheckerService', '$stateParams', 'TrackEventService'];
    return Controller;
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.h2", {
                abstract: true,
                controller: 'H2Ctrl',
                templateUrl: './app/modules/seospider/exam/analysis/h2/h2.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.h2.all", {
                url: "/h2",
                templateUrl: './app/modules/seospider/exam/analysis/h2/all/all.html',
                access: {restricted: true},
                controller: 'H2AllCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH2({
                            jobId: $stateParams.sessionId
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.h2.over", {
                url: "/h2/over-70-char",
                templateUrl: './app/modules/seospider/exam/analysis/h2/all/all.html',
                access: {restricted: true},
                controller: 'H2OverCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH2({
                            jobId: $stateParams.sessionId,
                            size: 'overDesktop'
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;

                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.h2.missing", {
                url: "/h2/missing",
                templateUrl: './app/modules/seospider/exam/analysis/h2/all/all.html',
                access: {restricted: true},
                controller: 'H2MissingCtrl',
                resolve: {
                    results: ['$stateParams', '$q', 'SeospiderPagesService', function ($stateParams, $q, SeospiderPages) {
                        var dfd = $q.defer();
                        SeospiderPages.findAllH2({
                            jobId: $stateParams.sessionId,
                            missing: true
                        }).then(function (result) {
                            dfd.resolve(result);
                        }, function (err) {
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.h2.duplicated", {
                url: "/h2/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/h2/duplicated/duplicated.html',
                access: {restricted: true},
                controller: 'H2DuplicatedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findAllH2Duplicated({
                            jobId: $stateParams.sessionId
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }

    config.$inject = ['$stateProvider'];
    return config;
});

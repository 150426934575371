'use strict';
define([], function() {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'Canonical',
            categoryState:'app.seospider.exam.analysis.canonical',
            fields:[
                {label:'seospider.canonical.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.canonical.defined',icon:'icon icon-check',uiSref:'.defined'},
                {label:'seospider.canonical.withoutCanonical',icon:'icon icon-ban',uiSref:'.missing'}
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

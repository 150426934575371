'use strict'
define([], function () {
    function Controller($q, TrackEventService, stToggleExpand, $scope, $state, $stateParams, $timeout, CopyMetrics, userInfo, stLimitMessage, LanguageService, $window) {
        function _constructor() {
            copymetrics();
        }

        var vm = $scope;
        vm.newAnalysis = copymetrics;
        vm.query = {
            selectedMode: 'overview',
            url: $stateParams.url,
            //countryCode: $stateParams.countryCode || 'US',
        };
        vm.expandSection = stToggleExpand.isExpanded('copymetrics-toggle') || false;


        vm.sections = {
            header: true,
            snippetOverview: false,
            relevantKeywords: true,
            keywordsSuggestions: true,
            textOverview: true,
            headings: true,
            images: true,
            links: true,
        };

        vm.results = {
            detailData: null,
            isLoading: true,
        };

        var lastRequestDone = false;

        function setLoading() {
            vm.results.isLoading = true;
            lastRequestDone = JSON.stringify(vm.query);
            vm.results.messageWhenEmptyDataTitle = null;
            vm.results.messageWhenEmptyDataSubtitle = null;
            vm.results.messageWhenEmptyDataIcon = null;
            vm.results.messageWhenEmptyDataCta = null;
        }

        function copymetrics() {
            if (vm.results.isLoading && lastRequestDone === JSON.stringify(vm.query)) {
                return;
            }
            $state.go('app.copymetrics.analyzer', vm.query, {notify: false, reload: false});
            setLoading();
            CopyMetrics.get({
                url: vm.query.url,
            })
                .then(function (results) {
                    TrackEventService.event(
                        'Suite Usages',
                        'Copy Metrics - Audit', 'URL');
                    TrackEventService.gaV2Event(
                        'copy_audit', {
                            type: "URL",
                            tool: "Copy Metrics"
                        });

                    lastRequestDone = false;
                    vm.results.isLoading = false;
                    vm.results.detailData = results;
                })
                .catch(function (err) {
                    vm.results.detailData = {};
                    lastRequestDone = false;
                    vm.results.isLoading = false;
                    if (err.status === 429 || err.status === 426) {
                        vm.results.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                        vm.results.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                        vm.results.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                        vm.results.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                    }
                });
        }

        _constructor();
    }

    Controller.$inject = ['$q', 'TrackEventService', 'stToggleExpandSectionService', '$scope', '$state', '$stateParams', '$timeout', 'CopyMetricsService', 'userInfo', 'stLimitMessage', 'LanguageService', '$window'];
    return Controller;
});

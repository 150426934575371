/**
 * @ngdoc overview
 * @name cacheManagerService.service:CacheManagerService
 */
'use strict';
define(['app'], function (app) {
    app.service('CacheManagerService', CacheManagerService);

    function CacheManagerService() {
        return {
            hash: hash,
            set: set,
            get: get,
            remove: remove,
        };

        function hash(string) {
            return window.btoa(unescape(encodeURIComponent(string)));
        }

        /**
         * @param {string} key
         * @param {object} value
         * @param ttl
         */
        function set(key, value, ttl = null, useSessionStorage = false) {
            var now = new Date();

            if (!ttl) {
                // seconds * minutes * hours = 1 day
                ttl = 60 * 60 * 24;
            }

            // From milliseconds to seconds
            var ttlSeconds = ttl * 1000;
            var item = {
                value: value,
                expire: now.getTime() + ttlSeconds,
            };

            try {
                if (useSessionStorage) {
                    sessionStorage.setItem(key, JSON.stringify(item));
                } else {
                    localStorage.setItem(key, JSON.stringify(item));
                }
            } catch (err) {
                console.error(err);
            }
        }

        function get(key, useSessionStorage = false) {
            var itemStr;
            if (useSessionStorage) {
                itemStr = sessionStorage.getItem(key);
            } else {
                itemStr = localStorage.getItem(key);
            }


            if (!itemStr) {
                return null;
            }

            var item = JSON.parse(itemStr);
            var now = new Date();

            if (now.getTime() > item.expire) {
                if (useSessionStorage) {
                    sessionStorage.removeItem(key);
                } else {
                    localStorage.removeItem(key)
                }
                return null
            }

            return item.value;
        }

        function remove(key, useSessionStorage = false) {

            if (useSessionStorage) {
                sessionStorage.removeItem(key);
            } else {
                localStorage.removeItem(key);
            }
        }
    }
});

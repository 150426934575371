/**
 * @ngdoc overview
 * @name pagespeed.service:PagespeedService
 *
 * @description
 * Aggregate External function for Pagespeed Module
 */
'use strict';
define(['app'], function (app) {
    var API_END_POINT = 'https://u780ztpq54.execute-api.eu-west-1.amazonaws.com/Prod';
    app.service('CoreWebVitalsService', CoreWebVitalsService);
    CoreWebVitalsService.$inject = ['$http', '$sce', '__env'];

    /**
     * Pagespeed Service
     * @namespace PagespeedService
     */
    function CoreWebVitalsService($http, $sce, __env) {
        this.audit = function (params) {
            if (!params || !params.url) {
                console.warn('Missing required urls for CoreWebVitalsService analysis');
                return -1;
            }
            var requestPayload = {
                "url": params.url,
                "language": params.language || 'en',
                /*"strategy": params.strategy||'desktop'*/
            };

            var requestParams = {
                method: 'POST',
                url: $sce.trustAsUrl(API_END_POINT + '/page-speed-google'),
                data: requestPayload,
                headers: {
                    'Authorization': null,
                    'Content-Type': 'application/json',
                    'x-api-key': 'A5ePJkvJSg6DRCQAK9DoY2pgUJesCWZ1U0ql3fp2'
                },
            };
            return $http(requestParams);
        };
    }
});

export default {
    templateUrl: 'app/components/st-number-input/st-number-input.html',
    bindings: {
        model:'=',
        size:'@',
        label:'@',
        subLabel:'@',
        min:'<',
        max:'<',
    },
    controller: ['$scope', function ($scope) {

        const vm=this;
        let startingValue=null;
        // Access `mainKeyword` in the controller
        vm.$onInit = function() {
            startingValue=JSON.parse(JSON.stringify(vm.model));
            if(vm.min===null||vm.min===undefined){
                vm.min=1;
            }

        };
        $scope.reset=function (){
            vm.model=startingValue;
        };
        function valueToAddOrRemove(){
            if(vm.model<25){
                return 1;
            }
            if(vm.model<50){
                return 5;
            }
            if(vm.model<100){
                return 10;
            }
            if(vm.model<1000){
                return 50;
            }
            if(vm.model<2000){
                return 100;
            }
            if(vm.model<3000){
                return 200;
            }
            if(vm.model<5000){
                return 500;
            }
            return 1000;
        }
        $scope.increase=function (){

            const toShift=valueToAddOrRemove();
            if(vm.model+toShift>vm.max){
                vm.model=vm.max;
                return;
            }
            vm.model+=toShift;
        };
        $scope.decrease=function (){
            const toShift=valueToAddOrRemove();
            if(vm.model-toShift<vm.min){
                vm.model=vm.min;
                return;
            }
            vm.model-=toShift;
        };
    }]
};

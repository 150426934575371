'use strict';
define(['app'], function (app) {
    app.component('stExportCsv', {
        templateUrl: 'app/components/st-export-csv/st-export-csv.html',
        controller: controller,
        bindings: {
            options: "="
        }
    });

    controller.$inject = ['$scope', 'ExportService', 'TrackEventService'];

    function controller($scope, ExportService, TrackEventService) {
        var ctrl = this;

        $scope.$watch('$ctrl.options', function (options) {
            if (!options) {
                return;
            }
            ctrl.options.stoTool = options.stoTool;
            ctrl.options.class = options.class;
            ctrl.options.icon = options.icon;
            ctrl.options.text = options.text;
            ctrl.options.data = options.data;
            ctrl.options.limit = options.limit;
            ctrl.options.fileName = options.fileName;
        });

        ctrl.exportCsv = function (rows, limit, fileName) {
            ExportService.checkExportAvailability()
                .then(function () {
                    var csvContent = "";
                    var counter = 0;

                    if (!limit) {
                        limit = rows.length - 1;
                    }

                    rows.forEach(function (rowArray) {
                        if (counter < limit) {
                            var row = rowArray.join(",");
                            csvContent += row + "\r\n";
                            counter++;
                        }
                    });

                    var blob = new Blob(["\ufeff", csvContent]);
                    var encodedUri = URL.createObjectURL(blob);

                    //var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", fileName + ".csv");

                    document.body.appendChild(link);
                    link.click();

                    switch (ctrl.options.stoTool) {
                        case "keyword-explorer":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword Explorer');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword Explorer",
                                    type: "csv"
                                });
                            break;
                        case "serp-report":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'SERP Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "SERP Checker",
                                    type: "csv"
                                });
                            break;
                        case "keyword-list-manager":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Keyword List Manager');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Keyword List Manager",
                                    type: "csv"
                                });
                            break;
                        case "rank-tracker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Rank Tracker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Rank Tracker",
                                    type: "csv"
                                });
                            break;
                        case "backlink-checker":
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX', 'Backlink Checker');
                            TrackEventService.gaV2Event(
                                'export',{
                                    tool: "Backlink Checker",
                                    type: "csv"
                                });
                            break;
                        default :
                            TrackEventService.event(
                                'Suite Usages',
                                'Export CSV & XLSX');
                            TrackEventService.gaV2Event(
                                'export',{
                                    type: "csv"
                                });
                            break;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        };
    }
});

'use strict';
define([],function(){
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/canonical/components/table.html',
        bindings: { pages: '=' },
        controller:['$scope',function($scope){
            var vm=$scope;
            $scope.$watch('$ctrl.pages',function(pages){
                vm.pages=pages;
            });
            if(vm.pages==undefined) vm.pages=[];
        }]
    };
});
'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/title/all/all.controller',
],function(
    angular,
    MDAllCtrl
){
    var app=angular.module('app.seospider.exam.analysis.title.all', []);
    app.controller("TitleAllCtrl",MDAllCtrl);
    return app;
});
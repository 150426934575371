/**
 * @ngdoc overview
 * @name keywordlist.service:KeywordListService
 *
 */
'use strict';
define(['app'], function (app) {
    app.service('KeywordListService', KeywordListService);
    KeywordListService.$inject = ['API', '__env', '$q'];

    function KeywordListService(API, __env, $q) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.keywordListAPI;

        this.createAKeywordList = function (params) {
            return API.post(apiPath, params, true, true);
        };

        this.getSharedKeywordList = function (token) {
            if (!token || typeof token !== 'string') {
                var dfd = $q.defer();
                dfd.reject(new Error('invalid token'));
                return dfd.promise;
            }
            return API.get(apiPath + '/shared/' + token, true, true);
        };

        this.getAllKeywordList = function () {
            return API.get(apiPath, true, true);
        };

        this.addQueries = function (keywordListId, queries) {
            return API.put(apiPath + '/' + keywordListId + '/queries', {queries: queries}, true);
        };

        this.addKeywords = function (keywordListId, keywords, countries) {
            return API.put(apiPath + '/' + keywordListId + '/keywords', {
                keywords: keywords,
                countries: countries
            }, true);
        };

        this.deleteKeywords = function (keywordListId, keywordUuids) {
            var query = '';
            for (var i = 0, j = keywordUuids.length; i < j; i++) {
                query += "&keywordUuids[]=" + keywordUuids[i];
            }
            return API.delete(apiPath + '/' + keywordListId + '/keywords?' + query, null, true);
        };

        this.getKeywordListByQuery = function (params) {
            if (!params || !params.keywordUuids || params.keywordUuids.length <= 0) {
                var dfd = $q.defer();
                dfd.reject(API.formatError('No keywords in list'));
                return dfd.promise;
            }
            return API.post(apiPath + '/lists-queries', {
                keywordUuids: params.keywordUuids
            }, true, true);
        };

        this.updateKeywordList = function (keywordListName, keywordListId) {
            return API.put(apiPath + '/' + keywordListId, {name: keywordListName, toolSourceId: 1}, true);
        };

        this.cloneKeywordList = function (keywordListName, keywordListId) {
            return API.post(apiPath + '/' + keywordListId + '/clone', {name: keywordListName, toolSourceId: 1}, true);
        };

        this.moveToKeywordList = function (queriesIds, moveToKeywordListId, keywordListIdSource) {
            return API.put(apiPath + '/ ' + keywordListIdSource + '/move', {
                queriesIds: queriesIds,
                moveToKeywordListId: moveToKeywordListId,
            }, true);
        };

        this.deleteKeywordList = function (keywordListIds) {
            var query = '';
            for (var i = 0, j = keywordListIds.length; i < j; i++) {
                query += "&keywordListIds[]=" + keywordListIds[i];
            }
            return API.delete(apiPath + '/delete?' + query, null, true);
        };

        this.getOneKeywordList = function (keywordListId) {
            if (!keywordListId) {
                var dfd = $q.defer();
                dfd.reject(API.formatError('No keyword list selected'));
                return dfd.promise;
            }

            return API.get(apiPath + '/' + keywordListId, true, true);
        };
    }
});

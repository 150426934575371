'use strict';
define([], function() {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'seospider.lang',
            categoryState:'app.seospider.exam.analysis.languages',
            fields:[
                {label:'seospider.lang.allPages',icon:'icon icon-like', uiSref:'.all'},
                {label:'seospider.lang.withoutXDefault',icon:'icon icon-size-actual', uiSref:'.noXDefault'},
                {label:'seospider.lang.withoutHreflang',icon:'icon icon-ban', uiSref:'.missing'}
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

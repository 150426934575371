'use strict';
define([], function () {
    function InvoicesController($scope, WorkspaceService, $localStorage, $timeout) {
        var vm = $scope;
        vm.workspace = $localStorage.currentUser.workspace;
        vm.isLoading = true;
        vm.invoices = [];

        function download(filename, url) {
            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }

        function downloadInvoice(invoice) {
            invoice.isDownloadLoading = true;

            WorkspaceService
                .createInvoice(invoice)
                .then(function (result) {
                    $timeout(function () {
                        invoice.isDownloadLoading = false;
                    }, 500);

                    download(result.name, result.url);
                })
                .catch(function (err) {
                    invoice.isDownloadLoading = false;
                    console.log(err);
                });
        }

        vm.controllerInvoices = {
            downloadInvoice: downloadInvoice,
        }

        WorkspaceService.getInvoicesWorkspace()
            .then(function (res) {
                if (res && res.invoices && res.invoices.length) {
                    res.invoices.map(function(invoice) {
                        invoice.status = 'settings.invoices.paid';
                        return invoice;
                      });
                    vm.invoices = res.invoices;
                }
                vm.isLoading = false;
            })
            .catch(function (err) {
                console.log(err)
                vm.isLoading = false;
            });

        WorkspaceService.getNextInvoices()
            .then(function (res) {
                if (res && res.invoices && res.invoices.length) {
                    res.invoices.map(function(invoice) {
                        invoice.id = '#';
                        invoice.status = 'settings.invoices.next';
                        return invoice;
                      });

                    Array.prototype.push.apply(res.invoices, vm.invoices);
                    vm.invoices = res.invoices;
                }
                vm.isLoading = false;
            })
            .catch(function (err) {
                console.log(err)
                vm.isLoading = false;
            });
    }

    InvoicesController.$inject = ['$scope', 'WorkspaceService', '$localStorage', '$timeout'];
    return InvoicesController;
});

'use strict';
define([], function () {
    function SubscriptionsController($scope, subscriptionsInfo, canDoTrial, paymentMethods, $mdDialog, SubscriptionService, $mdToast, TrackEventService, LanguageService, $window, __env, WorkspaceService) {
        var vm = $scope;
        vm.goToContactUs = goToContactUs;
        vm.goToConfirmPayment = goToConfirmPayment;
        vm.subscriptionList = subscriptionsInfo.data;
        vm.changeRenewal = changeRenewal;
        vm.countActivePlansForSubscriptions = countActivePlansForSubscriptions;
        vm.trialPeriodDays = __env.trialPeriodDays;
        vm.canDoTrial = canDoTrial;

        vm.activeSubscriptionList = vm.subscriptionList.filter(function (sub) {
            return sub.status === "past_due" || sub.status === "active" || sub.status === "trialing" || sub.status === "incomplete";
        });

        vm.inactiveSubscriptionList = vm.subscriptionList.filter(function (sub) {
            return sub.status === "canceled" || sub.status === "incomplete_expired";
        });

        vm.activeStripeSubscription = vm.activeSubscriptionList.filter(function (sub) {
            return sub.gateway == 'stripe';
        });

        if (vm.activeStripeSubscription.length && paymentMethods.methods.stripe.length) {
            vm.defaultStripeCard = paymentMethods.methods.stripe[0];
        }

        WorkspaceService.getNextInvoices()
            .then(function (res) {
                vm.renewalCostIsLoading = true;
                if (res && res.invoices && res.invoices.length) {

                    vm.nextInvoices = res.invoices;
                }
                vm.renewalCostIsLoading = false;
            })
            .catch(function (err) {
                console.log(err)
                vm.renewalCostIsLoading = false;
            });

        vm.getRenewalCostBySubscriptionId = function(subscriptionId) {
            if (!vm.nextInvoices) {
                return;
            }
            
            var invoice = vm.nextInvoices.filter(function(invoice) {
                return invoice.subscriptionId == subscriptionId;
            });

            if (invoice.length) {
                return invoice[0].total;
            }

            return null;
        }

        vm.dateDiff = function (dateOne, dateCreation) {
            if (dateOne == null) dateOne = new Date();
            else dateOne = new Date(dateOne);
            var newDate = dateOne.getTime() - new Date(dateCreation).getTime();
            var timeDiff = Math.abs(newDate);
            return Math.ceil(timeDiff / (1000 * 3600 * 24));
        };

        vm.hoursDiff = function (dateCreation) {
            dateCreation = new Date(dateCreation);
            var dateCreationPlus24Hours = new Date(dateCreation);
            dateCreationPlus24Hours = dateCreationPlus24Hours.setDate(dateCreationPlus24Hours.getDate() + 1);
            var newDate = new Date(dateCreationPlus24Hours).getTime() - dateCreation;
            var timeDiff = Math.abs(newDate);
            return Math.ceil(timeDiff / (1000 * 3600));
        };

        vm.hadPitchGroundSub = subscriptionsInfo.hasPitchGroundSub;

        vm.controllerInactiveSub = {
            countActivePlansForSubscriptions: countActivePlansForSubscriptions
        }


        function changeStatusFailed(sub, err) {
            sub.loadingRenewal = false;

            if (err && err.message && err.message === 'subscription removed') {
                sub.renewal = false;
                sub.removedFromGateway = 1;

                $mdToast.show({
                    template:
                        '<md-toast class="md-toast" >' +
                        '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i>{{"error.removed.subscription"|translate}}</span>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
                return
            }

            $mdToast.show({
                template:
                    '<md-toast class="md-toast" >' +
                    '<span layout="row" layout-align="start center"><i class="material-icons st-red m-r">clear</i>{{"error.generic"|translate}}</span>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });

            console.log(err);
        }

        function changeRenewal(ev, sub, renewal) {

            if (!renewal) {
                showChurnDialog(ev, sub, renewal);
                return;
            }

            sub.loadingRenewal = true;

            // Call API for Update Status Subscription
            SubscriptionService.setRenewal({subscriptionId: sub.id, renewal: true})
                .then(function () {
                    sub.loadingRenewal = false;
                    sub.renewal = true;
                    TrackEventService.event('Funnel Events', 'Auto Renewal - Activated');
                    TrackEventService.gaV2Event('auto_renewal_activated');
                })
                .catch(function (res) {
                    if (!res || !res.data || !res.data.error) {
                        return;
                    }

                    changeStatusFailed(sub, res.data.error);
                });
        }

        function countActivePlansForSubscriptions(sub) {
            return sub.plans.length;
        }

        function goToContactUs() {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();
            if (language === 'it-IT') {
                $window.open("https://it.seotesteronline.com/contatti", '_blank');
            } else if (language === 'es-ES') {
                $window.open(" https://es.seotesteronline.com/contacts", '_blank');
            } else {
                $window.open("https://www.seotesteronline.com/contacts", '_blank');
            }
        }


        function goToConfirmPayment(subscription) {
            if (subscription && subscription.confirmPaymentLink) {
                $window.open(subscription.confirmPaymentLink, '_blank');
            }
        }

        function showChurnDialog(ev, sub, renewal) {
            $mdDialog.show({
                templateUrl: "app/modules/settings/dialogs/churn-flow/churn-flow.html",
                controller: "ChurnFlowCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    sub: sub,
                    renewal: renewal
                }
            }).then(function (answer) {
                if (answer && answer === 'subscription-deactivated') {
                    sub.renewal = false;
                    sub.removedFromGateway = 1;
                }
            }).catch(function (err) {
                console.log(err);
            });
        }
    }

    SubscriptionsController.$inject = ['$scope', 'subscriptionsInfo', 'canDoTrial', 'paymentMethods', '$mdDialog', 'SubscriptionService', '$mdToast', 'TrackEventService', 'LanguageService', '$window', '__env', 'WorkspaceService'];
    return SubscriptionsController;
});

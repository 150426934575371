'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/dashboard/components/latest-feature/latest-feature.html',
        bindings: {

        },
        controller: ['$scope', '$window', function ($scope, $window) {

            // Controlla se il box è già stato nascosto
            $scope.hideLatestFeature = $window.localStorage.getItem('hideLatestFeature') === 'true';

            // Funzione per chiudere il box e salvare la preferenza in localStorage
            $scope.closeFeatureBox = function() {
                $scope.hideLatestFeature = true;
                $window.localStorage.setItem('hideLatestFeature', 'true');
            };

        }]
    };
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/robots/all/all.controller',
],function(
    angular,
    SPRobotsAllCtrl
){
    var app=angular.module('app.seospider.exam.analysis.robots.all', []);
    app.controller("SPRobotsAllCtrl",SPRobotsAllCtrl);
    return app;
});
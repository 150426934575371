'use strict';
define([
    'angular',
	'modules/copymetrics/copymetrics.routes',
    'modules/copymetrics/copymetrics.controller',
    'modules/copymetrics/components/copymetrics-text-analysis/copymetrics-text-analysis.component',
    'modules/copymetrics/components/copymetrics-heatmap/copymetrics-heatmap.component',
    'modules/copymetrics/analyzer/analyzer.module'
], function (angular, routes,CopymetricsLandingCtrl,copymetricsTextAnalysis, copymetricsHeatmap) {
	var app = angular.module('app.copymetrics', [
        'app.copymetrics.analyzer'
	]);
    app.config(routes);
    app.controller("CopymetricsLandingCtrl", CopymetricsLandingCtrl);
    app.component("copymetricsTextAnalysis", copymetricsTextAnalysis);
    app.component("copymetricsHeatmap", copymetricsHeatmap);
	return app;
});

'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/st-battery/st-battery.html',
        bindings: { score: '=', segments:'=' },
        controller:['$scope',function($scope){
            $scope.segmentsArray=[];
            $scope.$watch('$ctrl.score',function (score) {
                $scope.score=score;

                $scope.segmentToActive=($scope.score*$scope.segments)/100;
            });
            $scope.$watch('$ctrl.segments',function (segments) {
                $scope.segments=segments;
                $scope.segmentsArray=new Array(segments);
                $scope.segmentToActive=($scope.score*segments)/100;
            });
            $scope.segmentToActive=($scope.score*$scope.segments)/100;
        }]
    };
});
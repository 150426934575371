define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.copymetrics", {
                abstract: true,
                templateUrl: './app/modules/copymetrics/copymetrics.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.copymetrics.landing", {
                url: "/copy-metrics",
                templateUrl: './app/modules/copymetrics/landing.html',
                controller: 'CopymetricsLandingCtrl',
                resolve: {
                    userCountry: ['$translate', function () {
                        return localStorage.getItem('countrySelectCM');
                    }]
                }
            })
            .state("app.copymetrics.analyzer", {
                url: "/copy-metrics/analysis?:url&:countryCode",
                templateUrl: './app/modules/copymetrics/analyzer/analyzer.html',
                controller: 'CopymetricsCtrl',
                params: {
                    url: null,
                    countryCode: null,
                },
                resolve: {
                    userInfo: ['UserService', function (UserService) {
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data;
                            }).catch(function (err) {
                                return null;
                            });
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

define(['angular','app'],function(angular,app) {
    app.directive('grammarCorrection', ['$sce','GrammarService', function ($sce,Grammar) {
        return {
            restrict: 'A', // only activate on element attribute
            scope: {
                ngGrammar:'='
            },
            link: function ($scope, element, attrs) {
                doGrammarCheck();
                var lastGrammar='';
                function doGrammarCheck(){
                    var content=element.html();
                    if(lastGrammar!=content&&content.length>1){
                        lastGrammar=content;
                        Grammar.check({
                            language: 'it',
                            text: content
                        }).then(function (corrections) {
                            $scope.ngGrammar(corrections.matches);
                            //element.html(markMyWords(content,corrections.matches));
                            setTimeout(doGrammarCheck,9000);
                        });
                    }
                }

                // markMyWords
                function markMyWords(contentHtml,suggestions) {

                    var subs='';
                    for(var i=suggestions.length-1;i>=0;i--){
                        subs=contentHtml.substr(suggestions[i].offset,suggestions[i].length);
                        if(subs.indexOf("<")==-1&&subs.indexOf(">")==-1){
                            contentHtml=(contentHtml.substr(0,suggestions[i].offset)||'')+
                                '<st-grammar-hl>'+subs+'</st-grammar-hl>'+
                                (contentHtml.substr(suggestions[i].offset+suggestions[i].length,contentHtml.length)||'');
                        }
                    }
                    return contentHtml;
                };
            }
        };
    }]);
});

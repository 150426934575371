'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/twittercard/twittercard.routes',
    'modules/seospider/exam/analysis/twittercard/twittercard.controller',
    'modules/seospider/exam/analysis/twittercard/all/all.controller',
    'modules/seospider/exam/analysis/twittercard/optimized/optimized.controller',
    'modules/seospider/exam/analysis/twittercard/not-optimized/not-optimized.controller',
],function(
    angular,
    Routes,
    TwittercardCtrl,
    TwittercardAllCtrl,
    TwittercardOptimizedCtrl,
    TwittercardNotOptimizedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.twittercard', [
    ]);
    app.config(Routes);
    app.controller('TwittercardCtrl',TwittercardCtrl);
    app.controller('TwittercardAllCtrl',TwittercardAllCtrl);
    app.controller('TwittercardOptimizedCtrl',TwittercardOptimizedCtrl);
    app.controller('TwittercardNotOptimizedCtrl',TwittercardNotOptimizedCtrl);
    return app;
});
define([], function(){
    function config($stateProvider) {
        $stateProvider
        .state("app.seospider.exam.analysis.content",{
            controller:'ContentCtrl',
            abstract:true,
            templateUrl: './app/modules/seospider/exam/analysis/content/content.html',
            access: {restricted: true}
        })
        .state("app.seospider.exam.analysis.content.all",{
            url:"/content",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'AllContentCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.content.low",{
            url:"/content/low-level-text",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'TxtLevLowCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        textLevel:'low',
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.content.med",{
            url:"/content/medium-text-level",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'TxtLevMedCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        jobId:$stateParams.sessionId,
                        textLevel:'med',
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.content.high",{
            url:"/content/high-level-of-text",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'TxtLevHighCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        textLevel:'high',
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.content.lowRatio",{
            url:"/content/low-text-code-ratio",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'LowRatioCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        lowRatio:'low',
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
        .state("app.seospider.exam.analysis.content.highRatio",{
            url:"/content/high-text-code-ratio",
            templateUrl: './app/modules/seospider/exam/analysis/content/all/all.html',
            access: {restricted: true},
            controller:'HighRatioCtrl',
            resolve:{
                results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                    var dfd = $q.defer();
                    Pages.findAll({
                        lowRatio:'high',
                        jobId:$stateParams.sessionId,
                        fields:['id','url','title','depth','wordCount','ratioTextCode'],
                    }).then(function(result){
                        dfd.resolve(result);
                    },function(err){
                        dfd.resolve(err.data);
                    });
                    return dfd.promise;
                }],
                jobId:['$stateParams', function($stateParams) {
                    return $stateParams.sessionId;
                }]
            }
        })
    };
    config.$inject=['$stateProvider'];
    return config;
});

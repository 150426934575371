'use strict';
define(['app'], function (app) {
    app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(['$location', '$injector', '$localStorage', '$q', function ($location, $injector, $localStorage, $q) {
            var enabled = 0;

            function existElmentsArraOneInArrayTwo(permissionsWorkspace, permissionsElement) {
                if (typeof permissionsWorkspace === 'string') {
                    permissionsWorkspace = JSON.parse(permissionsWorkspace);
                }

                if (Array.isArray(permissionsWorkspace)) {
                    return permissionsWorkspace.some(function (r) {
                        return permissionsElement.indexOf(r) >= 0;
                    });
                }
            }

            function checkPermissions(permissionsAvailable) {
                if (enabled === 1) {
                    return $q.resolve();
                }

                if (!$localStorage.currentUser || !$localStorage.currentUser.workspace || !$localStorage.currentUser.workspace.permissions) {
                    return $q.resolve();
                }

                var permissionsUser = $localStorage.currentUser.workspace.permissions;
                if (Array.isArray(permissionsAvailable) && permissionsAvailable.length && existElmentsArraOneInArrayTwo(permissionsUser, permissionsAvailable)) {
                    return $q.resolve();
                }

                if (!permissionsUser || !permissionsAvailable || permissionsUser.indexOf(permissionsAvailable) >= 0) {
                    return $q.resolve();
                }

                enabled = 1;

                var stAlertLimitService = $injector.get('stAlertLimitService');
                var $state = $injector.get('$state');

                return $state
                    .go('app.welcome', {}, {notify: true, location: 'replace'})
                    .then(function () {
                        return stAlertLimitService.open('auth-login', {
                            error: {
                                request: {
                                    alias: 'permissions'
                                }
                            }
                        });
                    });
            }

            var locationsPermissions = {
                'seo-checker': ['seo_checker_edit'],
                'keyword-explorer': ['keyword_explorer_edit'],
                'copy-metrics': ['copy_metrics_edit'],
                'seo-editor': ['editor_read'],
                'lead-generation': ['lead_gen_read'],
                'keyword-list-manager': ['keyword_list_read'],
                'choose-keywords': ['keyword_list_edit'],
                'rank-tracker': ['project_read'],
                'add-keywords': ['project_edit'],
                'settings/customizations': ['workspace_edit'],
                '/settings/payments-methods': ['workspace_payment_methods_read'],
                '/settings/billing-info': ['workspace_payment_methods_read'],
                '/settings/subscriptions': ['workspace_subscriptions_read'],
                '/settings/logs': ['workspace_log_read'],
                '/settings/invoices': ['workspace_invoice_read'],
                '/settings/users': [
                    'workspace_user_admin',
                    'workspace_user_manager',
                    'workspace_user_bookkeeper',
                    'workspace_user_marketer',
                    'workspace_user_client'
                ],
            }
            return {
                response: function (res) {
                    angular.forEach(locationsPermissions, function (permissions, location) {
                        if ($location.url().indexOf(location) > -1) {
                            checkPermissions(permissions);
                        }
                    });

                    return res;
                },
            };
        }]);
    }]);
});

'use strict';
define(['angular'], function(ng) {
    function H1Ctrl($scope) {
        var vm=$scope;
        vm.showToolbarDetail=false;
        vm.tabs={
            categoryTitle:'H1',
            categoryState:'app.seospider.exam.analysis.h1',
            fields:[
                {label:'seospider.h1.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.h1.over',icon:'icon icon-speedometer',uiSref:'.over'},
                {label:'seospider.h1.missing',icon:'icon icon-close',uiSref:'.missing'},
                {label:'seospider.h1.multiple',icon:'icon icon-grid',uiSref:'.multiple'},
                {label:'seospider.h1.duplicate',icon:'icon icon-docs',uiSref:'.duplicated'},
            ]
        };
        vm.evalOccurences=evalOccurences;
        vm.evalLength=evalLength;

        function evalOccurences(score) {
            score=parseInt(score)||0;
            if (score == 1)
                return 'green-text';
            if (score == 0)
                return 'red-text';
            else  return 'text-darken-1 orange-text';
        }
        function evalLength(len) {
            len=parseInt(len)||0;
            if (len >70)
                return 'red-text';
            if (len >15&&len<=70)
                return 'green-text';
            if (len >=5&&len<=15)
                return 'text-darken-1 orange-text';
            return 'red-text';
        }
    }
    H1Ctrl.$inject=['$scope'];
    return H1Ctrl;
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/pages-downloaded/pages-downloaded.html',
        bindings: {
            codesByMinute: '<',
            inAnimation: '<'
        },
        controller: ['$scope', '$filter', '$mdMedia', function ($scope, $filter, $mdMedia) {
            var vm = $scope;
            vm.$mdMedia = $mdMedia;
            vm.dataSuccess = [];
            vm.dataRedirect = [];
            vm.dataError = [];
            vm.isLoaded = false;
            vm.colors = ['#0BC7B5', '#FFE3D8', '#FF0066'];
            vm.labels = [];
            vm.data = [
                vm.dataSuccess,
                vm.dataRedirect,
                vm.dataError
            ];

            vm.datasetOverride = [{
                label: "Success (2xx)",
                borderWidth: 0,
                backgroundColor: '#0BC7B5'
            },
                {
                    label: "Redirect (3xx)",
                    borderWidth: 0,
                    backgroundColor: '#FFE3D8'
                },
                {
                    label: "Client Error (4xx)",
                    borderWidth: 0,
                    backgroundColor: '#FF0066'
                }
            ];

            vm.chartOpts = {
                responsive: true,
                maintainAspectRatio: false,
                animation: {},
                legend: {
                    display: false,
                    align: 'left',
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    boxWidth: 10,
                },
                title: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        display: false,
                        gridLines: {
                            color: 'transparent',
                            zeroLineColor: 'transparent'
                        },
                        categoryPercentage: 0.9,
                        barPercentage: 0.9,
                    }],
                    yAxes: [{
                        position: 'right',
                        stacked: true,
                        display: true,
                        gridLines: {
                            color: 'rgba(118, 110, 148, 0.12)',
                            zeroLineColor: 'rgba(118, 110, 148, 0.05)',
                        },
                        ticks: {
                            suggestedMax: 80,
                        }
                    }]
                }
            }

            vm.$watchCollection('$ctrl.codesByMinute', function (codesByMinute) {
                if (codesByMinute && codesByMinute.length) {
                    setData(codesByMinute);
                }
                if (codesByMinute && !codesByMinute.length) {
                    vm.dataSuccess = [0];
                    vm.dataRedirect = [0];
                    vm.dataError = [0];
                    vm.data = [
                        vm.dataSuccess,
                        vm.dataRedirect,
                        vm.dataError
                    ];
                    vm.labels = [];
                    vm.isLoaded = false;
                }
            });

            vm.$watch('$ctrl.inAnimation', function (inAnimation) {
                if (!inAnimation) {
                    vm.chartOpts.animation.duration = 0;
                }
            })

            function setData(codesByMinute) {
                var minColumnsToStamp = 20;
                var firstCrawlTimestamp;

                angular.forEach(codesByMinute, function (code, indexCodeByMinute) {
                    minColumnsToStamp--;
                    firstCrawlTimestamp = firstCrawlTimestamp || code.crawledAt;

                    if (!vm.labels.includes($filter('date')(code.crawledAt, 'HH:mm'))) {
                        vm.labels.push($filter('date')(code.crawledAt, 'HH:mm'));
                    }

                    var codeCounts = JSON.parse(code.counts)
                    angular.forEach(codeCounts, function (countForStatus) {
                        if (countForStatus.status === 200) {
                            vm.dataSuccess.splice(indexCodeByMinute, 1, countForStatus.count);
                        }

                        if (countForStatus.status === 301) {
                            vm.dataRedirect.splice(indexCodeByMinute, 1, countForStatus.count);
                        }

                        if (countForStatus.status === 404) {
                            vm.dataError.splice(indexCodeByMinute, 1, countForStatus.count);
                        }
                    });
                });

                // Define blank columns on first time
                if (vm.isLoaded || !firstCrawlTimestamp) {
                    return;
                }

                vm.isLoaded = true;
                for (var i = 0; i < minColumnsToStamp; i++) {
                    var newBlankDate = new Date(firstCrawlTimestamp + i * 60000);
                    vm.labels.push($filter('date')(newBlankDate, 'HH:mm'));
                }
            }
        }]
    };
});

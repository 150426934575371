'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/components/select-project/select-project.html',
        bindings: {
            inCrawling: '<',
            selectProject: '=',
            projectId: '<',
            projects: '<',
            projectSelected:'='
        },
        controller: ['$scope', function ($scope) {
            var vm = $scope;
            var ctrl = this;
            vm.projects = [];
            ctrl.projectSelected = {};
            ctrl.$onInit=function(){
                vm.selectedProjectId=ctrl.projectId;
                vm.projects=ctrl.projects||[];
            };

            vm.selectProject = function (project) {
                ctrl.selectProject(project);
            };
        }]
    };
});

/**
 * @ngdoc overview
 * @name backlinkchecker.service:BacklinkCheckerService
 */
'use strict';
define(['app'], function (app) {
    app.service('BacklinkCheckerService', BacklinkCheckerService);
    BacklinkCheckerService.$inject = ['API', '__env', '$http', '$q', 'CacheManagerService', 'AuthService'];

    function BacklinkCheckerService(API, __env, $http, $q, CacheManagerService, AuthService) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiBacklinkCheckerPath = apiBaseUrl + __env.backlinkCheckerAPI;

        return {
            getBacklinks: getBacklinks,
            getHistory: getHistory,
            saveInHistory: saveInHistory,
            updateHistory: updateHistory,
        };

        function saveInHistory(bodyRequest, filters) {
            var apiRequestUrl = apiBacklinkCheckerPath + '/history';
            var deferred = $q.defer();

            bodyRequest.filters = filters;


            API.post(apiRequestUrl, bodyRequest, true, true)
                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(null);
                });
            return deferred.promise;
        }

        function getBacklinks(domain, filters, offset, limit) {
            var apiRequestUrl = apiBacklinkCheckerPath + '/backlinks';
            var bodyRequest = {
                domain: domain,
                filters: filters,
                offset: offset ? offset : undefined,
                limit: limit ? limit : 500,
            };
            return API.post(apiRequestUrl, bodyRequest, true, true);
        }

        function updateHistory(historyId, bodyRequest, filters) {
            var apiRequestUrl = apiBacklinkCheckerPath + '/history';
            var deferred = $q.defer();
            bodyRequest.filters = filters;
            bodyRequest.historyId = historyId;


            API.patch(apiRequestUrl, bodyRequest, true, true)
                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(null);
                });
            return deferred.promise;
        }


        function getHistory() {
            var apiRequestUrl = apiBacklinkCheckerPath + '/history';
            var deferred = $q.defer();

            API.get(apiRequestUrl)
                .then(function (res) {
                    if (res && res.data) {
                        deferred.resolve(res.data);
                    } else {
                        deferred.resolve(null);
                    }

                })
                .catch(function (err) {
                    console.log(err);
                    deferred.resolve(null);
                });
            return deferred.promise;
        }
    }

});

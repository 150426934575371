controller.$inject = ['$scope', 'FavIconService'];

function controller($scope, FavIconService) {

    $scope.getImageSRCFromSource=function (stats){
        return FavIconService.get(stats.examples[stats.currentExampleIndex].source);
    };
    $scope.getDomainFromSource=function (stats){
        return FavIconService.getDomain(stats.examples[stats.currentExampleIndex].source);
    };

    const vm=this;
    // Access `mainKeyword` in the controller
    vm.$onInit = () => {
        if(!vm.keywords){
            vm.keywords=[];
            return;
        }
        // Se $scope.items è un oggetto, lo converto in un array di valori
        if (!Array.isArray(vm.keywords)) {
            vm.keywords = Object.values(vm.keywords);
        }
    };
    $scope.$watch('$ctrl.keywords', (newVal)=>{
        // Se $scope.items è un oggetto, lo converto in un array di valori
        if (!Array.isArray(newVal)) {
            vm.keywords = Object.values(newVal);
        }
    });
    $scope.FavIconService = FavIconService;
    $scope.nextExample = function (stats) {
        if (stats.currentExampleIndex < stats.examples.length - 1) {
            stats.currentExampleIndex++;
        }
    };
    $scope.prevExample = function (stats) {
        if (stats.currentExampleIndex > 0) {
            stats.currentExampleIndex--;
        }
    };
    $scope.showExample = function (keyword) {
        if(keyword.showExample){
            keyword.showExample = false;
            return;
        }
        // Ciclare su tutte le keyword e settare showMio=false
        for (let keyword in vm.keywords) {
            if (vm.keywords.hasOwnProperty(keyword)) {
                vm.keywords[keyword].showExample = false;
            }
        }
        keyword.showExample = true;

    };

    $scope.isKeywordStuffing=isKeywordStuffing;
    function isKeywordStuffing(keywordCount, threshold = null) {
        const totalWordCount=vm.contentWordsCount;
        if(!totalWordCount){
            return false;
        }
        if(!threshold){
            if (totalWordCount <= 500) {
                threshold = 0.05; // 5% for short content
            } else if (totalWordCount <= 1000) {
                threshold = 0.04; // 4% for content up to 1000 words
            } else if (totalWordCount <= 3000) {
                threshold = 0.03; // 3% for content up to 3000 words
            } else if (totalWordCount <= 5000) {
                threshold = 0.02; // 2% for content up to 5000 words
            } else if (totalWordCount <= 10000) {
                threshold = 0.015; // 1.5% for content up to 10,000 words
            } else {
                threshold = 0.01; // 1% for content over 10,000 words
            }
        }
        // Calculate keyword density
        const keywordDensity = keywordCount / totalWordCount;

        // Determine if keyword stuffing is present based on the threshold
        return keywordDensity > threshold;
    }


}

export default {
    templateUrl: 'app/modules/writerassistant/components/assistant-auditor-keywords/assistant-auditor-keywords.html',
    controller: controller,
    bindings: {
        keywords: "<",
        contentWordsCount: "<",
        filter: "<",
    }
};

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/http-status/components/table.component'
],function(
    angular,
    httpStatusTable
){
    var app=angular.module('app.seospider.exam.analysis.httpstatus.components',
        []
    );
    app.component("httpStatusTable",httpStatusTable);
    return app;
});
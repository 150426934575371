'use strict';
define(['angular'], function (angular) {


    return {
        templateUrl: 'app/modules/seochecker/components/exams-navigator/exams-navigator.html',
        bindings: {
            navigationIndex: '<',
            exams: '<',
            categories: '<',
            title: '<',
        },
        controller: ['$scope', '$location', '$filter', function ($scope, $location, $filter) {
            var vm = $scope;
            var ctrl=this;
            ctrl.$onInit = function () {
                if (ctrl.exams||ctrl.categories) {
                    //todo remove this if section.. you should use a standard format for every usecase using toSpy argument
                    vm.navigationIndex=convertElementToIndexFormat();
                    vm.navigationIndex = $filter('orderBy')(vm.navigationIndex, 'order', false);
                } else if (ctrl.navigationIndex) {
                    vm.navigationIndex=ctrl.navigationIndex||[];
                }
                if (ctrl.title){
                    vm.navigationIndex=ctrl.title;
                }
            };
            function convertElementToIndexFormat(){
                var navigationIndex =[];
                if (ctrl.exams) {
                    vm.title = "seochecker.audits";
                    angular.forEach(ctrl.exams, function(value, key) {
                        navigationIndex.push({id: value.id, title: value.title, order: value.order});
                    });

                } else if (ctrl.categories) {
                    vm.title = "seochecker.categories";
                    for (var property in ctrl.categories) {
                        navigationIndex.push({id: property.split(' ').join('_'), title: property, order: 0});
                    }
                }
                return navigationIndex;
            }
        }]
    };
});

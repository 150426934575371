'use strict';
define([], function () {
    function Controller(
        $state,
        $scope,
        $mdMedia,
        UserService,
        AuthService,
        SeospiderService,
        LanguageService,
        $window
    ) {
        $scope.$mdMedia = $mdMedia;
        $scope.projects = [];
        $scope.projectsCreated = 0;
        $scope.projectsAllowed = 0;
        $scope.formattedProjectsAllowed = 0;

        var goToWizard = function () {
            $state.go('app.project.wizard', {
                step: 'create-project',
                tool: 'seo-spider',
                from: 'seo-spider',
                firstproject: true
            });
        };

        var getProjects = function () {
            SeospiderService.findJobs({})
                .then(function (projects) {
                    if (!projects || projects.length <= 0) {
                        goToWizard();
                        return;
                    }

                    for(var i=0;i<projects.length;i++){
                        try{
                            projects[i].crawlerSession.result=JSON.encode(projects[i].crawlerSession.result);
                        }catch(err){

                        }
                    }
                    $scope.projects = projects;
                    $scope.projectsCreated = $scope.projects.length;
                })
                .catch(function (err) {
                    console.log(err);
                    goToWizard();
                });
        }

        var getUserLimits = function () {
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'seo-project-limit') {
                                $scope.projectsAllowed = service.qtyAllowed;
                            }
                        });
                    }

                    $scope.formattedProjectsAllowed = $scope.projectsAllowed;
                    if ($scope.projectsAllowed >= 9999) {
                        $scope.formattedProjectsAllowed = '∞';
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }

        var init = function () {
            if (!AuthService.isAuthenticated()) {
                goToWizard();
                return;
            }

            getProjects();
            getUserLimits();
        }

        init();
    }

    Controller.$inject = [
        '$state',
        '$scope',
        '$mdMedia',
        'UserService',
        'AuthService',
        'SeospiderService',
        'LanguageService',
        '$window'
    ];
    return Controller;
});

'use strict';
define([], function () {
    function SCLandingCtrl($scope, $state, $translate, $rootScope, TypedService, AuthService, LanguageService, $window) {

        var typed;
        var placeholder;
        $scope.typeOfAnalysis = 'seo-checker';
        $scope.placeholderInput = $translate.instant('seochecker.first.placeholder');

        if (!AuthService.isAuthenticated()) {
            $scope.placeholderInput = "";
            placeholder = [$translate.instant('seochecker.first.placeholder'), $translate.instant('seochecker.second.placeholder')];
            typed = TypedService.createTyped(placeholder, '#search');
        }

        $scope.$on("languageChanged", function () {
            if ($state.current.url === '/seo-checker') {
                $scope.placeholderInput = $translate.instant('seochecker.first.placeholder')
                if (!AuthService.isAuthenticated()) {
                    typed.destroy();
                    $scope.placeholderInput = "";
                    placeholder = [$translate.instant('seochecker.first.placeholder'), $translate.instant('seochecker.second.placeholder')];
                    typed = TypedService.createTyped(placeholder, '#search');
                }
            }
        });

        $scope.loading = false;
        $scope.isNotUrl = false;
        var correct_url = new RegExp(/^((http:\/\/|https:\/\/)?(www\.|^\.)?((([\-|\wæÆØøÅå|\d]+)\.)+([a-zA-ZæÆØøÅå]{1,10}))(\/?(([a-zA-ZZæÆØøÅå0-9_@]+\.([a-zA-ZZæÆØøÅå0-9]{3,6}))?(\??[-A-Za-zæÆØøÅå0-9+:&@#\/%=~'_\.?|]+))){0,1})$/gim);
        $scope.emptyPlace = function () {
            $scope.placeholderInput = "";
        };

        $scope.seochecker = function () {
            if ($scope.searchText && $scope.searchText.length > 0 && $scope.searchText.match(correct_url)) {
                if ($scope.typeOfAnalysis === 'seo-spider') {
                    $state.go('app.project.wizard', {url: $scope.searchText, from: 'seo-checker-landing', tool: 'seo-spider', step: 'create-project'});
                    return;
                }
                $state.go('app.seochecker.results.overview', {url: encodeURIComponent(btoa($scope.searchText))});
            } else {
                $scope.isNotUrl = true;
                setTimeout(function () {
                    $scope.isNotUrl = false;
                }, 5000);
            }
        };

    }

    SCLandingCtrl.$inject = ['$scope', '$state', '$translate', '$rootScope', 'TypedService', 'AuthService', 'LanguageService', '$window'];
    return SCLandingCtrl;
})
;

define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.title",{
                controller:'TitleCtrl',
                abstract:true,
                templateUrl: './app/modules/seospider/exam/analysis/title/title.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.title.all",{
                url:"/title",
                templateUrl: './app/modules/seospider/exam/analysis/title/all/all.html',
                access: {restricted: true},
                controller:'TitleAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllTitle({
                            jobId:$stateParams.sessionId
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.title.multiple",{
                url:"/title/multiple",
                templateUrl: './app/modules/seospider/exam/analysis/title/all/all.html',
                access: {restricted: true},
                controller:'titleAllCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllTitle({
                            jobId:$stateParams.sessionId,
                            multiple:true
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.title.missing",{
                url:"/title/missing",
                templateUrl: './app/modules/seospider/exam/analysis/title/all/all.html',
                access: {restricted: true},
                controller:'TitleMissingCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllTitle({
                            jobId:$stateParams.sessionId,
                            missing:true
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.title.over",{
                url:"/title/over",
                templateUrl: './app/modules/seospider/exam/analysis/title/all/all.html',
                access: {restricted: true},
                controller:'TitleOverCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllTitle({
                            jobId:$stateParams.sessionId,
                            size:'over'
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.title.duplicated",{
                url:"/title/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/title/duplicated/duplicated.html',
                access: {restricted: true},
                controller:'TitleDuplicatedCtrl',
                resolve:{
                    results: ['$stateParams','$q','SeospiderPagesService', function($stateParams,$q,Pages) {
                        var dfd = $q.defer();
                        Pages.findAllTitleDuplicated({
                            jobId:$stateParams.sessionId
                        }).then(function(result){
                            dfd.resolve(result);
                        },function(err){
                            dfd.resolve(err.data);
                        });
                        return dfd.promise;
                    }],
                    jobId:['$stateParams', function($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});

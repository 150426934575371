'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/access-components/st-signup/st-signup.html',
        bindings: {
            service: '=',
            redirectTo: '<',
            dialogMode: '<',
            params: '<'
        },
        controller: [
            '$mdToast',
            '$translate',
            '$window',
            '$scope',
            '$state',
            'AuthService',
            '$timeout',
            'Facebook',
            'TrackEventService',
            'UtilitiesService',
            '$stateParams',
            'WorkspaceService',
            '$mdMedia',
            '__env',
            'LanguageService',
            'UserService',
            '$location',
            'UsetifulService',
            function (
                $mdToast,
                $translate,
                $window,
                $scope,
                $state,
                Auth,
                $timeout,
                Facebook,
                TrackEventService,
                UtilitiesService,
                $stateParams,
                WorkspaceService,
                $mdMedia,
                __env,
                LanguageService,
                UserService,
                $location,
                UsetifulService,
            ) {
                // Permette all'integrazione Google Signin di selezionare l'account con cui entrare
                $window.___gcfg = {
                    isSignedOut: true
                };

                var ctrl = this;

                var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
                var thisTimeout;

                $scope.getPasswordStrenght = getPasswordStrenght;
                $scope.goSignin = goSignin;
                $scope.loginFacebook = loginFacebook;
                $scope.signup = signup;
                $scope.setPassType = setPassType;
                $scope.$mdMedia = $mdMedia;

                $scope.strengthPasswordIndicator = "weak";
                $scope.passType = 'password';
                $scope.checkClauses = false;
                $scope.loading = false;
                $scope.emailPattern = UtilitiesService.getEmailRegex();

                $scope.$watch('$ctrl.service', setService);
                $scope.$on('event:google-plus-signin-success', loginGoogleEvent);

                $scope.data = {
                    signup: {
                        email: null,
                        pass: null,
                        rePass: null,
                        lang: currentLang
                    },
                };

                ctrl.$onInit = function () {
                };

                var hash = $location.search().hash;
                if (hash) {
                    var params = {
                        hash: hash
                    };

                    WorkspaceService.getDetailWorkspaceFromHash(params)
                        .then(function (res) {
                            $scope.workspaceLogoUrl = res.logoImgUrl;
                            $scope.workspaceName = res.name;
                            params.id = res.id;
                            $scope.workspaceId = res.id;
                        })
                        .catch(function (err) {
                            console.log('err', err);
                        });
                }

                /**
                 * BOOTSTRAP USETIFUL
                 */
                function usetifulBootStrap() {
                    if (__env.stage === 'local') {
                        return;
                    }

                    var user = Auth.getSessionUser();
                    if (!user || !user.id) {
                        return;
                    }

                    var language = LanguageService.getLanguageCodeSaveOnStorage();
                    UserService.limits()
                        .then(function (res) {
                            if (!res || !res.data || !res.data.isActive) {
                                console.log('usetiful: no data res');
                                return null;
                            }

                            var account = res.data.account || 'offline';
                            var canDoTrial = res.data.canDoTrial || true;
                            var isTrial = res.data.isTrial || false;

                            UsetifulService.start(user.id, language, account, canDoTrial, isTrial);
                        }).catch(function (err) {
                        console.log(err);
                        return null;
                    });
                }

                function signup(form) {
                    if (!$scope.loading) {
                        $scope.loading = true;
                        $timeout.cancel(thisTimeout);

                        Auth.signup($scope.data.signup)
                            .then(function () {
                                if ($window.localStorage.getItem('coupon') === 'SITEGROUNDDISCOUNT') {
                                    $state.go('upgrade.checkout', {
                                        alias: 'plan-siteground-v1'
                                    });
                                } else {
                                    Auth.signin($scope.data.signup)
                                        .then(function (res) {
                                            usetifulBootStrap();

                                            if (ctrl.dialogMode) {
                                                $scope.$parent.cancel(); //call the parent method cancel() to close the dialog
                                            }

                                            $state.go('access.email-verification', {
                                                token: btoa(res.email),
                                                redirectTo: ctrl.redirectTo,
                                                params: ctrl.params
                                            });
                                        })
                                        .catch(function (err) {
                                            $scope.loading = false;
                                            console.log(err);
                                        });
                                }

                            })
                            .catch(function (err) {
                                if (!err) {
                                    $mdToast.show($mdToast.simple()
                                        .textContent($translate.instant('error.auth.offline')));
                                    $scope.loading = false;
                                    return;
                                }

                                if (err.message.indexOf('invalid recaptcha') >= 0) {
                                    $mdToast.show($mdToast.simple()
                                        .textContent($translate.instant('error.auth.recaptcha')));
                                    $scope.loading = false;
                                    return;
                                }

                                form.checkClauses.$setValidity('emailAlreadyRegistered', false);
                                $scope.loading = false;

                                thisTimeout = $timeout(function () {
                                    form.$setUntouched();
                                    form.$setPristine();
                                    form.checkClauses.$setValidity('emailAlreadyRegistered', true);
                                }, 6000);

                            });
                    }
                }

                function loginFacebook() {
                    if (!Facebook.isReady()) {
                        return;
                    }

                    Facebook.login(function (response) {
                        if (response.status !== 'connected') {
                            return;
                        }

                        Auth.facebookSignin({
                            lang: currentLang,
                            access_token: response.authResponse.accessToken
                        })
                            .then(function (res) {
                                if (res.data.info.firstAccess) {
                                    if ($scope.service) {
                                        TrackEventService.event('Funnel Events', 'Signup Completed', $scope.service);
                                        TrackEventService.gaV2Event('sign_up', {
                                            'sign_up_method': 'Facebook',
                                            'method': 'Facebook',
                                            'service': $scope.service
                                        });
                                    } else {
                                        TrackEventService.event('Funnel Events', 'Signup Completed');
                                        TrackEventService.gaV2Event('sign_up', {
                                            'sign_up_method': 'Facebook',
                                            'method': 'Facebook',
                                        });
                                    }
                                }

                                redirectBasedOnUserStatus(res);
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }, {
                        scope: 'email,public_profile'
                    });
                }

                function loginGoogleEvent(evt, token) {
                    loginGoogle(token);
                }

                function loginGoogle(token) {
                    Auth.googleSignin({
                        lang: currentLang,
                        access_token: token
                    })
                        .then(function (res) {
                            if (res.data.info.firstAccess) {
                                if ($scope.service) {
                                    TrackEventService.event('Funnel Events', 'Signup Completed', $scope.service);
                                    TrackEventService.gaV2Event('sign_up', {
                                        'sign_up_method': 'Google',
                                        'method': 'Google',
                                        'service': $scope.service
                                    });
                                } else {
                                    TrackEventService.event('Funnel Events', 'Signup Completed');
                                    TrackEventService.gaV2Event('sign_up', {
                                        'sign_up_method': 'Google',
                                        'method': 'Google',
                                    });
                                }
                            }

                            redirectBasedOnUserStatus(res);
                        })
                        .catch(function (err) {
                            console.log(err);
                        })
                }

                function setService(value) {
                    $scope.service = value;
                }

                function setPassType(value) {
                    $scope.passType = value;
                }

                //TODO: spostare in un componente a parte
                function getPasswordStrenght(pass) {
                    var score = 0;
                    if (!pass) {
                        return score;
                    }

                    // award every unique letter until 5 repetitions
                    var letters = new Object();
                    for (var i = 0; i < pass.length; i++) {
                        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                        score += 5.0 / letters[pass[i]];
                    }

                    // bonus points for mixing it up
                    var variations = {
                        digits: /\d/.test(pass),
                        lower: /[a-z]/.test(pass),
                        upper: /[A-Z]/.test(pass),
                        nonWords: /\W/.test(pass),
                    }

                    var variationCount = 0;
                    for (var check in variations) {
                        variationCount += (variations[check] == true) ? 1 : 0;
                    }
                    score += (variationCount - 1) * 10;

                    if (score > 80) {
                        $scope.strengthPasswordIndicator = "strong";
                        $scope.strengthPasswordColor = "#3AB526";
                    } else if (score > 60) {
                        $scope.strengthPasswordIndicator = "good";
                        $scope.strengthPasswordColor = "#FFC700";
                    } else if (score >= 0) {
                        $scope.strengthPasswordIndicator = "weak";
                        $scope.strengthPasswordColor = "#FE001A";
                    }

                    return parseInt(score);
                }

                function goSignin() {
                    if (ctrl.dialogMode) {
                        $scope.$parent.setFragment('alert-signin');
                    } else {
                        $state.go('access.signin');
                    }
                }

                function redirectBasedOnUserStatus(res) {
                    if (res && res.data && res.data.user && (!res.data.user.businessType || !res.data.user.teamMembers || !res.data.user.jobTitle)) {
                        if (ctrl.dialogMode) {
                            $scope.$parent.cancel();
                        }

                        $state.go('access.tailor-made');
                        return;
                    }

                    var stateOptions = {};
                    var stateObject = {};

                    if (ctrl.dialogMode) {
                        $scope.$parent.cancel();

                        stateOptions = {
                            reload: true
                        };
                        if (ctrl.params) {
                            stateObject = ctrl.params;
                        }

                        if (ctrl.redirectTo) {
                            $state.go(ctrl.redirectTo, stateObject, stateOptions);
                        }
                    } else {
                        $state.go('app.welcome');
                    }
                }
            }
        ]
    };
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.settings", {
                url: "/settings",
                controller: "SettingsController",
                templateUrl: './app/modules/settings/settings.html',
                access: {restricted: true},
                resolve: {
                    currentUser: ['AuthService', function (AuthService) {
                        return AuthService.getSessionUser();
                    }]
                }
            })
            .state("app.settings.update", {
                url: "/update",
                controller: "UpdateController",
                templateUrl: './app/modules/settings/update/update.html',
                access: {restricted: true},
                resolve: {
                    accountData: ['UserService', function (UserService) {
                        return UserService.accountInfo()
                            .then(function (res) {
                                return res.data;
                            })
                            .catch(function () {
                                return {};
                            });
                    }]
                }
            })
            .state("app.settings.overview", {
                url: "/overview",
                controller: "SettingsOverviewCtrl",
                templateUrl: './app/modules/settings/overview/overview.html',
                resolve: {
                    userInfo: ['AuthService', function (AuthService) {
                        return AuthService.getSessionUser();
                    }],
                    canDoTrial: ['$stateParams', '$state', 'UserService', 'AuthService', function ($stateParams, $state, UserService, AuthService) {
                        if (!AuthService.isAuthenticated()) return true;
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data.canDoTrial;
                            }).catch(function () {
                                return false;
                            });
                    }],
                    userCredits: ['UserService', 'AuthService', function (UserService, AuthService) {
                        if (!AuthService.isAuthenticated()) return false;
                        return UserService.limits()
                            .then(function (res) {
                                return res.data.limits;
                            })
                            .catch(function (err) {
                                return false;
                            });
                    }],

                    subscriptionCredits: ['SubscriptionService', 'AuthService', function (SubscriptionService, AuthService) {
                        if (!AuthService.isAuthenticated()) return false;
                        return SubscriptionService.credits().then(function (res) {
                            return res.data;
                        }).catch(function (err) {
                            return false;
                        });

                    }],
                    isTrialing: ['$stateParams', '$state', 'CheckoutService', 'AuthService', '$q', function ($stateParams, $state, CheckoutService, AuthService, $q) {
                        var deferred = $q.defer();

                        var userId = null;
                        var user = AuthService.getSessionUser();
                        if (user && user.id) {
                            userId = user.id
                        }
                        CheckoutService.checkTrial(userId)
                            .then(function (res) {
                                deferred.resolve(res.data.canDoTrial);
                            })
                            .catch(function (err) {
                                console.log(err);
                                deferred.resolve(false);
                            });

                        return deferred.promise;
                    }],
                    subscriptionsInfo: ['AuthService', 'SubscriptionService', function (AuthService, SubscriptionService) {
                        if (!AuthService.isAuthenticated()) return true;
                        return SubscriptionService.plans()
                            .then(function (res) {
                                var subscriptionList = res.data;
                                var subscriptionsData = {
                                    monthlySub: false,
                                    annualSub: false,
                                    subscriptionNotEnd: true,
                                    majorAmountAnnual: 0,
                                    majorAmountMonthly: 0,
                                    isNotInTrial: true,
                                    planImportant: null,
                                    accountPitchground: false,
                                    diffDays: 0,
                                    bar: {
                                        width: '0%',
                                        class: 'st-bg-bar-expired'
                                    }
                                };

                                //Control subscription
                                var subscriptionTrial = [];
                                var subscriptionActive = [];
                                var planMonthly = [];
                                var planAnnual = [];
                                var planPitchground = [];
                                subscriptionList.forEach(function (subscription) {
                                    if (subscription.status === 'trialing') {
                                        subscriptionTrial.push(subscription);
                                    } else if (subscription.status === 'active') {
                                        subscriptionActive.push(subscription);
                                    }
                                });

                                if (subscriptionActive.length) {
                                    subscriptionsData.isNotInTrial = true;
                                    subscriptionActive.forEach(function (subscription) {
                                        subscription.plans.forEach(function (plan) {
                                            //Control plan
                                            if (plan.alias.startsWith('pitchground') || plan.alias.startsWith('plan-pg')) {
                                                planPitchground.push(plan);
                                            } else if (plan.interval === 'year') {
                                                planAnnual.push(plan);
                                            } else {
                                                planMonthly.push(plan);
                                            }
                                        });
                                    });

                                    if (planPitchground.length) {
                                        planPitchground.forEach(function (plan) {
                                            if (subscriptionsData.majorAmountAnnual < plan.amount) {
                                                subscriptionsData.majorAmountAnnual = plan.amount;
                                                subscriptionsData.annualSub = true;
                                                subscriptionsData.accountPitchground = true;
                                                subscriptionsData.monthlySub = false;
                                                subscriptionsData.planImportant = plan;
                                                subscriptionsData.planImportant.dateEnd = 'LIFETIME';
                                            }
                                        });
                                    } else if (planAnnual.length) {
                                        var i = 0;
                                        planAnnual.forEach(function (plan) {
                                            if (subscriptionsData.majorAmountAnnual < plan.amount) {
                                                subscriptionsData.majorAmountAnnual = plan.amount;
                                                subscriptionsData.annualSub = true;
                                                subscriptionsData.monthlySub = false;
                                                subscriptionsData.planImportant = plan;
                                                setDaysAndColorBar(plan, subscriptionActive[i].currentPeriodEnd, subscriptionActive[i].currentPeriodStart);
                                                subscriptionsData.planImportant.dateEnd = subscriptionActive[i].currentPeriodEnd;
                                            }
                                            i++;
                                        });
                                    } else {
                                        var i = 0;
                                        planMonthly.forEach(function (plan) {
                                            if (subscriptionsData.majorAmountMonthly < plan.amount) {
                                                subscriptionsData.majorAmountMonthly = plan.amount;
                                                subscriptionsData.monthlySub = true;
                                                subscriptionsData.annualSub = false;
                                                setDaysAndColorBar(plan, subscriptionActive[i].currentPeriodEnd, subscriptionActive[i].currentPeriodStart);
                                                subscriptionsData.planImportant.dateEnd = subscriptionActive[i].currentPeriodEnd;
                                            }
                                            i++;
                                        });
                                    }
                                } else if (subscriptionTrial.length) {
                                    subscriptionsData.isNotInTrial = false;
                                    subscriptionTrial.forEach(function (subscription) {
                                        subscription.plans.forEach(function (plan) {
                                            subscriptionsData.planImportant = {dateEnd: subscription.trialEnd};
                                            setDaysAndColorBar(plan, subscription.trialEnd, subscription.trialStart);
                                        });
                                    });
                                } else {
                                    subscriptionsData.isNotInTrial = true;
                                    subscriptionsData.monthlySub = true;
                                    subscriptionsData.annualSub = false;
                                    subscriptionsData.diffDays = 'expired';
                                    subscriptionsData.bar.width = '2%';
                                    subscriptionsData.subscriptionNotEnd = false;
                                    subscriptionsData.bar.class = 'st-bg-bar-expired';
                                }

                                function setDaysAndColorBar(plan, subEnd, substart) {
                                    subscriptionsData.planImportant = plan;
                                    var interval = Math.ceil(Math.abs(new Date(substart).getTime() - new Date(subEnd).getTime()) / (1000 * 3600 * 24));
                                    var newDate = new Date().getTime() - new Date(subEnd).getTime();
                                    var timeDiff = Math.abs(newDate);
                                    if (timeDiff <= 0) {
                                        subscriptionsData.diffDays = 'expired';
                                        subscriptionsData.bar.width = '2%';
                                        subscriptionsData.subscriptionNotEnd = false;
                                        subscriptionsData.bar.class = 'st-bg-bar-expired';
                                        return;
                                    }

                                    subscriptionsData.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                    subscriptionsData.bar.width = '' + (100 * subscriptionsData.diffDays) / interval + '%';
                                    if (parseInt(subscriptionsData.bar.width) < 20) {
                                        subscriptionsData.bar.class = 'st-bg-bar-expired';
                                    } else if (parseInt(subscriptionsData.bar.width) > 20 && parseInt(subscriptionsData.bar.width) < 50) {
                                        subscriptionsData.bar.class = 'st-bg-bar-half';
                                    } else {
                                        subscriptionsData.bar.class = 'st-bg-bar-full';
                                    }
                                }

                                return subscriptionsData;
                            }).catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.settings.businessInfo", {
                url: "/businessInfo",
                controller: "BusinesInfoCtrl",
                templateUrl: './app/modules/settings/businessInfo/businessInfo.html',
                access: {restricted: true},
                resolve: {
                    accountData: ['UserService', function (UserService) {
                        return UserService.accountInfo().then(function (res) {
                            return res.data;
                        }).catch(function () {
                            return {};
                        });
                    }]
                }
            })
            .state("app.settings.changePassword", {
                url: "/change-password",
                controller: "ChangePasswordController",
                templateUrl: './app/modules/settings/changePassword/changePassword.html',
                access: {restricted: true}
            })
            .state("app.settings.billingInfo", {
                url: "/billing-info",
                controller: 'BillingInfoCtrl',
                templateUrl: './app/modules/settings/billingInfo/billingInfo.html',
                access: {restricted: true},
                resolve: {
                    billingInfo: ['UserService', function (UserService) {
                        return UserService.accountInfo().then(function (result) {
                            if (result.data.billingIsEU == 1)
                                result.data.billingIsEU = true;
                            if (result.data.billingIsEU == 0)
                                result.data.billingIsEU = false;
                            return result.data;
                        }).catch(function (err) {
                            return {};
                        });
                    }],
                    numberOfSubscriptions: ['SubscriptionService', function (SubscriptionService) {
                        return SubscriptionService.info().then(function (result) {
                            return result.data.totalRows;
                        }).catch(function (err) {
                            return {};
                        });
                    }]
                }
            })
            .state("app.settings.subscriptions", {
                url: "/subscriptions",
                controller: 'SubscriptionsCtrl',
                templateUrl: './app/modules/settings/subscriptions/subscriptions.html',
                access: {restricted: true},
                resolve: {
                    /*
                    nextInvoices: ['WorkspaceService', function(WorkspaceService) {
                        return WorkspaceService.getNextInvoices()
                            .then(function (res) {
                                if (res && res.invoices && res.invoices.length) {
                                    return res.invoices;
                                }

                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                    */
                    canDoTrial: ['$stateParams', '$state', 'UserService', 'AuthService', function ($stateParams, $state, UserService, AuthService) {
                        if (!AuthService.isAuthenticated()) return true;
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data.canDoTrial;
                            }).catch(function () {
                                return false;
                            });
                    }],
                    paymentMethods: ['UserService', function (UserService) {
                        return UserService.getPaymentMethods();
                    }],
                    subscriptionsInfo: ['SubscriptionService', function (SubscriptionService) {
                        return SubscriptionService.plans()
                            .then(function (result) {
                                var subscriptionList = result.data;
                                for (var i = 0; i < subscriptionList.length; i++) {
                                    if (subscriptionList[i].plans[0].alias.startsWith('pitchground') ||
                                        subscriptionList[i].plans[0].alias.startsWith('plan-pg')) {

                                        return {
                                            data: subscriptionList,
                                            hasPitchGroundSub: true
                                        };
                                    }
                                }
                                return {
                                    data: subscriptionList,
                                    hasPitchGroundSub: false
                                };
                            })
                            .catch(function (err) {
                                console.log(err);
                                return {};
                            });
                    }]
                }
            })
            .state("app.settings.paymentsMethods", {
                url: "/payments-methods",
                controller: 'paymentsMethodsCtrl',
                templateUrl: './app/modules/settings/paymentsMethods/paymentsMethods.html',
                access: {restricted: true},
            })
            .state("app.settings.partnersArea", {
                url: "/partners-area",
                controller: "PartnersAreaController",
                templateUrl: './app/modules/settings/partnersArea/partnersArea.html',
                access: {restricted: true},
                resolve: {
                    userInfo: ['SubscriptionService', function (SubscriptionService) {
                        return SubscriptionService.plans()
                            .then(function (result) {
                                var isPartner = false;
                                var agencyType = null;
                                var goldAgencyPlans = [];
                                var silverAgencyPlans = [];
                                var marketerPlans = [];

                                var partnerSubscriptions = result.data.filter(function (subscription) {
                                    return subscription.status === 'active' &&
                                        subscription.plans[0].alias.startsWith('plan-partner')
                                });

                                angular.forEach(partnerSubscriptions, function (partnerSubscription) {
                                    if (partnerSubscription.plans[0].alias.indexOf('gold') != -1) {
                                        goldAgencyPlans.push(partnerSubscription.plans[0].alias);
                                    } else if (partnerSubscription.plans[0].alias.indexOf('silver') != -1) {
                                        silverAgencyPlans.push(partnerSubscription.plans[0].alias);
                                    } else if (partnerSubscription.plans[0].alias.indexOf('marketer') != -1) {
                                        marketerPlans.push(partnerSubscription.plans[0].alias);
                                    }
                                });

                                isPartner = marketerPlans.length > 0 || silverAgencyPlans.length > 0 || goldAgencyPlans.length > 0;

                                if (goldAgencyPlans.length > 0) {
                                    agencyType = 'gold_agency';
                                } else if (silverAgencyPlans.length > 0) {
                                    agencyType = 'silver_agency';
                                } else if (marketerPlans.length > 0) {
                                    agencyType = 'marketer';
                                }

                                return {
                                    isPartner: isPartner,
                                    agencyType: agencyType
                                };
                            })
                            .catch(function (err) {
                                console.log(err);
                                return {};
                            });
                    }],
                    partnerInfo: ['PartnerInfoService', function (PartnerInfoService) {
                        return PartnerInfoService.getPartnerInfo()
                            .then(function (result) {
                                if (result) {
                                    return result;
                                }

                                return {};
                            })
                            .catch(function (err) {
                                console.log(err);
                                return {};
                            });
                    }]
                }
            })
            .state("app.settings.invitations", {
                url: "/invitations",
                controller: "InvitationsController",
                templateUrl: './app/modules/settings/invitations/invitations.html',
                access: {restricted: true},
            })
            .state("app.settings.customizations", {
                url: "/customizations",
                controller: 'CustomizationsController',
                templateUrl: './app/modules/settings/customizations/customizations.html',
                access: {restricted: true},
                resolve: {
                    workspaceInfo: ['$localStorage', function ($localStorage) {
                        return $localStorage.currentUser.workspace;
                    }]
                }
            })
            .state("app.settings.logs", {
                url: "/logs",
                controller: 'LogsController',
                templateUrl: './app/modules/settings/logs/logs.html',
                access: {restricted: true},
            })
            .state("app.settings.invoices", {
                url: "/invoices",
                controller: 'InvoicesController',
                templateUrl: './app/modules/settings/invoices/invoices.html',
                access: {restricted: true}
            })
            .state("app.settings.users", {
                url: "/users",
                controller: 'UsersController',
                templateUrl: './app/modules/settings/users/users.html',
                access: {restricted: true},
            })
            .state("app.settings.gdpr", {
                url: "/gdpr",
                controller: '',
                templateUrl: '',
                access: {restricted: true}
            })
            .state("app.settings.api", {
                url: "/api",
                controller: 'ApiController',
                templateUrl: './app/modules/settings/api/api.html',
                access: {restricted: true},
                resolve: {
                    apiToken: ['AuthService', function(AuthService) {
                        return AuthService.getApiToken().then(function(res){
                            return res.token;
                        }).catch(function(err){
                            console.log('err', err)
                            return null;
                        });
                    }],
                    userApiCredits: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits()
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                    return null;
                                }

                                var apiCredits = res.data.limits.filter(function(limit){
                                    return limit.alias == 'api-credits';
                                });

                                if (!apiCredits.length) {
                                    return null;
                                }

                                return apiCredits[0];
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                },
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define(['angular'], function(ng) {
    function TwittercardCtrl($scope) {
        var vm = $scope;
       
        vm.tabs={
            categoryTitle:'Twittercard',
            categoryState:'app.seospider.exam.analysis.twittercard',
            fields:[
                {label:'seospider.twittercard.all',icon:'icon icon-list', uiSref:'.all'},
            ]
        };
       
    }
    TwittercardCtrl.$inject=['$scope'];
    return TwittercardCtrl;
});

'use strict';
define([], function () {
    function controller($scope, $mdMedia, $mdDialog, $state) {
        var ctrl = this;
        $scope.$mdMedia = $mdMedia;

        $scope.showDropAlert = showDropAlert;
        $scope.addKeywords = addKeywords;
        $scope.goToSettings = goToSettings;
        $scope.goToProject = goToProject;


        function addKeywords(trackerProjectId, ev) {
            ev.preventDefault();
            ev.stopPropagation();
            $state.go(
                'app.keywordtracker.addkeywords',
                {
                    action: 'GET',
                    trackerProjectId: trackerProjectId
                }
            );
        }

        function goToSettings(projectId) {
            $state.go(
                'app.project.settings',
                {
                    action: 'GET',
                    projectId: projectId,
                    activeTab: 'scope'
                }
            );
        }

        function goToProject(id) {
            if (ctrl.isLoading) {
                return;
            }
            $state.go(
                'app.keywordtracker.overview',
                {
                    action: 'GET',
                    project: id
                }
            );
        }

        function showDropAlert(project, ev) {
            $mdDialog.show({
                controller: 'ProjectDeleteProjectCtrl',
                templateUrl: "app/modules/project/dialogs/delete-project/delete-project.html",
                targetEvent: ev,
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project: project
                }
            }).then(function (answer) {
                $state.reload();
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }


        ctrl.$onInit = function () {

        }
    }

    controller.$inject = ['$scope', '$mdMedia', '$mdDialog', '$state'];

    return {
        templateUrl: 'app/modules/project/components/project-card/project-card.html',
        bindings: {
            isLoading: '<',
            url: '<',
            scope: '<',
            trackedKeywords: '<',
            projectData: '<'
        },
        controller: controller,
    }
});

'use strict';
define([], function () {

    function Controller($scope, $q, form, $translate, LanguageService, LeadGenerationService, $stateParams, $timeout, currentLangCode) {
        var vm = $scope;
        var ctrl = this;

        vm.media = {};
        vm.workingSessionModel={
            form: null
        };
        vm.form = form;
        var defaultFields = [{
            id: 'leadWebsite',
            name: "website",
            title: 'Website',
            type: "url",
            inputType: "input",
            placeholder: $translate.instant("leadgeneration.website.url"),
            deletable: false,
            required: true,
            show: true,
            value: null
            }, {
            id: 'leadEmail',
            name: "email",
            title: 'Email',
            type: "email",
            inputType: "input",
            placeholder: $translate.instant("leadgeneration.email.eg"),
            deletable: false,
            required: true,
            show: true,
            value: null
        }];

        vm.defaultFields = JSON.parse(JSON.stringify(defaultFields));

        if (!vm.form || !vm.form.magnet) {
            vm.form.magnet = {
                type: "custom-file",
                config: {
                    params: {
                        resourceUrl: null,
                    },
                    default: false,
                    version: "1.0"
                }
            };

            vm.form.config = {
                title: 'Ottieni il tuo PDF',
                subtitle: 'Completa i campi per ottenere il file',
                callToAction: 'Ottieni',
                fields: defaultFields,
                events: {
                    redirectAfterConversion: {
                        enabled: true,
                        url: null
                    }
                },
                gdpr: {
                    enabled: false,
                    termsAndConditionsUrl: null,
                    privacyPolicyUrl: null,
                },
                color: {
                    title: '#130D41',
                    subtitle: '#596679',
                    textCTA: '#ffffff',
                    backgroundColorCta: '#ff8800',
                    backgroundHidden: false,
                    backgroundColor: '#ffffff',
                },
                lang: currentLangCode || 'en' || null
            }
        }


        vm.formLocale = LanguageService.getLocaleFromLanguageCode(vm.form.config.lang);
        vm.magnetLocale = LanguageService.getLocaleFromLanguageCode(vm.form.magnet.config.params.locale);

        if (vm.form&&vm.form.magnet&&vm.form.magnet.config&&vm.form.magnet.config.params&&vm.form.magnet.config.params.resourceUrl) {
            vm.filename = vm.form.magnet.config.params.resourceUrl.substring(vm.form.magnet.config.params.resourceUrl.lastIndexOf('/') + 25);
            vm.filename = '...' + vm.filename;
        }


        vm.imagePresent = false;
        vm.customFile = {};

        vm.updateMedia = function(media) {
            if (media && media.resourceUrl) {
                vm.media = media;
                vm.form.magnet.config.params.resourceUrl = media.resourceUrl;
                saveForm();
                vm.filename = vm.form.magnet.config.params.resourceUrl.substring(vm.form.magnet.config.params.resourceUrl.lastIndexOf('/') + 25);
                vm.filename = '...' + vm.filename;            }

        };

        ctrl.$onInit = function () {
            currentLangCode = $translate.instant('currentLangCode') || 'en';

            autosavePooling();
        };
        ctrl.$onDestroy = function () {
            currentPoolingSessionSaveDataAborted = true;
            $timeout.cancel(currentPoolingSessionSaveData);
        };


        var lastSavedForm = null;
        var currentPoolingSessionSaveData;
        var currentPoolingSessionSaveDataAborted = false;

        function autosavePooling() {
            if (currentPoolingSessionSaveDataAborted) {
                return;
            }
            var dfd = $q.defer();
            saveForm().then(function () {
                dfd.resolve(true);
                currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
            }).catch(function () {
                dfd.resolve(true);
                currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
            });
            return dfd.promise;
        }

        function saveForm() {
            var dfd = $q.defer();

            vm.form.magnet.config.params.locale = LanguageService.getLanguageCodeFromLocale(vm.magnetLocale);

            var formToSave = {
                id: Number($stateParams.formId),
                name: vm.form.name,
                config: vm.form.config,
                magnet: vm.form.magnet
            };

            if (vm.workingSessionModel.form) {
                formToSave = {
                    id: Number($stateParams.formId),
                    name: vm.form.name,
                    config: vm.workingSessionModel.form.config,
                    magnet: vm.form.magnet
                };
            }


            if (lastSavedForm === JSON.stringify(formToSave)) {
                $timeout(function () {
                    dfd.resolve(true);
                }, 1000);
                return dfd.promise;
            }

            lastSavedForm = JSON.stringify(formToSave);
            LeadGenerationService
                .updateForm(formToSave)
                .then(function (res) {
                    dfd.resolve(res);
                }).catch(function (err) {
                    dfd.resolve(err);
                });
            return dfd.promise;
        }

        vm.removeAttachedFile = function() {
            vm.form.magnet.config.params.resourceUrl = null;
            saveForm();
        }

        vm.uploadFile = function() {
            $timeout(function () {
                angular.element(document.querySelector('#media-upload button')).click();
            });
        }

    }
    Controller.$inject = [ '$scope', '$q', 'form', '$translate', 'LanguageService', 'LeadGenerationService', '$stateParams', '$timeout', 'currentLangCode'];
    return Controller;
});

'use strict';
define([], function () {
    function Controller(
        $scope,
        keywordList,
        KeywordListService,
        ExportService,
        $translate,
        $mdDialog,
        AuthService,
        $state,
        $window,
        keywordsLimit,
        TrackEventService,
        stToggleExpand,
        LanguageService
    ) {
        $scope.expandSection = stToggleExpand.isExpanded('keyword-list-toggle') || false;

        $scope.keywordList = {
            id: keywordList.keywordListId,
            name: keywordList.keywordListName,
            token: keywordList.tokenKeywordList,
            averageCPC: keywordList.averageCPC,
            totalVolume: keywordList.totalVolume,
            obscured: keywordList.obscured,
            averageDifficulty: keywordList.averageDifficulty,
            queries: keywordList.queries.rows,
            limit: keywordsLimit,
        };

        $scope.rowsSelected = [];
        $scope.getAllKeywordList = getAllKeywordList;
        $scope.createAKeywordList = createAKeywordList;
        $scope.addQueriesToKeywordlist = addQueriesToKeywordlist;
        $scope.getKeywordListByQuery = getKeywordListByQuery;
        $scope.deleteKeywords = deleteKeywords;
        $scope.shareKeywordList = shareKeywordList;
        $scope.checkboxSelected = checkboxSelected;
        $scope.goToContactUs = goToContactUs;
        $scope.keywordListPage = {
            template: 4,
            createAKeywordList: $scope.createAKeywordList,
            addQueriesToKeywordlist: $scope.addQueriesToKeywordlist,
            getAllKeywordList: $scope.getAllKeywordList,
            getKeywordListByQuery: $scope.getKeywordListByQuery,
            buttonShow: true,
            buttonDisabled: !$scope.rowsSelected.length,
            queries: [],
            class: 'st-button__rounded--md--blue--outline',
            queriesSelected: [],
            position: '0 -150'
        };

        $scope.analyzeList = function () {
            var url = $state.href('app.keywordexplorer.search', {
                keywordListId: $scope.keywordList.id,
                keywordListName: $scope.keywordList.name,
                countryCode: $scope.selectedCountry,
                typeOfResearch: 'keywordList'
            });
            $window.open(url, '_blank');
        };

        setQueriesForComponents(keywordList.queries.rows);

        function getAllKeywordList() {
            if (!AuthService.isAuthenticated()) return [];
            return KeywordListService.getAllKeywordList().then(function (res) {
                return res;
            });
        }

        function getKeywordListByQuery(keywordUuids) {
            if (!AuthService.isAuthenticated()) return [];
            return KeywordListService.getKeywordListByQuery({
                keywordUuids: keywordUuids
            }).then(function (res) {
                $scope.keywordListPage.keywordListByQuery = res;
                return res;
            });
        }

        function createAKeywordList(name) {
            return KeywordListService.createAKeywordList({
                name: name,
                toolSourceId: 1
            })
                .then(function () {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - List Created');
                    TrackEventService.gaV2Event(
                        'list_create', {
                            tool: "Keyword List Manager"
                        });
                    return getAllKeywordList();
                });
        }

        function addQueriesToKeywordlist(queriesSelected, keywordListId) {
            return KeywordListService.addQueries(keywordListId, queriesSelected)
                .then(function () {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - Keyword Added');
                    TrackEventService.gaV2Event(
                        'keyword_add', {
                            tool: "Keyword List Manager"
                        });
                    return getKeywordListByQuery($scope.keywordListPage.keywordUuids)
                        .then(function () {
                            return getAllKeywordList();
                        })
                        .catch(function (err) {
                            return err;
                        });
                });
        }

        function checkboxSelected(rowsSelected) {
            $scope.rowsSelected = rowsSelected;
            $scope.keywordListPage.buttonDisabled = !$scope.rowsSelected.length;
            $scope.keywordListPage.queriesSelected = rowsSelected;
            setQueriesForComponents(rowsSelected);
        }

        function setQueriesForComponents(queries) {
            if (!queries.length) {
                queries = keywordList.queries.rows;
            }
            var objectForComponents = {
                queriesName: [],
                queriesKeywordUuids: [],
                queriesAllFields: []
            };

            queries.forEach(function (query) {
                objectForComponents.queriesName.push(query.keyword);
                objectForComponents.queriesKeywordUuids.push(query.keywordUuid);
                objectForComponents.queriesAllFields.push({
                    keyword: query.keyword,
                    avgCompetition: query.avgCompetition,
                    avgCpc: query.avgCpc,
                    avgVolume: query.avgVolume,
                    countryCode: query.countryCode,
                });
            });
            $scope.keywordUuids = objectForComponents.queriesKeywordUuids;
            createComponentExportAndCopyToClipboard(objectForComponents);
        }

        $scope.addKeywords = function () {
            $state.go(
                'app.keywordlistmanager.wizard', {
                    action: 'GET',
                    keywordListId: keywordList.keywordListId,
                    step: 1
                }
            );
        };

        function deleteKeywords(ev, keywordUuids) {
            if (!keywordUuids.length) {
                return;
            }
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/delete-keywords/delete-keywords.html",
                controller: "KeywordListManagerDeleteKeywordsCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    keywordUuids: keywordUuids,
                    keywordListId: keywordList.keywordListId
                }
            });
        }

        function shareKeywordList(ev, tokenKeywordList, keywordListName) {
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/share-keywordlist/share-keywordlist.html",
                controller: "KeywordListManagerShareKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    tokenKeywordList: tokenKeywordList,
                    keywordListName: keywordListName
                }
            });
        }

        function createComponentExportAndCopyToClipboard(object) {
            $scope.copyToClipboardOptions = {
                class: 'st-button__rounded--md--blue--outline',
                text: $translate.instant('keyword.exp.copy.clipboard'),
                icon: 'icon-copy-filled',
                showToast: true,
                callback: $scope.setToastForCopyToClipboard,
                data: [object.queriesName],
                mode: 'rows',
            };
            $scope.exportCsvOptions = {
                stoTool: 'keyword-list-manager',
                class: 'st-button__rounded--md--violet',
                text: $translate.instant('keyword.exp.export.csv'),
                icon: 'icon-download',
                data: ExportService.convertObjectToCsvArray(object.queriesAllFields),
                fileName: 'keywords',
            };
        }

        $scope.keywordController = {
            formatHistory: function (history) {
                if (!history) {
                    return '<st-sparkline data="[]"></st-sparkline>';
                }

                var trends = [];
                var formattedTrends = history;
                if (typeof formattedTrends === 'string') {
                    formattedTrends = JSON.parse(formattedTrends);
                }

                for (const datetime in formattedTrends) {
                    trends.push(formattedTrends[datetime]);
                }

                return '<st-sparkline data="[' + trends + ']"></st-sparkline>';
            },
            updatedAt: keywordList.updated
        };

        function goToContactUs() {

            var language = LanguageService.getCodeAndCountrySaveOnStorage();
            if (language === 'it-IT') {
                $window.open("https://it.seotesteronline.com/contatti", '_blank');
            } else if (language === 'es-ES') {
                $window.open("https://es.seotesteronline.com/contacts", '_blank');
            } else {
                $window.open("https://www.seotesteronline.com/contacts", '_blank');
            }
        }
    }

    Controller.$inject = [
        '$scope',
        'keywordList',
        'KeywordListService',
        'ExportService',
        '$translate',
        '$mdDialog',
        'AuthService',
        '$state',
        '$window',
        'keywordsLimit',
        'TrackEventService',
        'stToggleExpandSectionService',
        'LanguageService'
    ];
    return Controller;
});

'use strict';
define(['angular'],function(ng){
    return {
        templateUrl: 'app/components/collapsable/collapsable.html',
        bindings: {
            title: '=',
            content: '=',
            addClass: '=',
            collapsed: '='
        },
        controller: ['$scope',function($scope) {
            var vm=this;
            if(vm.collapsed==undefined)
                vm.collapsed=true;
        }]
    };
});
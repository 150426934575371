'use strict';
define([], function () {
    function Controller($rootScope, $scope, $stateParams, LanguageService, CRMService, $state) {
        var vm = $scope;
        vm.cantDoTrial = $stateParams.canDoTrial;
        vm.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();
        vm.switchLang = switchLang;

        CRMService.addEvents(['UserToTrialGenericPaymentFailedEvent'], [
            'UserToTrialGenericCheckoutRecoverEvent',
            'UserToTrialPricingRecoverEvent',
        ]);

        function switchLang(language) {
            vm.selectedLang = language;
            $rootScope.setLang(language);
        }

        vm.backTo = function() {
            $state.go('upgrade.checkout', {
                alias: $stateParams.alias,
                coupon: $stateParams.coupon
            });
        };
    }

    Controller.$inject = ['$rootScope', '$scope', '$stateParams', 'LanguageService', 'CRMService', '$state'];
    return Controller;
});

import {Editor} from '@tiptap/core';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Placeholder from '@tiptap/extension-placeholder';

import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import ImageResize from 'tiptap-extension-resize-image';
import {saveAs} from 'file-saver';
import {Slice, DOMParser, DOMSerializer} from 'prosemirror-model';
import {Node} from '@tiptap/core';
import {debounce} from 'lodash';


const BlockWithLabel = Node.create({
    name: 'blockWithLabel',

    group: 'block',

    content: 'block+',

    addNodeView() {
        return ({node, view, getPos}) => {
            const dom = document.createElement('div');
            const label = document.createElement('span');

            // Imposta il nome del nodo (p, h1, h2, etc.)
            label.textContent = node.type.name;
            label.style.cssText = 'position: absolute; left: -40px; font-size: 12px; color: gray;';

            dom.style.position = 'relative';
            dom.appendChild(label);

            // Contenitore del nodo effettivo
            const contentDOM = document.createElement('div');
            contentDOM.style.marginLeft = '40px'; // Sposta il contenuto per far spazio all'etichetta
            dom.appendChild(contentDOM);

            return {
                dom,
                contentDOM,
            };
        };
    },
});


function ControllerApp(__env, UserService, $element, $translate, $timeout, $scope, $stateParams, $sce, $window, article, $mdDialog, DocxService, WriterAssistantService) {
    var vm = $scope;
    vm.articleDataSet = article;

// Debounce the updateArticle call

    vm.subscriptionCheckLoading = true;
    vm.canDoTrial = false;
    vm.trialPeriodDays = __env.trialPeriodDays;
    vm.hasValidSuscription = true;

    async function checkSubscription() {
        const response = await UserService.limits();
        try {
            vm.hasValidSuscription = response.data.limits.find(function (service) {
                return service.alias === 'seo-editor';
            }).qtyAllowed > 0;
            vm.canDoTrial = response.data.canDoTrial;
        } catch (e) {
            vm.hasValidSuscription = false;
        }

        $timeout(() => {
            vm.subscriptionCheckLoading = false;
        }, 1000);

        // Set initial editable state based on subscription
        vm.editor.setEditable(vm.hasValidSuscription);
    }

    checkSubscription();

    $scope.savingInLoading = false;
    const debouncedUpdateArticle = debounce(async (articleId, articleData) => {
        $scope.savingInLoading = true;
        await WriterAssistantService.updateArticle(articleId, articleData);
        vm.articleDataSet.updatedAt = new Date();
        $scope.savingInLoading = false;
    }, 1000);
    if (!vm.articleDataSet ||
        !vm.articleDataSet.content ||
        !vm.articleDataSet.content.html) {
        vm.articleDataSet.content = {
            html: '',
            json: {}
        };
    }

    vm.aiLoading = false;

    function sendPromptToAI(action) {
        if (!vm.hasValidSuscription) {
            return;
        }
        if (vm.aiLoading) {
            return;
        }
        vm.aiLoading = true;
        const {state, view} = vm.editor;
        const {selection} = state;
        if (selection.empty) {
            vm.aiLoading = false;
            console.warn("No text selected");
            return;
        }

        // Get the selected content as HTML
        const slice = state.doc.slice(selection.from, selection.to);
        const fragment = slice.content;

        // Use DOMSerializer to serialize the ProseMirror document fragment to HTML
        const domSerializer = DOMSerializer.fromSchema(vm.editor.schema);

        const div = document.createElement('div');
        div.appendChild(domSerializer.serializeFragment(fragment));
        const selectedHTML = div.innerHTML;

        // Call rewriteTextWithAI to process the selected HTML content
        WriterAssistantService.rewriteTextWithAI($stateParams.id, action, selectedHTML)
            .then(function (aiResponse) {
                if (aiResponse && aiResponse.trim()) {
                    // Clean AI response
                    const cleanAiResponse = aiResponse.replace(/(\r\n|\n|\r)/gm, "").trim();

                    // Parse the AI's cleaned HTML response into a ProseMirror Node
                    const domParser = DOMParser.fromSchema(vm.editor.schema);
                    const dom = document.createElement('div');
                    dom.innerHTML = cleanAiResponse;

                    // Parse the HTML into a document slice, including handling of marks (e.g., <em>)
                    const parsedSlice = domParser.parseSlice(dom, {
                        preserveWhitespace: true, // Preserve whitespace to avoid accidental removal of marks
                    });

                    // Ensure open depths are consistent
                    const fromResolved = state.doc.resolve(selection.from);
                    const toResolved = state.doc.resolve(selection.to);

                    const adjustedSlice = new Slice(
                        parsedSlice.content,
                        Math.min(parsedSlice.openStart, fromResolved.depth),
                        Math.min(parsedSlice.openEnd, toResolved.depth)
                    );

                    // Replace the range with the adjusted slice
                    const transaction = state.tr.replaceRange(
                        selection.from,
                        selection.to,
                        adjustedSlice
                    );

                    vm.aiLoading = false;
                    // Apply the transaction
                    view.dispatch(transaction);
                } else {
                    console.warn("AI returned an empty or invalid response.");
                }
                $scope.$apply();
                vm.aiLoading = false;
            }).catch(function (error) {
            vm.aiLoading = false;
            console.error("Error from AI service:", error);
        });
    }


    // Function to expand the selected content
    vm.expandContent = function () {
        sendPromptToAI("Expand");
    };

    // Function to simplify the selected content
    vm.simplifyContent = function () {
        sendPromptToAI("Simplify");
    };

    // Function to rewrite the selected content
    vm.rewriteContent = function () {
        sendPromptToAI("Rewrite");
    };

    // Function to summarize the selected content
    vm.summarizeContent = function () {
        sendPromptToAI("Summarize");
    };

// Function to remove empty tags recursively
    function removeEmptyTags(element) {
        const children = Array.from(element.childNodes);

        children.forEach(child => {
            if (child.nodeType === Node.ELEMENT_NODE) {
                removeEmptyTags(child); // Recursively clean child elements
            }

            // Remove the element if it's empty and not an img (or other self-closing non-text tags)
            if (child.nodeType === Node.ELEMENT_NODE && child.nodeName.toLowerCase() !== 'img' && !child.innerHTML.trim()) {
                child.remove();
            }
        });
    }

// Function to trim the selected content and remove empty tags
    vm.trimContent = function () {
        if (vm.aiLoading) {
            return;
        }
        vm.aiLoading = true;
        $timeout(() => {
            try {
                const {state, view} = vm.editor;
                const {selection} = state;

                if (selection.empty) {
                    vm.aiLoading = false;
                    console.warn("No text selected");
                    return;
                }

                // Get the selected content as HTML
                const slice = state.doc.slice(selection.from, selection.to);
                const fragment = slice.content;

                // Use DOMSerializer to serialize the ProseMirror document fragment to HTML
                const domSerializer = DOMSerializer.fromSchema(vm.editor.schema);
                const div = document.createElement('div');
                div.appendChild(domSerializer.serializeFragment(fragment));
                let selectedHTML = div.innerHTML;

                selectedHTML = selectedHTML
                    .replace(/(\r\n|\n|\\n|\r|\\r|\\t|\t)/gm, "")
                    .replace(/\s+/g, ' ')
                    .trim();

                // Parse the HTML into a DOM element and remove empty tags
                div.innerHTML = selectedHTML;
                removeEmptyTags(div);  // Clean up empty tags recursively

                selectedHTML = div.innerHTML;

                // Parse the cleaned HTML back into a ProseMirror document slice
                const domParser = DOMParser.fromSchema(vm.editor.schema);
                const dom = document.createElement('div');
                dom.innerHTML = selectedHTML;

                // Parse the HTML into a document slice, including handling of marks (e.g., <em>)
                const parsedSlice = domParser.parseSlice(dom, {
                    preserveWhitespace: true, // Preserve whitespace to avoid accidental removal of marks
                });

                // Ensure open depths are consistent
                const fromResolved = state.doc.resolve(selection.from);
                const toResolved = state.doc.resolve(selection.to);

                const adjustedSlice = new Slice(
                    parsedSlice.content,
                    Math.min(parsedSlice.openStart, fromResolved.depth),
                    Math.min(parsedSlice.openEnd, toResolved.depth)
                );

                // Replace the range with the adjusted slice
                const transaction = state.tr.replaceRange(
                    selection.from,
                    selection.to,
                    adjustedSlice
                );

                vm.aiLoading = false;
                // Apply the transaction
                view.dispatch(transaction);
            } catch (e) {
                console.log(e);
            }
            vm.aiLoading = false;
        }, 2000);
    };


    var headings = [
        {name: 'Normal text', command: 'setParagraph'},
        {name: 'Heading 1', command: 'toggleHeading', argument: {level: 1}},
        {name: 'Heading 2', command: 'toggleHeading', argument: {level: 2}},
        {name: 'Heading 3', command: 'toggleHeading', argument: {level: 3}},
        {name: 'Heading 4', command: 'toggleHeading', argument: {level: 4}},
        {name: 'Heading 5', command: 'toggleHeading', argument: {level: 5}},
        {name: 'Heading 6', command: 'toggleHeading', argument: {level: 6}},
    ];

    vm.selectedHeading = {
        value: 'Normal text',
    };

    vm.applyHeading = function () {
        headings.forEach(button => {
            if (button.name === vm.selectedHeading.value) {
                vm.executeCommand(button);
            }
        });
    };

    vm.editorToolbar = [
        {
            type: 'select',
            model: vm.selectedHeading,
            values: headings,
            runCallback: vm.applyHeading
        },
        {
            name: 'bold',
            command: 'toggleBold',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 11H12.5C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6H8V11ZM18 15.5C18 17.9853 15.9853 20 13.5 20H6V4H12.5C14.9853 4 17 6.01472 17 8.5C17 9.70431 16.5269 10.7981 15.7564 11.6058C17.0979 12.3847 18 13.837 18 15.5ZM8 13V18H13.5C14.8807 18 16 16.8807 16 15.5C16 14.1193 14.8807 13 13.5 13H8Z"></path></svg>'
            ),
        },
        {
            name: 'italic',
            command: 'toggleItalic',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M15 20H7V18H9.92661L12.0425 6H9V4H17V6H14.0734L11.9575 18H15V20Z"></path></svg>'
            ),
        },
        {
            name: 'underline',
            command: 'toggleUnderline',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 3V12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12V3H18V12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12V3H8ZM4 20H20V22H4V20Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'strike',
            command: 'toggleStrike',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M17.1538 14C17.3846 14.5161 17.5 15.0893 17.5 15.7196C17.5 17.0625 16.9762 18.1116 15.9286 18.867C14.8809 19.6223 13.4335 20 11.5862 20C9.94674 20 8.32335 19.6185 6.71592 18.8555V16.6009C8.23538 17.4783 9.7908 17.917 11.3822 17.917C13.9333 17.917 15.2128 17.1846 15.2208 15.7196C15.2208 15.0939 15.0049 14.5598 14.5731 14.1173C14.5339 14.0772 14.4939 14.0381 14.4531 14H3V12H21V14H17.1538ZM13.076 11H7.62908C7.4566 10.8433 7.29616 10.6692 7.14776 10.4778C6.71592 9.92084 6.5 9.24559 6.5 8.45207C6.5 7.21602 6.96583 6.165 7.89749 5.299C8.82916 4.43299 10.2706 4 12.2219 4C13.6934 4 15.1009 4.32808 16.4444 4.98426V7.13591C15.2448 6.44921 13.9293 6.10587 12.4978 6.10587C10.0187 6.10587 8.77917 6.88793 8.77917 8.45207C8.77917 8.87172 8.99709 9.23796 9.43293 9.55079C9.86878 9.86362 10.4066 10.1135 11.0463 10.3004C11.6665 10.4816 12.3431 10.7148 13.076 11H13.076Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'image',
            command: 'insertImage',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M21 15V18H24V20H21V23H19V20H16V18H19V15H21ZM21.0082 3C21.556 3 22 3.44495 22 3.9934V13H20V5H4V18.999L14 9L17 12V14.829L14 11.8284L6.827 19H14V21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'link',
            command: 'setLink',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'clear marks',
            command: 'unsetAllMarks',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12.6512 14.0654L11.6047 20H9.57389L10.9247 12.339L3.51465 4.92892L4.92886 3.51471L20.4852 19.0711L19.071 20.4853L12.6512 14.0654ZM11.7727 7.53009L12.0425 5.99999H10.2426L8.24257 3.99999H19.9999V5.99999H14.0733L13.4991 9.25652L11.7727 7.53009Z"></path></svg>'
            ),
        },
        {
            name: 'bullet list',
            command: 'toggleBulletList',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 4H21V6H8V4ZM4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12C6 12.8284 5.32843 13.5 4.5 13.5ZM4.5 20.4C3.67157 20.4 3 19.7284 3 18.9C3 18.0716 3.67157 17.4 4.5 17.4C5.32843 17.4 6 18.0716 6 18.9C6 19.7284 5.32843 20.4 4.5 20.4ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"></path></svg>'
            ),
        },
        {
            name: 'ordered list',
            command: 'toggleOrderedList',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'code block',
            command: 'toggleCodeBlock',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M24 12L18.3431 17.6569L16.9289 16.2426L21.1716 12L16.9289 7.75736L18.3431 6.34315L24 12ZM2.82843 12L7.07107 16.2426L5.65685 17.6569L0 12L5.65685 6.34315L7.07107 7.75736L2.82843 12ZM9.78845 21H7.66009L14.2116 3H16.3399L9.78845 21Z"></path></svg>'
            ),
        },
        {
            inline: false,
            name: 'blockquote',
            command: 'toggleBlockquote',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M4.58341 17.3211C3.55316 16.2274 3 15 3 13.0103C3 9.51086 5.45651 6.37366 9.03059 4.82318L9.92328 6.20079C6.58804 8.00539 5.93618 10.346 5.67564 11.822C6.21263 11.5443 6.91558 11.4466 7.60471 11.5105C9.40908 11.6778 10.8312 13.159 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0095 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2274 13 15 13 13.0103C13 9.51086 15.4565 6.37366 19.0306 4.82318L19.9233 6.20079C16.588 8.00539 15.9362 10.346 15.6756 11.822C16.2126 11.5443 16.9156 11.4466 17.6047 11.5105C19.4091 11.6778 20.8312 13.159 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0095 14.5834 17.3211Z"></path></svg>'
            ),
        },
        {
            name: 'undo',
            command: 'undo',
            disable: true,
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path></svg>'
            ),
        },
        {
            name: 'redo',
            command: 'redo',
            disable: true,
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z"></path></svg>'
            ),
        },
    ];


// Funzione per generare il documento DOCX e scaricarlo
    async function exportToDocx() {
        const blob = await DocxService.getDocxFromTipTapJson(
            vm.editor.getJSON()
        );
        // Avvia il download del file
        saveAs(blob, vm.articleDataSet.mainKeyword + ".docx");
    }

    vm.exportDoc = exportToDocx;
    vm.editorToolbarBubble = [
        {
            name: 'bold',
            command: 'toggleBold',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 11H12.5C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6H8V11ZM18 15.5C18 17.9853 15.9853 20 13.5 20H6V4H12.5C14.9853 4 17 6.01472 17 8.5C17 9.70431 16.5269 10.7981 15.7564 11.6058C17.0979 12.3847 18 13.837 18 15.5ZM8 13V18H13.5C14.8807 18 16 16.8807 16 15.5C16 14.1193 14.8807 13 13.5 13H8Z"></path></svg>'
            ),
        },
        {
            name: 'italic',
            command: 'toggleItalic',
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M15 20H7V18H9.92661L12.0425 6H9V4H17V6H14.0734L11.9575 18H15V20Z"></path></svg>'
            ),
        },
        {
            name: 'undo',
            command: 'undo',
            disable: true,
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path></svg>'
            ),
        },
        {
            name: 'redo',
            command: 'redo',
            disable: true,
            icon: $sce.trustAsHtml(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z"></path></svg>'
            ),
        }
    ];

    vm.isActive = (format) => {
        if (!vm.editor) return false;
        return vm.editor.isActive(format);
    };

    vm.executeCommand = (button) => {
        if (!vm.editor) {
            return;
        }

        if (button.command === 'insertImage') {
            vm.insertImage();
            return;
        }
        if (button.command === 'setLink') {
            setLink();
            return;
        }

        if (button.argument) {
            vm.editor.chain().focus()[button.command](button.argument).run();
        } else {
            vm.editor.chain().focus()[button.command]().run();
        }

        updateUndoRedoState(); // Update the state after executing the command
    };


    vm.insertImage = () => {
        const src = prompt('Enter the image URL');
        const alt = prompt('Enter the alt text for the image');
        if (src) {
            vm.editor.chain().focus().setImage({src, alt}).run();
        }
    };

    vm.openAltTextEditor = () => {
        const {view} = vm.editor;
        const {state} = view;

        const {selection} = state;
        let altText = '';
        let previewImageSrc = '';

        // Aggiungi un controllo esplicito per la posizione iniziale
        const node = state.doc.nodeAt(selection.from);
        if (node && node.type.name === 'image') {
            altText = node.attrs.alt || '';
            previewImageSrc = node.attrs.src || '';
        } else if (selection.from === 0 && state.doc.firstChild.type.name === 'image') {
            // Caso in cui l'immagine è la primissima cosa nel documento
            altText = state.doc.firstChild.attrs.alt || '';
            previewImageSrc = state.doc.firstChild.attrs.src || '';
        }

        vm.previewImageSrc = previewImageSrc;
        vm.altText = altText; // Set the ng-model to the retrieved alt text
    };

    vm.updateImageAlt = (altText) => {
        const {view} = vm.editor;
        const {state, dispatch} = view;
        const {schema} = state;

        const {selection} = state;
        let tr = state.tr;

        state.doc.descendants((node, pos) => {
            if (node.type.name === 'image' && pos <= selection.from && pos + node.nodeSize >= selection.to) {
                tr = tr.setNodeMarkup(pos, schema.nodes.image, {
                    ...node.attrs,
                    alt: altText
                });
                return false; // Stop iterating once we find the image node
            }
        });

        if (tr !== state.tr) {
            dispatch(tr);
        }

        vm.editor.chain().focus().run();
    }

    $scope.showAlert = (ev) => {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title('This is an alert title')
                .textContent('You can specify some description text in here.')
                .ariaLabel('Alert Dialog Demo')
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    vm.updateImageSrc = (src) => {
        const {view} = vm.editor;
        const {state, dispatch} = view;
        const {schema} = state;

        const {selection} = state;
        let tr = state.tr;

        state.doc.descendants((node, pos) => {
            if (node.type.name === 'image' && pos <= selection.from && pos + node.nodeSize >= selection.to) {
                tr = tr.setNodeMarkup(pos, schema.nodes.image, {
                    ...node.attrs,
                    src: src
                });
                return false; // Stop iterating once we find the image node
            }
        });

        if (tr !== state.tr) {
            dispatch(tr);
        } else {
            console.log("No changes made, not dispatching");
        }

        vm.editor.chain().focus().run();
    };


    function initEditor() {
        const content = vm.articleDataSet.content.html;
        vm.editor = new Editor({
            element: document.getElementById('editor-wrapper-element'),
            extensions: [
                StarterKit,
                BlockWithLabel, // Aggiungi la custom NodeView per mostrare il tipo di nodo
                Image,
                ImageResize,
                Italic,
                Bold,
                Underline,
                BulletList,
                Placeholder.configure({
                    // Use a placeholder:
                    placeholder: $translate.instant('Write something …'),
                    // Use different placeholders depending on the node type:
                    // placeholder: ({ node }) => {
                    //   if (node.type.name === 'heading') {
                    //     return 'What’s the title?'
                    //   }

                    //   return 'Can you add some further context?'
                    // },
                }),
                Document,
                ListItem,
                HardBreak,
                Heading,
                Link.configure({
                    openOnClick: false,
                    autolink: true,
                    defaultProtocol: 'https',
                }),
                Text,
                BubbleMenu.configure({
                    pluginKey: 'bubbleMenuText',
                    element: document.querySelector('#menu-editor-bubble-format-text'),
                    shouldShow: ({editor}) => !editor.isActive('image'),
                }),
                BubbleMenu.configure({
                    pluginKey: 'bubbleMenuImage',
                    element: document.querySelector('#menu-editor-bubble-image'),
                    shouldShow: ({editor}) => editor.isActive('image'),
                    tippyOptions: {
                        placement: 'top', // Place the bubble menu above the image
                        offset: [0, 10], // Adjust the offset to fine-tune the position
                        // Additional options can be added here
                    },
                }),
            ],
            content,
            onUpdate: ({editor}) => {
                vm.articleDataSet.content.html = editor.getHTML();
                vm.articleDataSet.content.json = editor.getJSON();
                vm.articleDataSet.content.text = editor.getText();
                // Update undo/redo state when the editor content updates
                updateUndoRedoState();

                debouncedUpdateArticle(vm.articleDataSet.id, vm.articleDataSet);
            },
        });

        // Update undo/redo state when the editor content updates
        updateUndoRedoState();
        vm.articleDataSet.content.html = vm.editor.getHTML();
        vm.articleDataSet.content.json = vm.editor.getJSON();
        vm.articleDataSet.content.text = vm.editor.getText();
        vm.editor.on('selectionUpdate', evt => {
            const {state} = vm.editor.view;
            const {selection} = state;
            var match = state.doc.resolve(state.selection.anchor).parent;
            if (!match.attrs.level) {
                vm.selectedHeading.value = 'Normal text';
            } else {
                vm.selectedHeading.value = 'Heading ' + match.attrs.level;
            }

            // Check if the selection is an image
            const node = state.doc.nodeAt(selection.from);
            if (node && node.type.name === 'image') {
                vm.openAltTextEditor(); // Populate the alt text input
            }
        });

        // Manualmente forzare un aggiornamento della selezione
        const {view} = vm.editor;
        if (view.state.selection.from === 0 && view.state.doc.firstChild.type.name === 'image') {
            // Se la selezione è all'inizio e il primo nodo è un'immagine
            vm.openAltTextEditor();
        }

        vm.editor.on('init', () => {
            const {state} = vm.editor.view;
            const {selection} = state;

            // Check if the initial selection is an image
            const node = state.doc.nodeAt(selection.from);
            if (node && node.type.name === 'image') {
                vm.openAltTextEditor(); // Populate the alt text input
            }
        });
    }

    $scope.$on('$destroy', function () {
        if (vm.editor) {
            vm.editor.destroy();
        }
    });

    const setLink = () => {
        const previousUrl = vm.editor.getAttributes('link').href;
        const url = window.prompt('Provide the final destination URL of your link', previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === '') {
            vm.editor.chain().focus().extendMarkRange('link').unsetLink()
                .run();

            return;
        }

        // update link
        vm.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({href: url})
            .run();
    };


// Function to check if undo/redo is available and update the button states
    function updateUndoRedoState() {
        if (vm.editor) {
            const canUndo = vm.editor.can().undo();
            const canRedo = vm.editor.can().redo();

            // Update the toolbar buttons' disable state
            vm.editorToolbar.forEach(button => {
                if (button.command === 'undo') {
                    button.disable = !canUndo;
                } else if (button.command === 'redo') {
                    button.disable = !canRedo;
                }
            });

            // If you're using Bubble Menu for undo/redo:
            vm.editorToolbarBubble.forEach(button => {
                if (button.command === 'undo') {
                    button.disable = !canUndo;
                } else if (button.command === 'redo') {
                    button.disable = !canRedo;
                }
            });

            $scope.$applyAsync();  // Apply changes to the scope
        }
    }


    $timeout(() => {
        initEditor();

// Listen for selection changes to trigger the alt text editor
        vm.editor.on('selectionUpdate', () => {
            const {state} = vm.editor.view;
            const {selection} = state;

            // Check if the selection is an image
            const node = state.doc.nodeAt(selection.from);
            if (node && node.type.name === 'image') {
                vm.openAltTextEditor(); // Populate the alt text input
            }
        });
    }, 50);
}

ControllerApp.$inject = ['__env', 'UserService', '$element', '$translate', '$timeout', '$scope', '$stateParams', '$sce', '$window', 'article', '$mdDialog', 'DocxService', 'WriterAssistantService'];

export default ControllerApp;

'use strict';
define([], function () {
    function Controller(__env, UserService, $scope, $mdMedia, userInfo, BetaProgramService, PlanDetailsService, SEOProjectsService, AuthService, $timeout, SubscriptionService, WordpressService, TrackEventService, $localStorage) {
        TrackEventService.gaV2Event('screen_view', {
            screen_name: 'dashboard',
        });
        /* The beta test will start here immediately after login,
        ps. this function works only on development stage*/
        var vm = $scope;

        vm.trialPeriodDays = __env.trialPeriodDays;
        vm.$mdMedia = $mdMedia;
        vm.isPromoTime = PlanDetailsService.isPromoTime();
        vm.projects = [];
        vm.isLoading = true;
        vm.workspace = $localStorage.currentUser && $localStorage.currentUser.workspace ? $localStorage.currentUser.workspace : {};
        vm.loadingPosts = true;
        vm.currentUser = userInfo;
        vm.subscriptions = {
            isLoading: true, data: [], hasPitchGroundSub: false
        };
        vm.isPartner = false;
        vm.projectsAllowed = 0;
        vm.formattedProjectsAllowed = 0;
        vm.monthlyPlans = [];
        vm.annualPlans = [];
        vm.userHasAnAnnualPlanLiteActive = false;

        vm.decodeHTMLEntities = decodeHTMLEntities;

        function _constructor() {
            getRankTrackerProject();
            getSubscriptionInfo();
            getPostsFromWordpress();
        }

        vm.getColorByScore = getColorByScore;
        vm.getTrialDaysLeft = getTrialDaysLeft;
        vm.getTrialDaysLeftScore = getTrialDaysLeftScore;
        vm.getTrialDays = getTrialDays;
        vm.userHasAnActiveSubscription = userHasAnActiveSubscription;
        vm.isTrial = isTrial;
        vm.trialWasExpired = trialWasExpired;
        vm.shortcutBoxesData = getShortcutBoxesDataByPermissions(vm.workspace.permissions);
        vm.isOffline = vm.currentUser && vm.currentUser.account && vm.currentUser.account === 'offline';
        vm.userCanSeePromotion = checkIfUserCanSeePromo();

        function checkIfUserCanSeePromo() {
            if (!vm.isPromoTime) {
                return false;
            }

            if (!vm.userHasAnActiveSubscription(vm.currentUser) || vm.isOffline) {
                return true;
            }

            if ((vm.monthlyPlans.length > 0 && vm.annualPlans.length <= 0) || (vm.annualPlans.length === 1 && vm.userHasAnAnnualPlanLiteActive)) {
                return true;
            }

            return false;
        }

        function getShortcutBoxesDataByPermissions(permissions) {
            if (!permissions) {
                return;
            }

            var shortcutBoxesData = [{
                imgName: 'billing.png',
                title: 'welcome.billing',
                description: 'welcome.download.the.latest.invoices.for.your.workspace.from.here',
                buttonCta: 'Download',
                redirectTo: 'app.settings.invoices',
                permissionToHave: 'workspace_invoice_read'
            }, {
                imgName: 'affiliation.png',
                title: 'welcome.affiliation',
                description: 'welcome.earn.money.by.promoting.our.products',
                buttonCta: 'welcome.earn.money',
                redirectTo: 'app.settings.invoices',
                permissionToHave: 'workspace_affiliation'
            }, {
                imgName: 'partner_program.svg',
                title: 'welcome.partner.program',
                description: 'welcome.manage.your.partnership.data.in.the.best.possible.way',
                buttonCta: 'welcome.manage',
                redirectTo: 'app.settings.partnersArea',
                permissionToHave: 'workspace_partner_program_read'
            }];

            return shortcutBoxesData.filter(function (shortcutBoxData) {
                return permissions.indexOf(shortcutBoxData.permissionToHave) > -1;
            });

        }

        function getSubscriptionInfo() {
            if (!AuthService.isAuthenticated()) {
                vm.subscriptions.isLoading = false;
                return;
            }
            vm.subscriptions.isLoading = true;
            return SubscriptionService.plans({
                status: 'active'
            }).then(function (result) {
                var results = [];

                var subscriptionList = result.data;
                for (var j = 0; j < subscriptionList.length; j++) {
                    if (subscriptionList[j].status.indexOf('active') !== -1) {
                        results.push(subscriptionList[j]);
                    }
                    if (subscriptionList[j].status.indexOf('trialing') !== -1) {
                        results.push(subscriptionList[j]);
                    }
                }
                for (var i = 0; i < results.length; i++) {
                    if (results[i].plans[0].alias.startsWith('pitchground') || results[i].plans[0].alias.startsWith('plan-pg')) {
                        vm.subscriptions = {
                            isLoading: false, data: results, hasPitchGroundSub: true
                        };
                        return;
                    }

                    if (results[i].plans[0].alias.startsWith('plan-partner')) {
                        vm.isPartner = true;
                        return;
                    }

                }
                vm.subscriptions = {
                    isLoading: false, data: results, hasPitchGroundSub: false
                };
            }).catch(function (err) {
                vm.subscriptions.isLoading = false;
                return {};
            });
        }

        function getRankTrackerProject() {
            if (!AuthService.isAuthenticated()) {
                vm.isLoading = false;
                return;
            }
            vm.isLoading = true;
            SEOProjectsService.findAll()
                .then(function (projects) {
                    vm.projects = projects;
                    vm.projectsCreated = vm.projects.length;
                    vm.isLoading = false;
                })
                .catch(function () {
                    vm.projects = [];
                    vm.projectsCreated = 0;
                    vm.isLoading = false;
                });
        }

        var entities = {
            'amp': '&',
            '#038': '&',
            'apos': '\'',
            '#x27': '\'',
            '#x2F': '/',
            '#39': '\'',
            '#47': '/',
            'lt': '<',
            'gt': '>',
            'nbsp': ' ',
            'quot': '"',
            '#8217': '\'',
            '#8211': '-'
        };

        function decodeHTMLEntities(text) {
            return text.replace(/&([^;]+);/gm, function (match, entity) {
                return entities[entity] || match;
            });
        }

        function getPostsFromWordpress() {
            vm.loadingPosts = true;
            WordpressService.blogPosts()
                .then(function (posts) {
                    vm.posts = posts;
                    vm.loadingPosts = false;
                })
                .catch(function (err) {
                    vm.posts = '';
                    vm.loadingPosts = false;
                });
        }

        vm.$on("deleteProject", function (evt) {
            getRankTrackerProject();
        });


        vm.$on("languageChanged", function () {
            vm.posts = '';
            vm.loadingPosts = true;
            getPostsFromWordpress();
        });

        if (userInfo && userInfo.limits && userInfo.limits.length) {
            angular.forEach(userInfo.limits, function (service) {
                if (service.alias === 'seo-project-limit') {
                    vm.projectsAllowed = service.qtyAllowed;
                }
            });
        }

        vm.formattedProjectsAllowed = vm.projectsAllowed;
        if (vm.projectsAllowed >= 9999) {
            vm.formattedProjectsAllowed = '∞';
        }

        function getDateDiffInDays(d1, d2) {
            d1 = new Date(d1);
            d2 = new Date(d2);
            var t2 = d2.getTime();
            var t1 = d1.getTime();
            return parseInt((t2 - t1) / (24 * 3600 * 1000));
        }

        function getColorByScore(score) {
            if (score <= 20) {
                return 'st-bg-bar-expired';
            }
            if (score > 20 && score < 50) {
                return 'st-bg-bar-half';
            }
            return 'st-bg-bar-full';
        }

        function getTrialDaysLeft(currentUser) {
            return Math.max(0, getDateDiffInDays(new Date().toDateString(), currentUser.subscriptions[0].trialEnd));
        }

        function getTrialDays(currentUser) {
            return getDateDiffInDays(currentUser.subscriptions[0].trialStart, currentUser.subscriptions[0].trialEnd);
        }

        function trialWasExpired(currentUser) {
            if (!currentUser) return true;
            if (!currentUser.subscriptions || !currentUser.subscriptions[0] || !currentUser.subscriptions[0].trialEnd) return true;
            return new Date() >= new Date(currentUser.subscriptions[0].trialEnd);
        }

        function userHasAnActiveSubscription(currentUser) {
            if (!currentUser) return false;
            if (!currentUser.subscriptions || !currentUser.subscriptions.length) return false;
            var foundAnActiveSubscription = false;
            vm.monthlyPlans = [];
            vm.annualPlans = [];

            for (var i = 0; i < currentUser.subscriptions.length; i++) {

                if ((currentUser.subscriptions[i].status === 'active' || currentUser.subscriptions[i].status === 'trialing')) {
                    if (new Date() <= new Date(currentUser.subscriptions[i].currentPeriodEnd)) {
                        foundAnActiveSubscription = true;

                        if (currentUser.subscriptions[i].plans.length && currentUser.subscriptions[i].plans[0].interval === 'month') {
                            vm.monthlyPlans.push(currentUser.subscriptions[i].plans[0]);
                        }

                        if (currentUser.subscriptions[i].plans.length && currentUser.subscriptions[i].plans[0].interval === 'year') {
                            if (currentUser.subscriptions[i].plans[0].alias == 'plan-lite-annual') {
                                vm.userHasAnAnnualPlanLiteActive = true;
                            }
                            vm.annualPlans.push(currentUser.subscriptions[i].plans[0]);
                        }
                    }
                }
            }

            return foundAnActiveSubscription;
        }

        function getTrialDaysLeftScore(currentUser) {
            if (!currentUser) {
                return 0;
            }
            var trialDaysLeft = getTrialDaysLeft(currentUser);
            var totalTrialDays = getTrialDays(currentUser);
            if (!totalTrialDays) return 0;
            return parseInt((trialDaysLeft / totalTrialDays) * 100);
        }


        function isTrial(currentUser) {
            return currentUser && currentUser.subscriptions && currentUser.subscriptions.length === 1 && currentUser.subscriptions[0].status === 'trialing';
        }

        _constructor();
    }

    Controller.$inject = ['__env', 'UserService', '$scope', '$mdMedia', 'userInfo', 'BetaProgramService', 'PlanDetailsService', 'ProjectsService', 'AuthService', '$timeout', 'SubscriptionService', 'WordpressService', 'TrackEventService', '$localStorage'];
    return Controller;
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.report", {
                controller: 'SSReportCtrl',
                abstract: true,
                templateUrl: './app/modules/seospider/exam/analysis/report/report.html',
                access: {restricted: true},
            })
            .state("app.seospider.exam.analysis.report.overview", {
                url: "/report",
                templateUrl: './app/modules/seospider/exam/analysis/report/overview/overview.html',
                access: {restricted: true},
                controller: 'SSReportOverviewCtrl',
                resolve: {/*
                    analysisDetail: ['SeospiderService', '$q', '$stateParams', function (SeospiderService, $q, $stateParams) {
                        var dfd = $q.defer();
                        SeospiderService.getSessions($stateParams.projectId, $stateParams.sessionId)
                            .then(function (res) {
                                dfd.resolve(res);
                            })
                            .catch(function () {
                                dfd.resolve({});
                            });
                        return dfd.promise;

                    }]*/
                }
            })
            .state("app.seospider.exam.analysis.report.base", {
                url: "/report/base",
                templateUrl: './app/modules/seospider/exam/analysis/report/base/base.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.report.content", {
                url: "/report/content",
                templateUrl: './app/modules/seospider/exam/analysis/report/content/content.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.report.structure", {
                url: "/report/structure",
                templateUrl: './app/modules/seospider/exam/analysis/report/structure/structure.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.report.duplicated", {
                url: "/report/duplicated",
                templateUrl: './app/modules/seospider/exam/analysis/report/duplicated/duplicated.html',
                access: {restricted: true}
            })
            .state("app.seospider.exam.analysis.report.social", {
                url: "/report/social",
                templateUrl: './app/modules/seospider/exam/analysis/report/social/social.html',
                access: {restricted: true}
            })
    }

    config.$inject = ['$stateProvider'];
    return config;
});

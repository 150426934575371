'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$mdDialog,SEOEditorService,article,audit,$stateParams) {
        var vm=$scope;

        vm.loading={
            post: false
        };
        vm.campaign={
            name: null
        };

        vm.duplicatedNameCampaignError=false;
        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;
        var content = article;
        vm.article = {
            articleId:$stateParams.id,
            content:content,
            audit:audit
        };

        vm.changeNameArticle=function (title) {
            vm.article.content.title = title;
        };

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            $mdDialog.cancel();
        }
        function answer() {
            SEOEditorService.updateArticle($stateParams.id,vm.article).then(function(result){
                $mdDialog.hide();
            }).catch(function(err){
                console.log(err);
            });
        }
    }
    Controller.$inject=['$scope','$mdDialog','SEOEditorService','article','audit','$stateParams'];
    return Controller;
});

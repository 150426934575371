/**
 * @ngdoc overview
 * @name searchconsole.service:SearchConsoleService
 */
'use strict';
define(['app'], function (app) {
    app.service('SearchConsoleService', SearchConsoleService);
    SearchConsoleService.$inject = ['API', '__env'];

    function SearchConsoleService(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.searchConsoleAPI;

        this.generateAuthUrl = function () {
            return API.get(apiPath + '/auth-url', true, true);
        };

        this.getToken = function (code) {
            return API.get(apiPath + '/token?code=' + code, true);
        };

        this.getSites = function (token) {
            return API.get(apiPath + '/sites?token=' + token, true);
        };

        this.getQueries = function (token, filters) {
            return API.get(apiPath + '/queries?token=' + token + '&filters=' + JSON.stringify(filters), true);
        };
    }
});

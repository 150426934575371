'use strict';
define([], function () {
    function Controller($scope, $stateParams, LeadGenerationService, $mdToast, $translate, $element, settings) {
        var vm = $scope;
        vm.settings = settings;
        vm.isLoading = true;
        vm.validateAndAddEmail = validateAndAddEmail;
        vm.removeEmail = removeEmail;
        vm.emailInWhitelist = [];

        function getWhitelist() {
            vm.emailInWhitelist = vm.settings.whitelist ? vm.settings.whitelist.split(',') : [];
        }

        function saveSettings(action, email) {
            LeadGenerationService
                .saveSettings({
                    formId: $stateParams.formId,
                    whitelist: vm.emailInWhitelist.join(','),
                    name: vm.settings.name,
                    autoLeadUnlock: vm.settings.autoLeadUnlock
                })
                .then(function () {
                    if (action === 'add') {
                        showCustomToast('leadgeneration.whitelist.saved', 'icon-checkmark st-green');
                    } else if (action === 'remove') {
                        showCustomToast('leadgeneration.email.was.removed.from.whitelist', 'icon-checkmark st-green', {email: email});
                    }
                }, function (err) {
                    showCustomToast('settings.something.went.wrong', 'icon-x st-red');
                });
        }

        function validateAndAddEmail(email) {
            if (event.which === 13) {
                var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
                if (!reg.test(email)) {
                    showCustomToast('email.not.valid', 'icon-x st-red');
                    vm.emailInWhitelist.pop();
                    return;
                }

                if (vm.emailInWhitelist.length > 10) {
                    showCustomToast('leadgeneration.you.cant.add.more.than.10.emails.in.whitelist', 'icon-x st-red');
                    vm.emailInWhitelist.pop();
                    return;
                }

                saveSettings('add');

            }
        }

        function removeEmail(email) {
            saveSettings('remove', email);
        }

        function showCustomToast(string, classIcon, data) {
            var translation;
            if (data && data.email) {
                translation = $translate.instant(string, {email: data.email});
            } else {
                translation = $translate.instant(string);
            }
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + translation + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: $element[0].querySelector('.toast-show')
            });
        }

        function init() {
            getWhitelist();
        }

        init();
    }

    Controller.$inject = ['$scope', '$stateParams', 'LeadGenerationService', '$mdToast', '$translate', '$element', 'settings'];
    return Controller;
});

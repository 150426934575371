'use strict';
define(['app'], function (app) {
    app.service('stLimitMessage', stLimitMessage);
    stLimitMessage.$inject = ['$translate', '__env'];

    function stLimitMessage($translate, __env) {
        return {
            getFromError: get
        };

        function get(error, userInfo) {
            if (!userInfo) {
                userInfo = {account: 'offline', canDoTrial: false};
            }
            if (error.status !== 429 && error.status !== 426) {
                return;
            }
            if (error.data.error.request && error.data.error.request.offline) {
                return {
                    title: 'keyword.exp.table.finish.credits',
                    subtitle: 'keyword.exp.table.limit.account.trial.and.upgrade',
                    icon: 'icon-no-serp-credits',
                    cta:
                        '<md-button ' +
                        'class="p-l-lg p-r-lg md-raised st-button st-bg-green-2 m-0 md-button st-white"' +
                        ' ui-sref="access.signup">'+
                        $translate.instant('cta.sign.up.free')+
                        '</md-button>'+
                        '<div class="md-caption m-t st-grey-3">'+$translate.instant( "keyword.exp.table.limit.account")+'</div>'
                };
            }
            if (userInfo.canDoTrial) {
                return {
                    title: 'keyword.exp.table.finish.credits',
                    subtitle: 'keyword.exp.table.limit.account.trial.and.upgrade',
                    icon: 'icon-sf-trial',
                    cta: '<md-button ' +
                        'class="p-l-lg p-r-lg md-raised st-button st-bg-green-2 m-0 md-button st-white"' +
                        ' ui-sref="upgrade.choose-plan">'+
                        $translate.instant('cta.get.trial', {trialPeriodDays: __env.trialPeriodDays}) +
                        '</md-button>'+
                        '<div class="st-grey-3 md-caption m-t-md" ng-bind-html="\'upgrade.no.commitments\'|translate"></div>'

                };
            }
            return {
                title: 'keyword.exp.table.finish.credits',
                subtitle: 'keyword.exp.trend.nodata.credits.tip',
                icon: 'icon-no-serp-credits',
                cta: '<md-button ' +
                    'class="p-l-lg p-r-lg md-raised st-button st-bg-green-2 m-0 md-button st-white" ui-sref="upgrade.choose-plan">'+
                    $translate.instant('cta.get.sub') +
                    '</md-button>'+
                    '<div class="st-grey-3 md-caption m-t-md" ng-bind-html="\'upgrade.no.commitments\'|translate"></div>'
            };
        }
    }

    app.service('ErrorMessageService', ErrorMessageService);
    ErrorMessageService.$inject = ['__env'];
    var messages;

    function ErrorMessageService(__env) {
        var templateNoDataSerp = '' +
            '<i class="icon-no-data-serp custom-message-template-icon"></i>' +
            '<div class="md-headline-1 m-b-xs">' +
            '   {{\'keyword.exp.trend.nodata.serp.message\'|translate}}' +
            '</div>' +
            '<div class="md-subhead m-b-md st-grey-3">' +
            '   {{\'keyword.exp.trend.nodata.quick.tip\'|translate}}' +
            '</div>';
        var templateNoDataKeyword = '' +
            '<i class="icon-no-data-kw custom-message-template-icon"></i>' +
            '<div class="md-headline-1 m-b-xs">' +
            '   {{\'keyword.exp.trend.nodata.message\'|translate}}' +
            '</div>' +
            '<div class="md-subhead m-b-md st-grey-3">' +
            '   {{\'keyword.exp.trend.nodata.quick.tip\'|translate}}' +
            '</div>';

        function setCallToAction(userStatus, icon) {
            var callToAction;
            if (userStatus === 'sub') {
                callToAction = '' +
                    '<a class="md-body-1 md-primary txt-decoration-und" ' +
                    '   ui-sref="upgrade.choose-plan"> ' +
                    '   <strong>{{\'cta.get.sub\'|translate | lowercase}}</strong>' +
                    '</a>';
            }
            if (userStatus === 'trial') {
                callToAction = '' +
                    '<a class="md-body-1 md-primary txt-decoration-und" ' +
                    ' ui-sref="upgrade.choose-plan"> ' +
                    '   <strong>{{\'cta.get.trial\'| translate: {trialPeriodDays: ' + __env.trialPeriodDays + '} }}</strong>' +
                    '</a>';
            }
            if (userStatus === 'offline') {
                callToAction = '' +
                    '<a class="md-body-1 md-primary txt-decoration-und" ' +
                    '   ui-sref="access.signup"> ' +
                    '   <strong>{{\'cta.sign.up.free\'|translate | lowercase}}</strong>' +
                    '</a>';
            }
            var template = '' +
                icon +
                '<div class="md-headline-1 m-b-xs">{{\'keyword.exp.table.finish.credits\'|translate}}' +
                '</div>' +
                '<div class="md-subhead m-b-md st-grey-3">' +
                '   {{\'keyword.exp.trend.nodata.credits.tip\'|translate}}' +
                ', ' + callToAction +
                '</div>';
            return template;
        }

        messages = {
            code: {
                'DATA': {
                    'SUCCESS': {
                        'KEYWORD': {
                            sub: {
                                code: 'SUCCESS'
                            },
                            offline: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account\' | translate}}</div>' +
                                '<md-button class="md-button md-raised st-button st-green"' +
                                'ui-sref="access.signup">{{ \'cta.sign.up.free\' | translate}}</md-button>',
                            onlineCanDoTrial: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account.trial.and.upgrade\' | translate}}</div>' +
                                '<md-button class="md-button md-raised st-button st-green"' +
                                'ui-sref="upgrade.choose-plan">{{ \'cta.get.trial\' | translate: {trialPeriodDays: ' + __env.trialPeriodDays + '} }}</md-button>',
                            online: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account.trial.and.upgrade\' | translate}}</div>' +
                                '<md-button class="md-button md-raised st-button st-green"' +
                                'ui-sref="upgrade.choose-plan">{{ \'cta.get.sub\' | translate}}</md-button>',
                        },
                        "NOT_IN_SUBSCRIPTION": {
                            'SERP': {
                                onlineCanDoTrial: templateNoDataSerp,
                            },
                            'KEYWORD': {
                                onlineCanDoTrial: templateNoDataKeyword,
                            }
                        },
                        'INTERNAL_SERVER_ERROR': {
                            'SERP': {
                                sub: templateNoDataSerp,
                                offline: templateNoDataSerp,
                                onlineCanDoTrial: templateNoDataSerp,
                                online: templateNoDataSerp
                            },
                            'KEYWORD': {
                                sub: templateNoDataKeyword,
                                offline: templateNoDataKeyword,
                                onlineCanDoTrial: templateNoDataKeyword,
                                online: templateNoDataKeyword
                            },
                        },
                        'NO_ENOUGH_CREDITS': {
                            'SERP': {
                                sub: setCallToAction('sub',
                                    '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                                offline: setCallToAction('offline',
                                    '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                                onlineCanDoTrial: setCallToAction('trial',
                                    '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                                online: setCallToAction('sub',
                                    '<i class="icon-no-serp-credits custom-message-template-icon"></i>')
                            },
                            'KEYWORD': {
                                sub: {code: 'success'},
                                offline: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account\'| translate}}</div>' +
                                    '<md-button class="md-button md-raised st-button st-green"' +
                                    'ui-sref="access.signup">{{ \'cta.sign.up.free\' | translate}}</md-button>',
                                onlineCanDoTrial: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account.trial.and.upgrade\' | translate}}</div>' +
                                    '<md-button class="md-button md-raised st-button st-green"' +
                                    'ui-sref="upgrade.choose-plan">{{ \'cta.get.trial\' | translate: {trialPeriodDays: ' + __env.trialPeriodDays + '} }}</md-button>',
                                online: '<div class="md-subhead m-b p-l p-r">{{\'keyword.exp.table.limit.account.trial.and.upgrade\'| translate}}</div>' +
                                    '<md-button class="md-button md-raised st-button st-green"' +
                                    'ui-sref="upgrade.choose-plan">{{ \'cta.get.sub\' | translate}}</md-button>',
                            },
                        }
                    },
                },
                'NO_DATA': {
                    'INVALID_PARAMS': {
                        'SERP': {
                            sub: templateNoDataSerp,
                            offline: templateNoDataSerp,
                            onlineCanDoTrial: templateNoDataSerp,
                            online: templateNoDataSerp
                        },
                        'KEYWORD': {
                            sub: templateNoDataKeyword,
                            offline: templateNoDataKeyword,
                            onlineCanDoTrial: templateNoDataKeyword,
                            online: templateNoDataKeyword
                        },
                    },
                    'SUCCESS': {
                        'SERP': {
                            sub: templateNoDataSerp,
                            offline: templateNoDataSerp,
                            onlineCanDoTrial: templateNoDataSerp,
                            online: templateNoDataSerp
                        },
                        'KEYWORD': {
                            sub: templateNoDataKeyword,
                            offline: templateNoDataKeyword,
                            onlineCanDoTrial: templateNoDataKeyword,
                            online: templateNoDataKeyword
                        },
                    },
                    'NO_ENOUGH_CREDITS': {
                        'SERP': {
                            sub: setCallToAction('sub',
                                '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                            offline: setCallToAction('offline',
                                '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                            onlineCanDoTrial: setCallToAction('trial',
                                '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                            online: setCallToAction('sub',
                                '<i class="icon-no-serp-credits custom-message-template-icon"></i>')
                        },
                        'KEYWORD': {
                            sub: setCallToAction('sub',
                                '<i class="icon-no-kw-credits custom-message-template-icon"></i>'),
                            offline: setCallToAction('offline',
                                '<i class="icon-no-kw-credits custom-message-template-icon"></i>'),
                            onlineCanDoTrial: setCallToAction('onlineCanDoTrial',
                                '<i class="icon-no-kw-credits custom-message-template-icon"></i>'),
                            online: setCallToAction('online',
                                '<i class="icon-no-kw-credits custom-message-template-icon"></i>')
                        },
                    },
                    "NOT_IN_SUBSCRIPTION": {
                        'SERP': {
                            onlineCanDoTrial: setCallToAction('trial',
                                '<i class="icon-no-serp-credits custom-message-template-icon"></i>'),
                        },
                        'KEYWORD': {
                            onlineCanDoTrial: setCallToAction('trial',
                                '<i class="icon-no-kw-credits custom-message-template-icon"></i>')
                        }
                    },
                    'INTERNAL_SERVER_ERROR': {
                        'SERP': {
                            sub: templateNoDataSerp,
                            offline: templateNoDataSerp,
                            onlineCanDoTrial: templateNoDataSerp,
                            online: templateNoDataSerp
                        },
                        'KEYWORD': {
                            online: templateNoDataKeyword,
                            onlineCanDoTrial: templateNoDataKeyword,
                            offline: templateNoDataKeyword,
                            sub: templateNoDataKeyword,
                        }

                    }
                },
            }
        };

        return {
            setMessageForTheTable: setMessageForTheTable
        };
    }

    function setMessageForTheTable(numberOfRows, messageFromServer, userInfo, typeOfResearch) {
        if (!userInfo) {
            userInfo = {account: 'offline', canDoTrial: false};
        }
        numberOfRows = numberOfRows ? 'DATA' : 'NO_DATA';
        messageFromServer = messageFromServer.toUpperCase();

        if (!messageFromServer) {
            return 'error';
        }
        if (userInfo.account === 'online' && userInfo.canDoTrial) {
            return messages.code[numberOfRows][messageFromServer][typeOfResearch]['onlineCanDoTrial'];
        }
        return messages.code[numberOfRows][messageFromServer][typeOfResearch][userInfo.account];
    }
});

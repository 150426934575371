'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/components/category-toolbar/category-toolbar.html',
        bindings: {pagination: '=', search: '=', tabs: '='},
        controller: ['$scope', '$state', function ($scope, $state) {
            var vm = this;
            vm.searchInputIsFocused = false;
            vm.$state = $state;
            vm.changeCategory = changeCategory;
            vm.currentCategory = $state.current.name;
            vm.translateId = 'seospider.';
            vm.$onInit = function () {
                vm.currentCategory = $state.current.name;
            };

            function changeCategory() {
                $state.go(vm.currentCategory);
            }

            $scope.$watch('$ctrl.pagination', function (pagination) {
                vm.pagination = pagination;                
            });
            $scope.$watch('$ctrl.search', function (search) {
                vm.search = search;
            });
            $scope.$watchCollection('$ctrl.tabs', function (tabs) {
                vm.tabs = tabs;
            });
        }]
    };
});

'use strict';
define(['angular'], function (ng) {
    function Controller($scope, $rootScope, $stateParams, $q, results, jobId, Pages, $translate) {
        var vm = $scope;
        vm.query = '';
        vm.jobId = jobId;
        vm.openDetail = openDetail;

        vm.pages = results.data;
        vm.pagination = {
            numElements: results.totalRows,
            offset: 0,
            callback: pagingCallback
        };

        vm.currentSearch = {
            placeholder: $translate.instant('seospider.h2.search'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.h2.search'),
                callback: searchCallback
            };
        });


        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAllH2Duplicated({
                jobId: $stateParams.sessionId,
                q: query,
                offset: 0
            })
                .then(function (res) {
                    vm.pagination.offset = 0;
                    vm.pagination.numElements = results.totalRows;
                    vm.pages = res.data;
                })
                .catch(function (err) {
                    console.log(err);
                    vm.pages = err.data;
                });
        }

        function pagingCallback(limit, offset) {
            var dfd = $q.defer();
            Pages.findAllH2Duplicated({
                jobId: $stateParams.sessionId,
                q: vm.query,
                limit: limit,
                offset: offset
            })
                .then(function (res) {
                    vm.pagination.numElements = results.totalRows;
                    vm.pages = res.data;
                    dfd.resolve();
                })
                .catch(function (err) {
                    console.log(err);
                    vm.pages = err.data;
                    dfd.resolve();
                });
            return dfd.promise;
        }

        function openDetail(info) {
            var toolbarInfo = {
                list: info.duplicated,
                element: 'h2',
                label: $translate.instant('seospider.duplicated.in.page'),
                placeholder: $translate.instant('seospider.h2.filter', {'infoH2': info.h2})
            };
            $rootScope.$emit('openToolbarDetail', toolbarInfo);
            $rootScope.$on('languageChanged', function () {
                $rootScope.$emit('openToolbarDetail', {
                        list: info.duplicated,
                        element: 'h2',
                        label: $translate.instant('seospider.duplicated.in.page'),
                        placeholder: $translate.instant('seospider.h2.filter', {'infoH2': info.h2})
                    }
                );
            });
        }
    }

    Controller.$inject = ['$scope', '$rootScope', '$stateParams', '$q', 'results', 'jobId', 'SeospiderPagesService', '$translate'];
    return Controller;
});

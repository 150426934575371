'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/keywordtracker/components/project-card/project-card.html',
        bindings: {
            project: '<',
            type: '<',
            isLoading: '<',
        },
        controller: ['$scope', 'FavIconService', '$state', '$mdDialog', '$rootScope', function ($scope, FavIconService, $state, $mdDialog, $rootScope) {
            var colors = ['#B5269E', '#26B57F', '#FF6F00', '#0BC7B5', '#DB3236'];
            var ctrl = this;
            $scope.isDelete = false;

            $scope.getFavIcon = FavIconService.get;
            $scope.showDropAlert = showDropAlert;
            $scope.addKeywords = addKeywords;
            $scope.goToSettings = goToSettings;
            $scope.goToProject = goToProject;
            $scope.competitorsSelected;

            $scope.$watch('$ctrl.project', function (project) {
                if (project) {
                    project.keywords = sortSearches(project.keywords);
                    project.uniqueCountries = uniqueCountries(project.locations);
                    project.uniqueDevices = uniqueDevices(project.locations);
                    $scope.project = project;
                }
            });

            $scope.$watch('$ctrl.isLoading', function (isLoading) {
                if (isLoading) {
                    ctrl.isLoading = isLoading;
                }
            });

            angular.element(document).ready(function () {
                if (!$scope.project) {
                    return;
                }

                $scope.competitorsSelected = $scope.project.competitors.map(function (competitor) {
                    return competitor.linkUrl;
                });
            });

            $scope.setColorPerCompetitor = function (index) {
                return colors[index];
            };

            function uniqueCountries(locations) {
                var inserted = {};
                var unique = [];
                for (var i = 0; i < locations.length; i++) {
                    if (!inserted[locations[i].countryCode]) {
                        inserted[locations[i].countryCode] = true;
                        unique.push(locations[i]);
                    }
                }
                return unique;
            }

            function uniqueDevices(locations) {
                var inserted = {};
                var unique = [];
                for (var i = 0; i < locations.length; i++) {
                    if (!inserted[locations[i].deviceId]) {
                        inserted[locations[i].deviceId] = true;
                        unique.push(locations[i].deviceId);
                    }
                }
                return unique;
            }

            function sortSearches(keywords) {
                keywords.map(function (keyword) {
                    if (keyword && keyword.searches && keyword.searches.length > 0) {
                        return keyword.searches.sort(function (a, b) {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                        });
                    }
                });
                keywords.map(function (keyword) {
                    if (keyword && keyword.competitorSearches) {
                        var idCompetitors = Object.keys(keyword.competitorSearches);
                        angular.forEach(idCompetitors, function (id) {
                            return keyword.competitorSearches[id].sort(function (a, b) {
                                return new Date(b.createdAt) - new Date(a.createdAt);
                            });
                        });
                    }
                });
                return keywords;
            }

            function showDropAlert(project, ev) {
                console.log('project from tracker', project);

                $mdDialog.show({
                    controller: 'ProjectDeleteProjectCtrl',
                    templateUrl: "app/modules/project/dialogs/delete-project/delete-project.html",
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    parent: angular.element(document.body),
                    locals: {
                        project: project
                    }
                }).then(function (answer) {
                    localStorage.removeItem('project-filters' + project.id);
                    $scope.isDelete = true;
                    $rootScope.$broadcast("deleteProject");
                }, function () {
                    $scope.status = 'You cancelled the dialog.';
                });
            }

            $scope.cleanUrl = function (url) {
                url = url.replace('https://', '');
                url = url.replace('http://', '');
                url = url.replace('www.', '');
                url = url.replace(/^www\./i, '');
                url = url.replace(/\/$/i, '');
                return url.trim();
            }

            function addKeywords(trackerProjectId, ev) {
                ev.preventDefault();
                ev.stopPropagation();
                $state.go(
                    'app.keywordtracker.addkeywords',
                    {
                        action: 'GET',
                        trackerProjectId: trackerProjectId
                    }
                );
            }

            function goToSettings(projectId) {
                $state.go(
                    'app.project.settings',
                    {
                        action: 'GET',
                        projectId: projectId,
                        activeTab: 'scope'
                    }
                );
            }

            function goToProject(id) {
                if (ctrl.isLoading) {
                    return;
                }
                $state.go(
                    'app.keywordtracker.overview',
                    {
                        action: 'GET',
                        project: id
                    }
                );
            }
        }]
    };
});

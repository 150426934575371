'use strict';
define([], function () {
    function ApiController($scope, apiToken, $localStorage, LogService, userApiCredits, $timeout, LanguageService, $window) {
        var vm = $scope;
        vm.workspace = $localStorage.currentUser.workspace;
        vm.rows = [];
        vm.userApiCredits = userApiCredits;
        vm.apiToken = apiToken;
        vm.isLoading = true;

        function getLogs() {
            LogService.getApiLogs()
                .then(function (res) {
                    if(!res || !res.data || !res.data.allLogs) {
                        vm.isLoading = false;
                        throw new Error('invalid result');

                    }

                    var allLogs = res.data.allLogs;
                    angular.forEach(res.data.allLogs, function (value, key) {
                        var payload = JSON.parse(value.payload);
                        allLogs[key].method = payload.method;
                        allLogs[key].endpoint = payload.endpoint;
                        allLogs[key].credits = payload.credits;
                        allLogs[key].response = payload.response;

                    });
                    vm.rows = allLogs;
                    vm.isLoading = false;
                    console.log('rows', vm.rows)
                });

        }

        getLogs();

        vm.controllerApiLogs = {
            downloadResponseAsJsonFile: downloadResponseAsJsonFile,
        }

        function downloadResponseAsJsonFile (row) {
            if(!row || !row.id || typeof row.response == 'undefined' || row.response == null || row.response == "") {
                return;
            }

            var link = document.createElement("a");
            link.download = "response."+row.id+".json";
            var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(row.response, null, "\t"));
            link.href = "data:" + data;
            link.click();
        }

        vm.copyToClipboardOptions = {
            class: 'st-button__rounded--md--green-2--text-white font-bold w-full',
            text: 'settings.api.copy.to.clipboard',
            icon:'icon-copy',
            showToast: true,
            data: vm.apiToken,
            mode: 'string',
        };

        vm.triggerCopyToClipboardButton = function() {
            $timeout(function() {
                angular.element(document.querySelector('#api_key_copy_to_clipboard button')).click();
            });

        }

        vm.goToExternalContactPage = function () {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();

            switch (language) {
                case 'it-IT':
                    // https://it.seotesteronline.com/contacts/
                    $window.open("https://seotesteronline.typeform.com/api-seo-it?utm_source=suite-api", '_blank');
                    break;
                case 'es-ES':
                    // https://es.seotesteronline.com/contacts/
                    $window.open("https://seotesteronline.typeform.com/api-seo-tester?utm_source=suite-api", '_blank');
                    break;
                case 'pl-PL':
                    // https://pl.seotesteronline.com/contacts/
                    $window.open("https://seotesteronline.typeform.com/api-seo-tester?utm_source=suite-api", '_blank');
                    break;
                default:
                    // https://www.seotesteronline.com/contacts/
                    $window.open("https://seotesteronline.typeform.com/api-seo-tester?utm_source=suite-api", '_blank');
            }
        }
    }

    ApiController.$inject = ['$scope', 'apiToken', '$localStorage', 'LogService', 'userApiCredits', '$timeout', 'LanguageService', '$window'];
    return ApiController;
});

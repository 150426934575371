'use strict';
define([], function () {
    function SCTranslationsCtrl($scope, translations) {
        $scope.customAudit = translations.customAudit;
        $scope.lightHouseAudit = translations.lightHouseAudit;
        $scope.pageSpeedAudit = translations.pageSpeedAudit;
    }

    SCTranslationsCtrl.$inject = ['$scope', 'translations'];
    return SCTranslationsCtrl;
})
;

'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/device-rendering/device-rendering.html',
        bindings: { src: '=' , device: '@'},
        controller: ['$scope','$sce',function($scope, $sce) {
            var ctrl = this;
            $scope.$watch('$ctrl.src', function (ctrlSrc) {
                if(ctrlSrc.match(/\?/g)){
                    ctrl.trustSrc = $sce.trustAsResourceUrl(ctrlSrc+"&output=embed");
                }else ctrl.trustSrc = $sce.trustAsResourceUrl(ctrlSrc+"?output=embed");
            });
            $scope.switchResolution=function(device){
                switch(device){
                    case 'iphone-6s':ctrl.device='iphone-6s';break;
                    case 'iphone-6s-plus':ctrl.device='iphone-6s-plus';break;
                    case 'iphone-5':ctrl.device='iphone-5';break;
                    case 'galaxy-s5':ctrl.device='galaxy-s5';break;
                    case 'laptop':ctrl.device='laptop';break;
                    default:ctrl.device='iphone-5';break;
                }
            };
        }]
    };
});
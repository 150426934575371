define([], function () {
    controller.$inject = ['$scope', 'AuthService', '$state', 'WorkspaceService', '$localStorage', '$mdDialog', '$timeout', 'LanguageService', '$window', 'TrackEventService', '$translate', '__env'];

    function controller($scope, AuthService, $state, WorkspaceService, $localStorage, $mdDialog, $timeout, LanguageService, $window, TrackEventService, $translate, __env) {
        var vm = $scope;
        var menu = document.querySelector('.menu');
        vm.goToLink = goToLink;
        vm.chatIsOpen = false;
        vm.url = null;
        vm.language = LanguageService.getCodeAndCountrySaveOnStorage();
        vm.isItalianLanguage = vm.language === 'it-IT';

        vm.$watch(function () {
            return $localStorage.currentUser;
        }, function (newVal) {

            vm.userIsOnline = newVal !== undefined;
            vm.menuItems = [
                {
                    id: 'user-manual',
                    label: 'user.manual',
                    icon: 'menu_book',
                    isVisible: true
                },
                {
                    id: 'seo-guide',
                    label: 'seo.guides',
                    icon: 'local_library',
                    isVisible: true
                },
                {
                    id: 'seo-blog',
                    label: 'blog',
                    icon: 'mode_edit',
                    isVisible: true
                },
                {
                    id: 'roadmap',
                    label: 'roadmap',
                    icon: 'push_pin',
                    isVisible: true
                },
                {
                    id: 'faq',
                    label: 'FAQ',
                    icon: 'live_help',
                    isVisible: true
                },
                {
                    id: 'credits-and-limits',
                    label: 'credits.and.limits',
                    icon: 'data_usage',
                    isVisible: vm.userIsOnline
                },
                {
                    id: 'feedback',
                    label: 'send.us.a.feedback',
                    icon: 'comment',
                    isVisible: true
                },
                {
                    id: 'contact-us',
                    label: 'contact.support',
                    icon: 'headset_mic',
                    isVisible: true
                },
            ];
        });

        vm.$watch(function () {
            return localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        }, function (currentLanguage) {
            vm.isItalianLanguage = currentLanguage === 'it-IT';
        });

        vm.menuIsOpen = function () {
            return menu.classList.contains('active');
        };

        vm.actionToggle = function () {
            if (vm.chatIsOpen) {
                vm.closeChat();
                vm.closeMenu();
            } else {
                var ms = vm.chatIsOpen ? 600 : 0;
                vm.closeChat();
                $timeout(function () {
                    if (!vm.menuIsOpen()) {
                        vm.openMenu();
                    } else {
                        vm.closeMenu();
                    }
                }, ms);
            }
        };

        vm.closeMenu = function () {
            menu.classList.remove('active');
        };

        vm.openMenu = function () {
            menu.classList.add('active');
            if (typeof zE !== 'undefined') {
                zE('messenger:on', 'unreadMessages', function (count) {
                    vm.unreadMessages = count;
                    console.log(`You have ${count} unread message(s).`);
                });
            }
        };

        vm.openChat = function () {
            vm.chatIsOpen = true;
            vm.closeMenu();
            if (typeof zE !== 'undefined') {
                zE('messenger', 'open');
            }

            TrackEventService.event(
                'Interactions',
                'Click Help Floating Button'
            );

            TrackEventService.gaV2Event(
                'click',
                {
                    type: 'Help Floating Button',
                    link_text: $translate.instant('live.chat'),
                }
            );
        };

        vm.closeChat = function () {
            if (vm.chatIsOpen && typeof zE !== 'undefined') {
                zE('messenger', 'close');
                vm.chatIsOpen = false;
            }
        };

        vm.goToBookADemo = function () {
            $window.open("https://calendly.com/sto-platform-sales-team/product-presentation-italiano", '_blank');

            TrackEventService.event(
                'Interactions',
                'Click Help Floating Button',
                'book-demo'
            );

            TrackEventService.gaV2Event(
                'click',
                {
                    type: 'User Manual Button',
                    link_id: 'book-demo',
                    link_text: $translate.instant(''),
                    link_url: 'https://calendly.com/sto-platform-sales-team/product-presentation-italiano',
                }
            );
        };

        function goToLink(item) {
            vm.language = LanguageService.getCodeAndCountrySaveOnStorage();

            switch (item.id) {
                case 'user-manual': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/knowledge-base/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/knowledge-base/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/knowledge-base/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/knowledge-base/";
                    }
                    break;
                }

                case 'seo-guide': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/guide-seo/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/guide-seo/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/seo-guides/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/seo-guides/";
                    }
                    break;
                }

                case 'seo-blog': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/blog/", '_blank');
                        vm.url = "https://it.seotesteronline.com/blog/";
                    } else {
                        $window.open("https://www.seotesteronline.com/blog/", '_blank');
                        vm.url = "https://www.seotesteronline.com/blog/";
                    }
                    break;
                }

                case 'roadmap': {
                    $window.open("https://feedback.seotesteronline.com/", '_blank');
                    vm.url = "https://feedback.seotesteronline.com/";
                    break;
                }

                case 'feedback': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://seotesteronline.typeform.com/to/qV264LHG", '_blank');
                        vm.url = "https://seotesteronline.typeform.com/to/qV264LHG";
                    } else {
                        $window.open("https://seotesteronline.typeform.com/to/YF89ycur", '_blank');
                        vm.url = "https://seotesteronline.typeform.com/to/YF89ycur";
                    }
                    break;
                }

                case 'faq': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/contatti/#faq-section", '_blank');
                        vm.url = "https://it.seotesteronline.com/contatti/#faq-section";
                    } else if (vm.language === 'es-ES') {
                        $window.open("https://es.seotesteronline.com/contacts/#faq-section", '_blank');
                        vm.url = "https://es.seotesteronline.com/contacts/#faq-section";
                    } else if (vm.language === 'pl-PL') {
                        $window.open("https://pl.seotesteronline.com/contacts/#faq-section", '_blank');
                        vm.url = "https://pl.seotesteronline.com/contacts/#faq-section";
                    } else {
                        $window.open("https://www.seotesteronline.com/contacts/#faq-section", '_blank');
                        vm.url = "https://www.seotesteronline.com/contacts/#faq-section";
                    }
                    break;
                }

                case 'contact-us': {
                    if (vm.language === 'it-IT') {
                        $window.open("https://it.seotesteronline.com/contatti/", '_blank');
                        vm.url = "https://it.seotesteronline.com/contatti/";
                    } else if (vm.language === 'es-ES') {
                        $window.open("https://es.seotesteronline.com/contacts/", '_blank');
                        vm.url = "https://es.seotesteronline.com/contacts/";
                    } else if (vm.language === 'pl-PL') {
                        $window.open("https://pl.seotesteronline.com/contacts/", '_blank');
                        vm.url = "https://pl.seotesteronline.com/contacts/";
                    } else {
                        $window.open("https://www.seotesteronline.com/contacts/", '_blank');
                        vm.url = "https://www.seotesteronline.com/contacts/";
                    }
                    break;
                }

                case 'credits-and-limits': {
                    $state.go('app.settings.overview');

                    vm.url = __env.frontEndUrl + '/settings/overview';
                    break;
                }

                default:
            }

            TrackEventService.event(
                'Interactions',
                'Click Help Floating Button',
                item.id
            );

            TrackEventService.gaV2Event(
                'click',
                {
                    type: 'Help Floating Button',
                    link_id: item.id,
                    link_text: $translate.instant(item.label),
                    link_url: vm.url,
                }
            );

        }
    }

    return {
        templateUrl: 'app/components/st-floating-button/st-floating-button.component.html',
        controller: controller,
        bindings: {
            currentUser: '<',
        }
    };
});

'use strict';
define([
    'angular',
    'modules/leadgeneration/form/settings/settings.controller',
    'modules/leadgeneration/form/settings/general/general.controller',
    'modules/leadgeneration/form/settings/whitelist/whitelist.controller',
    'modules/leadgeneration/form/settings/integrations/integrations.controller',
], function (
    angular,
    LeadGenerationFormSettingsCtrl,
    LeadGenerationFormSettingsGeneralCtrl,
    LeadGenerationFormSettingsWhitelistCtrl,
    LeadGenerationFormSettingsIntegrationsCtrl) {
    var app = angular.module('app.leadgeneration.form.settings', []);
    app.controller('LeadGenerationFormSettingsCtrl', LeadGenerationFormSettingsCtrl);
    app.controller('LeadGenerationFormSettingsGeneralCtrl', LeadGenerationFormSettingsGeneralCtrl);
    app.controller('LeadGenerationFormSettingsWhitelistCtrl', LeadGenerationFormSettingsWhitelistCtrl);
    app.controller('LeadGenerationFormSettingsIntegrationsCtrl', LeadGenerationFormSettingsIntegrationsCtrl);
    return app;
});

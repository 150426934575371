define(['angular', 'app'], function (angular, app) {
    app.filter('orderByNumber', [function () {
        function isObject(path) {
            return path && path.indexOf('.') >= 0;
        }

        function getValueFromPath(obj, path) {
            const keys = path.split('.');
            let current = obj;

            for (const key of keys) {
                if (current[key] !== undefined) {
                    current = current[key];
                } else {
                    return undefined; // Se il path non esiste nell'oggetto, ritorna undefined
                }
            }

            return current;
        }

        return function (items, field, reverse) {
            items.sort(function (a, b) {
                if (isObject(field)) {
                    if (getValueFromPath(b, field) == undefined) {
                        return -1;
                    }

                    return (getValueFromPath(a, field) > getValueFromPath(b, field) ? -1 : 1);
                }

                if (b[field] == undefined) {
                    return 0;
                }

                return (a[field] > b[field] ? -1 : 1);
            });
            if (reverse) items.reverse();
            return items;
        };
    }]);
});

define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.backlinkchecker", {
                abstract: true,
                templateUrl: './app/modules/backlink-checker/backlink-checker.html',
            })
            .state("app.backlinkchecker.tool", {
                url: "/backlink-checker?:domain&:filters",
                controller: 'BacklinkCheckerToolCtrl',
                templateUrl: './app/modules/backlink-checker/tool/tool.html',
                resolve: {
                    userInfo: ['UserService', '$q', function (UserService, $q) {
                        var deferred = $q.defer();

                        UserService.limits(true)
                            .then(function (res) {
                                deferred.resolve(res.data);
                            }).catch(function (err) {
                                deferred.resolve(null);
                            });
                        return deferred.promise;
                    }],
                },
                params: {
                    domain: {
                        squash: true,
                        value: null
                    },
                    filters: {
                        squash: true,
                        value: null
                    },
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define(['angular'], function(ng) {
    function Controller($scope, $mdDialog, fieldToEdit, currentFields, on_complete, $timeout) {
        var vm = $scope;
        vm.loading={
            post: false
        };

        vm.fieldToEdit = Object.assign({}, fieldToEdit);
        vm.oldFieldName = vm.fieldToEdit.name;
        vm.currentFields =  Object.assign({}, currentFields);
        for (let key in vm.currentFields) {
            if (vm.fieldToEdit.name.toLowerCase().trim() == vm.currentFields[key]['name'].toLowerCase().trim()) {
                delete vm.currentFields[key];
                break;
            }
        }

        vm.getFieldType = getFieldType;
        vm.wasPressedSaveButton = false;

        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;
        vm.allFieldType = allFieldType;

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            vm.wasPressedSaveButton = false;
            $mdDialog.cancel();
        }
        function answer(form) {
            vm.loading.post = true;
            vm.wasPressedSaveButton = true;

            if (form.$valid) {
                vm.loading.post = false;
                vm.action = 'edit';

                var fieldOptions = [];
                var fieldName = vm.fieldToEdit.name;

                if (vm.fieldToEdit.options) {
                    fieldOptions = vm.fieldToEdit.options.filter(
                        function(option) {
                            return option !== '';
                        }
                    );

                    fieldOptions = fieldOptions.filter(
                        function(option,pos) {
                            return fieldOptions.indexOf(option) == pos
                        }
                    );
                }

                for (let key in vm.currentFields) {
                    if (fieldName == vm.currentFields[key]['name']) {
                        vm.showErrorMessage = true;
                        vm.errorMessage = 'leadgeneration.you.already.have.a.field.with.this.name';
                        $timeout(function(){
                            vm.showErrorMessage = false;

                        }, 3000);
                        return;
                    }
                }

                if (getInputType(vm.fieldToEdit.type) == 'select' && fieldOptions.length <= 0) {
                    vm.showErrorMessage = true;
                    vm.errorMessage = 'leadgeneration.you.need.at.least.one.option.for.this.field.type';
                    $timeout(function(){
                        vm.showerrorMessage = false;
                        vm.loading.post=false;
                        vm.wasPressedSaveButton = false;
                    }, 3000)
                    return;
                }

                hide();
                vm.newCustomField  = {
                    id: fieldName +'_id',
                    name: fieldName.toLowerCase(),
                    title: vm.fieldToEdit.type == 'Hidden' ? null : vm.fieldToEdit.title,
                    type: getType(vm.fieldToEdit.type),
                    inputType: getInputType(vm.fieldToEdit.type),
                    placeholder: vm.fieldToEdit.type == 'Hidden' ? null : vm.fieldToEdit.placeholder,
                    deletable: vm.fieldToEdit.deletable,
                    required: vm.fieldToEdit.type !== 'Hidden' ? vm.fieldToEdit.required : false,
                    show: vm.fieldToEdit.show,
                    value: vm.fieldToEdit.type == 'Hidden' ? vm.fieldToEdit.value : null,
                    options: fieldOptions
                };
            }
        }

        function getInputType(fieldType) {
            switch(fieldType) {
                case 'Short Text':
                case 'Link':
                case 'Email':
                case 'Hidden':
                    return 'input';
                case 'Long Text':
                    return 'textarea';
                case 'Select':
                case 'Multiselect':
                    return 'select';
            }
        }

        function getType(fieldType) {
            switch(fieldType) {
                case 'Short Text':
                    return 'text';
                case 'Link':
                    return 'url';
                case 'Email':
                    return 'email';
                case 'Hidden':
                    return 'hidden';
                case 'Long Text':
                    return 'textarea';
                case 'Select':
                    return 'normal';
                case 'Multiselect':
                    return 'multiple';
            }
        }

        function getFieldType(type) {
            switch(type) {
                case 'text':
                    return 'Short Text';
                case 'url':
                    return 'Link';
                case 'email':
                    return 'Email';
                case 'hidden':
                    return 'Hidden';
                case 'textarea':
                    return 'Long Text';
                case 'normal':
                    return 'Select';
                case 'multiple':
                    return 'Multiselect';
            }
        }

        function allFieldType() {
            return [
                "Short Text",
                "Long Text",
                "Link",
                "Email",
                "Select",
                "Hidden",
                //"Multiselect",
            ];
        }

        vm.getFieldIconByFieldType = function (fieldType) {
            switch (fieldType) {
                case 'Link': {
                    return 'link';
                }
                case 'Short Text': {
                    return 'short_text';
                }
                case 'Long Text': {
                    return 'notes';
                }
                case 'Email': {
                    return 'email';
                }
                case 'Select': {
                    return 'arrow_drop_down_circle';
                }
                /*
                case 'multiple': {
                    return 'check_box';
                }
                */
                case 'Hidden': {
                    return 'visibility_off'
                }
            }
        }

        vm.addOption = function(optionToAdd) {
            vm.insertNewOption(optionToAdd)
            if (vm.fieldToEdit && vm.fieldToEdit.options && vm.fieldToEdit.options.indexOf('')>=0) {
                return;
            }
        }

        vm.insertNewOption = function(option) {
            if (option == "") {
                return;
            }

            if (vm.fieldToEdit && vm.fieldToEdit.options && vm.fieldToEdit.options.indexOf(option)>=0) {
                vm.showErrorMessage = true;
                vm.errorMessage = 'leadgeneration.you.already.have.this.option';
                $timeout(function(){
                    vm.showErrorMessage = false;
                    vm.optionToAdd = "";
                }, 3000);

                return;
            }

            vm.fieldToEdit.options.push(option);
            vm.optionToAdd = "";
        }

        vm.onDrop = function(srcList, srcIndex, targetList, targetIndex) {
            srcList.splice(srcIndex, 1)

            return true;
          };

        vm.deleteOption = function(optionIndexToDelete) {
            vm.fieldToEdit.options.splice(optionIndexToDelete, 1);
        }

        vm.deleteField = function() {
            vm.wasPressedSaveButton = true;
            vm.action = 'delete';
            hide();
        }

        vm.editOption = function(option, index) {
            if (option=="") {
                vm.deleteOption(index);
                return;
            }

            if (vm.fieldToEdit.options.indexOf(option)>=0 && vm.fieldToEdit.options.indexOf(option)!==index) {
                vm.showErrorMessage = true;
                vm.errorMessage = 'leadgeneration.you.already.have.this.option';
                $timeout(function(){
                    vm.showErrorMessage = false;
                    vm.optionToAdd = "";
                }, 3000);

                return;
            }
            vm.fieldToEdit.options[index] = option;
        }


        $scope.$on('$destroy', function() {
            if (!vm.wasPressedSaveButton) {
                on_complete(null);
            } else {
                var data = {
                    fieldToEdit: vm.newCustomField,
                    action: vm.action,
                }
                on_complete(data);
            }

        });

        function clearName(name) {
            return name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '-').toLowerCase();
        }

        vm.onChangeName = function () {
            vm.nameFieldWasChanged = true;
        };

        vm.onChangeLabel = function () {
            if (!vm.nameFieldWasChanged) {
                vm.fieldToEdit.name = vm.fieldToEdit.title.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '-').toLowerCase().substring(0, 25);
            }
        };

        vm.onDrop = function(srcList, srcIndex, targetList, targetIndex) {
            // Copy the item from source to target.
            targetList.splice(targetIndex, 0, srcList[srcIndex]);
            // Remove the item from the source, possibly correcting the index first.
            // We must do this immediately, otherwise ng-repeat complains about duplicates.
            if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
            srcList.splice(srcIndex, 1);
            // By returning true from dnd-drop we signalize we already inserted the item.
            return true;
        };

    }
    Controller.$inject=['$scope', '$mdDialog', 'fieldToEdit', 'currentFields', 'on_complete', '$timeout'];
    return Controller;
});

'use strict';
define([], function () {
    function Controller($scope, $mdDialog, keywordUuids, keywordListId, KeywordListService, $state,$translate, TrackEventService) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.keywordUuids = keywordUuids;
        $scope.modalTitle = $translate.instant('keyword.list.component.delete.all.keywords', { count: $scope.keywordUuids.length });

        function answerDialog() {
            $scope.loading.post = true;
            KeywordListService.deleteKeywords(keywordListId, keywordUuids).then(function (res) {
                TrackEventService.event(
                    'Suite Usages',
                    'Keyword List Manager - Keyword Deleted');
                TrackEventService.gaV2Event(
                    'keyword_remove',{
                        tool: "Keyword List Manager"
                    });
                $scope.loading.post = false;
                $mdDialog.hide();
                $state.reload();
            }).catch(function (err) {
                $scope.loading.post = false;
            });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }

    Controller.$inject = ['$scope', '$mdDialog', 'keywordUuids', 'keywordListId', 'KeywordListService', '$state','$translate', 'TrackEventService'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/report/report.routes',
    'modules/seospider/exam/analysis/report/report.controller',
    'modules/seospider/exam/analysis/report/overview/overview.module'
],function(angular,Routes,SSReportCtrl){
    var app=angular.module('app.seospider.exam.analysis.report', [
        'app.seospider.exam.analysis.report.overview'
    ]);
    app.controller('SSReportCtrl',SSReportCtrl);
    app.config(Routes);
    return app;
});
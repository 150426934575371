'use strict';
define([], function () {

    

    return {
        templateUrl: 'app/modules/seochecker/components/headings-preview/headings-preview.html',
        bindings: {
            loading: '<',
            base: '<',
            overview: '<'
        },
        controller: ['$scope', function ($scope) {
            var vm = $scope
            vm.showAllHeadings = false;
            
            vm.headings = [];
           
            this.$onChanges = function (data) { 
                vm.loading = this.loading;
               
                if (!data.loading.currentValue) {
                   
                    if (data.overview.currentValue && data.overview.currentValue.h1 && data.overview.currentValue.h1.length > 0) {
                        var h1Exams =  data.overview.currentValue.h1;
                        for (var i=0; i<h1Exams.length; i++) {
                          
                            vm.headings.push({
                                type: 'h1',
                                value:  h1Exams[i]
                            });
                        }
                    }
                    
                    if (data.overview.currentValue && data.overview.currentValue.h2 && data.overview.currentValue.h2.length > 0) {
                        var h2Exams =  data.overview.currentValue.h2;
                        for (var i=0; i<h2Exams.length; i++) {
                            vm.headings.push({
                                type: 'h2',
                                value: h2Exams[i]
                            });
                        }
                    }

                    if (data.overview.currentValue && data.overview.currentValue.h3 && data.overview.currentValue.h3.length > 0) {
                        var h3Exams = data.overview.currentValue.h3;
                    
                        for (var i=0; i<h3Exams.length; i++) {
                            vm.headings.push({
                                type: 'h3',
                                value: h3Exams[i]
                            });
                        }
                    }
                    
                    vm.headingsToShow = vm.headings.slice(0, 6);
                }
            };

            vm.toggleAllHeadings = function () {
                vm.showAllHeadings = !vm.showAllHeadings;
                if (vm.showAllHeadings) {
                    vm.headingsToShow = vm.headings;
                } else {
                    vm.headingsToShow = vm.headings.slice(0, 6);
                }
            };
        }]
    };
});

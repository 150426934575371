'use strict';
define([], function () {
    function paymentsMethodsController($timeout, $scope, UserService, CheckoutService, AuthService, LanguageService, $window) {
        var vm = $scope;
        vm.methods = [];
        vm.user = AuthService.getSessionUser();
        vm.customerPortalUrl = null;
        vm.redirect = false;
        vm.loading = true;

        function getOldPaymentMethods() {
            CheckoutService.getCustomerPortal()
                .then(function (result) {
                    if (result && result.data && result.data.sessionLink) {
                        vm.customerPortalUrl = result.data.sessionLink;
                    }

                    return UserService.getPaymentMethods();
                })
                .then(function (result) {
                    if (result && result.methods) {
                        vm.methods = result.methods;
                    }

                    if (vm.customerPortalUrl && !vm.methods || !vm.methods.paypal || vm.methods.paypal.length <= 0) {
                        vm.redirect = true;

                        $timeout(function () {
                            $window.location.href = vm.customerPortalUrl;
                        }, 2000);
                    }

                    vm.loading = false;
                })
                .catch(function (err) {
                    $window.console.error(err);
                    vm.loading = false;
                });
        }

        vm.goToContactUs = function () {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();
            if (language === 'it-IT') {
                $window.open("https://it.seotesteronline.com/contatti", '_blank');
            } else if (language === 'es-ES') {
                $window.open(" https://es.seotesteronline.com/contacts", '_blank');
            } else {
                $window.open("https://www.seotesteronline.com/contacts", '_blank');
            }
        };

        function init() {
            getOldPaymentMethods();
        }

        init();
    }

    paymentsMethodsController.$inject = ['$timeout', '$scope', 'UserService', 'CheckoutService', 'AuthService', 'LanguageService', '$window'];
    return paymentsMethodsController;
});

'use strict';
define([
    'angular',
    'modules/project/project.routes',
    'modules/project/settings/tracker/tracker.component',
    'modules/project/components/project-card/project-card.component',
    'modules/project/components/step-navbar/step-navbar.component',
    'modules/project/components/chart-keywords/chart-keywords.component',
    'modules/project/wizard/steps/create-project/create-project.component',
    'modules/project/wizard/steps/add-keywords/add-keywords.component',
    'modules/project/wizard/steps/set-locations/set-locations.component',
    'modules/project/wizard/steps/choose-competitors/choose-competitors.component',
    'modules/project/wizard/steps/schedule-audits/schedule-audits.component',
    'modules/project/settings/settings.module',
    'modules/project/dialogs/dialogs.module',
    'modules/project/wizard/wizard.module',

], function (
    angular,
    routes,
    Tracker,
    projectCard,
    stepNavbar,
    chartKeywords,
    createProject,
    addKeywords,
    setLocations,
    chooseCompetitors,
    scheduleAudits
    ) {
    var app = angular.module('app.project', [
        'app.project.settings',
        'app.project.dialogs',
        'app.project.wizard',
    ]);

    app.config(routes);
    app.component('tracker', Tracker);
    app.component('stProjectCard',projectCard);
    app.component('stepNavbar', stepNavbar);
    app.component('chartKeywords', chartKeywords);
    app.component('createProject', createProject);
    app.component('addKeywords', addKeywords);
    app.component('setLocations', setLocations);
    app.component('chooseCompetitors', chooseCompetitors);
    app.component('scheduleAudits', scheduleAudits);

    return app;
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/exam-performance/exam-performance.html',
        bindings: {exam: '<'},
        controller: ['__env', '$scope', 'stAlertLimitService', 'AuthService', '$mdMedia',
            function (__env, $scope, stAlertLimit, AuthService, $mdMedia)  {
                var ctrl = this;
                var vm = $scope;
                vm.$mdMedia = $mdMedia;

                vm.isLogged = false;
                vm.canDoTrial = true;
                vm.trialPeriodDays = __env.trialPeriodDays;

                ctrl.$onInit = function () {
                    vm.isLogged = AuthService.isAuthenticated();
                };

                $scope.$watch('$ctrl.exam', function () {
                    var examId = ctrl.exam.id.split('_')[1];
                    ctrl.exam.expand = {
                        problem: true,
                        suggest: false
                    };
                    ctrl.exam.title = ctrl.exam.title || 'header-exam.' + examId;
                    ctrl.exam.colorClass = getTextColor(ctrl.exam.score);
                    ctrl.exam.iconClass = getHeaderIconByScore(ctrl.exam.score);
                    ctrl.exam.timeIconClass = getTimeIconByScore(ctrl.exam.score);
                    if (examId == 'serp') {
                        setSerpTextLength(ctrl.exam);
                    }
                });

                ctrl.openTrial = function() {
                    stAlertLimit.open('auth-login', {
                        error:
                            {
                                request: {
                                    alias: 'seo-checker-unlock-suggestions',
                                    offline: false,
                                }
                            }
                    });
                }

                function getTextColor(score) {
                    if (score == null || score < 0)
                        return 'blue-text';
                    if (score >= 75)
                        return 'st-green-2';
                    else if (score >= 50)
                        return 'st-orange-2';
                    else return 'st-red-2';
                }

                function getTimeIconByScore(score) {
                    if (score == null || score < 0)
                        return '';
                    if (score >= 75)
                        return 'thumb_up';
                    else if (score >= 50)
                        return 'error';
                    else
                        return 'warning';
                }

                function getHeaderIconByScore(score) {
                    if (score == null || score < 0)
                        return '';
                    if (score >= 75)
                        return 'check_circle';
                    else if (score >= 50)
                        return 'warning';
                    else
                        return 'warning';
                }

                function setSerpTextLength(exam) {
                    if (exam.preview[0].args[0].value.title && exam.preview[0].args[0].value.title.length > 70)
                        exam.preview[0].args[0].value.title = exam.preview[0].args[0].value.title.substr(0, 65) + " ...";
                    if (exam.preview[0].args[0].value.description && exam.preview[0].args[0].value.description.length > 160)
                        exam.preview[0].args[0].value.description = exam.preview[0].args[0].value.description.substr(0, 155) + " ...";
                    if (exam.preview[0].args[0].value.url && exam.preview[0].args[0].value.url.length > 70)
                        exam.preview[0].args[0].value.url = exam.preview[0].args[0].value.url.substr(0, 70) + " ...";
                }
            }]
    };
});

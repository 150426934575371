define([
    'angular',
    'modules/seospider/exam/analysis/content/content.routes',
    'modules/seospider/exam/analysis/content/content.controller',
    'modules/seospider/exam/analysis/content/all/all.controller',
    'modules/seospider/exam/analysis/content/high-ratio/high-ratio.controller',
    'modules/seospider/exam/analysis/content/low-ratio/low-ratio.controller',
    'modules/seospider/exam/analysis/content/text-level-high/text-level-high.controller',
    'modules/seospider/exam/analysis/content/text-level-med/text-level-med.controller',
    'modules/seospider/exam/analysis/content/text-level-low/text-level-low.controller',
    'modules/seospider/exam/analysis/content/content-table.component'
],function(angular, Routes, ContentCtrl, AllContentCtrl,HighRatioCtrl,LowRatioCtrl,TxtLevHighCtrl,TxtLevMedCtrl,TxtLevLowCtrl, ContentTable){
    var app=angular.module('app.seospider.exam.analysis.content',[]);
    app.config(Routes);
    app.controller("ContentCtrl",ContentCtrl);
    app.controller("HighRatioCtrl",HighRatioCtrl);
    app.controller("LowRatioCtrl",LowRatioCtrl);
    app.controller("TxtLevHighCtrl",TxtLevHighCtrl);
    app.controller("TxtLevMedCtrl",TxtLevMedCtrl);
    app.controller("TxtLevLowCtrl",TxtLevLowCtrl);
    app.controller("AllContentCtrl",AllContentCtrl);
    app.component("contentTable",ContentTable);
    return app;
});
'use strict';
define(['angular'], function () {
    function Controller($scope, $q, $filter, $timeout, form, $mdDialog, $state, $stateParams, LeadGenerationService, $mdToast, UserService) {
        var vm = $scope;
        vm.form = form;
        var trend;
        vm.searchQuery = null;
        vm.getColumnVisibility = getColumnVisibility;
        vm.selectColumnTableIsOpen = false;
        if (vm.form && vm.form.trend) {
            trend = vm.form.trend;
        }
        form.config.fields.forEach(function(field){

            field.label = field.name;
        });

        vm.selectedTableColumns = [];
        vm.tableFields = [];

        applyDefaultTableFieldsOrder();

        /*$timeout(function(){
            applyDefaultTableFieldsOrder();
            checkTableColumn()
        }, 200)
        */


        vm.getFieldsNumber = function(fieldsNumber) {
            if (fieldsNumber<=0) {
                return;
            }
            return new Array(fieldsNumber);
        }

        vm.customTableController = {
            getColumnVisibility: getColumnVisibility,
            getFieldsNumber: vm.getFieldsNumber,
            fields: vm.tableFields, //form.config.fields
            formatDate: formatDate
        };

        function formatDate(strDate) {
            var date = strDate.split(" ")[0];
            var myDate = date.split("-");
            var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

            return $filter('date')(newDate.getTime(), "mediumDate");
        }

        function getColumnVisibility(columnId) {
            var columnSelected = true;
            if (columnId=='leadEmail') {
                return 'show';
            }
            for (var i = 0; i < vm.selectedTableColumns.length; i++) {
                if (vm.selectedTableColumns[i].id == columnId) {
                    columnSelected = vm.selectedTableColumns[i].selected;
                    break;
                }
            }

            if (columnSelected) {
                return 'show';
            } else {
                return 'hide';
            }
        }

        vm.onClickEditColumnButton = function (ev) {
            if (!vm.selectColumnTableIsOpen) {
                $timeout(function () {
                    angular.element(document.querySelector('#multiselect-field')).click();
                    vm.selectColumnTableIsOpen = true;
                }, 100);
            }
        }

        if (trend) {
            vm.lineGraphParams = setGraphParams(trend);
        } else {
            vm.lineGraphParams = setGraphParams([]);
        }

        vm.loading = {
            leads: true,
        };

        vm.limits = 0;
       

        vm.countLeadsLocked = 0;

        vm.selectedLeads = [];
        var leadsId = [];

        vm.unlockLeads = unlockLeads;
        vm.exportCSVFile = exportCSVFile;

        vm.$watch('selectedColumn', function (newValue, oldValue) {

            if (newValue && oldValue && newValue !== oldValue) {
                var difference = newValue.filter(function(x) {
                    return !oldValue.includes(x)
                }).concat(oldValue.filter(function(x) {
                    return !newValue.includes(x)
                } ));


                if (newValue.length < oldValue.length) {
                    vm.tableFields.forEach(function(field){
                        if (field.id === difference[0] && difference[0] !== 'leadEmail' && difference[0] !== 'leadWebsite') {
                            var oldString = field.customClass;
                            var newString = oldString.replace('show', 'hide')
                            field.customClass = newString;
                        }
                        return;
                    });

                } else if (newValue.length > oldValue.length) {
                    vm.tableFields.forEach(function(field){
                        if (field.id === difference[0]) {
                            var oldString = field.customClass;
                            var newString = oldString.replace('hide', 'show')
                            field.customClass = newString;
                        }
                        return;
                    });
                }

                checkTableColumn();
            }

        });

        function checkTableColumn() {
            if (vm.selectedColumn && vm.selectedColumn.length >= 0) {
                for (var i = 0; i < vm.selectedTableColumns.length; i++) {
                    vm.selectedTableColumns[i].selected = vm.selectedColumn.indexOf(vm.selectedTableColumns[i].id) >= 0;
                }
            }
        }

        function unlockLeads(ev) {
            if (vm.rowsSelected == 0) {
                showCustomToast();
                return;
            }
            vm.rowsSelected.forEach(function (item) {
                if (item.locked == 1) {
                    leadsId.push(item.id);
                }
            });
            $mdDialog.show({
                templateUrl: "app/modules/leadgeneration/components/dialogs/unlock-leads/unlock-leads.html",
                controller: "leadGenerationDialogUnlockLeadCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    leadsId: leadsId
                }
            }).then(function (answer) {
                $state.reload();
            }, function () {

            });
        }

        function applyDefaultTableFieldsOrder() {
            if (!vm.form || !form.config || !form.config.fields[0] || !form.config.fields[1]) {
                return;
            }

            /*
            vm.selectedTableColumns.push({
                id: 'leadEmail',
                label: 'leadgeneration.email',
                name: 'email',
                text: 'leadgeneration.email',
                selected: true,
            });

            vm.selectedTableColumns.push({
                id: 'leadWebsite',
                label: 'Website',
                name: 'website',
                text: 'Website',
                selected: true,
            });
            */

            vm.tableFields.push({
                label: 'leadgeneration.email',
                name: 'email',
                id: 'leadEmail',
                emptyState: 'square',
                customClass: ' '+ vm.getColumnVisibility('leadEmail'),
            });

            vm.tableFields.push({
                label: 'Website',
                name: 'website',
                id: 'leadWebsite',
                emptyState: 'square',
                customClass: ' '+ vm.getColumnVisibility('leadWebsite'),
            });



            for (i=0; i<form.config.fields.length; i++) {
                if (form.config.fields[i].id != 'email_id' && form.config.fields[i].id != 'leadEmail' && form.config.fields[i].id != 'website_id' && form.config.fields[i].id != 'leadWebsite') {
                    vm.selectedTableColumns.push({
                        id: form.config.fields[i].id,
                        label: form.config.fields[i].name,
                        text: form.config.fields[i].name,
                        name: form.config.fields[i].name,
                        selected: true,
                    });


                    vm.tableFields.push({
                        emptyState: 'three-lines',
                        label: form.config.fields[i].name,
                        name: form.config.fields[i].name,
                        id: form.config.fields[i].id,
                        customClass: 'm-w text-left '+ vm.getColumnVisibility(form.config.fields[i].id),
                    });

                }


            }

            vm.selectedTableColumns.push({
                id: 'referralUrl',
                label: 'leadgeneration.origin',
                name: 'referralUrl',
                text: 'leadgeneration.origin',
                selected: true,
            })

            vm.selectedTableColumns.push({
                id: 'acquiredAt',
                label: 'leadgeneration.date',
                name: 'acquiredAt',
                text: 'leadgeneration.date',
                selected: true,
            });

            vm.tableFields.push({
                label: 'leadgeneration.origin',
                name: 'referralUrl',
                id: 'referralUrl',
                emptyState: 'square',
                customClass: ' '+ vm.getColumnVisibility('referralUrl'),
            });
            vm.tableFields.push({
                label: 'leadgeneration.date',
                name: 'acquiredAt',
                id: 'acquiredAt',
                emptyState: 'square',
                customClass: ' '+ vm.getColumnVisibility('acquiredAt'),
            })



        }

        function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, leadsList;

            leadsList = args.leadsList || null;
            if (leadsList == null || !leadsList.length) {
                return null;
            }

            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(leadsList[0]);

            result = '';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            leadsList.forEach(function (item) {
                ctr = 0;
                keys.forEach(function (key) {
                    if (ctr > 0) result += columnDelimiter;

                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });

            return result;
        }

        vm.checkboxLeadsSelected = function (rowsSelected) {
            vm.rowsSelected = rowsSelected;
        }

        vm.searchInTable = function () {
            vm.leads.data = $filter("filter")(vm.resultRowsCopy, vm.searchQuery);
        }


        function exportCSVFile(args) {
            if (vm.rowsSelected == 0) {
                vm.rowsSelected =vm.leads.data.filter(function(lead) {
                    return !lead.locked;
                });
            }

            var filename, link;
            var leadsListToConvert = [];
            vm.rowsSelected.forEach(function (item) {
                leadsListToConvert.push(Object.assign({}, item));
            });
            leadsListToConvert.forEach(function (item) {
                delete item.id;
                delete item.formId;
                delete item.deleted;
                delete item.contactedAt;
                delete item.company;
                delete item.name;
                delete item.email;
                delete item.website;
                delete item.note;
                delete item.locked;
                delete item.surname;
                delete item.$$hashKey;
                delete item.daysLeft;
                delete item.keywords;
                delete item.telephone;
                delete item.fullname;
                delete item.$$hashKey;

                var itemFields = JSON.parse(item.fields);

                itemFields.forEach(function(field){
                    item[field.name] = field.value;
                });
                delete item.fields;

            });
            var csv = convertArrayOfObjectsToCSV({
                leadsList: leadsListToConvert
            });
            if (csv == null) return;
            filename = args.filename || 'leads.csv';

            var blob = new Blob(["\ufeff", csv]);
            var url = URL.createObjectURL(blob);

            link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.click();
        }

        vm.leads = {
            data: [],
        };
        function generateLeads() {
            vm.loading.leads = true;
            vm.selectedLeads = [];
            leadsId = [];
            vm.leads.data.forEach(function (lead) {
                var date1 = new Date(lead.acquiredAt);
                var date2 = new Date();
                var diffTime = Math.abs(date2 - date1);
                var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                lead.daysLeft = 60 - diffDays;
                if (lead.locked == 1) {
                    vm.countLeadsLocked = vm.countLeadsLocked + 1;
                }
            });
            vm.loading.leads = false;
        }

        function setGraphParams(trend) {
            if (!trend) trend = [];
            var graphParams = {
                data: [],
                labels: [],
            };
            for (var i = 0; i < trend.length; i++) {
                graphParams.data.push(trend[i].total);
                graphParams.labels.push(trend[i].acquiredAt.replace(/^[0-9]{4}\-/gi, '').replace('-', '/'));
            }
            graphParams.data.reverse();
            graphParams.labels.reverse();
            graphParams.data = [graphParams.data];
            return graphParams;
        }

        function getLeads() {
            var dfd = $q.defer();
            vm.loading.leads = true;
            LeadGenerationService
                .findLeads({
                    formId: $stateParams.formId,                    
                })
                .then(function (res) {
                    vm.loading.leads = false;
                    vm.leads = res;

                    if (vm.leads && vm.leads.data) {
                        vm.leads.data.forEach(function (lead) {
                            var date1 = new Date(lead.acquiredAt);
                            var date2 = new Date();
                            var diffTime = Math.abs(date2 - date1);
                            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            lead.daysLeft = 60 - diffDays;
                            if (lead.locked == 1) {
                                vm.countLeadsLocked = vm.countLeadsLocked + 1;
                            }
                        });
                    }

                    vm.leads.data.forEach(function(lead){
                        if (lead.fields && !lead.locked) {
                            var fields = JSON.parse(JSON.stringify(lead.fields));
                            if (fields) {
                                fields = JSON.parse(fields);

                                fields.forEach(function(field){
                                    lead[field.name] = field.value;
                                });
                            }
                        }
                    });

                    vm.resultRowsCopy = angular.copy(vm.leads.data);

                }, function (err) {
                    vm.loading.leads = false;
                    vm.pages = err.data;
                    dfd.resolve();
                });
            return dfd.promise;
        }


        //selection leads
        vm.isIndeterminate = function () {
            return (vm.selectedLeads.length !== 0 &&
                vm.selectedLeads.length !== vm.leads.data.length);
        };

        vm.isChecked = function () {
            return vm.selectedLeads.length === vm.leads.data.length;
        };

        vm.toggleAll = function () {
            if (vm.selectedLeads.length === vm.leads.data.length) {
                vm.selectedLeads = [];
            } else if (vm.selectedLeads.length === 0 || vm.selectedLeads.length > 0) {
                vm.selectedLeads = vm.leads.data.slice(0);
            }
        };

        vm.exists = function (item, list) {
            return list.findIndex(function (el) {
                return el.id == item.id;
            }) > -1;
        };

        vm.toggle = function (item, list) {
            var idx = list.findIndex(function (el) {
                return el.id == item.id;
            });
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item);
            }
        };

        function showCustomToast() {
            $mdToast.show({
                template:
                    '<md-toast class="md-toast">' +
                    '<span layout="row" layout-align="start center"><i class="icon-x st-err m-r-sm"></i>{{"leadgeneration.select.lead" | translate}}</span>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'bottom left'
            });
        }

        function getCreditsToUnlockLeads() {
            UserService.limits(true)
                .then(function (res) {
                    if (!res || !res.data || !res.data.limits || res.data.limits <= 0) {
                        return;
                    }

                    angular.forEach(res.data.limits, function (service) {
                        if (!service || service.alias !== 'lead-gen-tool-leads-unlock') {
                            return;
                        }
                        vm.limits = service.qtyRemaining;
                        vm.totalToUnlock = service.qtyRemaining;
                    });
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function _constructor() {
            getLeads();
            getCreditsToUnlockLeads();
            generateLeads({formId: $stateParams.formId});
        }

        _constructor();


        vm.getFieldValueForLead = function(lead, fieldIndex) {
            if (!vm.form || !vm.form.config || !vm.form.config.fields) {
                return;
            }
            if (lead.locked == 1) {
                return '***********';
            }
            var leadFields = JSON.parse(lead.fields);

            var fieldName = vm.form.config.fields[fieldIndex].name;

            for (var i=0; i<leadFields.length; i++) {
                if (leadFields[i].name == fieldName) {
                    return leadFields[i].value;
                }
            }

            return '-';
        }


    }

    Controller.$inject = ['$scope', '$q', '$filter', '$timeout', 'form', '$mdDialog', '$state', '$stateParams', 'LeadGenerationService', '$mdToast', 'UserService'];
    return Controller;
});

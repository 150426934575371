'use strict';
define([], function () {
    function Controller(
        $scope,
        $mdDialog,
        benchmark,
        FavIconService) {

        var vm = $scope;
        vm.benchmark = clone(benchmark);
        vm.targetUrl = null;
        vm.hide = hideDialog;
        vm.cancel = cancelDialog;
        vm.answer = answerDialog;
        vm.addToBenchmark = addToBenchmark;
        vm.removeToBenchmark = removeToBenchmark;

        vm.getFavIcon = FavIconService.get;
        function answerDialog() {
            if (vm.targetUrl) {
                addToBenchmark(vm.targetUrl);
            }
            
            $mdDialog.hide(vm.benchmark);
        }

        function hideDialog() {
            $mdDialog.cancel();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }


        function removeToBenchmark(index) {
            vm.benchmark.splice(index, 1);
        }

        function addToBenchmark(targetUrl) {
            targetUrl = cleanUrl(targetUrl);
            if (isValidUrl(targetUrl)&&vm.benchmark.length<3){
                if (vm.benchmark.indexOf(targetUrl)===-1){
                    vm.benchmark.push(cleanUrl(targetUrl));
                }
                vm.targetUrl = null;
            }
        }
        function clone(objOrList){
            return JSON.parse(JSON.stringify(objOrList));
        }
        function cleanUrl(url){
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i,'');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }
        function isValidUrl(value) {
            if(!value){
                return false;
            }
            if(value.indexOf('http')===-1){
                value='http://'+value;
            }
            return /^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/.test(value);
        }
    }

    Controller.$inject = [
        '$scope',
        '$mdDialog',
        'benchmark',
        'FavIconService'
    ];
    return Controller;
});

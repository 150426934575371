'use strict';
define([], function () {
    function Controller($scope, $mdDialog, projectId, queriesSelected, KeywordTrackerService, $state) {
        $scope.queriesSelected = queriesSelected;
        $scope.cancelDialog = cancelDialog;
        $scope.answerDialog = answerDialog;

        var queries = queriesSelected.map(function (query) {
            return {
                id: query.id,
                deviceId: query.deviceId,
                searchEngineId: query.searchEngineId,
                countryCode: query.countryCode
            };
        });

        function answerDialog() {
            KeywordTrackerService.deleteKeywords(projectId, queries)
                .then(function (res) {
                    hideDialog();
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }

    Controller.$inject = ['$scope', '$mdDialog', 'projectId', 'queriesSelected', 'KeywordTrackerService', '$state'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/page/all/all.controller',
], function (
    angular,
    MDAllCtrl
) {
    var app = angular.module('app.seospider.exam.analysis.page.all', []);
    app.controller("PageAllCtrl", MDAllCtrl);
    return app;
});

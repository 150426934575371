'use strict';
define([],function(){
    controller.$inject = ['$scope'];
    function controller($scope) {
        var ctrl=this;
        $scope.$watch('$ctrl.consume',function (consume) {
            ctrl.consume=consume;
        });
        $scope.$watch('$ctrl.maxLimit',function (maxLimit) {
            ctrl.maxLimit=maxLimit;
        });
        $scope.$watch('$ctrl.text',function (text) {
            ctrl.text=text;
        });
        $scope.$watch('$ctrl.value',function (value) {
            ctrl.value=value;
        });
        $scope.$watch('$ctrl.color',function (color) {
            ctrl.color=color;
        });
        $scope.$watch('$ctrl.tooltip',function (tooltip) {
            ctrl.tooltip=tooltip;
        });
    }
    return {
        templateUrl: 'app/components/st-progress/st-progress.html',
        bindings: {
            limit:'<',
            text:'<',
            remaining:'<',
            color:'<',
            value:'<',
            tooltip:'<'
        },
        controller: controller
    };
});
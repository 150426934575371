/**
 * @ngdoc overview
 * @name partnerinfo.service:PartnerInfoService
 */
'use strict';
define(['app'], function (app) {
    app.service('PartnerInfoService', PartnerInfoService);
    PartnerInfoService.$inject = ['API', '__env'];

    function PartnerInfoService(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.partnerInfoAPI;

        this.getPartnerInfo = function () {
            return API.get(apiPath)
                .then(function(res) {
                    return res.data;
                })
                .catch(function(err) { 
                    console.log(err); 
                    return err;
                });
        };

        this.updatePartnerInfo = function (partnerData) {
            return API.put(apiPath, partnerData, true);
        };

    }
});

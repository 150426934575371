/**
 * @ngdoc overview
 * @name suggestions.service:SuggestionsService
 */
'use strict';
define(['app'], function (app) {
    app.service('SuggestionsService', SuggestionsService);
    SuggestionsService.$inject = ['$http', '$sce', '__env'];

    function SuggestionsService($http, $sce, __env) {
        return {
            imageSuggestions: imageSuggestions,
        };

        function imageSuggestions(q) {
            return $http.jsonp($sce.trustAsResourceUrl('https://pixabay.com/api/?key=5707332-2001852eb34dc3e6298ded1f6&q=' + q), {
                "callback": "callback"
            })
                .then(function (res) {
                    return res.data.hits;
                })
                .catch(function (err) {
                    console.log('Errore on suggestion', err);
                });
        }
    }
});

'use strict';
define([], function () {
    function controller($scope, $stateParams, TrackEventService, $rootScope,FavIconService) {
        var ctrl = this;
        var nameFieldWasChanged = false;

        $scope.favIconService=FavIconService;
        $scope.protocols = [
            {
                id: 'both',
                label: 'project.wizard.http.https'
            },
            {
                id: 'https',
                label: 'project.wizard.https'
            },
            {
                id: 'http',
                label: 'project.wizard.http'
            }
        ];

        $scope.domainTypes = [{
                id: 'domain_with_subdomains',
                icon: 'subdomains.svg',
                label: 'project.wizard.analyze.all',
                detail: 'project.wizard.domain.including.subdomains',
                example: 'project.wizard.subdomains.example'
            },
            {
                id: 'domain_without_subdomains',
                icon: 'domain.svg',
                label: 'project.wizard.domain',
                detail: 'project.wizard.only.specified.domain',
                example: 'project.wizard.domain.example'
            },
            {
                id: 'prefix',
                icon: 'path.svg',
                label: 'project.wizard.path',
                detail: 'project.wizard.path.including.subfolders',
                example: 'project.wizard.path.example'
            },
            {
                id: 'exact_url',
                icon: 'exact-url.svg',
                label: 'project.wizard.exact.url',
                detail: 'project.wizard.only.specified.url',
                example: 'project.wizard.url.example'
            },
        ];

        $scope.onChangeUrl = function () {
            var result;
            var match;

            if ($scope.project.url) {
                var url = $scope.project.url;
                url = url.replace('https://', '').replace('http://', '');

                if (url.length > 7) {
                    $scope.project.url = url;
                }
            }

            if (!nameFieldWasChanged && $scope.wizardMode) {
                if (match = $scope.project.url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\d{0,3}[.])?([^:\/\n\?\=]+)/im)) {
                    result = match[1];
                    if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
                        result = match;
                    }
                }

                if (!result) {
                    $scope.project.name = null;
                    return;
                }

                if (result.indexOf('.') !== -1 && result.indexOf('.') !== result.length - 1) {
                    $scope.project.name = result.split('.')[0];
                }
            }
        };

        $scope.onChangeName = function () {
            nameFieldWasChanged = true;
        };

        this.$onInit = function () {
            $scope.wizardMode = this.wizardMode;
            $scope.project = this.project;

            // TRACKING
            var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';
            $rootScope.stoFrom = $stateParams.from ? $stateParams.from : $rootScope.stoFrom;
            var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
            if ($scope.wizardMode) {
                TrackEventService.event(
                    'Suite Usages',
                    'SEO Project - Step - Create Project', eventLabel);
                TrackEventService.gaV2Event(
                    'project_wizard', {
                        step: 'Create Project',
                        from: $rootScope.stoFrom,
                        type: stoTool
                    });
            }
            //
        };

    }

    controller.$inject = ['$scope', '$stateParams', 'TrackEventService', '$rootScope','FavIconService'];

    return {
        templateUrl: 'app/modules/project/wizard/steps/create-project/create-project.html',
        bindings: {
            project: '<',
            wizardMode: '<'
        },
        controller: controller,
    };
});

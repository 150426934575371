'use strict';
define(['app'], function (app) {
    app.component('stTableBadge', {
        templateUrl: 'app/components/st-table-badge/st-table-badge.html',
        controller: controller,
        transclude: true,
        bindings: {
            value: '<',
        }
    });
    controller.$inject = ['__env', '$scope'];

    function controller(__env, $scope) {
        var vm = $scope;

        vm.$watch('$ctrl.value', function (value) {
            vm.value = value;
        });
    }

});

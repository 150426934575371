'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/http-status/http-status.routes',
    'modules/seospider/exam/analysis/http-status/http-status.controller',
    'modules/seospider/exam/analysis/http-status/200/200.module',
    'modules/seospider/exam/analysis/http-status/404/404.module',
    'modules/seospider/exam/analysis/http-status/301/301.module',
    'modules/seospider/exam/analysis/http-status/components/components.module'
],function(angular,routes,SPHttpStatusCtrl){
    var app=angular.module('app.seospider.exam.analysis.httpstatus', [
        'app.seospider.exam.analysis.httpstatus.200',
        'app.seospider.exam.analysis.httpstatus.301',
        'app.seospider.exam.analysis.httpstatus.404',
        'app.seospider.exam.analysis.httpstatus.components'
    ]);
    app.config(routes);
    app.controller('SPHttpStatusCtrl',SPHttpStatusCtrl);
    return app;
});
define([], function(){
    function config($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('/access/*path', '/*path');

        $stateProvider
            .state("access",{
                controller: "AccessController",
                templateUrl: './app/modules/access/access.html',
                params: {
                    redirectTo: { squash: true },
                    workspaceId: { squash: true },
                    triggerSrc: { squash: true },
                }
            })
            .state("access.signup",{
                url: "/signup?:redirectTo&:workspaceId&:triggerSrc",
                templateUrl: './app/modules/access/signup/signup.html',
                access: {restricted: false},
                resolve: {
                    enteredSignup: ['TrackEventService', function(TrackEventService){
                        TrackEventService.event('Funnel Events', 'Entered Signup', 'Page');
                        TrackEventService.gaV2Event('screen_view', {
                            screen_name : 'signup-page',
                        });
                        return true;
                    }]
                }
            })
            .state("access.signin",{
                url: "/signin?:redirectTo&:workspaceId&:triggerSrc",
                templateUrl: './app/modules/access/signin/signin.html',
                access: {restricted: false},
            })
            .state("access.signin-zendesk",{
                url: "/signin-zendesk",
                templateUrl: './app/modules/access/signin-zendesk/signin-zendesk.html',
                //access: {restricted: false},
                controller: "SigninZendeskController",
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();

                                if (!user) {
                                    $state.go('access.signin');
                                }

                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }

            })
            .state("access.signin-feedbear",{
                url: "/signin-feedbear",
                templateUrl: './app/modules/access/signin-feedbear/signin-feedbear.html',
                //access: {restricted: false},
                controller: "SigninFeedbearController",
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();

                                if (!user) {
                                    $state.go('access.signin');
                                }

                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }

            })
            .state("access.email-verification",{
                url: "/email-verification/:token?:redirectTo&:workspaceId&:triggerSrc",
                templateUrl: './app/modules/access/email-verification/email-verification.html',
                access: {restricted: false},
                params: {
                    email: null,
                    token: { squash: true },
                },
                resolve: {
                    decodeEmail:['$stateParams', function($stateParams){
                        $stateParams.email = atob($stateParams.token)
                    }],
                }
            })
            .state("access.forgot",{
                url: "/forgot",
                templateUrl: './app/modules/access/forgot/forgot.html',
                controller: "ForgotController",
                access: {restricted: false}
            })
            .state("access.reset",{
                url: "/reset/:token",
                templateUrl: './app/modules/access/reset/reset.html',
                controller: 'ResetController',
                access: {restricted: false},
                resolve: {
                    resetTest:['$state', '$q', '$stateParams', 'AuthService', function($state, $q, $stateParams, Auth){
                        var deferred = $q.defer();
                        Auth.resetCheck($stateParams.token)
                            .then(function(valid) {
                                console.log('valid', valid)
                                return true;
                            })
                            .catch(function(invalidToken) {
                                console.log('invalidToken', invalidToken);
                                $state.go('access.signin');
                            });
                        return deferred;
                    }],
                }
            })
            .state("access.tailor-made",{
                url: "/tailor-made?:redirectTo&:workspaceId&:triggerSrc",
                templateUrl: './app/modules/access/tailor-made/tailor-made.html',
                access: {restricted: false},
            })
            .state("access.get-started-now",{
                url: "/get-started-now?:redirectTo&:workspaceId&:triggerSrc",
                templateUrl: './app/modules/access/get-started-now/get-started-now.html',
                params: {
                    suggestedPlans: { squash: true },
                    redirectTo: { squash: true },
                    userCanDoTrial: { squash: true },
                },
                access: {restricted: false},
                controller: "GetStartedNowController",
                resolve: {
                    userInfo: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits()
                            .then(function (res) {
                                /*
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                    return null;
                                }
                                */

                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                }
            })
            .state("access.admin",{
                url: "/UG9zc29ubyBkaXN0cnVnZ2VybWkgY2FzYSwgcG9zc29ubyB0b2dsaWVybWkgaSBnaW9jYXR0b2xpLCBtYSBjJ8OoIHVuYSBjb3NhIG5vbiBtaSBwb3Nzb25vIHRvZ2xpZXJlLCBpbyBzb25vIElyb24gTWFuIQ",
                templateUrl: './app/modules/access/signin/admin.html',
                access: {restricted: false},
            });

    }
    config.$inject=['$stateProvider', '$urlRouterProvider'];
    return config;
});

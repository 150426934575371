'use strict';
define(['app'], function (app) {
    app.service('TextStatistics', TextStatistics);
    TextStatistics.$inject = ['KeywordDensityService', 'KeywordsService', '$q'];

    function TextStatistics(KeywordDensity, KeywordsModel, $q) {
        return {
            textStat: textStat,
            readingTime: readingTime,
            syllables: syllables,
            flesch: flesch,
            gulpease: gulpease,
            gunningFog: gunningFog,
            metrics: metrics
        };

        function sortByPercentage(a, b) {
            if (a.percentage > b.percentage)
                return -1;
            if (a.percentage < b.percentage)
                return 1;
            return 0;
        }

        function metrics(text, country, extraTopics) {
            if (!extraTopics)
                extraTopics = [];
            return $q(function (resolve, reject) {
                var topics = {};
                if (!text.length) {
                    return resolve({
                        reading: readingTime(''),
                        density: [],
                        avgCpc: 0,
                        textDifficulty: 0,
                        buyIntent: 0,
                        volumeScore: 0,
                        topics: [],
                        topicsKeywords: [],
                        uniqueKeywords: [],
                        potentialVolume: 0,
                    });
                }
                KeywordDensity.setText(text);
                var readingStats = readingTime(text);
                var wordStats = KeywordDensity.oneWord();
                var densityOne = wordStats.density.sort(sortByPercentage);
                var densityTwo = KeywordDensity.twoWords().density.sort(sortByPercentage);
                var densityThree = KeywordDensity.threeWords().density.sort(sortByPercentage);
                var densityFour = KeywordDensity.fourWords().density.sort(sortByPercentage);
                var densityFive = KeywordDensity.fiveWords().density.sort(sortByPercentage);
                var densitySix = KeywordDensity.sixWords().density.sort(sortByPercentage);
                var densitySeven = KeywordDensity.sevenWords().density.sort(sortByPercentage);
                var densityEight = KeywordDensity.eightWords(null, 8).density.sort(sortByPercentage);
                var densityNine = KeywordDensity.analyze(null, 9).density.sort(sortByPercentage);
                var densityTen = KeywordDensity.analyze(null, 10).density.sort(sortByPercentage);
                var keywords = [];
                keywords = densityTwo.concat(densityOne);
                keywords = densityThree.concat(keywords);
                keywords = densityFour.concat(keywords);
                keywords = densityFive.concat(keywords);
                keywords = densitySix.concat(keywords);
                keywords = densitySeven.concat(keywords);
                keywords = densityEight.concat(keywords);
                keywords = densityNine.concat(keywords);
                keywords = densityTen.concat(keywords);
                keywords.sort(sortByPercentage);
                var topicsKeywords = [];
                var uniqueKeywords = [];
                var topicCount = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0};
                for (var i = 0; i < keywords.length; i++) {
                    if (readingStats.words < 2000 && topicsKeywords.length >= 3 || readingStats.words > 2000 && topicsKeywords.length >= 1)
                        break;
                    uniqueKeywords.push(keywords[i].word);
                    switch (keywords[i].numWords) {
                        case 1:
                            if (keywords[i].percentage >= 6) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;
                        case 2:
                            if (keywords[i].percentage > 6) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;
                        case 3:
                            if (keywords[i].percentage >= 5) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;
                        case 4:
                            if (keywords[i].percentage >= 4) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;
                        case 4:
                        case 5:
                        case 6:
                            if (keywords[i].percentage >= 4) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;
                            if (keywords[i].percentage >= 4) {
                                topicCount[keywords[i].numWords]++;
                                topics[keywords[i].word] = keywords[i];
                                topicsKeywords.push(keywords[i].word);
                            }
                            break;

                    }
                }
                if (readingStats.words < 2000 && topicsKeywords.length < 2 || readingStats.words > 2000 && topicsKeywords.length < 1) {
                    for (var x = 0; x < 2; x++) {
                        if (keywords[x]
                            && (keywords[x].percentage > 1.85 || keywords[x].count > 15)
                            && !topics.hasOwnProperty(keywords[x].word)) {
                            topicCount[keywords[x].numWords]++;
                            topics[keywords[x].word] = keywords[x];
                            topicsKeywords.push(keywords[x].word);
                        }
                    }
                }
                var tmpAnalyzedKw;
                var tmpThisKw;
                var canFetchKeyword = false;
                //topic fallback

                for (var i in topics) {
                    for (var j in keywords) {
                        canFetchKeyword = keywords[j].numWords > 1
                            && keywords[j].word != topics[i].word
                            && isTopic(keywords[j], topics[i].numWords)
                            && keywords[j].word.toLowerCase().indexOf(topics[i].word.toLowerCase()) != -1;
                        if (canFetchKeyword) {
                            topicsKeywords.push(keywords[j].word);
                            topics[keywords[j].word] = keywords[j];
                        }
                    }
                }
                for (var j = 0; j < 5 && topicsKeywords.length < 5 && j < keywords.length; j++) {
                    if (!topics.hasOwnProperty(keywords[j].word)) {
                        topicCount[keywords[j].numWords]++;
                        topics[keywords[j].word] = keywords[j];
                        topicsKeywords.push(keywords[j].word);
                    }
                }
                if (!topicsKeywords.length) {
                    return resolve({
                        reading: readingStats,
                        density: keywords,
                        avgCpc: 0,
                        textDifficulty: 0,
                        buyIntent: 0,
                        volumeScore: 0,
                        topics: topics,
                        topicsKeywords: [],
                        uniqueKeywords: uniqueKeywords,
                        potentialVolume: 0,
                    });
                }
                return KeywordsModel.findAll({
                    q: topicsKeywords,
                    country: country
                }).then(function (res) {
                    var totalCpc = 0;
                    var totalAms = 0;
                    var totalAmsWeight = 0;
                    var totalKdWeight = 0;
                    var totalCpcWeight = 0;
                    var totalCounted = 0;
                    var totalKeywordDiff = 0;
                    var buyIntentScore = 0;
                    var oneWordFound = false;
                    for (var i = 0; i < res.list.length; i++) {
                        topics[res.list[i].keyword] = Object.assign({}, topics[res.list[i].keyword], res.list[i]);
                        var thisKeyword = topics[res.list[i].keyword];
                        var thisLen = thisKeyword.numWords;
                        var considerateThisKeyword = (
                            topicsKeywords.length < 3
                            && topicsKeywords[0] === res.list[i].keyword)
                            || thisLen > 1
                            || thisKeyword.ams < 20000;
                        if (considerateThisKeyword) {
                            var tmpAms = !isNaN(parseInt(thisKeyword.ams)) ? parseInt(thisKeyword.ams) : 0;
                            var tmpKD = !isNaN(parseInt(thisKeyword.difficulty)) ? parseInt(thisKeyword.difficulty) : 0;
                            var volumePoints = Math.max(1, (10 * Math.min(10000, tmpAms)) / 10000);
                            var densityPoints = Math.max(1, (10 * Math.min(10, thisKeyword.percentage)) / 10);
                            var lenPoints = Math.max(1, parseInt(Math.min(thisLen, 10) * 10 / 7)) * thisLen;
                            totalCounted++;
                            totalAmsWeight += Math.max(1, lenPoints);
                            totalAmsWeight += volumePoints;
                            totalAmsWeight += densityPoints;
                            totalAms += tmpAms;
                            if (thisKeyword.ams > 0) {
                                totalKdWeight += Math.max(1, lenPoints);
                                totalKdWeight += volumePoints;
                                totalKdWeight += densityPoints;
                                totalKeywordDiff += tmpKD * (Math.max(1, lenPoints) + volumePoints + densityPoints);
                            }
                        }
                        totalCpc += !isNaN(parseFloat(thisKeyword.cpc)) ? parseFloat(thisKeyword.cpc) : 0;
                        totalCpcWeight++;
                    }
                    var avgCpc = totalCpc / totalCpcWeight;
                    var potentialVolume = Math.floor(parseInt(totalAms) / 50) * 50;
                    var textDifficulty = Math.min(100, parseInt(totalKeywordDiff / totalKdWeight));
                    var buyIntent = parseInt((100 * Math.min(avgCpc, 2)) / 2);
                    var volumeScore = parseInt(Math.min(potentialVolume, 15000) * 100) / 10000;
                    resolve({
                        reading: readingStats,
                        density: keywords,
                        avgCpc: avgCpc,
                        textDifficulty: isNaN(textDifficulty) ? 0 : textDifficulty,
                        buyIntent: buyIntent,
                        volumeScore: isNaN(volumeScore) ? 0 : volumeScore,
                        topics: topics,
                        topicsKeywords: topicsKeywords.filter(onlyUnique),
                        uniqueKeywords: uniqueKeywords,
                        potentialVolume: potentialVolume,
                    });
                }).catch(function (err) {
                    reject(err);
                });
            });

        }

        function isTopic(keywords, parentLen) {
            return (keywords.numWords == 2 && keywords.percentage > 2.50)
                || (keywords.numWords == 2 && keywords.count >= 2 && keywords.percentage > 1.2)
                || (keywords.numWords == 2 && keywords.count > 4 && keywords.percentage > 1)
                || (keywords.numWords == 2 && keywords.count > 3 && keywords.percentage > 1.2)
                || (keywords.numWords == 3 && keywords.percentage > 1.10)
                || (keywords.numWords == 3 && keywords.count > 3 && keywords.percentage >= 0.74)
                || (keywords.numWords == 3 && keywords.count > 7 && keywords.percentage >= 0.50)

                || (keywords.numWords == 4 && keywords.percentage > 0.85)
                || (keywords.numWords == 4 && keywords.count > 7 && keywords.percentage >= 0.50)
                || (keywords.numWords == 5 && keywords.percentage >= 0.9)
                || (keywords.numWords == 5 && keywords.count > 7 && keywords.percentage >= 0.50)
                || (keywords.numWords == 6 && keywords.percentage >= 0.9)
                || (keywords.numWords == 6 && keywords.percentage >= 0.9 && keywords.count >= 3)
                || (keywords.numWords > 6 && keywords.percentage >= 0.85 && keywords.count >= 3)
                || (keywords.numWords > 6 && keywords.percentage > 0.85)
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        function ansiWordBound(c) {
            return (
                (' ' === c) ||
                ('\n' === c) ||
                ('\r' === c) ||
                ('\t' === c)
            )
        }

        function readingTime(text, options) {
            var words = 0, start = 0, end = text.length - 1, wordBound, i
            options = options || {}
            // use default values if necessary
            options.wordsPerMinute = options.wordsPerMinute || 200
            // use provided function if available
            wordBound = options.wordBound || ansiWordBound
            // fetch bounds
            while (wordBound(text[start])) start++
            while (wordBound(text[end])) end--
            // calculate the number of words
            for (i = start; i <= end;) {
                for (; i <= end && !wordBound(text[i]); i++) ;
                words++
                for (; i <= end && wordBound(text[i]); i++) ;
            }
            // reading time stats
            var minutes = words / options.wordsPerMinute
            var time = minutes * 60 * 1000
            var displayed = Math.ceil(minutes.toFixed(2))
            return {
                minutes: minutes,
                time: time,
                words: words
            }
        }

        function sentences(text) {
            var list = [];
            try {
                var list = text.match(/\s{0,}\n?(^[a-zàèéìòù]|[A-ZÀÈÉÌÒÙ])[^.?!]+((?![.?!]\s{1,}[A-ZÀÈÉÌÒÙ][^.?!]).)+([.?!]+|$)/g);
                if (!list) list = [];
            } catch (err) {
            }
            return {
                count: list.length || 0,
                list: list
            };
        }

        function textStat(text) {
            var sentencesInfo = sentences(text);
            var syllablesInfo = syllables(text);
            var stats = {
                char: syllablesInfo.char,
                word: syllablesInfo.word,
                sentence: sentencesInfo.count,
                syllable: syllablesInfo.syllable,
                complexPolysillabicWord: syllablesInfo.complexPolysillabicWord,
                readingTime: syllablesInfo.readingTime,
            };
            stats.flesch = flesch(stats);
            stats.gulpease = gulpease(stats);
            stats.gunningFog = gunningFog(stats);
            return stats;
        }

        function syllables(string) {
            var allWords = [];
            try {
                allWords = string.trim().split(/\s{1,}/g);
            } catch (err) {
            }
            var totalSyllables = 0;
            var totalChars = 0;
            var foundSyllables;
            var complexPolysillabicWord = 0;
            var syllables;
            for (var i = 0; i < allWords.length; i++) {
                allWords[i] = allWords[i].toLowerCase();                                     //word.downcase!
                if (allWords[i].length <= 3) {
                    syllables = 1;
                } else {
                    allWords[i] = allWords[i].replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');   //word.sub!(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '')
                    allWords[i] = allWords[i].replace(/^y/, '');                                 //word.sub!(/^y/, '')
                    foundSyllables = allWords[i].match(/[aeiouy]{1,2}/g);
                    if (foundSyllables)
                        syllables = foundSyllables.length;
                    else syllables = 0;
                }
                totalSyllables += syllables;
                if (syllables >= 3) complexPolysillabicWord++;
                totalChars += allWords[i].length;
            }
            var wordsPerSecond = 270 / 60;
            var totalReadingTimeSeconds = allWords.length / wordsPerSecond;
            var totalReadingTimeMin = totalReadingTimeSeconds / 60;
            var int = Math.floor(totalReadingTimeMin);
            var dec = totalReadingTimeMin - int;
            var decInt = Math.floor(dec * 60);
            var totalReadingTimeMinString = "" + int + "." + decInt + " min";
            var totalWords = [];
            if (allWords[0] == "") {
                totalWords = 0;
            } else {
                totalWords = allWords.length;
            }
            return {
                word: totalWords,
                complexPolysillabicWord: complexPolysillabicWord,
                syllable: totalSyllables,
                char: totalChars,
                readingTime: totalReadingTimeMinString
            };
        }

        function gulpease(counts) {
            var SENTENCE_WEIGHT = 300;
            var WORD_WEIGHT = 89;
            var CHAR_WEIGHT = 10;
            var score = WORD_WEIGHT + ((
                (SENTENCE_WEIGHT * counts.sentence) - (CHAR_WEIGHT * counts.char)
            ) / counts.word);
            return score > 100 ? 100 : score;
        }

        function flesch(counts) {
            var SENTENCE_WEIGHT = 1.015;
            var WORD_WEIGHT = 84.6;
            var BASE = 206.835;
            if (!counts || !counts.sentence || !counts.word || !counts.syllable) {
                return NaN;
            }
            return BASE -
                (SENTENCE_WEIGHT * (counts.word / counts.sentence)) -
                (WORD_WEIGHT * (counts.syllable / counts.word));
        }

        function gunningFog(counts) {
            var COMPLEX_WORD_WEIGHT = 100;
            var WEIGHT = 0.4;
            if (!counts || !counts.sentence || !counts.word) {
                return NaN;
            }
            return WEIGHT * (
                (counts.word / counts.sentence) +
                (COMPLEX_WORD_WEIGHT *
                    ((counts.complexPolysillabicWord || 0) /
                        counts.word))
            );
        }
    }
});

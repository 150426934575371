'use strict';
define([], function () {
    var LOADING_LABELS = [
        'seochecker.loader.alert.im.turning.on.the.ballasts',
        'seochecker.loader.alert.im.analyzing.the.seo.of.the.page',
        'seochecker.loader.alert.im.analyzing.structured.data',
        'seochecker.loader.alert.im.drawing.a.new.orbital.plane',
        'seochecker.loader.alert.im.analyzing.the.html.tags',
        'seochecker.loader.alert.im.analyzing.the.ux.and.performances',
        'seochecker.loader.alert.im.checking.for.collisions.with.asteroids',
        'seochecker.loader.alert.im.analyzing.your.content'
    ];

    return {
        templateUrl: 'app/modules/seochecker/components/google-preview/google-preview.html',
        bindings: {
            loading: '<',
            performance: '<',
            customClass: '<',
        },
        controller: ['$scope', '$interval', 'PagespeedService', function ($scope, $interval, PagespeedService) {
            var vm = $scope;
            var labels = Object.assign([], LOADING_LABELS);
            var randomIndex = Math.floor(Math.random() * labels.length);
            labels.splice(randomIndex, 1);
            vm.loadingLabelsToShow = labels[randomIndex];
            vm.pageNotFound = true;
           
            function getScreenshotDesktop(performance) {
                var screenshot = '../assets/img/seochecker/screen-placeholder/desktop.png';

                if (performance && performance.desktop && performance.desktop.screenshot && performance.desktop.screenshot.src) {
                    vm.pageNotFound = false;
                    screenshot = performance.desktop.screenshot.src;
                }

                return screenshot;
            }

            function getScreenshotMobile(performance) {
                var screenshot = '../assets/img/seochecker/screen-placeholder/mobile.png';

                if (performance && performance.mobile && performance.mobile.screenshot && performance.mobile.screenshot.src) {
                    vm.pageNotFound = false;
                    screenshot = performance.mobile.screenshot.src;
                }

                return screenshot;
            }

            this.$onChanges = function () {
                vm.loading = this.loading;
              
                if (vm.loading) {
                    $interval(function () {
                        randomIndex = Math.floor(Math.random() * labels.length);
                        if (labels.length != 1) {
                            vm.loadingLabelsToShow = labels[randomIndex];
                            labels.splice(randomIndex, 1);            
                        } else if (labels.length == 1) {
                            labels = Object.assign([], LOADING_LABELS);
                        }
                    }, 2500);
                }
               
            };

            vm.$watch('$ctrl.performance', function (performance) {
                vm.performance = performance;
                
                if (!performance || !performance.mobile || !performance.mobile.screenshot || !performance.mobile.screenshot.src) {
                    vm.pageNotFound = true;
                }
                vm.screenshotDesktop = getScreenshotDesktop(performance);
                vm.screenshotMobile = getScreenshotMobile(performance);
            });
        }]
    };
});

'use strict';
define([], function () {
    function Controller($window, $rootScope, $scope, $state, $stateParams, WorkspaceService, LanguageService, CRMService) {
        var vm = $scope;
        vm.isTrialing = $stateParams.isTrialing;
        vm.trialEndDate = $stateParams.trialEndDate;
        vm.alias = $stateParams.alias;

        vm.switchLang = switchLang;
        vm.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();

        var redirectRoute = 'app.project.wizard';
        var redirectRouteObj = {
            action: 'GET',
            step: 'create-project',
            tool: 'rank-tracker',
            firstproject: true,
            from: 'thank-you'
        };

        // Remove Deal Id from local Storage User after buy Plan
        $window.localStorage.removeItem('dealId');
        $window.localStorage.removeItem('saleOwner');

        vm.callToActionText = 'project.wizard.create.a.project';
        vm.callToActionRedirect = callToActionRedirect;
        vm.isSeoCheckerPlan = vm.alias && vm.alias.startsWith('plan-seo-checker');
        vm.isAddonWorkspacePlan = vm.alias && vm.alias.startsWith('addon-additional-user');

        if (vm.isSeoCheckerPlan) {
            vm.callToActionText = 'upgrade.seo.checker.start.your.optimization';
            redirectRoute = 'app.seochecker.landing';
            redirectRouteObj = {};
        } else if (vm.isAddonWorkspacePlan) {
            vm.callToActionText = 'settings.users.organize.your.work.group';
            redirectRoute = 'app.settings.users';
            redirectRouteObj = {};
        }

        if (vm.isTrialing) {
            CRMService.addEvents([
                'UserToTrialGainPoint1Event',
                'UserToTrialGainPoint2Event',
                'UserToTrialGainPoint3Event',
            ]);
        }

        CRMService.addEvents([
            'UserToTrialUserStart1Event',
            'UserToTrialUserStart2Event',
            'UserToTrialUserStart3Event',
            'ProjectToCreateEvent',
        ], [
            'UserToTrialGenericCheckoutRecoverEvent',
            'UserToTrialPricingRecoverEvent',
            'UserToTrialGenericPaymentFailedEvent',
        ]);

        function callToActionRedirect() {
            WorkspaceService.getDefaultWorkspace()
                .then(function (workspace) {
                    WorkspaceService.setWorkspace(workspace);
                    $rootScope.$broadcast("workspace-set");
                    $state.go(redirectRoute, redirectRouteObj, {notify: true, reload: true});
                });
        }

        function switchLang(language) {
            vm.selectedLang = language;
            $rootScope.setLang(language);
        }
    }

    Controller.$inject = ['$window', '$rootScope', '$scope', '$state', '$stateParams', 'WorkspaceService', 'LanguageService', 'CRMService'];
    return Controller;
});

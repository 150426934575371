'use strict';
define(['app'], function (app) {
    app.factory('PendingActionsService', ['$rootScope', '$state', 'WorkspaceService',
        function ($rootScope, $state, WorkspaceService) {

            function clearLocalStorage(pendingActions, propertyToClear) {
                delete pendingActions[propertyToClear];
                localStorage.setItem('pendingActions', JSON.stringify(pendingActions));
                return pendingActions;
            }

            return {
                register: function (urlParams) {
                    $rootScope.$emit('pending-actions-service-event');
                    localStorage.setItem('pendingActions', JSON.stringify(urlParams));
                },
                execute: function (scope, callback) {
                    var handler = $rootScope.$on('pending-actions-service-event', callback);
                    var pendingActions = JSON.parse(localStorage.getItem('pendingActions'));

                    if (pendingActions && pendingActions.workspaceId) {
                        /*
                        WorkspaceService.acceptInvitations(Number(pendingActions.workspaceId))
                            .then(function (res) {
                                clearLocalStorage(pendingActions, 'workspaceId');
                                $state.go('app.settings.invitations');
                            }).catch(function (err) {
                            console.log(err);
                        });
                        */
                        clearLocalStorage(pendingActions, 'workspaceId');
                        $state.go('app.settings.invitations');
                    }

                    scope.$on('$destroy', handler);
                },
            };
        }]);
});

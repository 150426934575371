'use strict';
define(['angular'], function (angular) {
    function Controller($scope, $state, UserService, $sce, stAlertLimitService, stLimitMessage, userInfo, ExportService, $translate, __env, BacklinkCheckerService, $stateParams,
        TrackEventService, FavIconService, $timeout, $filter, $mdMedia, LanguageService, $window) {
        updateLimits();

        var CUSTOM_TEMPLATE_FOR_EMPTY_RESULT = '<div class="p-xl">' +
            '<i class="material-icons st-grey-1 f-s-70-force">link</i>' +
            '<p class="m-t-md md-subhead font-bold st-grey-3">' +
            $translate.instant('backlinkchecker.start.analyzing.backlinks.of.any.website') +
            '</p>' +
            '<p class="md-body-1 st-grey-2">' +
            $translate.instant('backlinkchecker.enter.a.domai.or.a.url.in.the.search.bar.and.find.thousand.of.backlinks.metrics') +
            '</p>' +
            '</div>';
        var DEFAULT_QUERIES;
        var progressValue = document.querySelector('.Progressbar__value');
        var vm = $scope;
        var firstAnalisys = true;
        vm.$mdMedia = $mdMedia;
        vm.userHasNoActiveSubscription = userInfo? (userInfo.account !== 'sub'):false;
        vm.totalBacklinks = null;

        vm.historyMode = false;
        vm.filters = {
            type: 'all',
            urlType: 'domain_with_subdomains',
            protocol: 'all',
            isBroken: 'all',
            follow: 'all',
            onePerDomain: false,
            domainRating: {},
            urlRating: {},
            urlToSpamScore: {},
            firstSeen: {from: null, to: null},
            keywordsInAnchor: '',
        };
        vm.limit = 500;
        vm.currentActiveTab = 0;
        vm.selectColumnTableIsOpen = false;
        vm.loadMoreBacklinksLoading = false;
        vm.filtersChanged = false;
        vm.selectedHistoryItem = null
        vm.getColumnVisibility = getColumnVisibility
        vm.customTemplate = CUSTOM_TEMPLATE_FOR_EMPTY_RESULT;
        vm.newAnalysis = newAnalysis;
        vm.selectedRowIndex = 0;
        vm.canShowFavIcon = false;
        vm.urlType = 'domain_with_subdomains';
        vm.copyToClipboardOptions = {};
        vm.exportCsvOptions = {};
        vm.getFavIcon = FavIconService.get;

        var customTableController = {
            colorByScore: colorByScore,
            cleanUrl: cleanUrl, //check
            getColorGraphByScore: getColorGraphByScore,
            query: vm.query, //check
            onRowSelected: onRowSelected,
            boldDomainFromUrl: boldDomainFromUrl,
            getColumnVisibility: getColumnVisibility,
            formatDate: formatDate
        };

        vm.selectedTableColumns = [{
                id: 'ur',
                text: 'UR',
                fullText: 'Url Rating',
                selected: true,
            },
            {
                id: 'dr',
                text: 'DR',
                fullText: 'Domain Rating',
                selected: true,
            },
            {
                id: 'el',
                text: 'EL',
                fullText: 'External links',
                selected: true,
            },
            {
                id: 'ib',
                text: 'IB',
                fullText: 'Identical Backlinks',
                selected: true,
            },
            {
                id: 'tb',
                text: 'TB',
                fullText: 'Total Backlinks',
                selected: vm.filters.onePerDomain,
            },
            {
                id: 'anchor-text-and-backlink',
                text: 'Anchor Text And Backlink',
                selected: true,
            },
            {
                id: 'as',
                text: 'AS',
                fullText: 'Authority Score',
                selected: true,
            },
            {
                id: 'first-seen',
                text: 'backlinkchecker.first.seen',
                selected: true,
            },
            {
                id: 'last-seen',
                text: 'backlinkchecker.last.seen',
                selected: false,
            },
            {
                id: 'page-from-size',
                text: 'backlinkchecker.source.page.size',
                selected: false,
            },
            {
                id: 'page-from-encoding',
                text: 'backlinkchecker.source.page.encoding',
                selected: false,
            },
            {
                id: 'domain-from-ip',
                text: 'backlinkchecker.source.ip',
                selected: false,
            },
            {
                id: 'page-from-status-code',
                text: 'backlinkchecker.source.http.status.code',
                selected: false,
            },
            {
                id: 'page-from-internal-links',
                text: 'backlinkchecker.source.page.internal.links',
                selected: false,
            },
            {
                id: 'url-to-spam-score',
                text: 'backlinkchecker.source.url.spam.score',
                selected: false,
            },
        ];

        vm.linkTabs = [{
            label: 'backlinkchecker.all.links',
            isActive: true,
        }, {
            label: 'backlinkchecker.new.links',
            isActive: false
        }, {
            label: 'backlinkchecker.lost.links',
            isActive: false
        }];

        vm.domainTypes = [{
                id: 'domain_with_subdomains',
                icon: 'subdomains.svg',
                label: 'project.wizard.analyze.all',
                detail: 'project.wizard.domain.including.subdomains',
                example: 'project.wizard.subdomains.example'
            },
            {
                id: 'domain_without_subdomains',
                icon: 'domain.svg',
                label: 'project.wizard.domain',
                detail: 'project.wizard.only.specified.domain',
                example: 'project.wizard.domain.example'
            },
            {
                id: 'prefix',
                icon: 'path.svg',
                label: 'project.wizard.path',
                detail: 'project.wizard.path.including.subfolders',
                example: 'project.wizard.path.example'
            },
            {
                id: 'exact_url',
                icon: 'exact-url.svg',
                label: 'project.wizard.exact.url',
                detail: 'project.wizard.only.specified.url',
                example: 'project.wizard.url.example'
            },

        ];

        vm.followOptions = [{
                id: 'all',
                label: 'backlinkchecker.dofollow.nofollow',
            },
            {
                id: 'dofollow',
                label: 'backlinkchecker.dofollow',
            },
            {
                id: 'nofollow',
                label: 'backlinkchecker.nofollow',
            },
        ];

        vm.activeBrokenOptions = [{
                id: 'all',
                label: 'backlinkchecker.active.broken',
            },
            {
                id: 'active',
                label: 'backlinkchecker.active',
            },
            {
                id: 'broken',
                label: 'backlinkchecker.broken',
            },
        ];

        vm.protocols = [{
                id: 'all',
                label: 'project.wizard.http.https'
            },
            {
                id: 'https',
                label: 'project.wizard.https'
            },
            {
                id: 'http',
                label: 'project.wizard.http'
            }
        ];

        vm.backlinksToUnlockOptions = [{
                limit: 500,
                label: 'backlinkchecker.unlock.other.backlinks'
            },
            {
                limit: 1000,
                label: 'backlinkchecker.unlock.other.backlinks'
            },
            {
                limit: 2000,
                label: 'backlinkchecker.unlock.other.backlinks'
            }
        ];

        //FUNCTIONS

        function init() {
            getHistory();
        }

        function getHistory() {
            if(!userInfo){
                vm.history = [];
                return;
            }
            if (userInfo.account === 'sub') {
                BacklinkCheckerService
                    .getHistory()
                    .then(function (res) {
                        if (res && res.data && res.data.history) {
                            vm.history = res.data.history;

                        } else {
                            vm.history = [];
                        }

                    });
            } else {
                vm.history = [];
            }
        }

        vm.getLockedRows = function () {
            if (!vm.userHasNoActiveSubscription) {
                return 0;
            }

            if (vm.totalBacklinks > 500) {
                return 495;
            } else {
                return vm.totalBacklinks - 5;
            }
        }

        vm.switchOnePerDomain = function (ev) {
            vm.filters.onePerDomain = !vm.filters.onePerDomain;
            ev.stopPropagation();
            ev.preventDefault();

            vm.results.isLoading = true;
            if (!vm.filters.onePerDomain) {

                var index = vm.selectedColumn.indexOf('tb');
                if (index !== -1) {
                    vm.selectedColumn.splice(index, 1);
                }

            } else {
                var index = vm.selectedColumn.indexOf('tb');
                if (index < 0) {
                    vm.selectedColumn.push('tb')
                }
            }
            checkTableColumn();
            vm.newAnalysis();
        }


        function formatDate(strDate) {
            var date = strDate.split(" ")[0];
            var myDate = date.split("-");
            var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

            return $filter('date')(newDate.getTime(), "mediumDate");
        }

        vm.checkboxBacklinksSelected = function (rowsSelected) {
            vm.rowsSelected = rowsSelected;

            setQueriesForComponents(rowsSelected);
        }

        vm.setHistoryItem = function (historyItem) {
            vm.historyItem = historyItem;
            if (historyItem) {
                vm.showFilter = false;
            }
            vm.historyMode = true;
            vm.results.isLoading = false;
            vm.domain = historyItem.object.url;
            vm.favIconUrl = 'https://www.google.com/s2/favicons?sz=64&domain=' + vm.domain;
            vm.results.rows = historyItem.object.rows;
            vm.customTemplate = null;
            vm.filters = historyItem.object.filters;
            vm.totalBacklinks = historyItem.object.totalRows;
            vm.pageAuthority = historyItem.object.pageAuthority;
            vm.domainAuthority = historyItem.object.domainAuthority;
            vm.currentActiveTab = vm.filters.type == 'lost' ? 2 : (vm.filters.type == 'new' ? 1 : 0);

            TrackEventService.event(
                'Suite Usages',
                'Backlink Checker - Analyze', 'History');
            TrackEventService.gaV2Event(
                'backlink_analyze',{
                    type: 'History',
                    tool: 'Backlink Checker'
                });

            $state.transitionTo('app.backlinkchecker.tool', {
                domain: vm.domain,
                filters: JSON.stringify(vm.filters)
            }, {
                notify: false
            });
        }

        function setQueriesForComponents(queries, initialQueries) {
            if (initialQueries) {
                DEFAULT_QUERIES = initialQueries;
            }
            if (!queries.length) {
                queries = DEFAULT_QUERIES ? DEFAULT_QUERIES : [];
            }
            var objectForComponents = {
                queriesName: [],
                queriesAllFields: []
            };

            queries.forEach(function (query) {
                objectForComponents.queriesName.push(query.urlFrom);

                objectForComponents.queriesAllFields.push({
                    type: formatValueForCsv(query.type),
                    domainFrom: formatValueForCsv(query.domainFrom),
                    urlFrom: formatValueForCsv(query.urlFrom),
                    urlFromHttps: formatValueForCsv(query.urlFromHttps),
                    domainTo: formatValueForCsv(query.domainTo),
                    urlTo: formatValueForCsv(query.urlTo),
                    urlToHttps: formatValueForCsv(query.urlToHttps),
                    tldFrom: formatValueForCsv(query.tldFrom),
                    isNew: formatValueForCsv(query.isNew),
                    isLost: formatValueForCsv(query.isLost),
                    isBroken: formatValueForCsv(query.isBroken),
                    isfollow: formatValueForCsv(query.isfollow),
                    UR: formatValueForCsv(query.pageFromRank),
                    DR: formatValueForCsv(query.domainFromRank),
                    EL: formatValueForCsv(query.pageFromExternalLinks),
                    IB: formatValueForCsv(query.linksCount),
                    TB: formatValueForCsv(query.groupCount),
                    AS: formatValueForCsv(query.rank),
                    pageFromInternalLinks: formatValueForCsv(query.pageFromInternalLinks),
                    pageFromTitle: formatValueForCsv(query.pageFromTitle),
                    pageFromLanguage: formatValueForCsv(query.pageFromLanguage),
                    pageFromSize: formatValueForCsv(query.pageFromSize),
                    pageFromEncoding: formatValueForCsv(query.pageFromEncoding),
                    domainFromIp: formatValueForCsv(query.domainFromIp),
                    pageFromStatusCode: formatValueForCsv(query.pageFromStatusCode),
                    domainFromPlatformType: formatValueForCsv(query.domainFromPlatformType),
                    urlToStatusCode: formatValueForCsv(query.urlToStatusCode),
                    urlToSpamScore: formatValueForCsv(query.urlToSpamScore),
                    textPre: formatValueForCsv(query.textPre),
                    textPost: formatValueForCsv(query.textPost),
                    alt: formatValueForCsv(query.alt),
                    anchor: formatValueForCsv(query.anchor),
                    original: formatValueForCsv(query.original),
                    attributes: formatValueForCsv(query.attributes),
                    itemType: formatValueForCsv(query.itemType),
                    firstSeen: formatValueForCsv(query.firstSeen),
                    prevSeen: formatValueForCsv(query.prevSeen),
                    lastSeen: formatValueForCsv(query.lastSeen),
                });
            });

            createComponentExportAndCopyToClipboard(objectForComponents);
        }

        function formatValueForCsv(value) {
            switch (typeof value) {
                case 'string':
                    return value.split(',').join('').split('\n').join('');
                case 'boolean':
                case 'number':
                default:
                    return value;
            }
        }

        function createComponentExportAndCopyToClipboard(object) {
            vm.copyToClipboardOptions = {
                class: 'st-button__rounded--xs--blue',
                text: 'backlinkchecker.copy',
                showToast: true,
                icon: 'icon-copy-filled',
                data: [object.queriesName],
                mode: 'rows',
                buttonDisabled: vm.userHasNoActiveSubscription || ((!vm.results || !vm.results.rows || vm.results.rows.length <= 0) && !vm.results.isLoading)
            };

            var formattedRows = object && object.queriesAllFields ? object.queriesAllFields : [];
            vm.exportCsvOptions = {
                stoTool: 'backlink-checker',
                class: 'st-button__rounded--xs--flamingo',
                text: 'backlinkchecker.csv.export',
                icon: 'icon-download',
                data: ExportService.convertObjectToCsvArray(formattedRows),
                fileName: 'backlinks',
                buttonDisabled: vm.userHasNoActiveSubscription || ((!vm.results || !vm.results.rows || vm.results.rows.length <= 0) && !vm.results.isLoading)
            };
        }

        vm.applyFilters = function () {
            vm.filtersCount = 0;

            vm.showFilter = false;
            if (vm.filters.urlRating && vm.filters.urlRating.min) {
                vm.filters.urlRating.min = Number(vm.filters.urlRating.min);
                vm.filtersCount += 1;
            }

            if (vm.filters.urlRating && vm.filters.urlRating.max) {
                vm.filters.urlRating.max = Number(vm.filters.urlRating.max);
                vm.filtersCount += 1;
            }

            if (vm.filters.domainRating && vm.filters.domainRating.min) {
                vm.filters.domainRating.min = Number(vm.filters.domainRating.min);
                vm.filtersCount += 1;
            }

            if (vm.filters.domainRating && vm.filters.domainRating.max) {
                vm.filters.domainRating.max = Number(vm.filters.domainRating.max);
                vm.filtersCount += 1;
            }

            if (vm.filters.urlToSpamScore && vm.filters.urlToSpamScore.min) {
                vm.filters.urlToSpamScore.min = Number(vm.filters.urlToSpamScore.min);
                vm.filtersCount += 1;
            }

            if (vm.filters.urlToSpamScore && vm.filters.urlToSpamScore.max) {
                vm.filters.urlToSpamScore.max = Number(vm.filters.urlToSpamScore.max);
                vm.filtersCount += 1;
            }

            if (vm.filters.firstSeen && vm.filters.firstSeen.from) {
                var dateFrom = new Date(vm.filters.firstSeen.from);

                if (firstAnalisys)  {
                    dateFrom.setDate(dateFrom.getDate() + 1);
                } else {
                    dateFrom.setDate(dateFrom.getDate());
                }

                vm.filters.firstSeen.from = dateFrom.toISOString().substring(0, 10);
                vm.filtersCount += 1;
            }

            if (vm.filters.firstSeen && vm.filters.firstSeen.to) {
                var dateTo = new Date(vm.filters.firstSeen.to);

                if (firstAnalisys)  {
                    dateTo.setDate(dateTo.getDate() + 1);
                } else {
                    dateTo.setDate(dateTo.getDate());
                }
                vm.filters.firstSeen.to = dateTo.toISOString().substring(0, 10);
                vm.filtersCount += 1;
            }

            if (vm.filters.keywordsInAnchor && vm.filters.keywordsInAnchor.length) {
                vm.filtersCount += 1;
            }

            if (vm.filters.sourceUrl) {
                vm.filtersCount += 1;
            }

            if (vm.filters.targetUrl) {
                vm.filtersCount += 1;
            }

            vm.newAnalysis();
        };

        vm.resetFilters = function () {
            vm.filtersCount = 0;
            vm.filters.urlRating.min = null;
            vm.filters.urlRating.max = null;
            vm.filters.domainRating.min = null;
            vm.filters.domainRating.max = null;
            vm.filters.urlToSpamScore.min = null;
            vm.filters.urlToSpamScore.max = null;
            vm.filters.firstSeen.from = null;
            vm.filters.firstSeen.to = null;
            vm.filters.keywordsInAnchor = null;
            vm.filters.sourceUrl = null;
            vm.filters.targetUrl = null;
        };

        function onRowSelected(row, index) {
            var iframe = null;
            var value = 0;

            vm.iframeIsLoaded = false;
            vm.iframeUrl = null;
            progressValue.style.width = 0 + "%";
            vm.progressValue = 0;

            vm.selectedRowIndex = index;
            vm.currentRow = row;

            vm.iframeUrl = $sce.trustAsResourceUrl(__env.proxyIframe + '/?url=' + vm.currentRow.urlFrom + '&target=' + vm.currentRow.urlTo + '&anchor=' + vm.currentRow.anchor);

            iframe = document.getElementById("iframe");
            if (iframe) {
                iframe.addEventListener("load", function () {
                    vm.iframeIsLoaded = true;
                    value = 100;
                    vm.progressValue = 100;
                });
            }

            function setValue(value) {
                progressValue.style.width = value + "%";
                vm.progressValue = value;
            }


            setValue(value);
        }

        vm.onChangeDomainType = function (domainType) {
            vm.filters.urlType = domainType;
        };

        vm.results = {
            isLoading: false,
            controller: customTableController,
            rows: [],
            messages: {
                onBoarding: ''
            },
            fixedRows: []
        };

        function updateLimits() {
            UserService.limits()
                .then(function (res) {
                    userInfo = res.data;
                    vm.analysisLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'backlink-checker-requests';
                    });
                    vm.rowsLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'backlink-checker-rows';
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });
        }

        function getColorGraphByScore(score) {
            score = parseFloat(score);
            return [score >= 75 ? "#28b670" :
                score >= 50 ? "#fb8c00" :
                "#f44336"
            ];
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function colorByScore(score) {
            function rgbify(maxval, minval, val) {
                val -= 13;
                if (val < 0) val = 0;
                var intnsty = (val - minval) / (maxval - minval);
                intnsty -= 0.005;
                if (intnsty < 0) intnsty = 0;
                var r, g;
                if (intnsty > 0.5) {
                    r = 240;
                    g = Math.round(2 * (1 - intnsty) * 195);
                } else {
                    g = 200;
                    r = Math.round(2 * intnsty * 235);
                }
                return "rgb(" + r.toString() + ", " + g.toString() + ", 100 , 1)";
            }

            return rgbify(100, 0, score);
        }

        vm.setActiveTab = function (tabIndex) {
            vm.currentActiveTab = tabIndex;
            switch (tabIndex) {
                default:
                case 0: {
                    vm.filters.type = 'all';
                    break;
                }
                case 1: {
                    vm.filters.type = 'new';
                    break;
                }
                case 2: {
                    vm.filters.type = 'lost';
                    break;
                }
            }
            newAnalysis();
        };

        vm.onClickEditColumnButton = function (ev) {
            if (!vm.selectColumnTableIsOpen) {
                $timeout(function () {
                    angular.element(document.querySelector('#multiselect-field')).click();
                    vm.selectColumnTableIsOpen = true;
                }, 100);
            }
        };

        function getColumnVisibility(columnId) {
            var columnSelected = true;

            for (var i = 0; i < vm.selectedTableColumns.length; i++) {
                if (vm.selectedTableColumns[i].id == columnId) {
                    columnSelected = vm.selectedTableColumns[i].selected;
                    break;
                }
            }

            if (columnSelected) {
                return 'show';
            } else {
                return 'hide';
            }
        }

        vm.$watch('selectedColumn', function () {
            checkTableColumn();
        });

        $scope.$watch('filters', function (newValue, oldValue, scope) {
            vm.filtersChanged = angular.equals(vm.filtersCopy, vm.filters);
        }, true);

        function checkTableColumn() {
            if (vm.selectedColumn && vm.selectedColumn.length >= 0) {
                for (var i = 0; i < vm.selectedTableColumns.length; i++) {
                    vm.selectedTableColumns[i].selected = vm.selectedColumn.indexOf(vm.selectedTableColumns[i].id) >= 0;
                }
            }

            vm.tableFields = [{
                    emptyState: 'three-lines',
                    label: 'backlinkchecker.source.url',
                    customClass: 'm-w text-left',
                    tooltip: 'backlinkchecker.source.url.tooltip',
                },
                {
                    label: 'UR',
                    emptyState: 'square',
                    sortBy: 'pageFromRank',
                    customClass: 'column-45px hide-xs '+ vm.getColumnVisibility('ur'),
                    tooltip: 'backlinkchecker.ur.tooltip'
                },
                {
                    label: 'DR',
                    emptyState: 'square',
                    sortBy: 'domainFromRank',
                    customClass: 'column-45px hide-xs ' +vm.getColumnVisibility('dr'),
                    tooltip: 'backlinkchecker.dr.tooltip'
                },
                {
                    label: 'EL',
                    sortBy: 'pageFromExternalLinks',
                    customClass: 'column-xs hide-xs ' +vm.getColumnVisibility('el'),
                    tooltip: 'backlinkchecker.el.tooltip'
                },
                {
                    label: 'IB',
                    sortBy: 'linksCount',
                    customClass: 'column-xs hide-xs ' +vm.getColumnVisibility('ib'),
                    tooltip: 'backlinkchecker.ib.tooltip'
                },
                {
                    label: 'TB',
                    sortBy: 'groupCount',
                    customClass: 'column-xs ' +vm.getColumnVisibility('tb'),
                    tooltip: 'backlinkchecker.tb.tooltip'
                },
                {
                    emptyState: 'three-lines',
                    label: 'backlinkchecker.anchor.text.and.backlink',
                    customClass: 'm-w text-left ' +vm.getColumnVisibility('anchor-text-and-backlink'),
                },
                {
                    label: 'AS',
                    emptyState: 'square',
                    sortBy: 'rank',
                    customClass: 'column-45px ' +vm.getColumnVisibility('as'),
                    tooltip: 'backlinkchecker.as.tooltip'
                },
                {
                    label: 'backlinkchecker.first.seen',
                    sortBy: 'firstSeen',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('first-seen'),
                    tooltip: 'backlinkchecker.first.seen.tooltip'
                },
                {
                    label: 'backlinkchecker.last.seen',
                    sortBy: 'lastSeen',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('last-seen'),
                    tooltip: 'backlinkchecker.last.seen.tooltip'
                },
                {
                    label: 'backlinkchecker.source.page.size',
                    sortBy: 'pageFromSize',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('page-from-size'),
                    tooltip: 'backlinkchecker.source.page.size.tooltip'
                },
                {
                    label: 'backlinkchecker.source.page.encoding',
                    sortBy: 'pageFromEncoding',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('page-from-encoding'),
                    tooltip: 'backlinkchecker.source.page.encoding.tooltip'
                },
                {
                    label: 'backlinkchecker.source.ip',
                    sortBy: 'domainFromIp',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('domain-from-ip'),
                    tooltip: 'backlinkchecker.source.ip.tooltip'
                },
                {
                    label: 'backlinkchecker.source.http.status.code',
                    sortBy: 'pageFromStatusCode',
                    customClass: 'column-normal hide-xs ' +vm.getColumnVisibility('page-from-status-code'),
                    tooltip: 'backlinkchecker.source.http.status.code.tooltip'
                },
                {
                    label: 'backlinkchecker.source.page.internal.links',
                    sortBy: 'pageFromInternalLinks',
                    customClass: 'column-xs hide-xs ' +vm.getColumnVisibility('page-from-internal-links'),
                    tooltip: 'backlinkchecker.source.page.internal.links.tooltip'
                },
                {
                    label: 'backlinkchecker.source.url.spam.score',
                    sortBy: 'urlToSpamScore',
                    customClass: 'column-xs hide-xs ' +vm.getColumnVisibility('url-to-spam-score'),
                    tooltip: 'backlinkchecker.source.url.spam.score.tooltip'
                },

            ];

        }

        vm.searchInTable = function () {
            vm.results.rows = $filter("filter")(vm.resultRowsCopy, vm.searchQuery);
        };

        vm.onFiltersButtonClick = function() {
            vm.showFilter = !vm.showFilter;
            vm.filtersCopy = vm.filters;
            vm.filtersChanged = false;
        };

        function boldDomainFromUrl(domain, url) {
            return url.replace(domain, '<span class="font-bold">' + domain + '</span>');
        }

        function newAnalysis(offset) {
            firstAnalisys = false;
            if (!vm.domain) {
                return;
            }

            vm.historyMode = false;
            vm.selectedHistoryItem = null;
            vm.showFilter = false;

            $state.transitionTo('app.backlinkchecker.tool', {
                domain: vm.domain,
                filters: JSON.stringify(vm.filters)
            }, {
                notify: false
            });

            vm.rowsSelected = null;
            vm.searchQuery = null;
            vm.progressValue = 0;
            progressValue.style.width = 0 + "%";
            vm.iframeUrl = null;

            if (!offset) {
                vm.results.rows = null;
                vm.exportCsvOptions.buttonDisabled = true;
                vm.copyToClipboardOptions.buttonDisabled = true;
                vm.canShowFavIcon = false;
                vm.customTemplate = null;
                vm.offset = null;
                vm.results.isLoading = true;
            } else {
                vm.offset = vm.resultRowsCopy.length;
                vm.loadMoreBacklinksLoading = true;
            }

            BacklinkCheckerService.getBacklinks(vm.domain, vm.filters, vm.offset, vm.limit)
                .then(function (res) {
                    vm.results.isLoading = false;
                    vm.loadMoreBacklinksLoading = false;

                    if (!res || !res.data || !res.data.rows || res.data.rows.length <= 0) {
                        vm.totalBacklinks = 0;
                        return;
                    }

                    if (offset) {
                        vm.results.rows = vm.resultRowsCopy.concat(res.data.rows);
                        vm.historyId = vm.lastHistoryId;
                        res.data.rows = vm.results.rows;

                        if (userInfo.account === 'sub') {
                            BacklinkCheckerService.updateHistory(vm.historyId, res.data, vm.filters)
                                .then(function (newHistory) {
                                    vm.history = newHistory.data.history;
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            }
                    } else {
                        vm.results.rows = res.data.rows;
                        if (userInfo.account === 'sub') {
                            BacklinkCheckerService.saveInHistory(res.data, vm.filters)
                            .then(function (newHistory) {
                                vm.history = newHistory.data.history;
                                vm.lastHistoryId = newHistory.data.history[0].id;
                            }).catch(function (error) {
                                console.log(error);
                            });
                        }
                    }

                    setQueriesForComponents(vm.results.rows, vm.results.rows);
                    vm.resultRowsCopy = angular.copy(vm.results.rows);
                    vm.totalBacklinks = res.data.totalRows;
                    vm.pageAuthority = res.data.pageAuthority;
                    vm.domainAuthority = res.data.domainAuthority;
                    vm.favIconUrl = 'https://www.google.com/s2/favicons?sz=64&domain=' + vm.domain;

                    updateLimits();

                    if (vm.filters.type === 'all') {
                        TrackEventService.event(
                            'Suite Usages',
                            'Backlink Checker - Analyze', 'All');
                        TrackEventService.gaV2Event(
                            'backlink_analyze',{
                                type: 'All',
                                tool: 'Backlink Checker'
                            });
                    } else if (vm.filters.type === 'lost')  {
                        TrackEventService.event(
                            'Suite Usages',
                            'Backlink Checker - Analyze', 'Lost');
                        TrackEventService.gaV2Event(
                            'backlink_analyze',{
                                type: 'Lost',
                                tool: 'Backlink Checker'
                            });
                    }  else if (vm.filters.type === 'new')  {
                        TrackEventService.event(
                            'Suite Usages',
                            'Backlink Checker - Analyze', 'New');
                        TrackEventService.gaV2Event(
                            'backlink_analyze',{
                                type: 'New',
                                tool: 'Backlink Checker'
                            });
                    }

                }).catch(function (err) {
                    vm.results.isLoading = false;
                    vm.loadMoreBacklinksLoading = false;

                    if (Number(err.status) === 429 || Number(err.status) === 426) {
                        vm.results.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                        vm.results.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                        vm.results.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                        vm.results.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                    }
                });
        }

        vm.countActiveFiltersForHistoryItem = function (filters) {
            if (!filters) {
                return;
            }
            var count = 0;
            if (filters.urlRating && filters.urlRating.min) {
                count += 1;
            }

            if (filters.urlRating && filters.urlRating.max) {
                count += 1;
            }

            if (filters.domainRating && filters.domainRating.min) {
                count += 1;
            }

            if (filters.domainRating && filters.domainRating.max) {
                count += 1;
            }

            if (filters.urlToSpamScore && filters.urlToSpamScore.min) {
                count += 1;
            }

            if (filters.urlToSpamScore && filters.urlToSpamScore.max) {
                count += 1;
            }

            if (filters.firstSeen && filters.firstSeen.from) {
                count += 1;
            }

            if (filters.firstSeen && filters.firstSeen.to) {
                count += 1;
            }

            if (filters.keywordsInAnchor && filters.keywordsInAnchor.length) {
                count += 1;
            }

            if (filters.sourceUrl) {
                count += 1;
            }

            if (filters.targetUrl) {
                count += 1;
            }

            return count;
        };

        vm.timeSince = function (strDate) {
            var date = Date.parse(strDate);
            var seconds = Math.floor((new Date() - date) / 1000);
            var interval = Math.floor(seconds / 31536000);
            if (interval >= 1) return interval + ' ' + $translate.instant('years ago');
            if (interval === 1) return interval + ' ' + $translate.instant('year ago');

            interval = Math.floor(seconds / 2592000);
            if (interval >= 1) return interval + ' ' + $translate.instant('months ago');
            if (interval === 1) return interval + ' ' + $translate.instant('month ago');

            interval = Math.floor(seconds / 86400);
            if (interval >= 1) return interval + ' ' + $translate.instant('days ago');
            if (interval === 1) return interval + ' ' + $translate.instant('day ago');

            interval = Math.floor(seconds / 3600);
            if (interval >= 1) return interval + ' ' + $translate.instant('hours ago');
            if (interval === 1) return interval + ' ' + $translate.instant('hour ago');

            interval = Math.floor(seconds / 60);
            if (interval >= 1) return interval + ' ' + $translate.instant('minutes ago');
            if (interval === 1) return interval + ' ' + $translate.instant('minutes ago');

            if(seconds === 1) return interval + ' ' + $translate.instant('second ago');
            return Math.floor(seconds) + ' ' + $translate.instant('seconds ago');
        };


        vm.loadMoreBacklinks = function () {
            vm.limit = vm.otherBacklinksToUnlock;
            vm.newAnalysis(vm.otherBacklinksToUnlock);
        };

        if ($stateParams.domain) {
            vm.domain = $stateParams.domain;

            //$state.transitionTo('app.backlinkchecker.tool', {domain: vm.domain, filters: JSON.stringify(vm.filters)} , {notify: false});
            $timeout(function () {
                if ($stateParams.filters) {
                    vm.filters = JSON.parse($stateParams.filters);
                    vm.currentActiveTab = vm.filters.type === 'lost' ? 2 : (vm.filters.type === 'new' ? 1 : 0);
                    vm.applyFilters();

                } else {
                    vm.newAnalysis();
                }

            }, 600);
            return;
        }


        //TODO: change urls when the knowledgebase of backlink checker is online


        init();

    }

    Controller.$inject = ['$scope', '$state', 'UserService', '$sce', 'stAlertLimitService', 'stLimitMessage', 'userInfo', 'ExportService', '$translate', '__env', 'BacklinkCheckerService',
        '$stateParams', 'TrackEventService', 'FavIconService', '$timeout', '$filter', '$mdMedia', 'LanguageService', '$window'
    ];
    return Controller;
});

/**
 * @ngdoc overview
 * @name plan.service:PlanService
 */
'use strict';
define(['app'], function (app) {
    app.service('PlanService', PlanService);
    PlanService.$inject = ['$http', '__env'];

    function PlanService($http, __env) {
        return {
            findOne: findOne
        };

        function findOne(params) {
            params.alias = params.alias || '';
            params.alias = params.alias.substr(0, 128);
            return $http.get(__env.apiGateway + '/api/v1/plan/available/' + params.alias);
        }
    }
});

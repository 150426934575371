/**
 * @ngdoc overview
 * @name checkout.service:CheckoutService
 */
'use strict';
define(['app'], function (app) {
    app.service('CheckoutService', CheckoutService);
    CheckoutService.$inject = ['$http', '__env', 'sessionHistoryCache', '$window'];

    function CheckoutService($http, __env, sessionHistoryCache, $window) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + 'checkout';

        return {
            getCheckoutSession: getCheckoutSession,
            checkTrial: checkTrial,
            checkCoupon: checkCoupon,
            saveCouponOnHistory: saveCouponOnHistory,
            getCustomerPortal: getCustomerPortal,
        };

        function getCheckoutSession(params) {
            sessionHistoryCache.removeAll();
            return $http.post(apiPath + '/stripe/session', params);
        }

        function checkTrial(userId, alias) {
            return $http.get(apiPath + '/trial', {
                params: {
                    userId: userId,
                    alias: alias,
                }
            });
        }

        function checkCoupon(params) {
            return $http.get(apiPath + '/coupon', {params: params});
        }

        function getCustomerPortal() {
            return $http.get(apiPath + '/customer-portal');
        }

        function saveCouponOnHistory(coupon) {
            if (coupon) {
                var redeemedCouponHistory = $window.localStorage.getItem('redeemedCouponHistory');
                if (redeemedCouponHistory && typeof redeemedCouponHistory === 'string') {
                    redeemedCouponHistory = JSON.parse(redeemedCouponHistory);
                }

                if (!redeemedCouponHistory || typeof redeemedCouponHistory !== 'object') {
                    redeemedCouponHistory = {};
                }
                redeemedCouponHistory[coupon] = true;
                $window.localStorage.setItem('redeemedCouponHistory', JSON.stringify(redeemedCouponHistory));
            }
        }
    }
});

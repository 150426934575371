'use strict';
define(['angular'], function (angular) {
    return {
        templateUrl: 'app/components/report-pdf-button/report-pdf-button.html',
        bindings: {
            configuration: '=',
            disable: '=',
            customClass: '<',
            flat: '<'
        },
        controller: ['$scope', '$mdDialog',
            function ($scope, $mdDialog) {
                var ctrl = this;

                $scope.$watchCollection('$ctrl.configuration', function (configuration) {
                    ctrl.configuration = configuration;
                });

                $scope.$watch('$ctrl.disable', function (disable) {
                    ctrl.badUrl = disable;
                });

                $scope.$watch('$ctrl.customClass', function (customClass) {
                    if (customClass) {
                        ctrl.customClass = customClass;
                    } else {
                        ctrl.customClass = 'md-raised st-marine-azure md-fab-button m-r-lg floating-fab-right md-button';
                    }
                });

                $scope.showReportDialog = function (configuration, ev) {
                    $mdDialog.show({
                        controller: dialogController,
                        templateUrl: 'app/components/report-pdf-button/report-dialog.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        preserveScope: true,
                        scope: this,
                        bindToController: true,
                        clickOutsideToClose: true,
                        multiple: true,
                        locals: {
                            configuration: configuration,
                        }
                    });
                };
                function dialogController($scope, $rootScope, $mdDialog, configuration, ReportPdfService, $timeout, $interval, AuthService, TrackEventService) {
                    var reportId;
                    var loadPromise;

                    $scope.processingReportValue = 1;
                    $scope.medias = {
                        logo: {}
                    };
                    $scope.isActive = isActive;
                    $scope.setPdfTypeId = setPdfTypeId;
                    $scope.processingReportBufferValue = 1.5;
                    $scope.pdfLink = false;
                    $scope.pdfFailed = false;
                    $scope.pdf = {
                        pdfTypeId: 2,
                    };
                    $scope.notauth = !AuthService.isAuthenticated();

                    function setPdfTypeId(id) {
                        if (id == 1) {
                            $scope.configuration.config.default = true;
                        } else {
                            $scope.configuration.config.default = false;
                        }
                        $scope.pdf.pdfTypeId = id;
                    }

                    function isActive(id) {
                        return $scope.pdf.pdfTypeId == id;
                    }

                    $scope.configuration = configuration;
                    $scope.configuration.config.default = false;

                    $scope.step = 'choose';
                    $scope.checkCategory = false;
                    $scope.options = {
                        categories: {
                            base: 'on',
                            structure: 'on',
                            content: 'on',
                            social: 'on',
                            performanceWeb: 'on',
                            performanceMobile: 'on',
                            duplicated: 'on'
                        },
                        score: 'on',
                        preview: 'on',
                        suggests: 'on',
                        problems: 'on'
                    };
                    $scope.invalidImage = false;
                    $scope.noteTooMuchLong = false;

                    $scope.setStep = function (step) {
                        $scope.step = step;
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $rootScope.$on('$stateChangeStart', function () {
                        if (angular.isDefined(loadPromise)) {
                            $timeout.cancel(loadPromise);
                            loadPromise = undefined;
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if (angular.isDefined(loadPromise)) {
                            $timeout.cancel(loadPromise);
                            loadPromise = undefined;
                        }
                    });

                    $scope.$watchCollection('options.categories', function (categories) {
                        var checkOff = 0;
                        for (var category in categories) {
                            if (categories[category] === 'off') {
                                checkOff++;
                            }
                        }
                        $scope.checkCategory = checkOff > 4;
                    });

                    $scope.noteCheck = function () {
                        if ($scope.configuration.config.params.note && $scope.configuration.config.params.note.length) {
                            $scope.noteTooMuchLong = $scope.configuration.config.params.note.length > 2048;
                            return;
                        }

                        $scope.noteTooMuchLong = false;
                    };

                    $scope.goReport = function () {
                        $scope.step = 'processing';
                        $scope.processingReportValue = 1;
                        $scope.processingReportBufferValue = 1.5;
                        $scope.pdfLink = false;
                        $scope.pdfFailed = false;

                        if ($scope.configuration.config.default) {
                            $scope.configuration.config.params.categories = ReportPdfService.getCategories({
                                type: $scope.configuration.type || 'seo_checker',
                                score: true,
                                preview: true,
                                suggests: true,
                                problems: true
                            });
                            $scope.configuration.config.params.info = null;
                            $scope.configuration.config.params.note = null;
                            $scope.configuration.config.params.color = null;
                            $scope.configuration.config.params.logo = null;
                        } else {
                            $scope.configuration.config.params.logo = $scope.medias.logo.id ? $scope.medias.logo.id : null;
                            $scope.configuration.config.params.categories = setCategories($scope.configuration.config.params.categories, $scope.options, $scope.configuration.type);
                        }

                        ReportPdfService.post($scope.configuration)
                            .then(function (res) {
                                reportId = res.data.id;
                                nextLoad(2500);
                            })
                            .catch(function (err) {
                                $scope.processingReportBufferValue = 100;
                                $scope.processingReportValue = 100;
                                $scope.pdfFailed = true;
                            });
                        $interval(function () {
                            if ($scope.processingReportValue < 100) {
                                $scope.processingReportValue += 1;
                                $scope.processingReportBufferValue += 1.5;
                            }
                        }, 1000, 40);
                    };

                    var getReport = function () {
                        ReportPdfService.get({
                                id: reportId
                            })
                            .then(function (res) {
                                if (res.data.status === 'finished') {
                                    $scope.processingReportBufferValue = 100;
                                    $scope.processingReportValue = 100;

                                    if ($scope.configuration.type === 'seo_checker') {
                                        if ($scope.configuration.config.default) {
                                            TrackEventService.event(
                                                'Suite Usages',
                                                'SEO Report - Export', 'SEO Checker - Standard');
                                            TrackEventService.gaV2Event(
                                                'report', {
                                                    type: "Standard",
                                                    tool: "SEO Checker"
                                                });
                                        } else {
                                            TrackEventService.event(
                                                'Suite Usages',
                                                'SEO Report - Export', 'SEO Checker - White Label');
                                            TrackEventService.gaV2Event(
                                                'report', {
                                                    type: "White Label",
                                                    tool: "SEO Checker"
                                                });
                                        }
                                    } else {
                                        if ($scope.configuration.config.default) {
                                            TrackEventService.event(
                                                'Suite Usages',
                                                'SEO Report - Export', 'SEO Spider - Standard');
                                            TrackEventService.gaV2Event(
                                                'report', {
                                                    type: "Standard",
                                                    tool: "SEO Spider"
                                                });
                                        } else {
                                            TrackEventService.event(
                                                'Suite Usages',
                                                'SEO Report - Export', 'SEO Spider - White Label');
                                            TrackEventService.gaV2Event(
                                                'report', {
                                                    type: "White Label",
                                                    tool: "SEO Spider"
                                                });
                                        }
                                    }

                                    $timeout(function () {
                                        $scope.pdfLink = res.data.resourceUrl;
                                    }, 1000);
                                } else {
                                    if ($scope.processingReportBufferValue < 100) {
                                        $scope.processingReportBufferValue = $scope.processingReportBufferValue + 1.5;
                                    }
                                    nextLoad(2500);
                                }
                            }, function (err) {
                                console.log(err);
                                $scope.processingReportBufferValue = 100;
                                $scope.processingReportValue = 100;
                                $scope.pdfFailed = true;
                                cancelNextLoad();
                            });
                    };

                    var cancelNextLoad = function () {
                        $timeout.cancel(loadPromise);
                    };

                    var nextLoad = function (delay) {
                        if ($scope.processingReportValue < 100) {
                            $scope.processingReportValue = $scope.processingReportValue + 1;
                        }
                        //Always make sure the last timeout is cleared before starting a new one
                        cancelNextLoad();
                        loadPromise = $timeout(getReport, delay);
                    };

                    var setCategories = function (categories, options, type) {
                        categories = ReportPdfService.getCategories({
                            type: type,
                            score: (options.score == 'on' ? true : false),
                            preview: (options.preview == 'on' ? true : false),
                            suggests: (options.suggests == 'on' ? true : false),
                            problems: (options.problems == 'on' ? true : false),
                        });

                        switch (type) {
                            case 'seo_checker':
                                if (options.categories.base == 'off') {
                                    delete categories.base;
                                }
                                if (options.categories.content == 'off') {
                                    delete categories.content;
                                }
                                if (options.categories.performanceWeb == 'off') {
                                    delete categories.performanceWeb;
                                }
                                if (options.categories.performanceMobile == 'off') {
                                    delete categories.performanceMobile;
                                }
                                if (options.categories.social == 'off') {
                                    delete categories.social;
                                }
                                break;
                            case 'seo_spider':
                                if (options.categories.base == 'off') {
                                    delete categories.base;
                                }
                                if (options.categories.content == 'off') {
                                    delete categories.content;
                                }
                                if (options.categories.structure == 'off') {
                                    delete categories.structure;
                                }
                                if (options.categories.duplicated == 'off') {
                                    delete categories.duplicated;
                                }
                                if (options.categories.social == 'off') {
                                    delete categories.social;
                                }
                                break;
                        }
                        return categories;
                    };
                }

                dialogController.$inject = ['$scope', '$rootScope', '$mdDialog', 'configuration', 'ReportPdfService', '$timeout', '$interval', 'AuthService',
                    'TrackEventService'
                ];
            }
        ]
    };
});

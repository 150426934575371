'use strict';
define([], function () {
    function controller($scope, $element, $mdMedia) {
        var ctrl = this;
        $scope.$mdMedia = $mdMedia;
        $scope.getCurrentStep = getCurrentStep;
        
        
        ctrl.$onInit = function() {
            ctrl.fixedOnTop = $element[0].hasAttribute("fixed-on-top");
            ctrl.steps = this.steps;
            ctrl.currentStep = this.currentStep;
        }

        function getCurrentStep(currentStep) {
            return ctrl.steps.filter(function(step){
                return step.stepNumber == currentStep;
            })[0];
        }
    }

    controller.$inject = ['$scope', '$element', '$mdMedia'];

    return {
        templateUrl: 'app/components/st-step-navbar/st-step-navbar.html',
        bindings: {
            onCreation: '=',
            fixedOnTop: '<',
            steps: '<',
            currentStep: '<'
        },
        controller: controller,
    }
});

'use strict';
define([], function () {
    

    return {
        templateUrl: 'app/modules/seochecker/components/top-relevant-keywords/top-relevant-keywords.html',
        bindings: {
            loading: '<',
            overview: '<'
        },
        controller: ['$scope', function ($scope) {
            var vm = $scope;

            this.$onChanges = function () { 
                vm.loading = this.loading;
                
                if (this.overview && this.overview.relevantKeywords && this.overview.relevantKeywords.length >= 5) {
                    vm.relevantKeywords = this.overview.relevantKeywords.slice(0, 5);
                }
                
            };
        }]
    };
});

'use strict';
define(['angular', 'app'], function (angular, app) {
    app.directive('permissionsAvailable', ['$localStorage', function ($localStorage) {
        function existElmentsArraOneInArrayTwo(permissionsWorkspace, permissionsElement) {
            if (typeof permissionsWorkspace === 'string') {
                permissionsWorkspace = JSON.parse(permissionsWorkspace);
            }

            if (Array.isArray(permissionsWorkspace)) {
                return permissionsWorkspace.some(function (r) {
                    return permissionsElement.indexOf(r) >= 0;
                })
            }
        }

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$watch(attrs.permissionsAvailable, function (value) {
                    if (!$localStorage.currentUser || !$localStorage.currentUser.workspace || !$localStorage.currentUser.workspace.permissions) {
                        return;
                    }

                    var permissions = $localStorage.currentUser.workspace.permissions;

                    if (!permissions && $localStorage.currentUser.workspace.roleName === 'owner') {
                        return;
                    }

                    if (!permissions && $localStorage.currentUser.workspace.roleName !== 'owner') {
                        element.hide();
                        return;
                    }

                    if (Array.isArray(value.permission) && value.permission.length && existElmentsArraOneInArrayTwo(permissions, value.permission)) {
                        return;
                    }

                    if (!permissions || !value || !value.permission || permissions.indexOf(value.permission) >= 0) {
                        return;
                    }

                    if (value.actions === 'disabled') {
                        element.attr('ng-disabled', true);
                        element.attr('disabled', 'disabled');
                        element.addClass('div-disabled');
                        element.on('click', function (e) {
                            e.preventDefault();
                        });
                    }

                    if (value.actions === 'hide') {
                        element.hide();
                    }

                    if (value.actions === 'remove') {
                        element.remove();
                    }
                });

            }
        };
    }]);
});

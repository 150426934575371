'use strict';
define(['angular', 'jquery'], function (ng, $) {
    /* If you want clean some angular or html attributes from the embed html put it here */
    var ATTRIBUTES_TO_CLEAN = 'ng-if ng-repeat ng-model layout flex layout-align aria-invalid translate translate-values ng-style ng-switch ng-switch-when ng-switch-default';
    /* If you want clean css class from the embed html put it here */
    var CLASS_TO_CLEAN =
        'no-background-add no-background-add-active ng-animate no-background-remove no-background-remove-active ng-pristine ng-untouched layout-align-center-center ng-valid ng-valid-email ng-invalid ng-invalid-required ng-valid-required ng-empty layout-row layout-column';
    /* All the exam category of report lead magnet */
    var SEO_CHECKER_EXAMS = {
        base: ['serp', 'title', 'description', 'h1', 'h2', 'https', 'urlSeoFriendly', 'metaRobots', 'robots', 'sitemap'],
        content: ['uniqueKeywords', 'titleCoherence', 'h1Coherence', 'ratioTextCode', 'pageSize', 'altInTagImage', 'titleInTagA', 'microdata'],
        speed: ['desktop', 'mobile'],
        social: ['openGraph', 'twitterCard', 'socialPlugin', 'fbPopularity'],
        performanceWeb: ['largest-contentful-paint', 'interactive', 'first-contentful-paint', 'total-blocking-time', 'cumulative-layout-shift', 'speed-index'],
        performanceMobile: ['largest-contentful-paint', 'interactive', 'first-contentful-paint', 'total-blocking-time', 'cumulative-layout-shift', 'speed-index'],
    };

    return {
        templateUrl: 'app/modules/leadgeneration/components/lead-gen-form-editing/lead-gen-form-editing.html',
        bindings: {
            whitelabel: '<',
            form: '<',
            model: '='
        },
        controller: ['__env', '$q', '$mdMedia', '$scope', '$timeout', '$state', '$stateParams', '$mdDialog', 'ReportPdfService', 'LeadGenerationService', 'MediaService', '$translate',
            '$compile', '$mdToast', '$window', 'LanguageService',
            function (__env, $q, $mdMedia, $scope, $timeout, $state, $stateParams, $mdDialog, ReportPdfService, LeadGenerationService, MediaService, $translate, $compile, $mdToast,
                $window, LanguageService) {

                var currentLangCode;
                var savedForm = {};
                var form = {};
                var ctrl = this;

                var vm = $scope;
                vm.addCustomField = addCustomField;
                vm.editCustomField = editCustomField;
                vm.allLanguages = $translate.getAvailableLanguageKeys();

                vm.saveForm = saveForm;

                vm.toggleToFormField = toggleToFormField;
                vm.findField = findField;
                vm.fieldIsChecked = fieldIsChecked;
                vm.openDialogEmbedCode = openDialogEmbedCode;
                vm.copiedCode = copiedCode;
                vm.embedCode = null;
                vm.$mdMedia = $mdMedia;

                vm.imagePresent = false;
                vm.showRenameForm = false;
                vm.logoLoading = false;
                vm.saveChangeToast = false;
                vm.medias = {
                    logo: {}
                };

                vm.checkAll = {
                    base: true,
                    content: true,
                    speed: true,
                    social: true
                };

                vm.$on("languageChanged", function () {
                    vm.placeholderEditor = $translate.instant('add.notes');
                });

                vm.editor = {
                    config: {
                        svgPath: 'assets/img/editor-icons.svg',
                        tagsToKeep: ['strong', 'h1', 'h2', 'h3', 'h4', 'img', 'b', 'i', 'u', 'ul', 'li', 'ol', 'li', 'p', 'span'],
                        tagsToRemove: ['script', 'video', 'link', 'iframe'],
                        resetCss: true,
                        semantic: true,
                        removeformatPasted: true,
                        imageWidthModalEdit: true,
                        btns: [
                            ['formatting'],
                            ['undo', 'redo'],
                            ['strong', 'em', 'del'],
                            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                            ['unorderedList', 'orderedList'],
                            ['fullscreen']
                        ]
                    },
                    snippets: false,
                    show: {
                        keywordResearchToolbar: true,
                        optimizationToolbar: true
                    }
                };


                ctrl.$onInit = function () {
                    currentLangCode = $translate.instant('currentLangCode') || 'en';
                    savedForm = ctrl.form;
                    form = ctrl.form;
                    vm.whitelabel = ctrl.whitelabel || false;
                    vm.savedForm = savedForm || {};
                    vm.form = form || {};
                    _construct();

                    vm.$watchCollection('form.config', function (form) {
                        $timeout(generateEmbeddableCode, 100);
                        saveForm();
                    });

                    vm.$watchCollection('form.config.fields', function (form) {
                        $timeout(generateEmbeddableCode, 100);
                    });

                    vm.$watchCollection('form.config.gdpr', function () {
                        $timeout(generateEmbeddableCode, 100);
                    });

                    vm.$watchCollection('form.config.color', function () {
                        $timeout(generateEmbeddableCode, 100);
                    });

                    $timeout(generateEmbeddableCode, 100);
                    autosavePooling();
                    //setModel();
                    ctrl.model = vm.form || {};
                };

                var currentPoolingSessionSaveData;
                var currentPoolingSessionSaveDataAborted = false;

                function autosavePooling() {
                    if (currentPoolingSessionSaveDataAborted) {
                        return;
                    }
                    ctrl.model = vm.form || {};
                    var dfd = $q.defer();
                    saveForm().then(function () {
                        dfd.resolve(true);
                        currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
                    }).catch(function () {
                        dfd.resolve(true);
                        currentPoolingSessionSaveData = $timeout(autosavePooling, 100);
                    });
                    return dfd.promise;
                }

                var SDKJSEndpoint = __env.leadGenerationSDKJS;

                function _construct() {
                    vm.defaultFields = JSON.parse(JSON.stringify(defaultFields));

                    vm.fields = vm.form && vm.form.config && vm.form.config.fields ? vm.form.config.fields : vm.defaultFields;

                    vm.form = {
                        config: vm.savedForm.config || {
                            title: $translate.instant("leadgeneration.seo.analysis"),
                            subtitle: $translate.instant("leadgeneration.get.report"),
                            callToAction: $translate.instant("Analyze"),
                            fields: vm.fields,
                            events: {
                                redirectAfterConversion: {
                                    enabled: true,
                                    url: null
                                }
                            },
                            gdpr: {
                                enabled: false,
                                termsAndConditionsUrl: null,
                                privacyPolicyUrl: null,
                            },
                            color: {
                                title: '#130D41',
                                subtitle: '#596679',
                                textCTA: '#ffffff',
                                backgroundColorCta: '#ff8800',
                                backgroundHidden: false,
                                backgroundColor: '#ffffff',
                            },
                            lang: currentLangCode || 'en' || null
                        },
                        magnet: vm.savedForm.magnet || {
                            type: "report",
                            config: {
                                params: {
                                    logo: null,
                                    locale: 'en',
                                    categories: getDefaultCategoriesObject(true, true),
                                    exams: {
                                        score: true,
                                        suggests: true,
                                        problems: true
                                    }
                                },
                                default: false,
                                version: "1.0"
                            }
                        },
                        code: {
                            head: null,
                            body: null,
                            footer: null
                        }
                    };

                    vm.embedLocale = LanguageService.getLocaleFromLanguageCode(vm.form.config.lang);
                    vm.magnetLocale = LanguageService.getLocaleFromLanguageCode(vm.form.magnet.config.params.locale);

                    if (savedForm.magnet && savedForm.magnet.config && savedForm.magnet.config.params && savedForm.magnet.config.params.logo) {
                        vm.logoLoading = true;
                        MediaService.findOne({
                                id: savedForm.magnet.config.params.logo
                            })
                            .then(function (res) {
                                vm.logoLoading = false;
                                vm.medias.logo = res.data;
                            })
                            .catch(function (err) {
                                vm.logoLoading = false;
                            });
                    }

                }

                function copiedCode(code) {
                    vm[code] = true;
                    $timeout(function () {
                        vm[code] = false;
                    }, 2000);
                }

                function getDefaultCategoriesObject(initValue, isReportObject) {
                    var categories = {};
                    var tmpExamId;
                    for (var category in SEO_CHECKER_EXAMS) {
                        categories[category] = {};
                        if (isReportObject) {
                            categories[category] = {
                                exams: {},
                                score: true
                            };
                        }

                        for (var i in SEO_CHECKER_EXAMS[category]) {
                            tmpExamId = SEO_CHECKER_EXAMS[category][i];
                            if (!isReportObject) {
                                categories[category][tmpExamId] = initValue;
                            } else {
                                categories[category].exams[tmpExamId] = {
                                    score: initValue,
                                    preview: true,
                                    problems: initValue,
                                    suggests: initValue
                                };
                            }
                        }
                    }

                    if (isReportObject) {
                        delete categories['speed'];
                        categories.performanceWeb = {
                            score: initValue,
                            problems: initValue,
                            exams: categories.performanceWeb.exams
                        };

                        categories.performanceMobile = {
                            score: initValue,
                            problems: initValue,
                            exams: categories.performanceMobile.exams
                        };
                    }

                    return categories;
                }


                /*
                function resetCategories(isNotInit) {
                    var basicDefaultCategories = getDefaultCategoriesObject(isNotInit);
                    if (isNotInit == false) {
                        setDefaultCategory(basicDefaultCategories, 'base');
                        setDefaultCategory(basicDefaultCategories, 'content');
                        setDefaultCategory(basicDefaultCategories, 'social');
                        if (vm.savedForm.magnet.config.params.categories.performanceMobile)
                            basicDefaultCategories.speed.mobile = true;
                        if (vm.savedForm.magnet.config.params.categories.performanceWeb)
                            basicDefaultCategories.speed.desktop = true;
                    }
                    vm.categories = basicDefaultCategories;
                }


                function setDefaultCategory(basicDefaultCategories, category) {
                    var thisCategory = vm.savedForm.magnet.config.params.categories[category];
                    if (thisCategory) {
                        for (var examId in thisCategory.exams) {
                            basicDefaultCategories[category][examId] = true;
                        }
                    }
                }
                */

                var defaultFields = [{
                    id: 'leadWebsite',
                    name: "website",
                    title: 'Website',
                    type: "url",
                    inputType: "input",
                    placeholder: $translate.instant("leadgeneration.website.url"),
                    deletable: false,
                    required: true,
                    show: true,
                    value: null
                }, {
                    id: 'leadEmail',
                    name: "email",
                    title: 'Email',
                    type: "email",
                    inputType: "input",
                    placeholder: $translate.instant("leadgeneration.email.eg"),
                    deletable: false,
                    required: true,
                    show: true,
                    value: null
                }];

                function openDialogEmbedCode(ev) {
                    saveForm();
                    $mdToast.hide();
                    $mdDialog.show({
                        controller: 'leadGenerationDialogGetEmbedCodeCtrl',
                        templateUrl: "app/modules/leadgeneration/components/dialogs/get-embed-code/get-embed-code.html",
                        parent: $(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {
                            embeddableCode: vm.form.code,
                        }
                    }).then(function (newEmbedData) {
                        vm.saveChangeToast = false;
                        $window.location.hash = '';
                    }, function (err) {
                        vm.saveChangeToast = false;
                        $window.location.hash = '';
                    });
                }

                function generateScriptCode() {
                    return '<script id="st-embeddable-script" >(function(d, s, id,w,fId,tId){' +
                        'w._stoEmbed={formId:fId,formTypeId:tId};' +
                        'var js, fjs = d.getElementsByTagName(s)[0];' +
                        'if (d.getElementById(id)) {return;}' +
                        'js = d.createElement(s); js.id = id;' +
                        'js.src = "{{SDKJSEndpoint}}?v={{versionCacheHash}}";' +
                        'fjs.parentNode.insertBefore(js, fjs);' +
                        '}(document, \'script\', \'sto-jssdk\',window,' + savedForm.id + ',' + savedForm.formTypeId + '));</script>';
                }

                function generateEmbeddableCode() {
                    var versionCacheHash = generateRandomHash(12);
                    var embedCode = $('#editor-embeddable-html').clone(true);
                    var clonedHtmlEmbed = cleanNgAttributes(embedCode);
                    var clonedHtmlScript = '\n<script>' + cleanNgAttributes(generateScriptCode()) + '</script>\n';
                    clonedHtmlScript = clonedHtmlScript.replace('{{versionCacheHash}}', versionCacheHash);
                    clonedHtmlScript = clonedHtmlScript.replace('{{SDKJSEndpoint}}', SDKJSEndpoint);
                    vm.form.code.head = alignCode(clonedHtmlScript);
                    vm.form.code.body = alignCode(clonedHtmlEmbed);

                    vm.embedCode = vm.form.code.body + vm.form.code.head;
                }

                vm.switchLangForm = function (lang) {
                    vm.embedLocale = lang;
                    vm.form.config.lang = LanguageService.getLanguageCodeFromLocale(lang);
                };

                vm.switchLangMagnet = function (lang) {
                    vm.magnetLocale = lang;
                    vm.form.magnet.config.params.locale = LanguageService.getLanguageCodeFromLocale(lang);
                };

                var lastsavedForm = null;

                function saveForm() {
                    var dfd = $q.defer();
                    vm.form.config.lang = LanguageService.getLanguageCodeFromLocale(vm.embedLocale);
                    vm.form.magnet.config.params.locale = LanguageService.getLanguageCodeFromLocale(vm.magnetLocale);

                    var formToSave = {
                        id: Number($stateParams.formId),
                        name: savedForm.name,
                        config: vm.form.config,
                        magnet: vm.form.magnet
                    };

                    if (lastsavedForm === JSON.stringify(formToSave)) {
                        $timeout(function () {
                            dfd.resolve(true);
                        }, 1000);
                        return dfd.promise;
                    }

                    generateEmbeddableCode();

                    console.log('We are updating your embed data...');
                    lastsavedForm = JSON.stringify(formToSave);
                    LeadGenerationService
                        .updateForm(formToSave)
                        .then(function (res) {
                            dfd.resolve(res);
                        }).catch(function (err) {
                            dfd.resolve(err);
                        });
                    return dfd.promise;
                }

                function alignCode(code) {
                    return code.trim().replace(/ {1,}/gi, ' ').replace(/\n\s{1,}/gi, '\n').replace(/<!---->/gi, '')
                }

                function cleanNgAttributes(element) {
                    $(element).find('*').removeAttr(ATTRIBUTES_TO_CLEAN);
                    $(element).find('*').removeClass(CLASS_TO_CLEAN);
                    $(element).contents().filter(function () {
                        return this.nodeType == 8;
                    }).remove();
                    return $(element).html();
                }

                /*
                function disableField(i) {
                    try {
                        vm.defaultFields[i].show = false;
                        vm.form.config.fields.splice(i, 1);
                    } catch (err) {}
                }
                */

                function toggleToFormField(clickedField) {
                    if (!vm.form&&!vm.form.config&&!vm.form.config.fields) {
                        return;
                    }
                    var clickedFieldIndex = vm.form.config.fields.indexOf(clickedField);
                    if (clickedFieldIndex <= 0) {
                        return;
                    }

                    if (!clickedField.show) {
                        $timeout(function () {
                            moveItem(clickedFieldIndex, vm.form.config.fields.length - 1);
                        }, 120);

                    } else if (clickedField.show) {

                        for (var i = 0; i < vm.form.config.fields.length - 1; i++) {
                            if (vm.form.config.fields[i].show && !vm.form.config.fields[i + 1].show) {
                                if (clickedFieldIndex !== i) {
                                    $timeout(function () {
                                        moveItem(clickedFieldIndex, i + 1);
                                    }, 120);
                                    break;
                                }
                            }
                        }

                    }

                    generateEmbeddableCode();
                }

                function moveItem(from, to) {
                    // remove `from` item and store it
                    var f = vm.form.config.fields.splice(from, 1)[0];
                    // insert stored item into position `to`
                    vm.form.config.fields.splice(to, 0, f);
                }

                function findField(field, fields) {
                    for (var i = 0; i < fields.length - 1; i++) {
                        if (fields[i].id == field.id)
                            return i;
                    }
                    return -1;
                }

                function fieldIsChecked(field) {
                    return findField(field, vm.form.config.fields) != 1;
                }

                function generateRandomHash(length) {
                    var result = '';
                    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    var charactersLength = characters.length;
                    for (var i = 0; i < length; i++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }
                    return result;
                }


                /*
                function toggleAll(section) {
                    Object.keys(vm.categories[section]).forEach(function (key) {
                        for (key in vm.categories[section])
                            vm.categories[section][key] = !vm.checkAll[section];
                    });
                }


                function transformCategoryInReportObject(categories, categoryId) {
                    var tmp = 0;
                    Object.keys(vm.categories[categoryId]).forEach(function (key) {
                        if (vm.categories[categoryId][key] == false) {
                            delete categories[categoryId].exams[key];
                            tmp++;
                        }
                    });
                    if (Object.keys(vm.categories[categoryId]).length == tmp)
                        delete categories[categoryId];
                }


                function getMagnetCategories() {
                    var tmp = 0;
                    var categories = ReportPdfService.getCategories({
                        type: 'seo_checker',
                        score: vm.form.magnet.config.params.exams.score,
                        preview: true,
                        suggests: vm.form.magnet.config.params.exams.suggests,
                        problems: vm.form.magnet.config.params.exams.problems,
                    });
                    transformCategoryInReportObject(categories, 'base');
                    transformCategoryInReportObject(categories, 'content');
                    transformCategoryInReportObject(categories, 'social');

                    if (vm.categories.speed.desktop == false) {
                        delete categories.performanceWeb;
                    }
                    if (vm.categories.speed.mobile == false) {
                        delete categories.performanceMobile;
                    }
                    return categories;
                }

                function isChecked(section) {
                    var isCheked = false;
                    Object.values(vm.categories[section]).forEach(function (values) {
                        if (values == true) {
                            isCheked = true;
                            return;
                        }
                    });
                    return isCheked;
                }
                */

                vm.renameDialog = function (form, ev) {
                    $mdDialog.show({
                        controller: 'leadGenerationDialogRenameEmbedCtrl',
                        templateUrl: "app/modules/leadgeneration/components/dialogs/rename-embed/rename-embed.html",
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        parent: $(document.body),
                        locals: {
                            form: savedForm
                        }
                    }).then(function (answer) {
                        saveForm();
                    }, function () {
                        $scope.status = 'You renamed the dialog.';
                    });
                };

                ctrl.$onDestroy = function () {
                    currentPoolingSessionSaveDataAborted = true;
                    $timeout.cancel(currentPoolingSessionSaveData);
                };

                function addCustomField(ev) {
                    $mdDialog.show({
                        templateUrl: "app/modules/leadgeneration/components/dialogs/add-custom-field/add-custom-field.html",
                        controller: "leadGenerationAddCustomFieldDialogCtrl",
                        parent: $(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {
                            currentFields: vm.fields,
                            on_complete: function (new_custom_field) {
                                if (new_custom_field) {
                                    vm.fields.push(new_custom_field);
                                    vm.form.config.fields = vm.fields;
                                }

                            }
                        }
                    });
                }

                function editCustomField(field, ev) {
                    $mdDialog.show({
                        templateUrl: "app/modules/leadgeneration/components/dialogs/edit-custom-field/edit-custom-field.html",
                        controller: "leadGenerationEditCustomFieldDialogCtrl",
                        parent: $(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {
                            fieldToEdit: field,
                            currentFields: vm.fields,
                            on_complete: function (data) {
                                if (!vm.fields || !data) {
                                    return;
                                }
                                var oldFieldPosition = vm.fields.indexOf(field);

                                if (data && data.action == 'edit') {
                                    //remove the old field..
                                    vm.fields.splice(oldFieldPosition, 1);
                                    //..and change with the edited field
                                    vm.fields.splice(oldFieldPosition, 0, data.fieldToEdit);
                                    vm.form.config.fields = vm.fields;
                                } else if (data && data.action == 'delete') {
                                    //remove the field..
                                    vm.fields.splice(oldFieldPosition, 1);
                                    vm.form.config.fields = vm.fields;
                                }
                            }
                        }
                    });
                }

                // LEFT MENU MANAGEMENT

                vm.fieldsMenuOpenedStatus = {
                    'fields-menu': true,
                    'design-menu': false,
                    'gdpr-menu': false,
                    'othersections-menu': false
                };


                vm.toggleMenu = function (menuClass) {
                    if (vm.fieldsMenuOpenedStatus[menuClass]) {
                        $('.' + menuClass + '-content').css('display', 'none');
                        $('.' + menuClass).removeClass('is-opened');
                        vm.fieldsMenuOpenedStatus[menuClass] = false;
                    } else {
                        $('.' + menuClass + '-content').css('display', 'block');
                        $('.' + menuClass).addClass('is-opened');
                        vm.fieldsMenuOpenedStatus[menuClass] = true;
                    }
                }

                vm.getFieldIconByFieldType = function (fieldType) {
                    switch (fieldType) {
                        case 'url': {
                            return 'link';
                        }
                        case 'text': {
                            return 'short_text';
                        }
                        case 'textarea': {
                            return 'notes';
                        }
                        case 'email': {
                            return 'email';
                        }
                        case 'normal': {
                            return 'arrow_drop_down_circle';
                        }
                        case 'multiple': {
                            return 'check_box';
                        }
                        case 'hidden': {
                            return 'visibility_off'
                        }
                    }
                }
            }
        ]
    };
});

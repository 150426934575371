'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/backlink-checker/components/mini-doughnut/mini-doughnut.html',
        bindings: {
            customClass: "<",
            currentValue: "<",
            maximumValue: "<",
            isLoading: "<",
            showPercent: "<",
            fillColor: "@",
        },
        controller: ['$scope', function ($scope) {
            var vm = $scope;
        var $this = this;
        $this.$onInit=function(){
            vm.customClass=$this.customClass;
        };
        vm.$watch('$ctrl.customClass', function (customClass) {
            vm.customClass = customClass;
        });
        $this.$onChanges = function () {

            if (!this.currentValue) {
                this.currentValue = 0;
            }

            this.currentValue = (this.currentValue > this.maximumValue) ? this.maximumValue : this.currentValue;

            if (this.currentValue >0) {
                $scope.fillColor = "#" + this.fillColor;
            }

            $scope.circularProgressBarData = {
                percentage: Math.floor(this.currentValue / this.maximumValue * 100),
            };

            var fillCircularProgressBar = function (percentageToFill) {
                var chartData = [];
                var chartColors = [];
                var percentageToNotFill = 100 - percentageToFill;

                chartData.push(percentageToFill);
                chartColors.push($scope.fillColor);

                chartData.push(percentageToNotFill);
                chartColors.push("#EAEBED");

                $scope.chartData = chartData;
                $scope.chartColors = chartColors;
            };

            vm.chartOpts={
                responsive: false,
                aspectRatio: 1,
                maintainAspectRatio: true,
                cutoutPercentage: 65,
                legend: {
                    display: false,
                },
                hover: {
                    mode: null
                },
                tooltips:{
                    enabled:false
                },
                elements: {
                    arc: {
                        width: 20,
                        borderWidth: 0
                    }
                }
            };

            fillCircularProgressBar($scope.circularProgressBarData.percentage);
        };

        }]
    };
});


define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state('app', {
                abstract: true,
                controller: 'TemplateCtrl',
                resolve: {
                    isTrialing: ['$stateParams', '$state', 'UserService', 'AuthService', function ($stateParams, $state, UserService, AuthService) {
                        if (!AuthService.isAuthenticated()) return true;
                        return UserService.limits(true)
                            .then(function (res) {
                                return res.data.canDoTrial;
                            }).catch(function () {
                                return false;
                            });
                    }],
                    currentLangCode: ['$translate', function ($translate) {
                        return $translate('currentLangCode');
                    }],
                    currentUser: ['AuthService', function (AuthService) {
                        return AuthService.getSessionUser();
                    }],
                    userInfo: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits()
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                    return null;
                                }

                                return res.data;
                            })
                            .catch(function (err) {
                                console.log(err);
                                return null;
                            });
                    }],
                },
                templateUrl: './app/modules/template/template.view.html'
            })
            .state('404', {
                templateUrl: './app/modules/template/404.html',
            });
    }

    config.$inject = ['$stateProvider', '$urlRouterProvider'];
    return config;
});

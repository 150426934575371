/**
 * @ngdoc overview
 * @name keywordexplorer.service:SerpCheckerService
 */
'use strict';

define(['app'], function (app) {
    var DEFAULT_DEVICE_ID = 1; // Desktop
    var DEFAULT_SEARCH_ENGINE_ID = 1; // Google
    var DEFAULT_COUNTRY_CODE = 'US'; // United States
    var SEARCH_ENGINES_AVAILABLE = [
        {
            id: 1,
            label: 'Google',
            icon: 'google',
        },
        {
            id: 2,
            label: 'Yahoo',
            icon: 'yahoo',
        },
        {
            id: 3,
            label: 'Yandex',
            icon: 'yandex',
        },
        {
            id: 4,
            label: 'Bing',
            icon: 'bing',
        }
    ];
    var DEVICES_AVAILABLE = [
        {
            id: 1,
            label: 'Desktop',
            icon: 'icon-desktop',
        },
        {
            id: 2,
            label: 'Mobile',
            icon: 'icon-mobile',
        }
    ];
    var BLANK_AUDIT_SCORE = {
        base: {
            score: null
        },
        social: {
            score: null
        },
        content: {
            score: null
        },
        mobile: {
            score: null
        },
        desktop: {
            score: null
        },
        finalScore: null
    };
    app.service('SerpCheckerService', SerpCheckerService);
    SerpCheckerService.$inject = ['API', '__env', '$http', '$q', 'sessionHistoryCache', '$timeout', 'SeocheckerService'];

    function SerpCheckerService(API, __env, $http, $q, sessionHistoryCache, $timeout, SEOChecker) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.serpCheckerAPI;
        var apiKeywordExplorerPath = apiBaseUrl + __env.keywordExplorerAPI;
        return {
            config: {
                devicesAvailable: DEVICES_AVAILABLE,
                searchEnginesAvailable: SEARCH_ENGINES_AVAILABLE,
                defaultDeviceId: DEFAULT_DEVICE_ID,
                defaultSearchEngineId: DEFAULT_SEARCH_ENGINE_ID,
                defaultCountryCode: DEFAULT_COUNTRY_CODE
            },
            castQueryToAPIFormat: castQueryToAPIFormat,
            analyzeQueryBacklink: getSERP,
            analyzeBacklinkFromURLs: analyzeBacklinkFromURLs,
            analyzeCopywritingFromURLs: analyzeCopywritingFromURLs,
            analyzeCopywriting: analyzeCopywriting,
            getSERP: getSERP,
            getDeviceIdFromLabel:getDeviceIdFromLabel
        };

        function getDeviceIdFromLabel(label){
            for(let i=0;i<DEVICES_AVAILABLE.length;i++){
                if((DEVICES_AVAILABLE[i].label.toLowerCase()===label.toLowerCase().trim())){
                    return DEVICES_AVAILABLE[i].id;
                }
            }
            return DEFAULT_DEVICE_ID;
        }
        function castQueryToAPIFormat(params) {
            return cloneObject({
                mode: params.mode,
                keyword: params.keyword,
                filters: {
                    searchEngineId: params.searchEngineId,
                    deviceId: params.deviceId,
                    countryCode: params.countryCode
                },
                benchmark: params.benchmark
            });
        }

        function validateParams(params) {
            return {
                keyword: params.keyword,
                filters: {
                    deviceId: parseInt(params.filters.deviceId) || DEFAULT_DEVICE_ID,
                    searchEngineId: parseInt(params.filters.searchEngineId) || DEFAULT_SEARCH_ENGINE_ID,
                    countryCode: params.filters.countryCode || DEFAULT_COUNTRY_CODE
                }
            };
        }

        function getSERP(params, apiRequestConfig = {}) {
            var apiRequestUrl = apiKeywordExplorerPath + '/serp';
            if (!params.keyword) {
                return $q.reject({
                    code: 'NO_EMPTY_KEYWORD_ALLOWED',
                    message: 'Keyword must be filled'
                });
            }

            params = validateParams(params);

            return API.post(apiRequestUrl, params, false, true, true)
                .then(function (res) {
                    setDataAboutSerpRelAttributes(res.data);
                    // sessionHistoryCache.put(cacheId, res.data);
                    /* TODO: per la cache ho utilizzato il FLAG cacheRequest=TRUE del servizio API,
                        dovrebbe funzionare lo stesso, altrimenti ripristinare la riga commentata e
                        ripristinare anche i controlli sulla cache che c'erano prima */
                    return $q.resolve(res.data)
                });
        }

        function analyzeCopywriting(params, apiRequestConfig = {}) {
            if (!params.keyword)
                return $q.reject({
                    code: 'NO_EMPTY_KEYWORD_ALLOWED',
                    message: 'Keyword must be filled'
                });

            params = validateParams(params);
            var apiRequestUrl = apiPath + '/serp-copywriting-audit';
            var cacheId = setJSONParamsInURL('serp-copywriting-audit', params);
            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached) {
                return $timeout(function () {
                    return $q.resolve(existCached);
                }, 1000);
            }
            return $http.post(apiRequestUrl, params)
                .then(function (res) {
                    sessionHistoryCache.put(cacheId, res.data);
                    return res.data;
                });
        }

        function analyzeCopywritingFromURLs(params, apiRequestConfig = {}) {
            if (!params.urls || !params.urls.length) {
                return $q.resolve({
                    data: {rows: []},
                    message: 'success'
                });
            }
            var useCachedSERP = false;
            if (params.forceStatisticsUsingCachedSERP) {
                useCachedSERP = cloneObject(params.forceStatisticsUsingCachedSERP);
                delete params.forceStatisticsUsingCachedSERP;
            }
            var apiRequestUrl = apiPath + '/urls-copywriting-audit';
            var cacheId = setJSONParamsInURL('urls-copywriting-audit', params);
            var existCached = sessionHistoryCache.get(cacheId);

            /* if cache exist, use the cached results in browser history */
            if (existCached && existCached.rows && existCached.rows.length) {
                return $timeout(function () {
                    if (useCachedSERP)
                        existCached = updateDataWithCachedSERP(existCached, useCachedSERP);
                    return $q.resolve(existCached);
                }, 1000);
            }
            return $http.post(apiRequestUrl, params).then(function (res) {
                var isDataNotVoid = res.data && res.data.rows && res.data.rows.length;
                if (isDataNotVoid)
                    sessionHistoryCache.put(cacheId, res.data);
                if (useCachedSERP)
                    return updateDataWithCachedSERP(res.data, useCachedSERP);
                return res.data;
            });
        }

        function analyzeBacklinkFromURLs(params = {}, apiRequestConfig = {}) {
            if (!params.urls || !params.urls.length) {
                return $q.resolve({
                    data: {rows: []},
                    message: 'success'
                });
            }
            var useCachedSERP = false;
            if (params.forceStatisticsUsingCachedSERP) {
                useCachedSERP = cloneObject(params.forceStatisticsUsingCachedSERP);
                delete params.forceStatisticsUsingCachedSERP;
            }
            var apiRequestUrl = apiPath + '/urls-analysis';
            var cacheId = setJSONParamsInURL('urls-analysis', params);
            var existCached = sessionHistoryCache.get(cacheId);

            /* if cache exist, use the cached results in browser history */
            if (existCached && existCached.rows && existCached.rows.length) {
                return $timeout(function () {
                    if (useCachedSERP)
                        existCached = updateDataWithCachedSERP(existCached, useCachedSERP);
                    return $q.resolve(existCached);
                }, 1000);
            }
            return $http.post(apiRequestUrl, params).then(function (res) {
                var isDataNotVoid = res.data && res.data.rows && res.data.rows.length;
                if (isDataNotVoid)
                    sessionHistoryCache.put(cacheId, res.data);
                if (useCachedSERP)
                    return updateDataWithCachedSERP(res.data, useCachedSERP);
                return res.data;
            });
        }

        function setJSONParamsInURL(apiRequestUrl, apiParams) {
            return apiRequestUrl + '/' + JSON.stringify(apiParams);
        }

        function updateDataWithCachedSERP(benchmark, cachedSerp) {
            var benchmarkResults = benchmark.data.rows;
            for (var i = 0; i < benchmarkResults.length; i++) {
                for (var j = 0; j < cachedSerp.length; j++) {
                    var isInSERP = cachedSerp[j].resultType === 'organic' &&
                        cleanUrl(cachedSerp[j].link.url) === cleanUrl(benchmarkResults[i].link.url);
                    if (isInSERP) {
                        benchmarkResults[i] = cloneObject(cachedSerp[j]);
                        break;
                    }
                }
            }
            return benchmark;
        }

        function setDataAboutSerpRelAttributes(apiResults) {
            var rows = apiResults.data.rows;
            var avgRefDomains = 0;
            var doFollow = 0;
            var noFollow = 0;
            for (var i = 0; i < rows.length; i++) {
                avgRefDomains += rows[i].extraParams.refdomains || 0;
                noFollow += rows[i].extraParams.nofollowBacklinks || 0;
                doFollow += rows[i].extraParams.dofollowBacklinks || 0;
            }
            var totalLink = doFollow + noFollow;
            apiResults.data.details.backlinksTotal = totalLink;
            apiResults.data.details.backlinksNoFollow = noFollow;
            apiResults.data.details.backlinksDoFollow = doFollow;
            apiResults.data.details.avgRefDomains = parseInt(avgRefDomains / rows.length);
        }
    }

    function cleanUrl(url) {
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        url = url.replace('www.', '');
        url = url.replace(/^www\./i, '');
        url = url.replace(/\/$/i, '');
        return url.trim();
    }

    function cloneObject(objToClone) {
        return JSON.parse(JSON.stringify(objToClone));
    }


});

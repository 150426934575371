'use strict';
define([], function () {
    function controller($scope, $mdToast, $state, $translate, KeywordListService, TrackEventService) {
        var ctrl = this;

        $scope.isDisabled = false;

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right'
            });
        }

        function defaultOnCreation(keywordListId) {
            $state.go("app.keywordlistmanager.wizard", {
                keywordListId: keywordListId,
                step: 'choose-keywords'
            });
        }

        function isValidKeywordList(result) {
            return result && result.data && result.data.keywordListId;
        }

        function formatError(result) {
            if(result && result.data && result.data.error && result.data.error.message) {
                return result.data.error.message;
            }

            return 'general.error.standard';
        }

        $scope.createAKeywordList = function () {
            $scope.isDisabled = true;

            KeywordListService.createAKeywordList({name: $scope.keywordListName, toolSourceId: 1})
                .then(function (query) {
                    if (!isValidKeywordList(query)) {
                        $scope.isDisabled = false;
                        showCustomToast('general.error.standard', 'icon-x st-red');
                        return;
                    }
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - List Created');
                    TrackEventService.gaV2Event(
                        'list_create',{
                            tool: "Keyword List Manager"
                        });

                    var keywordListId = query.data.keywordListId;

                    if (ctrl && ctrl.onCreation && typeof ctrl.onCreation === 'function') {
                        ctrl.onCreation(keywordListId);
                        return;
                    }

                    defaultOnCreation(keywordListId);
                })
                .catch(function (err) {
                    $scope.isDisabled = false;
                    console.log(err);
                    showCustomToast(formatError(err), 'icon-x st-red');
                });
        };
    }

    controller.$inject = ['$scope', '$mdToast', '$state', '$translate', 'KeywordListService', 'TrackEventService'];

    return {
        templateUrl: 'app/modules/keywordlistmanager/components/input-list-name/input-list-name.html',
        bindings: {
            onCreation: '=',
        },
        controller: controller,
    };
});

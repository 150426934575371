'use strict';
define(['d3'], function(d3) {
    function TaxonomiesCtrl($scope,$window,taxonomies) {

        var listTaxonomies=arrayCast(taxonomies.data);
        var preparedTaxonomies=prepareTaxonomies(listTaxonomies);
        var mytree=createTree(preparedTaxonomies);
        var rootTree={name:'/',children:mytree};
        var rep=listTaxonomies.length/37;
        $scope.tree={width:900,height:$window.outerHeight*rep};
        $scope.taxonomyLoading=false;
        asyncLoadNodeGraph();
        function asyncLoadNodeGraph(){
            try{
                if(d3!=undefined)
                    initNodeGraph(
                        $scope.tree.width,
                        $scope.tree.height,
                        rootTree
                    );
            }
            catch(err){
                $scope.taxonomyLoading=true;
                setTimeout(asyncLoadNodeGraph,100);
            }
        }
        function initNodeGraph(w,h,rootTree){
            $scope.taxonomyLoading=false;
            var cluster = d3.layout.cluster()
                .size([h, w - 160]);
            var diagonal = d3.svg.diagonal()
                .projection(function(d) { return [d.y, d.x]; });
            var vis = d3.select("#taxonomies-tree").append("svg:svg")
                .attr("width", w)
                .attr("height", h)
                .append("svg:g")
                .attr("transform", "translate(40, 0)");
            var nodes = cluster.nodes(rootTree);
            var link = vis.selectAll("path.link")
                .data(cluster.links(nodes))
                .enter().append("svg:path")
                .attr("class", "link")
                .attr("d", diagonal);
            var node = vis.selectAll("g.node")
                .data(nodes)
                .enter().append("svg:g")
                .attr("class", "node")
                .attr("transform", function(d) { return "translate(" + d.y + "," + d.x + ")"; })
            node.append("svg:circle")
                .attr("r", 4.5);
            node.append("svg:text")
                .attr("dx", function(d) { return d.children ? -8 : 8; })
                .attr("dy", 3)
                .attr("text-anchor", function(d) { return d.children ? "end" : "start"; })
                .text(function(d) { return d.name; });

        }
        function arrayCast(taxonomies){
            var organizedTaxonomies=[];
            for(var i=0;i<taxonomies.length;i++)
                organizedTaxonomies.push(taxonomies[i].taxonomy);
            return organizedTaxonomies;
        }
        function prepareTaxonomies(taxonomiesList){
            var preparedArray=[];
            for(var i=0;i<taxonomiesList.length;i++){
                taxonomiesList[i]=taxonomiesList[i].replace(/^\/|\/$/g,'');
                taxonomiesList[i]=taxonomiesList[i].replace(/\s{1,}/g,'');
                var prepared=taxonomiesList[i].split('/');
                for(var j=0;j<prepared.length;j++){
                    if(prepared[i]=='')
                        prepared.splice(i,1);
                }
                if(prepared.length)
                    preparedArray.push(prepared);
            }
            return preparedArray;
        }
        function createTree(taxonomiesMap){
             var tree=[];
             for(var i=0;i<taxonomiesMap.length;i++)
                 navigateTree(tree,taxonomiesMap[i]);
             return tree;
        }
        function navigateTree(root,toInsert,_currentDepth){
            if(root==undefined)root=[];
            if(_currentDepth==undefined)
                _currentDepth=0;
            var founded=findInRoot(root,toInsert[_currentDepth]);
            if(founded==-1){
                founded=0;
                root.push({
                    name: toInsert[_currentDepth],
                    children:[]
                });
            }
            if(_currentDepth>=toInsert.length-1)
                return;
            else{
                _currentDepth++;
                return navigateTree(root[founded].children,toInsert,_currentDepth);
            }
        }

        function findInRoot(root,needle){
            if(root===undefined) return -1;
            if(!root.length) return -1;
            if(root[0]===undefined) return -1;
            for(var i=0;i<root.length;i++)
                if(root[i].name==needle)
                    return i;
            return -1;
        }
    }
    TaxonomiesCtrl.$inject=['$scope','$window','taxonomies'];
    return TaxonomiesCtrl;
});

'use strict';
define(['app'], function (app) {
    app.component('stSelectCountry', {
        templateUrl: 'app/components/st-select-country/st-select-country.html',
        controller: controller,
        bindings: {
            country: "=",
            selectClass: '@',
            disabledGlobal: "<",
            disabledCountries: "<",
            simple: "<",
        }
    });
    controller.$inject = ['$scope', 'CountriesService', 'LanguageService'];

    function controller($scope, Countries, LanguageService) {
        var ctrl = this;
        var DEFAULT_COUNTRY = LanguageService.getCountryCodeSaveOnStorage();

        var vm = $scope;
        vm.countries = Countries.all();

        vm.filterCountries = filterCountries;

        vm.$watch('$ctrl.country', function (country) {
            ctrl.country = country ? country : DEFAULT_COUNTRY;
            searchCountry(ctrl.country);
        });

        vm.$watchCollection('$ctrl.disabledCountries', function (disabledCountries) {
            ctrl.disabledCountries = disabledCountries;
            vm.disabledCountries = disabledCountries;
        });

        vm.$watch('$ctrl.disabledGlobal', function (disabledGlobal) {
            ctrl.disabledGlobal = disabledGlobal;
        });

        function searchCountry(countryCode) {
            if (countryCode === 'GBL' && !ctrl.disabledGlobal) {
                vm.selectedCountry = {
                    name: 'Global',
                    code: 'GBL'
                };
                return;
            }
            if (countryCode === 'GBL' && ctrl.disabledGlobal) {
                countryCode = DEFAULT_COUNTRY;
            }

            vm.countries.forEach(function (country) {
                if (country.code === countryCode) {
                    vm.selectedCountry = {
                        name: country.name,
                        code: country.code
                    };
                }
            });
        }

        vm.switchCountry = function (country) {
            if (country === 'global') {
                vm.selectedCountry = {
                    name: 'Global',
                    code: 'GBL'
                };
                ctrl.country = 'GBL';
            } else {
                vm.selectedCountry = {
                    name: country.name,
                    code: country.code
                };
                ctrl.country = country.code;
            }
        };

        function filterCountries($event, value) {
            var countryResearched = value.toUpperCase();
            var countries = document.getElementsByClassName("country-item");
            for (var i = 0, j = countries.length; i < j; i += 1) {
                var txtValue = countries[i].textContent || countries[i].innerText;
                if (txtValue.toUpperCase().indexOf(countryResearched) > -1) {
                    countries[i].style.display = "";
                } else {
                    countries[i].style.display = "none";
                }
            }
        }

        vm.isDisabledCountry = function (country) {
            return vm.disabledCountries &&
                vm.disabledCountries instanceof Array &&
                vm.disabledCountries.indexOf(country);
        };

    }
});

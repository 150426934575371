'use strict';
define(['app'], function (app) {
    app.component('stSelectPhonePrefix', {
        templateUrl: 'app/components/st-select-phone-prefix/st-select-phone-prefix.html',
        controller: controller,
        bindings: {
            prefix: "=",
        }
    });
    controller.$inject = ['$scope', 'CountriesService', 'LanguageService'];

    function controller($scope, Countries, LanguageService) {
        var ctrl = this;
    
        var vm = $scope;
        //remove duplicate prefix for CANADA and USA
        vm.countries = Countries.all().filter((function(country){
            return country.name != "Canada";
        }));

        vm.countries.sort(function (a, b) {
            return a.dial_code > b.dial_code ? 1: -1;
        });

        vm.filterCountries = filterCountries;

        vm.$watch('$ctrl.prefix', function (prefix) {
            ctrl.prefix = prefix;
            searchCountry(ctrl.prefix);
        });

        function searchCountry(countryPrefix) {
            vm.countries.forEach(function (country) {
                if (country.dial_code === countryPrefix) {
                    vm.selectedCountry = {
                        name: country.name,
                        code: country.code,
                        dial_code: country.dial_code
                    };
                }
            });
        }

        vm.switchCountry = function (country) {
            vm.selectedCountry = {
                name: country.name,
                code: country.code,
                dial_code: country.dial_code
            };
            
            ctrl.prefix = country.dial_code;
        };

        function filterCountries($event, value) {
            var countryResearched = value.toUpperCase();
            var countries = document.getElementsByClassName("country-item");
            for (var i = 0, j = countries.length; i < j; i += 1) {
                var txtValue = countries[i].textContent || countries[i].innerText;
                if (txtValue.toUpperCase().indexOf(countryResearched) > -1) {
                    countries[i].style.display = "";
                } else {
                    countries[i].style.display = "none";
                }
            }
        }

    }
});

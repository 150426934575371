'use strict';
define([], function () {
    controller.$inject = ['$scope', '$element'];

    function controller($scope, $element) {
        var ctrl = this;
        this.$postLink = function () {
            //add event listener to an element
            $element.find('*').on('focus', function (event, obj) {
                $element.addClass('st-search-container-active');
            });
            $element.find('*').on('blur', function (event, obj) {
                $element.removeClass('st-search-container-active');
            });
        };
    };
    return {
        templateUrl: 'app/components/st-search-container/st-search-container.html',
        transclude: true,
        bindings: {},
        controller: controller
    };
});

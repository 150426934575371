'use strict';
define(['app'], function (app) {
    app.service('FavIconService', FavIconService);
    FavIconService.$inject = [];

    function FavIconService() {
        return {
            get: get,
            getDomain: getDomain,
        };

        function get(url) {
            return 'https://www.google.com/s2/favicons?sz=64&domain=' + getDomain(url);
        }

        function getDomain(url) {
            if (url.hasOwnProperty('url')) {
                url = url.url;
            }

            if (!url) {
                return url;
            }

            try {

                if (url.indexOf('http') <= 0) {
                    url = 'https://' + url;
                }

                return new URL(url).hostname;
            } catch (e) {
            }
            return url;

        }
    }
});

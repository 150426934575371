/**
 * @ngdoc overview
 * @name typed.service:TypedService
 */
'use strict';
define(['app', 'typed.js'], function (app, Typed) {
    app.service('TypedService', TypedService);
    TypedService.$inject = [];

    function TypedService() {
        return {
            createTyped: createTyped,
            createTypedNotLoop: createTypedNotLoop
        };

        function create(placeholder, id, loop) {
            return new Typed(id, {
                strings: placeholder,
                backSpeed: 80,
                typeSpeed: 80,
                loop: loop,
                attr: 'placeholder',
                bindInputFocusEvents: true,
            });
        }

        function createTyped(placeholder, id) {
            return create(placeholder, id, true);
        }

        function createTypedNotLoop(placeholder, id) {
            return create(placeholder, id, false);
        }
    }
});

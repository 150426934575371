'use strict';
define([],function(){
    return {
        templateUrl: 'app/components/twittercard-preview/twittercard-preview.html',
        bindings: { debug:'=', title: '=', card: '=',site: '=',image: '=',description:'=' },
        controller:['$scope',function($scope){
            var ctrl=this;
            var host='';
            $scope.$watch('$ctrl.site',function(site){
                if(ctrl.site){
                    ctrl.host=site.replace(/^http[s]{0,1}\:\/\//i,'').replace(/^www\./g,'').replace(/\/.*/g,'');
                    ctrl.site=site;
                }
            });
        }]
    };
});
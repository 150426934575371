'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'Robots',
            categoryState:'app.seospider.exam.analysis.robots',
            fields:[
                {label:'seospider.robots.all',icon:'icon icon-list',uiSref:'.all'},
                {label:'seospider.robots.NoIndex',icon:'icon icon-ban',uiSref:'.noindex'},
                {label:'seospider.robots.Noodp',icon:'icon icon-ban',uiSref:'.noodp'},
                {label:'seospider.robots.Noydir',icon:'icon icon-ban',uiSref:'.noydir'}
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$mdDialog,embeddableCode,$timeout,$window) {
        var vm=$scope;

        $window.location.hash='generate-code';

        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;

        vm.embeddableCode=embeddableCode;
        vm.copiedCode=copiedCode;
        vm.copiedFirstCode=false;
        vm.copiedSecondCode=false;

        function copiedCode(code){
            vm[code]=true;
            $timeout(function(){
                vm[code]=false;
            },2000);
        }

        function hide() {
            $window.location.hash='';
            $mdDialog.hide();
        }
        function cancel() {
            $window.location.hash='';
            $mdDialog.cancel();
        }
        function answer() {
            $window.location.hash='';
        }
    }
    Controller.$inject=['$scope','$mdDialog','embeddableCode','$timeout','$window'];
    return Controller;
});
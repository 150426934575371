'use strict';
define([], function () {
    controller.$inject = ['$scope'];

    function controller($scope) {
        var ctrl = this;
        ctrl.$onInit=function () {
            try{

                ctrl.score=parseInt(ctrl.score.toFixed(0));
            }catch(e){

            }
        };

        var DEFAULT_START_COLOR = {r: 207, g: 257, b: 183};
        var DEFAULT_END_COLOR = {r: 255, g: 141, b: 141};
        $scope.getColor = getColor;
        $scope.$watch('$ctrl.colors', function (colors) {
            ctrl.colors = colors;
        });
        $scope.$watch('$ctrl.score', function (score) {
            ctrl.score = score;
        });

        function getColor(value) {
            return stdClassColor(value);
            //return rgbify(100, 0, value);
        }

        function rgbify(maxval, minval, val, moreisgood) {
            val -= 0;
            if (val < 0) val = 0;
            var intnsty = (val - minval) / (maxval - minval);
            intnsty += 0.16;
            if (intnsty < 0) intnsty = 0;
            var r, g;
            if (moreisgood) {
                if (intnsty > 0.5) {
                    g = 180;
                    r = Math.round(2 * (1 - intnsty) * 225);
                } else {
                    r = 235;
                    g = Math.round(2 * intnsty * 215);
                }

            } else { //lessisgood
                if (intnsty > 0.5) {
                    r = 240;
                    g = Math.round(2 * (1 - intnsty) * 195);
                } else {
                    g = 200;
                    r = Math.round(2 * intnsty * 235);
                }
            }
            return "rgb(" + r.toString() + ", " + g.toString() + ", 60 , 1.0)";
        }

        function stdClassColor(score) {

            if(ctrl.moreIsGood){

                if(score > 89) {
                    return '#1ba005';
                }
                if(score > 69) {
                    return '#aada2b';
                }
                if(score > 49) {
                    return '#ffbe02';
                }
                if(score > 29) {
                    return '#ef7a24';
                }
                if(score > 10) {
                    return '#bd462e';
                }
                return '#c11616';
            }
            if(score > 89) {
                return '#c11616';
            }
            if(score > 69) {
                return '#bd462e';
            }
            if(score > 49) {
                return '#ef7a24';
            }
            if(score > 29) {
                return '#ffbe02';
            }
            if(score > 10) {
                return '#aada2b';
            }
            return '#1ba005';

        }
    }

    return {
        templateUrl: 'app/components/st-badge-score/st-badge-score.html',
        bindings: {
            colors: '<',
            score: '<',
            size: '<',
            moreIsGood:'<'
        },
        controller: controller
    };
});


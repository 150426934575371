'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/languages/languages.controller',
    'modules/seospider/exam/analysis/languages/languages.routes',
    'modules/seospider/exam/analysis/languages/all/all.module',
    'modules/seospider/exam/analysis/languages/missing/missing.module',
    'modules/seospider/exam/analysis/languages/x-default-missing/x-default-missing.module',
],function(angular,LanguagesCtrl,routes){
    var app=angular.module('app.seospider.exam.analysis.languages', [
        'app.seospider.exam.analysis.languages.all',
        'app.seospider.exam.analysis.languages.missing',
        'app.seospider.exam.analysis.languages.noXDefault',
    ]);
    app.controller('SPLanguagesCtrl',LanguagesCtrl);
    app.config(routes);
    return app;
});
'use strict';
define([
    'angular',
    'modules/serpchecker/dialogs/edit-competitors/edit-competitors.controller',
], function (
    angular,
    SerpCheckerEditCompetitorsCtrl,
) {
    var app = angular.module('app.serpchecker.dialogs', []);
    app.controller('SerpCheckerEditCompetitorsCtrl', SerpCheckerEditCompetitorsCtrl);

    return app;
});

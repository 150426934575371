'use strict';
define([], function () {
    function Controller(
        __env,
        $scope,
        $stateParams,
        keywordList,
        $translate,
        AuthService,
        KeywordListService,
        userInfo,
        TrackEventService
    ) {
        $scope.trialPeriodDays = __env.trialPeriodDays || 7;
        $scope.userInfo = userInfo;
        $scope.getAllKeywordList = getAllKeywordList;
        $scope.createAKeywordList = createAKeywordList;
        $scope.addQueriesToKeywordlist = addQueriesToKeywordlist;
        $scope.getKeywordListByQuery = getKeywordListByQuery;
        $scope.formatHistory = formatHistory;
        $scope.sharedKeywordController = {
            formatHistory: formatHistory
        };
        $scope.keywordList = {
            id: keywordList.keywordListId,
            name: keywordList.keywordListName,
            token: keywordList.tokenKeywordList,
            averageCPC: keywordList.averageCPC,
            obscured: keywordList.obscured,
            totalVolume: keywordList.totalVolume,
            averageDifficulty: keywordList.averageDifficulty,
            queries: keywordList.queries.rows,
            limit: 1000
        };
        $scope.keywordListPage = {
            template: 4,
            createAKeywordList: $scope.createAKeywordList,
            addQueriesToKeywordlist: $scope.addQueriesToKeywordlist,
            getAllKeywordList: $scope.getAllKeywordList,
            getKeywordListByQuery: $scope.getKeywordListByQuery,
            buttonShow: true,
            buttonDisabled: false,
            queries: [],
            class: 'st-button__rounded--md--violet',
            keywordUuids: [],
            queriesSelected: [],
            keywordListByQuery: '',
            position: '0 -150'
        };
        $scope.copyToClipboardOptions = {
            class: 'st-button__rounded--md--blue--outline',
            text: $translate.instant('keyword.exp.copy.clipboard'),
            icon: 'icon-copy-filled',
            showToast: true,
            data: [setQueriesForComponents(keywordList.queries.rows)],
            mode: 'rows',
        };

        function formatHistory(history) {
            if (!history) {
                return [];
            }
            var trends = [];
            var formattedTrends = history;
            if (typeof formattedTrends === 'string') {
                formattedTrends = JSON.parse(formattedTrends);
            }

            for (const datetime in formattedTrends) {
                trends.push(formattedTrends[datetime]);
            }

            return '<st-sparkline data="[' + trends + ']"></st-sparkline>';
        }

        function setQueriesForComponents(queries) {
            var queriesName = [];
            queries.forEach(function (query) {
                queriesName.push(query.keywordName);
                $scope.keywordListPage.keywordUuids.push(query.keywordUuid)
            });
            return queriesName;
        }

        function getAllKeywordList() {
            if (!AuthService.isAuthenticated()) return [];
            return KeywordListService.getAllKeywordList().then(function (res) {
                return res;
            });
        }

        function getKeywordListByQuery(keywordUuids) {
            if (!AuthService.isAuthenticated()) return [];
            return KeywordListService.getKeywordListByQuery({
                keywordUuids: keywordUuids
            }).then(function (res) {
                $scope.keywordListPage.keywordListByQuery = res;
                return res;
            });
        }

        function createAKeywordList(name) {
            return KeywordListService.createAKeywordList({
                    name: name,
                    toolSourceId: 1
                })
                .then(function () {
                    TrackEventService.event(
                        'Suite Usages',
                        'Keyword List Manager - List Created');
                    TrackEventService.gaV2Event(
                        'list_create', {
                            tool: "Keyword List Manager"
                        });
                    return getAllKeywordList();
                });
        }

        function addQueriesToKeywordlist(queriesSelected, keywordListId) {
            return KeywordListService.addQueries(keywordListId, queriesSelected)
                .then(function () {
                    return getKeywordListByQuery($scope.keywordListPage.keywordUuids)
                        .then(function () {
                            return getAllKeywordList();
                        })
                        .catch(function (err) {
                            return err;
                        });
                });
        }
    }

    Controller.$inject = [
        '__env',
        '$scope',
        '$stateParams',
        'keywordList',
        '$translate',
        'AuthService',
        'KeywordListService',
        'userInfo',
        'TrackEventService'
    ];

    return Controller;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/snippets/snippets.routes',
    'modules/seospider/exam/analysis/snippets/snippets.controller',
    'modules/seospider/exam/analysis/snippets/all/all.controller',
    'modules/seospider/exam/analysis/snippets/optimized/optimized.controller',
    'modules/seospider/exam/analysis/snippets/not-optimized/not-optimized.controller',
],function(
    angular, 
    Routes, 
    SnippetsCtrl, 
    SnippetsAllCtrl,
    SnippetsOptimizedCtrl,
    SnippetsNotOptimizedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.snippets', []);
    app.config(Routes);
    app.controller('SnippetsCtrl',SnippetsCtrl);
    app.controller('SnippetsAllCtrl',SnippetsAllCtrl);
    app.controller('SnippetsOptimizedCtrl',SnippetsOptimizedCtrl);
    app.controller('SnippetsNotOptimizedCtrl',SnippetsNotOptimizedCtrl);
    return app;
});
'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('stAcceptanceService', stAcceptanceService);
    stAcceptanceService.$inject = ['$mdDialog'];

    function stAcceptanceService($mdDialog) {
        return {
            open: open
        };

        function open() {
            $mdDialog.show({
                controller: DialogController,
                templateUrl: 'app/components/st-acceptance/st-acceptance-dialog.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            })
                .then(function (answer) {

                }, function () {

                });
        }
    }

    function DialogController($scope, $mdDialog, $filter, $window, UserService) {
        $scope.step = 'acceptance';
        $scope.jobsTitle = [
            "Entrepreneur",
            "Digital Strategist",
            "SEO/SEM Specialist",
            "Social Media Manager",
            "Consultant",
            "Copywriter",
            "Data Analyst",
            "Designer",
            "Sales Account",
            "Project Manager",
            "Web Developer",
            "Student",
            "Other"
        ];
        $scope.businessTypes = [
            "Web Agency",
            "E-commerce",
            "Company",
            "Freelance",
            "Consulting",
            "Blog",
            "Non-profit",
            "SaaS",
            "Other"
        ];
        $scope.onboardingResponse = null;
        $scope.user = {
            businessType: null,
            jobTitle: null,
            acceptedReceiveOnboardingEmailAt: null
        };
        $scope.promoCode = '';
        $scope.agree = function () {
            var user = {
                acceptedPrivacyPolicyAt: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss'),
                acceptedCookiePolicyAt: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss'),
                acceptedTermsAndConditionsAt: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss'),
                acceptedDataProcessingAgreementAt: $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss')
            };
            UserService.updateProfile(user)
                .then(function () {
                    $mdDialog.cancel();
                    //$scope.step='info';
                });
        };
        $scope.update = function () {
            UserService.updateProfile($scope.user)
                .then(function () {

                    if ($scope.onboardingResponse == 'yes') {
                        $window.localStorage.setItem('onboardingResponse', 'yes');
                        $mdDialog.cancel();
                    } else {
                        $window.localStorage.setItem('onboardingResponse', 'no');
                        $mdDialog.cancel();
                    }

                });
        };
        $scope.onboarding = function (value) {
            $scope.onboardingResponse = value;
            if (value == "yes") {
                $scope.user.acceptedReceiveOnboardingEmailAt = $filter('date')(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss');
            } else {
                $scope.user.acceptedReceiveOnboardingEmailAt = null;
            }
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.answer = function (answer) {
            $mdDialog.hide(answer);
        };
    }

    DialogController.$inject = ['$scope', '$mdDialog', '$filter', '$window', 'UserService'];
});

import globalLanguages from "../../../../../locales/global-languages.json";

export default {
    templateUrl: 'app/modules/writerassistant/wizard/steps/create/create.html',
    bindings: {
        mode: "=",
        mainKeyword: "=",
        device: "=",
        country: "=",
        language: "=",
        loading: "=",
    },
    controller: ['$scope', function ($scope) {
        $scope.langs = globalLanguages;
    }]
};

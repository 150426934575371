'use strict';
define(['angular'], function(ng) {
    function Controller($scope,$stateParams,$q,results,jobId, Pages, $rootScope, $translate) {
        var vm = $scope;

        Array.prototype.subarray = function(start, end) {
            if (!end) { end = -1; }
            return this.slice(start, this.length + 1 - (end * -1));
        };
        vm.optimizedSnippets = [];

        vm.optimizedSnippets = results.data.filter(function(page){
            return (page && page.title && page.metaDescription && (page.title.length >= 40 ) && (page.title.length <= 69 ) && (page.metaDescription.length > 70 && page.metaDescription.length <= 160 ));
        });

        vm.pages=vm.optimizedSnippets.slice(0,100);
        vm.query='';
        vm.jobId=jobId;
        vm.tabs={
            categoryTitle:'Snippets',
            categoryState:'app.seospider.exam.analysis.snippets',
            fields:[
                {label:'seospider.snippets.all',icon:'icon icon-list', uiSref:'.all'},
                {label:'seospider.snippets.optimized',icon:'icon icon-list', uiSref:'.optimized'},
                {label:'seospider.snippets.not-optimized',icon:'icon icon-list', uiSref:'.not-optimized'},
            ]
        };
        vm.pagination={
            numElements:vm.optimizedSnippets.length,
            offset:0,
            limit:100,
            callback: pagingCallback
        };
        vm.currentSearch={
            placeholder: $translate.instant('seospider.search.snippet'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged',function () {
            vm.currentSearch={
                placeholder: $translate.instant('seospider.search.snippet'),
                callback: searchCallback
            };
        });
        vm.getColorByWordCount=getColorByWordCount;
        vm.getColorByTitleLength=getColorByTitleLength;
        vm.getColorByDescriptionLength=getColorByDescriptionLength;
        vm.getPercentageTitle=getPercentageTitle;
        vm.getPercentageDescription=getPercentageDescription;
        vm.getPercentageWCount=getPercentageWCount;
        vm.isUrlSEOFrienldy=isUrlSEOFrienldy;
        vm.isHttps=isHttps;
        function isHttps(str){
            return str.indexOf("https://") == 0;
        }
        function isUrlSEOFrienldy(url){
            var isSEOFrienldy=new RegExp(/^[a-zA-Z-\/\0-9]+$/gim);
            var action = new URL(url).pathname;
            var isFrienldy=url == undefined || action != undefined || action.length==0 || url.action.match(isSEOFrienldy);
            return isFrienldy;
        }
        function searchCallback(query){
            vm.query=query;
            vm.pagination.offset=0;

            Pages.findAll({
                jobId:$stateParams.sessionId,
                q:query,
                fields:['id','url','metaRobots','status','title','metaDescription','contentType','wordCount'],
                offset:0,
                limit:30
            }).
            then(function(res){
                    vm.pagination.offset=0;

                    vm.pages=res.data.filter(function(page){
                        return (page && page.title && page.metaDescription && (page.title.length >= 40 ) && (page.title.length <= 69 ) && (page.metaDescription.length > 70 && page.metaDescription.length <= 160 ));
                    });
                    vm.pagination.numElements=vm.pages.length;
                },
                function(err){
                    vm.pages=err.data;
                });
        }
        function pagingCallback(limit,offset){
            vm.pages=vm.optimizedSnippets.subarray(offset, limit);
        }
        function getColorByWordCount(wordCount){
            if (wordCount >= 600)
                return 'green-text';
            else if (wordCount >= 400)
                return 'text-darken-1 orange-text';
            else
                return 'red-text';

        }
        function getPercentageTitle(len){
            if (len >70)
                return 100;
            return (len*100)/70;
        }
        function getPercentageDescription(len){
            if (len >160)
                return 100;
            return (len*100)/160;
        }
        function getPercentageWCount(len){
            if (len >600)
                return 100;
            return (len*100)/600;
        }
        function getColorByTitleLength(titleLength){
            if (titleLength <=69&&titleLength >=40)
                return 'green-text';
            if (titleLength >= 30&&titleLength <= 39)
                return 'text-darken-1 orange-text';
            if (titleLength >= 70&&titleLength <= 72)
                return 'text-darken-1 orange-text';
            else return 'red-text';

        }

        function getColorByDescriptionLength(len) {
            len=parseInt(len)||0;
            if (len >70&&len<=160)
                return 'green-text';
            if (len >300)
                return 'red-text';
            if (len >160&&len <=300)
                return 'text-darken-1 orange-text';
            if (len >=20&&len<=70)
                return 'text-darken-1 orange-text';
            return 'red-text';
        }
    }
    Controller.$inject=['$scope','$stateParams','$q','results','jobId','SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

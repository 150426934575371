'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/title/missing/missing.controller',
],function(
    angular,
    TitleMissingCtrl
){
    var app=angular.module('app.seospider.exam.analysis.title.missing', []);
    app.controller("TitleMissingCtrl",TitleMissingCtrl);
    return app;
});
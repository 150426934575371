import angular from 'angular';
import categoryToolbar from 'modules/seospider/exam/analysis/components/category-toolbar/category-toolbar.component';
import stToolbarDetail from 'modules/seospider/exam/analysis/components/st-toolbar-detail/st-toolbar-detail.component';
import exportDialog from 'modules/seospider/exam/analysis/components/export-dialog/export-dialog.component';
import crawlSessionDetail from 'modules/seospider/exam/analysis/components/crawl-session-detail/crawl-session-detail.component';
import analysisRadar from 'modules/seospider/exam/analysis/components/analysis-radar/analysis-radar.component';
import websitePreview from 'modules/seospider/exam/analysis/components/website-preview/website-preview.component';

var app = angular.module('app.seospider.exam.analysis.components', []);
app.component("categoryToolbar", categoryToolbar);
app.component("stToolbarDetail", stToolbarDetail);
app.component("exportDialog", exportDialog);
app.component("crawlSessionDetail", crawlSessionDetail);
app.component("analysisRadar", analysisRadar);
app.component("websitePreview", websitePreview);
export default app;

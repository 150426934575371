define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("searchconsole", {
                abstract: true,
                url: "/search-console",
                templateUrl: './app/modules/searchconsole/searchconsole.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("searchconsole.sites", {
                url: "",
                controller: 'SitesController',
                templateUrl: './app/modules/searchconsole/sites/sites.html',
                resolve: {
                    keywordLimit: [function () {
                        var keywordLimitFromLocalStorage = localStorage.getItem('keywordLimit');

                        if (keywordLimitFromLocalStorage && Number(keywordLimitFromLocalStorage) > 0) {
                            return keywordLimitFromLocalStorage;
                        }

                        return 100;
                    }],
                    listsProperties: ['SearchConsoleService', '$location', '$q', function (SearchConsoleService, $location, $q) {
                        var url = new URL($location.absUrl());
                        var code = url.searchParams.get("code");
                        if (!code) return [];

                        var dfd = $q.defer();

                        SearchConsoleService.getToken(code)
                            .then(function (res) {
                                if (!res
                                    || !res.data
                                    || !res.data.token
                                    || !res.data.token
                                ) {
                                    dfd.resolve([]);
                                    return;
                                }

                                localStorage.setItem('googleSearchConsoleToken', res.data.token);
                                return SearchConsoleService.getSites(res.data.token);
                            })
                            .then(function (res) {
                                if (!res
                                    || !res.data
                                    || !res.data.sites
                                    || !res.data.sites.siteEntry
                                ) {
                                    dfd.resolve([]);
                                    return;
                                }

                                dfd.resolve(res.data.sites.siteEntry);
                            })
                            .catch(function (err) {
                                console.log(err);
                                dfd.resolve([]);
                            });

                        return dfd.promise;
                    }]
                }
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define(['app', 'jquery'], function (app, $) {
    app.service('BetaProgramService', BetaProgramService);
    BetaProgramService.$inject = ['$mdDialog', '$q', 'AuthService', '__env'];

    function BetaProgramService($mdDialog, $q, AuthService, __env) {

        return {
            welcome: welcome,
            startTest: startTest,
        };

        function pleaseSignupBeforeStartTest() {
            var parentEl = angular.element(document.body);
            $mdDialog.show({
                parent: parentEl,
                template: '<md-dialog aria-label="List dialog">' +
                    '  <md-dialog-content class=\"text-left\">' +
                    '<div class="p-xl max-w-xxl" layout="column" layout-align="center center">' +
                    "<i class=\"icon-sf-upgrade st-blue md-display-3\"></i>" +
                    "<div class=\"st-blue md-display-3 m-t-lg\">{{'beta.program.title'|translate}}</div>" +
                    "<p class=\"md-headline st-blue m-b-0\">{{'beta.program.subtitle'|translate}}</p>" +
                    "<p class=\"md-subhead m-b-0 st-grey-3\" ng-bind-html=\"'beta.program.descr1'|translate\"></p>" +
                    "<p class=\"md-subhead m-t st-grey-3\">{{'beta.program.descr2'|translate}}</p>" +
                    "<a ng-click=\"BetaDialogCtrl.closeDialog()\" class=\"max-w-md m-t-lg m-b-lg st-button-rounded m-0 md-raised md-orange st-blue md-button\" ui-sref=\"access.signup\">{{'beta.program.cta'|translate}} <i class=\"m-l material-icons\">arrow_forward</i></a>" +
                    '</div>' +
                    '</md-dialog-content>' +
                    '</md-dialog>',
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    this.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }],
                controllerAs: 'BetaDialogCtrl'
            });
        }

        function startTest() {
            if (__env.stage.indexOf('development') !== -1 && AuthService.isAuthenticated()) {
                var isOnboardingBetaProgramFirstProjectDone = localStorage.getItem('onboarding-beta-tester-seo-project');
                if (!isOnboardingBetaProgramFirstProjectDone || isOnboardingBetaProgramFirstProjectDone === 'false') {
                    var parentEl = angular.element(document.body);
                    $mdDialog.show({
                        parent: parentEl,
                        template: '<md-dialog aria-label="List dialog">' +
                            '<md-dialog-content class=\"text-left\">' +
                            '<div class="p-xl max-w-xxl" layout="column" layout-align="center center">' +
                            "<i class=\"icon-sf-upgrade st-blue md-display-3\"></i>" +
                            "<div class=\"st-blue md-display-3 m-t-lg\">{{'beta.program.title.step2'|translate}}</div>" +
                            "<p class=\"md-headline st-blue m-b-0\">{{'beta.program.subtitle.step2'|translate}}</p>" +
                            "<p class=\"md-subhead m-b-0 st-grey-3 text-center\" ng-bind-html=\"'beta.program.descr.step2'|translate\"></p>" +
                            "<div class=\"text-left m-t\"><md-checkbox ng-model=\"isChecked\" class=\"st-grey-3\">" +
                            "{{'beta.program.disclamer'|translate}}" +
                            "</md-checkbox></div>" +
                            "<a ng-click=\"BetaDialogSEOCtrl.closeDialog()\" class=\"max-w-md m-t-lg m-b-lg st-button-rounded m-0 md-raised md-orange st-blue md-button\" ui-sref=\"app.project.wizard\">{{'beta.program.cta.step2'|translate}} <i class=\"m-l material-icons\">arrow_forward</i></a>" +
                            '</div>' +
                            '</md-dialog-content>' +
                            '</md-dialog>',
                        controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                            $scope.isChecked = false;
                            $scope.$watch("isChecked", function (isChecked) {
                                localStorage.setItem('onboarding-beta-tester-seo-project', isChecked);
                            });
                            this.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }],
                        controllerAs: 'BetaDialogSEOCtrl'
                    });
                    console.log('benvenuto tester alla versione del tracker');
                }
            }
        }

        function welcome() {
            if (__env.stage.indexOf('development') !== -1 && !AuthService.isAuthenticated()) {
                pleaseSignupBeforeStartTest();
                console.log('Benvenuto nel beta program area');
            }
        }

    }
});

'use strict';
define(['app'], function (app) {
    app.component('stSelectCreditCard', {
        templateUrl: 'app/components/st-select-credit-card/st-select-credit-card.html',
        controller: controller,
        bindings: {
            selectedCard: "=",
            cards: "<",
        }
    });
    controller.$inject = ['$scope', 'AuthService', '__env', '$translate', 'UserService'];

    function controller($scope, AuthService, __env, $translate, UserService) {
        var ctrl = this;
        var vm = $scope;
        var sessionUser = AuthService.getSessionUser();
        var apiKey = __env.stripeKey;
        var handler = StripeCheckout.configure({
            key: apiKey,
            image: '/assets/img/brand-icon.svg',
            locale: 'auto',
            token: onToken
        });

        ctrl.$onInit = function () {
            vm.cards = ctrl.cards;
            vm.selectedCard = ctrl.selectedCard;
        }

        ctrl.$onChange = function () {
            vm.cards = ctrl.cards;
            vm.selectedCard = ctrl.selectedCard;
        }

        vm.OpenAddCard = function () {
           $scope.$parent.openStripeDialog();
        };

        function onToken(source) {
            vm.loading = true;
            UserService.addCard({source: source})
                .then(function () {
                    refreshList();
                    vm.selectedCard = source.card;
                    ctrl.selectedCard = vm.selectedCard
                });

        }

        function refreshList() {
            UserService.getCards().then(function (res) {
                vm.cards = res.data.data;
                vm.loading = false;
            }).catch(function (err) {
                console.log(err);
            });
        }

        vm.$watch('$ctrl.selectedCard', function (selectedCard) {
            ctrl.selectedCard = selectedCard;
        });

        vm.switchCard = function (card) {
            vm.selectedCard = card;
            ctrl.selectedCard = vm.selectedCard;
        };

    }
});

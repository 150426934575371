'use strict';
define([], function () {
    controller.$inject = ['$scope', '$mdMenu', '$timeout', '$rootScope', 'LanguageService'];

    function controller($scope, $mdMenu, $timeout, $rootScope, LanguageService) {
        var ctrl = this;
        var keywordListByQuery;
        var countryCode = LanguageService.getCountryCodeSaveOnStorage();;

        ctrl.canCreateAList = true;
        ctrl.options = {};

        var codeErrorLimit = {
            "NO_ENOUGH_CREDITS": {
                "keyword-list-manager-queries": {
                    title: 'keyword.list.component.error.your.list.full',
                    subTitle: 'keyword.list.component.error.excess.queries'
                }
            }
        };

        var codeError = {
            "INTERNAL_SERVER_ERROR": {
                title: 'keyword.list.component.error.title',
                subTitle: 'keyword.list.component.error.subtitle'
            }
        };

        $scope.step = 1;
        $scope.isPresent = false;
        $scope.filterKeywordList = filterKeywordList;
        $scope.setStep = setStep;
        $scope.keywordInList = keywordInList;
        $scope.getKeywordList = getKeywordList;
        $scope.addQueriesToKeywordlist = addQueriesToKeywordlist;
        $scope.createAKeywordList = createAKeywordList;
        $scope.setToggleCallback = setToggleCallback;
        $scope.createAKeywordListAndAddKeyword = createAKeywordListAndAddKeyword;

        $scope.$watch('$ctrl.options', function (options) {
            if (options) {
                ctrl.options = options;
            }
        });

        $scope.$watch('$ctrl.allQueries', function (allQueries) {
            ctrl.allQueries = allQueries;
        });

        $scope.$watch('$ctrl.canCreateAList', function (canCreateAList) {
            if (canCreateAList) {
                ctrl.canCreateAList = canCreateAList;
            }
        });

        $scope.$watch('$ctrl.row', function (row) {
            ctrl.row = row ? row : [];
        });
        $rootScope.$on('getKeywordListByQuery', function (evt, res) {
            keywordListByQuery = res.data;
            localStorage.setItem('keywordListByQuery', JSON.stringify(keywordListByQuery));
            if (!optionsHaveQueries(ctrl)) {
                return;
            }
            keywordInsertAtLeastOnceInAList(ctrl.row.keywordUuid, keywordListByQuery);
        });

        $scope.$watch('$ctrl.queriesPerPage', function (queries) {
            ctrl.queriesPerPage = queries;
            keywordListByQuery = JSON.parse(localStorage.getItem('keywordListByQuery'));
            if (!optionsHaveQueries(ctrl)) {
                return;
            }
            if (!keywordListByQuery) {
                return;
            }
            keywordInsertAtLeastOnceInAList(ctrl.row.keywordUuid, keywordListByQuery);
        });

        function getKeywordList($mdMenu, ev) {
            if (ctrl.options.buttonDisabled) {
                return;
            }

            if (isGlobal(countryCode)) {
                if (ctrl.options && ctrl.options.showCustomToast) {
                    ctrl.options.showCustomToast('keyword.list.component.toast.error.global', 'icon-x st-err');
                }
                return;
            }

            ctrl.options.getAllKeywordList()
                .then(function (res) {
                    $mdMenu.open(ev);
                    $scope.keywordList = res.data.rows;
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        $scope.checkIconIfAddedOnKeywordList = function (index) {
            $scope.addToList = index;
        };

        function createAKeywordListAndAddKeyword(queriesSelected, keywordListName) {
            if (!keywordListName) {
                return;
            }

            ctrl.options.createAKeywordList(keywordListName)
                .then(function (res) {
                    $scope.keywordList = res.data.rows;
                    $scope.keywordList.forEach(function (keywordList) {
                        if (keywordList.keywordListName === keywordListName) {
                            addQueriesToKeywordlist(queriesSelected, keywordList.keywordListId);
                        }
                    });
                })
                .catch(function (err) {
                    err.error = err.error ? err.error : !err.error ? err.data.error : {
                        code: 'success'
                    };
                    if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                        $mdMenu.hide();
                    } else {
                        setErrorTemplate(err);
                    }
                });
        }

        function createAKeywordList(keywordListName) {
            if (!keywordListName) {
                return;
            }

            ctrl.options.createAKeywordList(keywordListName)
                .then(function (res) {
                    $scope.keywordList = res.data.rows;
                })
                .catch(function (err) {
                    err.error = err.error ? err.error : !err.error ? err.data.error : {
                        code: 'success'
                    };
                    if (err.error.code === 'NO_ENOUGH_CREDITS' || err.error.code === 'NOT_IN_SUBSCRIPTION') {
                        $mdMenu.hide();
                    } else {
                        setErrorTemplate(err);
                    }
                });
        }

        function addQueriesToKeywordlist(queriesSelected, keywordListId) {
            $mdMenu.hide();

            ctrl.options.addQueriesToKeywordlist(queriesSelected, keywordListId, ctrl.allQueries)
                .then(function (res) {
                    $timeout(function () {
                        $scope.addToList = null;
                    }, 3000);
                    $scope.keywordList = res.data.rows;
                })
                .catch(function (err) {
                    console.log(err);
                    err.error = err.error ? err.error : !err.error ? err.data.error : {
                        code: 'INTERNAL_SERVER_ERROR'
                    };
                    setErrorLimitTemplate(err);
                    $scope.addToList = null;
                });
        }

        function setToggleCallback(query, keywordListId) {
            if (keywordInList(query[0].keywordUuid, keywordListId)) {
                deleteQueriesToKeywordList(query, keywordListId);
            } else {
                addQueriesToKeywordlist(query, keywordListId);
            }
        }

        function deleteQueriesToKeywordList(queriesSelected, keywordListId) {
            var keywordUuids = [];
            queriesSelected.filter(function (query) {
                keywordUuids.push(query.keywordUuid);
            });
            ctrl.options.deleteQueriesToKeywordList(keywordUuids, keywordListId, ctrl.allQueries)
                .then(function (res) {
                    $scope.keywordList = res.data.rows;
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function setErrorLimitTemplate(errorMessage) {
            $scope.step = 3;
            var codes = Object.keys(codeErrorLimit);
            codes.forEach(function (code) {
                if (errorMessage.error.code === code) {
                    $scope.title = codeErrorLimit[code][errorMessage.error.request.alias].title;
                    var subTitle = codeErrorLimit[code][errorMessage.error.request.alias].subTitle;
                    if (errorMessage.error.excessQueries) {
                        $scope.excessQueries = errorMessage.error.excessQueries;
                    }
                    $scope.subTitle = subTitle;
                } else {
                    $scope.title = codeError['INTERNAL_SERVER_ERROR'].title;
                    $scope.subTitle = codeError['INTERNAL_SERVER_ERROR'].subTitle;
                }
            });
        }

        function setErrorTemplate(errorMessage) {
            $scope.step = 3;
            var codes = Object.keys(codeError);
            codes.forEach(function (code) {
                if (errorMessage.error.code === code) {
                    $scope.title = codeError[code].title;
                    $scope.subTitle = codeError[code].subTitle;
                }
            });
        }

        function keywordInsertAtLeastOnceInAList(keywordUuid, listPerQueries) {
            $scope.isPresent = false;
            listPerQueries.forEach(function (listPerQuery) {
                if (keywordUuid && keywordUuid === listPerQuery.keywordUuid) {
                    $scope.isPresent = true;
                }
            });
        }

        function setStep(step) {
            $scope.step = step;
        }

        function keywordInList(keywordUuid, keywordListId) {
            if (!keywordListByQuery) {
                return;
            }

            for (var i = 0; i < keywordListByQuery.length; i++) {
                var query = keywordListByQuery[i];

                if (!query || !query.keywordUuid || query.keywordUuid !== keywordUuid) {
                    continue;
                }

                var arrayOfKeywordLists = [];
                if (query.keywordLists) {
                    arrayOfKeywordLists = query.keywordLists;

                    if (typeof query.keywordLists === 'string') {
                        arrayOfKeywordLists = JSON.parse(query.keywordLists);
                    }
                }

                for (var j = 0; j < arrayOfKeywordLists.length; j++) {
                    if (arrayOfKeywordLists[j] === keywordListId) {
                        return true;
                    }
                }
            }
        }

        function filterKeywordList($event, value) {
            var keywordResearch = value.toUpperCase();
            var namesKeywordList = document.getElementsByClassName("name-keyword-list");
            for (var i = 0, j = namesKeywordList.length; i < j; i++) {
                var txtValue = namesKeywordList[i].textContent || namesKeywordList[i].innerText;
                if (txtValue.toUpperCase().indexOf(keywordResearch) > -1) {
                    namesKeywordList[i].style.display = "";
                } else {
                    namesKeywordList[i].style.display = "none";
                }
            }
        }

        function optionsHaveQueries(ctrl) {
            return ctrl && ctrl.queriesPerPage && ctrl.queriesPerPage.length > 0;
        }

        function isGlobal(countryCode) {
            return countryCode === 'GBL';
        }
    }

    return {
        templateUrl: 'app/components/st-keyword-list/st-keyword-list.html',
        bindings: {
            options: "<",
            row: "<",
            queriesPerPage: "<",
            allQueries: "<",
            canCreateAList: "<"

        },
        controller: controller
    };
});

/**
 * @ngdoc overview
 * @name googleSuggestionsService.service:GoogleSuggestionsService
 */
'use strict';
define(['app'], function (app) {
    app.service('GoogleSuggestionsService', GoogleSuggestionsService);
    GoogleSuggestionsService.$inject = ['$http', '$timeout', '$sce', '$q', '__env'];

    function GoogleSuggestionsService($http, $timeout, $sce, $q, __env) {
        var countryMainLanguage = {
            'ar': 'es',
            'at': 'de',
            'au': 'en',
            'be': 'nl',
            'br': 'pt',
            'ca': 'en',
            'ch': 'de',
            'cz': 'cs',
            'de': 'de',
            'dk': 'da',
            'es': 'es',
            'fi': 'fi',
            'fr': 'fr',
            'hk': 'zh',
            'hu': 'hu',
            'id': 'id',
            'ie': 'en',
            'il': 'he',
            'in': 'hi',
            'it': 'it',
            'jp': 'ja',
            'mx': 'es',
            'nl': 'nl',
            'no': 'nb',
            'nz': 'en',
            'pl': 'pl',
            'ru': 'ru',
            'se': 'sv',
            'sg': 'zh',
            'sk': 'sk',
            'th': 'th',
            'tr': 'tr',
            'ua': 'uk',
            'uk': 'en',
            'us': 'en',
            'vn': 'vi',
        };

        var listQuestions = {
            'en': ['who', 'what', 'why', 'where', 'when', 'which', 'how', 'can', 'will', 'are'],
            'it': ['chi', 'cosa', 'perchè', 'dove', 'quando', 'quale', 'come', 'sono', 'siamo']
        };

        var listPrepositions = {
            'en': ['for', 'like', 'with', 'near', 'to', 'without', 'is', 'can'],
            'it': ['per', 'come', 'con', 'vicino', 'a', 'senza']
        };
        var listComparisons = {
            'en': ['and', 'or', 'like', 'versus', 'vs'],
            'it': ['e', 'o', 'come', 'contro', 'vs']
        };
        return {
            related: related,
            questions: questions,
            prepositions: prepositions,
            comparisons: comparisons,
            alphabeticals: alphabeticals,
            autocomplete: autocomplete,
            getLanguage: getLanguage,
        };

        function formatResult(result) {
            if (result && result.data && result.data.length > 0) {
                return $q.resolve(result.data[1]);
            }

            return $q.reject(new Error('suggestion invalid result'));
        }

        function getLanguage(countryCode) {
            countryCode = countryCode || 'us';
            return countryMainLanguage[countryCode.toLowerCase()];
        }

        function autocomplete(keyword, language = 'en') {
            var dfd = $q.defer();

            $http.jsonp($sce.trustAsResourceUrl('https://clients1.google.com/complete/search?hl=' +
                language + '&output=firefox&q=' + encodeURIComponent(keyword) + '&ds='), {
                "jsonp": "callback"
            })
                .then(function (result) {
                    return formatResult(result);
                })
                .then(function (formattedResult) {
                    dfd.resolve(formattedResult);
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        }

        function related(params, timeout) {
            validateParams(params);
            return $q(function (resolve) {
                $timeout(function () {
                    var RestAPI;
                    params.origin = params.origin == null ? '' : params.origin.toLowerCase();

                    if (params.country.toLowerCase() === params.origin) {
                        RestAPI = $http.jsonp($sce.trustAsResourceUrl('https://suggestqueries.google.com/complete/search?hl=' +
                            params.lang + '&client=firefox&q=' + encodeURIComponent(params.query)), {
                            "jsonp": "callback"
                        });
                    } else {
                        //fallback that not allow geo-localization
                        RestAPI = $http.get(__env.apiGateway + '/api/v1/keywords/suggestions', {
                            params: {
                                q: params.query + " ",
                                country: params.country
                            }
                        });
                    }

                    RestAPI.then(function (res) {
                        if (params.subject)
                            return resolve({subject: res.data[0], list: res.data[1]});
                        else resolve(res.data[1]);
                    }).catch(function (err) {
                        if (params.subject)
                            return resolve({subject: params.query, list: []});
                        else resolve([]);
                    });
                }, timeout || 0);
            });
        }

        function validateParams(params) {
            if (params.subject == undefined) {
                params.subject = false;
            }
            if (params.lang == undefined) {
                params.lang = 'en';
            }
            if (params.country && params.country.toUpperCase() == 'IT') {
                params.lang = 'it';
            }
        }

        function questions(params) {
            validateParams(params);
            var listPromise = [];

            for (var i = 0; i < listQuestions[params.lang].length; i++) {
                var q = listQuestions[params.lang][i] + ' ' + params.query;
                listPromise.push(this.related({
                    query: q,
                    country: params.country,
                    subject: params.subject,
                    origin: params.origin
                }));
            }

            /** TODO remove this promise.all */
            return Promise.all(listPromise).then(function (res) {
                var result = [];
                if (params.subject) {
                    return res;
                }

                for (var i = 0; i < res.length; i++) {
                    result = result.concat(res[i]);
                }
                return result;
            });

        }

        function prepositions(params) {
            validateParams(params);

            var listPromise = [];
            for (var i = 0; i < listPrepositions[params.lang].length; i++) {
                var q = params.query + ' ' + listPrepositions[params.lang][i] + ' ';
                listPromise.push(this.related({
                    query: q,
                    country: params.country,
                    subject: params.subject,
                    origin: params.origin
                }));
            }
            return Promise.all(listPromise).then(function (res) {
                var result = [];
                if (params.subject == true) {
                    return res;
                } else {
                    for (var i = 0; i < res.length; i++) {
                        result = result.concat(res[i]);
                    }
                    return result;
                }
            });
        }

        function comparisons(params) {
            validateParams(params);

            var listPromise = [];
            for (var i = 0; i < listComparisons[params.lang].length; i++) {
                var q = params.query + ' ' + listComparisons[params.lang][i] + ' ';
                listPromise.push(this.related({
                    query: q,
                    country: params.country,
                    subject: params.subject,
                    origin: params.origin
                }));
            }
            return Promise.all(listPromise).then(function (res) {
                var result = [];
                if (params.subject == true) {
                    return res;
                } else {
                    for (var i = 0; i < res.length; i++) {
                        result = result.concat(res[i]);
                    }
                    return result;
                }
            });
        }

        function alphabeticals(params) {
            validateParams(params);
            var a = 97;
            var listPromise = [];
            for (var i = 0; i < 26; i++) {
                var q = params.query + ' ' + String.fromCharCode(a + i);
                listPromise.push(this.related({
                    query: q,
                    country: params.country,
                    subject: params.subject,
                    origin: params.origin
                }, i));
            }
            return Promise.all(listPromise).then(function (res) {
                var result = [];
                if (params.subject == true) {
                    return res;
                } else {
                    for (var i = 0; i < res.length; i++) {
                        result = result.concat(res[i]);
                    }
                    return result;
                }
            });
        }
    }
});

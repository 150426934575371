/**
 * @ngdoc overview
 * @name coupon.service:CouponService
 */
'use strict';
define(['app'], function (app) {
    app.service('CouponService', CouponService);
    CouponService.$inject = ['$http', '__env'];

    function CouponService($http, __env) {
        return {
            isValid: isValid,
        };

        function isValid(coupon) {
            return $http.post(__env.apiGateway + '/api/v1/coupon/' + coupon)
        }
    }
});

'use strict';
define(['app'], function (app) {
    app.component('stMenuAccordion', {
        templateUrl: 'app/components/st-menu-accordion/st-menu-accordion.html',
        controller: controller,
        bindings: {
          menuItems: '<',
          menuConfig: '<'
        }
    });
    controller.$inject = ['$scope', '$state', '$window'];

    function controller($scope, $state, $window) {
      
      var ctrl = this;
      var vm = $scope;
      vm.current;

      /****
      Questo watcher serve a tenere il menu sincronizzato con la route corrente nel caso in cui il cambiamento di route non sia generato 
      da un click nel menu ma da altre fonti, ad esempio nei settings dell'utente, su Subscriptions c'è un link "Change Payment Method" che porta 
      a Paymenth Methods, oppure se dallo Spider cambio i filtri dei vari esami
      ****/
     
      vm.$watch(function(){
        return $state.$current.name;

            }, function() {
                resetCurrentState();
                autoSelectCurrentState();
      });

      ctrl.$onInit = function () {
        vm.menuItems = ctrl.menuItems;
        vm.menuConfig = ctrl.menuConfig;
        autoSelectCurrentState();
      }

      function autoSelectCurrentState() {
        var submenu = [];
        var thirdLevelMenu = [];
        var j = 0;
        var k = 0;
        for (var i = 0; i < vm.menuItems.length; i++) {
            if (vm.menuItems[i].link && vm.menuItems[i].link.urls.indexOf($state.$current.name) >= 0) {
                vm.menuItems[i].isOpened = true;
                vm.menuItems[i].isActive = true;
                vm.menuItems[i].current = true;
                break;
            }
            submenu = vm.menuItems[i].submenu && vm.menuItems[i].submenu.length
                ? vm.menuItems[i].submenu
                : [];
            for (j = 0; j < submenu.length; j++) {
                if (submenu[j].link && submenu[j].link.urls.indexOf($state.$current.name) >= 0) {
                    submenu[j].isOpened = true;
                    submenu[j].isActive = true;
                    vm.menuItems[i].isOpened = true;
                    break;
                }
                thirdLevelMenu = submenu[j].submenu && submenu[j].submenu.length
                    ? submenu[j].submenu
                    : [];
                for (k = 0; k < thirdLevelMenu.length; k++) {
                    if (thirdLevelMenu[k].link && thirdLevelMenu[k].link.urls.indexOf($state.$current.name) >= 0) {
                        thirdLevelMenu[k].isOpened = true;
                        thirdLevelMenu[k].isActive = true;
                        submenu[j].isOpened = true;
                        vm.menuItems[i].isOpened = true;
                        break;
                    }
                }
            }
          }
        } 

        vm.goTo = function (item, level, ev) {
          if (ev) {
              ev.preventDefault();
              ev.stopPropagation();
          }
          resetCurrentState();
          if(item.isOpened===undefined){
              item.isOpened=false;
          }
          item.isOpened = !item.isOpened;
          if (!item.isOpened&&item.submenu) {
              return;
          }
          if (item.link) {
              item.isActive = true;
              openState(item);
              return;
          }
          if (!item.link && item.submenu && item.submenu.length) {
              var currentItem = item.submenu;
              if (currentItem[0].link) {
                  currentItem[0].isOpened = true;
                  currentItem[0].isActive = true;
                  openState(currentItem[0]);
              }
          }
      };

      function openState(item) {
          if (item.link && item.link.type === 'internal') {
              $state.go(item.link.urls[0]);
          }
          if (item.link && item.link.type === 'external') {
              $window.open(item.link.urls[0], '_blank');
          }
      }

      function resetCurrentState() {
        var submenu = [];
        var thirdLevelMenu = [];
        var j = 0;
        var k = 0;
        for (var i = 0; i < vm.menuItems.length; i++) {
            vm.menuItems[i].isActive = false;
            submenu = vm.menuItems[i].submenu && vm.menuItems[i].submenu.length
                ? vm.menuItems[i].submenu
                : [];
            for (j = 0; j < submenu.length; j++) {
                submenu[j].isActive = false;
                thirdLevelMenu = submenu[j].submenu && submenu[j].submenu.length
                    ? submenu[j].submenu
                    : [];
                for (k = 0; k < thirdLevelMenu.length; k++) {
                    thirdLevelMenu[k].isActive = false;
                }
            }
        }
      }
    }
});

/**
 * @ngdoc overview
 * @name leadgeneration.service:LeadGenerationService
 */
'use strict';
define(['app'], function (app) {
    var DEFAULT_LIMIT = 2000;
    app.service('LeadGenerationService', Service);
    Service.$inject = ['API', '__env'];

    function Service(API, __env) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.leadGenerationAPI;

        return {
            getAllForms: getAllForms,
            createForm: createForm,
            deleteForm: deleteForm,
            updateForm: updateForm,
            getStatistics: getStatistics,
            findLeads: findLeads,
            getSettings: getSettings,
            saveSettings: saveSettings,
            unlockLeads: unlockLeads,
            getLockedLeads: getLockedLeads,
        };

        function unlockLeads(params) {
            return API.put(apiPath + '/form/' + params.formId + '/lead/unlock', params, true);
        }

        function getLockedLeads() {
            return API.get(apiPath + '/form/lead-locked-list', {}, true);
        }

        //NEW
        function getAllForms() {
            return API.get(apiPath + '/form', {}, true);
        }

        function createForm(params) {
            return API.post(apiPath + '/form', params, true);
        }

        function updateForm(params) {
            return API.put(apiPath + '/form', params, true);
        }


        function deleteForm(params) {
            return API.delete(apiPath + '/form/' + params.formId);
        }

        function getStatistics(params) {
            var formId = parseInt(params.formId);
            return API.get(apiPath + '/form/' + formId, {}, true);
        }

        function findLeads(params) {
            var formId = parseInt(params.formId);
            if (params.limit == undefined)
                params.limit = DEFAULT_LIMIT;
            if (params.offset == undefined)
                params.offset = 0;
            return API.get(apiPath + '/form/' + formId + '/lead', {
                params: params,
                ignoreLoadingBar: params.ignoreLoadingBar
            }, true);
        }

        function getSettings(params) {
            var formId = parseInt(params.formId);

            return API.get(apiPath + '/form/' + formId + '/settings', true, true);
        }

        function saveSettings(params) {
            var formId = parseInt(params.formId);

            return API.put(apiPath + '/form/' + formId + '/settings', params, true);
        }

    }
});

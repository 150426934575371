'use strict';
define(['jquery'], function () {
    var DEFAULT_STATE = 'access.signup';
    var DEFAULT_ERROR_REDIRECT_TO = 'app.welcome';
    var FORM_RESET_DELAY = 3000;
    var IS_STATE_IMPLEMENTED = {
        /* add here every new embedded state */
        'access.signin': true,
        'access.signup': true,
        'access.email-verification': true
    };

    return {
        templateUrl: 'app/components/st-auth/st-auth.html',
        bindings: {
            state: '=',
            title: '<',
            hideTitle: '<',
            subtitle: '=',
            customClass: '=',
            useExternalTransitions: '<',
            onAuth: '<',
            redirectTo: '<',
            emailToVerify: '<',
        },
        controller: ['$scope', '$state', '$mdToast', 'UtilitiesService', '$window', 'Facebook', '$mdMedia', 'AuthService', '$timeout', '$translate', '$location',
            function ($scope, $state, $mdToast, UtilitiesService, $window, Facebook, $mdMedia, AuthService, $timeout, $translate, $location) {
                $window.___gcfg = {isSignedOut: true};
                var $this = this;
                var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
                var thisTimeout;
                var vm = $scope;
                var virtualStateParams = {};
                var verificationUrl = $location.$$protocol + '://' + $location.$$host + $location.$$path;

                vm.$mdMedia = $mdMedia;
                vm.emailPattern = UtilitiesService.getEmailRegex();
                vm.loading = false;
                vm.data = {
                    signin: {email: null, pass: null, lang: currentLang},
                    signup: {
                        email: null,
                        pass: null,
                        rePass: null,
                        lang: currentLang,
                        fieldPasswordCurrentType: 'password',
                        verificationUrl: verificationUrl
                    },
                    forgot: {email: null, lang: currentLang},
                    reset: {pass: null, rePass: null, lang: currentLang},
                };

                this.$onInit = function () {
                    /* set default values of component */
                    $this.useExternalTransitions = $this.useExternalTransitions || false;
                    $this.state = $this.state || DEFAULT_STATE;
                    $this.onAuth = $this.onAuth || function () {
                    };
                    $this.redirectTo = $this.redirectTo || false;
                };

                /* generics UX methods */
                vm.stateGo = stateGo;
                /* signup methods */
                vm.signup = signup;
                vm.getPasswordStrength = getPasswordStrength;
                /* signin methods */
                vm.signin = signin;
                /* both signin, signup methods */
                vm.loginFacebook = loginFacebook;
                vm.$on('event:google-plus-signin-success', loginGoogleEvent);

                function onAuthenticationCallback(res) {
                    vm.loading = false;
                    res = unifyPayloadOutput(res);
                    var isUserNotVerified = res && res.data && res.data.user && !AuthService.isVerified();
                    var isUserNotProfiled = res && res.data && res.data.user && !AuthService.isProfiled();

                    if (isUserNotVerified) {
                        $this.emailToVerify = res.data.user.email;
                        stateGo('access.email-verification', {
                            token: btoa(res.data.user.email),
                            redirectTo: $this.redirectTo
                        });
                        return;
                    }

                    if ($this.onAuth && $this.onAuth instanceof Function) {
                        $this.onAuth(res);
                    }

                    if ($this.redirectTo && $this.redirectTo.length) {
                        $state.go($this.redirectTo, {}, {reload: true});
                        return;
                    }

                    if (isUserNotProfiled) {
                        stateGo('access.tailor-made');
                        return;
                    }

                    /* else use the default behaviour */
                    if ($window.localStorage.getItem('coupon') === 'SITEGROUNDDISCOUNT') {
                        $state.go('upgrade.checkout', {alias: 'plan-siteground-v1'});
                        return;
                    }
                    $state.go('app.welcome', {}, {reload: true});
                }

                function signup(form) {
                    if (vm.loading) {
                        return;
                    }

                    vm.loading = true;
                    $timeout.cancel(thisTimeout);
                    AuthService.signup(vm.data.signup)
                        .then(function () {
                            return AuthService.signin(vm.data.signup);
                        })
                        .then(onAuthenticationCallback)
                        .catch(function (err) {
                            if (!err) {
                                $mdToast.show($mdToast.simple()
                                    .textContent($translate.instant('error.auth.offline')));
                            }

                            if (err.message.indexOf('invalid recaptcha') >= 0) {
                                $mdToast.show($mdToast.simple()
                                    .textContent($translate.instant('error.auth.recaptcha')));
                                vm.loading = false;
                                return;
                            }

                            form.checkClauses.$setValidity('emailAlreadyRegistered', false);

                            vm.loading = false;
                            thisTimeout = $timeout(function () {
                                form.$setUntouched();
                                form.$setPristine();
                                form.checkClauses.$setValidity('emailAlreadyRegistered', true);
                            }, FORM_RESET_DELAY);
                        });
                }

                function signin(form) {
                    if (vm.loading) {
                        return;
                    }
                    vm.loading = true;
                    form.pass.$setValidity('wrongPassword', true);
                    form.pass.$setValidity('unregisteredAccount', true);
                    if (form.$valid) {
                        AuthService.signin(vm.data.signin)
                            .then(onAuthenticationCallback)
                            .catch(function (err) {
                                vm.loading = false;
                                if (!err) {
                                    $mdToast.show($mdToast.simple().textContent('The authentication service is temporarily under maintenance, please retry later!'));
                                }
                                //TODO: verificare questa gestione degli errori
                                if (err.message && err.message === "incorrect password") {
                                    form.pass.$setValidity('wrongPassword', false);
                                }
                                form.pass.$setValidity('unregisteredAccount', false);
                                thisTimeout = $timeout(function () {
                                    form.$setUntouched();
                                    form.$setPristine();
                                    form.pass.$setValidity('wrongPassword', true);
                                    form.pass.$setValidity('unregisteredAccount', true);
                                }, FORM_RESET_DELAY);

                            });
                    }
                }

                function loginFacebook() {
                    AuthService
                        .signinWithFacebook()
                        .then(onAuthenticationCallback)
                        .catch(function (err) {
                            console.log(err);
                            if (!err) {
                                $mdToast.show($mdToast.simple().textContent('The authentication service is temporarily under maintenance, please retry later!'));
                            }
                        });
                }

                function loginGoogleEvent(evt, token) {
                    AuthService
                        .signinWithGoogle(token)
                        .then(onAuthenticationCallback)
                        .catch(function (err) {
                            console.log(err);
                            if (!err) {
                                $mdToast.show($mdToast.simple().textContent('The authentication service is temporarily under maintenance, please retry later!'));
                            }
                        });
                }

                function stateGo(state, stateParams, stateConfig) {
                    if (!stateParams) {
                        stateParams = {};
                    }
                    if (!stateConfig) {
                        stateConfig = {};
                    }
                    virtualStateParams = stateParams;
                    try {
                        if ($this.useExternalTransitions || !isImplementedInComponent(state)) {
                            $state.go(state, stateParams, stateConfig);
                            return;
                        }
                        $this.state = state;
                    } catch (err) {
                        $state.go(DEFAULT_ERROR_REDIRECT_TO);
                    }
                }

                function getPasswordStrength(pass) {
                    var score = AuthService.getPasswordScore(pass);
                    if (score > 80) {
                        vm.strengthPasswordIndicator = "strong";
                        vm.strengthPasswordColor = "#3AB526";
                    } else if (score > 60) {
                        vm.strengthPasswordIndicator = "good";
                        vm.strengthPasswordColor = "#FFC700";
                    } else if (score >= 0) {
                        vm.strengthPasswordIndicator = "weak";
                        vm.strengthPasswordColor = "#FE001A";
                    }

                }

                function unifyPayloadOutput(res) {
                    if (res && res.email) {
                        res = {data: {user: res, info: {firstAccess: false}}};
                    }
                    return res;
                }

                function isImplementedInComponent(state) {
                    return IS_STATE_IMPLEMENTED[state];
                }
            }]
    };
});

define(['angular', 'app'], function (angular, app) {
    app.filter('grammarHighlight', ['$sce', function ($sce) {
        return function (text, phrase) {
            if (text == undefined) return '';
            if (phrase) text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
                '<span class="grammar-highlighted">$1</span>');
            return $sce.trustAsHtml(text);
        };
    }]);
});

'use strict';
define(['angular'], function (angular) {

    var controller = function ($scope, $mdMedia, $state, $timeout, $filter, PlanDetailsService) {
        var vm = $scope;
        vm.isPromoTime = PlanDetailsService.isPromoTime();

        if (!vm.isPromoTime) {
            return;
        }
        vm.$mdMedia = $mdMedia;

        var now = Date.now();
        var promo = PlanDetailsService.getCurrentPromo();

        vm.expiredHours;
        vm.expiredDate;
        vm.expiredMonth;
        vm.expiredDay;

        vm.endPromoDate;

        if (promo && promo.endPromoDate) {
            vm.endPromoDate = promo.endPromoDate;

            vm.expiredHours = $filter('date')(vm.endPromoDate, 'HH:mm');
            vm.expiredDate = $filter('date')(vm.endPromoDate, 'MMMM d');
            vm.expiredMonth = vm.endPromoDate.toLocaleString('en-US', {
                month: 'long'
            });
            vm.expiredDay = $filter('date')(vm.endPromoDate, 'd');
        }

        vm.$watch(function () {
            return localStorage.getItem('NG_TRANSLATE_LANG_KEY');
        }, function (currentLanguage, previousLanguage) {
            vm.expiredMonth = vm.endPromoDate.toLocaleString(currentLanguage, {
                month: 'long'
            });
        });


        var distance = vm.endPromoDate - now;

        var tick = function () {
            distance = new Date(distance - 1000);

            vm.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            vm.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            vm.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            vm.seconds = Math.floor((distance % (1000 * 60)) / 1000);
            vm.clock = distance;

            $timeout(tick, 1000);

            if (vm.days == 0 && vm.hours == 0 && vm.minutes == 0 && vm.seconds == 0) {
                PlanDetailsService.setPromoTimeToFalse();
                $state.reload();
                if ($state.current.name == 'app.welcome' || $state.current.name == 'upgrade.choose-plan' || $state.current.name == 'access.get-started-now') {
                    window.location.reload();
                }

                clearLocalstorage();
            }
        }

        tick();

        vm.format2Digits = function (value) {
            return (value.toString().length < 2) ? "0" + value : value;
        }

        this.$onInit = function () {
            vm.classModifier = '';
            if (this.dialogMode) {
                vm.classModifier = 'dialog-mode';
            }
            if (this.dashboardMode) {
                vm.classModifier = 'dashboard-mode';
            }
        }

        function clearLocalstorage() {
            if (promo && promo.promoCoupon && localStorage.getItem('coupon') == promo.promoCoupon) {
                localStorage.removeItem('coupon');
            }
        }
    };
    controller.$inject = ['$scope', '$mdMedia', '$state', '$timeout', '$filter', 'PlanDetailsService'];
    return {
        templateUrl: 'app/components/st-promo-countdown/st-promo-countdown.html',
        bindings: {
            dashboardMode: '<',
            dialogMode: '<',
            onlyExpirationText: '<'
        },
        controller: controller
    };
});

'use strict';
define([], function() {
    function AccessController($scope, $rootScope, LanguageService, $mdMedia, $state, $stateParams, PlanDetailsService) {
        var vm = $scope;
        vm.switchLang = switchLang;
        vm.$mdMedia = $mdMedia;

        $scope.redirectTo = $stateParams.redirectTo;
        $scope.workspaceId = $stateParams.workspaceId;
        $scope.triggerSrc = $stateParams.triggerSrc;

        // Set Language and Country get from Storage or set from default config
        vm.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();

        vm.isPromoTime = PlanDetailsService.isPromoTime() && PlanDetailsService.getCurrentPromo().promoType == 'all-platform-promo';

        if (vm.isPromoTime) {
            vm.classModifier = PlanDetailsService.getCurrentPromo().currentPromoModifierClass;
        }

        function switchLang(language) {
            vm.selectedLang = language;
            $rootScope.setLang(language);
        }

    }
    AccessController.$inject=['$scope', '$rootScope', 'LanguageService', '$mdMedia', '$state', '$stateParams', 'PlanDetailsService'];
    return AccessController;
});

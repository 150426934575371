/**
 * @ngdoc overview
 * @name auth.service:AuthService
 */
'use strict';
define(['app'], function (app) {
    app.service('AuthService', AuthService);
    AuthService.$inject = ['API', 'sessionHistoryCache', '$location', '$translate', 'Facebook', 'TrackEventService', '$http', '$q', '$localStorage', 'SocketFactory', '__env', 'LanguageService', 'UtilitiesService', 'WorkspaceService', 'UsetifulService', '$window'];

    function AuthService(API, sessionHistoryCache, $location, $translate, Facebook, TrackEventService, $http, $q, $localStorage, SocketFactory, __env, LanguageService, UtilitiesService, WorkspaceService, UsetifulService, $window) {
        var currentLang = $translate.proposedLanguage() || $translate.use() || 'en';
        return {
            getSessionUser: getSessionUser,
            setSessionUser: setSessionUser,
            getPasswordScore: getPasswordScore,
            getSessionToken: getSessionToken,
            checkSignup: checkSignup,
            signup: signup,
            checkSignin: checkSignin,
            signin: signin,
            signinWithFacebook: signinWithFacebook,
            signinWithGoogle: signinWithGoogle,
            facebookSignin: facebookSignin,
            googleSignin: googleSignin,
            isAuthenticated: isAuthenticated,
            logout: logout,
            verifyCode: verifyCode,
            resendVerificationCode: resendVerificationCode,
            forgot: forgot,
            reset: reset,
            resetCheck: resetCheck,
            loginAs: loginAs,
            me: me,
            isVerified: isVerified,
            isProfiled: isProfiled,
            getApiToken: getApiToken,
            signinZendesk: signinZendesk,
            signinFeedbear: signinFeedbear,
        };

        function checkSignup(params) {
            var deferred = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/check-signup', params, {
                headers: {'accept-language': params.lang || 'en'}
            })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function signup(params, attempts) {
            var deferred = $q.defer();

            if(!attempts) {
                attempts = 1;
            }

            $window.grecaptcha.enterprise.ready(function () {
                $window.grecaptcha.enterprise.execute('6Ld6aSEmAAAAAMU7nEFRm4y8rRslH6glcGrwiOVC', {action: 'login'})
                    .then(function (recaptchaToken) {
                        return $http.post(__env.apiGateway + '/api/v2/auth/signup', {
                            email: params.email,
                            pass: params.pass,
                            recaptchaToken,
                        }, {
                            headers: {'accept-language': params.lang || 'en'}
                        });
                    })
                    .then(function (res) {
                        sessionHistoryCache.removeAll();

                        deferred.resolve(res.data.user);
                    })
                    .catch(function (err) {
                        if (err && err.data && err.data.message === 'invalid recaptcha (EXPIRED)' && attempts <= 3) {
                            signup(params, (attempts + 1))
                                .then(function (user) {
                                    deferred.resolve(user);
                                })
                                .catch(function (err) {
                                    deferred.reject(err);
                                });
                        } else {
                            deferred.reject(err.data);
                        }
                    });
            });

            return deferred.promise;
        }

        /**
         * @param {{email: string, pass: string}} user
         */
        function checkSignin(user) {
            var deferred = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/signin', user)
                .then(function (res) {
                    if (!res || !res.data || !res.data.user) {
                        deferred.reject(new Error('user not found'));
                        return;
                    }

                    deferred.resolve(res.data.user);
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function signin(user) {
            var deferred = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/signin', user)
                .then(function (res) {
                    sessionHistoryCache.removeAll();

                    if (res.data.token && res.data.token.length) {
                        localStorage.setItem('accessToken', res.data.token);
                    }

                    if (res.data.tokenForZendeskChat && res.data.tokenForZendeskChat.length && typeof zE !== 'undefined') {
                        zE('messenger', 'loginUser', function (callback) {
                            callback(res.data.tokenForZendeskChat);
                        });
                    }

                    if (res.data.user) {
                        setSessionUser(res.data.user);
                    }

                    deferred.resolve(res.data.user);
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function signinZendesk() {
            var deferred = $q.defer();
            API.get(__env.apiGateway + '/api/v2/auth/zendesk', {}, true)

                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function signinFeedbear() {
            var deferred = $q.defer();
            API.get(__env.apiGateway + '/api/v2/auth/feedbear', {}, true)

                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function facebookSignin(params) {
            var deferred = $q.defer();
            var lang = params.lang ? '&lang=' + params.lang : '';
            $http.get(__env.apiGateway + '/api/v2/auth/signin/facebook?access_token=' + params.access_token + lang, {
                headers: {'accept-language': params.lang || 'en'}
            }).then(function (res, status) {
                sessionHistoryCache.removeAll();
                if (res.data.token && res.data.token.length) {
                    localStorage.setItem('accessToken', res.data.token);
                }
                setSessionUser(res.data.user);
                deferred.resolve(res);
            }).catch(function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function getPasswordScore(pass) {
            var score = 0;
            if (!pass) {
                return score;
            }
            // award every unique letter until 5 repetitions
            var letters = {};
            for (var i = 0; i < pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                score += 5.0 / letters[pass[i]];
            }
            // bonus points for mixing it up
            var variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass),
            };
            var variationCount = 0;
            for (var check in variations) {
                variationCount += (variations[check] === true) ? 1 : 0;
            }
            score += (variationCount - 1) * 10;
            return parseInt(score);

        }

        function googleSignin(params) {
            var deferred = $q.defer();
            var lang = params.lang ? '&lang=' + params.lang : '';
            $http.get(__env.apiGateway + '/api/v2/auth/signin/google?access_token=' + params.access_token + lang, {
                headers: {'accept-language': params.lang || 'en'}
            }).then(function (res, status) {
                sessionHistoryCache.removeAll();
                if (res.data.token && res.data.token.length) {
                    localStorage.setItem('accessToken', res.data.token);
                }
                // store username and token in local storage to keep user logged in between page refreshes
                setSessionUser(res.data.user);
                deferred.resolve(res);
            }).catch(function (err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function getSessionToken() {
            var session = localStorage.getItem('session') || UtilitiesService.getRandomString();
            localStorage.setItem('session', session);
            return session;
        }

        function getSessionUser() {
            var currentUser = false;

            if ($localStorage.currentUser) {
                currentUser = $localStorage.currentUser;
            }

            if ($localStorage.fromAdmin && currentUser) {
                currentUser.fromAdmin = true;
            }

            if (currentUser && currentUser.workspace) {
                WorkspaceService.setWorkspace(currentUser.workspace);
            }

            return currentUser;
        }

        function setSessionUser(user) {
            try {
                if (!user || !user.workspace) {
                    throw new Error('invalid login');
                }

                $localStorage.currentUser = user || false;
                WorkspaceService.setWorkspace(user.workspace);

                if ($localStorage.fromAdmin || __env.stage === 'development') {
                    return;
                }

                SocketFactory.emit('multi-session-login', {
                    userId: user.id,
                    workspaceId: user.workspace.id,
                    session: getSessionToken()
                });
            } catch (e) {
                console.log(e);
                throw e;
            }
        }

        function setFromAdmin() {
            $localStorage.fromAdmin = true;
        }

        /**
         * @description Check if user is logged
         * @returns {boolean}
         */
        function isAuthenticated() {
            return !!getSessionUser();
        }

        function loginAs(admin, loginAs) {
            var deferred = $q.defer();
            $http.post(__env.apiGateway + '/api/v1/auth/loginAs', {email: admin.email, pass: admin.pass, loginAs: loginAs})
                .then(function (res) {
                    sessionHistoryCache.removeAll();
                    if (res.data.token && res.data.token.length) {
                        localStorage.setItem('accessToken', res.data.token);
                    }

                    var user = res.data.user;
                    user.fromAdmin = true;

                    setFromAdmin();
                    setSessionUser(res.data.user);
                    deferred.resolve(res.data.user);
                })
                .catch(function (err) {
                    deferred.reject(err.data);
                });
            return deferred.promise;
        }

        function logout() {
            var propertiesToRemoveFromLocalStorage = [
                'userInfo',
                'accessToken',
                'ngStorage-currentUser',
                '__paypal_storage__'
            ]

            propertiesToRemoveFromLocalStorage.forEach(function (property) {
                localStorage.removeItem(property)
            })

            $http.defaults.headers.common.Workspace = null;
            $localStorage.$reset();
            sessionHistoryCache.removeAll();

            UsetifulService.reset(LanguageService.getLanguageCodeSaveOnStorage());
            return $q.resolve(true);
        }

        function verifyCode(params) {
            var q = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/verify/', params, {
                headers: {'accept-language': params.lang || 'en'}
            }).then(function (data) {
                TrackEventService.event('Funnel Events', 'Signup Completed', $location.$$url);
                TrackEventService.gaV2Event('sign_up', {
                    'sign_up_method': 'STO',
                    'method': 'STO',
                    'service': $location.$$url
                });
                q.resolve(data);
            }).catch(function (data) {
                q.reject(data);
            });
            return q.promise;
        }

        function resendVerificationCode(params) {
            var q = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/verify/resend', params, {
                headers: {'accept-language': params.lang || 'en'}
            }).then(function (data) {
                q.resolve(data);
            }).catch(function (data) {
                q.reject(data);
            });
            return q.promise;
        }

        function forgot(params) {
            var q = $q.defer();
            $http.post(__env.apiGateway + '/api/v2/auth/forgot', params, {
                headers: {'accept-language': params.lang || 'en'}
            }).then(function (data) {
                q.resolve(data);
            }).catch(function (data) {
                q.reject(data);
            });
            return q.promise;
        }

        function reset(data) {
            var q = $q.defer();
            $http.put(__env.apiGateway + '/api/v2/auth/reset', data, {
                headers: {'accept-language': data.lang || 'en'}
            }).then(function (data) {
                q.resolve(data);
            }).catch(function (err) {
                q.reject(err);
            });
            return q.promise;
        }

        function resetCheck(data) {
            var deferred = $q.defer();
            $http.get(__env.apiGateway + '/api/v2/auth/reset/' + data)
                .then(function (data) {
                    deferred.resolve(data);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        function me() {
            var d = $q.defer();
            var cacheId = 'get-user-me';
            var existCached = sessionHistoryCache.get(cacheId);
            /* if cache exist, use the cached results in browser history */
            if (existCached) {
                return $q.resolve(existCached);
            }

            API.get(__env.apiGateway + '/api/v2/auth/', {}, true)
                .then(function (result) {
                    if (result && result.user) {
                        sessionHistoryCache.put(cacheId, result.user);
                        d.resolve(result.user);
                        return;
                    }

                    d.reject(new Error('user not found'));
                })
                .catch(function (err) {
                    d.reject(err);
                });

            return d.promise;
        }

        function signinWithGoogle(token, onSuccess = function (res) {
        }, onError = function (err) {
        }) {
            var d = $q.defer();
            googleSignin({
                lang: currentLang,
                access_token: token
            })
                .then(function (res) {
                    if (isUserFirstAccess(res)) {
                        TrackEventService.event('Funnel Events', 'Signup Completed', $location.$$url);
                        TrackEventService.gaV2Event('sign_up', {
                            'sign_up_method': 'Google',
                            'method': 'Google',
                            'service': $location.$$url
                        });
                    }


                    onSuccess();
                    d.resolve(res);
                })
                .catch(function (err) {
                    onError(err);
                    d.reject(err);
                });

            return d.promise;
        }

        function signinWithFacebook(onSuccess = function (res) {
        }, onError = function (err) {
        }) {
            var d = $q.defer();
            if (!Facebook.isReady()) {
                d.reject({
                    error: 'FACEBOOK_SDK_IS_NOT_READY'
                });
                return d.promise;
            }
            Facebook.login(function (response) {
                if (response.status !== 'connected') {
                    d.reject(response);
                    return;
                }
                facebookSignin({
                    lang: currentLang,
                    access_token: response.authResponse.accessToken
                })
                    .then(function (res) {
                        if (isUserFirstAccess(res)) {
                            TrackEventService.event('Funnel Events', 'Signup Completed', $location.$$url);
                            TrackEventService.gaV2Event('sign_up', {
                                'sign_up_method': 'Facebook',
                                'method': 'Facebook',
                                'service': $location.$$url
                            });
                        }
                        d.resolve(res);
                        onSuccess(res);
                    })
                    .catch(function (err) {
                        d.reject(err);
                        onError(err);
                    });
            }, {scope: 'email,public_profile'});
            return d.promise;
        }

        /**
         * @description Check if user is verified
         * @returns {*|boolean}
         */
        function isVerified() {
            var user = getSessionUser();
            return user && user.verified && user.verified > 0;
        }

        function isProfiled() {
            var user = getSessionUser();
            return user && user.businessType && user.teamMembers && user.jobTitle;
        }

        function isUserFirstAccess(res) {
            return res && res.data && res.data.info && res.data.info.firstAccess;
        }

        function getApiToken() {
            var deferred = $q.defer();
            API.get(__env.apiGateway + '/api/v2/auth/api-token/', {}, true)
                .then(function (data) {
                    if (data && data.token) {
                        deferred.resolve(data);
                    }

                    deferred.reject(null);
                })
                .catch(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }
    }
});

'use strict';
define([
    'angular',
    'modules/keywordtracker/keywordtracker.routes',
    'modules/keywordtracker/components/trend-competitors/trend-competitors.component',
    'modules/keywordtracker/components/benchmark/benchmark.component',
    'modules/keywordtracker/components/project-card/project-card.component',
    'modules/keywordtracker/components/locations/locations.component',
    'modules/keywordtracker/components/table-fluid-competitors/table-fluid-competitor.component',
    'modules/keywordtracker/components/serp-features-tracker/serp-features-tracker.component',
    'modules/keywordtracker/landing/landing.module',
    'modules/keywordtracker/overview/overview.module',
    'modules/keywordtracker/dialogs/dialogs.module',
    'modules/keywordtracker/settings/settings.module',
    'modules/keywordtracker/report/report.module',
    
], function (
    angular,
    routes,
    trendCompetitors,
    benchmark,
    rankTrackerProjectCard,
    locations,
    tableFluidCompetitor,
    serpFeaturesTracker
    ) {
    var app = angular.module('app.keywordtracker', [
        'app.keywordtracker.landing',
        'app.keywordtracker.overview',
        'app.keywordtracker.dialogs',
        'app.keywordtracker.settings',
        'app.keywordtracker.report',
    ]);

    app.component('trendCompetitors', trendCompetitors);
    app.component('benchmark', benchmark);
    app.component('rankTrackerProjectCard', rankTrackerProjectCard);
    app.component('locations', locations);
    app.component('tableFluidCompetitor', tableFluidCompetitor);
    app.component('serpFeaturesTracker', serpFeaturesTracker);
    
    app.config(routes);

    return app;
});

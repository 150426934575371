import angular from 'angular';
import routes from './writerassistant.routes';
import './landing/landing.module';
import './wizard/wizard.module';
import './editor/editor.module';

import assistantAuditor from "./components/assistant-auditor/assistant-auditor.component";
import WriterAssistantAuditorService from "./components/assistant-auditor/assistant-auditor.service";
import assistantAuditorKeywords from "./components/assistant-auditor-keywords/assistant-auditor-keywords.component";
import assistantAuditorWhatToFix from "./components/assistant-auditor-what-to-fix/assistant-auditor-what-to-fix.component";
import assistantAuditorContentStructure from "./components/assistant-auditor-content-structure/assistant-auditor-content-structure.component";

const app = angular.module('app.writerassistant', [
    'app.writerassistant.editor',
    'app.writerassistant.landing',
    'app.writerassistant.wizard'
]);

app.service('WriterAssistantAuditorService', WriterAssistantAuditorService);

app.component('assistantAuditor',assistantAuditor);
app.component('assistantAuditorKeywords',assistantAuditorKeywords);
app.component('assistantAuditorWhatToFix',assistantAuditorWhatToFix);
app.component('assistantAuditorContentStructure',assistantAuditorContentStructure);

app.config(routes);
export default app;

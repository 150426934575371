'use strict';
define(['jquery'], function ($) {
    function UsersController($window, $scope, WorkspaceService, $translate, $localStorage, $mdDialog, UserService) {
        var vm = $scope;
        vm.currentUser = $localStorage.currentUser;
        vm.workspace = vm.currentUser.workspace;
        vm.isLoading = true;
        vm.users = [];
        vm.maxMembers = 1;
        vm.inviteMembers = inviteMembers;
        vm.addUsers = addUsers;
        vm.isOwner = Number(vm.currentUser.id) === Number(vm.workspace.ownerId);

        function getDetailWorkspace() {
            WorkspaceService.getDetailWorkspace({
                id: vm.workspace.id
            })
                .then(function (res) {
                    if (res && res.users && res.users.length) {
                        vm.users = res.users.filter(function (user) {
                            return !user.refusedAt;
                        });
                    }

                    vm.isLoading = false;
                })
                .catch(function (err) {
                    $window.console.log(err);
                    vm.isLoading = false;
                });
        }

        vm.usersController = {
            changeRole: changeRole,
            deleteUser: deleteUser,
            setDisabledForPemissions: function (user) {

                if (parseInt(vm.workspace.roleId) === 1) {
                    return false;
                }

                if (!user || !user.roleName || !user.email || !vm.workspace.permissions || vm.currentUser.email == user.email) {
                    return true;
                }


                return vm.workspace.permissions.indexOf('workspace_user_' + user.roleName.toLowerCase()) < 0;
            },
            userWorkspace: $scope.workspace,
            setClassForStatus: function (row) {
                if (row.confirmedAt || row.roleName === 'owner') {
                    return 'badge-status-v2--green';
                }
                if (row.refusedAt) {
                    return 'badge-status-v2--red';
                }
                return 'badge-status-v2--blue';
            },
            setLabel: function (row) {
                if (row.confirmedAt || row.roleName === 'owner') {
                    return $translate.instant('settings.users.accepted');
                }
                if (row.refusedAt) {
                    return $translate.instant('settings.users.declined');
                }
                return $translate.instant('settings.users.pending');
            }
        };

        function deleteUser(ev, user) {
            $mdDialog
                .show({
                    templateUrl: "app/modules/settings/dialogs/delete-user/delete-user.html",
                    controller: "DeleteUserCtrl",
                    parent: $(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    locals: {
                        user: user,
                        workspaceId: vm.workspace.id
                    }
                })
                .then(function () {
                    getDetailWorkspace();
                })

                .catch(function (err) {
                    $window.console.log(err);
                });
        }

        function changeRole(ev, user) {
            $mdDialog
                .show({
                    templateUrl: "app/modules/settings/dialogs/change-role/change-role.html",
                    controller: "ChangeRoleCtrl",
                    parent: $(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    locals: {
                        user: user
                    }
                })
                .then(function () {
                    getDetailWorkspace();
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function canInviteUsers() {
            if (!vm.maxMembers) {
                vm.maxMembers = 1;
            }

            return vm.users && vm.users.length < vm.maxMembers;
        }

        function inviteMembers(ev) {
            if (!vm.isOwner) {
                if (!canInviteUsers()) {
                    showCantAddNewUsersDialog(ev);
                    return;
                }

                showInviteMembersDialog(ev);
                return;
            }

            if (!canInviteUsers()) {
                var additionalSubtitle = (Number(vm.maxMembers) === 1) ?
                    'settings.dialogs.to.do.this.you.must.first.update.your.plan' :
                    'settings.dialogs.to.do.this.you.must.first.update.your.plan.or.remove.an.user';

                showAddUsersDialog(ev, {
                    title: 'settings.dialogs.you.cant.add.other.users',
                    additionalSubtitle: additionalSubtitle,
                    userLimit: vm.maxMembers
                });
                return;
            }

            showInviteMembersDialog(ev);
        }

        function addUsers(ev) {
            showAddUsersDialog(ev, {
                title: 'settings.dialogs.add.users.to.your.workspace',
                additionalSubtitle: null,
                userLimit: vm.maxMembers,
            });
        }

        function showAddUsersDialog(ev, data) {
            $mdDialog
                .show({
                    templateUrl: "app/modules/settings/dialogs/add-users/add-users.html",
                    controller: "AddUsersCtrl",
                    parent: $(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    locals: {
                        title: data.title,
                        additionalSubtitle: data.additionalSubtitle,
                        userLimit: data.userLimit,
                    }
                })
                .then(function () {
                    getDetailWorkspace();
                })

                .catch(function (err) {
                    console.log(err);
                });
        }

        function showInviteMembersDialog(ev) {
            $mdDialog
                .show({
                    templateUrl: "app/modules/settings/dialogs/invite-members/invite-members.html",
                    controller: "InviteMembersCtrl",
                    parent: $(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                })
                .then(function () {
                    getDetailWorkspace();
                })
                .catch(function (err) {
                    $window.console.log(err);
                });
        }

        function showCantAddNewUsersDialog(ev) {
            $mdDialog
                .show({
                    templateUrl: "app/modules/settings/dialogs/cant-add-new-users/cant-add-new-users.html",
                    controller: "CantAddNewUsersCtrl",
                    parent: $(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                })
                .then(function () {
                    getDetailWorkspace();
                })
                .catch(function (err) {
                    $window.console.log(err);
                });
        }

        function loadMemberLimit() {
            UserService.limits()
                .then(function (res) {
                    if (res && res.data && res.data.limits && res.data.limits.length) {
                        angular.forEach(res.data.limits, function (service) {
                            if (service.alias === 'workspace-users-limit') {
                                vm.maxMembers = (service.qtyAllowed == 1) ? service.qtyAllowed : service.qtyAllowed + 1;
                            }
                        });
                    }
                })
                .catch(function (err) {
                    $window.console.log(err);
                    return null;
                });
        }

        getDetailWorkspace();
        loadMemberLimit();
    }

    UsersController.$inject = ['$window', '$scope', 'WorkspaceService', '$translate', '$localStorage', '$mdDialog', 'UserService'];
    return UsersController;
});

'use strict';
define([], function () {
    function InvitationsController($scope, WorkspaceService, $rootScope, $localStorage, $mdDialog, $state, TrackEventService) {
        var vm = $scope;
        $scope.workspaces = [];
        $scope.invitations = [];
        $scope.currentUser = $localStorage.currentUser;

        $scope.leaveWorkspace = leaveWorkspace;
        $scope.acceptInvitation = acceptInvitation;
        $scope.cancelInvitation = cancelInvitation;

        vm.workspacesController = {
            leaveWorkspace: leaveWorkspace,
            currentUser: $scope.currentUser,
            switchWorkspace: switchWorkspace
        };
        vm.invitationsController = {
            acceptInvitation: acceptInvitation,
            cancelInvitation: cancelInvitation,
        };

        function init() {
            getAllWorkspaces();
            getInvitations();
        }

        function getAllWorkspaces() {
            WorkspaceService
                .getAllWorkspaces()
                .then(function (res) {
                    if (res && res.workspaces && res.workspaces.length) {
                        $scope.workspaces = res.workspaces;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function getInvitations() {
            WorkspaceService
                .getInvitations()
                .then(function (res) {
                    if (res) {
                        $scope.invitations = res;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        }

        function switchWorkspace(ev, selectedWorkspace) {
            var workspace = WorkspaceService.getWorkspace();
            if (selectedWorkspace.id === workspace.id) {
                $state.go('app.settings.customizations', {}, {reload: true});
                return;
            }

            $mdDialog.show({
                templateUrl: "app/modules/template/dialogs/switch-workspace/switch-workspace.html",
                controller: "SwitchWorkspaceCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    workspace: selectedWorkspace,
                }
            })
                .then(function () {
                    WorkspaceService.setWorkspace(selectedWorkspace);
                    $rootScope.$broadcast("switchWorkSpace", $localStorage.currentUser);
                    $state.go('app.settings.customizations', {}, {reload: true});

                })
                .catch(function (err) {
                    console.log(err);
                });
        }


        function leaveWorkspace(ev, workspace) {
            $mdDialog.show({
                templateUrl: "app/modules/settings/dialogs/leave-workspace/leave-workspace.html",
                controller: "LeaveWorkspaceCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    workspace: workspace,
                }
            }).then(function () {
                getAllWorkspaces();
            });
        }

        function acceptInvitation(ev, invitation) {
            $mdDialog.show({
                templateUrl: "app/modules/settings/dialogs/accept-invitation/accept-invitation.html",
                controller: "AcceptInvitationCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    invitation: invitation,
                }
            }).then(function () {
                var acceptedInvitationIndex;

                $scope.invitations.some(function (elem, i) {
                    return elem.workspaceId == invitation.workspaceId ? (acceptedInvitationIndex = i, true) : false;
                });

                TrackEventService.event('Workspace', 'Invitation Accepted');
                TrackEventService.gaV2Event('invitation_accepted');

                $scope.invitations.splice(acceptedInvitationIndex, 1);
                getAllWorkspaces();
                $rootScope.$broadcast("invitation-accept", '');
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        function cancelInvitation(ev, invitation) {
            $mdDialog.show({
                templateUrl: "app/modules/settings/dialogs/cancel-invitation/cancel-invitation.html",
                controller: "CancelInvitationCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    invitation: invitation,
                }
            }).then(function () {
                var declinedInvitationIndex;

                $scope.invitations.some(function (elem, i) {
                    return elem.workspaceId == invitation.workspaceId ? (declinedInvitationIndex = i, true) : false;
                });

                $scope.invitations.splice(declinedInvitationIndex, 1);
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        init();
    }

    InvitationsController.$inject = ['$scope', 'WorkspaceService', '$rootScope', '$localStorage', '$mdDialog', '$state', 'TrackEventService'];
    return InvitationsController;
});

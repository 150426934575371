'use strict';
define(['jquery'], function () {
    return {
        templateUrl: 'app/components/access-components/st-promo-detail/st-promo-detail.html',
        bindings: {
            userIsOffline: '<',
            canDoTrial: '<',
        },
        controller: ['$scope',
            function ($scope) {
                $scope.data = {
                    group: 'freeplan'
                };
            }],
    };
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/http-status/404/404.controller',
],function(
    angular,
    SPHttpStatus404Ctrl
){
    var app=angular.module('app.seospider.exam.analysis.httpstatus.404', []);
    app.controller("SPHttpStatus404Ctrl",SPHttpStatus404Ctrl);
    return app;
});
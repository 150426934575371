'use strict';
define(['app','jquery'], function (app,$) {
    app.component('stCalendar', {
        templateUrl: 'app/components/st-calendar/st-calendar.html',
        controller: controller,
        bindings: {
            range: "=",
            callback: '=',
            limitDate: '<'
        }
    });

    controller.$inject = ['$scope', '$filter', '$mdToast', '$translate'];

    function controller($scope, $filter, $mdToast, $translate) {
        var ctrl = this;
        var itemSelectedActive = 'last_30_days';
        $scope.itemSelected = 'last_30_days';
        $scope.range = {
            startDate: new Date(),
            endDate: new Date()
        };
        $scope.range.startDate.setDate($scope.range.startDate.getDate() - 30);
        $scope.setRange = setRange;
        $scope.minDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 2,
            new Date().getDate()
        );
        $scope.maxDate = new Date();

        $scope.openMenu = function ($mdMenu, ev) {
            $mdMenu.open(ev);
            $(document).ready(function () {
                $scope.calendarStartDate = '' +
                    '<md-subheader hide-gt-xs>{{\'Start Date\'|translate}}</md-subheader>' +
                    '<md-calendar ng-cloak ng-change="setRange(\'custom_range\')" ' +
                    '   md-min-date="minDate" md-max-date="maxDate" ng-model="range.startDate">' +
                    '</md-calendar>';
                $scope.calendarEndDate = '' +
                    '<md-subheader hide-gt-xs>{{\'End Date\'|translate}}</md-subheader>' +
                    '<md-calendar ng-cloak ng-change="setRange(\'custom_range\')" ' +
                    '   md-min-date="minDate" md-max-date="maxDate" ng-model="range.endDate">' +
                    '</md-calendar>';
            });
            setTimeout(backgroundRangeDays, 1000);
        };
        $scope.$on('$mdMenuClose', function () {
            $scope.calendarStartDate = '';
            $scope.calendarEndDate = '';
        });

        $scope.positionMenu = function () {
            if (angular.element(window).width() < 1280) {
                //x - y
                return '500 0';
            }
        };

        function setArrayTypeOfRange() {
            $scope.typeOfRange = [
                {type: 'today', label: $translate.instant('Today'), disabled: false},
                {type: 'yesterday', label: $translate.instant('Yesterday'), disabled: checkIfThisRangeIsNotPossible('yesterday')},
                {type: 'last_7_days', label: $translate.instant('Last 7 days'), disabled: checkIfThisRangeIsNotPossible('last_7_days')},
                {type: 'last_30_days', label: $translate.instant('Last 30 days'), disabled: checkIfThisRangeIsNotPossible('last_30_days')},
                {type: 'this_month', label: $translate.instant('This month'), disabled: checkIfThisRangeIsNotPossible('this_month')},
                {type: 'custom_range', label: $translate.instant('Custom Range'), disabled: false}
            ];
        }

        var setRangeDate = {
            today: function () {
                setItemSelected('today');
                $scope.range = {
                    startDate: new Date(),
                    endDate: new Date()
                };
            },
            yesterday: function () {
                setItemSelected('yesterday');
                $scope.range = {
                    startDate: new Date(),
                    endDate: new Date()
                };
                $scope.range.startDate.setDate($scope.range.startDate.getDate() - 1);
                $scope.range.endDate.setDate($scope.range.endDate.getDate() - 1);
            },
            last_7_days: function () {
                setItemSelected('last_7_days');
                $scope.range = {
                    startDate: new Date(),
                    endDate: new Date()
                };
                $scope.range.startDate.setDate($scope.range.startDate.getDate() - 7);
            },
            last_30_days: function () {
                setItemSelected('last_30_days');
                $scope.range = {
                    startDate: new Date(),
                    endDate: new Date()
                };
                $scope.range.startDate.setDate($scope.range.startDate.getDate() - 30);
            },
            this_month: function () {
                setItemSelected('this_month');
                var year = new Date().getFullYear();
                var month = new Date().getMonth();
                var firstday = new Date(year, month, 1);
                var lastday = new Date(year, month + 1, 0);
                $scope.range = {
                    startDate: firstday,
                    endDate: lastday
                };
            },
            custom_range: function () {
                setItemSelected('custom_range');
            }
        };

        function validationDate(start, end) {
            if (start > end) {
                $mdToast.show({
                    template:
                        '<md-toast layout="row" flex class="md-toast">' +
                        '   <div><i class="icon-x st-red m-r-sm"></i></div>' +
                        '   <div>{{\'Select a valid Date\'|translate}}</div>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
                return false;
            }
            return true;
        }

        $scope.apply = function () {
            if (validationDate($scope.range.startDate, $scope.range.endDate)) {
                ctrl.callback({
                    startDate: formattedDate($scope.range.startDate, false),
                    endDate: formattedDate($scope.range.endDate, false)
                });
                itemSelectedActive = $scope.itemSelected;
            }
        };

        $scope.$watch('$ctrl.range', function (range) {
            if (range) {
                $scope.range = controlRange(range);
                ctrl.range = range;
                setArrayTypeOfRange();
                backgroundRangeDays();
            }
        });

        $scope.$watch('$ctrl.limitDate', function (limitDate) {
            if (limitDate && limitDate.minDate) {
                $scope.minDate = new Date(limitDate.minDate);
            }
            if (limitDate && limitDate.maxDate) {
                $scope.maxDate = new Date(limitDate.maxDate);
            }
            $scope.range = controlRange(ctrl.range);
            setArrayTypeOfRange();
        });

        function checkIfThisRangeIsNotPossible(rangeType) {
                var year = new Date().getFullYear();
                var month = new Date().getMonth();
                var startDate;
            if (rangeType === 'last_7_days') {
                startDate = new Date();
                return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 7) < $scope.minDate;
            }
            if (rangeType === 'last_30_days') {
                startDate = new Date();
                return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 30) < $scope.minDate;
            }
            if (rangeType === 'this_month') {
                startDate = new Date(year, month, 1);
                var lastday = new Date(year, month + 1, 0);
                return startDate < $scope.minDate || lastday > new Date();
            }
            if (rangeType === 'yesterday') {
                startDate = new Date();
                return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1) < $scope.minDate;
            }
            return false;
        }

        function setRange(range) {
            setRangeDate[range].call();
            backgroundRangeDays();
        }

        function controlRange(range) {
            if (range.startDate == 'Invalid Date' && range.endDate == 'Invalid Date' ||
                !range.startDate && !range.endDate
            ) {
                range = setDefaultRange();
                return range;
            }
            if (range.startDate && !range.endDate) {
                range.endDate = range.startDate;
            }
            if ($scope.minDate && range.startDate < $scope.minDate) {
                setItemSelected('custom_range');
                itemSelectedActive = 'custom_range';
                range.startDate = $scope.minDate;
            }

            if ($scope.minDate && range.endDate > $scope.minDate && range.startDate > range.endDate) {
                setItemSelected('custom_range');
                itemSelectedActive = 'custom_range';
                range.endDate = $scope.minDate;
            }

            if(range.startDate > $scope.minDate && range.endDate < new Date()){
                if(itemSelectedActive === 'today'){
                    itemSelectedActive = 'today';
                    setItemSelected('today');
                }else if(itemSelectedActive === 'yesterday'){
                    itemSelectedActive = 'yesterday';
                    setItemSelected('yesterday');
                } else {
                    setItemSelected('custom_range');
                    itemSelectedActive = 'custom_range';
                }
            }

            return range;
        }

        function setDefaultRange() {
            itemSelectedActive = 'last_30_days';
            setRange('last_30_days');
            if ($scope.range.startDate > $scope.minDate && $scope.range.endDate < new Date()) {
                return $scope.range;
            }
            if ($scope.range.startDate < $scope.minDate) {
                $scope.range.startDate = $scope.minDate;
                setItemSelected('custom_range');
                itemSelectedActive = 'custom_range';
            }
            if ($scope.range.endDate > new Date()) {
                $scope.range.endDate = new Date();
                setItemSelected('custom_range');
                itemSelectedActive = 'custom_range';
            }

            if ($scope.range.startDate.toDateString() === $scope.range.endDate.toDateString()) {
                setItemSelected('today');
                itemSelectedActive = 'today';
            }

            return $scope.range;
        }

        function backgroundRangeDays() {
            var tds = angular.element('md-calendar-month tr td');
            if (!tds.length) {
                return;
            }
            angular.forEach(tds, function (td) {
                td.classList.remove("rangeBackground");
                var timestamp = td.getAttribute('data-timestamp');
                var dateSearch = new Date(parseInt(timestamp, 10))
                if (dateSearch >= $scope.range.startDate && dateSearch <= $scope.range.endDate) {
                    td.classList.add("rangeBackground");
                }
            });
        }

        $scope.setLabel = function () {
            if (itemSelectedActive === 'custom_range') {
                var startDate = formattedDate($scope.range.startDate, true);
                var endDate = formattedDate($scope.range.endDate, true) ? ' - ' + formattedDate($scope.range.endDate, true) : '';
                if (JSON.stringify($scope.range.endDate) === JSON.stringify($scope.range.startDate)) {
                    endDate = '';
                }
                return startDate + endDate;
            }
            var labelType = '';
            angular.forEach($scope.typeOfRange,function(x){
                if(x.type === itemSelectedActive){
                    labelType = x.label;
                }
            })
            return labelType;
        };

        function formattedDate(date, label) {
            var dateFormat = label ? 'MM-dd' : 'yyyy-MM-dd';
            if (date) {
                return $filter('date')(date, dateFormat);
            }
            return '';
        }

        function setItemSelected(type) {
            $scope.itemSelected = type;
        }
    }
});

'use strict';
define(['app'], function (app) {
    app.component('stSelectSearchable', {
        templateUrl: 'app/components/st-select-searchable/st-select-searchable.html',
        controller: controller,
        transclude: {
            'content': '?stSelectContent',
            'footer': '?stSelectFooter'
        },
        bindings: {
            rows: '<',
            customFooter: '<',
            position: '<',
            controller: '<'
        }
    });
    controller.$inject = ['__env', '$scope','$sce','$filter'];
        function controller(__env, $scope,$sce,$filter) {
        var vm=$scope;
        var $this=this;
        var originatorEv;
        vm.position = $this.position;
        vm.rows = $this.rows;

        vm.openMenu = function($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
        };
    }

    app.directive( 'transcope', function() {
        return {
            link: function( $scope, $element, $attrs, controller, $transclude ) {
                if ( !$transclude ) {
                    throw minErr( 'ngTransclude' )( 'orphan',
                        'Illegal use of ngTransclude directive in the template! ' +
                        'No parent directive that requires a transclusion found. ' +
                        'Element: {0}',
                        startingTag( $element ));
                }
                var innerScope = $scope.$new();
                $transclude( innerScope, function( clone ) {
                    $element.empty();
                    $element.append( clone );
                    $element.on( '$destroy', function() {
                        innerScope.$destroy();
                    });
                });
            }
        };
    });
});

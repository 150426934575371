import angular from 'angular';
import WriterAssistantCtrl from 'modules/writerassistant/editor/editor.controller.js';
import WriterAssistantRightCtrl from 'modules/writerassistant/editor/right.controller.js';
import WriterAssistantUpdateArticleCtrl from 'modules/writerassistant/editor/dialog/update-article/updateArticle.controller.js';
import WriterAssistantDeleteArticleCtrl from 'modules/writerassistant/editor/dialog/delete-article/delete-article.controller.js';
import WriterAssistantErrorArticleCtrl from 'modules/writerassistant/editor/dialog/error/error.controller.js';

const app = angular.module('app.writerassistant.editor', []);
app.filter('timeAgo', ['$translate', function ($translate) {
    return function (input) {
        if (!input) return '';

        const date = new Date(input);
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);

        let interval = Math.floor(seconds / 31536000);
        if (interval >= 1) return interval + ' ' + $translate.instant('years ago');
        if (interval === 1) return interval + ' ' + $translate.instant('year ago');

        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) return interval + ' ' + $translate.instant('months ago');
        if (interval === 1) return interval + ' ' + $translate.instant('month ago');

        interval = Math.floor(seconds / 86400);
        if (interval >= 1) return interval + ' ' + $translate.instant('days ago');
        if (interval === 1) return interval + ' ' + $translate.instant('day ago');

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + ' ' + $translate.instant('hours ago');
        if (interval === 1) return interval + ' ' + $translate.instant('hour ago');

        interval = Math.floor(seconds / 60);
        if (interval >= 1) return interval + ' ' + $translate.instant('minutes ago');
        if (interval === 1) return interval + ' ' + $translate.instant('minutes ago');

        if(seconds === 1) return interval + ' ' + $translate.instant('second ago');
        return Math.floor(seconds) + ' ' + $translate.instant('seconds ago');
    };
}]);
app.controller('WriterAssistantCtrl', WriterAssistantCtrl);
app.controller('WriterAssistantRightCtrl', WriterAssistantRightCtrl);
app.controller('WriterAssistantUpdateArticleCtrl', WriterAssistantUpdateArticleCtrl);
app.controller('WriterAssistantDeleteArticleCtrl', WriterAssistantDeleteArticleCtrl);
app.controller('WriterAssistantErrorArticleCtrl', WriterAssistantErrorArticleCtrl);

app.controller('WriterAssistantErrorArticleCtrl', WriterAssistantErrorArticleCtrl);

export default app;

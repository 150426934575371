
'use strict';
define(['angular'], function(ng) {
    function SnippetsCtrl($scope) {
        var vm = $scope;
       
        vm.query='';
        
        vm.tabs={
            categoryTitle:'Snippets',
            categoryState:'app.seospider.exam.analysis.snippets',
            fields:[
                {label:'seospider.snippets.all',icon:'icon icon-list', uiSref:'.all'},
            ]
        };
       
    }
    SnippetsCtrl.$inject=['$scope'];
    return SnippetsCtrl;
});

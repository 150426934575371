'use strict';
define(['app'], function (app) {

    var OFFLINE_LIMITS = {
        seochecker: 2,
        kwexpl: 3
    };
    var DEFAULT_LIMITS = {
        seochecker: 0,
        kwexpl: 0
    };
    app.service('OfflineDailyLimitsService', OfflineDailyLimitsService);
    OfflineDailyLimitsService.$inject = ['$localStorage'];

    function OfflineDailyLimitsService($localStorage) {

        return {
            isAvailable: isAvailable,
            canConsume: canConsume,
            consume: consume
        };

        function consume(params) {
            var limits = $localStorage.offlineLimits;
            limits = setDefaultLimits(limits);
            if (isAvailable(params)) {
                limits.tools[params.action]++;
                $localStorage.offlineLimits = limits;
                return true;
            } else {
                return false;
            }
        }

        function isAvailable(params) {
            var limits = $localStorage.offlineLimits;
            limits = setDefaultLimits(limits);
            return limits.tools[params.action] <= OFFLINE_LIMITS[params.action];
        }

        function canConsume(params) {
            var limits = $localStorage.offlineLimits;
            limits = setDefaultLimits(limits);
            return limits.tools[params.action] + 1 <= OFFLINE_LIMITS[params.action];
        }

        function setDefaultLimits(limits) {
            if (limits === undefined || getDiffDays(limits.date) > 0) {
                $localStorage.offlineLimits = {
                    tools: DEFAULT_LIMITS,
                    date: new Date().getTime()
                };
                return $localStorage.offlineLimits;
            } else {
                return limits;
            }
        }

        function getDiffDays(settedDay) {
            var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            var today = new Date().getTime();
            var countDays = Math.round(Math.abs((today - settedDay) / (oneDay)));
            return countDays;
        }

    }
});

'use strict';
define(['app'], function (app) {
    app.component('circularProgressBar', {
        templateUrl: 'app/components/circular-progress-bar/circular-progress-bar.html',
        controller: controller,
        bindings: {
            customClass: "<",
            currentValue: "<",
            maximumValue: "<",
            customHeight: "=",
            customFontSize: "<",
            customCutoutPercentage: "<",
            colorInverse: "<",
            isScoreColored: "<",
            hidePercentageNumber: "<"
        },
    });

    controller.$inject = ['$scope'];

    function controller($scope) {
        var vm = $scope;
        var $this = this;
        $this.$onInit = function () {
            vm.customCutoutPercentage = $this.customCutoutPercentage || 85;
            vm.isScoreColored = $this.isScoreColored || false;
            vm.customClass = $this.customClass;
            vm.chartOpts = {
                responsive: true,
                aspectRatio: 1,
                animation: {
                    duration: 1000,
                },
                showTooltips: false,
                circumference: 2 * Math.PI,
                rotation: -0.5 * Math.PI,
                cutoutPercentage: $this.customCutoutPercentage || 85,
                legend: {
                    display: false
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                tooltips: {
                    enabled: false
                },
                segmentShowStroke: false,
                hover: false
            };


        };
        vm.$watch('$ctrl.customClass', function (customClass) {
            vm.customClass = customClass;
        });
        $this.$onChanges = function () {
            var colorInverse = this.colorInverse ? this.colorInverse : false;
            if (!this.currentValue) {
                this.currentValue = 0;
            }

            this.currentValue = (this.currentValue > this.maximumValue) ? this.maximumValue : this.currentValue;


            $scope.circularProgressBarData = {
                percentage: Math.floor(this.currentValue / this.maximumValue * 100),
                get color() {
                    return getColorFromPercentage(this.percentage);

                }
            };

            var fillCircularProgressBar = function (percentageToFill) {
                var chartData = [];
                var chartColors = [];
                var percentageToNotFill = 100 - percentageToFill;

                chartData.push(percentageToFill);
                chartColors.push($scope.circularProgressBarData.color);

                chartData.push(percentageToNotFill);
                chartColors.push("#EAEBED");

                $scope.chartData = chartData;
                $scope.chartColors = chartColors;
            };
            fillCircularProgressBar($scope.circularProgressBarData.percentage);
        };

        vm.getColorFromPercentage = getColorFromPercentage;

        function getColorFromPercentage(score) {
            if (score == null) {
                score = parseInt(Math.floor($this.currentValue / $this.maximumValue * 100));
            }

            if($this.colorInverse){

                if(score > 89) {
                    return '#1ba005';
                }
                if(score > 69) {
                    return '#aada2b';
                }
                if(score > 49) {
                    return '#ffbe02';
                }
                if(score > 29) {
                    return '#ef7a24';
                }
                if(score > 10) {
                    return '#bd462e';
                }
                return '#c11616';
            }
            if(score > 89) {
                return '#c11616';
            }
            if(score > 69) {
                return '#bd462e';
            }
            if(score > 49) {
                return '#ef7a24';
            }
            if(score > 29) {
                return '#ffbe02';
            }
            if(score > 10) {
                return '#aada2b';
            }
            return '#1ba005';
        }

    }

});


'use strict';
define(['app'], function (app) {
    app.component('stImportCsv', {
        templateUrl: 'app/components/st-import-csv/st-import-csv.html',
        controller: controller,
        bindings: {
            onImport: '=',
            csvObjectField: '<',
            buttonClass: '<',
            buttonText: '<',
            buttonIcon: '<',
        }
    });

    controller.$inject = ['$scope', '$mdToast', '$translate'];

    function controller($scope, $mdToast, $translate) {
        var ctrl = this;
        $scope.isLoading = false;

        $scope.uploadFiles = function (file) {
            if (!file) {
                return;
            }

            $scope.isLoading = true;
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function () {
                if (!reader.result) {
                    return;
                }

                var arrayOfObjects = csvJSON(reader.result);
                var keywords = arrayOfObjects;
                if (ctrl.csvObjectField) {
                    keywords = getFieldFromRows(arrayOfObjects);
                }

                $scope.isLoading = false;
                ctrl.onImport(keywords);
            };
        };

        function getFieldFromRows(rows) {
            var columns = [];

            if (!rows || rows.length <= 0) {
                return [];
            }

            if (!ctrl || !ctrl.csvObjectField || !rows[0][ctrl.csvObjectField]) {
                var firstKey = Object.keys(rows[0])[0];

                if (!firstKey) {
                    return [];
                }

                for (var i = 0; i < rows.length; i++) {
                    columns.push(rows[i][firstKey]);
                }

                return columns;
            }

            for (var i = 0; i < rows.length; i++) {
                columns.push(rows[i][ctrl.csvObjectField]);
            }

            return columns;
        }

        function csvJSON(csv) {
            var lines = csv.split('\n');
            var result = [];

            if (lines && lines.length > 0) {
                // It's only an array of keywords
                if (lines && lines.length === 1) {
                    var keywords = lines[0].split(',');
                    for (var i = 0; i < keywords.length; i++) {
                        result.push({
                            keyword: keywords[i],
                        });
                    }
                } else {
                    var headers = getFormattedCsvHeader(lines[0]);
                    for (var i = 1; i < lines.length; i++) {
                        if (!lines[i]) {
                            continue;
                        }

                        var obj = {};
                        var currentline = lines[i].split(',');

                        for (var j = 0; j < headers.length; j++) {
                            obj[headers[j]] = currentline[j];
                        }

                        result.push(obj);
                    }
                }
            }


            if (result.length <= 0) {
                $mdToast.show({
                    template: '<md-toast layout="row" flex class="md-toast">' +
                        '   <div><i class="icon-x st-red m-r-sm"></i></div>' +
                        '   <div>' + $translate.instant('No keywords found') + '</div>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
            }

            return result;
        }

        function getFormattedCsvHeader(firstLine) {
            var headers = [];
            var rawTitles = firstLine.split(',');

            for (var i = 0; i < rawTitles.length; i++) {
                var formattedTitle = rawTitles[i].toLowerCase();
                headers.push(formattedTitle);
            }

            return headers;
        }
    }
});

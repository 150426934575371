'use strict';
define([], function () {
    function Controller($scope, $mdDialog, keywordlistName, keywordListId, KeywordListService, $state, $translate, $mdToast) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };
        $scope.keywordList = {
            name: keywordlistName,
        };
        $scope.duplicatedNameKeywordListError = true;

        $scope.changeNameKeywordList = function () {
            $scope.duplicatedNameKeywordListError = false;
        };

        function answerDialog(nameKeywordList) {
            $scope.loading.post = true;
            KeywordListService.updateKeywordList(nameKeywordList, keywordListId).then(function (res) {
                $state.reload();
                $scope.loading.post = false;
                $mdDialog.hide();
            }).catch(function (err) {
                console.log(err)
                $scope.loading.post = false;
                $scope.duplicatedNameKeywordListError = true;

                $mdToast.show({
                    template:
                        '<md-toast layout="row" flex class="md-toast">' +
                        '   <div><i class="icon-x st-err m-r-sm"></i></div>' +
                        '   <div>' + $translate.instant(err.data.error.message) + '&nbsp;</div>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
            });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }
    }

    Controller.$inject = ['$scope', '$mdDialog', 'keywordlistName', 'keywordListId', 'KeywordListService', '$state', '$translate', '$mdToast'];
    return Controller;
});

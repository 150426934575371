'use strict';
define([], function () {
    function Controller($scope, $mdDialog, keywordListId, KeywordListService, $state, $translate, $mdToast, TrackEventService) {
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;
        $scope.loading = {
            post: false
        };

        function answerDialog(nameKeywordList) {
            $scope.loading.post = true;
            if (!nameKeywordList || nameKeywordList.length < 1) {
                $scope.loading.post = false;
                return;
            }
            KeywordListService.cloneKeywordList(nameKeywordList, keywordListId).then(function (res) {
                TrackEventService.event(
                    'Suite Usages',
                    'Keyword List Manager - List Created');
                TrackEventService.gaV2Event(
                    'list_create',{
                        tool: "Keyword List Manager"
                    });
                $state.reload();
                $scope.loading.post = false;
                $mdDialog.hide();
            }).catch(function (err) {
                $scope.loading.post = false;
                $scope.duplicatedNameKeywordListError = true;

                $mdToast.show({
                    template:
                        '<md-toast layout="row" flex class="md-toast">' +
                        '   <div><i class="icon-x st-err m-r-sm"></i></div>' +
                        '   <div>' + $translate.instant(err.data.error.message) + '&nbsp;</div>' +
                        '</md-toast>',
                    hideDelay: 5000,
                    position: 'top right'
                });
            });
        }

        function hideDialog() {
            $mdDialog.hide();
        }

        function cancelDialog() {
            $mdDialog.cancel();
        }

    }

    Controller.$inject = ['$scope', '$mdDialog', 'keywordListId', 'KeywordListService', '$state', '$translate', '$mdToast', 'TrackEventService'];
    return Controller;
});

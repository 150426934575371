'use strict';
define(['angular'], function (ng) {
    var defaultLimit = 100;

    function Controller($scope, $stateParams, $q, results, jobId, Pages, $rootScope, $translate) {
        var vm = $scope;
        vm.pages = results.data;
        vm.query = '';
        vm.jobId = jobId;
        vm.pagination = {
            numElements: results.totalRows,
            offset: 0,
            callback: pagingCallback
        };
        vm.currentSearch = {
            placeholder: $translate.instant('seospider.search.pages'),
            callback: searchCallback
        };
        $rootScope.$on('languageChanged', function () {
            vm.currentSearch = {
                placeholder: $translate.instant('seospider.search.pages'),
                callback: searchCallback
            };
        });

        function searchCallback(query) {
            vm.query = query;
            vm.pagination.offset = 0;
            Pages.findAll({
                textLevel: 'med',
                q: vm.query,
                jobId: $stateParams.sessionId,
                fields: ['id', 'url', 'title', 'depth', 'wordCount', 'ratioTextCode'],
            }).then(function (res) {
                vm.pagination.offset = 0;
                vm.pagination.numElements = res.totalRows;
                vm.pages = res.data;
            }, function (err) {
                vm.pages = err.data;
            });
        }

        function pagingCallback(limit, offset) {
            return Pages.findAll({
                textLevel: 'med',
                q: vm.query,
                limit: limit,
                offset: offset,
                jobId: $stateParams.sessionId,
                fields: ['id', 'url', 'title', 'depth', 'wordCount', 'ratioTextCode'],
            }).then(function (res) {
                vm.pages = res.data;
                return res.data;
            }, function (err) {
                vm.pages = err.data;
                return err.data;
            });
        }
    }

    Controller.$inject = ['$scope', '$stateParams', '$q', 'results', 'jobId', 'SeospiderPagesService', '$rootScope', '$translate'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/seochecker/results/overview/overview.controller',
],function(angular,SEOCheckerOverviewController){
    var app=angular.module('app.seochecker.results.overview', []);
    app.controller('SEOCheckerOverviewController',SEOCheckerOverviewController);

    
    return app;
});

'use strict';
define([
    'angular',
    'modules/backlink-checker/tool/tool.controller',
], function (
    angular,
    BacklinkCheckerToolCtrl) {
    var app = angular.module('app.backlinkchecker.tool', []);
    app.controller('BacklinkCheckerToolCtrl',BacklinkCheckerToolCtrl);
    return app;
});

'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/canonical/defined/defined.controller',
],function(
    angular,
    SPCanonicalDefinedCtrl
){
    var app=angular.module('app.seospider.exam.analysis.canonical.defined', []);
    app.controller("SPCanonicalDefinedCtrl",SPCanonicalDefinedCtrl);
    return app;
});
'use strict';
define(['angular'], function (angular) {
    var controller = function ($scope, $sce, $compile, stAlertLimit, AuthService, $mdMedia, LanguageService, UserService) {
        var ctrl = this;
        var vm = $scope;
        ctrl.takeValue = false;
        ctrl.takeFormat = false;
        ctrl.existProblem = false;
        ctrl.title = '';
        ctrl.nameWrapper = '';
        vm.$mdMedia = $mdMedia;
        ctrl.languageUsed = LanguageService.getLanguageCodeSaveOnStorage();
        ctrl.showWeHelpYou = false;

        ctrl.$onInit = function () {
            var userData = AuthService.getSessionUser();
            UserService.limits(true)
                .then(function (res) {
                    if (res && res.data && res.data.account) {
                        if (
                            userData &&
                            (res.data.account === 'offline' || res.data.account === 'online') &&
                            (!userData.jobTitle || userData.jobTitle !== 'Digital Agency')
                        ) {
                            ctrl.showWeHelpYou = true;
                        }
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });

        };

        ctrl.hiddenWrapper = function (nameWrapper, e) {
            e.preventDefault();
            angular.element(document.querySelector('#wrapper-' + nameWrapper)).addClass('hidden');
        };

        ctrl.openTrial = function () {
            stAlertLimit.open('auth-login', {
                error: {
                    request: {
                        alias: 'seo-checker',
                        offline: false,
                    }
                }
            });
        };

        $scope.$watch('$ctrl.exam', function (exam) {
            if (!exam || !exam.id) {
                return;
            }

            ctrl.nameWrapper = exam.id;
        });

        $scope.$watch('$ctrl.title', function (title) {
            if (typeof title === 'string') {
                ctrl.title = title.replace('&lt;', '<').replace('&gt;', '>');
            }
        });

        $scope.$watch('$ctrl.problem', function (problem) {
            ctrl.existProblem = problem ? true : false;
        });

        vm.$watch('$ctrl.data', function () {
            ctrl.formattedHtml = $sce.trustAsHtml(ctrl.data.format);
            ctrl.takeFormat = true;
            if (ctrl.data.format != null && ctrl.data.format.match(new RegExp(/^\$1+$/gmi))) {
                ctrl.takeFormat = false;
                if (ctrl.data.args !== undefined && ctrl.data.args.length >= 1) {
                    for (var i = 0; i < ctrl.data.args.length; i++) {
                        if (angular.equals({}, ctrl.data.args[i].value) && ctrl.data.args[i].type !== 'card')
                            ctrl.takeValue = false;
                        else ctrl.takeValue = true;

                        if (ctrl.data.args[i].type === 'old-table') {
                            var rows = ctrl.data.args[i].value.rows;
                            angular.forEach(rows, function (row, index) {
                                for (var j = 0; j < row.length; j++) {
                                    if (row[j] === true) {
                                        ctrl.data.args[i].value.rows[index][j] = $sce.trustAsHtml("<i class='material-icons st-green fa-check'>done</i>");
                                    }
                                    if (row[j] === false) {
                                        ctrl.data.args[i].value.rows[index][j] = $sce.trustAsHtml("<i class='material-icons st-grey-2 fa-times'>clear</i>");
                                    }
                                }
                            });
                        }
                        ctrl.formattedClass = "api-textual-type-" + ctrl.data.args[i].type;
                    }
                }
            } else if (ctrl.data.format != null && ctrl.data.format.match(new RegExp(/\$[0-9]/gmi))) {
                ctrl.takeFormat = true;
                if (ctrl.data.args != undefined && ctrl.data.args.length >= 1) {
                    var formattedHtml = ctrl.data.format;
                    for (var i = 0; i < ctrl.data.args.length; i++) {
                        ctrl.formattedClass = "api-textual-type-" + ctrl.data.args[i].type; // Non viene usata bhoo
                        if (!(angular.equals({}, ctrl.data.args[i].value))) {
                            switch (ctrl.data.args[i].type) {
                                case 'list':
                                    var listHtml = '<ul>';
                                    for (var j = 0; j < ctrl.data.args[i].value.length; j++) {
                                        listHtml = listHtml + '<li>' + ctrl.data.args[i].value[j] + '</li>';
                                    }
                                    listHtml = listHtml + '</ul>';
                                    formattedHtml = formattedHtml.replace(new RegExp(eval("/\\$" + (i + 1) + "/gmi")),
                                        listHtml);
                                    ctrl.formattedHtml = $sce.trustAsHtml(formattedHtml);
                                    break;
                                case 'button':
                                case 'table':
                                    ctrl.takeFormat = false;
                                    ctrl.takeValue = true;
                                    break;
                                default:
                                    formattedHtml = formattedHtml.replace(new RegExp(eval("/\\$" + (i + 1) + "/gmi")),
                                        ctrl.data.args[i].value);
                                    ctrl.formattedHtml = $sce.trustAsHtml(formattedHtml);
                                    break;
                            }
                        }
                    } // end.for
                } // end.elseIf
            }
        });
    };
    controller.$inject = ['$scope', '$sce', '$compile', 'stAlertLimitService', 'AuthService', '$mdMedia', 'LanguageService', 'UserService'];
    return {
        templateUrl: 'app/components/api-textual-rendering/api-textual-rendering.html',
        bindings: {
            data: '<',
            exam: '<',
            index: '<',
            problem: '<',
            title: '<'
        },
        controller: controller
    };
});

'use strict';
define([], function () {
    function SocialController($scope, $state) {
        if ($scope.loading) {
            $state.go('app.seochecker.results.overview');
            return;
        }
        $scope.$watch('social', function (social) {});
    }
    SocialController.$inject = ['$scope', '$state'];
    return SocialController;
});

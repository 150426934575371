'use strict';
define([], function () {
    function Controller(
        AuthService,
        $scope,
        $stateParams,
        $state,
        $mdToast,
        $translate,
        ProjectsService,
        $element,
        LanguageService,
        $debounce,
        stAlertLimitService,
        UserService,
        KeywordTrackerService,
        TrackEventService,
        $rootScope,
        CRMService,
    ) {
        var stepKeys = {
            'create-project': 1,
            'schedule-audits': 2,
            'add-keywords': 3,
            'set-locations': 4,
            'choose-competitors': 5
        };
        var vm = $scope;
        vm.isFirstProject = $stateParams.firstproject;


        // Keywords
        vm.keywords = [];
        vm.keywordsLimit = 15;
        vm.keywordsAlreadyAddedCounter = 0;
        vm.keywordsCount = vm.keywords.length;

        // Locations
        vm.locations = [{
            searchEngineId: 1,
            countryCode: LanguageService.getCountryCodeSaveOnStorage() || 'US',
            deviceId: 1
        }];
        vm.locationsCount = vm.locations.length || 1;

        // Competitors
        vm.competitors = [];
        vm.suggestedCompetitors = [];

        vm.schedule = {
            crawlerNow: true,
            runScheduled: false,
            respectRobots: true,
            maxDepth: '∞',
            resourcesLimit: 100,
            userAgent: 'STO',
            speed: 5,
            scheduleFrequency: 'monthly',
            scheduleStartDay: 1,
            scheduleStartHour: 1,
            scheduleTimezoneOffset: 0,
        };

        vm.steps = setStepsWizard();
        vm.tool = $stateParams.tool;
        vm.showCustomToast = showCustomToast;
        vm.step = stepKeys[$stateParams.step] ? stepKeys[$stateParams.step] : 1;

        if ($scope.step !== 1) {
            $state.go('app.project.wizard', {
                tool: $scope.tool,
                step: 'create-project'
            });
            return;
        }

        vm.loading = {
            exceeded: false,
            post: false,
            suggestedKeywords: false
        };

        var projectFromLocalStorate = JSON.parse(localStorage.getItem('project') || '{}');
        if (!$stateParams.url) {
            vm.project = {
                name: (projectFromLocalStorate && projectFromLocalStorate.name) || '',
                url: (projectFromLocalStorate && projectFromLocalStorate.url) || '',
                protocol: 'both',
                domainType: 'domain_with_subdomains',
            };
        } else {
            vm.project = {
                name: getNameFromURL($stateParams.url),
                url: cleanUrl($stateParams.url),
                protocol: 'both',
                domainType: 'domain_with_subdomains',
            };
        }

        function getNameFromURL(url) {
            if (!url) {
                return '';
            }
            url = cleanUrl(url);
            if (url.indexOf('.') !== -1 && url.indexOf('.') !== url.length - 1) {
                url = url.split('.')[0];
            }
            return url;
        }

        function cleanUrl(url) {
            return url.replace('https://', '').replace('http://', '').replace('www.', '');
        }

        var stoTool = $stateParams.tool === 'seo-spider' ? 'SEO Spider' : 'Rank Tracker';

        function getSuggestedKeywords(url) {
            vm.loading.suggestedKeywords = true;
            ProjectsService.suggestedKeywords(url)
                .then(function (res) {
                    vm.loading.suggestedKeywords = false;
                    if (res && res.data && res.data.keywords && !$scope.keywords.length) {
                        $scope.keywords = res.data.keywords;
                    }

                })
                .catch(function (err) {
                    vm.loading.suggestedKeywords = false;
                    console.log(err);
                });
        }

        function getSuggestedCompetitors(url, keyword) {
            ProjectsService.suggestedCompetitors(url, keyword)
                .then(function (res) {
                    $scope.suggestedCompetitors = [];
                    if (res && res.data && res.data.competitors) {
                        angular.forEach(res.data.competitors, function (suggestedCompetitor) {
                            $scope.suggestedCompetitors.push({
                                url: suggestedCompetitor,
                                domainType: 'domain_with_subdomains',
                            })
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
        }

        function getLimits() {
            UserService.limits()
                .then(function (res) {
                    if (!res || !res.data || !res.data.limits || !res.data.limits.length) {
                        return;
                    }

                    angular.forEach(res.data.limits, function (service) {
                        if (service.alias === 'rank-tracker-keywords' && service.qtyAllowed) {
                            $scope.keywordsLimit = service.qtyAllowed;
                        }
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    return null;
                });

            KeywordTrackerService.keywordCountTracker()
                .then(function (res) {
                    if (!res || !res.data || !res.data.keywords) {
                        return;
                    }

                    $scope.keywordsAlreadyAddedCounter = res.data.keywords;
                })
                .catch(function (err) {
                    console.log(err)
                });
        }

        $scope.goToPreviousStep = function () {
            var sourceRoute = $stateParams.tool === 'seo-spider' ? 'app.seospider.list' : 'app.keywordtracker.landing';
            if ($scope.step === 1) {
                $state.go(sourceRoute);
            } else {
                $scope.step = $scope.step - 1;
                updateUrlParams($scope.step);
            }

            if ($scope.step === 3) {
                $scope.keywordsExceeded();
            }

            if ($scope.step === 4) {
                $scope.locationsExceeded();
            }
        };

        $scope.goToNextStep = function () {
            // $debounce is used to avoid multiple rapid clicks
            $debounce(goToNextStepAction, 400);
        };

        function goToNextStepAction() {
            if ($scope.step === 1) {
                if (!isValidUrl($scope.project.url) || !$scope.project.name) {

                    if (!isValidUrl($scope.project.url)) {
                        $scope.projectForm.url.$setValidity("pattern", false);
                    }

                    if (!$scope.project.name) {
                        $scope.projectForm.name.$setValidity("pattern", false);
                    }

                    return;
                }
                // Set name project for search console landing
                localStorage.setItem('projectName', $scope.project.name)

                localStorage.setItem('project', JSON.stringify($scope.project));
                if (!AuthService.isAuthenticated()) {
                    stAlertLimitService.open('auth-login', {
                        error: {
                            request: {
                                alias: 'seo-project',
                                offline: false,
                            }
                        },
                        canClose: false,
                    });
                    return;
                }

                localStorage.removeItem('project');
                if ($stateParams.tool !== 'seo-spider') {
                    getSuggestedKeywords($scope.project.url);
                }

                getNameProject($scope.project.name);
                getLimits();
                return;
            }

            if ($scope.step === 2 && $stateParams.tool === 'seo-spider') {
                createAProject();
                return;
            }

            if ($scope.step === 3 && $scope.keywords.length === 0) {
                showCustomToast('project.wizard.add.one.keyword', 'icon-x st-red');
                return;
            }

            if ($scope.step === 4 && $scope.locations.length === 0) {
                showCustomToast('project.wizard.add.one.configuration', 'icon-x st-red');
                return;
            }

            if ($scope.step === 5) {
                createAProject();
                return;
            }

            $scope.step = $scope.step + 1;
            updateUrlParams($scope.step);

            if ($scope.step === 3) {
                $scope.keywordsExceeded();
            }

            if ($scope.step === 4) {
                $scope.locationsExceeded();

                if ($scope.keywords && $scope.keywords.length > 0) {
                    getSuggestedCompetitors($scope.project.url, $scope.keywords[$scope.keywords.length - 1]);
                }
            }
        }

        function setStepsWizard() {
            var stepsDefault = [{
                    stepNumber: 1,
                    stepLabel: 'project.wizard.create.a.project'
                },
                {
                    stepNumber: 2,
                    stepLabel: 'project.wizard.schedule.audits'
                },
            ];

            if ($stateParams.tool === 'seo-spider') {
                return stepsDefault;
            }

            stepsDefault.push({
                stepNumber: 3,
                stepLabel: 'project.wizard.add.keywords'
            }, {
                stepNumber: 4,
                stepLabel: 'project.wizard.set.locations'
            }, {
                stepNumber: 5,
                stepLabel: 'project.wizard.choose.competitors'
            });
            return stepsDefault;
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template: '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: $element[0].querySelector('.toast-show')
            });
        }

        function createAProject() {
            $scope.loading.post = true;
            var project = {
                name: $scope.project.name,
                protocol: $scope.project.protocol,
                url: $scope.project.url,
                domainType: $scope.project.domainType,
                crawlerNow: $scope.schedule.crawlerNow,
                crawler: {
                    respectRobots: $scope.schedule.respectRobots,
                    maxDepth: $scope.schedule.maxDepth,
                    resourcesLimit: $scope.schedule.resourcesLimit,
                    userAgent: $scope.schedule.userAgent,
                    speed: $scope.schedule.speed,
                    scheduleFrequency: $scope.schedule.scheduleFrequency,
                    scheduleStartDay: $scope.schedule.scheduleStartDay,
                    scheduleStartHour: $scope.schedule.scheduleStartHour,
                    scheduleTimezoneOffset: $scope.schedule.scheduleTimezoneOffset,
                    runScheduled: $scope.schedule.runScheduled ? 1 : 0,
                },
                keywords: $scope.keywords,
                locations: $scope.locations,
                competitors: $scope.competitors
            };

            ProjectsService.createProject(project)
                .then(function (res) {
                    var toastMessage = $scope.isFirstProject ? 'project.wizard.first.project.saved' : 'project.wizard.project.saved';
                    showCustomToast(toastMessage, 'icon-checkmark st-green');
                    $scope.loading.post = false;
                    var eventLabel = $rootScope.stoFrom ? stoTool + ' - ' + $rootScope.stoFrom : stoTool;
                    TrackEventService.event(
                        'Suite Usages',
                        'SEO Project - Created', eventLabel);
                    TrackEventService.gaV2Event(
                        'project_create', {
                            from: $rootScope.stoFrom,
                            type: stoTool
                        });

                    CRMService.addEvents(['ProjectCreatedEvent'], [
                        'ProjectToConfigEvent',
                        'ProjectToCreateEvent',
                    ]);

                    if ($scope.schedule.crawlerNow) {
                        TrackEventService.event(
                            'Suite Usages',
                            'SEO Project - Start Crawl', eventLabel);
                        TrackEventService.gaV2Event(
                            'crawl_start', {
                                from: $rootScope.stoFrom
                            });
                    }
                    setTimeout(function () {
                        goToProject(res.data),
                            1000
                    });
                }).catch(function (err) {
                    showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                    $scope.loading.post = false;
                    console.log(err);
                });
        }

        function goToProject(data) {
            var sourceRouteDefault;
            var sourceRouteSuccess;
            var detailsObj;

            if (data.crawlerSessionId === null && ($stateParams.tool !== 'seo-spider')) {
                sourceRouteDefault = 'app.keywordtracker.landing';
                sourceRouteSuccess = 'app.keywordtracker.overview';
                detailsObj = {
                    action: 'GET',
                    project: data.trackerProjectId,
                    firstLoad: 'true',
                    onBoarding: 'true'
                };
            } else if (data.crawlerSessionId === null && ($stateParams.tool === 'seo-spider' || $rootScope.stoFrom === 'dashboard' || $rootScope.stoFrom === 'thank-you')) {
                sourceRouteDefault = 'app.seospider.list';
                sourceRouteSuccess = sourceRouteDefault;
                detailsObj = {};
            } else if (data.crawlerSessionId !== null && ($stateParams.tool === 'seo-spider' || $rootScope.stoFrom === 'dashboard' || $rootScope.stoFrom === 'thank-you')) {
                sourceRouteDefault = 'app.seospider.list';
                sourceRouteSuccess = 'app.seospider.exam.analysis.report.overview';
                detailsObj = {
                    projectId: data.crawlerProjectId,
                    sessionId: data.crawlerSessionId,
                };
            } else {
                sourceRouteDefault = 'app.keywordtracker.landing';
                sourceRouteSuccess = 'app.keywordtracker.overview';
                detailsObj = {
                    action: 'GET',
                    project: data.trackerProjectId,
                    firstLoad: 'true',
                    onBoarding: 'true'
                };
            }

            if (data.trackerProjectId) {
                localStorage.removeItem('project-filters' + data.trackerProjectId);
            }

            if (sourceRouteSuccess) {
                $state.go(
                    sourceRouteSuccess,
                    detailsObj
                );
            } else {
                // as a fallback redirect to the default route after creation
                $state.go(sourceRouteDefault);
            }
        }

        function getNameProject(projectName) {
            $scope.loading.post = true;
            ProjectsService.getByName(projectName)
                .then(function (res) {
                    if (res && res.data && res.data.project) {
                        showCustomToast('project.wizard.this.name.already.exists', 'icon-x st-red');
                        $scope.loading.post = false;
                        $element[0].querySelector('#name-input').focus();
                        return;
                    }

                    $scope.loading.post = false;
                    $scope.step = $scope.step + 1;
                    updateUrlParams($scope.step);
                })
                .catch(function (err) {
                    $scope.loading.post = false;
                    $element[0].querySelector('#name-input').focus();
                    console.log(err);
                });
        }

        function updateUrlParams(step) {
            var params = {
                step: getKeyByValue(stepKeys, step),
                tool: $stateParams.tool
            };
            $state.transitionTo('app.project.wizard', params, {
                notify: false
            });
            $stateParams = params;
        }

        function getKeyByValue(object, value) {
            return Object.keys(object).filter(function (key) {
                return object[key] === value;
            })[0];
        }

        function isValidUrl(urlString) {
            var regex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, "i");
            return regex.test(urlString);
        }

        $scope.isLimitExceeded = function () {
            return ($scope.keywordsCount * $scope.locationsCount) + $scope.keywordsAlreadyAddedCounter > $scope.keywordsLimit;
        };

        $scope.keywordsExceeded = function () {
            $scope.keywordsCount = $scope.keywords.length;
            $scope.loading.exceeded = $scope.isLimitExceeded() && $scope.locations.length <= 1;
        };

        $scope.locationsExceeded = function () {
            $scope.locationsCount = ($scope.locations && $scope.locations.length) || 1;
            $scope.loading.exceeded = $scope.isLimitExceeded();
        }

        $scope.$watchCollection('keywords', function () {
            if ($scope.step === 3) {
                $scope.keywordsExceeded();
            }
        });

        $scope.$watchCollection('locations', function (locations) {
            if ($scope.step === 4) {
                $scope.locationsExceeded();
            }
        });
    }

    Controller.$inject = [
        'AuthService',
        '$scope',
        '$stateParams',
        '$state',
        '$mdToast',
        '$translate',
        'ProjectsService',
        '$element',
        'LanguageService',
        '$debounce',
        'stAlertLimitService',
        'UserService',
        'KeywordTrackerService',
        'TrackEventService',
        '$rootScope',
        'CRMService',
    ];
    return Controller;
});

'use strict';
define([], function () {
    function controller($scope) {
        var ctrl = this;
        ctrl.locationsSelected = [];

        $scope.toggle = function (item, list) {
            var idx = list.findIndex(function (a) {
                return JSON.stringify(a) === JSON.stringify(item);
            });
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item);
            }
        };

        $scope.setDevice = function (idDevice) {
            var deviceList = [{
                id: 1,
                name: 'desktop'
            }, {
                id: 2,
                name: 'mobile'
            }, ];
            var device = '';
            angular.forEach(deviceList, function (obj) {
                if (obj.id == idDevice) {
                    device = obj.name;
                }
            })
            return device;
        };

        $scope.setSearchEngine = function (idSearchEngine) {
            var listSearchEngine = [{
                id: 1,
                name: 'Google'
            }, {
                id: 2,
                name: 'Yahoo'
            }, {
                id: 3,
                name: 'Yandex'
            }, {
                id: 4,
                name: 'Bing'
            }];

            var searchEngine = '';
            angular.forEach(listSearchEngine, function (obj) {
                if (obj.id == idSearchEngine) {
                    searchEngine = obj.name;
                }
            })
            return searchEngine;
        };

        $scope.exists = function (item, list) {
            var idx = JSON.stringify(list).indexOf(JSON.stringify(item));
            return idx > -1;
        };

        $scope.$watch('$ctrl.locations', function (locations) {
            ctrl.locations = locations;
        });

        $scope.$watchCollection('$ctrl.locationsSelected', function (locationsSelected) {
            if (locationsSelected && ctrl.locations) {
                ctrl.locationsSelected = locationsSelected.filter(function (locationSelected) {
                    return ctrl.locations.some(function (location) {
                        return JSON.stringify(location) == JSON.stringify(locationSelected);
                    })
                })
            }
        });

        $scope.filterLocations = function ($event, value) {
            var locationResearched = value.toUpperCase();
            var locations = document.getElementsByClassName("locations__item");
            for (var i = 0, j = locations.length; i < j; i += 1) {
                var txtValue = locations[i].textContent || locations[i].innerText;
                if (txtValue.toUpperCase().indexOf(locationResearched) > -1) {
                    locations[i].style.display = "";
                } else {
                    locations[i].style.display = "none";
                }
            }
        }
    }

    controller.$inject = ['$scope'];

    return {
        templateUrl: 'app/modules/keywordtracker/components/locations/locations.html',
        bindings: {
            locations: '<',
            locationsSelected: '=',
            project: '<'
        },
        controller: controller,
    };
});

'use strict';
define([], function () {
    function controller($window, $scope, CheckoutService, TrackEventService, CountriesService, TaxService, $q) {
        var ctrl = this;
        var vm = $scope;

        vm.objectPlan = {};
        vm.planRawData = {};
        vm.isValid = false;

        /**
         * @desc check Raw Data Plan for see exist all data we need
         * @returns {boolean}
         */
        function validateDataCheck(planRawData) {
            if (!planRawData) {
                return false;
            }
            /**
             * Lock Button only if Plan don't have at same time each ID
             */
            if (planRawData.paypalPlanId === false && planRawData.paypalTrialPlanId === false) {
                return false;
            }
            if (planRawData.state !== "available") {
                return false;
            }

            return !isNaN(planRawData.amountDecimal);
        }

        /**
         * @name init
         * More Important: See documentation on Jira STO-2309
         */
        function setSubscription() {
            var planRawData = vm.plan;

            if (!validateDataCheck(planRawData)) {
                return 'Error validation';
            }

            /**
             * SUBSCRIPTION SETTINGS
             */
            var planPriceValue = Number.parseFloat(planRawData.amountDecimal);
            var currencyCode = planRawData.currency.toUpperCase();
            var interval = planRawData.interval.toUpperCase(); // YEAR - MONTH - WEEK - DAY
            var totalTax = getTax(ctrl.order, planRawData);
            var taxes = {
                percentage: totalTax.tax,
                inclusive: false
            };

            /**
             * COLLECTION DATA FOR PLAN
             */
            var objectPlan = {};
            objectPlan.name = planRawData.name;

            /**
             * COUPON SETTINGS
             */
            var existCoupon = false;
            var couponValue = 0;
            var amountWithCoupon = 0;
            var intervalCouponUnit = 'DAY';
            var intervalCountCoupon = 1;
            var isForEver = false;

            // Exist coupon
            if (ctrl.coupon != null &&
                ctrl.coupon.data != null &&
                ctrl.coupon.discount != null &&
                ctrl.coupon.discount !== 0) {

                // check is number
                if (!isNaN(ctrl.coupon.discount)) {
                    // Amount Discount
                    couponValue = Number.parseFloat(ctrl.coupon.discount);
                    amountWithCoupon = Number.parseFloat(planRawData.amountDecimal - couponValue);

                    // Verify results
                    var isPositive = Math.sign(amountWithCoupon);
                    if (isPositive === -1) {
                        amountWithCoupon = 0;
                        // return 'Amount with Coupon is Negative';
                    }
                    existCoupon = true;
                }
            }

            /**
             * TODO ctrl.coupon.duration_in_months <---- ask for it
             * TODO management case multiple coupon <--- tech debit
             * Verify Modality of Coupon
             */
            if (existCoupon && ctrl.coupon && ctrl.coupon.data && ctrl.coupon.data.duration) {
                if (ctrl.coupon.data.duration === "once") {
                    intervalCouponUnit = interval;
                    intervalCountCoupon = 1;
                } else if (ctrl.coupon.data.duration === "forever") {
                    intervalCouponUnit = interval;
                    intervalCountCoupon = 1;
                    isForEver = true;
                }
            }

            /**
             * Exist Coupon and is it Forever
             * Override Price on all case
             */
            if (existCoupon && isForEver) {
                planPriceValue = Number.parseFloat(amountWithCoupon);
            }

            /**
             * SETTINGS FREQUENCY
             * OBJECTS FOR PAYPAL PLAN
             *
             * TODO for the future if you want change DAY need variable get from backend (if exist it) <--- tech debit
             * TODO vitto tell me can exist 3 Month of trial <--- tech debit
             */
            var frequencyTrial = {
                interval_unit: 'DAY',
                interval_count: (planRawData.trialPeriodDays + 1), // es: 15 DAY of Trial
            };

            var frequencyCoupon = {
                interval_unit: (existCoupon === false) ? interval : intervalCouponUnit, // es: 'YEAR',
                interval_count: intervalCountCoupon,
            };

            var frequencySubscription = {
                interval_unit: interval, // es: YEAR - MONTH - WEEK - DAY
                interval_count: planRawData.intervalCount, // es: 1
            };

            /**
             * SETTINGS PRICE SCHEMA
             */
            var couponPriceValue = 0;
            if (planPriceValue > 0) {
                couponPriceValue = planPriceValue.toFixed(2);
            }

            if (existCoupon) {
                couponPriceValue = 0;

                if (amountWithCoupon > 0) {
                    couponPriceValue = amountWithCoupon.toFixed(2);
                }
            }

            var pricingSchemeCoupon = {
                fixed_price: {
                    value: couponPriceValue,
                    currency_code: currencyCode.toUpperCase(), // es: 'EUR'
                }
            };

            /**
             * TODO quando si cancella il Holiday Promo sullo schema dovete rimuovere il pezzo di codice
             * @type {{fixed_price: {value: *, currency_code: string}}}
             */
            var subPriceValue = 0;
            if (planPriceValue > 0) {
                subPriceValue = planPriceValue.toFixed(2);
            }

            var pricingSchemeSubscription = {
                fixed_price: {
                    value: subPriceValue,
                    currency_code: currencyCode, // es: 'EUR'
                }
            };

            /**
             * More Important: See documentation on Jira STO-2309
             *
             * IF STEP 1: TRIAL + COUPON + SUBSCRIPTION && TRIAL + SUBSCRIPTION
             * IF STEP 2: COUPON + SUBSCRIPTION && SUBSCRIPTION
             *
             * TODO STO-2485 oltre alla documentazione considerare anche che
             *  in questo passaggio recupero dal checkout l'isTrialing
             *  ma per il futuro prevedere che possa essere fatto un
             *  trial anche se l'utente lo ha già fatto
             *
             *  Il Trial è fattibile solo se Trial è True ed esiste l'ID del trial
             *  in tutte le altre casistiche andrà sul piano di default
             *
             * TODO se per la prima volta un utente acquista un Piano
             *  e scegli un piano che non prevede trial invierò l'utente a pagare
             *  e salverò sul DB la sua prima subscription
             *  (sul DB Subscription non avrà mai eseguito un Trial ma avrà 1 subscription)
             *  successivamente se l'utente va su un piano che ha il Trial quando arriverà al checkout
             *  anche se sul DB i campi del Trial non sono fullati, siccome ha almeno 1 subscrition
             *  vm.isTrialing mi torna False per tanto non avrà mai un Trial
             */

            // Set the default plan without trial
            objectPlan.plan_id = planRawData.paypalPlanId;

            // Set user id
            objectPlan.custom_id = vm.userId;

            var cycleCoupon = {
                frequency: frequencyCoupon,
                tenure_type: 'TRIAL',
                sequence: 1,
                total_cycles: 1,
                pricing_scheme: pricingSchemeCoupon,
            };

            var cycleSubscription = {
                frequency: frequencySubscription,
                tenure_type: 'REGULAR',
                sequence: 2,
                total_cycles: 0, // in our model Business is 0 = infinite
                pricing_scheme: pricingSchemeSubscription,
            };

            objectPlan.plan = {
                taxes,
                billing_cycles: [
                    cycleCoupon,
                    cycleSubscription
                ],
            };

            // Reset the plan with trial
            if (vm.isTrialing && planRawData.paypalTrialPlanId) {
                objectPlan.plan_id = planRawData.paypalTrialPlanId;
                /**
                 * CYCLE
                 */
                var cycleTrial = {
                    frequency: frequencyTrial,
                    tenure_type: 'TRIAL',
                    sequence: 1,
                    total_cycles: 1, //  // Total Cycle | 0 = infinite or 15
                    pricing_scheme: {
                        fixed_price: {
                            value: '0',
                            currency_code: currencyCode.toUpperCase(), // es: 'EUR'
                        }
                    }
                };
                cycleCoupon = {
                    frequency: frequencyCoupon,
                    tenure_type: 'TRIAL',
                    sequence: 2,
                    total_cycles: 1,
                    pricing_scheme: pricingSchemeCoupon,
                };
                cycleSubscription = {
                    frequency: frequencySubscription,
                    tenure_type: 'REGULAR',
                    sequence: 3,
                    total_cycles: 0, // in our model Business is 0 = infinite
                    pricing_scheme: pricingSchemeSubscription,
                };
                objectPlan.plan = {
                    taxes,
                    billing_cycles: [
                        cycleTrial,
                        cycleCoupon,
                        cycleSubscription,
                    ],
                };
            }

            objectPlan.subscriber = {
                "name": {
                    "given_name": vm.order.billing.billingFirstname,
                    "surname": vm.order.billing.billingLastname,
                },
                "shipping_address": {
                    "name": {
                        "full_name": vm.order.billing.billingName,
                    },
                    "address": {
                        "address_line_1": vm.order.billing.billingAddressLine1,
                        "address_line_2": vm.order.billing.billingAddressLine2,
                        "admin_area_2": vm.order.billing.billingAddressCity,
                        "admin_area_1": vm.order.billing.billingAddressState,
                        "postal_code": vm.order.billing.billingAddressZip,
                        "country_code": vm.order.billing.billingAddressCountry,
                    }
                }
            };

            vm.objectPlan = objectPlan;
            vm.planRawData = planRawData;
        }

        /**
         * @desc
         * @returns {boolean}
         */
        function getTax(order, plan) {
            var toCharge = plan.amount;
            var subtotal = toCharge;
            var taxPercent = 0;
            var taxSupply = 0;
            var tax = 0;

            var thisTax = TaxService.getTax(
                order.billing.billingAddressCountry,
                CountriesService.isEU(order.billing.billingAddressCountry),
                order.billing.billingBusinessName && order.billing.billingBusinessName.trim() !== ''
            );

            if (thisTax > 0) {
                taxPercent = thisTax / 100;
                tax = thisTax;
                taxSupply = subtotal * taxPercent;
                taxSupply = castIntegerPrice(taxSupply) <= 0 ? 0 : castIntegerPrice(taxSupply);
            }

            toCharge = toCharge + taxSupply;

            return {
                taxSupply: taxSupply,
                tax: tax,
                taxPercent: taxPercent,
                subtotal: subtotal,
                amount: toCharge
            };
        }

        /**
         * @param x
         * @returns {number}
         */
        function castIntegerPrice(x) {
            return parseInt(parseFloat(x).toFixed(0));
        }

        function beforeCallPaypal(data, actions) {
            TrackEventService.event('Funnel Events', 'Open Paypal Dialog', vm.planRawData.alias);
            TrackEventService.gaV2Event('open_paypal_dialog');

            if (ctrl.onClick && typeof ctrl.onClick === 'function') {
                var dfd = $q.defer();
                ctrl.onClick()
                    .then(function () {
                        // To set objectPlan
                        setSubscription();

                        dfd.resolve(actions.subscription.create(vm.objectPlan));
                        // dfd.resolve(CheckoutService.getPaypalActionSubscription(vm.objectPlan));
                    })
                    .catch(function (err) {
                        console.log(err);
                        dfd.reject(err);
                    });

                return dfd.promise;
            } else {
                // To set objectPlan
                setSubscription();
                return actions.subscription.create(vm.objectPlan);
                // return CheckoutService.getPaypalActionSubscription(vm.objectPlan);
            }
        }

        function chargePaypalPayment(data) {
            var dfd = $q.defer();

            data.paypalPlanId = vm.objectPlan.plan_id;
            vm.order.dealId = $window.localStorage.getItem('dealId');
            vm.order.saleOwner = $window.localStorage.getItem('saleOwner');

            vm.order.paymentMethod = {
                gateway: 'paypal',
                subscription: {
                    orderId: data.orderID,
                    subscriptionId: data.subscriptionID,
                    paypalPlanId: data.paypalPlanId,
                    paymentId: data.paymentID,
                },
            };

            CheckoutService
                .charge({
                    order: vm.order
                })
                .then(function (res) {
                    CheckoutService.saveCouponOnHistory(vm.order.coupon);
                    if (ctrl.onSuccess && typeof ctrl.onSuccess === 'function') {
                        ctrl.onSuccess(res);
                    }

                    dfd.resolve();
                })
                .catch(function (err) {
                    console.log('Error from checkout service', err);
                    if (ctrl.onFail && typeof ctrl.onFail === 'function') {
                        ctrl.onFail(err);
                    }
                    dfd.reject();
                });

            // Remove Deal Id from local Storage User after buy Plan
            $window.localStorage.removeItem('dealId');
            $window.localStorage.removeItem('saleOwner');

            return dfd.promise;
        }

        function onApprove(data) {
            if (ctrl.onApprove && typeof ctrl.onApprove === 'function') {
                return ctrl.onApprove()
                    .then(function () {
                        return chargePaypalPayment(data);
                    });
            }

            return chargePaypalPayment(data);
        }

        /**
         * @name renderButton
         * @desc this is used for Render Button PayPal
         * send data PayPal if plan is active work well all,
         * otherwise PayPal return error schema syntax.
         * onApprove: PayPal return success and we can call STO server
         * @returns {Object}
         */
        function renderButton() {
            if (!paypal || !paypal.Buttons) {
                return;
            }

            paypal.Buttons({
                style: {
                    tagline: 'false',
                    shape: 'pill',
                    color: 'silver',
                    layout: 'horizontal',
                    label: vm.isTrialing ? 'paypal' : 'pay',
                    style: 'responsive',
                    fundingicons: 'true',
                },
                createSubscription: beforeCallPaypal,
                onApprove: onApprove,
            }).render('#paypal-button');
        }

        ctrl.$onInit = function () {
            vm.order = ctrl.order;
            vm.tax = ctrl.tax; // Euro Value Tax
            vm.coupon = ctrl.coupon;
            vm.isTrialing = ctrl.isTrialing;
            vm.plan = ctrl.plan;

            renderButton();
        };

        vm.$watch('$ctrl.isTrialing', function () {
            vm.isTrialing = ctrl.isTrialing;
        });

        vm.$watch('$ctrl.plan', function () {
            vm.plan = ctrl.plan;
        });

        vm.$watch('$ctrl.order', function () {
            vm.order = ctrl.order;
        });

        vm.$watch('$ctrl.coupon', function () {
            vm.coupon = ctrl.coupon;
        });

        vm.$watch('$ctrl.tax', function () {
            vm.tax = ctrl.tax;
        });

        vm.$watch('$ctrl.isValid', function () {
            vm.isValid = ctrl.isValid;
        });

        vm.$watch('$ctrl.userId', function () {
            vm.userId = ctrl.userId;
        });
    }

    controller.$inject = ['$window', '$scope', 'CheckoutService', 'TrackEventService', 'CountriesService', 'TaxService', '$q'];
    return {
        templateUrl: 'app/modules/upgrade/components/paypal-button/paypal-button.html',
        controller: controller,
        bindings: {
            isValid: '=',
            plan: '<',
            tax: '<',
            userId: '<',
            order: '=',
            coupon: '<',
            isTrialing: '<',
            onClick: '<',
            onApprove: '<',
            onSuccess: '<',
            onFail: '<',
            update: '<',
        },
    };
});

'use strict';
define([], function () {
    function Controller(
        KeywordListService,
        $scope,
        lists,
        $mdDialog,
        $mdMedia,
        ExportService,
        $translate,
        listsLimit,
        LanguageService,
        $window
    ) {
        var defaultListsRows = {
            rows: [],
            totalRows: 0,
        };
        $scope.$mdMedia=$mdMedia;
        $scope.idsSelected = [];
        $scope.lists = lists || defaultListsRows;
        $scope.listsLimit = listsLimit;
        $scope.checkboxSelected = checkboxSelected;
        $scope.deleteKeywordList = deleteKeywordList;
        $scope.createNewKeywordList = createNewKeywordList;

        function checkboxSelected(rowsSelected) {
            $scope.idsSelected = [];
            rowsSelected.forEach(function (row) {
                $scope.idsSelected.push(row.keywordListId);
            });
        }

        $scope.exportCsvOptions = {
            stoTool: 'keyword-list-manager',
            class: 'st-blue w-full txt-noUppercase layout-row layout-align-start-center f-w-400 m-0 p-0',
            text: $translate.instant('keyword.list.component.export.csv'),
            icon: 'icon-download m-r-xs',
            fileName: 'keywordsList',
            buttonDisabled: false
        };

        function deleteKeywordList(ev, keywordListIds) {
            if (!keywordListIds.length) {
                return;
            }
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/delete-keywordlist/delete-keywordlist.html",
                controller: "KeywordListManagerDeleteKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    keywordListIds: keywordListIds,
                }
            });
        }

        function renameKeywordList(ev, keywordlistName, keywordListId) {
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/rename-keywordlist/rename-keywordlist.html",
                controller: "KeywordListManagerRenameKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    keywordlistName: keywordlistName,
                    keywordListId: keywordListId
                }
            });
        }

        function createNewKeywordList(ev) {
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/create-keywordlist/create-keywordlist.html",
                controller: "KeywordListManagerCreateKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
            });
        }

        function cloneKeywordList(ev, keywordListId) {
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/cloned-keywordlist/clone-keywordlist.html",
                controller: "KeywordListManagerCloneKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    keywordListId: keywordListId
                }
            });
        }

        function shareKeywordList(ev, tokenKeywordList, keywordListName) {
            $mdDialog.show({
                templateUrl: "app/modules/keywordlistmanager/dialogs/share-keywordlist/share-keywordlist.html",
                controller: "KeywordListManagerShareKeywordListCtrl",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {
                    tokenKeywordList: tokenKeywordList,
                    keywordListName: keywordListName
                }
            });
        }

        function setQueriesForComponents(queries) {
            var objectForComponents = {
                queriesAllFields: []
            };

            queries.forEach(function (query) {
                objectForComponents.queriesAllFields.push({
                    keyword: query.keyword,
                    avgCompetition: query.avgCompetition,
                    avgCpc: query.avgCpc,
                    avgVolume: query.avgVolume,
                    countryCode: query.countryCode,
                });
            });
            return objectForComponents;
        }

        $scope.controllerTable = {
            exportCsvOptions: $scope.exportCsvOptions,
            rename: function (event, keywordlistName, keywordListId) {
                renameKeywordList(event, keywordlistName, keywordListId);
            },
            clone: function (event, keywordListId) {
                cloneKeywordList(event, keywordListId);
            },
            delete: function (event, keywordListId) {
                deleteKeywordList(event, [keywordListId]);
            },
            share: function (event, tokenKeywordList, keywordListName) {
                shareKeywordList(event, tokenKeywordList, keywordListName)
            },
            setCsvKeywords: function (keywordListId) {
                KeywordListService.getOneKeywordList(keywordListId).then(function (res) {
                    var object = setQueriesForComponents(res.data.queries.rows);
                    $scope.exportCsvOptions.data = ExportService.convertObjectToCsvArray(object.queriesAllFields);
                }).catch(function (err) {
                    console.log(err);
                    $scope.exportCsvOptions.data = [];
                });
            }
        };

    }

    Controller.$inject = [
        'KeywordListService',
        '$scope',
        'lists',
        '$mdDialog',
        '$mdMedia',
        'ExportService',
        '$translate',
        'listsLimit',
        'LanguageService',
        '$window'
    ];
    return Controller;
});

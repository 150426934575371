'use strict';
define([], function () {
    function Controller(
        $scope,
        $stateParams,
        project,
        $state,
        $mdToast,
        $translate,
        KeywordTrackerService,
        $element,
        LanguageService
    ) {

        $scope.saveReport = saveReport;
        $scope.setReportType = setReportType;
        $scope.validateEmail = validateEmail;
        $scope.showCustomToast = showCustomToast;
        $scope.switchLang = switchLang;
       
        $scope.project = project;
        $scope.reportType = $scope.project.reportType || 'none';
        $scope.reportName = $scope.project.reportName;
        $scope.reportRecepients = $scope.project.reportRecepients ? $scope.project.reportRecepients.split(',') : [];
        $scope.reportLanguage  = $scope.project.reportLanguage || LanguageService.getCodeAndCountrySaveOnStorage();

        $scope.loading = {
            post: false
        };
       
        function switchLang(language) {
            $scope.reportLanguage = language
        }
        
        function saveReport() {
            var reportSettings = {
                reportType: $scope.reportType,
                reportName: $scope.reportName,
                reportRecepients: $scope.reportRecepients.join(','),
                reportLanguage: $scope.reportLanguage
            };
           
            KeywordTrackerService.setReport($stateParams.project, reportSettings)
                .then(function(res) {
                    showCustomToast('project.wizard.project.saved', 'icon-checkmark st-green');
                    setTimeout(function() { goToProject($stateParams.project), 2000});
                })
                .catch(function(err) { 
                    showCustomToast('project.wizard.project.not.saved', 'icon-x st-red');
                });
        }

        function goToProject(projectId) {
            $state.go(
                'app.keywordtracker.overview',
                {
                    action: 'GET',
                    project: projectId
                }
            );
        }

        function validateEmail(email) {
            if (event.which === 13) {
                var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
                if (!reg.test(email)) {                                        
                    showCustomToast('email.not.valid', 'icon-x st-red');             
                    $scope.reportRecepients.pop();
                }
            };
        };


        function setReportType(reportType) {
            $scope.reportType = reportType;
        }

        function showCustomToast(string, classIcon) {
            $mdToast.show({
                template:
                    '<md-toast layout="row" flex class="md-toast">' +
                    '   <div><i class="' + classIcon + ' m-r-sm"></i></div>' +
                    '   <div>' + $translate.instant(string) + '</div>' +
                    '</md-toast>',
                hideDelay: 5000,
                position: 'top right',
                parent: $element[0].querySelector('.toast-show')
            });
        }
    }

    Controller.$inject = [
        '$scope',
        '$stateParams',
        'project',
        '$state',
        '$mdToast',
        '$translate',
        'KeywordTrackerService',
        '$element',
        'LanguageService'
    ];
    return Controller;
});

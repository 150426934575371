'use strict';
define(['app', 'angular'], function (app, angular) {
    app.service('stToggleExpandSectionService', ['$localStorage',function ($localStorage) {
        $localStorage.$default({
            isExpanded:{}
        });
        return {
            isExpanded: function (sectionId) {
                try{
                    return $localStorage.isExpanded[sectionId];
                }catch(err){
                    return false;
                }
            },
            set: function (sectionId,isExpanded) {
                try{
                    if(!sectionId)
                        return false;
                    $localStorage.isExpanded[sectionId]=!!isExpanded;
                }catch(err){
                    return false;
                }
            }
        };
    }]);

    app.component('stToggleExpandSection', {
        templateUrl: 'app/components/st-toggle-expand-section/st-toggle-expand-section.html',
        controller: controller,
        transclude: true,
        bindings: {
            isExpanded: '=',
            direction: '<',
            sectionId: '<',
        }
    });
    controller.$inject = ['__env', 'stToggleExpandSectionService'];

    function controller(__env,history) {
        var vm = this;
        vm.$onInit = function() {
            if(this.sectionId){
                this.isExpanded=history.isExpanded(this.sectionId);
            }
        };
        vm.toggleVisibility=toggleVisibility;
        function toggleVisibility() {
            this.isExpanded=!this.isExpanded;
            if(this.sectionId){
                history.set(this.sectionId,this.isExpanded);
            }
        }
    }
});

define([], function () {
    controller.$inject = ['$scope','$mdDialog', '$state', 'WorkspaceService', '$localStorage', '$rootScope'];

    function controller($scope,$mdDialog, $state, WorkspaceService, $localStorage, $rootScope) {
        var ctrl = this;
        $scope.workspaces = [];

        $scope.$watch('$ctrl.currentUser', function () {
            $scope.currentUser = $localStorage.currentUser;
            getAllWorkspaces();
        });

        function getAllWorkspaces() {
            $scope.workspaces=[];
            WorkspaceService.getAllWorkspaces().then(function (res) {
                if (res && res.workspaces && res.workspaces.length) {
                    $scope.workspaces = res.workspaces;
                }
            }).catch(function(err){
                console.log(err)
            })
        }

        $scope.openMenu = function($mdMenu, ev) {
            getAllWorkspaces();
            $mdMenu.open(ev);
        };


        $scope.switchWorkspace = function(ev, workspace){
            console.log('switchWorkspace to:',workspace);
            try{
                WorkspaceService.setWorkspace(workspace);
                $rootScope.$broadcast("switchWorkSpace", $localStorage.currentUser);
                $state.reload();
            }catch (e) {
                console.log(e);
            }
        };

        $scope.$on('invitation-accept',function(){
            getAllWorkspaces();
        });

        $scope.$on("switchWorkSpace", function (evt) {
            $scope.currentUser = $localStorage.currentUser;
        });

    }
    return {
        templateUrl: 'app/modules/settings/components/st-select-workspace/st-select-workspace.component.html',
        controller: controller,
        bindings: {
            currentUser: '<',
            selectModeEnabled: '<',
            noRedirect: '<',
            size: '=',
        }
    };
});

controller.$inject = ['$scope', 'FavIconService'];

function controller($scope, FavIconService) {

    const vm = this;
    // Access `mainKeyword` in the controller
    vm.$onInit = () => {
        if (vm.collapsed === undefined) {
            vm.collapsed = false;
        }
    };

    $scope.toggleCollapse = function () {
        vm.collapsed = !vm.collapsed;
    };

    $scope.currentExampleIndex = 0;
    // Access `mainKeyword` in the controller

    $scope.FavIconService = FavIconService;
    $scope.nextExample = function () {
        if ($scope.currentExampleIndex < vm.issues.elements.length - 1) {
            $scope.currentExampleIndex++;
        }
    };
    $scope.prevExample = function () {
        if ($scope.currentExampleIndex > 0) {
            $scope.currentExampleIndex--;
        }
    };
    $scope.focusEditorOnError = function (issue) {
        if (issue.start === null) {
            return;
        }
        vm.editor.chain().focus().setTextSelection({from: issue.start, to: issue.end}).scrollIntoView().run();
    };
}

export default {
    templateUrl: 'app/modules/writerassistant/components/assistant-auditor-what-to-fix/assistant-auditor-what-to-fix.html',
    controller: controller,
    bindings: {
        issues: "=",
        collapsed: "=",
        editor: "<",
    }
};

'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/google-serp-preview/google-serp-preview.html',
        bindings: {
            titlePreview: '=',
            url: '=',
            description: '=',
        },
        controller: ['$scope', function ($scope) {
        }]
    };
});

'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/opengraph-preview/opengraph-preview.html',
        bindings: {slim: '=', debug: '=', title: '=', url: '=', image: '=', description: '='},
        controller: ['$scope', function ($scope) {
            var ctrl = this;
            $scope.$watch('$ctrl.url', function (url) {
                if (ctrl.url) {
                    ctrl.host = url.replace(/^http[s]{0,1}\:\/\//i, '').replace(/^www\./g, '').replace(/\/.*/g, '');
                    ctrl.url = url;
                }
            });
        }]
    };
});

'use strict';
define(['angular'], function (angular) {
    function Controller($scope, UserService, stAlertLimitService, stLimitMessage, userInfo, ExportService, $translate, __env, $timeout, CoreWebVitalsService, $sce, $mdDialog, SerpCTRService, SERPChecker, $stateParams, TrackEventService) {
        function _constructor() {
            newAnalysis();
            setEventsListeners();
            //updateLimits();
        }
        var vm = $scope;
        vm.query = {
            keyword: $stateParams.keyword || null,
            deviceId: $stateParams.deviceId || SERPChecker.config.defaultDeviceId,
            searchEngineId: $stateParams.searchEngineId || SERPChecker.config.defaultSearchEngineId,
            countryCode: $stateParams.countryCode || SERPChecker.config.defaultCountryCode,
            benchmark: getBenchmarkFromStateParams() || []
        };
        vm.analysisLimit = null;
        var currentQueryParams = vm.query;
        var lastRequestParams = null;
        var lastRequestDone = null;
        var settings = {
            setDevice: function (idDevice) {
                if (!idDevice) {
                    return;
                }
                var listDevice = [
                    {id: 1, name: 'desktop'}, {id: 2, name: 'mobile'}
                ];
                var device = listDevice.find(function (x) {
                    return x.id === idDevice;
                });
                if (device) {
                    return device.name;
                }
                return '';
            },
            setSearchEngine: function (idSearchEngine) {
                if (!idSearchEngine) {
                    return;
                }
                var listSearchEngine = [
                    {id: 1, name: 'google'}, {id: 2, name: 'yahoo'},
                    {id: 3, name: 'yandex'}, {id: 4, name: 'bing'}
                ];
                var searchEngine = listSearchEngine.find(function (x) {
                    return x.id === idSearchEngine;
                });
                if (searchEngine) {
                    return searchEngine.name;
                }
                return '';
            }
        };

        var customTableController = {
            removeFixed: removeFixed,
            colorByScore: colorByScore,
            cleanUrl: cleanUrl,
            analyzeUrlBySeochecker: analyzeUrlBySeochecker,
            engagementRate: engagementRate,
            getColorGraphByScore: getColorGraphByScore,
            getFavIconURL: getFavIconURL,
            query: currentQueryParams,
            getCTRByPosition: SerpCTRService.getCTRByPosition,
        };
        vm.results = {
            isLoading: false,
            competitorsRowsAreLoading: false,
            export: {
                stoTool: 'serp-report',
                class: 'st-button__rounded--md--violet',
                text: $translate.instant('keyword.exp.export.serp'),
                icon: 'icon-download',
                data: [],
                fileName: 'serp-keyword',
                buttonDisabled: true
            },
            controller: customTableController,
            rows: [],
            messages: {
                onBoarding: ''
            },
            fixedRows: []
        };

        vm.triggerExportCsvButton = function() {
            $timeout(function () {
                angular.element(document.querySelector('#export_csv_button button')).click();
                return;
            });
        }

        function updateLimits() {
            UserService.limits()
                .then(function (res) {
                    userInfo = res.data;
                    vm.analysisLimit = userInfo.limits.find(function (service) {
                        return service.alias === 'analysis';
                    });
                })
                .catch(function (err) {
                    return null;
                });
        }

        function analyzeUrlBySeochecker(url) {
            return encodeURIComponent(btoa(url));
        }

        function getBenchmarkFromStateParams() {

            var stateParamsBenchmark = [];
            if ($stateParams.benchmark && isString($stateParams.benchmark))
                stateParamsBenchmark = [$stateParams.benchmark];
            if ($stateParams.benchmark && !isString($stateParams.benchmark) && $stateParams.benchmark.length)
                stateParamsBenchmark = $stateParams.benchmark;
            return stateParamsBenchmark;
        }

        function castQueryToAPIFormat(params) {
            return cloneObject({
                mode: params.mode,
                keyword: params.keyword,
                filters: {
                    device: params.device,
                    countryCode: params.countryCode
                },
                benchmark: params.benchmark
            });
        }

        function castUrl(url) {
            return encodeURIComponent(btoa(url));
        }

        function cloneObject(objToClone) {
            return JSON.parse(JSON.stringify(objToClone));
        }

        function getFavIconURL(url) {
            //url=getDomain(url,true);
            return 'https://www.google.com/s2/favicons?sz=64&domain=' + url;
        }


        function getColorGraphByScore(score) {
            return parseFloat(score) >= 75 ? "#28b670" :
                parseFloat(score) >= 50 ? "#fb8c00" :
                    "#f44336";
        }

        function cleanUrl(url) {
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        }

        function engagementRate(reactions = 0, shares = 0, comments = 0, traffic = null) {
            if (!traffic)
                return null;
            var rate = reactions + shares + comments;
            if (!rate)
                return 0;
            return rate > 1000000 ? 100 : parseFloat(getBaseLog(1000000, rate) * 100).toFixed(0);
        }

        function getBaseLog(x, y) {
            return Math.log(y) / Math.log(x);
        }

        function removeFixed(index) {
            vm.results.fixedRows.splice(index, 1);
            vm.$emit('SERPCheckerOnCompetitorsChanged', index);
        }

        function colorByScore(score) {
            if (!score)
                return 'transparent';

            function rgbify(maxval, minval, val) {
                val -= 13;
                if (val < 0) val = 0;
                var intnsty = (val - minval) / (maxval - minval);
                intnsty -= 0.005;
                if (intnsty < 0) intnsty = 0;
                var r, g;
                if (intnsty > 0.5) {
                    r = 240;
                    g = Math.round(2 * (1 - intnsty) * 195);
                } else {
                    g = 200;
                    r = Math.round(2 * intnsty * 235);
                }
                return "rgb(" + r.toString() + ", " + g.toString() + ", 100 , 0.38)";
            }

            return rgbify(100, 0, score);
        }

        function setLoadingState() {
            vm.results.isLoading = true;
            vm.results.competitorsRowsAreLoading = false;
            vm.results.export.buttonDisabled = true;
            vm.backlinksAvg = null;
            vm.searchResults = null;
            vm.serpFeaturesList = null;
            vm.avgDifficulty = null;
            vm.avgVolume = null;
            vm.avgRefDomains = null;
            vm.backlinksTotal = null;
            vm.backlinksNoFollow = null;
            vm.backlinksDoFollow = null;
        }

        function competitorsBacklinkAnalysis(event = null, optionalQuery = null) {
            if (vm.analysisLimit && vm.analysisLimit.qtyRemaining <= 0)
                return;

            if (optionalQuery) {
                currentQueryParams = optionalQuery;
            }
            if (!currentQueryParams || !currentQueryParams.benchmark || !currentQueryParams.benchmark.length) {
                vm.results.fixedRows = [];
                vm.results.competitorsRowsAreLoading = false;
                return;
            }
            vm.results.competitorsRowsAreLoading = true;
            SERPChecker.analyzeBacklinkFromURLs({
                urls: currentQueryParams.benchmark,
                forceStatisticsUsingCachedSERP: vm.results.rows
            }).then(function (competitorsResponse) {
                vm.results.fixedRows = competitorsResponse.data.rows;
                vm.results.competitorsRowsAreLoading = false;
            }).catch(function (err) {
                vm.results.competitorsRowsAreLoading = false;
                vm.results.fixedRows = [];
            });
        }

        function setRowsForExportCSV(queries) {
            if (!queries.length) {
                return;
            }

            var formattedQueriesForCSV = [];
            queries.forEach(function (query) {
                var formattedCSVRow = {
                    position: 0,
                    rankPosition: 0,
                    breadcrumb: '',
                    title: '',
                    estimatedVisits: 0,
                    device: 'desktop',
                    reaction: 0,
                    share: 0,
                    comments: 0,
                    resultType: 'organic'
                };

                if (!query) {
                    return;
                }
                formattedCSVRow.position = query.position;
                formattedCSVRow.rankPosition = query.rankPosition;
                formattedCSVRow.breadcrumb = query.breadcrumb;
                formattedCSVRow.resultType = query.resultType;

                if (query.title && typeof query.title === 'string') {
                    formattedCSVRow.title = query.title.replace(',', '');
                }

                if (query.deviceId) {
                    formattedCSVRow.device = settings.setDevice(query.deviceId);
                }

                if (!query.extraParams) {
                    formattedQueriesForCSV.push(formattedCSVRow);
                    return;
                }

                formattedCSVRow.reaction =  query.extraParams.reaction || '',
                formattedCSVRow.share =  query.extraParams.share || 0,
                formattedCSVRow.comments = query.extraParams.comment || '',
                formattedCSVRow.estimatedVisits = query.extraParams.estimatedVisits || 0

                formattedQueriesForCSV.push(formattedCSVRow);
            });

            return formattedQueriesForCSV;
        }

        function analysis(currentRequestParams) {
            vm.results.messages.onBoarding = null;
            SERPChecker.analyzeQueryBacklink(currentRequestParams)
                .then(function (serpAnalysisResults) {
                    TrackEventService.event(
                        'Suite Usages',
                        'SERP Checker - Audit', 'Social');
                    TrackEventService.gaV2Event(
                        'serp_audit',{
                            type: "Social",
                            tool: "SERP Checker"
                        });
                    if (angular.equals(currentRequestParams, lastRequestParams)) {

                        vm.results.isLoading = false;
                        vm.results.rows = serpAnalysisResults.data.rows;
                        vm.results.export.data = ExportService.convertObjectToCsvArray(setRowsForExportCSV(vm.results.rows));
                        vm.results.export.buttonDisabled = false;
                        vm.backlinksAvg = serpAnalysisResults.data.details.backlinksAvg;
                        vm.searchResults = serpAnalysisResults.data.details.totalRows;
                        vm.serpFeaturesList = serpAnalysisResults.data.details.resultTypes;
                        vm.avgDifficulty = serpAnalysisResults.data.details.avgDifficulty;
                        vm.avgVolume = serpAnalysisResults.data.details.avgVolume;
                        vm.backlinksTotal = serpAnalysisResults.data.details.backlinksTotal;
                        vm.backlinksNoFollow = serpAnalysisResults.data.details.backlinksNoFollow;
                        vm.backlinksDoFollow = serpAnalysisResults.data.details.backlinksDoFollow;
                        vm.avgRefDomains = serpAnalysisResults.data.details.avgRefDomains;
                        competitorsBacklinkAnalysis();
                    }
                })
                .catch(function (err) {
                    if (angular.equals(currentRequestParams, lastRequestParams)) {
                        vm.results.isLoading = false;
                        vm.results.rows = [];
                        vm.results.fixedRows = [];
                        if (err.status === 429 || err.status === 426) {
                            vm.results.messageWhenEmptyDataTitle = stLimitMessage.getFromError(err, userInfo).title;
                            vm.results.messageWhenEmptyDataSubtitle = stLimitMessage.getFromError(err, userInfo).subtitle;
                            vm.results.messageWhenEmptyDataIcon = stLimitMessage.getFromError(err, userInfo).icon;
                            vm.results.messageWhenEmptyDataCta = stLimitMessage.getFromError(err, userInfo).cta;
                            //injected manually to get around circular dependency problem.
                            err.data.canClose = true;
                            stAlertLimitService.open('auth-login', err.data);
                        }
                    }
                });
        }

        function newAnalysis() {
            if (lastRequestDone === JSON.stringify(currentQueryParams)) {
                return;
            }
            if (!currentQueryParams.keyword) {
                return;
            }
            var currentRequestParams = SERPChecker.castQueryToAPIFormat(currentQueryParams);
            if (JSON.stringify(currentRequestParams) === JSON.stringify(lastRequestParams))
                return;
            lastRequestDone = JSON.stringify(currentQueryParams);
            lastRequestParams = SERPChecker.castQueryToAPIFormat(currentQueryParams);
            setLoadingState();
            analysis(currentRequestParams);
        }

        function isString(value) {
            return typeof value === 'string';
        }

        function setEventsListeners() {
            vm.$on('SERPCheckerAudit', function (evt, query) {
                if (query)
                    vm.query = query;
                newAnalysis();
            });
            vm.$on('SERPCheckerAuditCompetitors', function (evt, query) {
                if (query)
                    vm.query = query;
                competitorsBacklinkAnalysis();
            });
        }

        _constructor();
    }

    Controller.$inject = ['$scope', 'UserService', 'stAlertLimitService', 'stLimitMessage', 'userInfo', 'ExportService', '$translate', '__env', '$timeout', 'CoreWebVitalsService', '$sce', '$mdDialog', 'SerpCTRService', 'SerpCheckerService', '$stateParams', 'TrackEventService'];
    return Controller;
});

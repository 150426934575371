/**
 * @ngdoc overview
 * @name api.service:API
 */
'use strict';
define(['app'], function (app) {
    app.service('API', API);
    API.$inject = [
        '$http',
        '$q',
        '$timeout',
        '__env',
        'UtilitiesService',
        'LanguageService',
        'sessionHistoryCache',
    ];

    /**
     * API Service
     * @namespace API
     */
    function API($http, $q, $timeout, __env, UtilitiesService, LanguageService, sessionHistoryCache) {
        var pendingRequests = {};

        return {
            get: get,
            post: post,
            put: put,
            patch: patch,
            delete: del,
            formatError: formatError
        };

        function getUuidUrlName(url) {
            return btoa(url);
        }

        function formatResult(results) {
            var dfd = $q.defer();

            try {
                if (!results || !results.data) {
                    dfd.reject(formatError('Data not found'));
                    return;
                }

                results.data = JSON.stringify(results.data);
                results.data = UtilitiesService.replace_html_entities(results.data);
                dfd.resolve(JSON.parse(results.data));
            } catch (error) {
                dfd.reject(error);
            }
            return dfd.promise;
        }

        function isThisRequestPending(url) {
            var urlUuid = getUuidUrlName(url);
            return !!pendingRequests[urlUuid];
        }

        function stopDuplicatedRequest(url) {
            var urlUuid = getUuidUrlName(url);
            pendingRequests[urlUuid].resolve('http call aborted');
        }

        function setUniqueRequest(url) {
            var urlUuid = getUuidUrlName(url);
            pendingRequests[urlUuid] = $q.defer();
            return pendingRequests[urlUuid].promise;
        }

        function execRequest(request, formatExecute = false, isUnique = false, cacheRequest = false) {
            var cacheId = setJSONParamsInURL(request.url, request.params || request.data || {});

            if (cacheRequest) {
                var existCached = sessionHistoryCache.get(cacheId);

                /* if cache exist, use the cached results in browser history */
                if (existCached) {
                    return $timeout(function () {
                        return $q.resolve(existCached);
                    }, 1000);
                }
            }

            if (isUnique) {
                if (isThisRequestPending(request.url)) {
                    stopDuplicatedRequest(request.url);
                }

                request.timeout = setUniqueRequest(request.url);
            }

            var dfd = $q.defer();

            $http(request)
                .then(function (results) {
                    if (formatExecute) {
                        return formatResult(results);
                    }

                    if (cacheRequest) {
                        sessionHistoryCache.put(cacheId, results);
                    }

                    dfd.resolve(results);
                })
                .then(function (results) {
                    if (cacheRequest) {
                        sessionHistoryCache.put(cacheId, results);
                    }

                    dfd.resolve(results);
                })
                .catch(function (error) {
                    dfd.reject(error);
                });
            return dfd.promise;
        }

        /**
         * @param {string} apiRequestUrl
         * @param {object} apiParams
         * @returns {string}
         */
        function setJSONParamsInURL(apiRequestUrl, apiParams) {
            return apiRequestUrl + '/' + JSON.stringify(apiParams);
        }

        /**
         * @name get
         * @desc Standard GET HTTP Call
         * @param {String} apiPath
         * @param {Object} data
         * @param {Boolean} formatExecute
         * @param {Boolean} isUnique
         * @param {Boolean} cacheRequest
         * @returns
         * @memberOf API
         */
        function get(apiPath, data = null, formatExecute = false, isUnique = false, cacheRequest = false) {
            var languageCodeSaveOnStorage = LanguageService.getCodeAndCountrySaveOnStorage();

            var request = {
                method: 'GET',
                url: apiPath,
                headers: {'Accept-Language': languageCodeSaveOnStorage},
            };

            if (data && data.params) {
                request.params = data.params;
            }

            return execRequest(request, formatExecute, isUnique, cacheRequest)
        }

        /**
         * @name post
         * @desc Standard POST HTTP Call
         * @param {String} apiPath
         * @param {Object} data
         * @param {Boolean} formatExecute
         * @param {Boolean} isUnique
         * @param {Boolean} cacheRequest
         * @returns
         * @memberOf API
         */
        function post(apiPath, data = null, formatExecute = false, isUnique = false, cacheRequest = false) {
            var languageCodeSaveOnStorage = LanguageService.getCodeAndCountrySaveOnStorage();
            var request = {
                method: 'POST',
                url: apiPath,
                headers: {'Accept-Language': languageCodeSaveOnStorage},
            };

            if (data) {
                request.data = data;
            }

            return execRequest(request, formatExecute, isUnique, cacheRequest);
        }

        /**
         * @name put
         * @desc PUT METHOD with Promises
         * @param {String} apiPath
         * @param {Object} data
         * @param {Boolean} formatExecute
         * @param {Boolean} isUnique
         * @returns {Object}
         * @memberOf API
         */
        function put(apiPath, data, formatExecute = false, isUnique = false) {
            var languageCodeSaveOnStorage = LanguageService.getCodeAndCountrySaveOnStorage();
            var request = {
                method: 'PUT',
                url: apiPath,
                headers: {'Accept-Language': languageCodeSaveOnStorage},
                data: data
            };
            return execRequest(request, formatExecute, isUnique, false);
        }

        /**
         * @name patch
         * @desc PATCH METHOD with Promises
         * @param {String} apiPath
         * @param {Object} data
         * @param {Boolean} formatExecute
         * @param {Boolean} isUnique
         * @returns {Object}
         * @memberOf API
         */
         function patch(apiPath, data, formatExecute = false, isUnique = false) {
            var languageCodeSaveOnStorage = LanguageService.getCodeAndCountrySaveOnStorage();

            var request = {
                method: 'PATCH',
                url: apiPath,
                headers: {'Accept-Language': languageCodeSaveOnStorage},
                data: data
            };

            return execRequest(request, formatExecute, isUnique, false);
        }

        /**
         * @name del
         * @desc Standard DELETE HTTP Call
         * @param {String} apiPath
         * @param {Object} data
         * @param {Boolean} formatExecute
         * @param {Boolean} isUnique
         * @returns
         * @memberOf API
         */
        function del(apiPath, data, formatExecute = false, isUnique = false) {
            var languageCodeSaveOnStorage = LanguageService.getCodeAndCountrySaveOnStorage();

            var request = {
                method: 'DELETE',
                url: apiPath,
                headers: {'Accept-Language': languageCodeSaveOnStorage},
                data: data
            };

            return execRequest(request, formatExecute, isUnique);
        }

        /**
         * @param {String} message
         * @returns {{error: {code: string, message: *}}}
         */
        function formatError(message) {
            return {
                error: {
                    code: 'INVALID_REQUEST',
                    message: message,
                },
            };
        }
    }
});

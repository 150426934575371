'use strict';
define(['angular'], function(ng) {
    function Controller($scope) {
        var vm = $scope;
        vm.query='';
        
        vm.tabs={
            categoryTitle: 'Opengraph',
            categoryState:'app.seospider.exam.analysis.opengraph',
            fields:[
                {label:'seospider.opengraph.all',icon:'icon icon-list', uiSref:'.all'},
            ]
        };
        
    }
    Controller.$inject=['$scope'];
    return Controller;
});

'use strict';
define([], function() {
    function Controller($scope) {
        var vm=$scope;
        vm.tabs={
            categoryTitle:'Status',
            categoryState:'app.seospider.exam.analysis.httpstatus',
            fields:[
                {label:'seospider.status.found',icon:'icon icon-like',uiSref:'.200'},
                {label:'seospider.status.redirect',icon:'icon icon-size-actual',uiSref:'.301'},
                {label:'seospider.status.pageNotFound',icon:'icon icon-ban',uiSref:'.404'}
            ]
        };
    }
    Controller.$inject=['$scope'];
    return Controller;
});

'use strict';
define([
    'angular',
    'modules/backlink-checker/backlink-checker.routes',
    'modules/backlink-checker/components/components.module',
    'modules/backlink-checker/tool/tool.module'
], function (angular, routes) {
    var app = angular.module('app.backlinkchecker', [
        'app.backlinkchecker.ui',
        'app.backlinkchecker.tool'
    ]);
    app.config(routes);
    return app;
});

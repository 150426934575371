'use strict';
define(['app', "jquery"], function (app, $) {
	angular.module('sparkline', [])
		.directive('dynamicbar', function () {
			return {
				scope: {
					data: '=',
					type: '='

				},
				link: function (scope, element) {
					var settings={
						type: 'line',
						lineColor: '#006eff',
						spotColor: '#006eff',
						fillColor: '#f3faff',
						spotRadius: 0,
						height: '18',
						width: 50,
						lineWidth: 1.2,
					};
					if(scope.type&&scope.type.toLowerCase()==='pie'){
						console.log('entroo');
						settings = {
							type: 'pie',
							sliceColors: ['#006eff','#f0f0f0'],
							height: '18',
							width: 50,
						};
					}


					element.sparkline(scope.data,settings);
				}
			};
		});
});

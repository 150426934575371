'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/page/duplicated/duplicated.controller',
], function (
    angular,
    PageDuplicatedCtrl
) {
    var app = angular.module('app.seospider.exam.analysis.page.duplicated', []);
    app.controller("PageDuplicatedCtrl", PageDuplicatedCtrl);
    return app;
});

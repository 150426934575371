'use strict';
define([
    'angular',
    'modules/seospider/exam/analysis/page/page.routes',
    'modules/seospider/exam/analysis/page/page.controller',
    'modules/seospider/exam/analysis/page/page.component',
    'modules/seospider/exam/analysis/page/all/all.module',
    'modules/seospider/exam/analysis/page/duplicated/duplicated.module',
], function (
    angular,
    Routes,
    PageCtrl,
    table
) {
    var app = angular.module('app.seospider.exam.analysis.page', [
        'app.seospider.exam.analysis.page.all',
        'app.seospider.exam.analysis.page.duplicated'
    ]);
    app.config(Routes);
    app.component("pageTable", table);
    app.controller("PageCtrl", PageCtrl);
    return app;
});

'use strict';
define(['app', 'angular', 'jquery'], function (app, angular, $) {
    app.component('stSwitch', {
        templateUrl: 'app/components/st-switch/st-switch.html',
        controller: controller,
        bindings: {
            fields: '<',
            size: '<',
            invertColor: '<',
            elements: '<',
            onSelectionChange: '=',
            cursorClass: '<',
            onChanged: '&?',
            selectedItem: "=",
            hideCursor: '<'
        }
    });
    controller.$inject = ['$scope', '$element', '$timeout', '$window'];
    function controller($scope, $element, $timeout, $window) {
        var vm = $scope;
        var $ctrl = this;
        vm.size = $ctrl.size;
        vm.cursorClass = $ctrl.cursorClass;
        vm.hideCursor=$ctrl.hideCursor;
        vm.selectItemRow = selectItemRow;
        vm.badgePosition = 0;
        vm.badgeWidth = 70;

        $ctrl.internalOnChange = function (value) {
            if ($ctrl.onChanged) {
                $ctrl.onChanged(value);
            }
        };

        $ctrl.$postLink = function () {
            $timeout(function () {
                selectItemRow($ctrl.selectedItem);
            }, 25);
        };

        $scope.$watch('$ctrl.selectedItem', function (selectedItem) {
            if (selectedItem) {
                $ctrl.selectedItem = selectedItem;
                selectItemRow(selectedItem);
            }
        });

        function selectItemRow(value) {
            if (!$ctrl.elements)
                return;
            try {
                if (!value) {
                    value = $ctrl.elements[0].value;
                }

                for (var i = 0; i < $ctrl.elements.length; i++) {
                    delete $ctrl.elements[i].active;
                }

                for (i = 0; i < $ctrl.elements.length; i++) {
                    if ($ctrl.elements[i].value === value) {
                        $ctrl.elements[i].active = true;
                    }
                }
                $ctrl.selectedItem = value;
                $ctrl.internalOnChange(value);
                bindPositionCursor();
            } catch (err) {
                console.log(err);
            }

        }

        /*
         * Beautiful debouncing for resize event firing too much on the PC
         * by Pim Jager http://stackoverflow.com/a/668185/930987
         */
        var resizeEvent = false;
        angular.element($window).bind('resize', function () {
            if (!resizeEvent) {
                $timeout.cancel(resizeEvent);
                resizeEvent = $timeout(bindPositionCursor, 1000); // half a second should be enough for a modern PC
            }
        });


        function bindPositionCursor() {
            $timeout(function () {
                var badgeWidth = $($element).find('.st-switch-item-active').innerWidth();
                var elementBefore = $($element).find('.st-switch-item-active').prevAll();
                var widthAll = 0;
                for (var i = 0; i < elementBefore.length; i++) {
                    widthAll += elementBefore[i].clientWidth;
                }
                vm.badgePosition = widthAll;
                vm.badgeWidth = badgeWidth;
                if (badgeWidth === 0) {
                    $timeout(bindPositionCursor(), 1000);
                }
            }, 100);
        }

    }
});

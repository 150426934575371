'use strict';
define(['app', 'jquery'], function (app, $) {
    app.service('addOnService', addOnService);
    addOnService.$inject = ['$q', '$mdDialog', '$location', '$rootScope'];

    function addOnService($q, $mdDialog, $location, $rootScope) {
        return {
            open: open
        };

        function open(type) {
            if ($rootScope.dialogIsOpen) {
                return $q.resolve();
            }

            $rootScope.dialogIsOpen = true;

            var dfd = $q.defer();
            $mdDialog.show({
                controller: ['$scope', 'type', function ($scope, type) {
                    $scope.type = type;
                }],
                locals: {type: type},
                templateUrl: 'app/components/add-on/add-on.service.html',
                parent: $(document.body),
                clicskOutsideToClose: false,
                escapeToClose: false,
            })
                .then(function () {
                    $location.hash(null);

                    dfd.resolve();
                })
                .catch(function () {
                    $location.hash(null);
                    dfd.resolve();
                });

            return dfd.promise;
        }
    }
});

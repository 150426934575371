define([], function () {
    function config($stateProvider) {
        $stateProvider
            .state("app.keywordtracker", {
                abstract: true,
                url: "/rank-tracker",
                templateUrl: './app/modules/keywordtracker/keywordtracker.html',
                resolve: {
                    notVerifiedRedirect: ['UserService', 'AuthService', '$state', function (UserService, AuthService, $state) {
                        return UserService.limits(true)
                            .then(function (res) {
                                var user = AuthService.getSessionUser();
                                if (res && res.data && !res.data.isActive && user && user.email) {
                                    $state.go('access.email-verification', {
                                        token: btoa(user.email)
                                    });
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    }],
                }
            })
            .state("app.keywordtracker.landing", {
                url: "",
                controller: 'KeywordTrackerLandingCtrl',
                templateUrl: './app/modules/keywordtracker/landing/landing.html',
                resolve: {
                    projects: ['AuthService', '$stateParams', '$state', '$q', 'KeywordTrackerService',
                        function (AuthService, $stateParams, $state, $q, KeywordTrackerService) {
                            if (!AuthService.isAuthenticated()) return;

                            var dfd = $q.defer();
                            KeywordTrackerService.getAllTrackerProjectPreviews()
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.trackerProjects
                                        || !res.data.trackerProjects.rows) {
                                        dfd.resolve([]);
                                        return;
                                    }
                                    dfd.resolve(res.data.trackerProjects.rows);
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    dfd.resolve(err.data);
                                });

                            return dfd.promise;
                        }]
                }
            })
            .state("app.keywordtracker.overview", {
                url: "/:project/overview?:queryId&:competitors&:locations&:start&:end&:minPosition&:maxPosition&:minEv&:maxEv&:minVolume&:maxVolume&:excludedWords&:includedWords&:tag&&firstLoad&&onBoarding",
                controller: 'KeywordTrackerOverviewCtrl',
                templateUrl: './app/modules/keywordtracker/overview/overview.html',
                access: {restricted: true},
                reloadOnSearch: false,
                resolve: {
                    projects: ['KeywordTrackerService',
                        function (KeywordTrackerService) {
                            return KeywordTrackerService.getAllTrackerProjectPreviews()
                                .then(function (res) {
                                    if (res && res.data && res.data.trackerProjects && res.data.trackerProjects.rows) {
                                        return res.data.trackerProjects.rows;
                                    }
                                    return [];
                                })
                                .catch(function (err) {
                                    console.log(err);
                                });
                        }],
                },
            })
            .state("app.keywordtracker.report", {
                url: "/:project/report",
                controller: 'KeywordTrackerReportCtrl',
                templateUrl: './app/modules/keywordtracker/report/report.html',
                access: {restricted: true},
                resolve: {
                    project: ['$stateParams', 'KeywordTrackerService',
                        function ($stateParams, KeywordTrackerService) {

                            return KeywordTrackerService.getOneProject($stateParams.project, {filters: {}})
                                .then(function (res) {
                                    return res.data.trackerProject;
                                }).catch(function (err) {
                                    console.log(err);
                                    return err.data;
                                });
                        }],
                },
            })
            .state("app.keywordtracker.addkeywords", {
                url: "/:trackerProjectId/settings/add-keywords",
                controller: 'KeywordTrackerAddKeywordsCtrl',
                templateUrl: './app/modules/keywordtracker/settings/add-keywords/add-keywords.html',
                access: {restricted: true},
                resolve: {
                    project: ['$stateParams', 'KeywordTrackerService',
                        function ($stateParams, KeywordTrackerService) {
                            return KeywordTrackerService.getOneProject($stateParams.trackerProjectId, {})
                                .then(function (res) {
                                    return res.data.trackerProject;
                                }).catch(function (err) {
                                    console.log(err);
                                    return err.data;
                                });
                        }],
                    keywordsLimit: ['UserService',
                        function (UserService) {
                            return UserService.limits()
                                .then(function (res) {
                                    if (!res || !res.data || !res.data.limits || !res.data.limits.length) {
                                        return 15;
                                    }

                                    var limit = 15
                                    angular.forEach(res.data.limits, function (service) {
                                        if (service.alias === 'rank-tracker-keywords' && service.qtyAllowed) {
                                            limit = service.qtyAllowed;
                                        }
                                    });

                                    return limit;
                                })
                                .catch(function (err) {
                                    console.log(err);
                                    return null;
                                });
                        }],

                },
            });
    }

    config.$inject = ['$stateProvider'];
    return config;
});

'use strict';
define(['app'], function (app) {
    app.service('SessionService', SessionService);
    SessionService.$inject = ['$cookieStore'];

    function SessionService($cookieStore) {
        return {
            set: set,
            get: get,
            destroy: destroy
        };

        function set(key, value) {
            return $cookieStore.put(key, value);
        }

        function get(key) {
            return $cookieStore.get(key) || false;
        }

        function destroy(key) {
            $cookieStore.remove(key);
        }
    }
});

'use strict';
define([], function () {
    function PitchgroundController($scope, $mdDialog, $timeout, CouponService, PitchGroundService, $translate) {
        var formPitchground = {
            email: null,
            coupons: []
        };

        $scope.redeemption = {
            plan: {
                name: ''
            }
        };
        $scope.emailCheck = false;
        $scope.pitchgroundFinalBox = false;
        $scope.pitchgroundFirstBox = true;
        $scope.firstCouponCheck = false;
        $scope.titleText = '<span>' + $translate.instant('pitchground.redeems.your.coupon') + '</span>';
        $scope.subTitleText = $translate.instant('pitchground.insert.a.valid.coupon.to.unlock.your.plan');
        $scope.imgPlan = '<div class="pg-img-NoPlan"></div>';
        $scope.loading = {
            redeem: false
        };
        $scope.coupons = {
            email: null,
            firstCoupon: null,
            errorFirst: null,
        };
        var PATTERNS = {
            coupon: /^PGBF2020-([\w]{8})$/i,
            email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        };
        $scope.patterns = PATTERNS;
        $scope.validEmail = function (email) {
            if (PATTERNS.email.test(email)) {
                $scope.emailCheck = true;
            } else {
                $scope.email = email;
                $scope.emailCheck = false;
            }
        };
        $scope.showConfirm = function (ev) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('pitchground.are.you.sure.to.confirm.coupons.activation'))
                .textContent($translate.instant('pitchground.if.you.accept', {planName: $scope.redeemption.plan.name}))
                .targetEvent(ev)
                .cancel($translate.instant('Abort'))
                .ok($translate.instant('Confirm'));

            $mdDialog.show(confirm).then(function () {
                update();
                $scope.loading.redeem = true;
            }, function () {
            });
        };

        function update() {
            formPitchground.coupons = [];

            if ($scope.firstCouponCheck === false) {
                $scope.coupons.firstCoupon = '';
            } else {
                $scope.finalPlan = $scope.redeemption.plan.name;
                formPitchground.coupons.push($scope.coupons.firstCoupon)
            }

            formPitchground.email = $scope.coupons.email;
            PitchGroundService.redeem(formPitchground)
                .then(function (res) {
                    if (res.data.to.isNewAccount == true) {
                        $scope.confirmText = $translate.instant('pitchground.a.temporary.password.will.be.send', {email: $scope.coupons.email})
                    } else {
                        $scope.confirmText = $translate.instant('pitchground.great.job', {email: $scope.coupons.email})
                    }
                    $timeout(function () {
                        $scope.loading.redeem = false;
                        $scope.pitchgroundFirstBox = false;
                        $scope.pitchgroundFinalBox = true;
                    }, 2000);
                }).catch(function (err) {
                $timeout(function () {
                    $scope.coupons.errorFirst = true;
                    $scope.loading.redeem = false;
                }, 2000);
            });
        }

        $scope.validFirstCoupon = function (firstCoupon) {
            if (PATTERNS.coupon.test(firstCoupon)) {
                CouponService.isValid(firstCoupon).then(function (res) {
                    if (res.data.code == "OK") {
                        $scope.redeemption.plan.name = res.data.planName;
                        $scope.coupons.errorFirst = false;
                        $scope.imgPlan = '<div class="pg-img-thirdPlan"></div>';
                        $scope.titleText = res.data.planName;
                        $scope.subTitleText = $translate.instant('pitchground.redeem.your.coupon.now');
                        $scope.firstCouponCheck = true;
                    }
                });
            } else {
                $scope.redeemption.plan.name = '';
                $scope.coupons.errorFirst = true;
                $scope.imgPlan = '<div class="pg-img-NoPlan"></div>';
                $scope.titleText = '<span>' + $translate.instant('pitchground.redeems.your.coupon') + '</span>';
                $scope.subTitleText = $translate.instant('pitchground.insert.a.valid.coupon.to.unlock.your.plan');
                $scope.firstCouponCheck = false;
            }
        }
    }

    PitchgroundController.$inject = ['$scope', '$mdDialog', '$timeout', 'CouponService', 'PitchGroundService', '$translate'];
    return PitchgroundController;
});

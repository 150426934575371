'use strict';
define([], function () {
    return {
        templateUrl: 'app/components/access-components/st-review-box/st-review-box.html',
        bindings: {
            dialogMode: '<'
        },
        controller: ['$scope', 'ReviewsService',
            function ($scope, ReviewsService) {
                var vm = $scope;

                var reviews = ReviewsService.getAll();

                vm.currentReview = reviews[Math.floor(Math.random() * reviews.length)];
            }]
    };
});

define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.snippets",{
                abstract:true,
                controller:'SnippetsCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/snippets/snippets.html',
                access: {restricted: true},
            })

            .state("app.seospider.exam.analysis.snippets.all",{
                url:"/snippets",
                templateUrl: './app/modules/seospider/exam/analysis/snippets/all/all.html',
                access: {restricted: true},
                controller:'SnippetsAllCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findAll({
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })

            .state("app.seospider.exam.analysis.snippets.optimized",{
                url:"/snippets/optimized",
                templateUrl: './app/modules/seospider/exam/analysis/snippets/optimized/optimized.html',
                access: {restricted: true},
                controller:'SnippetsOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.snippets.not-optimized",{
                url:"/snippets/not-optimized",
                templateUrl: './app/modules/seospider/exam/analysis/snippets/not-optimized/not-optimized.html',
                access: {restricted: true},
                controller:'SnippetsNotOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaRobots', 'status', 'title', 'metaDescription', 'contentType', 'wordCount']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});



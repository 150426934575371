'use strict';
define([], function() {
    function Controller($scope, $mdDialog, form, LeadGenerationService, TrackEventService) {
        $scope.form=form;
        $scope.hide = hideDialog;
        $scope.cancel = cancelDialog;
        $scope.answer = answerDialog;

        function answerDialog(answer) {
            LeadGenerationService.deleteForm({formId: form.id}).
            then(function (res){
                TrackEventService.event(
                    'Suite Usages',
                    'Lead Gen - Campaign Deleted');
                TrackEventService.gaV2Event(
                    'campaign_delete',{
                        tool: "Lead Generation Tool"
                    });
                $mdDialog.hide();
            }).
            catch(function(err){});
        }
        function hideDialog() {
            $mdDialog.hide();
        }
        function cancelDialog() {
            $mdDialog.cancel();
        }
    }
    Controller.$inject=['$scope', '$mdDialog', 'form', 'LeadGenerationService','TrackEventService'];
    return Controller;
});

'use strict';
define([], function () {


    return {
        templateUrl: 'app/modules/seochecker/components/word-cloud/word-cloud.html',
        bindings: {
            overview: '<',
            loading: '<'
        },
        controller: ['$scope', 'angularLoad', function ($scope, angularLoad) {
            var vm = $scope;

            this.$onInit = function() {
                angularLoad.loadScript('./app/vendors/d3.min.js')
                    .then(function(){
                        angularLoad.loadScript('./app/vendors/d3.layout.cloud.js')
                            .then(function(){
                                angularLoad.loadScript('./app/vendors/d3.wordcloud.js');
                            });
                        });
            }

            this.$onChanges = function() {
                vm.loading = this.loading;
                vm.relevantKeywords = [];

                if (typeof d3 === undefined)  {
                    return;
                }

                if (this.overview && this.overview.relevantKeywords) {
                    vm.relevantKeywords = this.overview.relevantKeywords;
                    vm.wordCount = this.overview.wordCount;
                    vm.readingTime = this.overview.readingTime;

                    var keywordList = [];

                    for (var i=0; i<vm.relevantKeywords.length; i+=1) {
                        keywordList.push({
                            "text": vm.relevantKeywords[i].word,
                            "size": vm.relevantKeywords[i].count
                        });
                    }
                }

                if (keywordList && keywordList.length) {
                    d3.wordcloud()
                        .size([450, 300])
                        .fill(d3.scale.category20b()
                        .range(['#CC00AE', '9600FF', '#596679', '#111' ]))
                        //.range(["#333", "#666", "#999", "#ccc"]))
                        .words(keywordList)
                        //.transitionDuration('2s')
                        .onwordclick(function(d, i) {
                            if (d.href) { window.location = d.href; }
                        })
                        .start();
                }
            }
        }]
    };
});

'use strict';
define([
    'angular',
    'modules/keywordlistmanager/dialogs/delete-keywords/delete-keywords.controller',
    'modules/keywordlistmanager/dialogs/rename-keywordlist/rename-keywordlist.controller',
    'modules/keywordlistmanager/dialogs/cloned-keywordlist/clone-keywordlist.controller',
    'modules/keywordlistmanager/dialogs/delete-keywordlist/delete-keywordlist.controller',
    'modules/keywordlistmanager/dialogs/share-keywordlist/share-keywordlist.controller',
    'modules/keywordlistmanager/dialogs/create-keywordlist/create-keywordlist.controller'
], function (
    angular,
    KeywordListManagerDeleteKeywordsCtrl,
    KeywordListManagerRenameKeywordListCtrl,
    KeywordListManagerCloneKeywordListCtrl,
    KeywordListManagerDeleteKeywordListCtrl,
    KeywordListManagerShareKeywordListCtrl,
    KeywordListManagerCreateKeywordListCtrl
) {
    var app = angular.module('app.keywordlistmanager.dialogs', []);
    app.controller('KeywordListManagerDeleteKeywordsCtrl', KeywordListManagerDeleteKeywordsCtrl);
    app.controller('KeywordListManagerRenameKeywordListCtrl', KeywordListManagerRenameKeywordListCtrl);
    app.controller('KeywordListManagerCloneKeywordListCtrl', KeywordListManagerCloneKeywordListCtrl);
    app.controller('KeywordListManagerDeleteKeywordListCtrl', KeywordListManagerDeleteKeywordListCtrl);
    app.controller('KeywordListManagerShareKeywordListCtrl',KeywordListManagerShareKeywordListCtrl);
    app.controller('KeywordListManagerCreateKeywordListCtrl',KeywordListManagerCreateKeywordListCtrl);


    return app;
});


define([], function(){
    function config($stateProvider) {
        $stateProvider
            .state("app.seospider.exam.analysis.twittercard",{
                abstract:true,
                controller:'TwittercardCtrl',
                templateUrl: './app/modules/seospider/exam/analysis/twittercard/twittercard.html',
                access: {restricted: true},                
            })            
            .state("app.seospider.exam.analysis.twittercard.all",{
                url:"/twittercard",
                templateUrl: './app/modules/seospider/exam/analysis/twittercard/all/all.html',
                access: {restricted: true},
                controller:'TwittercardAllCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', function ($stateParams, SeospiderPages) {
                        return SeospiderPages.findAll({
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })         
            .state("app.seospider.exam.analysis.twittercard.optimized",{
                url:"/twittercard/optimized",
                templateUrl: './app/modules/seospider/exam/analysis/twittercard/optimized/optimized.html',
                access: {restricted: true},
                controller:'TwittercardOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
            .state("app.seospider.exam.analysis.twittercard.not-optimized",{
                url:"/twittercard/not-optimized",
                templateUrl: './app/modules/seospider/exam/analysis/twittercard/not-optimized/not-optimized.html',
                access: {restricted: true},
                controller:'TwittercardNotOptimizedCtrl',
                resolve: {
                    results: ['$stateParams', 'SeospiderPagesService', '$localStorage', function ($stateParams, SeospiderPages, $localStorage) {
                        return SeospiderPages.findAll({
                            limit:  $localStorage.currentSessionResourcesCount || 100,
                            jobId: $stateParams.sessionId,
                            fields: ['id', 'url', 'metaTwitterTitle', 'metaTwitterDescription', 'metaTwitterImage', 'metaTwitterSite', 'metaTwitterCard']
                        });
                    }],
                    jobId: ['$stateParams', function ($stateParams) {
                        return $stateParams.sessionId;
                    }]
                }
            })
    }
    config.$inject=['$stateProvider'];
    return config;
});
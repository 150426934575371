'use strict';
define([], function () {
    function Controller($scope, $q, $http, $sce, $translate, $mdMedia) {
        var vm = $scope;
        var text = $translate.instant('if error persists');
        vm.$mdMedia = $mdMedia;

        // mark it as clean
        text = $sce.trustAsHtml(text);

        $scope.snippet = text;
        vm.step = 1;
        vm.plan;
        vm.selectPlan = selectPlan;

        function selectPlan(plan) {
            vm.plan = plan;
            vm.step = 2;
        }
    }

    Controller.$inject = ['$scope', '$q', '$http', '$sce', '$translate', '$mdMedia'];
    return Controller;
});

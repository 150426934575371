define(['app'], function (app) {
    return app.config(['$locationProvider', '$urlRouterProvider', '$httpProvider', function ($locationProvider, $urlRouterProvider, $httpProvider) {
        $httpProvider.defaults.headers.delete = {
            "Content-Type": "application/json;charset=utf-8"
        };

        // Use the HTML5 History API
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $urlRouterProvider.rule(function ($injector, $location) {
            var path = $location.path();
            var hasTrailingSlash = path[path.length - 1] === '/';
            if (hasTrailingSlash) {
                //if last charcter is a slash, return the same url without the slash
                var newPath = path.substr(0, path.length - 1);
                return newPath;
            }
        });

        $urlRouterProvider.otherwise(function ($injector, $location) {
            var state = $injector.get('$state');
            state.go('404');
            return $location.path();
        });

        $httpProvider.interceptors.push(function () {
            return {
                request: function (req) {
                    if (!req || !req.url || typeof req.url !== 'string') {
                        return req;
                    }

                    if (req.url.indexOf('.html') < 0 && req.url.indexOf('.json') < 0) {
                        return req;
                    }

                    if (req.url.indexOf('?') < 0) {
                        req.url += '?hash={contentHash}';
                    } else {
                        req.url += '&hash={contentHash}';
                    }

                    return req;
                }
            }
        });
    }]);
});

'use strict';
define([],function(){
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/https/https-table.html',
        bindings: { pages: '=' },
        controller:['$scope','$rootScope','$window','$translate',function($scope,$rootScope,$window,$translate){
            var vm=$scope;
            $scope.$watch('$ctrl.pages',function(pages){
                vm.pages=pages;
            });
            if(vm.pages==undefined) vm.pages=[];
            vm.evalOccurences=evalOccurences;
            function evalOccurences(score) {
                score=parseInt(score)||0;
                if (score == 1)
                    return 'green-text';
                if (score == 0)
                    return 'red-text';
                else  return 'text-darken-1 orange-text';
            }
            function openDetail(page){
                var url=new URL(page.url);
                var pathname=url.pathname.length?url.pathname:'/';
                $rootScope.$emit('openToolbarDetail',{
                    list:page.list,
                    templateType: 'duplicated',
                    element: 'content',
                    label: $translate.instant('seospider.content.duplicated'),
                    placeholder: $translate.instant('seospider.search.pages.in',{'pathname':pathname})
                });
            }
        }]
    };
});
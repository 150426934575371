'use strict';
define([], function() {
    function SettingsController($scope, $state, LanguageService, currentUser, $filter, $mdMedia) {
        var vm = $scope;
        vm.currentUser = currentUser;
        vm.$mdMedia = $mdMedia;

        vm.userIsEnabledToSeePartnerArea = userIsEnabledToSeePartnerArea();

        function userIsEnabledToSeePartnerArea() {
            return LanguageService.getLocale() === 'it_IT';
        }

        vm.menuConfig = {
            singleOpening: false,
            linkFirstLevelMenuToTheFirstSubmenu: true,
            saveStateOnRefresh: true
        };

        vm.mobileMenuItems = [];

        vm.menuItems = [
            {
                name: 'settings.account',
                icon: 'account_circle',
                isOpened: false,
                mobileTabOrder: 1,
                submenu: [
                    {
                        name: 'settings.profile',
                        isOpened: false,
                        mobileTabOrder: 2,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.update'],
                        },
                    },
                    {
                        name: 'settings.change.password',
                        isOpened: false,
                        mobileTabOrder: 3,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.changePassword'],
                        },
                    },
                    {
                        name: 'settings.invitations',
                        isOpened: false,
                        mobileTabOrder: 6,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.invitations'],
                        },
                    }
                ]
            },
            {
                name: 'Workspace',
                icon: 'workspaces_outline',
                isOpened: false,
                mobileTabOrder: 8,
                permission: [
                    'workspace_user_admin',
                    'workspace_user_manager',
                    'workspace_user_bookkeeper',
                    'workspace_user_marketer',
                    'workspace_user_client'
                ],
                submenu: [
                    {
                        name: 'settings.customizations',
                        isOpened: false,
                        mobileTabOrder: 9,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.customizations'],
                        },
                        permission: 'workspace_edit',
                    },
                    {
                        name: 'settings.partner.area',
                        isOpened: false,
                        mobileTabOrder: 10,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.partnersArea'],
                        },
                        permission: 'workspace_partner_program_read'
                    },
                    {
                        name: 'users',
                        isOpened: false,
                        mobileTabOrder: 11,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.users'],
                        },
                        permission: [
                            'workspace_user_admin',
                            'workspace_user_manager',
                            'workspace_user_bookkeeper',
                            'workspace_user_marketer',
                            'workspace_user_client'
                        ]
                    },
                    {
                        name: 'settings.logs',
                        isOpened: false,
                        mobileTabOrder: 12,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.logs'],
                        },
                        permission: 'workspace_log_read'
                    }
                ]
            },
            {
                name: 'settings.plans.and.subscriptions',
                icon: 'check_circle_outline',
                isOpened: false,
                mobileTabOrder: 13,
                submenu: [
                    {
                        name: 'settings.overview.credits-limits',
                        isOpened: false,
                        mobileTabOrder: 14,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.overview'],
                        },
                    },
                    {
                        name: 'settings.subscriptions',
                        isOpened: false,
                        mobileTabOrder: 15,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.subscriptions'],
                        },
                        permission: 'workspace_subscriptions_read'
                    },
                    {
                        name: 'settings.invoices',
                        isOpened: false,
                        mobileTabOrder: 16,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.invoices'],
                        },
                        permission: 'workspace_invoice_read'
                    },
                    {
                        name: 'settings.billing.info',
                        isOpened: false,
                        mobileTabOrder: 17,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.billingInfo'],
                        },
                        permission: 'workspace_payment_methods_read'
                    },
                    {
                        name: 'settings.payment.methods',
                        isOpened: false,
                        mobileTabOrder: 18,
                        link: {
                            type: 'internal',
                            urls: ['app.settings.paymentsMethods'],
                        },
                        permission: 'workspace_payment_methods_read'
                    }
                ]
            },
            {
                name: 'API',
                icon: 'api',
                isOpened: false,
                mobileTabOrder: 19,
                link: {
                    type: 'internal',
                    urls: ['app.settings.api'],
                },
            },
        ];

        buildMobileMenu(vm.menuItems);

        function buildMobileMenu(menuItems) {
            for (var i = 0; i<menuItems.length; i+=1) {
                if (menuItems[i].link) {
                    vm.mobileMenuItems.push(menuItems[i]);
                }
                if (menuItems[i].submenu) {

                    for (var j = 0; j<menuItems[i].submenu.length; j+=1) {
                        if (menuItems[i].submenu[j].link) {
                            vm.mobileMenuItems.push(menuItems[i].submenu[j]);
                        }

                        if (menuItems[i].submenu[j].submenu) {
                            for (var k = 0; k<menuItems[i].submenu[j].submenu.length; k+=1) {
                                if (menuItems[i].submenu[j].submenu[k].link) {
                                    vm.mobileMenuItems.push(menuItems[i].submenu[j].submenu[k]);
                                }
                            }
                        }
                    }
                }

            }

            vm.mobileMenuItems = $filter('orderBy')(vm.mobileMenuItems, 'mobileTabOrder', false);
        }

        vm.goTo = function(item, ev) {

            vm.current = item;
            if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
            }


            if (item.link && item.link.type == 'internal') {
                $state.go(item.link.url);
            }

            if (item.link && item.link.type == 'external') {
                $window.open(item.link.url, '_blank');
            }

        }

    }
    SettingsController.$inject=['$scope', '$state', 'LanguageService', 'currentUser', '$filter', '$mdMedia'];
    return SettingsController;
});

/**
 * @ngdoc overview
 * @name keywordexplorer.service:ProjectsService
 */
'use strict';
define(['app'], function (app) {
    app.service('ProjectsService', ProjectsService);
    ProjectsService.$inject = ['$q', 'API', '__env', 'SeospiderService', 'KeywordTrackerService'];

    function ProjectsService($q, API, __env, SeoSpider, KeywordTracker) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.projectAPI;

        function getAllTrackerProjects() {
            var dfd = $q.defer();

            KeywordTracker.getAllTrackerProjects()
                .then(function (res) {
                    if (!isValidDataFromRankTracker(res)) {
                        dfd.resolve();
                        return;
                    }

                    dfd.resolve(res.data.trackerProjects.rows)
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        }

        function getAllSpiderProjects() {
            var dfd = $q.defer();

            SeoSpider.findJobs({})
                .then(function (projects) {
                    if (!projects) {
                        dfd.resolve();
                        return;
                    }

                    dfd.resolve(projects)
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        }

        this.findAll = function () {
            return $q.all([getAllTrackerProjects(), getAllSpiderProjects()])
                .then(function (results) {
                    if (!results[0] || !results[1]) {
                        return [];
                    }

                    return mergeTrackerAndSpiderProject(results[0], results[1]);
                })
                .catch(function (err) {
                    console.error(err);
                });
        };

        function mergeTrackerAndSpiderProject(trackerProjects, spiderProjects) {
            var temp = {};
            for (var i = 0; i < spiderProjects.length; i++) {
                temp[spiderProjects[i].id] = spiderProjects[i];
                temp[spiderProjects[i].id].projectId = spiderProjects[i].id;
            }

            for (var j = 0; j < trackerProjects.length; j++) {
                temp[trackerProjects[j].projectId].trackerProject = trackerProjects[j];
            }

            var res = [];
            Object.keys(temp).forEach(function (property) {
                res.push(temp[property]);
            });
            return res;
        }

        function isValidDataFromRankTracker(res) {
            return res &&
                res.data &&
                res.data.trackerProjects &&
                res.data.trackerProjects.rows &&
                res.data.trackerProjects.totalRows !== 0;
        }

        this.createProject = function (project) {
            return API.post(apiPath + '/web', project, true, true);
        };

        this.deleteProject = function (projectId) {
            return API.delete(apiPath + '/' + projectId);
        };

        this.getByName = function (projectName) {
            return API.get(apiPath + '/get-by-name?name=' + projectName, {}, true);
        };

        this.addCompetitor = function (projectId, competitor) {
            return API.put(apiPath + '/' + projectId + "/competitor", {competitor}, true);
        };

        this.deleteCompetitor = function (projectId, competitorId) {
            return API.delete(apiPath + '/' + projectId + '/competitor?competitorId=' + competitorId, null, true);
        };

        this.saveSettings = function (projectId, settings) {
            return API.post(apiPath + '/' + projectId + '/settings', settings, true, true);
        };

        this.getSettings = function (projectId) {
            return API.get(apiPath + '/' + projectId + '/settings', {}, true);
        };

        this.getCompetitors = function (projectId) {
            return API.get(apiPath + '/' + projectId + '/competitor', {}, true);
        };

        this.suggestedKeywords = function (url) {
            return API.get(apiPath + '/suggested-keywords', {params: {url}}, true, true, true);
        };

        this.suggestedCompetitors = function (url, keyword) {
            return API.get(apiPath + '/suggested-competitors', {params: {url, keyword}}, true, true, true);
        };
    }
});

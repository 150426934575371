'use strict';
define(['angular'], function(ng) {
    function Controller($scope, $mdDialog, $state, $stateParams, LeadGenerationService, form) {
        var vm=$scope;
        vm.loading={
            post: false
        };
        vm.form=Object.assign({},form);
        vm.formName=form.name;
        vm.duplicatedNameFormError=false;

        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;

        vm.changeNameForm=function () {
            vm.duplicatedNameFormError=false;
        };

        function hide() {
            $mdDialog.hide();
        }
        function cancel() {
            $mdDialog.cancel();
        }
        function answer() {
            vm.loading.post=true;
            if(!vm.form.name||vm.form.name.length<1){
                vm.loading.post=false;
                return;
            }
            LeadGenerationService.updateForm({id:vm.form.id,name:vm.form.name.trim()})
                .then(function(res){
                    $state.reload();
                    vm.loading.post=false;
                    $mdDialog.hide();
                })
                .catch(function(err){
                    vm.loading.post=false;
                    if(err.errors[0].code=='ER_DUP_ENTRY'){
                        vm.duplicatedNameFormError=true;
                    }
                });
        }
    }
    Controller.$inject=['$scope', '$mdDialog', '$state', '$stateParams', 'LeadGenerationService', 'form'];
    return Controller;
});
